import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StoreState } from '../../../app/store';
import { CancelButton } from '../../../components/GridType';
import { MemberDetailSelectPropertyDefault } from '../../manager_management/components/MemberDetailProperty';
import { SelectedMember } from '../../email/component/SelectedMember';
import { CreateProperty } from '../../../components/Layout/CreateProperty';
import {
  deleteSelectedLicense,
  resetCreateMemberInformation,
  updateCreateMemberInformation,
  updateMemberPageStatus,
  updateSelectedLicenses
} from '../../../app/slice/memberManagementSlice';
import { SaveButton } from '../../../components/Layout/SaveButton';
import { insertMember } from '../tools/insertMember';
import { updateMember } from '../../../utils/user_API';

export function CreateSection() {
  const members = useSelector((state: StoreState) => state.memberManagement);

  const [lastSelected, setLastSelected] = useState<string>('');
  const create_member = members.create_member;

  const dispatch = useDispatch();

  function onChange(text: string, id: string) {
    dispatch(
      updateCreateMemberInformation({
        id: id,
        value: text
      })
    );
  }

  async function saveMember() {
    const result = await insertMember(create_member, members.selected_licenses);
    if (result) {
      dispatch(updateMemberPageStatus('read'));
      dispatch(resetCreateMemberInformation());
    }
  }

  async function changeMemberInformation() {
    const response = await updateMember(members.create_member);

    if (response.message === 'success') {
      alert('성공적으로 수정되었습니다.');
      dispatch(updateMemberPageStatus('read'));
    } else {
      alert('오류가 발생하였습니다.');
    }
  }

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '30px' }}>
        <CreateProperty
          title="아이디 (이메일 입력)"
          value={create_member.email}
          active={true}
          onChange={(e) => {
            onChange(e.target.value, 'email');

            const email_regex =
              /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/i;

            if (!email_regex.test(e.target.value)) {
            }
          }}
        />

        <CreateProperty
          title="비밀번호"
          value={create_member.password}
          active={true}
          onChange={(e) => {
            onChange(e.target.value, 'password');
          }}
        />

        <CreateProperty
          title="사업자등록번호"
          value={create_member.business_number}
          active={true}
          onChange={(e) => {
            onChange(e.target.value, 'business_number');
          }}
        />

        <MemberDetailSelectPropertyDefault
          item={[
            { value: '산업환경설비공사업', title: '산업환경설비공사업' },
            { value: '토목공사업', title: '토목공사업' },
            { value: '조경공사업', title: '조경공사업' },
            { value: '건축공사업', title: '건축공사업' },
            { value: '토목건축공사업', title: '토목건축공사업' },
            { value: '토공사', title: '토공사' },
            { value: '포장공사', title: '포장공사' },
            {
              value: '보링ㆍ그라우팅ㆍ파일공사',
              title: '보링ㆍ그라우팅ㆍ파일공사'
            },
            {
              value: '실내건축공사',
              title: '실내건축공사'
            },
            {
              value: '금속구조물ㆍ창호ㆍ온실공사',
              title: '금속구조물ㆍ창호ㆍ온실공사'
            },
            {
              value: '지붕판금ㆍ건축물조립공사',
              title: '지붕판금ㆍ건축물조립공사'
            },
            {
              value: '도장공사',
              title: '도장공사'
            },
            {
              value: '습식ㆍ방수공사',
              title: '습식ㆍ방수공사'
            },
            {
              value: '석공사',
              title: '석공사'
            },
            {
              value: '조경식재공사',
              title: '조경식재공사'
            },
            {
              value: '조경시설물설치공사',
              title: '조경시설물설치공사'
            },
            {
              value: '철근ㆍ콘크리트공사',
              title: '철근ㆍ콘크리트공사'
            },
            {
              value: '구조물해체ㆍ비계공사',
              title: '구조물해체ㆍ비계공사'
            },

            {
              value: '상ㆍ하수도설비공사',
              title: '상ㆍ하수도설비공사'
            },
            {
              value: '철도ㆍ궤도공사',
              title: '철도ㆍ궤도공사'
            },
            {
              value: '철강구조물공사',
              title: '철강구조물공사'
            },
            { value: '수중공사', title: '수중공사' },
            {
              value: '준설공사',
              title: '준설공사'
            },
            { value: '승강기설치공사', title: '승강기설치공사' },
            { value: '삭도설치공사', title: '삭도설치공사' }
          ]}
          onChange={(e) => {
            setLastSelected(e.target.value);
            dispatch(updateSelectedLicenses(e.target.value));
          }}
          size="short"
          id="license_name"
          title="면허 선택"
          isModify={true}
          value={lastSelected}
        />

        <div style={{ display: 'flex', gap: '20px' }}>
          {members.selected_licenses.length === 0 && (
            <span style={{ color: '#606975' }}>
              선택한 면허가 존재하지 않습니다.
            </span>
          )}
          {members.selected_licenses.map((item) => (
            <SelectedMember
              key={item.license_name}
              name={item.license_name}
              email={''}
              onDelete={() => {
                dispatch(deleteSelectedLicense(item.license_name));
              }}
            />
          ))}
        </div>

        <div
          style={{
            margin: '50px 0',
            height: '2px',
            backgroundColor: '#eee'
          }}
        />

        <CreateProperty
          title="회사명"
          value={create_member.company_name}
          active={true}
          onChange={(e) => {
            onChange(e.target.value, 'company_name');
          }}
        />

        <CreateProperty
          title="대표자명"
          value={create_member.representative_name}
          active={true}
          onChange={(e) => {
            onChange(e.target.value, 'representative_name');
          }}
        />

        <CreateProperty
          title="대표자 연락처"
          value={create_member.representative_phone}
          active={true}
          onChange={(e) => {
            onChange(e.target.value, 'representative_phone');
          }}
        />

        <CreateProperty
          title="사업장 주소"
          value={create_member.address}
          active={true}
          onChange={(e) => {
            onChange(e.target.value, 'address');
          }}
        />

        <CreateProperty
          title="회사 연락처"
          value={create_member.company_phone_number}
          active={true}
          onChange={(e) => {
            onChange(e.target.value, 'company_phone_number');
          }}
        />

        <CreateProperty
          title="팩스 번호"
          value={create_member.fax_number}
          active={true}
          onChange={(e) => {
            onChange(e.target.value, 'fax_number');
          }}
        />

        <CreateProperty
          title="이름"
          value={create_member.name}
          active={true}
          onChange={(e) => {
            onChange(e.target.value, 'name');
          }}
        />

        <CreateProperty
          title="직위"
          value={create_member.position}
          active={true}
          onChange={(e) => {
            onChange(e.target.value, 'position');
          }}
        />

        <CreateProperty
          title="전화번호"
          value={create_member.phone_number}
          active={true}
          onChange={(e) => {
            onChange(e.target.value, 'phone_number');
          }}
        />

        <MemberDetailSelectPropertyDefault
          title="구독 선택"
          value={create_member.subscription}
          id="subscription"
          size="short"
          isModify={true}
          item={[
            {
              value: '비즈니스',
              title: '비즈니스'
            },

            {
              value: '퍼스트',
              title: '퍼스트'
            },

            {
              value: '콘택스',
              title: '콘택스'
            }
          ]}
          onChange={(e) => {
            dispatch(
              updateCreateMemberInformation({
                id: 'subscription',
                value: e.target.value
              })
            );
          }}
        />

        <CreateProperty
          title="구독기간"
          value={create_member.subscription_end}
          active={create_member.subscription !== '비즈니스'}
          onChange={(e) => {
            onChange(e.target.value, 'phone_number');
          }}
        />
      </div>

      <div style={{ justifyContent: 'end', display: 'flex', gap: '20px' }}>
        <SaveButton
          onClick={
            members.status === 'write' ? saveMember : changeMemberInformation
          }
        />
        <CancelButton
          onClick={() => {
            dispatch(updateMemberPageStatus('read'));
          }}
        />
      </div>
    </>
  );
}
