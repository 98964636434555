import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  getClassification,
  searchPhoneSales,
  searchRecallPhoneSales,
} from '../../../utils/sales_API';
import {
  loadPhoneSales,
  resetPhoneSales,
  updatePhoneCurrentPage,
  updatePhoneSalesIndex,
  updatePhoneSalesTarget,
} from '../../../app/slice/phoneSalesSlice';
import PhoneSalesTableRowSection from './PhoneSalesTableRowSection';
import { updateLimit, updateSort } from '../../../app/slice/salesSlice';
import {
  updatePageLength,
  updatePageNumbers,
} from '../../../app/slice/phoneSalesSlice';
import {
  calculatePagination,
  createPaginationSize,
} from '../../../utils/common_API';
import {
  ManagerSection,
  RowDetailMemo,
  TableColumn,
  TableColumnSection,
  TableRow,
  TableSection,
} from './components';
import { MenuItem, Pagination, Select } from '@mui/material';
import PageNumber from './PageNumber';

const St_CheckSection = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const St_ArrowSection = styled.div`
  display: flex;
  margin-top: 20px;
  justify-content: center;
  gap: 20px;
  align-items: end;
`;

const St_TotalCount = styled.div`
  color: rgba(26, 49, 85, 0.7);
  font-weight: bold;

  strong {
    font-weight: bold;
    color: #1a3155;
  }
`;
export default function PhoneSalesRow({
  detailOn,
  setDetailOn,
  checkData,
  allCheck,
  changeExtraInfo,
  loadDetailMemo,
  regdate,
  is_shared,
  shareData,
  handlePage,
}) {
  const dispatch = useDispatch();

  const [recallOn, setRecallOn] = useState(false);
  const {
    phoneSales_total_count,
    phoneSales_data,
    current_page,
    selected_phoneSales_title,
  } = useSelector(state => state.phoneSales);

  const { sort, limit } = useSelector(state => state.sales);

  async function loadRecallSales() {
    const user_id = localStorage.getItem('id');
    const result = await searchRecallPhoneSales(1, limit);
    let length = calculatePagination(result.data.total_count, limit);
    let array = [];
    array = createPaginationSize(offset, length);
    dispatch(updatePageLength(length));
    dispatch(updatePageNumbers(array));

    const classification = await getClassification(user_id);

    const jonghap_jeonmoon_array = [...result.data.data];

    jonghap_jeonmoon_array.forEach((item, index) => {
      var isOk = false;
      classification.forEach(element => {
        if (item.unique_key === element.ncrgsseq) {
          isOk = true;
          item.classification = element.classification;
        }
      });

      if (isOk !== true) {
        item.classification = null;
      }

      console.log(item);
    });

    if (result.status === true) {
      setDetailOn({ key: detailOn.key, on: false });
      dispatch(
        loadPhoneSales({
          total_count: result.data.total_count,
          data: jonghap_jeonmoon_array,
        }),
      );
    }
  }

  async function init() {
    const filters = {
      area: '',
      address: '',
      ncrgskname: '',
      ncrgsmaster: '',
      /**
       * 공고번호
       */
      license: '',
      /** 업종 등록번호 */
      ncrmasternum: '',
      ncrofftel: '',
      email: '',
      phoneNumber: '',
    };
    const user_id = localStorage.getItem('id');
    const page = localStorage.getItem('phone_page');
    dispatch(updatePhoneCurrentPage(Number(page ?? 1)));
    dispatch(resetPhoneSales());
    const jonghap_jeonmoon_result = await searchPhoneSales(
      Number(page ?? 1),
      filters,
      sort,
      limit,
    );

    let length = calculatePagination(
      jonghap_jeonmoon_result.data.total_count,
      limit,
    );
    let array = [];
    array = createPaginationSize(offset, length);
    dispatch(updatePageLength(length));
    dispatch(updatePageNumbers(array));

    const classification = await getClassification(user_id);

    const jonghap_jeonmoon_array = [...jonghap_jeonmoon_result.data.data];

    jonghap_jeonmoon_array.forEach(item => {
      classification.forEach(element => {
        if (item.unique_key === element.ncrgsseq) {
          item.classification = element.classification;
        }
      });
    });

    if (jonghap_jeonmoon_result.status === true) {
      dispatch(
        loadPhoneSales({
          total_count: jonghap_jeonmoon_result.data.total_count,
          data: jonghap_jeonmoon_array,
        }),
      );
      setDetailOn({ key: detailOn.key, on: false });
    }
  }

  const [offset, setOffset] = useState(0);

  useEffect(() => {
    setDetailOn({ key: detailOn.key, on: false });
    if (recallOn) {
      loadRecallSales();
    } else {
      init();
    }
  }, [offset, recallOn, limit]);

  const limitOption = [10, 50, 100];

  return (
    <div style={{ display: 'flex' }}>
      <TableSection>
        <St_CheckSection>
          <St_TotalCount>
            총 <strong>{phoneSales_total_count.toString()}</strong> 개의 휴대폰
            DB 데이터가 있습니다.{' '}
          </St_TotalCount>

          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '10px',
            }}
          >
            <Select
              onChange={e => {
                dispatch(updateLimit(e.target.value));
              }}
              sx={{
                outline: 'none',
                width: '150px',
                border: '1px solid #ddd',
                borderRadius: '50px',
                color: '#aaa',
                fontSize: '16px',
              }}
              defaultValue={limit}
            >
              {limitOption.map(item => {
                return (
                  <MenuItem style={{ padding: '5px' }} key={item} value={item}>
                    {item}개씩 보기
                  </MenuItem>
                );
              })}
            </Select>

            <button
              onClick={() => {
                setRecallOn(!recallOn);
              }}
              style={
                recallOn
                  ? {
                      display: 'block',
                      backgroundColor: '#1976d2',
                      color: '#fff',
                      borderRadius: '5px',
                      width: '100px',
                      height: '30px',
                    }
                  : {
                      display: 'block',
                      backgroundColor: '#999',
                      color: '#fff',
                      borderRadius: '5px',
                      width: '100px',
                      height: '30px',
                    }
              }
            >
              재연락
            </button>
          </div>
        </St_CheckSection>

        <TableColumnSection>
          <TableColumn width={10}>번호</TableColumn>
          <TableColumn width={15}>상호명</TableColumn>
          <TableColumn width={10}>대표자명</TableColumn>
          <TableColumn width={15}>전화번호</TableColumn>
          <TableColumn width={15}>면허</TableColumn>
          <TableColumn
            width={15}
            onClick={() => {
              sort === null
                ? dispatch(updateSort(false))
                : dispatch(updateSort(!sort));
            }}
          >
            평가액 (단위:백만원)
            <span className="material-symbols-outlined">
              {sort === true ? 'expand_more' : 'expand_less'}
            </span>
          </TableColumn>
          <TableColumn width={10}>상세보기</TableColumn>
          <TableColumn width={10}>결번</TableColumn>
        </TableColumnSection>

        {phoneSales_data !== undefined &&
          phoneSales_data.map((item, index) => {
            return (
              <PhoneSalesTableRowSection
                page={current_page}
                index={index}
                target="phone_sales"
                item={item}
                detailOn={detailOn}
                setDetailOn={params => {
                  setDetailOn(params);
                }}
                checkData={checkData}
                allCheck={allCheck}
                changeExtraInfo={() => {
                  changeExtraInfo();
                }}
                loadDetailMemo={() => {
                  loadDetailMemo(item.unique_key);
                }}
              />
            );
          })}

        <St_ArrowSection>
          <Pagination
            count={Math.ceil(phoneSales_total_count / limit)}
            onChange={handlePage}
            page={Number(current_page)}
          />

          <PageNumber
            currentPage={current_page}
            onChange={handlePage}
            pageNumber={Math.ceil(phoneSales_total_count / limit)}
          />
        </St_ArrowSection>
      </TableSection>

      <ManagerSection>
        <h2
          style={{
            marginBottom: '20px',
            fontWeight: 'bold',
            fontSize: '20px',
          }}
        >
          체크리스트
        </h2>
        <TableColumnSection>
          <TableColumn width={30}>날짜</TableColumn>
          <TableColumn width={40}>상호명</TableColumn>
          <TableColumn width={30}>공유</TableColumn>
        </TableColumnSection>

        <>
          <div
            style={{
              display: 'flex',
              borderBottom: '1px solid #ddd',
            }}
          >
            <TableRow width={30}>
              {regdate === undefined ? '-' : regdate}
            </TableRow>

            <TableRow width={40}>{selected_phoneSales_title}</TableRow>
            <TableRow width={30}>
              <button
                style={
                  is_shared === true
                    ? {
                        backgroundColor: 'black',
                        borderRadius: '4px',
                        fontSize: '12px',
                        padding: '5px',
                        color: '#fff',
                      }
                    : {
                        backgroundColor: '#ddd',
                        borderRadius: '4px',
                        fontSize: '12px',
                        padding: '5px',
                      }
                }
              >
                {is_shared === true ? '메모완료' : '메모작성'}
              </button>
            </TableRow>
          </div>
          <RowDetailMemo shareData={shareData} detailOn={detailOn} />
        </>
      </ManagerSection>
    </div>
  );
}
