import { createSlice } from '@reduxjs/toolkit';

export const contactCheckListSlice = createSlice({
  name: 'contactCheckList',
  initialState: {
    checkLists: [
      {
        name: '설치완료',
        value: [
          { name: '대표자', value: false, type: 'check' },
          { name: '직원', value: false, type: 'check' },
          { name: '기타', value: false, type: 'check' },
        ],
        type: 'check',
      },
      {
        name: '스크립트 이행',
        value: [
          { name: '100%', value: false, type: 'check' },
          { name: '80%', value: false, type: 'check' },
          { name: '50%', value: false, type: 'check' },
          { name: '기타', value: false, type: 'check' },
        ],
        type: 'check',
      },
      { name: '14일 프로모션 적용', value: false, type: 'check' },
      { name: '세무기장 브리핑', value: false, type: 'check' },
      { name: '중처법 브리핑', value: false, type: 'check' },
      { name: '기타 재무, 세금, 금융 브리핑', value: false, type: 'check' },
    ],
    memo: '',
    regdate: '-',
    is_shared: false,
    re_call_date: '',
    re_call_true: false,
  },
  reducers: {
    getContactCheckList: (state, action) => {
      state.checkLists = action.payload.checkLists;
      state.memo = action.payload.memo;
      state.regdate = action.payload.regdate;
      state.is_shared = action.payload.is_shared;
      state.re_call_date = action.payload.re_call_date;
      state.re_call_true = action.payload.re_call_true;
    },
    updateCheckList: (state, action) => {
      const checkLists = [...state.checkLists];

      checkLists.forEach(item => {
        if (
          item.name === action.payload.name &&
          typeof item.value !== 'object'
        ) {
          item.value = action.payload.value;
        }

        if (typeof item.value === 'object') {
          item.value.forEach(jtem => {
            if (jtem.name === action.payload.name) {
              jtem.value = action.payload.value;
            }
          });
        }
      });

      state.checkLists = checkLists;
    },

    updateMemo: (state, action) => {
      state.memo = action.payload;
    },

    resetRecall: (state, action) => {
      state.re_call_date = '';
      state.re_call_true = false;
    },

    updateRecallDate: (state, action) => {
      state.re_call_date = action.payload;
    },

    updateRecallTrue: (state, action) => {
      state.re_call_true = action.payload;
    },

    updateShared: (state, action) => {
      state.is_shared = action.payload;
    },
  },
});

export const {
  updateShared,
  updateRecallTrue,
  updateRecallDate,
  updateCheckList,
  resetRecall,
  updateMemo,
  getContactCheckList,
} = contactCheckListSlice.actions;

export default contactCheckListSlice.reducer;
