import React, { useEffect, useRef, useState } from 'react';
import TitleCard from '../../components/Cards/TitleCard';
import axios from 'axios';
import {
  Box,
  Button,
  Chip,
  Grid,
  InputAdornment,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useDispatch } from 'react-redux';
import { setPageTitle } from '../../app/slice/headerSlice';
import { getToken } from '../../utils/getToken';
import stripHtmlAndLimitLength from '../../utils/stripHtmlAndLimitLength';

function Inquiry() {
  const dispatch = useDispatch();
  const [inquiries, setInquiries] = useState([
    {
      inquire_id: 1,
      ctgr: '',
      title: '',
      text: '',
      reg_date: '',
      file_path: '',
      email: ''
    }
  ]);

  const [selectedInquiry, setSelectedInquiry] = useState({
    inquire_id: 1,
    ctgr: '',
    title: '',
    text: '',
    reg_date: '',
    file_path: '',
    email: ''
  });

  const [replies, setReplies] = useState([
    {
      id: 1,
      category: '',
      title: '',
      regdate: ''
    }
  ]);

  const [isUpdate, setIsUpdate] = useState(false);

  const editorRef = useRef();

  const [id, setId] = useState(0);
  const [category, setCategory] = useState([]);
  const [, setEmail] = useState('');

  const [columns] = useState([
    {
      field: 'inquire_id',
      headerName: '문의 번호',
      width: 150,
      align: 'center',
      headerAlign: 'center'
    },
    { field: 'ctgr', headerName: '카테고리', width: 150 },
    { field: 'email', headerName: '이메일', width: 200 },
    { field: 'title', headerName: '제목', width: 500 },
    { field: 'reg_date', headerName: '작성일자', width: 200 },
    { field: 'reply', headerName: '답변여부', width: 200 }
  ]);

  const [title, setTitle] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [content, setContent] = useState('');
  const [status, setStatus] = useState('');
  const [replyId, setReplyId] = useState('');
  const [, setUserNotification] = useState([]);
  const [userIdList, setUserIdList] = useState([]);
  const [token, setToken] = useState([]);

  useEffect(() => {
    getInquiryData();
    dispatch(setPageTitle({ title: '1:1 문의' }));
    loadCategory();
  }, []);

  async function getManageNotification(paramsEmail) {
    try {
      const option = {
        url: 'https://cpi.contax.ai/get_mng_notification',
        method: 'GET',
        params: {
          email: paramsEmail
        },
        header: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      };

      const response = await axios(option);
      if (response.status === 200) {
        const array = [];
        response.data.forEach((item) => {
          if (item.target.includes('inquiry')) {
            array.push(item.email);
          }
        });

        getToken(array, (value) => {
          setToken(value);
        });
        setUserNotification(array);
        getUserId(array);
      }
    } catch (e) {}
  }

  async function insertNotification() {
    const body = {
      notification_type: 'inquiry',
      title: title,
      content: content,
      target: userIdList
    };

    if (status === 'create') {
      const option = {
        url: 'https://cpi.contax.ai/insert_notification',
        method: 'POST',
        data: body,
        header: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      };

      axios(option).then((response) => {
        if (response.status === 200) {
          return true;
        } else {
          console.log(response);
        }
      });
    }
  }

  async function sendNotification(paramsToken) {
    const body = {
      title: '[1:1문의] ' + title,
      body: stripHtmlAndLimitLength(content, 28),
      token: paramsToken
    };

    const option = {
      url: 'https://cpi.contax.ai/send_notification',
      method: 'POST',
      data: body,
      header: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    };

    axios(option).then((response) => {
      if (response.status === 200) {
        return true;
      } else {
        console.log(response);
      }
    });
  }

  async function getInquiryData() {
    let inquiresArray = [];
    let repliesArray = [];

    const option = {
      url: 'https://cpi.contax.ai/inquiries/all',
      method: 'GET',
      header: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    };

    await axios(option).then((response) => {
      if (response.status === 200) {
        inquiresArray = response.data.inquiries.reverse();
      }
    });

    const replyOption = {
      url: 'https://cpi.contax.ai/inquiries/reply/all',
      method: 'GET',
      header: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    };

    await axios(replyOption).then((response) => {
      if (response.status === 200) {
        setReplies(response.data);
        repliesArray = response.data;
      }
    });

    inquiresArray.forEach((item, index) => {
      repliesArray.forEach((jtem, jndex) => {
        if (item.inquire_id === jtem.id) {
          inquiresArray[index] = {
            ...inquiresArray[index],
            reply: 'O'
          };
        }
      });
    });

    setInquiries(inquiresArray);
  }

  async function createReplyData() {
    const body = {
      id: selectedInquiry.inquire_id,
      inquire_id: selectedInquiry.inquire_id,
      email: selectedInquiry.email,
      title: title,
      content: content,
      regdate: selectedInquiry.reg_date,
      file: ''
    };

    if (status !== 'create') {
      setStatus('create');
    }

    if (status === 'create') {
      const option = {
        url: 'https://cpi.contax.ai/inquiries/reply',
        method: 'POST',
        data: body,
        header: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      };

      axios(option).then((response) => {
        if (response.status === 200) {
          setTitle('');
          setContent('');
          getInquiryData();
          setStatus('');

          getInquiryData();
          insertNotification();

          token.forEach((item) => {
            sendNotification(item);
          });
        }
      });

      const sendOption = {
        url: 'https://cpi.contax.ai/inquiries/reply/send_email',
        method: 'POST',
        params: body,
        header: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      };

      axios(sendOption).then((response) => {
        if (response.status === 200) {
          setTitle('');
          setContent('');
          getInquiryData();
          setStatus('');
        }
      });
    }
  }

  async function updateReply() {
    if (status !== 'change') {
      setStatus('change');
    }

    if (status === 'change') {
      const option = {
        url: 'https://cpi.contax.ai/inquiries/reply/update',
        data: {
          id: replyId,
          title: title,
          content: content,
          file: '',
          regdate: ''
        },
        method: 'PUT',
        header: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      };

      axios(option).then((response) => {
        if (response.status === 200) {
          setTitle('');
          setContent('');
          setStatus('');
          getInquiryData();
        }
      });
    }
  }

  async function getUserId(paramsArray) {
    const option = {
      url: 'https://cpi.contax.ai/members/all_members',
      method: 'GET',
      header: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    };

    await axios(option).then((response) => {
      const array = [];
      if (response.status === 200) {
        response.data.forEach((item) => {
          paramsArray.forEach((jtem) => {
            if (item.email === jtem) {
              array.push(item.user_id);
            }
          });
        });
      }

      setUserIdList(array);
    });
  }

  async function changeCategory(newCategory) {
    const option = {
      url: 'https://cpi.contax.ai/categories/inquiry',
      data: {
        text: newCategory
      },
      method: 'PUT',
      header: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    };

    axios(option).then((response) => {
      if (response.status === 200) {
        setCategory(newCategory);
      }
    });
  }

  async function deleteInquiry() {
    const option = {
      url: 'https://cpi.contax.ai/inquiries',
      params: {
        id: id
      },
      method: 'DELETE',
      header: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    };

    axios(option).then((response) => {
      if (response.status === 200) {
        const array = [...inquiries];

        array.forEach((item, index) => {
          if (item.id === id) {
            array.splice(index, 1);
          }
        });

        setInquiries(array);
        alert('성공적으로 삭제되었습니다.');
      }
    });
  }

  async function loadCategory() {
    const option = {
      url: 'https://cpi.contax.ai/categories/inquiry',
      method: 'GET',
      header: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    };

    axios(option).then((response) => {
      if (response.status === 200) {
        setCategory(response.data[0][0]);
      }
    });
  }

  const addCategory = () => {
    const newCategory = [...category];
    newCategory.push(selectedCategory);

    changeCategory(newCategory);
  };

  const handleCategory = (e) => {
    setSelectedCategory(e.target.innerText);
  };

  const handleDelete = (index) => {
    const newSelectedCategories = [...category];
    newSelectedCategories.splice(index, 1); // 인덱스에 해당하는 카테고리 삭제
    changeCategory(newSelectedCategories);
  };

  const handleChange = () => {
    const data = editorRef.current.getInstance().getHTML();
    setContent(data);
  };

  const handleSelection = (newSelection) => {
    // newSelection은 선택된 행의 id 배열입니다.
    const reply = [...replies];
    const selectedData = inquiries.filter((row) =>
      newSelection.includes(row.inquire_id)
    );

    if (selectedData.length > 0) {
      const body = {
        inquire_id: selectedData[0].inquire_id,
        title: selectedData[0].title,
        text: selectedData[0].text,
        ctgr: selectedData[0].ctgr,
        reg_date: selectedData[0].reg_date,
        email: selectedData[0].email
      };

      setEmail(selectedData[0].email);
      getManageNotification(selectedData[0].email);

      if (selectedData[0].reply !== 'O') {
        setTitle('');
        setContent('');
        setSelectedCategory('');
        setIsUpdate(true);
      } else {
        setIsUpdate(false);
        reply.forEach((item, index) => {
          if (item.id === selectedData[0].inquire_id) {
            setTitle(item.title);
            setContent(item.content);
            setSelectedCategory(selectedData[0].ctgr);
            setReplyId(item.id);
          }
        });
      }

      setId(selectedData[0].inquire_id);

      setSelectedInquiry(body);
    }

    // 이제 selectedData 변수는 선택된 행의 데이터를 포함합니다.
  };

  return (
    <Grid container spacing={2} direction="column" justifyContent="center">
      <Grid item>
        <TitleCard title="1대1 문의">
          {/* Team Member list in table format loaded constant */}
          <DataGrid
            rows={inquiries}
            columns={columns}
            getRowId={(row) => row.inquire_id}
            onRowSelectionModelChange={(newSelection) => {
              handleSelection(newSelection);
            }}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 5 }
              }
            }}
            pageSizeOptions={[5, 10]}
          />
        </TitleCard>
      </Grid>

      <Grid
        item
        container
        spacing={2}
        direction="row"
        justifyContent="space-between"
      >
        {(status === 'create' || status === 'change') && (
          <Grid item xs={6}>
            <TitleCard title="문의 내용">
              <Stack>
                <Typography variant="h6">{selectedInquiry.title}</Typography>
                <Box
                  sx={{
                    marginTop: '20px',
                    p: 2,
                    border: '1px solid #ddd',
                    minHeight: '270px'
                  }}
                >
                  <Typography>{selectedInquiry.text}</Typography>
                </Box>
              </Stack>
            </TitleCard>
          </Grid>
        )}
        {(status === 'create' || status === 'change') && (
          <Grid item xs={6}>
            <TitleCard title="답변 내용">
              <Stack>
                <TextField
                  sx={{ marginBottom: '20px' }}
                  id="standard-basic"
                  label="답변 제목"
                  variant="standard"
                  value={title}
                  onChange={(e) => {
                    setTitle(e.target.value);
                  }}
                />
                <Box>
                  <Box display="flex" flexWrap="wrap" gap={1}>
                    {category.map((item, index) => (
                      <Chip
                        label={item}
                        onClick={handleCategory}
                        onDelete={() => {
                          handleDelete(index);
                        }}
                      />
                    ))}
                  </Box>

                  <Box mt={2} display="flex">
                    <TextField
                      variant="outlined"
                      fullWidth
                      label="카테고리"
                      value={selectedCategory}
                      onChange={(e) => {
                        setSelectedCategory(e.target.value);
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Button color="primary" onClick={addCategory}>
                              저장
                            </Button>
                          </InputAdornment>
                        )
                      }}
                    />
                  </Box>
                </Box>
                <TextField
                  sx={{ marginTop: '20px' }}
                  inputProps={{
                    style: { minHeight: '200px' }
                  }}
                  id="filled-multiline-flexible"
                  label="답변 내용"
                  multiline
                  variant="outlined"
                  value={content}
                  onChange={(e) => {
                    setContent(e.target.value);
                  }}
                />
              </Stack>
            </TitleCard>
          </Grid>
        )}
      </Grid>

      <Grid item>
        <Grid container justifyContent="flex-end" direction="row" spacing={2}>
          <Grid item>
            <Button
              disabled={id !== 0 && isUpdate === false}
              variant="contained"
              onClick={createReplyData}
            >
              답변 작성
            </Button>
          </Grid>
          <Grid item>
            <Button
              disabled={id === 0}
              sx={{ backgroundColor: 'red' }}
              onClick={deleteInquiry}
              variant="contained"
            >
              문의 삭제
            </Button>
          </Grid>
          {status !== 'create' && (
            <Grid item>
              <Button
                disabled={id !== 0 && isUpdate === true}
                variant="contained"
                onClick={updateReply}
              >
                답변 수정
              </Button>
            </Grid>
          )}
          {(status === 'change' || status === 'create') && (
            <Grid item>
              <Button
                variant="outlined"
                onClick={() => {
                  setStatus('');
                  setTitle('');
                  setContent('');
                }}
              >
                취소
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Inquiry;
