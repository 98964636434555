import { Input } from '@mui/material';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import {
  insertSalesClassify,
  updateExtraInfo,
  updateRecall,
} from '../../../utils/sales_API';
import axios from 'axios';
import {
  enterPhoneSalesExtraInfo,
  updateRecallDate,
  updateRecallTrue,
} from '../../../app/slice/phoneSalesSlice';
import { SalesDetailElement } from '../../../components/SalesDetailElement';
import { enterJonghapExtraInfo } from '../../../app/slice/jonghapSlice';

const St_MemoSection = styled.div`
  display: flex;
  gap: 10px;
  width: 35%;
  flex-direction: column;
  position: relative;
`;

const St_DetailMemo = styled.textarea`
  border: 1px solid #eee;
  margin: 10px 0;
  padding: 10px;
  height: 100%;
  resize: none;
  font-size: 15px;
`;

const St_DetailContentSection = styled.div`
  padding: 40px 20px;
  height: auto;
  display: ${props => (props.detailOn === true ? 'block' : 'none')};
  border: 1px solid #eee;
`;

const St_RowDetailContent = styled.div`
  width: 65%;

  background-color: #fff;
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export function PhoneSalesRowDetailContent({ data, target, detailOn }) {
  const [modalOn, setModalOn] = useState(false);
  const dispatch = useDispatch();
  const { phoneSales_data, phoneSales_index } = useSelector(
    state => state.phoneSales,
  );

  async function updateResolve(data) {
    if (!window.confirm('정말 해결 처리 하시겠습니까?')) {
      return false;
    }

    const option = {
      url: 'https://cpi.contax.ai/sales/update_rejection_resolve',
      method: 'PUT',
      data: {
        ncrgsseq: data.unique_key,
        second_manager_id: 116,
        contact_manager_id: data.rejection_item.contact_manager_id,
      },
      header: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    };

    await axios(option).then(response => {
      if (response.status === 200) {
        alert('해당 데이터의 해결처리가 완료되었습니다.');
      }
    });
  }

  return (
    <div>
      <St_DetailContentSection
        detailOn={data.unique_key === detailOn.key && detailOn.on === true}
      >
        {data.rejection === true && !data.rejection_item.resolve && (
          <div
            style={{
              paddingBottom: '30px',
              margin: '30px',
              borderBottom: '1px solid #ddd',
            }}
          >
            <h2
              style={{
                marginBottom: '20px',
                fontSize: '16px',
                fontWeight: 'bold',
              }}
            >
              반려
            </h2>

            <div
              style={{
                display: 'flex',
                gap: '20px',
                justifyContent: 'space-between',
              }}
            >
              <div
                style={{
                  width: '47%',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '10px',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <span
                    style={{
                      fontSize: '15px',
                      fontWeight: 'bold',
                    }}
                  >
                    반려 상호명
                  </span>
                  <span
                    style={{
                      fontSize: '15px',
                      color: '#555',
                    }}
                  >
                    {data.rejection_item.item}
                  </span>
                </div>

                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <span
                    style={{
                      fontSize: '15px',
                      fontWeight: 'bold',
                    }}
                  >
                    방문 영업사원
                  </span>
                  <span
                    style={{
                      fontSize: '15px',
                      color: '#555',
                    }}
                  >
                    {data.rejection_item.contact_manager_name}
                  </span>
                </div>

                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <span
                    style={{
                      fontSize: '15px',
                      fontWeight: 'bold',
                    }}
                  >
                    2차 관리자
                  </span>
                  <span
                    style={{
                      fontSize: '15px',
                      color: '#555',
                    }}
                  >
                    {data.rejection_item.second_manager_name}
                  </span>
                </div>

                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <span
                    style={{
                      fontSize: '15px',
                      fontWeight: 'bold',
                    }}
                  >
                    반려 상호명
                  </span>
                  <span
                    style={{
                      fontSize: '15px',
                      color: '#555',
                    }}
                  >
                    {data.rejection_item.item}
                  </span>
                </div>

                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <span
                    style={{
                      fontSize: '15px',
                      fontWeight: 'bold',
                    }}
                  >
                    반려된 날짜
                  </span>
                  <span
                    style={{
                      fontSize: '15px',
                      color: '#555',
                    }}
                  >
                    {data.rejection_item.date}
                  </span>
                </div>
              </div>

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '53%',
                }}
              >
                <span
                  style={{
                    fontSize: '15px',
                    fontWeight: 'bold',
                    marginBottom: '10px',
                  }}
                >
                  반려 사유
                </span>
                <textarea
                  defaultValue={data.rejection_item.reason}
                  disabled
                  style={{
                    resize: 'none',
                    height: '100%',
                    border: '1px solid #ddd',
                    padding: '10px',
                  }}
                />
                <button
                  onClick={() => {
                    updateResolve(data);
                  }}
                  style={{
                    width: '100px',
                    backgroundColor: '#1976d2',
                    marginLeft: 'auto',
                    marginTop: '10px',
                    color: '#fff',
                    fontSize: '14px',
                    borderRadius: '5px',
                    padding: '5px 0',
                  }}
                >
                  반려 해결
                </button>
              </div>
            </div>
          </div>
        )}
        <div style={{ display: 'flex' }}>
          <St_RowDetailContent>
            <SalesDetailElement title="사업자등록번호">
              <SalesDetailElement.Input
                id="ncrmasternum"
                placeholder="사업자등록번호 입력"
                data={data}
                onChange={e => {
                  dispatch(
                    enterPhoneSalesExtraInfo({
                      target: 'ncrmasternum',
                      value: e.target.value,
                    }),
                  );
                }}
                onBlur={updateExtraInfo}
                target="phone_sales"
              />
            </SalesDetailElement>

            <SalesDetailElement title="상호명">
              <SalesDetailElement.Input
                id="ncrgskname"
                placeholder="상호명 입력."
                data={data}
                onChange={e => {
                  dispatch(
                    enterPhoneSalesExtraInfo({
                      target: 'ncrgskname',
                      value: e.target.value,
                    }),
                  );
                }}
                onBlur={updateExtraInfo}
                target="phone_sales"
              />
            </SalesDetailElement>

            <SalesDetailElement title="대표자명">
              <SalesDetailElement.Input
                id="ncrgsmaster"
                placeholder="대표자명 입력."
                data={data}
                onChange={e => {
                  dispatch(
                    enterPhoneSalesExtraInfo({
                      target: 'ncrgsmaster',
                      value: e.target.value,
                    }),
                  );
                }}
                onBlur={updateExtraInfo}
                target="phone_sales"
              />
            </SalesDetailElement>

            <SalesDetailElement title="대표자 휴대폰">
              <SalesDetailElement.Input
                id="phone_number"
                placeholder="대표자 휴대폰 번호 입력"
                data={data}
                onChange={e => {
                  dispatch(
                    enterPhoneSalesExtraInfo({
                      target: 'phone_number',
                      value: e.target.value,
                    }),
                  );
                }}
                onBlur={updateExtraInfo}
                target="phone_sales"
              />
            </SalesDetailElement>

            <SalesDetailElement title="이메일">
              <SalesDetailElement.Input
                id="email"
                placeholder="이메일 입력."
                data={data}
                onChange={e => {
                  dispatch(
                    enterPhoneSalesExtraInfo({
                      target: 'email',

                      value: e.target.value,
                    }),
                  );
                }}
                onBlur={updateExtraInfo}
                target="phone_sales"
              />
            </SalesDetailElement>

            <SalesDetailElement title="팩스">
              <SalesDetailElement.Input
                id="fax"
                placeholder="팩스 입력"
                data={data}
                onChange={e => {
                  dispatch(
                    enterPhoneSalesExtraInfo({
                      target: 'fax',
                      value: e.target.value,
                    }),
                  );
                }}
                onBlur={updateExtraInfo}
                target="phone_sales"
              />
            </SalesDetailElement>

            <SalesDetailElement title="면허">
              <SalesDetailElement.Input
                id="jonghap_license"
                placeholder="면허 입력."
                data={data}
                onChange={e => {
                  dispatch(
                    enterPhoneSalesExtraInfo({
                      target: 'jonghap_license',
                      value: e.target.value,
                    }),
                  );
                }}
                onBlur={updateExtraInfo}
                target="phone_sales"
              />
            </SalesDetailElement>

            <SalesDetailElement title="팩스">
              <SalesDetailElement.Input
                id="fax"
                placeholder="팩스 입력"
                data={data}
                onChange={e => {
                  dispatch(
                    enterPhoneSalesExtraInfo({
                      target: 'fax',
                      value: e.target.value,
                    }),
                  );
                }}
                onBlur={updateExtraInfo}
                target="phone_sales"
              />
            </SalesDetailElement>

            <SalesDetailElement title="등록시도">
              <SalesDetailElement.Input
                id="area"
                placeholder="등록시도 입력"
                data={data}
                onChange={e => {
                  dispatch(
                    enterPhoneSalesExtraInfo({
                      target: 'area',
                      value: e.target.value,
                    }),
                  );
                }}
                onBlur={updateExtraInfo}
                target="phone_sales"
              />
            </SalesDetailElement>

            <SalesDetailElement title="소재지">
              <SalesDetailElement.Input
                id="address"
                placeholder="소재지 입력"
                data={data}
                onChange={e => {
                  dispatch(
                    enterPhoneSalesExtraInfo({
                      target: 'address',
                      value: e.target.value,
                    }),
                  );
                }}
                onBlur={updateExtraInfo}
                target="phone_sales"
              />
            </SalesDetailElement>

            <SalesDetailElement title="담당자">
              <SalesDetailElement.Input
                id="manager"
                placeholder="담당자 입력"
                data={data}
                onChange={e => {
                  dispatch(
                    enterPhoneSalesExtraInfo({
                      target: 'manager',
                      value: e.target.value,
                    }),
                  );
                }}
                onBlur={updateExtraInfo}
                target="phone_sales"
              />
            </SalesDetailElement>
          </St_RowDetailContent>

          <St_MemoSection>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <span style={{ fontWeight: 'bold', fontSize: '15px' }}>
                가망 등급 분류
              </span>
              <div style={{ display: 'flex', gap: '20px' }}>
                <div
                  style={{
                    display: 'flex',
                    gap: '10px',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    if (data.classification === '낮음') {
                      dispatch(
                        enterPhoneSalesExtraInfo({
                          target: 'classification',
                          value: null,
                        }),
                      );
                      insertSalesClassify(
                        data.unique_key,
                        data.ncrgskname,
                        '없음',
                      );

                      return;
                    }

                    dispatch(
                      enterPhoneSalesExtraInfo({
                        target: 'classification',
                        value: '낮음',
                      }),
                    );
                    insertSalesClassify(
                      data.unique_key,
                      data.ncrgskname,
                      '낮음',
                    );
                  }}
                >
                  <span style={{ fontSize: '15px' }}>낮음</span>
                  <input
                    type="checkbox"
                    checked={data.classification === '낮음'}
                  />
                </div>

                <div
                  style={{
                    display: 'flex',
                    gap: '10px',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    if (data.classification === '보통') {
                      dispatch(
                        enterPhoneSalesExtraInfo({
                          target: 'classification',
                          value: null,
                        }),
                      );
                      insertSalesClassify(
                        data.unique_key,
                        data.ncrgskname,
                        '없음',
                      );

                      return;
                    }

                    dispatch(
                      enterPhoneSalesExtraInfo({
                        target: 'classification',
                        value: '보통',
                      }),
                    );
                    insertSalesClassify(
                      data.unique_key,
                      data.ncrgskname,
                      '보통',
                    );
                  }}
                >
                  <span style={{ fontSize: '15px' }}>보통</span>
                  <input
                    type="checkbox"
                    checked={data.classification === '보통'}
                  />
                </div>

                <div
                  style={{
                    display: 'flex',
                    gap: '10px',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    if (data.classification === '낮음') {
                      dispatch(
                        enterPhoneSalesExtraInfo({
                          target: 'classification',
                          value: null,
                        }),
                      );
                      insertSalesClassify(
                        data.unique_key,
                        data.ncrgskname,
                        '없음',
                      );

                      return;
                    }

                    dispatch(
                      enterPhoneSalesExtraInfo({
                        target: 'classification',
                        value: '낮음',
                      }),
                    );
                    insertSalesClassify(
                      data.unique_key,
                      data.ncrgskname,
                      '낮음',
                    );
                  }}
                >
                  <span style={{ fontSize: '15px' }}>높음</span>
                  <input
                    type="checkbox"
                    checked={data.classification === '높음'}
                  />
                </div>
              </div>
            </div>
            <div
              onClick={() => {
                if (data.recall_true === false || data.recall_true === null) {
                  setModalOn(true);
                } else {
                  updateRecall(data.unique_key, false, '');
                  alert('재연락을 취소하였습니다.');
                  dispatch(updateRecallTrue(false));
                }
              }}
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '10px',
                cursor: 'pointer',
                width: '100px',
              }}
            >
              <span style={{ fontWeight: 'bold', fontSize: '15px' }}>
                재연락
              </span>
              <input
                type="checkbox"
                checked={
                  data.recall_true === false ||
                  data.recall_true === '' ||
                  data.recall_true === null
                    ? false
                    : true
                }
              />
            </div>
            {data.recall_true == true && data.recall_date !== null && (
              <span style={{ fontSize: '15px', fontWeight: 'bold' }}>
                재연락 날짜 : {data.recall_date}
              </span>
            )}

            <h3
              style={{
                fontSize: '15px',
                fontWeight: 'bold',
                marginTop: '20px',
              }}
            >
              메모 작성
            </h3>
            <St_DetailMemo
              placeholder="메모를 입력해주세요"
              value={data.memo === null ? '' : data.memo}
              onChange={e => {
                target === 'phone_sales' &&
                  dispatch(
                    enterPhoneSalesExtraInfo({
                      target: 'memo',
                      value: e.target.value,
                    }),
                  );
              }}
              onKeyDown={e => {
                if (e.key === 'F5') {
                  e.preventDefault(); // Prevent the default action of F5
                  const now = new Date();

                  const timeString =
                    now.getFullYear() +
                    '년 ' +
                    (now.getMonth() + 1) +
                    '월 ' +
                    now.getDate() +
                    '일 ' +
                    now.toLocaleTimeString() +
                    '\n> ';
                  dispatch(
                    enterPhoneSalesExtraInfo({
                      target: 'memo',
                      value:
                        data.memo === null
                          ? timeString
                          : data.memo + timeString,
                    }),
                  );
                }
              }}
              onBlur={() => {
                updateExtraInfo(
                  'memo',
                  data.memo === null ? '' : data.memo,
                  data.unique_key,
                  target,
                );
              }}
            />

            {modalOn && (
              <div
                style={{
                  padding: '10px',
                  backgroundColor: '#fff',
                  width: '350px',
                  bottom: '270px',
                  height: 'auto',
                  border: '1px solid #ddd',
                  position: 'absolute',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <h3 style={{ fontWeight: 'bold' }}>재연락 날짜 입력</h3>
                  <span
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      setModalOn(false);
                    }}
                  >
                    X
                  </span>
                </div>

                <input
                  style={{
                    border: '1px solid #ddd',
                    padding: '5px 10px ',
                    marginTop: '10px',
                  }}
                  value={data.recall_date}
                  onChange={e => {
                    dispatch(updateRecallDate(e.target.value));
                  }}
                />
                <span
                  style={{
                    marginTop: '10px',
                    fontSize: '12px',
                    color: '#aaa',
                  }}
                >
                  {'형식에 맞게 입력해주세요. 예) 24.01.01.17'}
                </span>
                <button
                  onClick={() => {
                    const regex =
                      /^\d{2}\.((0[1-9])|(1[0-2]))\.((0[1-9])|([1-2][0-9])|(3[0-1]))\.([0-1][0-9]|2[0-3])$/;
                    if (!regex.test(data.recall_date)) {
                      alert(
                        '날짜 형식이 맞지 않습니다. 형식에 맞게 수정해주세요 \n 예) 24.01.01.17',
                      );
                      return false;
                    } else {
                      alert('해당 데이터 재연락 날짜를 남겼습니다.');

                      dispatch(updateRecallTrue(true));
                      updateRecall(data.unique_key, true, data.recall_date);

                      setModalOn(false);
                    }
                  }}
                  style={{
                    padding: '5px',
                    border: '1px solid #ddd',
                    borderRadius: '5px',
                    marginTop: '10px',
                    backgroundColor: '#eee',
                  }}
                >
                  저장
                </button>
              </div>
            )}
          </St_MemoSection>
        </div>
      </St_DetailContentSection>
    </div>
  );
}
