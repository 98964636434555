import { createSlice } from '@reduxjs/toolkit';
import {
  calculatePagination,
  createPaginationSize
} from '../../utils/common_API';
import { ColProps } from '../../components/GridType';

export interface NotificationDataType {
  notification_type: string;
  title: string;
  content: string;
  target_user: string[];
  readed_user: string[] | null;
  id: number;
  regdate: string;
  article_id: number;
  email_ok: null | boolean;
  in_app_ok: null | boolean;
  kakao_ok: null | boolean;
}

export interface UserNotificationState {
  data: NotificationDataType[];
  tabList: string[];
  detailTabList: string[];
  selectedTab: '전체' | '보고서' | '매거진' | '공지사항' | '이용안내';
  detailTab: '알림 요약' | '인앱 알림' | '이메일' | '카카오';
  category: '제목';
  Grid_COL: ColProps[];
  total_count: number;
  offset: number;
  limit: number;
  page_numbers: number[];
  page_length: number;
  target: NotificationDataType;
  index: null | number;
  query: string;
}

const initialState: UserNotificationState = {
  index: 0,
  offset: 0,
  limit: 10,
  page_numbers: [1, 2, 3, 4, 5],
  page_length: 0,
  category: '제목',
  total_count: 0,
  data: [],
  target: {
    notification_type: '',
    title: '',
    content: '',
    target_user: [],
    readed_user: [],
    id: 0,
    regdate: '',
    article_id: 0,
    email_ok: false,
    in_app_ok: false,
    kakao_ok: false
  },
  tabList: ['전체', '보고서', '매거진', '공지사항', '이용안내'],
  detailTabList: ['알림 요약', '카카오', '인앱 알림', '이메일'],
  detailTab: '알림 요약',
  selectedTab: '전체',
  query: '',

  Grid_COL: [
    {
      search: false,
      title: '구분',
      id: 'notification_type',
      width: 15,
      textAlign: 'left',
      sort: true
    },
    {
      search: true,
      title: '제목',
      id: 'title',
      width: 25,
      textAlign: 'left',
      sort: true
    },

    {
      search: false,
      title: '발송 성공',
      id: 'in_app_ok',
      width: 25,
      textAlign: 'center',
      sort: true
    },

    {
      search: false,
      title: '발송 날짜',
      id: 'regdate',
      width: 15,
      textAlign: 'left',
      sort: true
    },

    {
      search: false,
      title: '발송 갯수 ',
      id: 'target_count',
      width: 10,
      textAlign: 'center',

      sort: false
    },

    {
      search: false,
      title: '상세보기',
      id: 'asdf',
      width: 10,
      textAlign: 'center',
      sort: false
    }
  ]
};
export const userNotificationSlice = createSlice({
  name: 'userNotification',
  initialState: initialState,
  reducers: {
    updateDetailTab: (state, action) => {
      state.detailTab = action.payload;
    },
    updateLimit: (state, action) => {
      state.limit = action.payload;
    },
    updateQuery: (state, action) => {
      state.query = action.payload;
    },
    updateDetailOn: (state, action) => {
      state.index = action.payload;
    },
    updateOffset: (state, action) => {
      state.offset = action.payload;
    },

    updatePageLength(state, action) {
      state.total_count = action.payload;
      const length = calculatePagination(action.payload, state.limit);
      let array = [];
      state.page_length = length;

      array = createPaginationSize(state.offset, length);
      state.page_numbers = array;
    },

    updateTotalCount: (state, action) => {
      state.total_count = action.payload;
    },
    updateTab: (state, action) => {
      state.selectedTab = action.payload;
    },

    resetNotificationData: (state) => {
      state.data = [];
    },

    updateCategory: (state, action) => {
      state.category = action.payload;
    },

    updateTarget: (state, action) => {
      const index = state.index;

      state.target = action.payload.item;
      if (index === action.payload.index) {
        state.index = null;
        return;
      }

      state.index = action.payload.index;
    },

    updateNotificationData: (state, action) => {
      const data = action.payload;
      const array: NotificationDataType[] = [];

      if (data.length === 0) {
        state.data = [];
        return;
      }

      state.total_count = data.total_count;
      data.data.forEach((item: NotificationDataType) => {
        let division = '';

        if (item.notification_type == 'bid') {
          division = '공고';
        }

        if (item.notification_type == 'contact') {
          division = '문의';
        }

        if (item.notification_type == 'notice') {
          division = '공지사항';
        }

        if (item.notification_type.includes('report')) {
          division = '보고서';
        }

        if (item.notification_type.includes('magazine')) {
          division = '매거진';
        }

        if (item.notification_type.includes('useGuide')) {
          division = '이용안내';
        }

        array.push({ ...item, notification_type: division });
      });
      state.data = array;
    }
  }
});

export const {
  updateQuery,
  updateLimit,
  updateTarget,
  updateOffset,
  updateNotificationData,
  updatePageLength,
  updateTab,
  updateDetailTab,
  updateCategory,
  resetNotificationData
} = userNotificationSlice.actions;

export default userNotificationSlice.reducer;
