import React, { useRef } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import EditorToolbar, { modules } from '../EditorToolbar';
import GoogleDonwloadButton from '../../asset/google_play_download_button.png';
import QrCode from '../../asset/QR code.png';
import styled from 'styled-components';
import { openModal } from '../../app/slice/layoutSlice';
import { useDispatch, useSelector } from 'react-redux';
import { PreviewEmailEditor } from '../Email/PreviewEmailEditor';
import { updateType } from '../../app/slice/emailBoardSlice';
import { StoreState } from '../../app/store';
import { unified } from 'unified';
import markdown from 'remark-parse';
import remark2rehype from 'remark-rehype';
import html from 'rehype-stringify';

const PreviewReport = styled.div`
  background: #ffffff;
  padding: 50px;
  h1 {
    font-weight: bold;
    font-size: 24px;
    margin-bottom: 10px;
  }

  h2 {
    font-weight: bold;
    font-size: 20px;
    margin-top: 60px;
    margin-bottom: 10px;
  }

  h3,
  h4 {
    font-weight: bold;
    font-size: 20px;
    padding-left: 20px;
    margin-bottom: 20px;
  }

  p {
    font-size: 18px;
    margin-bottom: 20px;
    padding-left: 20px;
    line-height: 2;
  }

  ul {
    font-size: 18px;
    padding-left: 50px;
    margin-bottom: 20px;

    ul {
      margin-top: 10px;
      padding-left: 30px;
    }
  }

  li {
    margin-bottom: 10px;
  }
`;

const ToolbarHeader = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;
`;

const BoardDownloadButton = styled.div`
  color: #fff;
  background-color: #1976d2;
  display: inline-flex;
  align-items: center;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  text-align: center;
  white-space: nowrap;
`;
//EditorToolBar.tsx

function ReportEmailEditor(props: {
  mode: string;
  value: string;
  setValue: (data: string) => void;
  bringBoard?: boolean;
}) {
  const dispatch = useDispatch();
  const quillRef = useRef(null);
  const { selectedReport } = useSelector(
    (state: StoreState) => state.reportEmail,
  );

  const contents = unified()
    .use(markdown)
    .use(remark2rehype)
    .use(html)
    .processSync(selectedReport.content)
    .toString();

  const formats = [
    'font',
    'size',
    'header',
    'color',
    'background',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'image',
    'align',
  ];

  //....
  return (
    <>
      <ToolbarHeader>
        <EditorToolbar />
        {(props.bringBoard ?? true) && (
          <BoardDownloadButton
            onClick={() => {
              dispatch(openModal());
              dispatch(updateType('매거진'));
            }}
          >
            게시판 글 가져오기
          </BoardDownloadButton>
        )}
      </ToolbarHeader>

      <div
        style={{
          padding: '20px',
          marginTop: '20px',
          paddingBottom: '20px',
          width: '100%',
          height: '100px',
          backgroundImage: `url(${require('../../asset/contax_logo_1.png')})`,
          backgroundSize: '25%',
          backgroundPositionX: 'left',
          backgroundPositionY: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundColor: '#fff',
          borderBottom: '3px solid rgba(50, 116, 230, 0.7)',
        }}
      />
      <div
        style={{
          padding: '100px',
          backgroundColor: '#f5f4f4',
        }}
      >
        {props.mode === 'preview' && <PreviewEmailEditor value={props.value} />}

        {props.mode !== 'preview' && (
          <PreviewReport dangerouslySetInnerHTML={{ __html: contents }} />
        )}

        <ReactQuill
          style={{
            height: '800px',
            marginTop: '4px',
            backgroundColor: '#ffffff',
            display: props.mode === 'write' ? 'block' : 'none',
          }}
          theme="snow"
          ref={quillRef}
          value={props.value}
          onChange={props.setValue}
          placeholder={'내용을 입력해주세요...'}
          modules={modules}
          formats={formats}
        />

        <div
          style={{
            backgroundColor: '#fff',
            borderTop: '2px solid #ddd',
            padding: '50px 0',
          }}
        >
          <div style={{ textAlign: 'center' }}>
            <span style={{ fontWeight: 'bold', fontSize: '24px' }}>
              지금 바로 앱을 무료로 다운받아보세요
            </span>

            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <a href="https://play.google.com/store/apps/details?id=com.contaxAI">
                <img
                  width="250px"
                  src={GoogleDonwloadButton}
                  alt="다운로드 버튼"
                />
              </a>

              <img width="100px" height="100px" src={QrCode} alt="QR 코드" />

              {/* <img
                                style={{
                                    width: "250px",
                                    height: "80px",
                                }}
                                src={AppleDonwloadButton}
                                alt="다운로드 버튼"
                            /> */}
            </div>
          </div>
        </div>

        <div
          style={{
            marginTop: '50px',
            backgroundColor: '#fff',
            padding: '40px 20px',
            color: '#999',
            fontSize: '14px',
          }}
        >
          <p style={{}}>본 메일은 발신 전용입니다.</p>
          <p>
            궁금하신 점이나 불편한 사항은 고객센터에 문의해 주시기 바랍니다.
          </p>
          <br />
          <p>
            회사명 : (주)인베스트파트너스 ㅣ 대표 : 이준형
            <br />
            주소 : 인천시 연수구 인천타워대로323, A동 3104
            <br />
            대표전화 : 1544-1530
            <br />
            이메일 : conbi@contax.ai
            <br />
            개인정보보호책임자 : 이소연
            <br />
            사업자등록번호 : 657-81-02424
          </p>
        </div>
      </div>
    </>
  );
}

export default ReportEmailEditor;
