import { useDispatch } from 'react-redux';
import {
  updateJeonmoonIndex,
  updateSelectedJeonmoonData,
  updateMissingNumber,
} from '../../../app/slice/jeonmoonSlice';
import { TableRow } from './components';
import styled from 'styled-components';
import JeonmoonRowDetailContent from './JeonmoonRowDetailContent';
import { updateJonghapJeonmoonMissingNumber } from '../../../utils/sales_API';

const St_TableRowSection = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  background-color: ${props =>
    props.recall === true
      ? '#1976d291'
      : props.is_saved === true
        ? 'rgba(0, 228, 42, 0.22)'
        : '#fff'};
  border-bottom: 1px solid #eee;
  border-top: 1px solid #eee;
  &:hover {
    background-color: ${props =>
      props.recall === true
        ? '105fae91'
        : props.is_saved === true
          ? 'rgba(0, 228, 42, 0.35)'
          : 'rgba(0,0,0,0.03)'};
  }
`;

export default function JeonmoonTableRowSection({
  item,
  checkData,
  index,
  target,
  detailOn,
  setDetailOn,
  loadDetailMemo,
  page,
}) {
  const dispatch = useDispatch();

  function rowClick() {
    localStorage.setItem('jeonmoon_page', page === 0 ? 1 : page);
    localStorage.setItem('jeonmoon_unique_key', item.unique_key);

    target === 'jeonmoon' && dispatch(updateJeonmoonIndex(index));

    if (item.unique_key !== detailOn.key && detailOn.on === true) {
      setDetailOn({ key: item.unique_key, on: true });
    } else if (item.unique_key !== detailOn.key && detailOn.on === false) {
      setDetailOn({ key: item.unique_key, on: true });
    } else if (item.unique_key === detailOn.key && detailOn.on === false) {
      setDetailOn({ key: item.unique_key, on: true });
    } else if (item.unique_key === detailOn.key && detailOn.on === true) {
      setDetailOn({ key: item.unique_key, on: false });
    }

    dispatch(
      updateSelectedJeonmoonData({
        title: item.ncrgskname,
        key: item.unique_key,
      }),
    );
    loadDetailMemo();
  }

  async function updateJeonmoonMissingNumber() {
    dispatch(updateJeonmoonIndex(index));
    const response = await updateJonghapJeonmoonMissingNumber(
      item.unique_key,
      !item.missing_number,
      '전문',
    );

    if (response) {
      dispatch(updateMissingNumber(!item.missing_number));
    }
  }

  function formatNumberToKRW(number) {
    const regex = /\[([^\]]+)\]/g;
    const matches = number.match(regex);

    return (
      parseInt(number.match(/\d+/)[0]).toLocaleString('ko-KR') + matches[0]
    );
  }

  const unique_key = localStorage.getItem('jeonmoon_unique_key') ?? 0;

  return (
    <>
      <St_TableRowSection
        recall={item.recall_true}
        is_saved={item.is_saved === true}
      >
        <TableRow width={10} onClick={rowClick}>
          {unique_key === item.unique_key && '>> '}
          {item.id}
        </TableRow>
        <TableRow width={15} onClick={rowClick}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            {item.ncrgskname}

            <div
              style={{
                color:
                  item.classification === '낮음'
                    ? 'red'
                    : item.classification === '보통'
                      ? 'green'
                      : item.classification === '높음'
                        ? 'blue'
                        : 'black',
              }}
            >
              {item.classification == null ? '' : `가망 ${item.classification}`}
            </div>
          </div>
        </TableRow>

        <TableRow width={10} onClick={rowClick}>
          {item.ncrgsmaster}
        </TableRow>
        <TableRow width={15} onClick={rowClick}>
          {item.ncrofftel}
        </TableRow>
        <TableRow width={15} onClick={rowClick}>
          {item.license}
        </TableRow>
        <TableRow width={15} onClick={rowClick}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {item.valuation != null
              ? item.valuation.split('),').map((item, index) => {
                  return (
                    <div style={{ padding: '3px 0' }}>
                      {item != null && item !== ''
                        ? formatNumberToKRW(
                            item.replace('(', ' [').replace(')', '') + ']',
                          )
                        : ''}
                    </div>
                  );
                })
              : ''}
          </div>
        </TableRow>
        {item.rejection === true && !item.rejection_item.resolve ? (
          <TableRow width={10} onClick={rowClick}>
            <button
              style={{
                display: 'flex',
                alignItems: 'center',
                backgroundColor: '#da5959',
                borderRadius: '4px',
                fontSize: '12px',
                padding: '5px 10px',
              }}
            >
              <span style={{ whiteSpace: 'nowrap', color: '#fff' }}>반려</span>
              <span
                style={{ fontSize: '16px', color: '#fff' }}
                className="material-symbols-outlined"
              >
                chevron_right
              </span>
            </button>
          </TableRow>
        ) : (
          <TableRow width={10} onClick={rowClick}>
            <button
              style={
                (item.email !== null && item.email !== '') ||
                (item.fax !== null && item.fax !== '') ||
                (item.manager !== null && item.manager !== '') ||
                (item.memo !== null && item.memo !== '') ||
                (item.phone_number !== null && item.phone_number !== '')
                  ? {
                      backgroundColor: '#000',
                      color: '#fff',
                      borderRadius: '4px',
                      fontSize: '12px',
                      padding: '5px',
                    }
                  : {
                      backgroundColor: '#ddd',
                      borderRadius: '4px',
                      fontSize: '12px',
                      padding: '5px',
                    }
              }
            >
              상세보기
            </button>
          </TableRow>
        )}

        <TableRow width={10} onClick={updateJeonmoonMissingNumber}>
          <input
            type="checkbox"
            checked={
              item.missing_number === false || item.missing_number === null
                ? false
                : true
            }
          />
        </TableRow>
      </St_TableRowSection>
      <JeonmoonRowDetailContent
        data={item}
        detailOn={detailOn}
        target={target}
      />
    </>
  );
}
