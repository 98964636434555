import { createSlice } from '@reduxjs/toolkit';

export interface LicenseType {
  license_ctgry: string;
  license_type: string;
  license_area: string;
  license_name: string;
}
export interface TestUserType {
  [key: string]: string | number | string[] | undefined | null | boolean;
  user_id: number;
  email: string;
  name: string;
  position: string;
  phone_number: string;
  business_number: string;
  company_name: string;
  representative_name: string;
  representative_phone: string;
  address: string;
  password: string;
  fax_number: string;
  company_phone_number: string;
  business_type: string;
  reg_date: string;
  sms_kakao_agree: number;
  license_names: string[];
  manager?: string;
  subscription: string;
  subscription_end: string;
  member_of_manager: string;
  is_login: boolean;
}

export interface SortType {
  option: string;
  sort: 'DESC' | 'ASC';
}

export interface TestUserDivisionType {
  business: SortType;
  first: SortType;
  contax: SortType;
}

export interface SignupErrorType {
  target: string;
  error: string;
}

export interface TestUserState {
  selected_licenses: LicenseType[];
  status: 'read' | 'write' | 'modify';
  users: TestUserType[];
  test_users: TestUserType[];
  managers: TestUserType[];
  user_total_count: number;
  manager_total_count: number;
  test_user_total_count: number;
  users_pending: 'loading' | 'success' | 'failed';
  managers_pending: 'loading' | 'success' | 'failed';
  test_users_pending: 'loading' | 'success' | 'failed';
  page_length: number;
  page_numbers: number[];
  tab: '비즈니스' | '퍼스트' | '콘택스';
  sort: TestUserDivisionType;
  original_target_member: TestUserType;
  target_member: TestUserType;
  create_member: TestUserType;
  manager: string;
  error: SignupErrorType;
}

const initialState: TestUserState = {
  selected_licenses: [],
  status: 'read',
  error: {
    target: '',
    error: ''
  },
  users: [],
  test_users: [],
  managers: [],
  manager: '',
  user_total_count: 0,
  manager_total_count: 0,
  test_user_total_count: 0,
  users_pending: 'success',
  managers_pending: 'success',
  test_users_pending: 'success',
  page_length: 0,
  page_numbers: [1],
  tab: '비즈니스',
  sort: {
    business: {
      option: 'reg_date',
      sort: 'DESC'
    },

    first: {
      option: 'reg_date',
      sort: 'DESC'
    },

    contax: {
      option: 'reg_date',
      sort: 'DESC'
    }
  },

  original_target_member: {
    is_login: false,
    subscription: '비즈니스',
    fax_number: '',
    representative_phone: '',
    password: '',
    user_id: 0,
    email: '',
    name: '',
    position: '',
    phone_number: '',
    business_number: '',
    company_name: '',
    representative_name: '',
    address: '',
    company_phone_number: '',
    business_type: '',
    reg_date: '',
    sms_kakao_agree: 0,
    subscription_end: '',
    license_names: [],
    member_of_manager: ''
  },

  target_member: {
    is_login: false,
    subscription: '비즈니스',
    fax_number: '',
    representative_phone: '',
    password: '',
    user_id: 0,
    email: '',
    name: '',
    position: '',
    phone_number: '',
    business_number: '',
    company_name: '',
    representative_name: '',
    address: '',
    company_phone_number: '',
    business_type: '',
    reg_date: '',
    sms_kakao_agree: 0,
    license_names: [],
    subscription_end: '',
    member_of_manager: ''
  },

  create_member: {
    is_login: false,
    subscription: '비즈니스',
    fax_number: '',
    representative_phone: '',
    password: '',
    user_id: 0,
    email: '',
    name: '',
    position: '',
    phone_number: '',
    business_number: '',
    company_name: '',
    representative_name: '',
    address: '',
    company_phone_number: '',
    business_type: '',
    reg_date: '',
    sms_kakao_agree: 0,
    license_names: [],
    subscription_end: '',
    member_of_manager: ''
  }
};

export const testUserSlice = createSlice({
  name: 'testUserSlice',
  initialState: initialState,
  reducers: {
    updateMemberPageStatus: (state, action) => {
      state.status = action.payload;
    },
    resetTargetMember: (state) => {
      state.target_member = {
        is_login: false,
        subscription: '비즈니스',
        fax_number: '',
        representative_phone: '',
        password: '',
        user_id: 0,
        email: '',
        name: '',
        position: '',
        phone_number: '',
        business_number: '',
        company_name: '',
        representative_name: '',
        address: '',
        company_phone_number: '',
        business_type: '',
        reg_date: '',
        sms_kakao_agree: 0,
        license_names: [],
        subscription_end: '',
        member_of_manager: ''
      };
    },

    deleteSelectedLicense: (state, action) => {
      const array = [...state.selected_licenses];
      state.selected_licenses = array.filter((jtem) => {
        return jtem.license_name !== action.payload;
      });
    },

    updateSelectedLicenses: (state, action) => {
      const array = [...state.selected_licenses];

      let license_ctgry = '종합';
      let license_type = '';

      switch (action.payload) {
        case '토공사':
          license_ctgry = '전문';
          license_type = '지반조성ㆍ포장공사업';
          break;

        case '포장공사':
          license_ctgry = '전문';
          license_type = '지반조성ㆍ포장공사업';
          break;

        case '보링ㆍ그라우팅ㆍ파일공사':
          license_ctgry = '전문';
          license_type = '지반조성ㆍ포장공사업';
          break;

        case '실내건축공사':
          license_ctgry = '전문';
          license_type = '실내건축공사업';
          break;

        case '금속구조물ㆍ창호ㆍ온실공사':
          license_ctgry = '전문';
          license_type = '금속창호ㆍ지붕건축물조립공사업';
          break;

        case '지붕판금ㆍ건축물조립공사':
          license_ctgry = '전문';
          license_type = '금속창호ㆍ지붕건축물조립공사업';
          break;

        case '도장공사':
          license_ctgry = '전문';
          license_type = '도장ㆍ습식ㆍ방수ㆍ석공사업';
          break;

        case '습식ㆍ방수공사':
          license_ctgry = '전문';
          license_type = '도장ㆍ습식ㆍ방수ㆍ석공사업';
          break;

        case '석공사':
          license_ctgry = '전문';
          license_type = '도장ㆍ습식ㆍ방수ㆍ석공사업';
          break;

        case '조경식재공사':
          license_ctgry = '전문';
          license_type = '조경식재ㆍ시설물공사업';
          break;

        case '조경시설물설치공사':
          license_ctgry = '전문';
          license_type = '조경식재ㆍ시설물공사업';
          break;

        case '철근ㆍ콘크리트공사':
          license_ctgry = '전문';
          license_type = '철근ㆍ콘크리트공사업';
          break;

        case '구조물해체ㆍ비계공사':
          license_ctgry = '전문';
          license_type = '구조물해체ㆍ비계공사업';
          break;

        case '상ㆍ하수도설비공사':
          license_ctgry = '전문';
          license_type = '상ㆍ하수도설비공사업';
          break;

        case '철도ㆍ궤도공사':
          license_ctgry = '전문';
          license_type = '철도ㆍ궤도공사업';
          break;

        case '철강구조물공사':
          license_ctgry = '전문';
          license_type = '철강구조물공사업';
          break;

        case '수중공사':
          license_ctgry = '전문';
          license_type = '수중ㆍ준설공사업';
          break;

        case '준설공사':
          license_ctgry = '전문';
          license_type = '수중ㆍ준설공사업';
          break;

        case '승강기설치공사':
          license_ctgry = '전문';
          license_type = '승강기ㆍ삭도공사업';
          break;

        case '삭도설치공사':
          license_ctgry = '전문';
          license_type = '승강기ㆍ삭도공사업';
          break;
      }

      array.push({
        license_ctgry: license_ctgry,
        license_type: license_type,
        license_area: action.payload,
        license_name: action.payload
      });

      state.selected_licenses = array;
    },

    updateSignupError: (state, action) => {
      state.error = action.payload;
    },

    updateUser: (state, action) => {
      state.users = action.payload.data;
      state.user_total_count = action.payload.total_count;
    },

    updatePageLength(state, action) {
      state.page_length = action.payload;
    },

    updatePageNumbers(state, action) {
      state.page_numbers = action.payload;
    },

    updateTab(state, action) {
      state.tab = action.payload;
    },

    updateSort: (state, action) => {
      if (state.tab === '비즈니스') {
        state.sort.business.option = action.payload.option;
        state.sort.business.sort = action.payload.sort;
      }

      if (state.tab === '퍼스트') {
        state.sort.first.option = action.payload.option;
        state.sort.first.sort = action.payload.sort;
      }

      if (state.tab === '콘택스') {
        state.sort.contax.option = action.payload.option;
        state.sort.contax.sort = action.payload.sort;
      }
    },

    updateCreateMember: (state, action) => {
      state.create_member = action.payload;

      console.log(action.payload.license_names);
    },

    updateTargetMember: (state, action) => {
      state.target_member = action.payload;
    },

    updateOriginalTargetMember: (state, action) => {
      state.original_target_member = action.payload;
    },

    updateTargetMemberInformation: (
      state,
      action: { payload: { id: string; value: string | number } }
    ) => {
      const info = state.target_member;

      if (
        action.payload.id !== 'user_id' &&
        action.payload.id !== 'sms_kakao_agree' &&
        typeof action.payload.value === 'string'
      ) {
        info[action.payload.id] = action.payload.value.toString();

        state.target_member = info;
        return;
      }

      if (
        (action.payload.id === 'user_id' ||
          action.payload.id === 'sms_kakao_agree') &&
        typeof action.payload.value === 'number'
      ) {
        info[action.payload.id] = action.payload.value;

        state.target_member = info;
        return;
      }
    },

    updateCreateMemberInformation: (
      state,
      action: { payload: { id: string; value: string | number } }
    ) => {
      const info = state.create_member;

      if (
        action.payload.id !== 'user_id' &&
        action.payload.id !== 'sms_kakao_agree' &&
        typeof action.payload.value === 'string'
      ) {
        info[action.payload.id] = action.payload.value.toString();

        state.create_member = info;
        return;
      }

      if (
        (action.payload.id === 'user_id' ||
          action.payload.id === 'sms_kakao_agree') &&
        typeof action.payload.value === 'number'
      ) {
        info[action.payload.id] = action.payload.value;
        state.create_member = info;
        return;
      }
    },

    updateManager: (state, action) => {
      state.manager = action.payload;
      state.target_member.manager = action.payload;
    },

    resetCreateMemberInformation: (state) => {
      state.selected_licenses = [];
      state.create_member = {
        is_login: false,
        subscription_end: '',
        subscription: '비즈니스',
        fax_number: '',
        representative_phone: '',
        password: '',
        user_id: 0,
        email: '',
        name: '',
        position: '',
        phone_number: '',
        business_number: '',
        company_name: '',
        representative_name: '',
        address: '',
        company_phone_number: '',
        business_type: '',
        reg_date: '',
        sms_kakao_agree: 0,
        license_names: [],
        member_of_manager: ''
      };
    }
  }
});

export const {
  deleteSelectedLicense,
  updateSelectedLicenses,
  updateCreateMember,
  updateMemberPageStatus,
  updateSignupError,
  updateManager,
  updateCreateMemberInformation,
  resetCreateMemberInformation,
  updateOriginalTargetMember,
  updateTargetMemberInformation,
  updateTargetMember,
  updateTab,
  updateUser,
  updatePageLength,
  updatePageNumbers,
  resetTargetMember
} = testUserSlice.actions;

export default testUserSlice.reducer;
