import TitleCard from '../../components/Cards/TitleCard';
import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import {
  ColProps,
  ManagerGrid,
  ManagerGridDetailSection,
  ManagerGridRow,
  ManagerGridButton,
  ManagerGridRowSection
} from '../../components/GridType';
import { useSelector, useDispatch } from 'react-redux';

import { getManagerDivision, getAccess } from '../../utils/user_API';
import { StoreState } from '../../app/store';
import {
  resetCreateMemberInformation,
  SortType,
  updateManager,
  updateOriginalTargetMember,
  updatePageLength,
  updatePageNumbers,
  updateTargetMember,
  updateUser,
  updateMemberPageStatus,
  UserType,
  updateSelectedContents,
  resetTargetManager
} from '../../app/slice/managerManagementSlice';
import BoardSearchHeader from '../../components/BoardSearchHeader';
import {
  calculatePagination,
  createPaginationSize
} from '../../utils/common_API';
import BoardPagenation from '../../components/BoardPagenation';

import { MemberDetailSection } from './components/MemberDetailSection';
import { CreateSection } from './components/CreateSection';
import { PencilIcon } from '@heroicons/react/16/solid';
import { resetTargetMember } from '../../app/slice/memberManagementSlice';
import { searchMembers } from './tools/content_management';

const Grid_COL: ColProps[] = [
  {
    title: '이름',
    id: 'name',
    width: 20,
    textAlign: 'left',
    sort: true,
    search: true
  },
  {
    title: '이메일',
    id: 'm.email',
    width: 20,
    textAlign: 'left',
    sort: true,
    search: true
  },
  {
    title: '회사명',
    id: 'company_name',
    width: 15,
    textAlign: 'left',
    sort: true,
    search: true
  },
  {
    title: '전화번호',
    id: 'phone_number',
    width: 15,
    textAlign: 'left',
    sort: true,
    search: true
  },
  {
    title: '가입 날짜',
    id: 'reg_date',
    width: 15,
    textAlign: 'left',
    sort: true,
    search: false
  },
  {
    title: '상세보기',
    id: '',
    width: 15,
    textAlign: 'center',
    sort: false,
    search: false
  }
];

function ManagerManagement() {
  const dispatch = useDispatch();
  const members = useSelector((state: StoreState) => state.managerManagement);
  console.log(members);
  const user: UserType[] = members.users;
  const user_total_count: number = members.user_total_count;
  const page_length: number = members.page_length;
  const page_numbers: number[] = members.page_numbers;

  const [open, setOpen] = useState({ index: 0, open: false });
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(10);

  const [searchQuery, setSearchQuery] = useState({
    category: 'name',
    query: ''
  });

  const [sort, setSort] = useState<SortType>({
    option: 'reg_date',
    sort: 'DESC'
  });

  async function initMembers() {
    dispatch(resetTargetMember());
    const result = await searchMembers(offset, limit, sort, searchQuery);

    if (result!.status === 'success') {
      dispatch(updateUser(result!.data));
      const length = calculatePagination(result!.data.total_count, limit);
      const array = createPaginationSize(offset, length);
      dispatch(updatePageLength(length));
      dispatch(updatePageNumbers(array));
    }

    if (result!.status === 'failed') {
      dispatch(
        updateUser({
          data: [],
          total_count: 0
        })
      );
    }
  }

  function onPrevious() {
    dispatch(updateMemberPageStatus('read'));
  }

  useEffect(() => {
    initMembers();
    setOpen({ index: 0, open: false });
  }, [
    offset,
    limit,
    sort.option,
    sort.sort,
    searchQuery.query,
    searchQuery.query,
    searchQuery.query,
    members.status
  ]);

  function clickSort(id: string) {
    if (id !== sort.option) {
      setSort({
        ...sort,
        option: id,
        sort: 'DESC'
      });
    }

    if (id === sort.option && sort.sort === 'DESC') {
      setSort({
        ...sort,
        option: id,
        sort: 'ASC'
      });
    }

    if (id === sort.option && sort.sort === 'ASC') {
      setSort({
        ...sort,
        option: id,
        sort: 'DESC'
      });
    }
  }

  async function loadContentsAccess(id: number) {
    const result = await getAccess(id);

    if (result.length > 0) {
      if (result[0].access.length > 0) {
        result[0].access.forEach((item: string) => {
          dispatch(updateSelectedContents(item));
          console.log(item);
        });
      }
    }
  }

  async function loadManagerDivision(id: number) {
    const result = await getManagerDivision(id);

    dispatch(
      updateManager(result.length > 0 ? result[0].manager_division : '')
    );
  }

  function searchData(e: React.ChangeEvent<HTMLInputElement>) {
    const query = { ...searchQuery };
    query.query = e.target.value;
    setSearchQuery(query);
  }

  function selectCategory(e: React.ChangeEvent<HTMLInputElement>) {
    const query = { ...searchQuery };
    query.category = e.target.value;
    setSearchQuery(query);
  }

  async function openDetailSection(index: number, item: UserType) {
    dispatch(resetTargetManager());
    dispatch(updateTargetMember(item));
    dispatch(updateOriginalTargetMember(item));

    await loadManagerDivision(item.user_id);
    await loadContentsAccess(item.user_id);

    if (index === open.index) {
      if (open.open) {
        setOpen({ index: index, open: false });
      }

      if (!open.open) {
        setOpen({ index: index, open: true });
      }
    }

    if (index !== open.index) {
      setOpen({ index: index, open: true });
    }
  }

  function string_to_searchQuery() {
    return searchQuery.query;
  }

  return (
    <>
      <Grid spacing={2}>
        {members.status === 'read' && (
          <TitleCard title={'관리자 계정 관리'}>
            <BoardSearchHeader
              limit={limit}
              setLimit={setLimit}
              query={string_to_searchQuery()}
              onInput={(e) => {
                searchData(e);
              }}
              onSelect={(e) => {
                selectCategory(e);
              }}
              col={Grid_COL}
              defaultColumn="name"
              title="관리자"
              total_count={user_total_count}
              limitOption={[10, 50, 100]}
            />

            <ManagerGrid size="large" col={Grid_COL} sortFunction={clickSort}>
              {user.length === 0 && (
                <div
                  style={{
                    padding: '150px 0',
                    textAlign: 'center'
                  }}
                >
                  데이터가 존재하지 않습니다.
                </div>
              )}
              {user.length > 0 &&
                user.map((item, index) => {
                  return (
                    <>
                      <ManagerGridRowSection key={index}>
                        <ManagerGridRow
                          size="large"
                          onClick={() => {
                            openDetailSection(index, item);
                          }}
                          width={20}
                        >
                          {item.name}
                        </ManagerGridRow>
                        <ManagerGridRow
                          size="large"
                          onClick={() => {
                            openDetailSection(index, item);
                          }}
                          width={20}
                        >
                          {item.email}
                        </ManagerGridRow>
                        <ManagerGridRow
                          size="large"
                          onClick={() => {
                            openDetailSection(index, item);
                          }}
                          width={15}
                        >
                          {item.company_name}
                        </ManagerGridRow>
                        <ManagerGridRow
                          size="large"
                          onClick={() => {
                            openDetailSection(index, item);
                          }}
                          width={15}
                        >
                          {item.phone_number}
                        </ManagerGridRow>
                        <ManagerGridRow
                          size="large"
                          onClick={() => {
                            openDetailSection(index, item);
                          }}
                          width={15}
                        >
                          {item.reg_date}
                        </ManagerGridRow>
                        <ManagerGridRow
                          size="large"
                          onClick={() => {
                            openDetailSection(index, item);
                          }}
                          width={15}
                          align="center"
                        >
                          <ManagerGridButton type="active">
                            상세보기
                          </ManagerGridButton>
                        </ManagerGridRow>
                      </ManagerGridRowSection>
                      <ManagerGridDetailSection
                        open={open.index === index && open.open === true}
                      >
                        <MemberDetailSection
                          closeDetailSection={() => {
                            setOpen({
                              index: index,
                              open: false
                            });
                          }}
                          searchMembers={() => {
                            initMembers();
                          }}
                        />
                      </ManagerGridDetailSection>
                    </>
                  );
                })}

              <div
                style={{
                  display: 'flex',
                  marginTop: '30px',
                  justifyContent: 'end'
                }}
              >
                <ManagerGridButton
                  type="active"
                  onClick={() => {
                    dispatch(updateMemberPageStatus('write'));
                    dispatch(resetCreateMemberInformation);
                  }}
                >
                  <PencilIcon style={{ width: '20px' }} />
                  작성
                </ManagerGridButton>
              </div>

              <BoardPagenation
                offset={offset}
                pageNumbers={page_numbers}
                pageLength={page_length}
                setOffset={setOffset}
              />
            </ManagerGrid>
          </TitleCard>
        )}

        {members.status === 'write' && (
          <TitleCard title="관리자 작성" previous={onPrevious}>
            <ManagerGridDetailSection open={true}>
              <CreateSection />
            </ManagerGridDetailSection>
          </TitleCard>
        )}

        {members.status === 'modify' && (
          <TitleCard title="관리자 수정" previous={onPrevious}>
            <ManagerGridDetailSection open={true}>
              <CreateSection />
            </ManagerGridDetailSection>
          </TitleCard>
        )}
      </Grid>
    </>
  );
}

export default ManagerManagement;
