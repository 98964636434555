import axios from 'axios';
import { MemberType } from '../../../app/slice/memberManagementSlice';
import { insertSubscription } from './insertSubscription';

export async function insertMember(
  params: MemberType,
  licenses: {
    license_ctgry: string;
    license_type: string;
    license_area: string;
    license_name: string;
  }[]
) {
  const date = new Date();

  const optionData = {
    member: {
      email: params.email,
      password: params.password,
      name: params.name,
      position: params.position,
      phone_number: params.phone_number,
      business_number: params.business_number,
      company_name: params.company_name,
      representative_name: params.representative_name,
      address: params.address,
      company_phone_number: params.company_phone_number,
      business_type: '유저',
      reg_date: `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`,
      sms_kakao_agree: 0,
      fax_number: params.fax_number,
      representative_phone: params.representative_phone
    },
    licenses: licenses
  };

  const option = {
    url: 'https://cpi.contax.ai/members/add_member',
    data: optionData,
    method: 'POST',
    header: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data'
    }
  };

  try {
    const response = await axios(option);
    if (response.status === 200) {
      if (response.data.error === 'The company name already exists') {
        alert('이미 존재하는 회사명입니다.');
        return;
      }

      if (response.data.error === 'The business number already exists') {
        alert('이미 존재하는 사업자 번호입니다.');
        return;
      }

      if (response.data.error === 'The email already exists') {
        alert('이미 존재하는 이메일입니다.');
        return;
      }

      alert('회원이 추가되었습니다.');

      await insertSubscription(
        params.email,
        params.subscription,
        params.subscription_end
      );
      return true;
    }
  } catch (e: any) {
    if (
      e.response.data.detail[0].msg ===
      'ensure this value has at least 1 characters'
    ) {
      alert('입력란에 적어도 한가지 이상의 문자를 적어주세요');
      return;
    }

    if (
      e.response.data.detail[0].msg === 'value is not a valid email address'
    ) {
      alert('이메일 형식을 맞게 작성해주세요');
      return;
    }
  }
}
