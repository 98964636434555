import React, { useEffect, useState } from 'react';
import TitleCard from '../../components/Cards/TitleCard';
import axios from 'axios';
import { Button, Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { setPageTitle } from '../../app/slice/headerSlice';
import {
  AdminGrid,
  AdminGridCols,
  AdminGridExpertRows,
  AdminGridThirdManagerRows
} from '../sales/component/adminGrid';
import { ExpertDetailContents } from '../sales/component/components';
import BoardPagenation from '../../components/BoardPagenation';
import {
  calculatePagination,
  createPaginationSize
} from '../../utils/common_API';
import {
  updateDetailOn,
  updateExpertInquiryTarget
} from '../../app/slice/expertInquirySlice';

function ExpertInquiry() {
  const { target, detailOn } = useSelector((state) => state.expertInquiry);
  const dispatch = useDispatch();
  const [inquiries, setInquiries] = useState([
    {
      inquire_id: 1,
      ctgr: '',
      title: '',
      text: '',
      reg_date: '',
      file_path: '',
      email: ''
    }
  ]);

  const [, setCategory] = useState([]);

  const cols = [
    { title: '선택', width: 5 },
    { title: '문의 번호', width: 10 },
    { title: '이름', width: 13 },
    { title: '이메일', width: 20 },
    { title: '카테고리', width: 10 },
    { title: '제목', width: 20 },
    { title: '작성일자', width: 12 },
    { title: '상세보기', width: 10 }
  ];

  const thirdManagercols = [
    { title: '선택', width: 20 },
    { title: '이름', width: 40 },
    { title: '이메일', width: 40 }
  ];

  const [, setUserIdList] = useState([]);
  const [offset, setOffset] = useState(0);
  const [limit] = useState(10);
  const [, setTotalCount] = useState(0);
  const [pageLength, setPageLength] = useState(0);
  const [pageNumbers, setPageNumbers] = useState([1]);

  const params = {
    database: '전문가 문의',
    categories: ['writter_to_expert'],
    search_queries: [false],
    offset: offset * limit,
    limit: limit,
    sort_option: 'question_date',
    sort: 'DESC'
  };

  useEffect(() => {
    dispatch(setPageTitle({ title: '1:1 문의' }));
    loadCategory();
    loadSaleManager();
  }, []);

  useEffect(() => {
    getInquiryData(params);
  }, [offset]);

  // async function insertNotification(){
  //     const body = {
  //         notification_type: 'inquiry',
  //         title: title,
  //         content: content,
  //         target: userIdList
  //     };

  //     if(status === 'create'){
  //         const option = {
  //             url: 'https://cpi.contax.ai/insert_notification',
  //             method: 'POST',
  //             data:body,
  //             header:{
  //                 'Accept':'application/json',
  //                 'Content-Type':'application/json'
  //             }
  //         }

  //         axios(option).then(response => {
  //             if(response.status === 200){
  //                 return true;
  //             }else{
  //                 console.log(response);
  //             }
  //         });
  //     }
  // }

  // async function sendNotification(paramsToken){
  //     const body = {
  //         title: '[1:1문의] ' + title,
  //         body: stripHtmlAndLimitLength(content, 28) ,
  //         token: paramsToken
  //     };

  //     const option = {
  //         url: 'https://cpi.contax.ai/send_notification',
  //         method: 'POST',
  //         data:body,
  //         header:{
  //             'Accept':'application/json',
  //             'Content-Type':'application/json'
  //         }
  //     }

  //     axios(option).then(response => {
  //         if(response.status === 200){
  //             return true;
  //         }else{
  //             console.log(response);
  //         }
  //     });
  // }

  const [salesManager, setSalesManager] = useState([]);

  async function loadSaleManager() {
    let data = [];
    let userData = [];
    let accessData = [];
    let salesAdminData = [];

    const conbiOption = {
      url: 'https://cpi.contax.ai/members/all_members',
      method: 'GET',
      header: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    };

    const accessOption = {
      url: 'https://cpi.contax.ai/access',
      method: 'GET',
      header: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    };

    await axios(conbiOption).then((response) => {
      if (response.status === 200) {
        data = response.data;
      }
    });

    await axios(accessOption).then((response) => {
      if (response.status === 200) {
        accessData = response.data;
      }
    });

    data.forEach((item) => {
      let isAdmin = false;
      accessData.forEach((jtem) => {
        if (item.user_id === jtem.user_id) {
          if (jtem.manager_division === 'contact_sales') {
            const newObj = {
              ...item,
              name: item.name,
              is_admin: 'O',
              access: [],
              assignment: [],
              sales_member: jtem.sales_member,
              division: jtem.manager_division
            };
            salesAdminData.push(newObj);
          }

          isAdmin = true;
        }
      });

      if (isAdmin === false) {
        const object = {
          ...item,
          subscription_type: '미가입',
          subscription_end: '-'
        };
        userData.push(object);
      }
    });

    setSalesManager(salesAdminData);
  }

  async function getInquiryData(params) {
    const option = {
      url: 'https://cpi.contax.ai/get_board',
      method: 'POST',
      data: params,
      header: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    };

    try {
      const response = await axios(option);

      if (response.status === 200) {
        setInquiries(response.data.data);
        setTotalCount(response.data.total_count);
        const length = calculatePagination(response.data.total_count, limit);
        setPageLength(calculatePagination(response.data.total_count, limit));
        setPageNumbers(createPaginationSize(offset, length));

        return response.data;
      } else {
        return [];
      }
    } catch (error) {
      return [];
    }
  }

  async function getUserId(paramsArray) {
    const option = {
      url: 'https://cpi.contax.ai/members/all_members',
      method: 'GET',
      header: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    };

    await axios(option).then((response) => {
      const array = [];
      if (response.status === 200) {
        response.data.forEach((item) => {
          paramsArray.forEach((jtem) => {
            if (item.email === jtem) {
              array.push(item.user_id);
            }
          });
        });
      }

      setUserIdList(array);
    });
  }

  async function changeCategory(newCategory) {
    const option = {
      url: 'https://cpi.contax.ai/categories/inquiry',
      data: {
        text: newCategory
      },
      method: 'PUT',
      header: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    };

    axios(option).then((response) => {
      if (response.status === 200) {
        setCategory(newCategory);
      }
    });
  }

  async function sendAnswerToUser(id) {
    if (!window.confirm('정말 승인하시겠습니까?')) {
      return false;
    }

    const option = {
      url: 'https://cpi.contax.ai/send_answer_to_user',
      params: {
        id: id
      },
      method: 'PUT',
      header: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    };

    axios(option).then((response) => {
      if (response.status === 200) {
        const array = [...inquiries];

        array.forEach((item) => {
          if (item.id === id) {
            item.answer_status = 'complete';
          }
        });

        setInquiries(array);
        alert('성공적으로 승인되었습니다');
      }
    });
  }

  async function loadCategory() {
    const option = {
      url: 'https://cpi.contax.ai/categories/inquiry',
      method: 'GET',
      header: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    };

    axios(option).then((response) => {
      if (response.status === 200) {
        setCategory(response.data[0][0]);
      }
    });
  }

  const [selectedData, setSelectedData] = useState([]);
  const [selectedDetailData, setSelectedDetailData] = useState([]);
  const [selectedExpert, setSelectedExpert] = useState();

  async function updateQuestions() {
    if (selectedExpert === undefined) {
      alert('3차 관리자를 선택해주세요');
      return false;
    }

    if (selectedData.length === 0) {
      alert('답변 대기 문의를 선택해주세요');
      return false;
    }

    const option = {
      url: 'https://cpi.contax.ai/update_questions',
      method: 'PUT',
      data: {
        question_ids: selectedData,
        user_id: selectedExpert.user_id,
        name: selectedExpert.name
      },
      header: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    };

    await axios(option).then((response) => {
      if (response.status === 200) {
        alert('성공적으로 문의가 공유되었습니다.');

        const array = [...inquiries];

        selectedData.forEach((item) => {
          array.forEach((jtem) => {
            if (jtem.id === item) {
              jtem.writter_to_expert = true;
              jtem.expert_id = selectedExpert.user_id;
              jtem.expert_name = selectedExpert.name;
            }
          });
        });

        setInquiries(array);
        setSelectedExpert();
        setSelectedData([]);
      }
    });
  }

  return (
    <Grid container spacing={2} direction="column" justifyContent="center">
      <Grid item>
        {detailOn === false && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              gap: '20px'
            }}
          >
            <TitleCard title="문의글 공유">
              <div style={{ display: 'flex', gap: '20px' }}>
                <div style={{ width: '70%', minHeight: '500px' }}>
                  <AdminGrid>
                    <AdminGridCols
                      detailOn={detailOn}
                      setDetailOn={(params) => {
                        dispatch(updateDetailOn(params));
                      }}
                      columns={cols}
                    />
                    <AdminGridExpertRows
                      detailOn={detailOn}
                      setDetailOn={(params) => {
                        dispatch(updateDetailOn(params));
                      }}
                      data={inquiries}
                      selectedDetailData={selectedDetailData}
                      setSelectedDetailData={(params) => {
                        dispatch(updateExpertInquiryTarget(params));
                      }}
                      selectedData={selectedData}
                      setSelectedData={(params) => {
                        setSelectedData(params);
                      }}
                    />
                  </AdminGrid>

                  <BoardPagenation
                    offset={offset}
                    setOffset={setOffset}
                    pageNumbers={pageNumbers}
                    pageLength={pageLength}
                  />
                </div>

                <div
                  style={{
                    width: '30%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    alignItems: 'end',
                    minHeight: '500px'
                  }}
                >
                  <AdminGrid>
                    <AdminGridCols
                      detailOn={detailOn}
                      setDetailOn={(params) => {
                        dispatch(updateDetailOn(params));
                      }}
                      columns={thirdManagercols}
                    />
                    <AdminGridThirdManagerRows
                      detailOn={detailOn}
                      setDetailOn={(params) => {
                        dispatch(updateDetailOn(params));
                      }}
                      data={salesManager}
                      selectedData={selectedExpert}
                      setSelectedData={(params) => {
                        setSelectedExpert(params);
                      }}
                    />
                  </AdminGrid>

                  <Button
                    sx={{ width: '130px' }}
                    onClick={updateQuestions}
                    variant="contained"
                  >
                    공유
                  </Button>
                </div>
              </div>
              {/* Team Member list in table format loaded constant */}
            </TitleCard>
          </div>
        )}

        {detailOn === true && (
          <TitleCard title="문의글">
            <ExpertDetailContents
              setDetailOn={() => {
                dispatch(updateDetailOn(false));
              }}
              item={target}
            />
          </TitleCard>
        )}
      </Grid>
    </Grid>
  );
}

export default ExpertInquiry;
