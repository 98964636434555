import React,  { Suspense } from 'react';
import ReactDOM from 'react-dom'; // Changed line
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import store from './app/store'
import { Provider } from 'react-redux'
import SuspenseContent from './containers/SuspenseContent';
import { koKR, LocalizationProvider } from '@mui/x-date-pickers';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs'
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme(
  {
    palette:{
      primary: {main: '#1976d2'},
    },
    koKR,
  }
)

ReactDOM.render(
  <React.StrictMode>
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <ThemeProvider theme={theme}>
        <Suspense fallback={<SuspenseContent />}>
          <Provider store={store}>
            <App />
          </Provider>
        </Suspense>
      </ThemeProvider>

    </LocalizationProvider>
    
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();