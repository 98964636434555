import React, { ChangeEventHandler } from 'react';
import styled from 'styled-components';

const Input = styled.input`
  display: inline-block;
  border-radius: 5px;
  border: 2px solid #1976d2;
  width: 30px;
  height: 30px;
  text-align: center;
  font-size: 14px;
`;

interface Params {
  pageNumber: number;
  currentPage: number;
  onChange: (event: React.ChangeEvent<HTMLInputElement>, value: any) => void;
}

export default function PageNumber(props: Params) {
  const input_wrapper = {
    display: 'flex',
    justifyContents: 'center',
    alignItems: 'center',
    gap: '10px',
  };
  const input_style = {};

  const text_style = {
    fontSize: '14px',
  };

  return (
    <div style={input_wrapper}>
      <span style={text_style}>페이지</span>
      <Input
        value={props.currentPage}
        onChange={e => {
          e.target.value === ''
            ? props.onChange(e, 0)
            : props.onChange(e, e.target.value);
        }}
      />
      <span style={text_style}>/</span>
      <span style={text_style}>{props.pageNumber}</span>
    </div>
  );
}
