import TitleCard from '../../components/Cards/TitleCard';
import Button from '@mui/material/Button';
import { Grid, MenuItem, Select, Stack, TextField } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import { useEffect, useState } from 'react';

const contentsColumns = [
  { field: 'user_id', headerName: '회원번호', width: 70 },
  { field: 'name', headerName: '이름', width: 130 },
  { field: 'email', headerName: '이메일', width: 130 },
  {
    field: 'password',
    headerName: '비밀번호',
    width: 130,
    renderCell: params => {
      return '*'.repeat(params.value.length);
    },
  },
  { field: 'position', headerName: '직급', width: 130 },
  { field: 'company_name', headerName: '회사', width: 130 },
  { field: 'phone_number', headerName: '휴대폰번호', width: 130 },
  { field: 'business_number', headerName: '사업자번호', width: 130 },
  { field: 'representative_name', headerName: '대표자명', width: 130 },
  { field: 'address', headerName: '주소', width: 130 },
  { field: 'company_phone_number', headerName: '회사 전화번호', width: 130 },
  { field: 'reg_date', headerName: '가입일', width: 130 },
  { field: 'smsAgree', headerName: '수신동의', width: 130 },
  { field: 'is_admin', headerName: '어드민 여부', width: 130 },
  { field: 'sales_member', headerName: '관리 회원', width: 130 },
];

function SalesManagement() {
  const [salesDivision, setSalesDivision] = useState('sales');

  const [data, setData] = useState([
    {
      user_id: 0,
      name: '',
      email: '',
      password: '',
      position: '',
      company_name: '',
      phoneNumber: '',
      businessNumber: '',
      representative: '',
      address: '',
      companyPhoneNumber: '',
      regdate: '',
      smsAgree: '',
      is_admin: false,
      access: [],
      assignment: [],
    },
  ]);

  const [id, setId] = useState(0);
  const [status, setStatus] = useState('');
  const [company, setCompany] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [checked, setChecked] = useState(false);
  const [password, setPassword] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [access, setAccess] = useState([]);
  const [position, setPosition] = useState('');
  const [isTopAdmin, setIsTopAdmin] = useState('');
  const [checkedData, setCheckedData] = useState([]);
  const [salesAdminData, setSalesAdminData] = useState([]);
  const [myAssignment, setMyAssignment] = useState([]);
  const [selectionModel, setSelectionModel] = useState([]);
  const [accessData, setAccessData] = useState([]);

  useEffect(() => {
    const localAccess = localStorage.getItem('access');
    const localMember = localStorage.getItem('assignment_member');
    const array = localAccess.split(',').map(item => item.trim());
    const member = localMember.split(',').map(item => item.trim());

    if (array.includes('최고 관리자')) {
      setIsTopAdmin(true);
    }

    setMyAssignment(member);
    getUserData(member);
  }, []);

  async function updateUserData() {
    if (status !== 'change') {
      setStatus('change');
    } else {
      let optionData = {
        user_id: id,
        name: name,
        email: email,
        company: company,
        password: password,
        phoneNumber: phoneNumber,
        members_list: selectionModel,
        division: salesDivision,
      };

      let conbiOption = {
        url: 'https://cpi.contax.ai/mng_page_user',
        method: 'PUT',
        data: optionData,
        header: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      };

      await axios(conbiOption).then(response => {
        if (response.status === 200) {
          const obj = [...data];
          obj.forEach((item, index) => {
            if (item.user_id === id) {
              obj[index].name = name;
              obj[index].email = email;
              obj[index].company_name = company;
              obj[index].password = password;
              obj[index].phone_number = phoneNumber;
            }
          });

          setData(obj);
          alert('회원이 수정되었습니다.');
        }
      });
    }
  }

  async function deleteUserData() {
    if (!window.confirm('정말 삭제하시겠습니까?')) {
      return;
    } else {
      const deleteOption = {
        url: 'https://cpi.contax.ai/delete_manager_member',
        params: {
          id: id,
          email: email,
        },
        method: 'DELETE',
        header: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      };

      axios(deleteOption).then(response => {
        if (response.status === 200) {
          const array = [...data];

          array.forEach((item, index) => {
            if (item.id === id) {
              array.splice(index, 1);
            }
          });

          setData(array);
          alert('회원이 삭제되었습니다.');
          window.location.reload();
        }
      });
    }
  }

  async function getUserData() {
    let data = [];
    let userData = [];
    let accessData = [];
    let salesAdminData = [];

    const conbiOption = {
      url: 'https://cpi.contax.ai/members/all_members',
      method: 'GET',
      header: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    };

    const accessOption = {
      url: 'https://cpi.contax.ai/access',
      method: 'GET',
      header: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    };

    await axios(conbiOption).then(response => {
      if (response.status === 200) {
        data = response.data;
      }
    });

    await axios(accessOption).then(response => {
      if (response.status === 200) {
        accessData = response.data;
      }
    });

    data.forEach((item, index) => {
      let isAdmin = false;
      accessData.forEach(jtem => {
        if (item.user_id === jtem.user_id) {
          if (
            jtem.manager_division === 'sales' ||
            jtem.manager_division === 'sales_second' ||
            jtem.manager_division === 'contact_sales'
          ) {
            const newObj = {
              ...item,
              is_admin: 'O',
              manager_division: jtem.manager_division,
              access: [],
              assignment: [],
              sales_member: jtem.sales_member,
            };
            salesAdminData.push(newObj);
          }

          isAdmin = true;
        }
      });

      if (isAdmin === false) {
        const object = {
          ...item,
          subscription_type: '미가입',
          subscription_end: '-',
        };
        userData.push(object);
      }
    });

    setData(userData);
    setAccessData(accessData);
    setSalesAdminData(salesAdminData);

    const anotherCheckedData = [...data];

    setCheckedData(anotherCheckedData);
  }

  async function insertMember(e) {
    if (status !== 'create') {
      setStatus('create');
    } else {
      const date = new Date();

      const optionData = {
        member: {
          email: email,
          password: password,
          name: name,
          position: position,
          phone_number: phoneNumber,
          business_number: phoneNumber,
          company_name: company,
          representative_name: 'Null',
          address: 'Null',
          company_phone_number: '0000',
          business_type: 'Null',
          reg_date: `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`,
          sms_kakao_agree: 0,
        },

        licenses: [],
      };

      let option = {
        url: 'https://cpi.contax.ai/members/add_member',
        data: optionData,
        method: 'POST',
        header: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
        },
      };

      await axios(option).then(response => {
        if (response.status === 200) {
          if (response.data.error === 'The company name already exists') {
            alert('이미 존재하는 회사명입니다.');
            return;
          }

          if (response.data.error === 'The business number already exists') {
            alert('이미 존재하는 전화번호입니다.');
            return;
          }

          if (response.data.error === 'The email aleready exists') {
            alert('이미 존재하는 이메일입니다.');
            return;
          }

          const array = [...data];
          array.push({
            user_id: response.data.user_id,
            name: name,
            email: email,
            password: password,
            position: position,
            phone_number: phoneNumber,
            business_number: phoneNumber,
            company_name: company,
            representative_name: 'Null',
            address: 'Null',
            company_phone_number: '0000',
            business_type: 'Null',
            reg_date: `${date.getFullYear()}-${date.getMonth()}-${date.getDate()}`,
            sms_kakao_agree: 0,
          });

          createAdmin(response.data.user_id);
          getUserData();
        }
      });
    }
  }

  async function createAdmin(id) {
    const option = {
      url: 'https://cpi.contax.ai/insert_admin',
      params: {
        id: id,
        division: salesDivision,
      },
      method: 'POST',
      header: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
      },
    };

    await axios(option).then(response => {
      if (response.status === 200) {
        alert('회원이 추가되었습니다.');
        window.location.reload();
        setStatus('');
      }
    });
  }

  const contentsHandleSelection = newSelection => {
    // newSelection은 선택된 행의 id 배열입니다.
    const selectedData = salesAdminData.filter(row =>
      newSelection.includes(row.user_id),
    );

    setId(selectedData[0].user_id);
    setCompany(selectedData[0].company_name);
    setEmail(selectedData[0].email);
    setChecked(selectedData[0].is_admin === 'O' ? true : false);
    setName(selectedData[0].name);
    setPhoneNumber(selectedData[0].phone_number);
    setAccess(selectedData[0].access);
    setPassword(selectedData[0].password);
    setSelectionModel(selectedData[0].sales_member);
    setSalesDivision(selectedData[0].manager_division);
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={isTopAdmin ? 12 : 9}>
          {isTopAdmin && (
            <TitleCard title={'영업 관리자 현황'}>
              <DataGrid
                rows={salesAdminData}
                columns={contentsColumns}
                getRowId={row => row.user_id}
                onRowSelectionModelChange={newSelection => {
                  contentsHandleSelection(newSelection);
                }}
                initialState={{
                  pagination: {
                    paginationModel: { page: 0, pageSize: 5 },
                  },
                }}
                pageSizeOptions={[10]}
              />
            </TitleCard>
          )}

          {(status === 'create' || status === 'change') && (
            <Grid container direction="row" gap={2}>
              <Grid item xs={5.8}>
                <TitleCard title="회원 관리">
                  <Stack>
                    <TextField
                      sx={{ marginBottom: '20px' }}
                      id="standard-basic"
                      label="회원 이름"
                      variant="standard"
                      value={name}
                      onChange={e => {
                        setName(e.target.value);
                      }}
                    />
                    <TextField
                      sx={{ marginBottom: '20px' }}
                      id="standard-basic"
                      label="이메일"
                      variant="standard"
                      value={email}
                      onChange={e => {
                        setEmail(e.target.value);
                      }}
                    />
                    <TextField
                      sx={{ marginBottom: '20px' }}
                      id="standard-basic"
                      type="password"
                      label="비밀번호"
                      variant="standard"
                      value={password}
                      onChange={e => {
                        setPassword(e.target.value);
                      }}
                    />
                    <TextField
                      sx={{ marginBottom: '20px' }}
                      id="standard-basic"
                      label="회사"
                      variant="standard"
                      value={company}
                      onChange={e => {
                        setCompany(e.target.value);
                      }}
                    />
                    <TextField
                      sx={{ marginBottom: '20px' }}
                      id="standard-basic"
                      label="휴대폰번호"
                      variant="standard"
                      value={phoneNumber}
                      onChange={e => {
                        setPhoneNumber(e.target.value);
                      }}
                    />
                    {status === 'create' && (
                      <TextField
                        sx={{ marginBottom: '20px' }}
                        id="standard-basic"
                        label="직위"
                        variant="standard"
                        value={position}
                        onChange={e => {
                          setPosition(e.target.value);
                        }}
                      />
                    )}
                    {console.log(salesDivision)}
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={salesDivision}
                      label="SalesDivision"
                      onChange={event => {
                        setSalesDivision(event.target.value);
                      }}
                    >
                      <MenuItem value="sales">1차 관리자</MenuItem>
                      <MenuItem value="sales_second">2차 관리자</MenuItem>
                      <MenuItem value="contact_sales">
                        방문 영업 관리자
                      </MenuItem>
                    </Select>
                  </Stack>
                </TitleCard>
              </Grid>
            </Grid>
          )}

          {isTopAdmin && (
            <Grid item mt={3}>
              <Grid
                container
                justifyContent="flex-end"
                direction="row"
                spacing={2}
              >
                {status !== 'change' && (
                  <Grid item>
                    <Button
                      disabled={status === 'change'}
                      variant="contained"
                      onClick={insertMember}
                    >
                      회원 추가
                    </Button>
                  </Grid>
                )}
                {status !== 'create' && (
                  <Grid item>
                    <Button
                      disabled={id === 0}
                      variant="contained"
                      onClick={updateUserData}
                    >
                      회원 수정
                    </Button>
                  </Grid>
                )}
                {(status === 'change' || status === 'create') && (
                  <Grid item>
                    <Button
                      variant="outlined"
                      onClick={() => {
                        setStatus('');
                      }}
                    >
                      취소
                    </Button>
                  </Grid>
                )}
                {status !== 'create' && (
                  <Grid item>
                    <Button
                      disabled={id === 0}
                      sx={{ backgroundColor: 'red' }}
                      variant="contained"
                      onClick={deleteUserData}
                    >
                      회원 삭제
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </>
  );
}

export default SalesManagement;
