import TitleCard from '../../../components/Cards/TitleCard';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  ManagerSection,
  SalesSection,
  TableColumn,
  TableColumnSection,
  TableManagerContactRowSection,
  TableMemoSection,
  TitleSection,
} from '../../sales/component/components';
import { MenuItem, Select, TextField } from '@mui/material';
import {
  getJonghapSalesAll,
  shareToThirdManager,
} from '../../../utils/sales_API';
import { CategorySection } from './CategorySectoin';

function Contact({ contactAdminData }) {
  const [data, setData] = useState([]);
  const [selectedFirstData, setSelectedFirstData] = useState([]);
  const [checkSecondManager, setCheckSecondManager] = useState('');
  const [checkList, setCheckList] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [order, setOrder] = useState({
    selected: 'second_saved_date',
    ncrareadetailname: 'ASC',
    second_saved_date: 'ASC',
    ncrgskname: 'ASC',
    writter_name: 'ASC',
    second_manager: 'ASC',
  });
  const [column, setColumn] = useState('second_saved_date');
  const [searchItem, setSearchItem] = useState('ncrgskname');
  const [searchValue, setSearchValue] = useState('');

  async function loadCheckList() {
    const option = {
      url: 'https://cpi.contax.ai/load_sales_check_item_list',
      method: 'GET',
      header: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    };

    try {
      const response = await axios(option);
      if (response.status === 200) {
        const firstArray = response.data.filter(
          item => item.division === 'second',
        );
        const itemMap = {};
        const rootItems = {}; // 최상위 노드를 저장할 객체

        // 아이템을 id를 키로 해서 저장하고, 각 아이템에 자식을 추가할 children 객체 초기화
        firstArray.forEach(item => {
          itemMap[item.id] = { ...item, children: {} };
        });

        // 부모-자식 관계 설정
        Object.values(itemMap).forEach(item => {
          if (item.parent && itemMap[item.parent]) {
            itemMap[item.parent].children[item.name] =
              itemMap[item.id].children;
          } else {
            rootItems[`${item.name}_${item.type}`] = itemMap[item.id].children;
          }
        });

        // 모든 항목에 대해 false 값 할당
        const assignFalse = obj => {
          Object.keys(obj).forEach(key => {
            if (Object.keys(obj[key]).length === 0) {
              if (key.includes('check')) {
                obj[key] = false;
              } else {
                obj[key] = '';
              }
            } else {
              assignFalse(obj[key]);
            }
          });
        };

        assignFalse(rootItems);
        return rootItems;
      } else {
        console.log('Non-200 response received: ', response.status);
        return null;
      }
    } catch (error) {
      console.error('Error loading checklist:', error);
    }
  }

  async function shareData() {
    await shareToThirdManager(
      selectedFirstData,
      checkSecondManager,
      data,
      selectedIndex,
      checkList,
    );
    init();
  }

  async function init() {
    setData([]);
    const jonghap_array = await getJonghapSalesAll(
      'second_saved_date',
      'DESC',
      '',
      searchItem,
    );
    setData(jonghap_array);
  }

  useEffect(() => {
    init();

    loadCheckList().then(transformedData => {
      setCheckList(transformedData);
    });
  }, []);

  return (
    <TitleCard title="영업">
      <CategorySection />
      <SalesSection>
        <ManagerSection>
          <h1
            style={{
              fontWeight: 'bold',
              fontSize: '18px',
              marginBottom: '20px',
            }}
          >
            스케줄 완료 데이터
          </h1>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: '20px',
            }}
          >
            <span
              style={{
                padding: '5px',
                margin: '10px 0 10xp 20px',
                marginRight: '20px',
                paddingRight: '10px',
                borderRight: '1px solid #ddd',
                color: '#555',
                fontSize: '14px',
                width: '120px',
              }}
            >
              검색
            </span>
            <Select
              style={{ height: '30px', background: '#fff' }}
              defaultValue="ncrgskname"
              onChange={e => {
                setSearchItem(e.target.value);
              }}
              sx={{ width: '100px', padding: '0' }}
            >
              <MenuItem value="ncrgskname">상호명</MenuItem>
              <MenuItem value="ncrofftel">전화번호</MenuItem>
              <MenuItem value="address">지역</MenuItem>
            </Select>

            <TextField
              variant="outlined"
              onChange={e => {
                setSearchValue(e.target.value);
                // 타이머 초기화
                if (window.searchTimer) {
                  clearTimeout(window.searchTimer);
                }

                // 사용자가 입력을 멈춘 후 1초 뒤에 selectedSearch 실행
                window.searchTimer = setTimeout(async () => {
                  const result = await getJonghapSalesAll(
                    column,
                    order,
                    e.target.value,
                    searchItem,
                  );
                  setData(result);
                }, 1000); // 1초 대기
              }}
              sx={{ marginLeft: '20px', fontSize: '14px' }}
            />

            {/* <button onClick={()=>{selectedSearch(1)}} style={{"display": "block", "marginLeft": "30px", "backgroundColor": "#999", "color" : "#fff", "borderRadius": "5px", "width": "100px", "height": "30px"}} >검색</button> */}
          </div>
          <TableColumnSection>
            <TableColumn width={7}>선택</TableColumn>
            <TableColumn
              onClick={() => {
                getJonghapSalesAll('j_address', order, searchValue, searchItem);

                const object = { ...order };
                object['selected'] = 'j_address';
                if (object['j_address'] === 'DESC') {
                  object['j_address'] = 'ASC';
                } else {
                  object['j_address'] = 'DESC';
                }
                setOrder(object);
                setColumn('j_address');
              }}
              width={15}
            >
              지역{' '}
              <span className="material-symbols-outlined">
                {order['ncrareadetailname'] === 'ASC'
                  ? 'expand_more'
                  : 'expand_less'}
              </span>{' '}
            </TableColumn>
            <TableColumn width={10}>
              DB
              <span className="material-symbols-outlined"></span>
            </TableColumn>

            <TableColumn
              onClick={() => {
                getJonghapSalesAll('s_address', order, searchValue, searchItem);

                const object = { ...order };
                object['selected'] = 's_address';
                if (object['s_address'] === 'DESC') {
                  object['s_address'] = 'ASC';
                } else {
                  object['s_address'] = 'DESC';
                }
                setOrder(object);
                setColumn('s_address');
              }}
              width={10}
            >
              전화번호
              <span className="material-symbols-outlined">
                {order['ncrofftel'] === 'ASC' ? 'expand_more' : 'expand_less'}
              </span>{' '}
            </TableColumn>
            <TableColumn
              onClick={() => {
                getJonghapSalesAll(
                  'second_saved_date',
                  order,
                  searchValue,
                  searchItem,
                );

                const object = { ...order };

                object['selected'] = 'second_saved_date';

                if (object['second_saved_date'] === 'DESC') {
                  object['second_saved_date'] = 'ASC';
                } else {
                  object['second_saved_date'] = 'DESC';
                }

                setOrder(object);
                setColumn('second_saved_date');
              }}
              width={15}
            >
              저장 날짜{' '}
              <span className="material-symbols-outlined">
                {order['second_saved_date'] === 'ASC'
                  ? 'expand_more'
                  : 'expand_less'}
              </span>{' '}
            </TableColumn>
            <TableColumn
              onClick={() => {
                getJonghapSalesAll(
                  'ncrgskname',
                  order,
                  searchValue,
                  searchItem,
                );

                const object = { ...order };

                object['selected'] = 'ncrgskname';

                if (object['ncrgskname'] === 'DESC') {
                  object['ncrgskname'] = 'ASC';
                } else {
                  object['ncrgskname'] = 'DESC';
                }
                setOrder(object);
                setColumn('ncrgskname');
              }}
              width={15}
            >
              상호명{' '}
              <span className="material-symbols-outlined">
                {order['ncrgskname'] === 'ASC' ? 'expand_more' : 'expand_less'}
              </span>{' '}
            </TableColumn>

            <TableColumn width={15}>방문 영업사원</TableColumn>
            <TableColumn width={15}>상세내용</TableColumn>
          </TableColumnSection>

          {data.length === 0 && (
            <span
              style={{
                textAlign: 'center',
                fontSize: '14px',
                color: '#333',
                paddingTop: '50px',
              }}
            >
              {' '}
              메모가 존재하지 않습니다.
            </span>
          )}

          {data.map((item, index) => {
            return (
              <TableMemoSection
                selectedFirstData={selectedFirstData}
                setSelectedFirstData={() => {
                  const array = [...selectedFirstData];
                  if (array.length === 0) {
                    array.push(item);
                  } else {
                    let ok = false;
                    let index = 0;
                    selectedFirstData.forEach((jtem, jndex) => {
                      if (jtem.id === item.id) {
                        ok = true;
                        index = jndex;
                      }
                    });

                    if (ok === true) {
                      array.splice(index, 1);
                    } else {
                      array.push(item);
                    }
                  }

                  setSelectedIndex(index);
                  setSelectedFirstData(array);
                }}
                item={item}
                index={index}
                division="second_sales"
              />
            );
          })}
        </ManagerSection>

        <div
          style={{
            width: '40%',
            marginLeft: '20px',
            marginTop: '50px',
          }}
        >
          <TitleSection>
            <h1 style={{ fontWeight: 'bold', marginBottom: '20px' }}>
              방문 영업사원
            </h1>
          </TitleSection>

          <TableColumnSection>
            <TableColumn width={10}>선택</TableColumn>
            <TableColumn width={30}>관리자명</TableColumn>
            <TableColumn width={60}>이메일</TableColumn>
          </TableColumnSection>

          {contactAdminData.map(item => {
            return (
              <TableManagerContactRowSection
                item={item}
                checkData={checkList}
                setCheckData={params => {
                  setCheckSecondManager(params);
                }}
              />
            );
          })}
        </div>
      </SalesSection>

      <button
        style={{
          display: 'block',
          marginTop: '30px',
          marginLeft: 'auto',
          backgroundColor: '#1976d2',
          color: '#fff',
          borderRadius: '5px',
          width: '100px',
          height: '40px',
        }}
        onClick={shareData}
      >
        공유
      </button>
    </TitleCard>
  );
}

export default Contact;
