import styled from 'styled-components';
import TitleCard from '../../../components/Cards/TitleCard';
import {
  ManagerSection,
  SalesSection,
  SecondTableMemoSection,
  TableColumn,
  TableColumnSection,
} from './components';
import React, { useEffect, useState } from 'react';
import axios from 'axios';

const ChangeModeButton = styled.button`
  width: 140px;
  height: 40px;
  color: ${props => (props.mode ? '#fff' : '#999')};
  background-color: ${props => (props.mode ? '#999' : 'white')};
  border: 1px solid #999;
`;

const ChangeModeButtonSection = styled.div`
  display: flex;
  gap: 20px;
  margin-bottom: 40px;
`;

function Second({ mode, setMode }) {
  const access = localStorage.getItem('access');

  async function loadSalesSecondAll() {
    const option = {
      url: 'https://cpi.contax.ai/sales/load_sales_second_memo_all',
      method: 'GET',
      header: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    };

    await axios(option).then(response => {
      if (response.status === 200) {
        setSecondData(response.data);
      }
    });
  }

  useEffect(() => {
    loadSalesSecondAll();
  }, []);
  const [secondData, setSecondData] = useState([]);
  const [selectedSecondData, setSelectedSecondData] = useState([]);

  return (
    <TitleCard title="영업">
      <ChangeModeButtonSection>
        <ChangeModeButton
          mode={'search' === mode}
          onClick={() => {
            setMode('search');
          }}
        >
          검색
        </ChangeModeButton>
        <ChangeModeButton
          mode={'memo' === mode}
          onClick={() => {
            setMode('memo');
          }}
        >
          관리자 현황
        </ChangeModeButton>
        <ChangeModeButton
          mode={'performance' === mode}
          onClick={() => {
            setMode('performance');
          }}
        >
          영업실적
        </ChangeModeButton>
        <ChangeModeButton
          mode={mode === 'share'}
          onClick={() => {
            setMode('share');
          }}
        >
          1차 데이터 공유
        </ChangeModeButton>
        <ChangeModeButton
          mode={mode === 'contact'}
          onClick={() => {
            setMode('contact');
          }}
        >
          방문 데이터 공유
        </ChangeModeButton>
        <ChangeModeButton
          mode={mode === 'second'}
          onClick={() => {
            setMode('second');
          }}
        >
          방문 데이터
        </ChangeModeButton>
        <ChangeModeButton
          mode={mode === 'trash'}
          onClick={() => {
            setMode('trash');
          }}
        >
          휴지통
        </ChangeModeButton>
        {access !== '영업 관리' && (
          <ChangeModeButton
            mode={mode === 'check_item'}
            onClick={() => {
              setMode('check_item');
            }}
          >
            체크리스트
          </ChangeModeButton>
        )}
      </ChangeModeButtonSection>

      <SalesSection>
        <ManagerSection>
          <h1 style={{ fontWeight: 'bold', marginBottom: '20px' }}>
            방문 데이터
          </h1>
          <TableColumnSection>
            <TableColumn width={10}>선택</TableColumn>
            <TableColumn width={20}>날짜</TableColumn>
            <TableColumn width={25}>메모</TableColumn>
            <TableColumn width={25}>관리자</TableColumn>
            <TableColumn width={20}>상세내용</TableColumn>
          </TableColumnSection>

          {secondData.length === 0 && (
            <span
              style={{
                textAlign: 'center',
                fontSize: '14px',
                color: '#333',
                paddingTop: '50px',
              }}
            >
              {' '}
              메모가 존재하지 않습니다.
            </span>
          )}

          {secondData.map((item, index) => {
            if (item.is_saved === true) {
              return (
                <SecondTableMemoSection
                  selectedFirstData={selectedSecondData}
                  setSelectedFirstData={params => {
                    const array = [...selectedSecondData];
                    if (array.length === 0) {
                      array.push(params);
                    } else {
                      let ok = false;
                      let index = 0;
                      selectedSecondData.forEach((item, jndex) => {
                        if (item.id === params.id) {
                          ok = true;
                          index = jndex;
                        }
                      });

                      if (ok === true) {
                        array.splice(index, 1);
                      } else {
                        array.push(params);
                      }
                    }

                    setSelectedSecondData(array);
                  }}
                  item={item}
                  index={index}
                  division="sales_second"
                />
              );
            }
          })}
        </ManagerSection>
      </SalesSection>
    </TitleCard>
  );
}

export default Second;
