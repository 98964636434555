import React from 'react';
import styled from 'styled-components';
const PropertyWrapper = styled.div<{ direction?: 'row' | 'column' }>`
  display: flex;
  flex-direction: ${(props) =>
    props.direction == 'column' ? 'column' : 'row'};
  gap: 10px;
  width: 60%;
  justify-content: start;
`;

const Title = styled.span`
  font-weight: bold;
  font-size: 16px;
  color: #606975;
  width: 250px;
`;

const Value = styled.span`
  font-size: 16px;
  color: #000;
`;

interface DetailPropertyProps {
  title: string;
  value: string;
  direction?: 'row' | 'column';
}

export function MemberInformation(props: DetailPropertyProps) {
  return (
    <PropertyWrapper direction={props.direction}>
      <Title>{props.title}</Title>
      <Value>{props.value}</Value>
    </PropertyWrapper>
  );
}
