import { StaticDatePicker } from '@mui/x-date-pickers';
import { createSlice } from '@reduxjs/toolkit';

export const jonghapSlice = createSlice({
  name: 'jonghapSales',
  initialState: {
    jonghap_data: [],
    jonghap_total_count: 0,
    jonghap_index: 0,
    selected_jonghap_key: '',
    selected_jonghap_title: '-',
    current_page: 1,
  },

  reducers: {
    updateJonghapIndex: (state, action) => {
      state.jonghap_index = action.payload;
    },

    loadJonghapSales: (state, action) => {
      state.jonghap_data = action.payload.data;
      state.jonghap_total_count = action.payload.total_count;
    },

    resetJonghapSales: state => {
      state.jonghap_data = [];
      state.jonghap_total_count = 0;
    },

    enterJonghapExtraInfo: (state, action) => {
      const array = [...state.jonghap_data];
      array[state.jonghap_index][action.payload.target] = action.payload.value;

      state.jonghap_data = array;
    },

    updateSelectedJonghapData: (state, action) => {
      state.selected_jonghap_key = action.payload.key;
      state.selected_jonghap_title = action.payload.title;
    },

    updateRecallTrue: (state, action) => {
      state.jonghap_data[state.jonghap_index].recall_true = action.payload;
    },

    updateRecallDate: (state, action) => {
      state.jonghap_data[state.jonghap_index].recall_date = action.payload;
    },

    removeRejection: (state, action) => {
      state.jonghap_data[state.jonghap_index].rejection = false;
      state.jonghap_data[state.jonghap_index].rejection_item = {};
    },

    updateMissingNumber: (state, action) => {
      state.jonghap_data[state.jonghap_index].missing_number = action.payload;
    },

    updateJonghapCurrentPage: (state, action) => {
      state.current_page = action.payload;
    },
  },
});

export const {
  updateJonghapCurrentPage,
  updateMissingNumber,
  removeRejection,
  updateRecallTrue,
  updateRecallDate,
  loadJonghapSales,
  resetJonghapSales,
  updateJonghapIndex,
  enterJonghapExtraInfo,
  updateSelectedJonghapData,
} = jonghapSlice.actions;

export default jonghapSlice.reducer;
