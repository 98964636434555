import { GyejeongType } from '../interface/jasanInterface';

interface OrderGyejeongListType {
  pyeongjeom: string;
  gyejeong: GyejeongType[];
}

export function orderGyejeongList(params: OrderGyejeongListType) {
  switch (params.pyeongjeom) {
    case '유동비율':
      return filteringYudongGyejeong(params.gyejeong);

    case '부채비율':
      return filteringBuchaeGyejeong(params.gyejeong);

    case '차입금의존도':
      return filteringChaIpGyejeong(params.gyejeong);

    case '영업이익대비 이자보상배율':
      return filteringProfitBaeyoolGyejeong(params.gyejeong);

    case '매출액영업이익율':
      return filteringMaechoolSalesProfitGyejeong(params.gyejeong);

    case '매출액순이익율':
      return filteringMaechoolNetProfitGyejeong(params.gyejeong);

    case '총자산순이익율':
      return filteringAllJasanNetProfitGyejeong(params.gyejeong);

    case '총자산대비영업현금흐름비율':
      return filteringCashFlowGyejeong(params.gyejeong);

    case '최근년도 자산회전율':
      return filteringJasanTurningGyejeong(params.gyejeong);

    case '건설기술개발투자비율':
      return filteringTechDevGyejeong(params.gyejeong);

    default:
      return [];
  }
}

export function filteringYudongGyejeong(gyejeong: GyejeongType[]) {
  let insertOn: boolean;
  insertOn = false;
  const orderReference: GyejeongType[] = [];
  let filteredReference: GyejeongType[] = [];

  // 재무제표 순서대로 차례대로 기입
  gyejeong.forEach((item) => {
    if (item.category === '유동자산' || item.category === '유동부채') {
      insertOn = true;
    }

    if (insertOn) {
      orderReference.push(item);
    }

    if (item.category === '비유동자산' || item.category === '비유동부채') {
      insertOn = false;
    }
  });

  filteredReference = orderReference.filter((item) => {
    return (
      item.category !== '비유동자산' &&
      item.category !== '비유동부채' &&
      item.category !== '당기순손실' &&
      item.category !== '당기'
    );
  });

  return filteredReference;
}

export function filteringBuchaeGyejeong(gyejeong: GyejeongType[]) {
  let insertOn: boolean;
  insertOn = false;
  const orderReference: GyejeongType[] = [];
  let filteredReference: GyejeongType[] = [];

  // 재무제표 순서대로 차례대로 기입
  gyejeong.forEach((item) => {
    if (
      item.category === '유동부채' ||
      item.category === '자본' ||
      item.category === '부채총계'
    ) {
      insertOn = true;
    }

    if (insertOn) {
      orderReference.push(item);
    }

    if (item.category === '비유동부채' || item.category === '자본총계') {
      insertOn = false;
    }
  });

  filteredReference = orderReference.filter((item) => {
    return (
      item.category !== '자본' &&
      item.category !== '당기순손실' &&
      item.category !== '자본총계'
    );
  });

  if (filteredReference.length === 0) {
    filteredReference.push({
      category: '부채총계',
      value: 0,
      period: '',
      created_date: '',
      company_name: ''
    });
  }

  return filteredReference;
}

export function filteringChaIpGyejeong(gyejeong: GyejeongType[]) {
  let filteredReference: GyejeongType[] = [
    {
      category: '차입금',
      company_name: '',
      value: 0,
      created_date: '',
      period: ''
    },

    {
      category: '단기차입금',
      company_name: '',
      value: 0,
      created_date: '',
      period: ''
    },
    {
      category: '장기차입금',
      company_name: '',
      value: 0,
      created_date: '',
      period: ''
    },

    {
      category: '자산총계',
      company_name: '',
      value: 0,
      created_date: '',
      period: ''
    }
  ];

  filteredReference = copyGyejeongValue(gyejeong, filteredReference);

  const result = [];

  if (
    filteredReference[0].value === 0 &&
    filteredReference[1].value !== 0 &&
    filteredReference[2].value === 0
  ) {
    result.push(filteredReference[1]);
    result.push(filteredReference[3]);
  }

  if (
    filteredReference[0].value === 0 &&
    filteredReference[1].value === 0 &&
    filteredReference[2].value !== 0
  ) {
    result.push(filteredReference[2]);
    result.push(filteredReference[3]);
  }

  if (
    filteredReference[0].value !== 0 &&
    filteredReference[1].value !== 0 &&
    filteredReference[2].value !== 0
  ) {
    result.push(filteredReference[0]);
    result.push(filteredReference[1]);
    result.push(filteredReference[2]);
    result.push(filteredReference[3]);
  }

  if (
    filteredReference[0].value === 0 &&
    filteredReference[1].value !== 0 &&
    filteredReference[2].value !== 0
  ) {
    result.push(filteredReference[1]);
    result.push(filteredReference[2]);
    result.push(filteredReference[3]);
  }

  if (
    filteredReference[0].value === 0 &&
    filteredReference[1].value === 0 &&
    filteredReference[2].value === 0
  ) {
    result.push(filteredReference[0]);
    result.push(filteredReference[3]);
  }

  return result;
}

export function filteringProfitBaeyoolGyejeong(gyejeong: GyejeongType[]) {
  let filteredReference: GyejeongType[] = [
    {
      category: '영업이익',
      company_name: '',
      value: 0,
      created_date: '',
      period: ''
    },

    {
      category: '영업손실',
      company_name: '',
      value: 0,
      created_date: '',
      period: ''
    },

    {
      category: '이자비용',
      company_name: '',
      value: 0,
      created_date: '',
      period: ''
    }
  ];

  filteredReference = copyGyejeongValue(gyejeong, filteredReference);

  if (filteredReference[0].value === 0 && filteredReference[1].value !== 0) {
    filteredReference = filteredReference.filter((item) => {
      return item.category !== '영업이익';
    });
    return filteredReference;
  }

  if (
    (filteredReference[0].value !== 0 && filteredReference[1].value === 0) ||
    (filteredReference[0].value === 0 && filteredReference[1].value === 0)
  ) {
    filteredReference = filteredReference.filter((item) => {
      return item.category !== '영업손실';
    });
    return filteredReference;
  }
}

export function filteringMaechoolSalesProfitGyejeong(gyejeong: GyejeongType[]) {
  let filteredReference: GyejeongType[] = [
    {
      category: '영업이익',
      company_name: '',
      value: 0,
      created_date: '',
      period: ''
    },

    {
      category: '영업손실',
      company_name: '',
      value: 0,
      created_date: '',
      period: ''
    },

    {
      category: '매출액',
      company_name: '',
      value: 0,
      created_date: '',
      period: ''
    }
  ];

  filteredReference = copyGyejeongValue(gyejeong, filteredReference);

  if (filteredReference[0].value === 0 && filteredReference[1].value !== 0) {
    filteredReference = filteredReference.filter((item) => {
      return item.category !== '영업이익';
    });
    return filteredReference;
  }

  if (
    (filteredReference[0].value !== 0 && filteredReference[1].value === 0) ||
    (filteredReference[0].value === 0 && filteredReference[1].value === 0)
  ) {
    filteredReference = filteredReference.filter((item) => {
      return item.category !== '영업손실';
    });
    return filteredReference;
  }
}

export function filteringMaechoolNetProfitGyejeong(gyejeong: GyejeongType[]) {
  let filteredReference: GyejeongType[] = [
    {
      category: '당기순이익',
      company_name: '',
      value: 0,
      created_date: '',
      period: ''
    },

    {
      category: '당기순손실',
      company_name: '',
      value: 0,
      created_date: '',
      period: ''
    },

    {
      category: '매출액',
      company_name: '',
      value: 0,
      created_date: '',
      period: ''
    }
  ];

  filteredReference = copyGyejeongValue(gyejeong, filteredReference);

  if (filteredReference[0].value === 0 && filteredReference[1].value !== 0) {
    filteredReference = filteredReference.filter((item) => {
      return item.category !== '당기순이익';
    });
    return filteredReference;
  }

  if (
    (filteredReference[0].value !== 0 && filteredReference[1].value === 0) ||
    (filteredReference[0].value === 0 && filteredReference[1].value === 0)
  ) {
    filteredReference = filteredReference.filter((item) => {
      return item.category !== '당기순손실';
    });
    return filteredReference;
  }
}

export function filteringAllJasanNetProfitGyejeong(gyejeong: GyejeongType[]) {
  let filteredReference: GyejeongType[] = [
    {
      category: '당기순이익',
      company_name: '',
      value: 0,
      created_date: '',
      period: ''
    },

    {
      category: '당기순손실',
      company_name: '',
      value: 0,
      created_date: '',
      period: ''
    },

    {
      category: '자산총계',
      company_name: '',
      value: 0,
      created_date: '',
      period: ''
    }
  ];

  filteredReference = copyGyejeongValue(gyejeong, filteredReference);

  if (filteredReference[0].value === 0 && filteredReference[1].value !== 0) {
    filteredReference = filteredReference.filter((item) => {
      return item.category !== '당기순이익';
    });
    return filteredReference;
  }

  if (
    (filteredReference[0].value !== 0 && filteredReference[1].value === 0) ||
    (filteredReference[0].value === 0 && filteredReference[1].value === 0)
  ) {
    filteredReference = filteredReference.filter((item) => {
      return item.category !== '당기순손실';
    });
    return filteredReference;
  }
}

export function filteringCashFlowGyejeong(gyejeong: GyejeongType[]) {
  let filteredReference: GyejeongType[] = [
    {
      category: '영업이익',
      company_name: '',
      value: 0,
      created_date: '',
      period: ''
    },

    {
      category: '영업손실',
      company_name: '',
      value: 0,
      created_date: '',
      period: ''
    },

    {
      category: '자산총계',
      company_name: '',
      value: 0,
      created_date: '',
      period: ''
    }
  ];

  filteredReference = copyGyejeongValue(gyejeong, filteredReference);

  if (filteredReference[0].value === 0 && filteredReference[1].value !== 0) {
    filteredReference = filteredReference.filter((item) => {
      return item.category !== '영업이익';
    });
    return filteredReference;
  }

  if (
    (filteredReference[0].value !== 0 && filteredReference[1].value === 0) ||
    (filteredReference[0].value === 0 && filteredReference[1].value === 0)
  ) {
    filteredReference = filteredReference.filter((item) => {
      return item.category !== '영업손실';
    });
    return filteredReference;
  }
}

export function filteringJasanTurningGyejeong(gyejeong: GyejeongType[]) {
  let filteredReference: GyejeongType[] = [
    {
      category: '매출액',
      company_name: '',
      value: 0,
      created_date: '',
      period: ''
    },

    {
      category: '자산총계',
      company_name: '',
      value: 0,
      created_date: '',
      period: ''
    }
  ];

  filteredReference = copyGyejeongValue(gyejeong, filteredReference);

  return filteredReference;
}

export function filteringTechDevGyejeong(gyejeong: GyejeongType[]) {
  let filteredReference: GyejeongType[] = [
    {
      category: '건설기술투자비',
      company_name: '',
      value: 0,
      created_date: '',
      period: ''
    },

    {
      category: '건설부분총매출',
      company_name: '',
      value: 0,
      created_date: '',
      period: ''
    }
  ];

  // filteredReference = copyGyejeongValue(gyejeong, filteredReference);

  filteredReference = [
    {
      category: '건설기술투자비',
      company_name: '',
      value: 0,
      created_date: '',
      period: ''
    },

    {
      category: '건설부분총매출',
      company_name: '',
      value: 0,
      created_date: '',
      period: ''
    }
  ];

  return filteredReference;
}

function copyGyejeongValue(gyejeong: GyejeongType[], array: GyejeongType[]) {
  gyejeong.forEach((item) => {
    array.forEach((elem, index) => {
      if (item.category === elem.category) {
        array[index] = { ...item };
      }
    });
  });

  return array;
}
