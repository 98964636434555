import { EmailForm } from '../../components/Email/EmailForm';
import React from 'react';
import { Grid } from '@mui/material';
import TitleCard from '../../components/Cards/TitleCard';
import { SelectMemberList } from './SelectMemberList';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { StoreState } from '../../app/store';
import { EmailBoardModal } from '../../components/EmailBoardModal';

const BlurBackground = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 99;
  backdrop-filter: blur(2px);
`;

export function SendEmailPage() {
  const { modal_active } = useSelector((state: StoreState) => state.layout);
  return (
    <Grid container spacing={2}>
      {modal_active && <BlurBackground />}
      {modal_active && <EmailBoardModal />}
      <TitleCard title="이메일 전송 페이지">
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            gap: '50px',
          }}
        >
          <div style={{ width: '30%' }}>
            <SelectMemberList />
          </div>

          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: '20px',
            }}
          >
            <EmailForm isSender={false} />
          </div>
        </div>
      </TitleCard>
    </Grid>
  );
}
