import React from 'react';
import styled from 'styled-components';
import { ManagerButton } from './Button';
import { PlayArrow } from '@mui/icons-material';
import PropTypes from 'prop-types';

const Grid = styled.div`
  width: 100%;
  min-height: 680px;
  height: 100%;
  box-shadow: 0px 1px 4px #969696;
  border-radius: 5px;
`;

const GridColumnSection = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const GridColumn = styled.div`
  width: ${props => props.width + '%'};
  font-weight: 500;
  padding: 16px 0;
  text-align: ${props => props.textAlign};

  &:nth-child(1) {
    padding-left: 70px;
  }

  &:last-child {
    padding-right: 70px;
  }
`;

const GridRowSection = styled.div`
  width: 100%;
  display: flex;
  cursor: pointer;

  &:nth-child(2n) {
    background-color: #f0f7ff;

    &:hover {
      background-color: #d4eaff;
    }
  }

  &:nth-child(2n-1) {
    background-color: #ffffff;

    &:hover {
      background-color: #f5f5f5;
    }
  }
`;

const GridRow = styled.div`
  width: ${props => props.width + '%'};
  padding: 20px 0;
  display: flex;

  ${props => props.align !== undefined && 'justify-content: ' + props.align};
  ${props =>
    props.backgroundColor !== undefined &&
    'background-color: ' + props.backgroundColor};
  ${props => props.color !== undefined && 'color: ' + props.color};

  align-items: center;
  gap: 10px;
  &:nth-child(1) {
    padding-left: 70px;
  }

  &:last-child {
    padding-right: 70px;
  }

  word-break: break-word;
`;

const GridButton = styled.div`
  width: 100%;
  padding: 15px 0;
  display: flex;

  justify-content: center;
  ${props =>
    props.backgroundColor !== undefined &&
    'background-color: ' + props.backgroundColor};
  ${props => props.color !== undefined && 'color: ' + props.color};

  align-items: center;
  gap: 10px;
  cursor: pointer;
`;

const St_BeforeUploadGridRow = styled.div`
  width: ${props => props.width + '%'};
  padding: 20px 0;
  text-align: ${props => props.textAlign};
  color: #b86b00;
  &:nth-child(1) {
    padding-left: 70px;
  }
`;

const St_CompleteUploadGridRow = styled.div`
  width: ${props => props.width + '%'};
  padding: 20px 0;
  text-align: ${props => props.textAlign};
  display: flex;
  align-items: center;
  justify-content: center;
  color: #00a257;
  &:nth-child(1) {
    padding-left: 70px;
  }
`;

const St_UnmatchGridRow = styled.div`
  width: ${props => props.width + '%'};
  padding: 20px 0;
  text-align: ${props => props.textAlign};
  display: flex;
  align-items: center;
  color: #c60303;
  justify-content: center;
  gap: 10px;
  &:nth-child(1) {
    padding-left: 70px;
  }
`;

const St_GridDetailSection = styled.div`
  width: 100%;
  height: ${props => (props.open === true ? 'auto' : '0')};
  background-color: #fff;

  overflow: hidden;
  padding: 0px 60px;
  margin-top: ${props => (props.open === true ? '40px' : '0px')};
  transition: all 0.3s ease;
`;

const St_PyeongjeomProperty = styled.div`
  width: 194px;
  height: 205px;
  border: 1px solid #d7d7d7;
  border-radius: 5px;
  padding: 14px 20px;
  display: flex;
  flex-direction: column;
  justify-content: end;
  position: relative;
`;

const St_PyeongjeomPropertyName = styled.div`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: auto;
`;

const St_PyeongjeomRatio = styled.span`
  font-size: ${props => (props.name === '실질자본금' ? '18px' : '20px')};
  font-weight: bold;
`;

export function ManagerGrid({ col, children }) {
  return (
    <Grid>
      <GridColumnSection>
        {col.map((item, index) => {
          return (
            <GridColumn
              key={index}
              width={item.width}
              textAlign={item.textAlign}
            >
              {item.title}
            </GridColumn>
          );
        })}
      </GridColumnSection>

      {children}
    </Grid>
  );
}

export function ManagerGridRowSection({ children }) {
  return <GridRowSection>{children}</GridRowSection>;
}

export function ManagerGridRow({
  children,
  width,
  onClick,
  align,
  backgroundColor,
  color,
  pointer,
}) {
  return (
    <GridRow
      width={width}
      onClick={onClick}
      align={align}
      backgroundColor={backgroundColor}
      color={color}
    >
      {children}
    </GridRow>
  );
}

ManagerGrid.defaultProps = {
  align: 'left',
  backgroundColor: '#fff',
  color: '#000',
};

export function ManagerGridButton({
  children,
  onClick,
  backgroundColor,
  color,
}) {
  return (
    <GridButton
      onClick={onClick}
      backgroundColor={backgroundColor}
      color={color}
    >
      {children}
    </GridButton>
  );
}

ManagerGridButton.defaultProps = {
  align: 'left',
  backgroundColor: '#fff',
  color: '#000',
};

ManagerGridRow.propTypes = {
  width: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  align: PropTypes.string,
  color: PropTypes.string,
  backgroundColor: PropTypes.string,
};

export function BeforeUploadGridRow({ width, textAlign, onClick }) {
  return (
    <St_BeforeUploadGridRow
      width={width}
      textAlign={textAlign}
      onClick={onClick}
    >
      업로드 전
    </St_BeforeUploadGridRow>
  );
}

export function CompleteUploadGridRow({ width, textAlign, onClick }) {
  return (
    <St_CompleteUploadGridRow
      width={width}
      textAlign={textAlign}
      onClick={onClick}
    >
      <span className="material-symbols-outlined">check_circle</span>
    </St_CompleteUploadGridRow>
  );
}

export function UnmatchGridRow({ width, textAlign, onClick, length }) {
  return (
    <St_UnmatchGridRow width={width} textAlign={textAlign} onClick={onClick}>
      <span className="material-symbols-outlined">warning</span>
      누락 {length}
    </St_UnmatchGridRow>
  );
}

export function ManagerGridDetailSection({ children, open }) {
  return (
    <St_GridDetailSection open={open}>
      {children}
      <div style={{ height: '40px' }}></div>
    </St_GridDetailSection>
  );
}

const St_ButtonSection = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
`;

const St_Diff = styled.div`
  font-weight: bold;
  color: #575757;
  font-size: 14px;
`;
const St_PyeongjeomRatioSection = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

export function PyeongjeomProperty({
  name,
  ratio,
  diff,
  setModalOn,
  result,
  button,
}) {
  return (
    <St_PyeongjeomProperty>
      <St_PyeongjeomPropertyName>{name}</St_PyeongjeomPropertyName>

      <St_PyeongjeomRatioSection>
        <St_PyeongjeomRatio name={name}>
          {name !== '실질자본금'
            ? isNaN(ratio) || ratio === Infinity
              ? 0
              : ratio.toFixed(2)
            : ratio}
          {name === '실질자본금' && ' 원'}
          {name === '영업이익대비 이자보상배율' && ' 배'}
          {name === '최근년도 자산회전율' && ' 회'}
          {name !== '실질자본금' &&
            name !== '최근년도 자산회전율' &&
            name !== '영업이익대비 이자보상배율' &&
            ' %'}
        </St_PyeongjeomRatio>
        {result === 'up' && (
          <PlayArrow sx={{ color: '#40C057', transform: 'rotate(-90deg)' }} />
        )}
        {result === 'down' && (
          <PlayArrow sx={{ color: '#B80000', transform: 'rotate(-270deg)' }} />
        )}
      </St_PyeongjeomRatioSection>

      <St_Diff>
        전분기 대비 : {diff === undefined ? 0 : diff.toFixed(2)} %
      </St_Diff>
      <St_ButtonSection>
        <ManagerButton
          onClick={() => {
            setModalOn('gyejeong');
          }}
          width="70px"
          height="30px"
        >
          계정과목
        </ManagerButton>
        <ManagerButton
          onClick={() => {
            setModalOn('comment');
          }}
          width="70px"
          height="30px"
        >
          코멘트
        </ManagerButton>
      </St_ButtonSection>
    </St_PyeongjeomProperty>
  );
}

export function ExpertProperty({
  setGyejeongStatus,
  name,
  ratio,
  diff,
  setModalOn,
  result,
  getExpertComment,
}) {
  return (
    <St_PyeongjeomProperty>
      <St_PyeongjeomPropertyName>{name}</St_PyeongjeomPropertyName>

      <St_PyeongjeomRatioSection>
        <St_PyeongjeomRatio name={name}>
          {name !== '실질자본금'
            ? isNaN(ratio) || ratio === Infinity
              ? 0
              : ratio.toFixed(2)
            : ratio}
          {name === '실질자본금' && ' 원'}
          {name === '영업이익대비 이자보상배율' && ' 배'}
          {name === '최근년도 자산회전율' && ' 회'}
          {name !== '실질자본금' &&
            name !== '최근년도 자산회전율' &&
            name !== '영업이익대비 이자보상배율' &&
            ' %'}
        </St_PyeongjeomRatio>
        {result === 'up' && (
          <PlayArrow sx={{ color: '#40C057', transform: 'rotate(-90deg)' }} />
        )}
        {result === 'down' && (
          <PlayArrow sx={{ color: '#B80000', transform: 'rotate(-270deg)' }} />
        )}
      </St_PyeongjeomRatioSection>

      <St_Diff>
        전분기 대비 : {diff === undefined ? 0 : diff.toFixed(2)} %
      </St_Diff>
      <St_ButtonSection>
        <ManagerButton
          onClick={() => {
            setModalOn('gyejeong');
          }}
          width="70px"
          height="30px"
        >
          계정과목
        </ManagerButton>
        <ManagerButton
          onClick={() => {
            getExpertComment(name);
            setGyejeongStatus(name);
            setModalOn('report');
          }}
          width="70px"
          height="30px"
        >
          보고서
        </ManagerButton>
      </St_ButtonSection>
    </St_PyeongjeomProperty>
  );
}

export function ExpertCommentProperty({
  date,
  name,
  setModalOn,
  getExpertComment,
}) {
  return (
    <St_PyeongjeomProperty>
      <St_PyeongjeomPropertyName>{name} </St_PyeongjeomPropertyName>
      <St_Diff>작성날짜 : {date.substring(0, 10)}</St_Diff>
      <St_ButtonSection>
        <ManagerButton
          onClick={() => {
            getExpertComment(name);
            setModalOn('report');
          }}
          width="100%"
          height="30px"
        >
          코멘트 확인
        </ManagerButton>
      </St_ButtonSection>
    </St_PyeongjeomProperty>
  );
}

ExpertCommentProperty.propTypes = {
  setModalOn: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  getExpertComment: PropTypes.func.isRequired,
  date: PropTypes.string,
};
