import React from 'react';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { OptionType } from '../interface/jasanInterface';

const St_Select = styled.div`
  border: 1px solid #eee;
  border-radius: 5px;
  display: flex;
  align-items: center;
  padding: 5px 10px;
  justify-content: space-between;
  cursor: pointer;
`;

const St_Options = styled.div`
  border: 1px solid #eee;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  position: absolute;
  background-color: #fff;
  width: 100%;
  z-index: 99;
  top: 40px;
`;

const St_Option = styled.div`
  padding: 5px 10px;
  cursor: pointer;
  &:hover {
    background-color: #eee;
  }
`;

const St_Input = styled.input`
  padding: 5px 10px;
  width: 100%;
`;

export interface SelectProps {
  option: OptionType[];
  bringValue: (str: string) => void;
  review: string;
}

export function Select(props: SelectProps) {
  const [selected, setSelected] = useState('한줄 평 선택');
  const [mode, setMode] = useState<'input' | 'text'>('text');
  const [value, setValue] = useState('');
  const [optionOn, setOptionOn] = useState(false);

  useEffect(() => {
    if (props.review !== null && props.review !== '') {
      setSelected(props.review);
    }
  }, [props.review]);

  useEffect(() => {
    if (mode === 'input') {
      props.bringValue(value);
    } else {
      props.bringValue(selected);
    }
  }, [selected]);

  return (
    <div style={{ position: 'relative' }}>
      {mode === 'input' && (
        <>
          <St_Select>
            <St_Input
              placeholder="한줄평을 입력해주세요."
              value={value}
              onChange={e => {
                props.bringValue(e.target.value);
                setValue(e.target.value);
              }}
            />
            <span
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setOptionOn(!optionOn);
              }}
              className="material-symbols-outlined"
            >
              expand_more
            </span>
          </St_Select>

          {optionOn && (
            <St_Options>
              <St_Option
                onClick={() => {
                  setOptionOn(false);
                  setMode('input');
                }}
              >
                직접 입력
              </St_Option>

              {props.option.map(item => {
                return (
                  <St_Option
                    onClick={() => {
                      setOptionOn(false);
                      setMode('text');
                      setSelected(item.text);
                    }}
                  >
                    {item.text}
                  </St_Option>
                );
              })}
            </St_Options>
          )}
        </>
      )}

      {mode !== 'input' && (
        <>
          <St_Select
            onClick={() => {
              setOptionOn(!optionOn);
            }}
          >
            <span>{selected}</span>
            <span className="material-symbols-outlined">expand_more</span>
          </St_Select>

          {optionOn && (
            <St_Options>
              <St_Option
                onClick={() => {
                  setOptionOn(false);
                  setMode('input');
                  setSelected('직접 입력');
                }}
              >
                직접 입력
              </St_Option>

              {props.option.map(item => {
                return (
                  <St_Option
                    onClick={() => {
                      setOptionOn(false);
                      setMode('text');
                      setSelected(item.text);
                    }}
                  >
                    {item.text}
                  </St_Option>
                );
              })}
            </St_Options>
          )}
        </>
      )}
    </div>
  );
}
