import axios from 'axios';

export interface ParamsType {
  company: string;
  selectedYear: string;
  selectedQuarter: string;
}

export async function getJaemuGyejeong(company: string) {
  const option = {
    url: 'https://cpi.contax.ai/jaemu_data_by_company',
    params: {
      company_name: company
    },
    method: 'GET',
    header: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data'
    }
  };

  try {
    const response = await axios(option);
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    alert('재무 계정과목을 불러오던 중 문제가 발생하였습니다.');
    return [];
  }
}

export async function getSonikGyejeong(company: string) {
  const option = {
    url: 'https://cpi.contax.ai/sonik_data_by_company',
    params: {
      company_name: company
    },
    method: 'GET',
    header: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data'
    }
  };

  try {
    const response = await axios(option);
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    alert('손익 계정과목을 불러오던 중 문제가 발생하였습니다.');
    return [];
  }
}

export interface SiljilDataType {
  company_name: string;
  real_capital: number;
  unrecognized_assets_sum: number;
  recognized_assets: number;
  total_assets: number;
  capital_total: number;
  total_dept: number;
}

export async function getSiljilGyejeong(company: string, quarter: string) {
  const option = {
    url: 'https://cpi.contax.ai/management_score/calculate_real_capital_quarter',
    params: {
      company_name: company,
      quarter: quarter
    },
    method: 'GET',
    header: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data'
    }
  };

  try {
    const response = await axios(option);
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    return {
      company_name: company,
      real_capital: 0,
      unrecognized_assets_sum: 0,
      recognized_assets: 0,
      total_assets: 0,
      capital_total: 0,
      total_dept: 0
    };
  }
}

export async function updateQuarter(company: string, quarter: string) {
  const option = {
    url: 'https://cpi.contax.ai/update_quarter',
    params: {
      company: company,
      quarter: quarter
    },
    method: 'PUT',
    header: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data'
    }
  };
  try {
    const response = await axios(option);
    if (response.status === 200) {
      return true;
    }
  } catch (error) {
    alert('파일 업로드 중 서버 오류가 발생했습니다.');
    return false;
  }
}

export async function yudongRatio(params: ParamsType) {
  const option = {
    url: 'https://cpi.contax.ai/management_score/calculate_yudongratio_diff_quarter',
    params: {
      company_name: params.company,
      quarter: params.selectedYear + '' + params.selectedQuarter
    },
    method: 'GET',
    header: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data'
    }
  };

  try {
    const response = await axios(option);

    if (
      response.status === 200 &&
      response.data.percent_diff !== 'Data insufficient'
    ) {
      return response.data.percent_diff;
    }

    if (
      response.status === 200 &&
      response.data.percent_diff === 'Data insufficient'
    ) {
      return 0;
    }
  } catch (error) {
    return 0;
  }
}

export async function getAnalyReport(email: string) {
  const option = {
    url: 'https://cpi.contax.ai/conbi_analy_report?email=' + email,
    method: 'GET',
    header: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data'
    }
  };

  try {
    const response = await axios(option);
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {}
}

export async function getQuarter(company: string) {
  const option = {
    url: 'https://cpi.contax.ai/get_quarter',
    params: {
      company: company
    },
    method: 'GET',
    header: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data'
    }
  };

  try {
    const response = await axios(option);

    if (response.status === 200) {
      return response.data[1];
    }
  } catch (error) {
    return '01';
  }
}

export async function yudongCurrentRatio(params: ParamsType) {
  const option = {
    url: 'https://cpi.contax.ai/management_score/calculate_yudongratio_updown_quarter',
    params: {
      company_name: params.company,
      quarter: params.selectedYear + '' + params.selectedQuarter
    },
    method: 'GET',
    header: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data'
    }
  };

  try {
    const response = await axios(option);

    if (response.status === 200) {
      return response.data.ratio;
    }
  } catch (error) {
    return 0;
  }
}

export async function yudongResult(params: ParamsType) {
  const option = {
    url: 'https://cpi.contax.ai/management_score/calculate_yudongratio_updown_quarter',
    params: {
      company_name: params.company,
      quarter: params.selectedYear + '' + params.selectedQuarter
    },
    method: 'GET',
    header: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data'
    }
  };

  try {
    const response = await axios(option);

    if (response.status === 200) {
      return response.data.result;
    }
  } catch (error) {
    return 'down';
  }
}

export async function buchaeRatio(params: ParamsType) {
  const option = {
    url: 'https://cpi.contax.ai/management_score/calculate_buchaeratio_diff_quarter',
    params: {
      company_name: params.company,
      quarter: params.selectedYear + '' + params.selectedQuarter
    },
    method: 'GET',
    header: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data'
    }
  };

  try {
    const response = await axios(option);

    if (
      response.status === 200 &&
      response.data.percent_diff !== 'Data insufficient'
    ) {
      return response.data.percent_diff;
    }

    if (
      response.status === 200 &&
      response.data.percent_diff === 'Data insufficient'
    ) {
      return 0;
    }
  } catch (error) {
    return 0;
  }
}

export async function buchaeResult(params: ParamsType) {
  const option = {
    url: 'https://cpi.contax.ai/management_score/calculate_buchaeratio_updown_quarter',
    params: {
      company_name: params.company,
      quarter: params.selectedYear + '' + params.selectedQuarter
    },
    method: 'GET',
    header: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data'
    }
  };

  try {
    const response = await axios(option);

    if (response.status === 200) {
      return response.data.result;
    }
  } catch (error) {
    return 'down';
  }
}

export async function buchaeCurrentRatio(params: ParamsType) {
  const option = {
    url: 'https://cpi.contax.ai/management_score/calculate_buchaeratio_updown_quarter',
    params: {
      company_name: params.company,
      quarter: params.selectedYear + '' + params.selectedQuarter
    },
    method: 'GET',
    header: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data'
    }
  };

  try {
    const response = await axios(option);

    if (response.status === 200) {
      return response.data.ratio;
    }
  } catch (error) {
    return 0;
  }
}

export async function chaIpRatio(params: ParamsType) {
  const option = {
    url: 'https://cpi.contax.ai/management_score/calculate_cha_ipratio_diff_quarter',
    params: {
      company_name: params.company,
      quarter: params.selectedYear + '' + params.selectedQuarter
    },
    method: 'GET',
    header: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data'
    }
  };

  try {
    const response = await axios(option);

    if (
      response.status === 200 &&
      response.data.percent_diff !== 'Data insufficient'
    ) {
      return response.data.percent_diff;
    }

    if (
      response.status === 200 &&
      response.data.percent_diff === 'Data insufficient'
    ) {
      return 0;
    }
  } catch (error) {
    return 0;
  }
}

export async function chaIpCurrentRatio(params: ParamsType) {
  const option = {
    url: 'https://cpi.contax.ai/management_score/calculate_cha_ipratio_updown_quarter',
    params: {
      company_name: params.company,
      quarter: params.selectedYear + '' + params.selectedQuarter
    },
    method: 'GET',
    header: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data'
    }
  };

  try {
    const response = await axios(option);

    if (response.status === 200) {
      return response.data.ratio;
    }
  } catch (error) {
    return 0;
  }
}

export async function chaIpResult(params: ParamsType) {
  const option = {
    url: 'https://cpi.contax.ai/management_score/calculate_cha_ipratio_updown_quarter',
    params: {
      company_name: params.company,
      quarter: params.selectedYear + '' + params.selectedQuarter
    },
    method: 'GET',
    header: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data'
    }
  };

  try {
    const response = await axios(option);

    if (response.status === 200) {
      return response.data.result;
    }
  } catch (error) {
    return 'down';
  }
}

export async function maechulRatio(params: ParamsType) {
  const option = {
    url: 'https://cpi.contax.ai/management_score/calculate_maechul_realinterest_ratio_diff_quarter',
    params: {
      company_name: params.company,
      quarter: params.selectedYear + '' + params.selectedQuarter
    },
    method: 'GET',
    header: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data'
    }
  };

  try {
    const response = await axios(option);

    if (
      response.status === 200 &&
      response.data.percent_diff !== 'Data insufficient'
    ) {
      return response.data.percent_diff;
    }

    if (
      response.status === 200 &&
      response.data.percent_diff === 'Data insufficient'
    ) {
      return 0;
    }
  } catch (error) {
    return 0;
  }
}

export async function maechulCurrentRatio(params: ParamsType) {
  const option = {
    url: 'https://cpi.contax.ai/management_score/calculate_maechul_realinterest_ratio_updown_quarter',
    params: {
      company_name: params.company,
      quarter: params.selectedYear + '' + params.selectedQuarter
    },
    method: 'GET',
    header: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data'
    }
  };

  try {
    const response = await axios(option);

    if (response.status === 200) {
      return response.data.ratio;
    }
  } catch (error) {
    return 0;
  }
}

export async function maechulResult(params: ParamsType) {
  const option = {
    url: 'https://cpi.contax.ai/management_score/calculate_maechul_realinterest_ratio_updown_quarter',
    params: {
      company_name: params.company,
      quarter: params.selectedYear + '' + params.selectedQuarter
    },
    method: 'GET',
    header: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data'
    }
  };

  try {
    const response = await axios(option);

    if (response.status === 200) {
      return response.data.result;
    }
  } catch (error) {
    return 'down';
  }
}

export async function jasanTurningRatio(params: ParamsType) {
  const option = {
    url: 'https://cpi.contax.ai/management_score/calculate_jasan_turning_ratio_diff_quarter',
    params: {
      company_name: params.company,
      quarter: params.selectedYear + '' + params.selectedQuarter
    },
    method: 'GET',
    header: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data'
    }
  };

  try {
    const response = await axios(option);

    if (
      response.status === 200 &&
      response.data.percent_diff !== 'Data insufficient'
    ) {
      return response.data.percent_diff;
    }

    if (
      response.status === 200 &&
      response.data.percent_diff === 'Data insufficient'
    ) {
      return 0;
    }
  } catch (error) {
    return 0;
  }
}

export async function jasanTurningCurrentRatio(params: ParamsType) {
  const option = {
    url: 'https://cpi.contax.ai/management_score/calculate_jasan_turning_ratio_updown_quarter',
    params: {
      company_name: params.company,
      quarter: params.selectedYear + '' + params.selectedQuarter
    },
    method: 'GET',
    header: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data'
    }
  };

  try {
    const response = await axios(option);

    if (response.status === 200) {
      return response.data.ratio;
    }
  } catch (error) {
    return 0;
  }
}

export async function jasanTurningResult(params: ParamsType) {
  const option = {
    url: 'https://cpi.contax.ai/management_score/calculate_jasan_turning_ratio_updown_quarter',
    params: {
      company_name: params.company,
      quarter: params.selectedYear + '' + params.selectedQuarter
    },
    method: 'GET',
    header: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data'
    }
  };

  try {
    const response = await axios(option);

    if (response.status === 200) {
      return response.data.result;
    }
  } catch (error) {
    return 'down';
  }
}

export async function cashflowRatio(params: ParamsType) {
  const option = {
    url: 'https://cpi.contax.ai/management_score/calculate_alljasan_sale_cashflow_ratio_diff_quarter',
    params: {
      company_name: params.company,
      quarter: params.selectedYear + '' + params.selectedQuarter
    },
    method: 'GET',
    header: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data'
    }
  };

  try {
    const response = await axios(option);

    if (
      response.status === 200 &&
      response.data.percent_diff !== 'Data insufficient'
    ) {
      return response.data.percent_diff;
    }

    if (
      response.status === 200 &&
      response.data.percent_diff === 'Data insufficient'
    ) {
      return 0;
    }
  } catch (error) {
    return 0;
  }

  return null;
}

export async function cashflowResult(params: ParamsType) {
  const option = {
    url: 'https://cpi.contax.ai/management_score/calculate_alljasan_sale_cashflow_ratio_updown_quarter',
    params: {
      company_name: params.company,
      quarter: params.selectedYear + '' + params.selectedQuarter
    },
    method: 'GET',
    header: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data'
    }
  };

  try {
    const response = await axios(option);

    if (response.status === 200) {
      return response.data.result;
    }
  } catch (error) {
    return 'down';
  }

  return null;
}

export async function cashflowCurrentRatio(params: ParamsType) {
  const option = {
    url: 'https://cpi.contax.ai/management_score/calculate_alljasan_sale_cashflow_ratio_updown_quarter',
    params: {
      company_name: params.company,
      quarter: params.selectedYear + '' + params.selectedQuarter
    },
    method: 'GET',
    header: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data'
    }
  };

  try {
    const response = await axios(option);

    if (response.status === 200) {
      return response.data.ratio;
    }
  } catch (error) {
    return 0;
  }

  return null;
}
export async function techdevRatio(params: ParamsType) {
  const option = {
    url: 'https://cpi.contax.ai/management_score/calculate_construct_techdev_invest_ratio_diff_quarter',
    params: {
      company_name: params.company,
      quarter: params.selectedYear + '' + params.selectedQuarter
    },
    method: 'GET',
    header: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data'
    }
  };

  try {
    const response = await axios(option);

    if (
      response.status === 200 &&
      response.data.percent_diff !== 'Data insufficient'
    ) {
      return response.data.percent_diff;
    }

    if (
      response.status === 200 &&
      response.data.percent_diff === 'Data insufficient'
    ) {
      return 0;
    }
  } catch (error) {
    return 0;
  }

  return null;
}

export async function techdevResult(params: ParamsType) {
  const option = {
    url: 'https://cpi.contax.ai/management_score/calculate_construct_techdev_invest_ratio_updown_quarter',
    params: {
      company_name: params.company,
      quarter: params.selectedYear + '' + params.selectedQuarter
    },
    method: 'GET',
    header: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data'
    }
  };

  try {
    const response = await axios(option);

    if (response.status === 200) {
      return response.data.result;
    }
  } catch (error) {
    return 'down';
  }
}

export async function techdevCurrentRatio(params: ParamsType) {
  const option = {
    url: 'https://cpi.contax.ai/management_score/calculate_construct_techdev_invest_ratio_updown_quarter',
    params: {
      company_name: params.company,
      quarter: params.selectedYear + '' + params.selectedQuarter
    },
    method: 'GET',
    header: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data'
    }
  };

  try {
    const response = await axios(option);

    if (response.status === 200) {
      return response.data.ratio;
    }
  } catch (error) {
    return 0;
  }
}

export async function alljasanRatio(params: ParamsType) {
  const option = {
    url: 'https://cpi.contax.ai/management_score/calculate_alljasan_realinterest_ratio_diff_quarter',
    params: {
      company_name: params.company,
      quarter: params.selectedYear + '' + params.selectedQuarter
    },
    method: 'GET',
    header: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data'
    }
  };

  try {
    const response = await axios(option);

    if (
      response.status === 200 &&
      response.data.percent_diff !== 'Data insufficient'
    ) {
      return response.data.percent_diff;
    }

    if (
      response.status === 200 &&
      response.data.percent_diff === 'Data insufficient'
    ) {
      return 0;
    }
  } catch (error) {
    return 0;
  }
}

export async function alljasanResult(params: ParamsType) {
  const option = {
    url: 'https://cpi.contax.ai/management_score/calculate_alljasan_realinterest_ratio_updown_quarter',
    params: {
      company_name: params.company,
      quarter: params.selectedYear + '' + params.selectedQuarter
    },
    method: 'GET',
    header: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data'
    }
  };

  try {
    const response = await axios(option);

    if (response.status === 200) {
      return response.data.result;
    }
  } catch (error) {
    return 'down';
  }
}

export async function alljasanCurrentRatio(params: ParamsType) {
  const option = {
    url: 'https://cpi.contax.ai/management_score/calculate_alljasan_realinterest_ratio_updown_quarter',
    params: {
      company_name: params.company,
      quarter: params.selectedYear + '' + params.selectedQuarter
    },
    method: 'GET',
    header: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data'
    }
  };

  try {
    const response = await axios(option);

    if (response.status === 200) {
      return response.data.ratio;
    }
  } catch (error) {
    return 0;
  }
}

export async function profitRatio(params: ParamsType) {
  const option = {
    url: 'https://cpi.contax.ai/management_score/calculate_operating_profit_interest_ratio_diff_quarter',
    params: {
      company_name: params.company,
      quarter: params.selectedYear + '' + params.selectedQuarter
    },
    method: 'GET',
    header: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data'
    }
  };

  try {
    const response = await axios(option);

    if (
      response.status === 200 &&
      response.data.percent_diff !== 'Data insufficient'
    ) {
      return response.data.percent_diff;
    }

    if (
      response.status === 200 &&
      response.data.percent_diff === 'Data insufficient'
    ) {
      return 0;
    }
  } catch (error) {
    return 0;
  }
}

export async function profitResult(params: ParamsType) {
  const option = {
    url: 'https://cpi.contax.ai/management_score/calculate_operating_profit_interest_ratio_updown_quarter',
    params: {
      company_name: params.company,
      quarter: params.selectedYear + '' + params.selectedQuarter
    },
    method: 'GET',
    header: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data'
    }
  };

  try {
    const response = await axios(option);

    if (response.status === 200) {
      return response.data.result;
    }
  } catch (error) {
    return 'down';
  }
}

export async function profitCurrentRatio(params: ParamsType) {
  const option = {
    url: 'https://cpi.contax.ai/management_score/calculate_operating_profit_interest_ratio_updown_quarter',
    params: {
      company_name: params.company,
      quarter: params.selectedYear + '' + params.selectedQuarter
    },
    method: 'GET',
    header: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data'
    }
  };

  try {
    const response = await axios(option);

    if (response.status === 200) {
      return response.data.ratio;
    }
  } catch (error) {
    return 0;
  }
}

export async function incomeRatio(params: ParamsType) {
  const option = {
    url: 'https://cpi.contax.ai/management_score/calculate_operating_income_margin_ratio_diff_quarter',
    params: {
      company_name: params.company,
      quarter: params.selectedYear + '' + params.selectedQuarter
    },
    method: 'GET',
    header: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data'
    }
  };

  try {
    const response = await axios(option);

    if (
      response.status === 200 &&
      response.data.percent_diff !== 'Data insufficient'
    ) {
      return response.data.percent_diff;
    }

    if (
      response.status === 200 &&
      response.data.percent_diff === 'Data insufficient'
    ) {
      return 0;
    }
  } catch (error) {
    return 0;
  }
}

export async function incomeResult(params: ParamsType) {
  const option = {
    url: 'https://cpi.contax.ai/management_score/calculate_operating_income_margin_ratio_updown_quarter',
    params: {
      company_name: params.company,
      quarter: params.selectedYear + '' + params.selectedQuarter
    },
    method: 'GET',
    header: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data'
    }
  };

  try {
    const response = await axios(option);

    if (response.status === 200) {
      return response.data.result;
    }
  } catch (error) {
    return 'down';
  }
}

export async function incomeCurrentRatio(params: ParamsType) {
  const option = {
    url: 'https://cpi.contax.ai/management_score/calculate_operating_income_margin_ratio_updown_quarter',
    params: {
      company_name: params.company,
      quarter: params.selectedYear + '' + params.selectedQuarter
    },
    method: 'GET',
    header: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data'
    }
  };

  try {
    const response = await axios(option);

    if (response.status === 200) {
      return response.data.ratio;
    }
  } catch (error) {
    return 0;
  }
}
