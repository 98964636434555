import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import { Box, Button, Grid, Stack, TextField } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import TitleCard from '../../components/Cards/TitleCard';
import { setPageTitle } from '../../app/slice/headerSlice';
import { getAllManageNotification } from '../../utils/getAllManageNotification';
import { getToken } from '../../utils/getToken';
import { getUserId } from '../../utils/getUserId';
import { sendNotification } from '../../utils/sendNotification';
import { insertNotification } from '../../utils/insertNotification';
import Editor from '../../components/Editor';
import stripHtmlAndLimitLength from '../../utils/stripHtmlAndLimitLength';

function Notice() {
  const [trans, setTrans] = useState([
    {
      id: 1,
      category: '',
      title: '',
      regdate: ''
    }
  ]);

  const dispatch = useDispatch();

  const [id, setId] = useState(0);
  const [gridWidth, setGridWidth] = useState(window.innerWidth);

  const columns = [
    {
      field: 'id',
      headerName: '문의 번호',
      width: gridWidth * 0.08,
      align: 'center',
      headerAlign: 'center'
    },
    { field: 'title', headerName: '제목', width: gridWidth * 0.4 },
    {
      field: 'regdate',
      headerName: '작성일자',
      width: gridWidth * 0.1,
      valueGetter: (params) => `${params.row.regdate.substring(0, 10)}`,
      align: 'center',
      headerAlign: 'center'
    }
  ];

  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [status, setStatus] = useState('');
  const [userIdList, setUserIdList] = useState([]);
  const [token, setToken] = useState([]);
  const [editorKey, setEditorKey] = useState(0);

  useEffect(() => {
    getNoticesData();
    dispatch(setPageTitle({ title: '공지사항' }));
    let array = [];

    getAllManageNotification('notice').then((result) => (array = result));
    getToken(array)
      .then((token) => setToken(token))
      .catch((error) => console.log(error));

    setToken(getToken(array));
    getUserId(array, setUserIdList);

    const handleResize = () => {
      setGridWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  async function getNoticesData() {
    const option = {
      url: 'https://cpi.contax.ai/notices',
      method: 'GET',
      header: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    };

    await axios(option).then((response) => {
      if (response.status === 200) {
        setTrans(response.data);
      }
    });
  }

  function resetEditorKey() {
    setEditorKey((prevKey) => prevKey + 1);
  }

  async function createNoticeData() {
    if (status === 'create' && (title === '' || title === null)) {
      alert('제목을 입력해주세요');
      return;
    }

    if (status === 'create' && (content === '' || content === null)) {
      alert('내용을 입력해주세요');
      return;
    }

    const body = {
      title: title,
      content: content
    };

    if (status !== 'create') {
      setStatus('create');
    }

    if (status === 'create') {
      const option = {
        url: 'https://cpi.contax.ai/notices',
        method: 'POST',
        data: body,
        header: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      };

      axios(option).then((response) => {
        const inputContent = stripHtmlAndLimitLength(content, 28);

        if (response.status === 200) {
          alert('성공적으로 추가되었습니다.');
          getAllManageNotification('notice').then((array) => {
            getToken(array)
              .then((token) =>
                token.forEach((item) => {
                  console.log(item);
                  // sendNotification(
                  //   '[공지사항] ' + title,
                  //   inputContent,
                  //   item,
                  //   'notice'
                  // );
                })
              )
              .catch((error) => console.log(error));

            getUserId(array)
              .then((idList) =>
                insertNotification(
                  'notice',
                  title,
                  inputContent,
                  idList,
                  response.data.id
                )
              )
              .catch((error) => console.log(error));
          });

          getNoticesData();

          resetData();
        }
      });
    }
  }

  function resetData() {
    setTitle('');
    setContent('');
    setStatus('');
  }

  async function deleteNoticeData() {
    if (!window.confirm('정말 삭제하시겠습니까?')) {
      return;
    } else {
      const option = {
        url: 'https://cpi.contax.ai/notices/' + id,
        method: 'DELETE',
        header: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      };

      axios(option).then((response) => {
        if (response.status === 200) {
          alert('성공적으로 삭제되었습니다.');
          window.location.reload();
        }
      });
    }
  }

  async function updateNoticesData() {
    if (status !== 'change') {
      setStatus('change');
    }

    if (status === 'change') {
      const option = {
        url: 'https://cpi.contax.ai/notices/' + id,
        params: {
          title: title,
          content: content
        },
        method: 'PUT',
        header: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      };

      axios(option).then((response) => {
        if (response.status === 200) {
          setTitle('');
          setContent('');
          setStatus('');
          getNoticesData();
          alert('성공적으로 수정되었습니다.');
        }
      });
    }
  }

  const handleContent = (data) => {
    setContent(data);
  };

  const handleSelection = (newSelection) => {
    // newSelection은 선택된 행의 id 배열입니다.
    const selectedData = trans.filter((row) => newSelection.includes(row.id));

    setId(selectedData[0].id);
    setTitle(selectedData[0].title);
    setContent(selectedData[0].content);
    resetEditorKey();
    // 이제 selectedData 변수는 선택된 행의 데이터를 포함합니다.
  };

  return (
    <Grid container spacing={2} direction="column" justifyContent="center">
      <Grid item>
        <TitleCard title="공지사항">
          {/* Team Member list in table format loaded constant */}
          <DataGrid
            rows={trans}
            columns={columns}
            getRowId={(row) => row.id}
            onRowSelectionModelChange={(newSelection) => {
              handleSelection(newSelection);
            }}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 5 }
              }
            }}
            pageSizeOptions={[5, 10]}
          />
        </TitleCard>
      </Grid>
      {(status === 'create' || status === 'change') && (
        <Grid item>
          <TitleCard title="공지사항 내용">
            <Stack>
              <TextField
                id="standard-basic"
                label="공지사항 제목"
                variant="standard"
                value={title}
                onChange={(e) => {
                  setTitle(e.target.value);
                }}
              />
              <Box mt={2}>
                <Editor value={content} setValue={handleContent} />
              </Box>
            </Stack>
          </TitleCard>
        </Grid>
      )}

      <Grid item>
        <Grid container justifyContent="flex-end" direction="row" spacing={2}>
          {status !== 'change' && (
            <Grid item>
              <Button
                disabled={status === 'change'}
                variant="contained"
                onClick={createNoticeData}
              >
                글 작성
              </Button>
            </Grid>
          )}
          {status !== 'create' && (
            <Grid item>
              <Button
                disabled={id === 0}
                variant="contained"
                onClick={updateNoticesData}
              >
                글 수정
              </Button>
            </Grid>
          )}
          {(status === 'change' || status === 'create') && (
            <Grid item>
              <Button
                variant="outlined"
                onClick={() => {
                  setStatus('');
                  setTitle('');
                  setContent('');
                }}
              >
                취소
              </Button>
            </Grid>
          )}
          {status !== 'create' && (
            <Grid item>
              <Button
                disabled={id === 0}
                sx={{ backgroundColor: 'red' }}
                variant="contained"
                onClick={deleteNoticeData}
              >
                글 삭제
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Notice;
