import { createSlice } from '@reduxjs/toolkit';
import { ColProps } from '../../components/GridType';
import {
  calculatePagination,
  createPaginationSize
} from '../../utils/common_API';

export interface LicenseData {
  id: number;
  license_category1: string;
  license_category2: string;
  license_name: string;
  capital_standard: number;
  year: number;
}

export interface LicensePageState {
  category_list: CategoryList[];
  limit: number;
  query: string;
  jonghap_column: ColProps[];
  jeonmoon_column: ColProps[];
  data: LicenseData[];
  total_count: number;
  pageLength: number;
  offset: number;
  mode: string;
  pageNumbers: number[];
  target: LicenseData;
  original_target: LicenseData;
}

const initialState: LicensePageState = {
  target: {
    id: 0,
    license_category1: '',
    license_category2: '',
    license_name: '',
    capital_standard: 0,
    year: 2024
  },

  original_target: {
    id: 0,
    license_category1: '',
    license_category2: '',
    license_name: '',
    capital_standard: 0,
    year: 2024
  },

  mode: 'default',
  pageLength: 0,
  pageNumbers: [1],
  offset: 0,
  category_list: [
    {
      title: '종합 면허',
      active: true
    },

    {
      title: '전문 면허',
      active: false
    }
  ],
  limit: 10,
  query: '',
  jonghap_column: [
    {
      title: '고유번호',
      width: 15,
      id: 'id',
      sort: true,
      search: false
    },

    {
      title: '구분',
      width: 15,
      id: 'id',
      sort: true,
      search: false
    },

    {
      title: '면허명',
      width: 30,
      id: 'license_name',
      sort: true,
      search: true
    },

    {
      title: '자본금',
      width: 20,
      id: 'captial_standard',
      sort: true,
      search: false
    },

    {
      title: '상세설정',
      width: 20,
      id: 'setting',
      sort: false,
      search: false
    }
  ],

  jeonmoon_column: [
    {
      title: '고유번호',
      width: 15,
      id: 'id',
      sort: true,
      search: false
    },

    {
      title: '구분',
      width: 15,
      id: 'id',
      sort: true,
      search: false
    },

    {
      title: '면허명',
      width: 30,
      id: 'license_name',
      sort: true,
      search: true
    },

    {
      title: '자본금',
      width: 20,
      id: 'captial_standard',
      sort: true,
      search: false
    },

    {
      title: '상세설정',
      width: 20,
      id: 'setting',
      sort: false,
      search: false
    }
  ],

  data: [],
  total_count: 0
};

export const licensePageSlice = createSlice({
  name: 'licensePageSlice',
  initialState: initialState,
  reducers: {
    updateLicenseTarget(state, action: { payload: LicenseData }) {
      state.target = action.payload;
    },

    saveLicenseTarget(state) {
      const list = [...state.data];

      list.forEach((item, index) => {
        console.log(item.id);
        console.log(state.target.id);
        if (item.id === state.target.id) {
          list[index] = state.target;
        }
      });

      state.data = list;
    },

    deleteLicenseTarget(state) {
      let list = [...state.data];
      list = list.filter((item) => {
        return item.id !== state.target.id;
      });

      console.log(list);
      console.log(state.target.id);

      state.data = list;
    },

    updateLicenseTargetData(
      state,
      action: { payload: { id: string; value: string } }
    ) {
      const target: LicenseData = state.target;
      if (action.payload.id === 'capital_standard') {
        const result = Number(action.payload.value);

        if (isNaN(result)) {
          target.capital_standard = Number(0);
          return;
        }

        target.capital_standard = result;
      }

      if (action.payload.id === 'license_name') {
        target.license_name = action.payload.value.toString();
      }

      if (action.payload.id === 'license_category1') {
        target.license_category1 = action.payload.value.toString();
      }

      if (action.payload.id === 'license_category2') {
        target.license_category2 = action.payload.value.toString();
      }

      state.target = target;
    },
    updateLicenseMode(state, action) {
      state.mode = action.payload;
    },

    updateOffset(state, action) {
      state.offset = action.payload;
    },

    updatePageNumbers(state, action) {
      state.pageNumbers = action.payload;
    },
    activateLicenseCategory(state, action) {
      const list = [...state.category_list];

      list.forEach((item) => {
        item.active = false;
      });

      list[action.payload].active = true;

      state.category_list = list;
    },
    updateLicenseLimit(state, action) {
      state.limit = action.payload;
    },

    updateLicenseQuery(state, action) {
      state.query = action.payload;
    },

    updateLicenseData(state, action) {
      state.data = action.payload.data;
      state.total_count = action.payload.total_count;
      const length: number = calculatePagination(
        action.payload.total_count,
        state.limit
      );

      state.pageLength = length;
      let array = [];
      array = createPaginationSize(state.offset, length);
      state.pageNumbers = array;
    },

    updateOriginalTargetLicense: (state, action) => {
      state.original_target = action.payload;
    },

    resetLicenseTarget(state) {
      state.target.capital_standard = 0;
      state.target.id = 0;
      state.target.license_category1 = '';
      state.target.license_category2 = '';
      state.target.license_name = '';
      state.target.year = 2023;
    }
  }
});

export const {
  updateLicenseTarget,
  updateLicenseMode,
  updateOffset,
  activateLicenseCategory,
  updateLicenseLimit,
  updateLicenseQuery,
  updateLicenseData,
  resetLicenseTarget,
  deleteLicenseTarget,
  updateOriginalTargetLicense,
  updateLicenseTargetData,
  saveLicenseTarget
} = licensePageSlice.actions;

export default licensePageSlice.reducer;
