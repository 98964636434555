import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StoreState } from '../../../app/store';
import { DeleteButton, ManagerGridButton } from '../../../components/GridType';
import { PencilIcon } from '@heroicons/react/16/solid';
import {
  resetCreateMemberInformation,
  updateCreateMember,
  updateMemberPageStatus
} from '../../../app/slice/managerManagementSlice';
import { deleteManager } from '../../../utils/user_API';
import { MemberInformation } from '../../../components/Layout/MemberInformation';
import { SelectedMember } from '../../email/component/SelectedMember';

interface MemberDetailSectionProps {
  searchMembers: () => void;
  closeDetailSection: () => void;
}

export function MemberDetailSection(props: MemberDetailSectionProps) {
  const members = useSelector((state: StoreState) => state.managerManagement);
  const dispatch = useDispatch();
  const target_members = members.target_member;
  const manager = members.manager;

  async function removeMember() {
    if (!window.confirm('정말 삭제하시겠습니까?')) {
      return;
    } else {
      const response = await deleteManager(
        target_members.user_id,
        target_members.email
      );
      if (response.error === 'Member not Found') {
        alert('이미 삭제한 멤버입니다.');
        props.searchMembers();
      }

      if (response.status === 'member deleted') {
        alert('성공적으로 삭제하였습니다.');
        props.searchMembers();
        props.closeDetailSection();
      }
    }
  }
  const [manager_division, setManager_division] = React.useState('');

  useEffect(() => {
    setManager_division('관리자');

    switch (manager) {
      case '':
        setManager_division('방문 관리자');
        break;

      case 'contact_sales':
        setManager_division('방문 관리자');
        break;

      case 'content':
        setManager_division('콘텐츠 관리자');
        break;

      case 'tax':
        setManager_division('세무 관리자');
        break;
    }
  }, [manager]);

  return (
    <>
      <div style={{ display: 'flex', gap: '20px', flexDirection: 'column' }}>
        <MemberInformation
          title="회원번호"
          value={target_members.user_id.toString()}
        />

        <MemberInformation
          title="아이디(이메일)"
          value={target_members.email}
        />

        <MemberInformation title="이름" value={target_members.name} />

        <MemberInformation
          title="전화번호"
          value={target_members.phone_number}
        />

        <div
          style={{
            margin: '20px 0',
            height: '2px',
            backgroundColor: '#eee'
          }}
        />

        <MemberInformation title="회사명" value={target_members.company_name} />
        <MemberInformation
          title="회사 전화번호"
          value={target_members.company_phone_number}
        />

        <MemberInformation
          title="대표자명"
          value={
            target_members.representative_name === 'Null'
              ? ''
              : target_members.representative_name
          }
        />

        <MemberInformation
          title="사업자등록번호"
          value={target_members.business_number}
        />

        <MemberInformation
          title="직위"
          value={
            target_members.position === 'Null' ? '' : target_members.position
          }
        />
        <MemberInformation title="관리자 구분" value={manager_division} />

        <MemberInformation
          title="주소"
          value={
            target_members.address === 'Null' ? '' : target_members.address
          }
        />

        {target_members.manager === 'content' && (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span style={{ width: '250px', fontWeight: 'bold' }}>
              접근 가능 컨텐츠
            </span>
            <div style={{ display: 'flex' }}>
              {members.selected_contents.map((item) => (
                <SelectedMember key={item} name={item} email={''} />
              ))}
            </div>
          </div>
        )}
      </div>

      <div style={{ justifyContent: 'end', display: 'flex', gap: '20px' }}>
        <ManagerGridButton
          type="active"
          onClick={() => {
            dispatch(resetCreateMemberInformation());
            dispatch(updateMemberPageStatus('modify'));
            dispatch(updateCreateMember(target_members));
          }}
        >
          <PencilIcon style={{ width: '20px' }} />
          수정
        </ManagerGridButton>

        <DeleteButton
          onClick={() => {
            removeMember();
          }}
        />
      </div>
    </>
  );
}
