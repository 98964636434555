// All components mapping with path for internal routes
import React from 'react';
import { lazy } from 'react';
import Notice from '../page/notice';
import Magazine from '../page/magazine';
import Inquiry from '../page/inquiry';
import Prompt from '../page/prompt';
import License from '../page/license';
import Consult from '../page/consult';
import Tax from '../page/tax';
import Sales from '../page/sales';
import Notification from '../page/notification';
import SalesManagement from '../page/salesManagement';
import TaxManagement from '../page/taxManagement';
import Coupon from '../page/coupon';
import ContentManagement from '../page/contentManagement';
import SalesManager from '../page/sales_manager';
import TaxManager from '../page/tax_manager';
import ExpertInquiry from '../page/expertInquiry';
import ExpertInquiryBoard from '../page/expertInquiryBoard';
import ExpertDashboard from '../page/expert_dashboard';
import JonghapJeonmoonSales from '../page/jonghap_jeonmoon_sales';
import TaxManagementSetting from '../page/taxManagementSetting';
import ExpertComment from '../page/expert_comment';
import ErrorBoard from '../page/error_board';
import TutorialContact from '../page/tutorial_contact';
import MemberManagement from '../page/members_management';
import { KakaotalkShareClickRatio } from '../page/kakaotalk_share_click_ratio';
import { SendEmailPage } from '../page/email';
import ManagerManagement from '../page/manager_management';
import { ManagerSendEmailPage } from '../page/manager_email';
import EmailLogPage from '../page/emailLog';
import TestManagement from '../page/test_management';
import PendingInquiry from '../page/pendingInquiry';
import ReportSendEmail from '../page/report_send_email';
import UseGuide from '../page/use_guide';
import DailyBidPage from '../page/dailyBid';

const Dashboard = lazy(() => import('../pages/protected/Dashboard'));
const Contact = lazy(() => import('../pages/protected/Contact'));

const routes = [
  {
    name: '대시보드',
    path: '/dashboard', // the url
    component: <Dashboard /> // view rendered
  },
  {
    name: '유저 현황',
    path: '/expert_dashboard',
    component: <ExpertDashboard />
  },

  {
    name: '회원 관리', // name that appear in Sidebar
    path: '/user', // the url
    component: <MemberManagement /> // view rendered
  },

  {
    name: '공지사항', // name that appear in Sidebar
    path: '/notice', // the url
    component: <Notice /> // view rendered
  },

  {
    name: '프롬프트 관리', // name that appear in Sidebar
    path: '/prompt', // the url
    component: <Prompt /> // view rendered
  },

  {
    name: '관리자 이메일 전송',
    path: '/manager_send_email', // the url
    component: <ManagerSendEmailPage /> // view rendered
  },

  {
    name: '보고서 이메일 전송',
    path: '/report_send_email', // the url
    component: <ReportSendEmail /> // view rendered
  },

  {
    name: '이용안내',
    path: '/use_guidea', // the url
    component: <UseGuide /> // view rendered
  },

  {
    name: '이메일 전송 내역', // name that appear in Sidebar
    path: '/email_log', // the url
    component: <EmailLogPage /> // view rendered
  },

  {
    name: '일일 공고 현황', // name that appear in Sidebar
    path: '/daily_bid', // the url
    component: <DailyBidPage /> // view rendered
  },

  {
    path: '/contentManagement', // the url
    component: <ContentManagement />, // view rendered
    name: '컨텐츠 관리자 현황' // name that appear in Sidebar
  },

  {
    path: '/taxManagement', // the url
    component: <TaxManagement /> // view rendered
  },

  {
    name: '카카오톡 공유 접속률', // name that appear in Sidebar
    path: '/kakaotalk_share_click_ratio', // the url
    component: <KakaotalkShareClickRatio /> // view rendered
  },

  {
    name: '이메일 전송', // name that appear in Sidebar
    path: '/send_email', // the url
    component: <SendEmailPage /> // view rendered
  },

  {
    path: '/salesManagement', // the url
    component: <SalesManagement /> // view rendered
  },

  {
    name: '면허 관리',
    path: '/license', // the url
    component: <License /> // view rendered
  },

  {
    name: '프로모션 코드', // name that appear in Sidebar
    path: '/coupon', // the url
    component: <Coupon /> // view rendered
  },

  {
    path: '/jonghap_sales', // the url
    component: <JonghapJeonmoonSales />, // view rendered
    name: '종합영업관리'
  },

  {
    name: '매거진', // name that appear in Sidebar
    path: '/magazine', // the url
    component: <Magazine /> // view rendered
  },

  {
    name: '승인대기 문의 조회', // name that appear in Sidebar
    path: '/pendingInquiry',
    component: <PendingInquiry />
  },

  {
    name: '관리자 계정 관리', // name that appear in Sidebar
    path: '/manager', // the url
    component: <ManagerManagement /> // view rendered
  },

  {
    name: '테스트 계정 관리', // name that appear in Sidebar
    path: '/test_user', // the url
    component: <TestManagement /> // view rendered
  },

  {
    name: '질문 설정', // name that appear in Sidebar
    path: '/taxManagementSetting', // the url
    component: <TaxManagementSetting /> // view rendered
  },

  {
    name: '영업',
    path: '/sales_manager', // the url
    component: <SalesManager /> // view rendered
  },

  {
    name: '세무 관리자 현황', // name that appear in Sidebar
    path: '/tax_manager', // the url
    component: <TaxManager /> // view rendered
  },

  {
    name: '1:1 문의', // name that appear in Sidebar
    path: '/inquiry', // the url
    component: <Inquiry /> // view rendered
  },

  {
    name: '문의글 공유', // name that appear in Sidebar
    path: '/expertInquiry', // the url
    component: <ExpertInquiry /> // view rendered
  },

  {
    name: '고객센터', // name that appear in Sidebar
    path: '/contact',
    component: <Contact />
  },

  {
    path: '/sales',
    component: Sales
  },

  {
    name: '기업 컨설팅', // name that appear in Sidebar
    path: '/consult',
    component: <Consult />
  },

  {
    name: '고객센터(전문가)', // name that appear in Sidebar
    path: '/expertInquiryBoard',
    component: <ExpertInquiryBoard />
  },

  {
    name: '알림 현황', // name that appear in Sidebar
    path: '/notification',
    component: <Notification />
  },

  {
    name: '세무 서비스', // name that appear in Sidebar
    path: '/tax',
    component: <Tax />
  },

  {
    path: '/expertComment',
    component: ExpertComment
  },

  {
    name: '에러 로그', // name that appear in Sidebar
    path: '/error_board',
    component: <ErrorBoard />
  },

  {
    name: '튜토리얼 문의', // name that appear in Sidebar
    path: '/tutorial_contact',
    component: <TutorialContact />
  }
];

export default routes;
