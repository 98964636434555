import TitleCard from '../../components/Cards/TitleCard';
import { Grid, Stack, Typography } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import {
  ManagerGrid,
  ManagerGridDetailSection,
  ManagerGridRow,
  ManagerGridRowSection,
  ExpertProperty
} from '../../components/GridType';
import {
  GyejeongModal,
  OverallModal,
  ReportModal,
  SiljilModal
} from '../../components/ModalComponents';
import {
  BeforeUploadGridRow,
  CompleteUploadGridRow
} from '../../components/Grid';
import Button from '@mui/material/Button';
import {
  getJaemuGyejeong,
  getQuarter,
  getSiljilGyejeong,
  getSonikGyejeong
} from '../../utils/jasan_API';

const ExpertPropertySection = styled.div`
  margin-top: 22px;
  display: flex;
  flex-wrap: wrap;
  gap: 50px;
`;

const St_PyeongjeomProperty = styled.div`
  width: 194px;
  height: 205px;
  border: 1px solid #d7d7d7;
  border-radius: 5px;
  padding: 14px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background-color: #eee;
  }
`;

const St_PyeongjeomPropertyName = styled.div`
  font-size: 20px;
  font-weight: bold;
  display: flex;
  gap: 3px;
  align-items: center;
`;

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1
});

function ExpertDashboard() {
  const jaemuRef = useRef(null);
  const sonikRef = useRef(null);

  const [expression, setExpression] = useState({});
  const [company, setCompany] = useState('');
  const [assignmentData, setAssignmentData] = useState([]);
  const [gyejeongRow, setGyejeongRow] = useState([]);
  const [reportList, setReportList] = useState([]);
  const [uploadedQuarter, setUploadedQuarter] = useState('');
  const [selectedYear, setSelectedYear] = useState('2022');
  const [selectedQuarter, setSelectedQuarter] = useState('01');
  const [siljil, setSiljil] = useState({
    company_name: '',
    real_capital: 0,
    unrecognized_assets_sum: 0,
    recognized_assets: 0,
    total_assets: 0,
    capital_total: 0,
    total_dept: 0
  });
  const [, setGyejeongStatus] = useState('');
  const [targetName, setTargetName] = useState('');
  const [targetId, setTargetId] = useState(0);

  const [diff, setDiff] = useState({
    yudong: 0,
    buchae: 0,
    chaIp: 0,
    maechul: 0,
    jasanTurning: 0,
    techdev: 0,
    cashflow: 0,
    profit: 0,
    income: 0,
    alljasan: 0
  });

  const [result, setResult] = useState({
    yudong: 'down',
    buchae: 'down',
    chaIp: 'down',
    maechul: 'down',
    jasanTurning: 'down',
    techdev: 'down',
    cashflow: 'down',
    profit: 'down',
    income: 'down',
    alljasan: 'down'
  });

  useEffect(() => {
    if (uploadedQuarter === 'None') {
      return;
    } else {
      setSelectedYear(uploadedQuarter.substring(0, 4));
      setSelectedQuarter(uploadedQuarter.substring(4, 6));
    }
  }, [uploadedQuarter]);

  const [sort, setSort] = useState('user_id');
  const [sortOption, setSortOption] = useState('DESC');

  useEffect(() => {
    const localMember = localStorage.getItem('second_manage_data');
    if (localMember) {
      const member = localMember.split(',').map((item) => item.trim());
      getUserData(member);
    }
    getExpression();
  }, [sort, sortOption]);

  async function getUserData() {
    let data = [];

    const user_id = localStorage.getItem('id');

    const conbiOption = {
      url: 'https://cpi.contax.ai/get_manager_of_member',
      method: 'GET',
      params: {
        user_id: Number(user_id),
        sort: sort,
        sortOption: sortOption
      },
      header: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    };

    await axios(conbiOption).then((response) => {
      if (response.status === 200) {
        data = response.data;
      }
    });

    setAssignmentData(data);
  }

  async function getAnalyReport(email) {
    const option = {
      url: 'https://cpi.contax.ai/conbi_analy_report?email=' + email,
      method: 'GET',
      header: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data'
      }
    };

    await axios(option).then((response) => {
      if (response.status === 200) {
        setReportList(response.data);
      }
    });
  }

  async function getExpression() {
    const option = {
      url: 'https://cpi.contax.ai/mng_avg_expression',
      method: 'GET',
      header: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data'
      }
    };

    await axios(option).then((response) => {
      if (response.status === 200) {
        setExpression(response.data);
      }
    });
  }

  const SelectQuarterSection = styled.div`
    display: flex;
    gap: 20px;
    align-items: end;
  `;

  async function insertComment(comment, title) {
    const id = localStorage.getItem('id');
    const name = localStorage.getItem('name');

    const option = {
      url: 'https://cpi.contax.ai/insert_expert_comment',
      data: {
        writter_id: id,
        writter_name: name,
        target_id: targetId,
        target_name: targetName,
        quarter: selectedYear + '' + selectedQuarter,
        company: company,
        comment: comment,
        pyeongjeom: title,
        comment_1: commentList.comment_1,
        comment_2: commentList.comment_2
      },
      method: 'POST',
      header: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data'
      }
    };

    await axios(option).then((response) => {
      if (response.status === 200) {
        alert('성공적으로 저장되었습니다.');
        setModalOn({ division: 'report', on: false });
      }
    });
  }

  function filteringDuplicate(data) {
    // 중복된 category를 찾기 위해 카운트 맵 생성
    const categoryCount = data.reduce((acc, item) => {
      acc[item.category] = (acc[item.category] || 0) + 1;
      return acc;
    }, {});

    // 중복된 category 중 첫 번째 항목 제거
    const firstOccurrenceRemoved = {};
    // 중복된 category 중 value가 0인 항목 제거
    const zeroValueRemoved = {};

    return data.filter((item) => {
      // 해당 category가 중복된 경우
      if (categoryCount[item.category] > 1) {
        // 중복된 category 중 value가 0인 항목이 있는지 확인
        if (item.value === 0 && !zeroValueRemoved[item.category]) {
          zeroValueRemoved[item.category] = true;
          return false; // value가 0인 항목 제거
        }
        // 첫 번째 항목 제거
        if (
          !firstOccurrenceRemoved[item.category] &&
          !zeroValueRemoved[item.category]
        ) {
          firstOccurrenceRemoved[item.category] = true;
          return false; // 첫 번째 항목 제거
        }
      }
      return true; // 나머지 항목 유지
    });
  }

  const quarterEndMonths = {
    '01': '03/31',
    '02': '06/30',
    '03': '09/30',
    '04': '12/31'
  };

  async function loadGyejeongGwamok(
    business_number,
    selectedYear,
    selectedQuarter
  ) {
    const array = [];
    const jaemuResponse = await getJaemuGyejeong(business_number);
    const quarterEndDate = `${selectedYear}/${quarterEndMonths[selectedQuarter]}`;

    jaemuResponse.forEach((element) => {
      if (element.period.endsWith(quarterEndDate)) {
        array.push(element);
      }
    });

    const sonikResponse = await getSonikGyejeong(business_number);

    sonikResponse.forEach((element) => {
      if (element.period.endsWith(quarterEndDate)) {
        array.push(element);
      }
    });

    setGyejeongRow(filteringDuplicate(array));
  }

  async function loadSiljilGwamok(company, selectedYear, selectedQuarter) {
    const response = await getSiljilGyejeong(
      company,
      selectedYear + '' + selectedQuarter
    );

    setSiljil(response);
  }

  useEffect(() => {
    if (company !== '') {
      getDiff(company);
      loadGyejeongGwamok(company, selectedYear, selectedQuarter);
      loadSiljilGwamok(company, selectedYear, selectedQuarter);
    }
  }, [selectedYear, selectedQuarter]);

  function calculateRatio(status) {
    let value1 = 0;
    let value2 = 0;
    let ratio = 0;

    gyejeongRow.forEach((item) => {
      if (status === '유동비율') {
        if (item['category'] === '유동자산') {
          value1 = item['value'];
        }

        if (item['category'] === '유동부채') {
          value2 = item['value'];
        }

        if (
          isNaN(value1 / value2) ||
          value1 / value2 === Infinity ||
          (value1 === 0 && value2 === 0)
        ) {
          ratio = 0;
        } else {
          ratio = (value1 / value2) * 100;
        }
      }

      if (status === '부채비율') {
        if (item['category'] === '부채총계') {
          value1 = item['value'];
        }

        if (item['category'] === '자본총계') {
          value2 = item['value'];
        }

        if (
          isNaN(value1 / value2) ||
          value1 / value2 === Infinity ||
          (value1 === 0 && value2 === 0)
        ) {
          ratio = 0;
        } else {
          ratio = (value1 / value2) * 100;
        }
      }

      if (status === '차입금의존도') {
        if (item['category'] === '차입금') {
          value1 = item['value'];
        }

        if (item['category'] === '자산총계') {
          value2 = item['value'];
        }

        if (
          isNaN(value1 / value2) ||
          value1 / value2 === Infinity ||
          (value1 === 0 && value2 === 0)
        ) {
          ratio = 0;
        } else {
          ratio = (value1 / value2) * 100;
        }
      }

      if (status === '영업이익대비 이자보상배율') {
        if (
          item['category'] === '영업이익' ||
          item['category'] === '영업손실'
        ) {
          if (item['value'] !== 0) {
            value1 = item['value'];
          }
        }

        if (item['category'] === '이자비용') {
          value2 = item['value'];
        }

        if (
          isNaN(value1 / value2) ||
          value1 / value2 === Infinity ||
          (value1 === 0 && value2 === 0)
        ) {
          ratio = 0;
        } else {
          ratio = -(value1 / value2);
        }
      }

      if (status === '매출액영업이익율') {
        if (
          item['category'] === '영업이익' ||
          item['category'] === '영업손실'
        ) {
          if (item['value'] !== 0) {
            value1 = item['value'];
          }
        }

        if (item['category'] === '매출액') {
          value2 = item['value'];
        }

        if (
          isNaN(value1 / value2) ||
          value1 / value2 === Infinity ||
          (value1 === 0 && value2 === 0)
        ) {
          ratio = 0;
        } else {
          ratio = -(value1 / value2) * 100;
        }
      }

      if (status === '매출액순이익율') {
        if (
          item['category'] === '당기순이익' ||
          item['category'] === '당기순손실'
        ) {
          if (item['value'] !== 0) {
            value1 = item['value'];
          }
        }

        if (item['category'] === '매출액') {
          value2 = item['value'];
        }

        if (
          isNaN(value1 / value2) ||
          value1 / value2 === Infinity ||
          (value1 === 0 && value2 === 0)
        ) {
          ratio = 0;
        } else {
          ratio = -(value1 / value2) * 100;
        }
      }

      if (status === '총자산순이익율') {
        if (
          item['category'] === '당기순이익' ||
          item['category'] === '당기순손실'
        ) {
          if (item['value'] !== 0) {
            value1 = item['value'];
          }
        }

        if (item['category'] === '자산총계') {
          value2 = item['value'];
        }

        if (
          isNaN(value1 / value2) ||
          value1 / value2 === Infinity ||
          (value1 === 0 && value2 === 0)
        ) {
          ratio = 0;
        } else {
          ratio = -(value1 / value2) * 100;
        }
      }

      if (status === '총자산대비영업현금흐름비율') {
        if (
          item['category'] === '영업이익' ||
          item['category'] === '영업손실'
        ) {
          if (item['value'] !== 0) {
            value1 = item['value'];
          }
        }

        if (item['category'] === '자산총계') {
          value2 = item['value'];
        }

        if (
          isNaN(value1 / value2) ||
          value1 / value2 === Infinity ||
          (value1 === 0 && value2 === 0)
        ) {
          ratio = 0;
        } else {
          ratio = -(value1 / value2) * 100;
        }
      }

      if (status === '최근년도 자산회전율') {
        if (item['category'] === '매출액') {
          value1 = item['value'];
        }

        if (item['category'] === '자산총계') {
          value2 = item['value'];
        }

        if (
          isNaN(value1 / value2) ||
          value1 / value2 === Infinity ||
          (value1 === 0 && value2 === 0)
        ) {
          ratio = 0;
        } else {
          ratio = value1 / value2;
        }
      }

      if (status === '건설기술개발투자비율') {
        if (item['category'] === '건설기술투자비') {
          value1 = item['value'];
        }

        if (item['category'] === '건설부분총매출') {
          value2 = item['value'];
        }

        if (
          isNaN(value1 / value2) ||
          value1 / value2 === Infinity ||
          (value1 === 0 && value2 === 0)
        ) {
          ratio = 0;
        } else {
          ratio = (value1 / value2) * 100;
        }
      }
    });

    return ratio;
  }

  async function getDiff(company) {
    setDiff({
      yudong: 0,
      buchae: 0,
      chaIp: 0,
      maechul: 0,
      jasanTurning: 0,
      techdev: 0,
      cashflow: 0,
      profit: 0,
      income: 0,
      alljasan: 0
    });

    const object = { ...diff };
    const resultObject = { ...result };

    const yudongOption = {
      url: 'https://cpi.contax.ai/management_score/calculate_yudongratio_diff_quarter',
      params: {
        company_name: company,
        quarter: selectedYear + '' + selectedQuarter
      },
      method: 'GET',
      header: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data'
      }
    };

    const yudongResultOption = {
      url: 'https://cpi.contax.ai/management_score/calculate_yudongratio_updown_quarter',
      params: {
        company_name: company,
        quarter: selectedYear + '' + selectedQuarter
      },
      method: 'GET',
      header: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data'
      }
    };

    try {
      const response = await yudongOption;

      if (
        response.status === 200 &&
        response.data.percent_diff !== 'Data insufficient'
      ) {
        object.yudong = response.data.percent_diff;
      }

      if (
        response.status === 200 &&
        response.data.percent_diff === 'Data insufficient'
      ) {
        object.yudong = 0;
      }
    } catch (error) {
      object.yudong = 0;
    }

    try {
      const response = await yudongResultOption;

      if (response.status === 200) {
        resultObject.yudong = response.data.result;
      }
    } catch (error) {
      resultObject.yudong = 'down';
    }

    const buchaeOption = {
      url: 'https://cpi.contax.ai/management_score/calculate_buchaeratio_diff_quarter',
      params: {
        company_name: company,
        quarter: selectedYear + '' + selectedQuarter
      },
      method: 'GET',
      header: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data'
      }
    };

    const buchaeResultOption = {
      url: 'https://cpi.contax.ai/management_score/calculate_buchaeratio_updown_quarter',
      params: {
        company_name: company,
        quarter: selectedYear + '' + selectedQuarter
      },
      method: 'GET',
      header: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data'
      }
    };

    try {
      const response = await buchaeOption;

      if (
        response.status === 200 &&
        response.data.percent_diff !== 'Data insufficient'
      ) {
        object.buchae = response.data.percent_diff;
      }

      if (
        response.status === 200 &&
        response.data.percent_diff === 'Data insufficient'
      ) {
        object.buchae = 0;
      }
    } catch (error) {
      object.buchae = 0;
    }

    try {
      const response = await buchaeResultOption;

      if (response.status === 200) {
        resultObject.buchae = response.data.result;
      }
    } catch (error) {
      resultObject.buchae = 'down';
    }

    const chaIpOption = {
      url: 'https://cpi.contax.ai/management_score/calculate_cha_ipratio_diff_quarter',
      params: {
        company_name: company,
        quarter: selectedYear + '' + selectedQuarter
      },
      method: 'GET',
      header: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data'
      }
    };

    const chaIpResultOption = {
      url: 'https://cpi.contax.ai/management_score/calculate_cha_ipratio_updown_quarter',
      params: {
        company_name: company,
        quarter: selectedYear + '' + selectedQuarter
      },
      method: 'GET',
      header: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data'
      }
    };

    try {
      const response = await chaIpOption;

      if (
        response.status === 200 &&
        response.data.percent_diff !== 'Data insufficient'
      ) {
        object.chaIp = response.data.percent_diff;
      }

      if (
        response.status === 200 &&
        response.data.percent_diff === 'Data insufficient'
      ) {
        object.chaIp = 0;
      }
    } catch (error) {
      object.chaIp = 0;
    }

    try {
      const response = await chaIpResultOption;

      if (response.status === 200) {
        resultObject.chaIp = response.data.result;
      }
    } catch (error) {
      resultObject.chaIp = 'down';
    }

    const maechulOption = {
      url: 'https://cpi.contax.ai/management_score/calculate_maechul_realinterest_ratio_diff_quarter',
      params: {
        company_name: company,
        quarter: selectedYear + '' + selectedQuarter
      },
      method: 'GET',
      header: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data'
      }
    };

    const maechulResultOption = {
      url: 'https://cpi.contax.ai/management_score/calculate_maechul_realinterest_ratio_updown_quarter',
      params: {
        company_name: company,
        quarter: selectedYear + '' + selectedQuarter
      },
      method: 'GET',
      header: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data'
      }
    };

    try {
      const response = await maechulOption;

      if (
        response.status === 200 &&
        response.data.percent_diff !== 'Data insufficient'
      ) {
        object.maechul = response.data.percent_diff;
      }

      if (
        response.status === 200 &&
        response.data.percent_diff === 'Data insufficient'
      ) {
        object.maechul = 0;
      }
    } catch (error) {
      object.maechul = 0;
    }

    try {
      const response = await maechulResultOption;

      if (response.status === 200) {
        resultObject.maechul = response.data.result;
      }
    } catch (error) {
      resultObject.maechul = 'down';
    }

    const jasanTurningOption = {
      url: 'https://cpi.contax.ai/management_score/calculate_jasan_turning_ratio_diff_quarter',
      params: {
        company_name: company,
        quarter: selectedYear + '' + selectedQuarter
      },

      method: 'GET',
      header: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data'
      }
    };

    const jasanTurningResultOption = {
      url: 'https://cpi.contax.ai/management_score/calculate_jasan_turning_ratio_updown_quarter',
      params: {
        company_name: company,
        quarter: selectedYear + '' + selectedQuarter
      },
      method: 'GET',
      header: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data'
      }
    };

    try {
      const response = await jasanTurningOption;

      if (
        response.status === 200 &&
        response.data.percent_diff !== 'Data insufficient'
      ) {
        object.jasanTurning = response.data.percent_diff;
      }

      if (
        response.status === 200 &&
        response.data.percent_diff === 'Data insufficient'
      ) {
        object.jasanTurning = 0;
      }
    } catch (error) {
      object.jasanTurning = 0;
    }

    try {
      const response = await jasanTurningResultOption;

      if (response.status === 200) {
        resultObject.jasanTurning = response.data.result;
      }
    } catch (error) {
      resultObject.jasanTurning = 'down';
    }

    const cashflowOption = {
      url: 'https://cpi.contax.ai/management_score/calculate_alljasan_sale_cashflow_ratio_diff_quarter',
      params: {
        company_name: company,
        quarter: selectedYear + '' + selectedQuarter
      },
      method: 'GET',
      header: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data'
      }
    };

    const cashflowResultOption = {
      url: 'https://cpi.contax.ai/management_score/calculate_alljasan_sale_cashflow_ratio_updown_quarter',
      params: {
        company_name: company,
        quarter: selectedYear + '' + selectedQuarter
      },
      method: 'GET',
      header: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data'
      }
    };

    try {
      const response = await cashflowOption;

      if (
        response.status === 200 &&
        response.data.percent_diff !== 'Data insufficient'
      ) {
        object.cashflow = response.data.percent_diff;
      }

      if (
        response.status === 200 &&
        response.data.percent_diff === 'Data insufficient'
      ) {
        object.cashflow = 0;
      }
    } catch (error) {
      object.cashflow = 0;
    }

    try {
      const response = await cashflowResultOption;

      if (response.status === 200) {
        resultObject.cashflow = response.data.result;
      }
    } catch (error) {
      resultObject.cashflow = 'down';
    }

    const techdevOption = {
      url: 'https://cpi.contax.ai/management_score/calculate_construct_techdev_invest_ratio_diff_quarter',
      params: {
        company_name: company,
        quarter: selectedYear + '' + selectedQuarter
      },
      method: 'GET',
      header: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data'
      }
    };

    const techdevResultOption = {
      url: 'https://cpi.contax.ai/management_score/calculate_construct_techdev_invest_ratio_updown_quarter',
      params: {
        company_name: company,
        quarter: selectedYear + '' + selectedQuarter
      },
      method: 'GET',
      header: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data'
      }
    };

    try {
      const response = await techdevOption;

      if (
        response.status === 200 &&
        response.data.percent_diff !== 'Data insufficient'
      ) {
        object.techdev = response.data.percent_diff;
      }

      if (
        response.status === 200 &&
        response.data.percent_diff === 'Data insufficient'
      ) {
        object.techdev = 0;
      }
    } catch (error) {
      object.techdev = 0;
    }

    try {
      const response = await techdevResultOption;

      if (response.status === 200) {
        resultObject.techdev = response.data.result;
      }
    } catch (error) {
      resultObject.techdev = 'down';
    }

    const alljasanOption = {
      url: 'https://cpi.contax.ai/management_score/calculate_alljasan_realinterest_ratio_diff_quarter',
      params: {
        company_name: company,
        quarter: selectedYear + '' + selectedQuarter
      },
      method: 'GET',
      header: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data'
      }
    };

    const alljasanResultOption = {
      url: 'https://cpi.contax.ai/management_score/calculate_alljasan_realinterest_ratio_updown_quarter',
      params: {
        company_name: company,
        quarter: selectedYear + '' + selectedQuarter
      },
      method: 'GET',
      header: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data'
      }
    };

    try {
      const response = await alljasanOption;

      if (
        response.status === 200 &&
        response.data.percent_diff !== 'Data insufficient'
      ) {
        object.alljasan = response.data.percent_diff;
      }

      if (
        response.status === 200 &&
        response.data.percent_diff === 'Data insufficient'
      ) {
        object.alljasan = 0;
      }
    } catch (error) {
      object.alljasan = 0;
    }

    try {
      const response = await alljasanResultOption;

      if (response.status === 200) {
        resultObject.alljasan = response.data.result;
      }
    } catch (error) {
      resultObject.alljasan = 'down';
    }

    const profitOption = {
      url: 'https://cpi.contax.ai/management_score/calculate_operating_profit_interest_ratio_diff_quarter',
      params: {
        company_name: company,
        quarter: selectedYear + '' + selectedQuarter
      },
      method: 'GET',
      header: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data'
      }
    };

    const profitResultOption = {
      url: 'https://cpi.contax.ai/management_score/calculate_operating_profit_interest_ratio_updown_quarter',
      params: {
        company_name: company,
        quarter: selectedYear + '' + selectedQuarter
      },
      method: 'GET',
      header: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data'
      }
    };

    try {
      const response = await profitOption;

      if (
        response.status === 200 &&
        response.data.percent_diff !== 'Data insufficient'
      ) {
        object.profit = response.data.percent_diff;
      }

      if (
        response.status === 200 &&
        response.data.percent_diff === 'Data insufficient'
      ) {
        object.profit = 0;
      }
    } catch (error) {
      object.alljasan = 0;
    }

    try {
      const response = await profitResultOption;

      if (response.status === 200) {
        resultObject.profit = response.data.result;
      }
    } catch (error) {
      resultObject.alljasan = 'down';
    }

    const incomeOption = {
      url: 'https://cpi.contax.ai/management_score/calculate_operating_income_margin_ratio_diff_quarter',
      params: {
        company_name: company,
        quarter: selectedYear + '' + selectedQuarter
      },
      method: 'GET',
      header: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data'
      }
    };

    const incomeResultOption = {
      url: 'https://cpi.contax.ai/management_score/calculate_operating_income_margin_ratio_updown_quarter',
      params: {
        company_name: company,
        quarter: selectedYear + '' + selectedQuarter
      },
      method: 'GET',
      header: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data'
      }
    };

    try {
      const response = await incomeOption;

      if (
        response.status === 200 &&
        response.data.percent_diff !== 'Data insufficient'
      ) {
        object.income = response.data.percent_diff;
      }

      if (
        response.status === 200 &&
        response.data.percent_diff === 'Data insufficient'
      ) {
        object.income = 0;
      }
    } catch (error) {
      object.income = 0;
    }

    try {
      const response = await incomeResultOption;

      if (response.status === 200) {
        resultObject.income = response.data.result;
      }
    } catch (error) {
      resultObject.income = 'down';
    }

    setDiff(object);
    setResult(resultObject);
  }

  async function openDetailSection(index, item) {
    const data = await getQuarter(item.business_number);

    let year;
    let quarter;

    if (data === null) {
      setSelectedYear('2022');
      setSelectedQuarter('01');

      year = '2022';
      quarter = '01';
    } else {
      setSelectedYear(data.substring(0, 4));
      setSelectedQuarter(data.substring(4, 6));

      year = data.substring(0, 4);
      quarter = data.substring(4, 6);
    }

    setUploadedQuarter(data);

    if (index === open.index) {
      if (open.open) {
        setOpen({ index: index, open: false });
      }

      if (!open.open) {
        setOpen({ index: index, open: true });
      }
    }

    if (index !== open.index) {
      setOpen({ index: index, open: true });
    }

    setTargetName(item.name);
    setTargetId(item.user_id);
    setCompany(item.business_number);
    await getDiff(item.business_number);
    await loadSiljilGwamok(item.business_number, year, quarter);
    await loadGyejeongGwamok(item.business_number, year, quarter);
    getAnalyReport(item.email);
  }

  const [Grid_COL, setGriod_COL] = useState([
    {
      id: 'user_id',
      title: '번호',
      width: '10',
      textAlign: 'center',
      sort: true,
      sortOption: 'DESC'
    },
    {
      id: 'representative_name',
      title: '이름',
      width: '15',
      textAlign: 'left',
      sort: true,
      sortOption: 'DESC'
    },
    {
      id: 'email',
      title: '이메일',
      width: '20',
      textAlign: 'left',
      sort: true,
      sortOption: 'DESC'
    },
    {
      id: 'company_name',
      title: '회사',
      width: '15',
      textAlign: 'left',
      sort: true,
      sortOption: 'DESC'
    },
    {
      id: 'phone_number',
      title: '휴대폰 번호',
      width: '15',
      textAlign: 'left',
      sort: true,
      sortOption: 'DESC'
    },
    {
      id: 'business_number',
      title: '사업자 번호',
      width: '10',
      textAlign: 'left',
      sort: true,
      sortOption: 'DESC'
    },
    { id: 'status', title: '상태', width: '15', textAlign: 'center' }
  ]);

  async function updateQuarter() {
    const option = {
      url: 'https://cpi.contax.ai/update_quarter',
      params: {
        company: company,
        quarter: selectedYear + '' + selectedQuarter
      },
      method: 'PUT',
      header: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data'
      }
    };
    try {
      const response = await axios(option);
      if (response.status === 200) {
        console.log('success');
      }
    } catch (e) {
      /* empty */
    }
  }

  async function uploadJaemuFile(e) {
    const target = e.target;
    const file = target.files[0];
    const formData = new FormData();
    formData.append('file', file);

    console.log(formData);

    const option = {
      url: 'https://cpi.contax.ai/jaemu_excel_upload_no_quarter',
      params: {
        company_name: company,
        quarter: selectedYear + '' + selectedQuarter
      },
      data: formData,
      method: 'POST',
      header: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data'
      }
    };

    try {
      const response = await axios(option);

      if (response.status === 200) {
        alert('파일이 업로드되었습니다.');

        await loadGyejeongGwamok(company, selectedYear, selectedQuarter);
        await loadSiljilGwamok(company, selectedYear, selectedQuarter);

        if (response.data.message === 'Data inserted successfully') {
          await updateQuarter(company, response.data.most_recent_period);
          await getUserData();
          setSelectedYear(response.data.most_recent_period.substring(0, 4));
          setSelectedQuarter(response.data.most_recent_period.substring(4, 6));

          if (
            Number(response.data.most_recent_period) > Number(uploadedQuarter)
          ) {
            setUploadedQuarter(response.data.most_recent_period);
          }
        }
        // insertNotification('재무제표');
        // token.forEach((item) => {
        //     sendNotification(item, '재무제표');
        // })
      }
    } catch (error) {
      if (file === undefined) {
        alert('파일 업로드가 취소되었습니다. ');
        return;
      }
      if (file === undefined) {
        alert('파일 업로드가 취소되었습니다. ');
        return;
      }

      if (error.code === 'ERR_NETWORK' || error.code === 'ERR_BAD_RESPONSE') {
        alert('파일 형식이 잘못되었습니다.');
        return;
      }

      if (
        error.code === 'ERR_BAD_REQUEST' &&
        error.response.data.detail === '손익계산서를 업로드해주세요'
      ) {
        alert('손익계산서를 업로드해주세요');
        return;
      }

      if (error.code === 'ERR_NETWORK' || error.code === 'ERR_BAD_RESPONSE') {
        alert('파일 형식이 잘못되었습니다.');
        return;
      }

      if (
        error.code === 'ERR_BAD_REQUEST' &&
        error.response.data.detail === '재무상태표를 업로드해주세요'
      ) {
        alert('재무상태표를 업로드해주세요');
        return;
      }
    }
  }

  async function uploadSonikFile(e) {
    const target = e.target;
    if (target.files === undefined) {
      return;
    }

    const file = target.files[0];
    const formData = new FormData();
    formData.append('file', file);

    const option = {
      url: 'https://cpi.contax.ai/sonik_excel_upload_no_quarter',
      params: {
        company_name: company
      },
      data: formData,
      method: 'POST',
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    };

    try {
      const response = await axios(option);
      if (response.status === 200) {
        alert('파일이 업로드되었습니다.');
        await loadGyejeongGwamok(company, selectedYear, selectedQuarter);
        await loadSiljilGwamok(company, selectedYear, selectedQuarter);

        if (response.data.message === 'Data inserted successfully') {
          await updateQuarter(company, response.data.most_recent_period);
          setSelectedYear(response.data.most_recent_period.substring(0, 4));
          setSelectedQuarter(response.data.most_recent_period.substring(4, 6));

          if (
            Number(response.data.most_recent_period) > Number(uploadedQuarter)
          ) {
            setUploadedQuarter(response.data.most_recent_period);
          }
        }
      }
    } catch (error) {
      if (file === undefined) {
        alert('파일 업로드가 취소되었습니다. ');
        return;
      }

      if (error.code === 'ERR_NETWORK' || error.code === 'ERR_BAD_RESPONSE') {
        alert('파일 형식이 잘못되었습니다.');
        return;
      }

      if (
        error.code === 'ERR_BAD_REQUEST' &&
        error.response.data.detail === '손익계산서를 업로드해주세요'
      ) {
        alert('손익계산서를 업로드해주세요');
        return;
      }
    }
  }

  async function getComment(pyeongjeom) {
    const id = localStorage.getItem('id');

    const option = {
      url: 'https://cpi.contax.ai/load_tax_manager_comment',
      params: {
        writter_id: id,
        pyeongjeom: pyeongjeom,
        target_user_id: targetId,
        quarter: selectedYear + '' + selectedQuarter
      },
      method: 'GET',
      header: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data'
      }
    };

    await axios(option).then((response) => {
      console.log(response.data);
    });
  }

  const [expertComment, setExpertComment] = useState('');
  const [commentList, setCommentList] = useState({
    comment_1: '',
    comment_2: ''
  });

  async function getExpertComment(pyeongjeom) {
    const id = localStorage.getItem('id');

    const option = {
      url: 'https://cpi.contax.ai/load_expert_comment',
      params: {
        writter_id: id,
        target_id: targetId,
        quarter: selectedYear + '' + selectedQuarter,
        pyeongjeom: pyeongjeom
      },
      method: 'GET',
      header: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data'
      }
    };

    await axios(option).then((response) => {
      if (response.data.length > 0) {
        setExpertComment(response.data[0].overall_comment);
        setCommentList({
          comment_1: response.data[0].comment_1,
          comment_2: response.data[0].comment_2
        });
      } else {
        setExpertComment('');
        setCommentList({
          comment_1: '',
          comment_2: ''
        });
      }
    });
  }

  function formatNumberWithCommas(num) {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  const [open, setOpen] = useState({ index: 0, open: false });
  const [modalOn, setModalOn] = useState({ division: '', on: false });
  const [selectedPyeongjeom, setSelectedPyeongjeom] = useState('');

  return (
    <>
      {modalOn.on === true && modalOn.division === 'siljil' && (
        <SiljilModal
          uploadedQuarter={uploadedQuarter}
          expression={expression}
          gyejeongRow={siljil}
          title={selectedPyeongjeom}
          setModalOn={() => {
            const object = { ...modalOn };
            object.on = false;
            setModalOn(object);
          }}
        />
      )}

      {modalOn.on === true && modalOn.division === 'gyejeong' && (
        <GyejeongModal
          uploadedQuarter={uploadedQuarter}
          expression={expression}
          gyejeongRow={gyejeongRow}
          title={selectedPyeongjeom}
          setModalOn={() => {
            const object = { ...modalOn };
            object.on = false;
            setModalOn(object);
          }}
        />
      )}

      {modalOn.on === true && modalOn.division === 'report' && (
        <ReportModal
          setCommentList={(params) => setCommentList(params)}
          commentList={commentList}
          expertComment={expertComment}
          insertComment={(comment, title) => {
            insertComment(comment, title);
          }}
          expression={expression}
          gyejeongRow={
            selectedPyeongjeom === '실질자본금' ? siljil : gyejeongRow
          }
          title={selectedPyeongjeom}
          setModalOn={() => {
            const object = { ...modalOn };
            object.on = false;
            setModalOn(object);
          }}
          selectedQuarter={selectedQuarter}
          selectedYear={selectedYear}
          reportList={reportList}
        />
      )}

      {modalOn.on === true && modalOn.division === 'overall' && (
        <OverallModal
          comment={expertComment}
          targetName={targetName}
          targetId={targetId}
          quarter={selectedYear + '' + selectedQuarter}
          gyejeongRow={gyejeongRow}
          title={selectedPyeongjeom}
          setModalOn={() => {
            const object = { ...modalOn };
            object.on = false;
            setModalOn(object);
          }}
          insertReview={(comment) => {
            insertComment(comment, '총평');
          }}
          loadReview={() => {
            getExpertComment('총평');
          }}
        />
      )}

      {/* <div style={{position:'absolute'}}>
                <PyeongjeomAverage year="2022" />
            </div> */}

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TitleCard title="유저 현황">
            <ManagerGrid
              sortFunction={(id, sortOption) => {
                const col = [];
                Grid_COL.forEach((item) => {
                  if (id === item.id && sortOption === 'ASC') {
                    setSort(id);
                    setSortOption('DESC');
                    col.push({ ...item, sortOption: 'DESC' });
                  } else if (id === item.id && sortOption === 'DESC') {
                    setSort(id);
                    setSortOption('ASC');
                    col.push({ ...item, sortOption: 'ASC' });
                  } else {
                    col.push(item);
                  }
                });

                setGriod_COL(col);
              }}
              col={Grid_COL}
            >
              {assignmentData.length === 0 && (
                <div style={{ textAlign: 'center', padding: '100px 0' }}>
                  데이터가 존재하지 않습니다.
                </div>
              )}
              {assignmentData.map((item, index) => {
                return (
                  <>
                    <ManagerGridRowSection>
                      <ManagerGridRow
                        onClick={() => {
                          openDetailSection(index, item);
                        }}
                        textAlign="center"
                        align="center"
                        width="10"
                      >
                        {item.user_id}
                      </ManagerGridRow>
                      <ManagerGridRow
                        onClick={() => {
                          openDetailSection(index, item);
                        }}
                        width="15"
                      >
                        {item.representative_name}
                      </ManagerGridRow>
                      <ManagerGridRow
                        onClick={() => {
                          openDetailSection(index, item);
                        }}
                        width="20"
                      >
                        {item.email}
                      </ManagerGridRow>
                      <ManagerGridRow
                        onClick={() => {
                          openDetailSection(index, item);
                        }}
                        width="15"
                      >
                        {item.company_name}
                      </ManagerGridRow>
                      <ManagerGridRow
                        onClick={() => {
                          openDetailSection(index, item);
                        }}
                        width="15"
                      >
                        {item.phone_number}
                      </ManagerGridRow>
                      <ManagerGridRow
                        onClick={() => {
                          openDetailSection(index, item);
                        }}
                        width="10"
                      >
                        {item.business_number}
                      </ManagerGridRow>
                      {item.is_uploaded === false ||
                      item.is_uploaded === undefined ||
                      item.is_uploaded === null ? (
                        <BeforeUploadGridRow
                          onClick={() => {
                            openDetailSection(index, item);
                          }}
                          width="15"
                          textAlign="center"
                        />
                      ) : (
                        <CompleteUploadGridRow
                          onClick={() => {
                            openDetailSection(index, item);
                          }}
                          width="15"
                          textAlign="center"
                        />
                      )}
                    </ManagerGridRowSection>

                    <ManagerGridDetailSection
                      open={open.index === index && open.open === true}
                    >
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between'
                        }}
                      >
                        <SelectQuarterSection>
                          <select
                            style={{
                              width: '95px',
                              fontWeight: 'bold',
                              fontSize: '20px'
                            }}
                            value={selectedYear}
                            onChange={(e) => {
                              setSelectedYear(e.target.value);
                            }}
                            defaultValue="2022"
                          >
                            <option value="2022">2022년</option>
                            <option value="2023">2023년</option>
                            <option value="2024">2024년</option>
                          </select>

                          <select
                            style={{
                              width: '95px',
                              fontWeight: 'bold',
                              fontSize: '20px'
                            }}
                            value={selectedQuarter}
                            onChange={(e) => {
                              const quarter = e.target.value;
                              setSelectedQuarter(quarter);
                            }}
                            defaultValue="01"
                          >
                            <option value="01">1분기</option>
                            <option value="02">2분기</option>
                            <option value="03">3분기</option>
                            <option value="04">4분기</option>
                          </select>

                          <span
                            style={{
                              color: '#1976d2',
                              cursor: 'pointer'
                            }}
                            className="material-symbols-outlined"
                          >
                            info
                          </span>
                          {(uploadedQuarter === 'None' ||
                            uploadedQuarter === '01') && (
                            <span
                              style={{
                                width: '200px',
                                fontSize: '14px',
                                color: '#555'
                              }}
                            >
                              업로드 한 재무제표가 없습니다.
                            </span>
                          )}

                          {uploadedQuarter !== 'None' &&
                            uploadedQuarter !== '01' && (
                              <span
                                style={{
                                  width: '200px',
                                  fontSize: '14px',
                                  color: '#555'
                                }}
                              >
                                최근 업로드 :{' '}
                                {uploadedQuarter.substring(0, 4) +
                                  '년 ' +
                                  uploadedQuarter.substring(5, 6) +
                                  '분기'}
                              </span>
                            )}
                        </SelectQuarterSection>

                        <Grid container justifyContent="end" spacing={5}>
                          <Grid item>
                            <Stack spacing={2}>
                              <Typography variant="h6">
                                재무상태표 업로드
                              </Typography>
                              <Button
                                component="label"
                                disabled={company === ''}
                                variant="contained"
                              >
                                업로드
                                <VisuallyHiddenInput
                                  type="file"
                                  accept=".xlsx, .xls"
                                  onChange={(event) => {
                                    uploadJaemuFile(event);
                                    event.target.value = '';
                                  }}
                                />
                              </Button>
                            </Stack>
                          </Grid>

                          <Grid item>
                            <Stack spacing={2} mr={2}>
                              <Typography variant="h6">
                                손익계산서 업로드
                              </Typography>
                              <Button
                                component="label"
                                disabled={company === ''}
                                variant="contained"
                              >
                                업로드
                                <VisuallyHiddenInput
                                  type="file"
                                  accept=".xlsx, .xls"
                                  onChange={(event) => {
                                    uploadSonikFile(
                                      event,
                                      selectedYear,
                                      selectedQuarter
                                    );

                                    event.target.value = '';
                                  }}
                                />
                              </Button>
                            </Stack>
                          </Grid>
                        </Grid>
                      </div>

                      <ExpertPropertySection>
                        <ExpertProperty
                          setModalOn={(division) => {
                            const object = {
                              ...modalOn
                            };
                            object.division = division;
                            object.on = true;
                            setModalOn(object);
                            setSelectedPyeongjeom('실질자본금');

                            if (division === 'comment') {
                              getComment('실질자본금');
                            }
                          }}
                          name="실질자본금"
                          getExpertComment={(params) => {
                            getExpertComment(params);
                          }}
                          setGyejeongStatus={(params) => {
                            setGyejeongStatus(params);
                          }}
                          ratio={formatNumberWithCommas(siljil?.real_capital)}
                        />

                        <ExpertProperty
                          setModalOn={(division) => {
                            const object = {
                              ...modalOn
                            };
                            object.division = division;
                            object.on = true;
                            setModalOn(object);
                            setSelectedPyeongjeom('유동비율');

                            if (division === 'comment') {
                              getComment('유동비율');
                            }
                          }}
                          ratio={calculateRatio('유동비율')}
                          diff={diff.yudong}
                          result={result.yudong}
                          getExpertComment={(params) => {
                            getExpertComment(params);
                          }}
                          name="유동비율"
                          setGyejeongStatus={(params) => {
                            setGyejeongStatus(params);
                          }}
                        />
                        <ExpertProperty
                          setModalOn={(division) => {
                            const object = {
                              ...modalOn
                            };
                            object.division = division;
                            object.on = true;
                            setModalOn(object);
                            setSelectedPyeongjeom('부채비율');

                            if (division === 'comment') {
                              getComment('부채비율');
                            }
                          }}
                          ratio={calculateRatio('부채비율')}
                          result={result.buchae}
                          setGyejeongStatus={(params) => {
                            setGyejeongStatus(params);
                          }}
                          diff={diff.buchae}
                          name="부채비율"
                          getExpertComment={(params) => {
                            getExpertComment(params);
                          }}
                        />

                        <ExpertProperty
                          setModalOn={(division) => {
                            const object = {
                              ...modalOn
                            };
                            object.division = division;
                            object.on = true;
                            setModalOn(object);
                            setSelectedPyeongjeom('차입금의존도');

                            if (division === 'comment') {
                              getComment('차입금의존도');
                            }
                          }}
                          ratio={calculateRatio('차입금의존도')}
                          result={result.chaIp}
                          diff={diff.chaIp}
                          getExpertComment={(params) => {
                            getExpertComment(params);
                          }}
                          setGyejeongStatus={(params) => {
                            setGyejeongStatus(params);
                          }}
                          name="차입금의존도"
                        />

                        <ExpertProperty
                          setModalOn={(division) => {
                            const object = {
                              ...modalOn
                            };
                            object.division = division;
                            object.on = true;
                            setModalOn(object);
                            setSelectedPyeongjeom('영업이익대비 이자보상배율');
                            if (division === 'comment') {
                              getComment('영업이익대비 이자보상배율');
                            }
                          }}
                          ratio={calculateRatio('영업이익대비 이자보상배율')}
                          diff={diff.profit}
                          result={result.profit}
                          getExpertComment={(params) => {
                            getExpertComment(params);
                          }}
                          setGyejeongStatus={(params) => {
                            setGyejeongStatus(params);
                          }}
                          name="영업이익대비 이자보상배율"
                        />

                        <ExpertProperty
                          setModalOn={(division) => {
                            const object = {
                              ...modalOn
                            };
                            object.division = division;
                            object.on = true;
                            setModalOn(object);
                            setSelectedPyeongjeom('매출액영업이익율');
                            if (division === 'comment') {
                              getComment('매출액영업이익율');
                            }
                          }}
                          ratio={calculateRatio('매출액영업이익율')}
                          diff={diff.maechul}
                          result={result.maechul}
                          getExpertComment={(params) => {
                            getExpertComment(params);
                          }}
                          name="매출액영업이익율"
                          setGyejeongStatus={(params) => {
                            setGyejeongStatus(params);
                          }}
                        />

                        <ExpertProperty
                          setModalOn={(division) => {
                            const object = {
                              ...modalOn
                            };
                            object.division = division;
                            object.on = true;
                            setModalOn(object);
                            setSelectedPyeongjeom('매출액순이익율');

                            if (division === 'comment') {
                              getComment('매출액순이익율');
                            }
                          }}
                          ratio={calculateRatio('매출액순이익율')}
                          diff={diff.income}
                          result={result.income}
                          getExpertComment={(params) => {
                            getExpertComment(params);
                          }}
                          setGyejeongStatus={(params) => {
                            setGyejeongStatus(params);
                          }}
                          name="매출액순이익율"
                        />
                        <ExpertProperty
                          setModalOn={(division) => {
                            const object = {
                              ...modalOn
                            };
                            object.division = division;
                            object.on = true;
                            setModalOn(object);
                            setSelectedPyeongjeom('총자산순이익율');

                            if (division === 'comment') {
                              getComment('총자산순이익율');
                            }
                          }}
                          ratio={calculateRatio('총자산순이익율')}
                          name="총자산순이익율"
                          diff={diff.alljasan}
                          result={result.alljasan}
                          getExpertComment={(params) => {
                            getExpertComment(params);
                          }}
                          setGyejeongStatus={(params) => {
                            setGyejeongStatus(params);
                          }}
                        />

                        <ExpertProperty
                          setModalOn={(division) => {
                            const object = {
                              ...modalOn
                            };
                            object.division = division;
                            object.on = true;
                            setModalOn(object);
                            setSelectedPyeongjeom('총자산대비영업현금흐름비율');

                            if (division === 'comment') {
                              getComment('총자산대비영업현금흐름비율');
                            }
                          }}
                          ratio={calculateRatio('총자산대비영업현금흐름비율')}
                          diff={diff.cashflow}
                          result={result.cashflow}
                          getExpertComment={(params) => {
                            getExpertComment(params);
                          }}
                          name="총자산대비영업현금흐름비율"
                          setGyejeongStatus={(params) => {
                            setGyejeongStatus(params);
                          }}
                        />

                        <ExpertProperty
                          setModalOn={(division) => {
                            const object = {
                              ...modalOn
                            };
                            object.division = division;
                            object.on = true;
                            setModalOn(object);
                            setSelectedPyeongjeom('최근년도 자산회전율');

                            if (division === 'comment') {
                              getComment('최근년도 자산회전율');
                            }
                          }}
                          ratio={calculateRatio('최근년도 자산회전율')}
                          diff={diff.jasanTurning}
                          result={result.jasanTurning}
                          getExpertComment={(params) => {
                            getExpertComment(params);
                          }}
                          name="최근년도 자산회전율"
                          setGyejeongStatus={(params) => {
                            setGyejeongStatus(params);
                          }}
                        />

                        <ExpertProperty
                          setModalOn={(division) => {
                            const object = {
                              ...modalOn
                            };
                            object.division = division;
                            object.on = true;
                            setModalOn(object);
                            setSelectedPyeongjeom('건설기술개발투자비율');

                            if (division === 'comment') {
                              getComment('건설기술개발투자비율');
                            }
                          }}
                          ratio={calculateRatio('건설기술개발투자비율')}
                          diff={diff.techdev}
                          name="건설기술개발투자비율"
                          getExpertComment={(params) => {
                            getExpertComment(params);
                          }}
                          result={result.techdev}
                          setGyejeongStatus={(params) => {
                            setGyejeongStatus(params);
                          }}
                        />

                        <St_PyeongjeomProperty
                          onClick={() => {
                            setModalOn({
                              on: true,
                              division: 'overall'
                            });
                            getExpertComment('총평');
                          }}
                        >
                          <St_PyeongjeomPropertyName>
                            <span className="material-symbols-outlined">
                              add_circle
                            </span>
                            <span>총평 입력</span>
                          </St_PyeongjeomPropertyName>
                        </St_PyeongjeomProperty>
                      </ExpertPropertySection>
                    </ManagerGridDetailSection>
                  </>
                );
              })}
            </ManagerGrid>
          </TitleCard>
        </Grid>
      </Grid>
    </>
  );
}

export default ExpertDashboard;
