import { configureStore } from '@reduxjs/toolkit';
import headerSlice from './slice/headerSlice';
import modalSlice from './slice/modalSlice';
import rightDrawerSlice from './slice/rightDrawerSlice';
import leadsSlice from '../page/leads/leadSlice';
import jonghapSlice from './slice/jonghapSlice';
import jeonmoonSlice from './slice/jeonmoonSlice';
import jonghapJeonmoonSlice from './slice/jonghap_jeonmoonSlice';
import checkListSlice from './slice/checkListSlice';
import managerSlice from './slice/managerSlice';
import contactCheckListSlice from './slice/contactCheckListSlice';
import errorLogSlice, { ErrorLogState } from './slice/errorLogSlice';
import tutorialContactSlice, {
  TutorialState
} from './slice/tutorialContactSlice';
import memberManagementSlice, {
  MemberManagementState
} from './slice/memberManagementSlice';
import phoneSalesSlice, { PhoneSalesDataState } from './slice/phoneSalesSlice';
import emailSlice, { EmailState } from './slice/emailSlice';
import salesSlice, { SalesState } from './slice/salesSlice';
import emailLogSlice, { EmailLogState } from './slice/emailLogSlice';
import layoutSlice, { LayoutState } from './slice/layoutSlice';
import emailBoardSlice, { EmailBoardState } from './slice/emailBoardSlice';
import licensePageSlice, { LicensePageState } from './slice/licensePageSlice';
import reportEmailSlice, {
  ReportEmailState
} from './slice/reportSendEmailSlice';
import notificationSlice, {
  NotificationState
} from './slice/notificationSlice';
import expertInquirySlice, {
  ExpertInquiryState
} from './slice/expertInquirySlice';
import contactPageSlice, { ContactPageState } from './slice/contactPageSlice';
import managerManagementSlice, {
  ManagerManagementState
} from './slice/managerManagementSlice';
import useGuidePageSlice, {
  UseGuidePageState
} from './slice/useGuidePageSlice';
import testUserSlice, { TestUserState } from './slice/testUserSlice';
import userNotificationSlice, {
  UserNotificationState
} from './slice/userNotificationSlice';
import dailyBidSlice, { DailyBidState } from './slice/dailyBidSlice';

const combinedReducer = {
  header: headerSlice,
  rightDrawer: rightDrawerSlice,
  modal: modalSlice,
  lead: leadsSlice,
  jonghapSales: jonghapSlice,
  jeonmoonSales: jeonmoonSlice,
  jonghapJeonmoonSales: jonghapJeonmoonSlice,
  checkList: checkListSlice,
  contactCheckList: contactCheckListSlice,
  manager: managerSlice,
  errorLog: errorLogSlice,
  tutorialContact: tutorialContactSlice,
  memberManagement: memberManagementSlice,
  phoneSales: phoneSalesSlice,
  email: emailSlice,
  sales: salesSlice,
  emailLog: emailLogSlice,
  layout: layoutSlice,
  licensePage: licensePageSlice,
  emailBoard: emailBoardSlice,
  reportEmail: reportEmailSlice,
  notification: notificationSlice,
  expertInquiry: expertInquirySlice,
  contactPage: contactPageSlice,
  useGuidePage: useGuidePageSlice,
  managerManagement: managerManagementSlice,
  testUser: testUserSlice,
  userNotification: userNotificationSlice,
  dailyBid: dailyBidSlice
};

export default configureStore({
  reducer: combinedReducer
});

export interface StoreState {
  header: typeof headerSlice;
  dailyBidSlice: typeof dailyBidSlice;
  jonghapSlice: typeof jonghapSlice;
  lead: typeof leadsSlice;
  modal: typeof modalSlice;
  rightDrawer: typeof rightDrawerSlice;
  tutorialContact: TutorialState;
  memberManagement: MemberManagementState;
  testUser: TestUserState;
  jonghapSales: typeof jonghapSlice;
  jeonmoonSales: typeof jeonmoonSlice;
  jonghapJeonmoonSales: typeof jonghapJeonmoonSlice;
  checkList: typeof checkListSlice;
  contactCheckList: typeof contactCheckListSlice;
  manager: typeof managerSlice;
  errorLog: ErrorLogState;
  phoneSales: PhoneSalesDataState;
  email: EmailState;
  sales: SalesState;
  emailLog: EmailLogState;
  layout: LayoutState;
  emailBoard: EmailBoardState;
  licensePage: LicensePageState;
  reportEmail: ReportEmailState;
  notification: NotificationState;
  contactPage: ContactPageState;
  expertInquiry: ExpertInquiryState;
  managerManagement: ManagerManagementState;
  useGuidePage: UseGuidePageState;
  userNotification: UserNotificationState;
  dailyBid: DailyBidState;
}
