import { createSlice } from '@reduxjs/toolkit';

export interface FiltersType {
  area: string;
  address: string;
  ncrgskname: string;
  ncrgsmaster: string;
  license: string;
  ncrmasternum: string;
  ncrofftel: string;
  email: string;
  phoneNumber: string;
}

export interface SalesState {
  mode:
    | 'jonghap'
    | 'jeonmoon'
    | 'jonghap_jeonmoon'
    | 'phone'
    | 'contact'
    | 'contact_data';
  sort: boolean | null;
  filters: FiltersType;
  filterAreaName: string;
  limit: number;
  total_count: number;
}

const initialState: SalesState = {
  limit: 10,
  mode: 'jonghap',
  sort: null,
  total_count: 0,
  filterAreaName: '',
  filters: {
    area: '',
    address: '',
    ncrgskname: '',
    ncrgsmaster: '',
    license: '',
    ncrmasternum: '',
    ncrofftel: '',
    email: '',
    phoneNumber: '',
  },
};

export const salesSlice = createSlice({
  name: 'salesSlice',
  initialState: initialState,
  reducers: {
    updateMode: (
      state,
      action: {
        payload:
          | 'jonghap'
          | 'jeonmoon'
          | 'jonghap_jeonmoon'
          | 'phone'
          | 'contact'
          | 'contact_data';
        type: string;
      },
    ) => {
      state.mode = action.payload;
    },

    updateSort: (state, action: { payload: boolean | null; type: string }) => {
      state.sort = action.payload;
    },

    updateLimit: (state, action: { payload: number; type: string }) => {
      state.limit = action.payload;
    },

    resetFilterAreaname: (state, action) => {
      state.filterAreaName = '';
    },

    updateTotalCount: (state, action) => {
      state.total_count = action.payload;
    },

    resetFilters: (state, action) => {
      state.filters = {
        area: '',
        address: '',
        ncrgskname: '',
        ncrgsmaster: '',
        license: '',
        ncrmasternum: '',
        ncrofftel: '',
        email: '',
        phoneNumber: '',
      };
    },
  },
});

export const {
  updateTotalCount,
  updateMode,
  updateSort,
  resetFilterAreaname,
  resetFilters,
  updateLimit,
} = salesSlice.actions;

export default salesSlice.reducer;
