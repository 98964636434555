import {CheckCircleOutline, PlayArrow} from '@mui/icons-material';
import {ManagerButton} from '../../../components/Button';
import React from 'react';
import styled from 'styled-components';

const St_PyeongjeomProperty = styled.div<{active: boolean}>`
  width: 194px;
  height: 205px;
  border: ${(props) => (props.active ? '3px solid #1976d2' : '1px solid #d7d7d7')};
  border-radius: 5px;
  padding: 14px 20px;
  display: flex;
  flex-direction: column;
  justify-content: end;
  position: relative;
`;

const St_PyeongjeomPropertyName = styled.div`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: auto;
  margin-top: 5px;
`;

const St_PyeongjeomRatio = styled.span<PyeongjeomRatioProps>`
  font-size: ${(props) => (props.name === '실질자본금' ? '16px' : '16px')};
  font-weight: bold;
`;

const St_PyeongjeomRatioSection = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

const St_Diff = styled.div`
  font-weight: bold;
  color: #575757;
  font-size: 14px;
`;

const St_ButtonSection = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
`;

interface ExpertyProperty {
  active: boolean;
  setGyejeongStatus: (name: string) => void;
  name: string;
  ratio: number | string;
  diff?: number;
  setModalOn: (status: string) => void;
  result?: string;
  getExpertComment: (name: string) => void;
  onClick: () => void;
}

interface PyeongjeomRatioProps {
  name: string;
}

export function SelectableExpertProperty(props: ExpertyProperty) {
  return (
    <St_PyeongjeomProperty active={props.active} onClick={props.onClick}>
      <CheckCircleOutline color={props.active ? 'primary' : 'disabled'} />
      <St_PyeongjeomPropertyName>{props.name}</St_PyeongjeomPropertyName>

      <St_PyeongjeomRatioSection>
        <St_PyeongjeomRatio name={props.name}>
          {props.name !== '실질자본금'
            ? typeof props.ratio === 'number' &&
              (isNaN(props.ratio) || props.ratio === Infinity ? 0 : props.ratio.toFixed(2))
            : props.ratio}
          {props.name === '실질자본금' && ' 원'}
          {props.name === '영업이익대비 이자보상배율' && ' 배'}
          {props.name === '최근년도 자산회전율' && ' 회'}
          {props.name !== '실질자본금' &&
            props.name !== '최근년도 자산회전율' &&
            props.name !== '영업이익대비 이자보상배율' &&
            ' %'}
        </St_PyeongjeomRatio>
        {props.result === 'up' && <PlayArrow sx={{color: '#40C057', transform: 'rotate(-90deg)'}} />}

        {props.result === 'down' && <PlayArrow sx={{color: '#B80000', transform: 'rotate(-270deg)'}} />}
      </St_PyeongjeomRatioSection>

      <St_Diff>전분기 대비 : {props.diff === undefined ? 0 : props.diff.toFixed(2)} %</St_Diff>
      <St_ButtonSection>
        <ManagerButton
          onClick={() => {
            props.setModalOn('gyejeong');
          }}
          width="70px"
          height="30px"
        >
          계정과목
        </ManagerButton>
        <ManagerButton
          onClick={() => {
            props.onClick();
            props.getExpertComment(props.name);
            props.setGyejeongStatus(props.name);
            props.setModalOn('report');
          }}
          width="70px"
          height="30px"
        >
          보고서
        </ManagerButton>
      </St_ButtonSection>
    </St_PyeongjeomProperty>
  );
}
