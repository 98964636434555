import { createSlice } from '@reduxjs/toolkit';

export const managerSlice = createSlice({
  name: 'manager',
  initialState: {
    first_manager: [],
    third_manager: [],
    selected_manager: {
      id: 0,
      name: '선택',
    },
  },

  reducers: {
    loadFirstManager: (state, action) => {
      state.first_manager = action.payload;
    },

    loadThirdManager: (state, action) => {
      state.third_manager = action.payload;
    },

    updateSelectedManager: (state, action) => {
      state.selected_manager.id = action.payload.id;
      state.selected_manager.name = action.payload.value;
    },
  },
});

export const { loadFirstManager, loadThirdManager, updateSelectedManager } =
  managerSlice.actions;

export default managerSlice.reducer;
