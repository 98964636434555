/** Icons are imported separatly to reduce build time */
import React from 'react';
import BellIcon from '@heroicons/react/24/outline/BellIcon';
import Squares2X2Icon from '@heroicons/react/24/outline/Squares2X2Icon';
import ChatBubbleBottomCenterTextIcon from '@heroicons/react/24/outline/ChatBubbleBottomCenterTextIcon';
import ClipboardDocumentIcon from '@heroicons/react/24/outline/ClipboardDocumentIcon';
import BookOpenIcon from '@heroicons/react/24/outline/BookOpenIcon';
import ChatBubbleLeftRightIcon from '@heroicons/react/24/outline/ChatBubbleLeftRightIcon';
import PaperAirplaneIcon from '@heroicons/react/24/outline/PaperAirplaneIcon';
import UserGroup from '@heroicons/react/24/outline/UserGroupIcon';
import PuzzlePieceIcon from '@heroicons/react/24/outline/PuzzlePieceIcon';
import ChartBarSquareIcon from '@heroicons/react/24/outline/ChartBarSquareIcon';
import CalculatorIcon from '@heroicons/react/24/outline/CalculatorIcon';
import ReceiptPercentIcon from '@heroicons/react/24/outline/ReceiptPercentIcon';
import { ErrorOutline } from '@mui/icons-material';

const iconClasses = `h-6 w-6`;
const access = localStorage.getItem('access') ?? '최고 관리자';
const manager_division = localStorage.getItem('manager_division');
const array = access.split(',').map((item) => item.trim());

if (array.includes('최고 관리자')) {
  // array.push('관리자 메인')
  array.push('영업 관리');
  array.push('일일 공고 현황');
  array.push('회원관리');
  array.push('프로모션 코드');
  array.push('면허 관리');
  array.push('고객센터');
  array.push('전문가 고객센터');
  array.push('1:1 문의');
  array.push('세무 서비스');
  array.push('알림 현황');
  array.push('기업 컨설팅');
  array.push('종합영업관리');
  array.push('구독 관리');
  array.push('공지사항');
  array.push('에러 로그');
  array.push('카카오톡 공유 접속률');
  array.push('이메일');
  array.push('이용안내');
}

if (manager_division === 'sales_second') {
  array.push('영업실적현황');
}

if (
  manager_division === 'sales' ||
  manager_division === 'sales_second' ||
  manager_division === 'contact_sales'
) {
  array.push('영업');
}

if (manager_division === 'contact_sales') {
  array.push('고객센터(전문가)');
  array.push('전문가 메인');
  array.push('유저 현황');
  array.push('관리자 이메일 전송');
}

if (manager_division === 'tax') {
  array.push('세무 유저 현황');
}

const routes = [
  // {
  //   path: '/dashboard',
  //   icon: <Squares2X2Icon className={iconClasses}/>,
  //   name: '관리자 메인',
  // },

  {
    path: '/dashboard',
    icon: <Squares2X2Icon className={iconClasses} />,
    name: '영업실적현황'
  },

  {
    path: '/prompt', // url
    icon: <PaperAirplaneIcon className={iconClasses} />, // icon component
    name: '프롬프트 관리' // name that appear in Sidebar
  },

  {
    path: '/license', // url
    icon: <PuzzlePieceIcon className={iconClasses} />, // icon component
    name: '면허 관리' // name that appear in Sidebar
  },

  {
    path: '/sales_manager',
    icon: <ReceiptPercentIcon className={iconClasses} />,
    name: '영업'
  },

  {
    path: '/daily_bid',
    icon: <ReceiptPercentIcon className={iconClasses} />,
    name: '일일 공고 현황'
  },

  {
    path: '/expert_dashboard',
    icon: <Squares2X2Icon className={iconClasses} />,
    name: '유저 현황'
  },

  {
    path: '/tax_manager',
    icon: <ReceiptPercentIcon className={iconClasses} />,
    name: '세무 유저 현황'
  },

  {
    icon: <UserGroup className={iconClasses} />, // icon component
    name: '회원관리', // name that appear in Sidebar
    submenu: [
      {
        path: '/user', // url
        name: '회원 관리' // name that appear in Sidebar
      },

      {
        path: '/manager', // url
        name: '관리자 계정 관리' // name that appear in Sidebar
      },

      {
        path: '/test_user', // url
        name: '테스트 계정 관리' // name that appear in Sidebar
      }
    ]
  },

  {
    icon: <UserGroup className={iconClasses} />, // icon component
    name: '세무 관리자', // name that appear in Sidebar
    submenu: [
      {
        path: '/taxManagement', // url
        name: '세무 관리자 현황' // name that appear in Sidebar
      },

      {
        path: '/taxManagementSetting', // url
        name: '질문 설정' // name that appear in Sidebar
      }
    ]
  },

  {
    icon: <UserGroup className={iconClasses} />, // icon component
    name: '컨텐츠 관리자', // name that appear in Sidebar
    submenu: [
      {
        path: '/contentManagement', // url
        name: '컨텐츠 관리자 현황' // name that appear in Sidebar
      }
    ]
  },

  {
    path: '/sales',
    icon: <UserGroup className={iconClasses} />, // icon component
    name: '영업 관리', // name that appear in Sidebar
    submenu: [
      {
        path: '/jonghap_sales',
        name: '종합영업관리'
      }
    ]
  },

  {
    icon: <UserGroup className={iconClasses} />,
    name: '이메일',
    submenu: [
      {
        path: '/send_email', // url
        name: '이메일 전송' // name that appear in Sidebar
      },

      {
        path: '/report_send_email', // url
        name: '보고서 이메일 전송' // name that appear in Sidebar
      },

      {
        path: '/email_log', // url
        name: '이메일 전송 내역' // name that appear in Sidebar
      }
    ]
  },

  {
    icon: <UserGroup className={iconClasses} />,
    name: '관리자 이메일 전송',
    path: '/manager_send_email'
  },

  {
    icon: <ChatBubbleBottomCenterTextIcon className={iconClasses} />, // icon component
    name: '고객센터', // name that appear in Sidebar
    submenu: [
      {
        path: '/contact', // url
        name: '고객센터' // name that appear in Sidebar
      },

      {
        path: '/use_guidea', // url
        name: '이용안내' // name that appear in Sidebar
      },

      {
        path: '/tutorial_contact', // url
        name: '튜토리얼 문의' // name that appear in Sidebar
      },

      {
        path: '/kakaotalk_share_click_ratio', // url
        name: '카카오톡 공유 접속률' // name that appear in Sidebar
      }
    ]
  },

  {
    icon: <ChatBubbleBottomCenterTextIcon className={iconClasses} />, // icon component
    name: '전문가 고객센터', // name that appear in Sidebar
    submenu: [
      {
        path: '/expertInquiry', // url
        name: '문의글 공유' // name that appear in Sidebar
      },

      {
        path: '/pendingInquiry', // url
        name: '승인대기 문의 조회' // name that appear in Sidebar
      }
    ]
  },

  {
    path: '/notice', // url
    icon: <ClipboardDocumentIcon className={iconClasses} />, // icon component
    name: '공지사항' // name that appear in Sidebar
  },

  {
    path: '/coupon', // url
    icon: <ClipboardDocumentIcon className={iconClasses} />, // icon component
    name: '프로모션 코드' // name that appear in Sidebar
  },

  {
    path: '/magazine', // url
    icon: <BookOpenIcon className={iconClasses} />, // icon component
    name: '매거진' // name that appear in Sidebar
  },

  {
    path: '/inquiry', // url
    icon: <ChatBubbleLeftRightIcon className={iconClasses} />, // icon component
    name: '1:1 문의' // name that appear in Sidebar
  },

  {
    path: '/expertInquiryBoard', // url
    icon: <ChatBubbleLeftRightIcon className={iconClasses} />, // icon component
    name: '고객센터(전문가)' // name that appear in Sidebar
  },

  {
    path: '/consult', // url
    icon: <ChartBarSquareIcon className={iconClasses} />, // icon component
    name: '기업 컨설팅' // name that appear in Sidebar
  },

  {
    path: '/tax', // url
    icon: <CalculatorIcon className={iconClasses} />, // icon component
    name: '세무 서비스' // name that appear in Sidebar
  },
  {
    path: '/notification', // url
    icon: <BellIcon className={iconClasses} />, // icon component
    name: '알림 현황' // name that appear in Sidebar
  },

  {
    path: '/error_board', // url
    icon: <ErrorOutline className={iconClasses} />, // icon component
    name: '에러 로그' // name that appear in Sidebar
  }
];

const filteredRoutes = routes.filter((route) => array.includes(route.name));

export default filteredRoutes;
