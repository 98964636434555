import TitleCard from '../../components/Cards/TitleCard';
import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TabList } from './components/TabList';
import { StoreState } from '../../app/store';
import { getNotification, QueryParams } from './tools/getNotification';
import {
  NotificationDataType,
  updateCategory,
  updateLimit,
  updateNotificationData,
  updateOffset,
  updatePageLength,
  updateQuery,
  updateTarget
} from '../../app/slice/userNotificationSlice';
import BoardPagenation from '../../components/BoardPagenation';
import {
  ManagerGrid,
  ManagerGridButton,
  ManagerGridDetailSection,
  ManagerGridRow,
  ManagerGridRowSection
} from '../../components/GridType';
import { Info } from '@mui/icons-material';
import axios from 'axios';
import BoardSearchHeader from '../../components/BoardSearchHeader';
import { SmallTabList } from './components/SmallTabList';

function NotificationPage() {
  const {
    detailTab,
    query,
    index,
    total_count,
    selectedTab,
    Grid_COL,
    data,
    offset,
    limit,
    page_numbers,
    page_length
  } = useSelector((state: StoreState) => state.userNotification);

  const dispatch = useDispatch();

  const [targetUsers, setTargetUsers] = useState<string>();
  const [readedUsers, setReadedUsers] = useState<string>();

  async function convertMemberIds(params: string[]) {
    const option = {
      url: 'https://cpi.contax.ai/convert_member_id',
      method: 'POST',
      data: {
        member_ids: params
      },
      header: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data'
      }
    };

    try {
      const response = await axios(option);
      if (response.status === 200) {
        let query = '';
        response.data.forEach(
          (member: { representative_name: string; company_name: string }) => {
            query += `${member.representative_name}[${member.company_name}]`;
          }
        );

        return query;
      } else {
        return '';
      }
    } catch ({ message }) {
      return '';
    }
  }

  async function init() {
    const categories: string[] = [];
    const search_queries: string[] = [];

    if (selectedTab === '보고서') {
      categories.push('notification_type');
      search_queries.push('report');
    }

    if (selectedTab === '매거진') {
      categories.push('notification_type');
      search_queries.push('magazine');
    }

    if (selectedTab === '공지사항') {
      categories.push('notification_type');
      search_queries.push('notice');
    }

    if (selectedTab === '이용안내') {
      categories.push('notification_type');
      search_queries.push('useGuide');
    }

    if (query !== '') {
      categories.push('title');
      search_queries.push(query);
    }

    const params: QueryParams = {
      database: 'user_notification',
      categories: categories,
      search_queries: search_queries,
      offset: offset * limit,
      limit: limit,
      sort_option: 'id',
      sort: 'DESC'
    };

    const data = await getNotification(params);
    if (data.status === 'success') {
      dispatch(updateNotificationData(data.data));
      dispatch(updatePageLength(data.data.total_count));
    } else {
      dispatch(updateNotificationData([]));
      dispatch(updatePageLength(0));
    }
  }

  useEffect(() => {
    init();
  }, [offset, selectedTab, limit, query]);

  async function openDetailSection(index: number, item: NotificationDataType) {
    dispatch(updateTarget({ item: item, index: index }));
    setTargetUsers(await convertMemberIds(item.target_user));
    setReadedUsers(await convertMemberIds(item.readed_user ?? []));
  }

  return (
    <>
      <Grid spacing={2}>
        <TitleCard title="알림 현황">
          <TabList />
          <BoardSearchHeader
            total_count={total_count}
            title={selectedTab}
            defaultColumn="id"
            col={Grid_COL}
            query={query}
            limit={limit}
            limitOption={[5, 10, 20]}
            setLimit={(e) => {
              dispatch(updateLimit(e));
            }}
            onInput={(e) => {
              dispatch(updateQuery(e.target.value));
            }}
            onSelect={(e) => {
              dispatch(updateCategory(e.target.value));
            }}
          />
          <ManagerGrid
            col={Grid_COL}
            sortFunction={() => {
              console.log(123);
            }}
            size="large"
          >
            {data.length === 0 && (
              <div
                style={{
                  padding: '150px 0',
                  textAlign: 'center'
                }}
              >
                데이터가 존재하지 않습니다.
              </div>
            )}

            {data.length > 0 &&
              data.map((item, i) => (
                <>
                  <ManagerGridRowSection key={i}>
                    <ManagerGridRow
                      size="large"
                      width={15}
                      onClick={() => {
                        openDetailSection(i, item);
                      }}
                    >
                      {item.notification_type}
                    </ManagerGridRow>
                    <ManagerGridRow
                      size="large"
                      width={25}
                      onClick={() => {
                        openDetailSection(i, item);
                      }}
                    >
                      <div
                        style={{
                          display: 'block',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis'
                        }}
                      >
                        {item.title}
                      </div>
                    </ManagerGridRow>
                    <ManagerGridRow
                      size="large"
                      align="center"
                      color={i === 1 ? '#ff0000' : '#000'}
                      width={25}
                      onClick={() => {
                        openDetailSection(i, item);
                      }}
                    >
                      발송 완료
                    </ManagerGridRow>
                    <ManagerGridRow
                      size="large"
                      width={15}
                      onClick={() => {
                        openDetailSection(i, item);
                      }}
                    >
                      {item.regdate.length > 10
                        ? item.regdate.substring(0, 10)
                        : item.regdate}
                    </ManagerGridRow>
                    <ManagerGridRow
                      onClick={() => {
                        openDetailSection(i, item);
                      }}
                      size="large"
                      width={10}
                      textAlign="center"
                      align="center"
                    >
                      {item.target_user.length}
                    </ManagerGridRow>

                    <ManagerGridRow size="large" width={10} align="center">
                      <ManagerGridButton
                        onClick={() => {
                          openDetailSection(i, item);
                        }}
                        type="active"
                      >
                        상세보기
                      </ManagerGridButton>
                    </ManagerGridRow>
                  </ManagerGridRowSection>
                  <ManagerGridDetailSection open={i === index}>
                    <div
                      style={{
                        padding: '20px',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '20px'
                      }}
                    >
                      <SmallTabList />
                      {detailTab === '카카오' && (
                        <div style={{ textAlign: 'center' }}>
                          알림이 발송되지 않았습니다.
                        </div>
                      )}

                      {detailTab === '이메일' && (
                        <div style={{ textAlign: 'center' }}>
                          알림이 발송되지 않았습니다.
                        </div>
                      )}

                      {detailTab === '인앱 알림' && (
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '30px'
                          }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              fontSize: '20px',
                              flexDirection: 'column'
                            }}
                          >
                            <span
                              style={{
                                fontSize: '20px',
                                fontWeight: 'bold',
                                width: '150px'
                              }}
                            >
                              제목
                            </span>
                            <p style={{ fontSize: '18px' }}>
                              {'[' + item.notification_type + ']' + item.title}
                            </p>
                          </div>

                          <div
                            style={{
                              display: 'flex',
                              fontSize: '20px',
                              flexDirection: 'column'
                            }}
                          >
                            <span
                              style={{
                                fontSize: '20px',
                                fontWeight: 'bold',
                                width: '150px'
                              }}
                            >
                              내용
                            </span>
                            <p style={{ fontSize: '18px' }}>{item.content}</p>
                          </div>
                          <div
                            style={{
                              display: 'flex',
                              fontSize: '20px',
                              flexDirection: 'column'
                            }}
                          >
                            <span
                              style={{
                                fontSize: '20px',
                                fontWeight: 'bold',
                                width: '150px'
                              }}
                            >
                              앱 알림 확인
                            </span>
                            <p style={{ fontSize: '18px' }}>{readedUsers}</p>
                          </div>
                        </div>
                      )}
                      {detailTab === '알림 요약' && (
                        <>
                          <div
                            style={{
                              display: 'flex',
                              fontSize: '20px',
                              flexDirection: 'row'
                            }}
                          >
                            <span
                              style={{
                                fontSize: '20px',
                                fontWeight: 'bold',
                                width: '150px'
                              }}
                            >
                              알림 구분
                            </span>
                            <p>{item.notification_type}</p>
                          </div>

                          <div
                            style={{
                              display: 'flex',
                              fontSize: '20px',
                              flexDirection: 'row'
                            }}
                          >
                            <span
                              style={{
                                fontSize: '20px',
                                fontWeight: 'bold',
                                width: '150px'
                              }}
                            >
                              제목
                            </span>
                            <p>{item.title}</p>
                          </div>

                          <div
                            style={{
                              display: 'flex',
                              fontSize: '20px',
                              flexDirection: 'row'
                            }}
                          >
                            <span
                              style={{
                                fontSize: '20px',
                                fontWeight: 'bold',
                                width: '150px'
                              }}
                            >
                              내용
                            </span>
                            <p>{item.content}</p>
                          </div>

                          <div
                            style={{
                              display: 'flex',
                              fontSize: '20px',
                              flexDirection: 'row'
                            }}
                          >
                            <span
                              style={{
                                fontSize: '20px',
                                fontWeight: 'bold',
                                width: '150px'
                              }}
                            >
                              발송날짜
                            </span>
                            <p>{item.regdate}</p>
                          </div>

                          <div
                            style={{
                              display: 'flex',
                              fontSize: '20px',
                              flexDirection: 'row'
                            }}
                          >
                            <span
                              style={{
                                fontSize: '20px',
                                fontWeight: 'bold',
                                width: '150px'
                              }}
                            >
                              발송 대상
                            </span>
                            <p>{targetUsers}</p>
                          </div>

                          <div
                            style={{
                              display: 'flex',
                              fontSize: '20px',
                              flexDirection: 'row'
                            }}
                          >
                            <span
                              style={{
                                fontSize: '20px',
                                fontWeight: 'bold',
                                width: '150px'
                              }}
                            >
                              앱 알림 확인
                            </span>
                            <p>{readedUsers}</p>
                          </div>
                          <div
                            style={{
                              display: 'flex',
                              fontSize: '20px',
                              flexDirection: 'row'
                            }}
                          >
                            <span
                              style={{
                                fontSize: '20px',
                                fontWeight: 'bold',
                                width: '150px'
                              }}
                            >
                              발송 성공
                            </span>
                            <p
                              style={{ display: 'flex', alignItems: 'center' }}
                            >
                              {item.target_user.length}건 발송 성공
                              <Info
                                style={{
                                  marginLeft: '10px',
                                  color: 'royalblue'
                                }}
                              />
                            </p>
                          </div>
                        </>
                      )}
                    </div>
                  </ManagerGridDetailSection>
                </>
              ))}
          </ManagerGrid>
          <BoardPagenation
            offset={offset}
            pageNumbers={page_numbers}
            pageLength={page_length}
            setOffset={(offset: number) => {
              dispatch(updateTarget({ item: [], index: null }));
              dispatch(updateOffset(offset));
            }}
          />
        </TitleCard>
      </Grid>
    </>
  );
}

export default NotificationPage;
