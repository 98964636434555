import {
  ColProps,
  ManagerGrid,
  ManagerGridRow,
  ManagerGridRowSection
} from '../../../components/GridType';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { StoreState } from '../../../app/store';
import axios from 'axios';
import BoardPagenation from '../../../components/BoardPagenation';
import {
  calculatePagination,
  createPaginationSize
} from '../../../utils/common_API';

const COL: ColProps[] = [
  {
    title: '활동 날짜',
    id: 'regdate',
    width: 20,
    textAlign: 'left',
    sort: true,
    search: true
  },

  {
    title: '활동 내역',
    id: 'division',
    width: 30,
    textAlign: 'left',
    sort: false,
    search: false
  },

  {
    title: '상세 내용',
    id: 'detail',
    width: 50,
    textAlign: 'left',
    sort: false,
    search: false
  }
];

interface QueryParams {
  database: 'performance';
  categories: string[];
  search_queries: string[];
  offset: number;
  limit: number;
  sort_option: string;
  sort: 'DESC' | 'ASC';
}

interface DataType {
  id: number;
  email: string;
  regdate: string;
  division: string;
  detail: string;
}

export function PerformanceTable() {
  const { target_member } = useSelector(
    (state: StoreState) => state.memberManagement
  );

  const [data, setData] = useState<DataType[]>([]);
  const [total_count, setTotalCount] = useState(0);
  const [sortOption, setSortOptions] = useState('DESC');
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(10);
  const [page_numbers, setPageNumbers] = useState<number[]>([1, 2, 3, 4, 5]);
  const [page_length, setPageLength] = useState(1);
  const [division, setDivision] = useState('전체');

  async function getPerformanceData() {
    const params: QueryParams = {
      database: 'performance',
      categories: ['email', 'division'],
      search_queries: [
        target_member.email,
        division === '전체' ? '' : division
      ],
      offset: offset * limit,
      limit: limit,
      sort_option: 'id',
      sort: 'DESC'
    };

    const option = {
      url: 'https://cpi.contax.ai/get_board',
      method: 'POST',
      data: params,
      header: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data'
      }
    };

    try {
      const response = await axios(option);
      if (response.status === 200) {
        setData(response.data.data);
        setTotalCount(response.data.total_count);
        const length = calculatePagination(response!.data.total_count, limit);
        setPageLength(length);
        const array = createPaginationSize(offset, length);
        setPageNumbers(array);
      }
    } catch ({ message }) {
      setData([]);
    }
  }

  useEffect(() => {
    getPerformanceData();
  }, [division, offset, target_member.email]);

  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <span
          style={{
            width: '100px',
            fontSize: '16px',
            fontWeight: 'bold',
            marginRight: '30px'
          }}
        >
          활동 내역 선택
        </span>
        <select
          style={{
            borderRadius: '5px',
            padding: '5px 10px',
            width: '150px',
            fontSize: '16px',
            border: '1px solid #ddd'
          }}
          onChange={(event) => {
            setDivision(event.target.value);
          }}
        >
          <option value="전체">전체</option>
          <option value="공고 조회">공고 조회</option>
          <option value="사정률 조회">사정률 조회</option>
          <option value="보고서 생성">보고서 생성</option>
          <option value="공사원가 저장">공사원가 저장</option>
          <option value="구독 전환">구독 전환</option>
        </select>
      </div>

      <div style={{ display: 'flex', alignItems: 'center' }}>
        <span
          style={{
            width: '100px',
            fontSize: '16px',
            fontWeight: 'bold',
            marginRight: '30px'
          }}
        >
          알림 갯수
        </span>
        <span>0건</span>
      </div>

      <ManagerGrid
        col={COL}
        sortFunction={() => {
          console.log();
        }}
        size="medium"
      >
        {data.length === 0 && (
          <div
            style={{
              padding: '150px 0',
              textAlign: 'center'
            }}
          >
            데이터가 존재하지 않습니다.
          </div>
        )}

        {data.length > 0 &&
          data.map((item, index) => (
            <ManagerGridRowSection key={index}>
              <ManagerGridRow size="medium" width={20}>
                {item.regdate}
              </ManagerGridRow>

              <ManagerGridRow size="medium" width={30}>
                {item.division}
              </ManagerGridRow>

              <ManagerGridRow size="medium" width={50}>
                {item.detail}
              </ManagerGridRow>
            </ManagerGridRowSection>
          ))}
      </ManagerGrid>

      <BoardPagenation
        offset={offset}
        pageNumbers={page_numbers}
        pageLength={page_length}
        setOffset={setOffset}
      />
    </>
  );
}
