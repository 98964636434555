import { createSlice } from '@reduxjs/toolkit';

interface PhoneSalesDataTypes {
  abs_sumb_value?: number;
  address?: string;
  area?: string;
  classification?: '낮음' | '보통' | '높음';
  email?: string;
  fax?: string;
  id?: number;
  is_saved?: string;

  jeonmoon_license?: string;
  jeonmoon_valuation?: number;
  jonghap_license?: string;
  jonghap_valuation?: number;
  manager?: string;
  memo?: string;
  ncrgskname?: string;
  ncrgsmaster: string;
  ncrmasternum?: string;
  ncrofftel?: string;
  phone_number: string;
  reg_number: string;
  unique_key: string;
  recall_true: boolean;
  recall_date: string;
  missing_number: boolean;
}

export interface PhoneSalesDataState {
  phoneSales_data: PhoneSalesDataTypes[];
  phoneSales_total_count: number;
  phoneSales_index: number;
  selected_phoneSales_key: string;
  selected_phoneSales_title: string;
  current_page: number;
  page_length: number;
  page_numbers: number[];
  target: PhoneSalesDataTypes;
}

const initialState: PhoneSalesDataState = {
  target: {
    ncrgsmaster: '',
    phone_number: '',
    reg_number: '',
    unique_key: '',
    recall_true: false,
    recall_date: '',
    missing_number: false,
  },
  phoneSales_data: [],
  page_length: 0,
  page_numbers: [1],
  phoneSales_total_count: 0,
  phoneSales_index: 0,
  selected_phoneSales_key: '',
  selected_phoneSales_title: '-',
  current_page: 1,
};

export const phoneSalesSlice = createSlice({
  name: 'phoneSalesSlice',
  initialState: initialState,
  reducers: {
    updatePhoneSalesTarget: (state, action) => {
      state.target = action.payload;
    },

    updatePhoneSalesIndex: (state, action) => {
      state.phoneSales_index = action.payload;
    },

    loadPhoneSales: (state, action) => {
      state.phoneSales_data = action.payload.data;
      state.phoneSales_total_count = action.payload.total_count;
    },

    resetPhoneSales: state => {
      state.phoneSales_data = [];
      state.phoneSales_total_count = 0;
    },

    updatePageLength(state, action) {
      state.page_length = action.payload;
    },

    updatePageNumbers(state, action) {
      state.page_numbers = action.payload;
    },

    enterPhoneSalesExtraInfo: (
      state,
      action: {
        payload: {
          target:
            | 'recall_true'
            | 'recall_date'
            | 'missing_number'
            | 'address'
            | 'area'
            | 'phone_number'
            | 'classification'
            | 'email'
            | 'fax';
          value: never;
        };
        type: string;
      },
    ) => {
      const array: PhoneSalesDataTypes[] = [...state.phoneSales_data];
      array[state.phoneSales_index][action.payload.target] =
        action.payload.value;

      state.phoneSales_data = array;
    },

    updateSelectedPhoneSalesData: (state, action) => {
      state.selected_phoneSales_key = action.payload.key;
      state.selected_phoneSales_title = action.payload.title;
    },

    updateRecallTrue: (state, action) => {
      state.phoneSales_data[state.phoneSales_index].recall_true =
        action.payload;
    },

    updateRecallDate: (state, action) => {
      state.phoneSales_data[state.phoneSales_index].recall_date =
        action.payload;
    },

    updateMissingNumber: (state, action) => {
      state.phoneSales_data[state.phoneSales_index].missing_number =
        action.payload;
    },

    updatePhoneCurrentPage: (state, action) => {
      state.current_page = action.payload;
    },
  },
});

export const {
  updatePhoneSalesTarget,
  updatePageNumbers,
  updatePageLength,
  updatePhoneCurrentPage,
  updateSelectedPhoneSalesData,
  updateMissingNumber,
  updatePhoneSalesIndex,
  loadPhoneSales,
  resetPhoneSales,
  enterPhoneSalesExtraInfo,
  updateRecallDate,
  updateRecallTrue,
} = phoneSalesSlice.actions;

export default phoneSalesSlice.reducer;
