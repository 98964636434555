import { createSlice } from '@reduxjs/toolkit';

export interface LicenseType {
  license_ctgry: string;
  license_type: string;
  license_area: string;
  license_name: string;
}
export interface UserType {
  [key: string]: string | number | string[] | undefined | null | boolean;
  member_of_manager: string;
  user_id: number;
  email: string;
  name: string;
  position: string;
  phone_number: string;
  business_number: string;
  company_name: string;
  representative_name: string;
  representative_phone: string;
  address: string;
  password: string;
  fax_number: string;
  company_phone_number: string;
  business_type: string;
  reg_date: string;
  sms_kakao_agree: number;
  license_names: string[];
  manager?: string;
  subscription: string;
  subscription_end: string;
  is_login: false;
}

export interface SortType {
  option: string;
  sort: 'DESC' | 'ASC';
}

export interface UserDivisionType {
  business: SortType;
  first: SortType;
  contax: SortType;
}

export interface SignupErrorType {
  target: string;
  error: string;
}

export interface ManagerManagementState {
  selected_contents: string[];
  status: 'read' | 'write' | 'modify';
  users: UserType[];
  test_users: UserType[];
  managers: UserType[];
  user_total_count: number;
  manager_total_count: number;
  test_user_total_count: number;
  users_pending: 'loading' | 'success' | 'failed';
  managers_pending: 'loading' | 'success' | 'failed';
  test_users_pending: 'loading' | 'success' | 'failed';
  page_length: number;
  page_numbers: number[];
  tab: '비즈니스' | '퍼스트' | '콘택스';
  sort: UserDivisionType;
  original_target_member: UserType;
  target_member: UserType;
  create_member: UserType;
  manager: string;
  error: SignupErrorType;
}

const initialState: ManagerManagementState = {
  selected_contents: [],
  status: 'read',
  error: {
    target: '',
    error: ''
  },
  users: [],
  test_users: [],
  managers: [],
  manager: '',
  user_total_count: 0,
  manager_total_count: 0,
  test_user_total_count: 0,
  users_pending: 'success',
  managers_pending: 'success',
  test_users_pending: 'success',
  page_length: 0,
  page_numbers: [1],
  tab: '비즈니스',
  sort: {
    business: {
      option: 'reg_date',
      sort: 'DESC'
    },

    first: {
      option: 'reg_date',
      sort: 'DESC'
    },

    contax: {
      option: 'reg_date',
      sort: 'DESC'
    }
  },

  original_target_member: {
    is_login: false,
    member_of_manager: '',
    subscription: '비즈니스',
    fax_number: '',
    representative_phone: '',
    password: '',
    user_id: 0,
    email: '',
    name: '',
    position: '',
    phone_number: '',
    business_number: '',
    company_name: '',
    representative_name: '',
    address: '',
    company_phone_number: '',
    business_type: '',
    reg_date: '',
    sms_kakao_agree: 0,
    subscription_end: '',
    license_names: []
  },

  target_member: {
    is_login: false,
    member_of_manager: '',
    subscription: '비즈니스',
    fax_number: '',
    representative_phone: '',
    password: '',
    user_id: 0,
    email: '',
    name: '',
    position: '',
    phone_number: '',
    business_number: '',
    company_name: '',
    representative_name: '',
    address: '',
    company_phone_number: '',
    business_type: '',
    reg_date: '',
    sms_kakao_agree: 0,
    license_names: [],
    subscription_end: ''
  },

  create_member: {
    is_login: false,
    member_of_manager: '',
    subscription: '비즈니스',
    fax_number: '',
    representative_phone: '',
    password: '',
    user_id: 0,
    email: '',
    name: '',
    position: '',
    phone_number: '',
    business_number: '',
    company_name: '',
    representative_name: '',
    address: '',
    company_phone_number: '',
    business_type: '',
    reg_date: '',
    sms_kakao_agree: 0,
    license_names: [],
    subscription_end: ''
  }
};

export const managerManagementSlice = createSlice({
  name: 'managerManagementSlice',
  initialState: initialState,
  reducers: {
    updateMemberPageStatus: (state, action) => {
      state.status = action.payload;
    },
    resetTargetManager: (state) => {
      state.selected_contents = [];
      state.target_member = {
        is_login: false,
        member_of_manager: '',
        subscription: '비즈니스',
        fax_number: '',
        representative_phone: '',
        password: '',
        user_id: 0,
        email: '',
        name: '',
        position: '',
        phone_number: '',
        business_number: '',
        company_name: '',
        representative_name: '',
        address: '',
        company_phone_number: '',
        business_type: '',
        reg_date: '',
        sms_kakao_agree: 0,
        license_names: [],
        subscription_end: ''
      };
    },

    deleteSelectedContents: (state, action) => {
      const array = [...state.selected_contents];
      state.selected_contents = array.filter((jtem) => {
        return jtem !== action.payload;
      });
    },

    updateSelectedContents: (state, action) => {
      const array = [...state.selected_contents];

      array.push(action.payload);
      state.selected_contents = array;
    },

    updateSignupError: (state, action) => {
      state.error = action.payload;
    },

    updateUser: (state, action) => {
      state.users = action.payload.data;
      state.user_total_count = action.payload.total_count;
    },

    updatePageLength(state, action) {
      state.page_length = action.payload;
    },

    updatePageNumbers(state, action) {
      state.page_numbers = action.payload;
    },

    updateTab(state, action) {
      state.tab = action.payload;
    },

    updateSort: (state, action) => {
      if (state.tab === '비즈니스') {
        state.sort.business.option = action.payload.option;
        state.sort.business.sort = action.payload.sort;
      }

      if (state.tab === '퍼스트') {
        state.sort.first.option = action.payload.option;
        state.sort.first.sort = action.payload.sort;
      }

      if (state.tab === '콘택스') {
        state.sort.contax.option = action.payload.option;
        state.sort.contax.sort = action.payload.sort;
      }
    },

    updateCreateMember: (state, action) => {
      state.create_member = action.payload;
    },

    updateTargetMember: (state, action) => {
      state.target_member = action.payload;
    },

    updateOriginalTargetMember: (state, action) => {
      state.original_target_member = action.payload;
    },

    updateTargetMemberInformation: (
      state,
      action: { payload: { id: string; value: string | number } }
    ) => {
      const info = state.target_member;

      if (
        action.payload.id !== 'user_id' &&
        action.payload.id !== 'sms_kakao_agree' &&
        typeof action.payload.value === 'string'
      ) {
        info[action.payload.id] = action.payload.value.toString();

        state.target_member = info;
        return;
      }

      if (
        (action.payload.id === 'user_id' ||
          action.payload.id === 'sms_kakao_agree') &&
        typeof action.payload.value === 'number'
      ) {
        info[action.payload.id] = action.payload.value;

        state.target_member = info;
        return;
      }
    },

    updateCreateMemberInformation: (
      state,
      action: { payload: { id: string; value: string | number } }
    ) => {
      const info = state.create_member;

      if (
        action.payload.id !== 'user_id' &&
        action.payload.id !== 'sms_kakao_agree' &&
        typeof action.payload.value === 'string'
      ) {
        info[action.payload.id] = action.payload.value.toString();

        state.create_member = info;
        return;
      }

      if (
        (action.payload.id === 'user_id' ||
          action.payload.id === 'sms_kakao_agree') &&
        typeof action.payload.value === 'number'
      ) {
        info[action.payload.id] = action.payload.value;
        state.create_member = info;
        return;
      }
    },

    updateManager: (state, action) => {
      state.manager = action.payload;
      state.target_member.manager = action.payload;
    },

    resetCreateMemberInformation: (state) => {
      state.create_member = {
        is_login: false,
        member_of_manager: '',
        subscription_end: '',
        subscription: '비즈니스',
        fax_number: '',
        representative_phone: '',
        password: '',
        user_id: 0,
        email: '',
        name: '',
        position: '',
        phone_number: '',
        business_number: '',
        company_name: '',
        representative_name: '',
        address: '',
        company_phone_number: '',
        business_type: '',
        reg_date: '',
        sms_kakao_agree: 0,
        license_names: []
      };
    }
  }
});

export const {
  deleteSelectedContents,
  updateSelectedContents,
  updateCreateMember,
  updateMemberPageStatus,
  updateSignupError,
  updateManager,
  updateCreateMemberInformation,
  resetCreateMemberInformation,
  updateOriginalTargetMember,
  updateTargetMemberInformation,
  updateTargetMember,
  updateTab,
  updateUser,
  updatePageLength,
  updatePageNumbers,
  resetTargetManager
} = managerManagementSlice.actions;

export default managerManagementSlice.reducer;
