import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StoreState } from '../../../app/store';
import { DeleteButton, ManagerGridButton } from '../../../components/GridType';
import { PencilIcon } from '@heroicons/react/16/solid';
import {
  resetCreateMemberInformation,
  updateCreateMember,
  updateMemberPageStatus,
  updateSelectedLicenses
} from '../../../app/slice/memberManagementSlice';
import { deleteUserData } from '../../../utils/user_API';
import { MemberInformation } from '../../../components/Layout/MemberInformation';
import { SelectedMember } from '../../email/component/SelectedMember';

interface MemberDetailSectionProps {
  searchMembers: () => void;
  closeDetailSection: () => void;
}

export function MemberDetailSection(props: MemberDetailSectionProps) {
  const members = useSelector((state: StoreState) => state.memberManagement);
  const dispatch = useDispatch();
  const target_members = members.target_member;

  async function removeMember() {
    if (!window.confirm('정말 삭제하시겠습니까?')) {
      return;
    } else {
      const response = await deleteUserData(target_members.email);
      if (response.status === 'member deleted') {
        alert('성공적으로 삭제하였습니다.');
        props.searchMembers();
        props.closeDetailSection();
      }
    }
  }

  return (
    <>
      <div style={{ display: 'flex', gap: '20px', flexDirection: 'column' }}>
        <MemberInformation
          title="회원번호"
          value={target_members.user_id.toString()}
        />

        <MemberInformation
          title="아이디(이메일)"
          value={target_members.email}
        />

        <MemberInformation title="이름" value={target_members.name} />

        <MemberInformation
          title="전화번호"
          value={target_members.phone_number}
        />

        <MemberInformation
          title="구독 상태"
          value={target_members.subscription}
        />

        <MemberInformation
          title="구독 기간"
          value={target_members.subscription_end}
        />

        <div
          style={{
            margin: '20px 0',
            height: '2px',
            backgroundColor: '#eee'
          }}
        />

        <MemberInformation
          title="담당 관리자"
          value={target_members.member_of_manager}
        />

        <MemberInformation title="회사명" value={target_members.company_name} />
        <MemberInformation
          title="회사 전화번호"
          value={target_members.company_phone_number}
        />

        <MemberInformation
          title="대표자명"
          value={target_members.representative_name}
        />

        <MemberInformation
          title="대표자 전화번호"
          value={target_members.representative_phone}
        />

        <MemberInformation
          title="사업자등록번호"
          value={target_members.business_number}
        />

        <MemberInformation title="팩스번호" value={target_members.fax_number} />
        <MemberInformation title="직위" value={target_members.position} />

        <MemberInformation title="주소" value={target_members.address} />

        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span style={{ width: '250px', fontWeight: 'bold' }}>면허</span>
          <div style={{ display: 'flex' }}>
            {target_members.license_names.map((item) => (
              <SelectedMember key={item} name={item} email={''} />
            ))}
          </div>
        </div>
      </div>

      <div style={{ justifyContent: 'end', display: 'flex', gap: '20px' }}>
        <ManagerGridButton
          type="active"
          onClick={() => {
            dispatch(resetCreateMemberInformation());
            dispatch(updateMemberPageStatus('modify'));
            dispatch(updateCreateMember(target_members));
            target_members.license_names.forEach((item) => {
              dispatch(updateSelectedLicenses(item));
            });
          }}
        >
          <PencilIcon style={{ width: '20px' }} />
          수정
        </ManagerGridButton>

        <DeleteButton
          onClick={() => {
            removeMember();
          }}
        />
      </div>
    </>
  );
}
