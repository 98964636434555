import { themeChange } from 'theme-change';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Bars3Icon from '@heroicons/react/24/outline/Bars3Icon';

import { Button } from '@mui/material';
import {
  NotificationsActive,
  NotificationsActiveRounded,
  NotificationsNone
} from '@mui/icons-material';
import { updateNotificationOn } from '../app/slice/layoutSlice';
import { NotificationModal } from '../components/Layout/NotificationModal';
import { QueryParams } from '../utils/inquiry_API';
import { getNotification } from '../utils/notification_API';
import { updateNotificationData } from '../app/slice/notificationSlice';

function Header() {
  const dispatch = useDispatch();
  const { notification_on } = useSelector((state) => state.layout);
  const { pageTitle } = useSelector((state) => state.header);
  const [id, setId] = useState(localStorage.getItem('id'));
  const [currentTheme, setCurrentTheme] = useState(
    localStorage.getItem('theme')
  );

  useEffect(() => {
    themeChange(false);
    if (currentTheme === null) {
      if (
        window.matchMedia &&
        window.matchMedia('(prefers-color-scheme: dark)').matches
      ) {
        setCurrentTheme('dark');
      } else {
        setCurrentTheme('light');
      }
    }

    setId(localStorage.getItem('id'));
    // 👆 false parameter is required for react project

    getNotificationDataNotReaded(localStorage.getItem('id'));
  }, [pageTitle]);

  function logoutUser() {
    localStorage.clear();
    window.location.reload();
  }

  async function getNotificationDataNotReaded(id) {
    const params = {
      database: '알림',
      categories: ['receiver', 'readed'],
      search_queries: [Number(id), 'false'],
      offset: 0,
      limit: 10,
      sort_option: 'regdate',
      sort: 'DESC'
    };
    const result = await getNotification(params);
    if (result !== undefined && result.status === 'success') {
      if (result.data.total_count > 0) {
        setIsNewNotification(true);
      } else {
        setIsNewNotification(false);
      }
    } else {
      setIsNewNotification(false);
    }
  }

  const [isNewNotification, setIsNewNotification] = useState(false);

  return (
    <>
      <div className="navbar w-full block justify-between  bg-base-100  z-10 shadow-md ">
        {/* Menu toogle for mobile view or small screen */}
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <label
              htmlFor="left-sidebar-drawer"
              className="btn btn-primary drawer-button lg:hidden"
            >
              <Bars3Icon className="h-5 inline-block w-5" />
            </label>
            <h1 className="text-2xl font-semibold ml-2">{pageTitle}</h1>
          </div>
          <div>
            {isNewNotification ? (
              <NotificationsNone
                onClick={() => {
                  notification_on
                    ? dispatch(updateNotificationOn(false))
                    : dispatch(updateNotificationOn(true));
                }}
                style={{ cursor: 'pointer' }}
                fontSize={'medium'}
              />
            ) : (
              <NotificationsNone
                onClick={() => {
                  notification_on
                    ? dispatch(updateNotificationOn(false))
                    : dispatch(updateNotificationOn(true));
                }}
                style={{ cursor: 'pointer' }}
                fontSize={'medium'}
              />
            )}

            {isNewNotification && (
              <span
                style={{
                  position: 'absolute',
                  width: '10px',
                  height: '10px',
                  borderRadius: '50px',
                  backgroundColor: 'red'
                }}
              ></span>
            )}

            {notification_on && <NotificationModal id={id} />}

            <Button
              sx={{ marginLeft: '20px', textAlign: 'right' }}
              variant="contained"
              onClick={logoutUser}
            >
              로그아웃
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}

export default Header;
