import { createSlice } from '@reduxjs/toolkit';
import { ColumnType } from '../../components/Layout/Table';

export interface NotificationDataType {
  id: number;
  division: string;
  writter: string;
  regdate: string;
  readed: boolean;
  content_id: string;
}

export interface NotificationState {
  data: NotificationDataType[];
  tabList: string[];
  selectedTab: '전체' | '보고서' | '매거진' | '공지사항' | '이용안내';
  Grid_COL: ColumnType[];
}

const initialState: NotificationState = {
  data: [],
  tabList: ['전체', '보고서', '매거진', '공지사항', '이용안내'],
  selectedTab: '전체',
  Grid_COL: [
    {
      display: true,
      title: '구분',
      id: 'division',
      width: 10,
      textAlign: 'left',
      sort: true
    },
    {
      display: true,
      title: '제목',
      id: 'title',
      width: 10,
      textAlign: 'left',
      sort: true
    },
    {
      display: true,
      title: '내용',
      id: 'content',
      width: 10,
      textAlign: 'left',
      sort: true
    },
    {
      display: true,
      title: '발송 유저 수 ',
      id: 'target_count',
      width: 10,
      textAlign: 'left',
      sort: true
    },
    {
      display: true,
      title: '인앱 알림 읽은 유저 수',
      id: 'in_app_count',
      width: 10,
      textAlign: 'left',
      sort: true
    },

    {
      display: true,
      title: '인앱 알림 발송 성공',
      id: 'in_app_ok',
      width: 10,
      textAlign: 'left',
      sort: true
    },

    {
      display: true,
      title: '카카오톡 발송 성공',
      id: 'kakao_ok',
      width: 10,
      textAlign: 'left',
      sort: true
    },

    {
      display: true,
      title: '카카오톡 발송 성공',
      id: 'email_ok',
      width: 10,
      textAlign: 'left',
      sort: true
    },

    {
      display: true,
      title: '상세보기',
      id: '',
      width: 10,
      textAlign: 'center',
      sort: false
    }
  ]
};
export const notificationSlice = createSlice({
  name: 'notification',
  initialState: initialState,
  reducers: {
    updateTab: (state, action) => {
      state.selectedTab = action.payload;
    },

    resetNotificationData: (state) => {
      state.data = [];
    },
    updateNotificationData: (state, action) => {
      const array = [...state.data];

      action.payload.forEach((item: NotificationDataType) => {
        array.push(item);
      });

      state.data = array;
    },

    updateReadedStatus: (state, action) => {
      const array = [...state.data];
      array.forEach((item) => {
        if (item.id === action.payload) {
          item.readed = true;
        }
      });

      state.data = array;
    }
  }
});

export const {
  updateTab,
  resetNotificationData,
  updateNotificationData,
  updateReadedStatus
} = notificationSlice.actions;

export default notificationSlice.reducer;
