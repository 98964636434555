import styled from 'styled-components';
import TitleCard from '../../../components/Cards/TitleCard';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import TrashIcon from '../../../asset/delete.png';
import PlusIcon from '../../../asset/plus.png';
import { MenuItem, Select } from '@mui/material';

const ChangeModeButton = styled.button`
  width: 140px;
  height: 40px;
  color: ${(props) => (props.mode ? '#fff' : '#999')};
  background-color: ${(props) => (props.mode ? '#999' : 'white')};
  border: 1px solid #999;
`;

const ChangeModeButtonSection = styled.div`
  display: flex;
  gap: 20px;
  margin-bottom: 40px;
`;

function CheckItem({ mode, setMode }) {
  const [division, setDivision] = useState('first');
  const [firstData, setFirstData] = useState([]);
  const [secondData, setSecondData] = useState([]);
  const [renderedItem, setRenderedItem] = useState([]);

  async function loadCheckList() {
    const option = {
      url: 'https://cpi.contax.ai/load_sales_check_item_list',
      method: 'GET',
      header: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    };

    const firstArray = [];
    const secondArray = [];

    await axios(option).then((response) => {
      if (response.status === 200) {
        response.data.forEach((element) => {
          if (element.division === 'first') {
            firstArray.push(element);
          }

          if (element.division === 'second') {
            secondArray.push(element);
          }
        });

        setFirstData(firstArray);
        setSecondData(secondArray);
      }
    });
  }

  async function insertCheckListItem(parent) {
    const option = {
      url: 'https://cpi.contax.ai/insert_sales_check_item',
      method: 'POST',
      params: {
        name: '항목',
        division: division,
        order_id:
          division === 'first' ? firstData.length + 1 : secondData.length + 1,
        parent: parent,
        type: 'check'
      },
      header: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    };

    const firstArray = [...firstData];
    const secondArray = [...secondData];

    await axios(option).then((response) => {
      if (response.status === 200 && division === 'first') {
        firstArray.push({
          id: response.data,
          division: 'first',
          name: '항목',
          parent: parent
        });

        setFirstData(firstArray);
      }

      if (response.status === 200 && division === 'second') {
        secondArray.push({
          id: response.data,
          division: 'second',
          name: '항목',
          parent: parent
        });

        setSecondData(secondArray);
      }
    });
  }

  async function updateCheckListItem(value, id, type) {
    const option = {
      url: 'https://cpi.contax.ai/update_sales_check_item',
      method: 'PUT',
      params: {
        id: Number(id),
        name: value,
        type: type
      },
      header: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    };

    await axios(option).then((response) => {
      if (response.status === 200) {
      }
    });
  }

  async function deleteCheckListItem(id) {
    const option = {
      url: 'https://cpi.contax.ai/delete_sales_check_item',
      method: 'DELETE',
      params: {
        id: Number(id)
      },
      header: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    };

    await axios(option).then((response) => {
      if (response.status === 200) {
        const firstArray = [...firstData];
        const firstFiltered = firstArray.filter((item) => item.id !== id);
        setFirstData(firstFiltered);

        const secondArray = [...secondData];
        const secondFiltered = secondArray.filter((item) => item.id !== id);
        setSecondData(secondFiltered);

        let array = [];

        firstFiltered.forEach((item) => {
          const children = firstFiltered.filter(
            (child) => Number(child.parent) === item.id
          );
          children.forEach((child) => {
            if (!array.includes(child.id)) {
              array.push(child.id);
            }
          });
        });

        secondFiltered.forEach((item) => {
          const children = secondFiltered.filter(
            (child) => Number(child.parent) === item.id
          );
          children.forEach((child) => {
            if (!array.includes(child.id)) {
              array.push(child.id);
            }
          });
        });

        setRenderedItem(array);
      }
    });
  }

  useEffect(() => {
    loadCheckList();

    let array = [];

    firstData.forEach((item) => {
      const children = firstData.filter(
        (child) => Number(child.parent) === item.id
      );
      children.forEach((child) => {
        if (!array.includes(child.id)) {
          array.push(child.id);
        }
      });
    });

    secondData.forEach((item) => {
      const children = secondData.filter(
        (child) => Number(child.parent) === item.id
      );
      children.forEach((child) => {
        if (!array.includes(child.id)) {
          array.push(child.id);
        }
      });
    });

    setRenderedItem(array);
  }, [division]);

  useEffect(() => {
    // 필요한 모든 계산을 여기에서 수행하고 결과를 상태에 저장
    let array = [];

    firstData.forEach((item) => {
      const children = firstData.filter(
        (child) => Number(child.parent) === item.id
      );
      children.forEach((child) => {
        if (!array.includes(child.id)) {
          array.push(child.id);
        }
      });
    });

    secondData.forEach((item) => {
      const children = secondData.filter(
        (child) => Number(child.parent) === item.id
      );
      children.forEach((child) => {
        if (!array.includes(child.id)) {
          array.push(child.id);
        }
      });
    });

    setRenderedItem(array);
  }, [firstData, secondData]);

  return (
    <TitleCard title="체크리스트">
      <ChangeModeButtonSection>
        <ChangeModeButton
          mode={mode === 'search'}
          onClick={() => {
            setMode('search');
          }}
        >
          검색
        </ChangeModeButton>
        <ChangeModeButton
          mode={mode === 'memo'}
          onClick={() => {
            setMode('memo');
          }}
        >
          관리자 현황
        </ChangeModeButton>
        <ChangeModeButton
          mode={mode === 'performance'}
          onClick={() => {
            setMode('performance');
          }}
        >
          영업실적
        </ChangeModeButton>
        <ChangeModeButton
          mode={mode === 'share'}
          onClick={() => {
            setMode('share');
          }}
        >
          1차 데이터 공유
        </ChangeModeButton>
        <ChangeModeButton
          mode={mode === 'contact'}
          onClick={() => {
            setMode('contact');
          }}
        >
          방문 데이터 공유
        </ChangeModeButton>
        <ChangeModeButton
          mode={mode === 'second'}
          onClick={() => {
            setMode('second');
          }}
        >
          방문 데이터
        </ChangeModeButton>
        <ChangeModeButton
          mode={mode === 'trash'}
          onClick={() => {
            setMode('trash');
          }}
        >
          휴지통
        </ChangeModeButton>
        <ChangeModeButton
          mode={mode === 'check_item'}
          onClick={() => {
            setMode('check_item');
          }}
        >
          체크리스트
        </ChangeModeButton>
      </ChangeModeButtonSection>

      <ChangeModeButtonSection>
        <ChangeModeButton
          mode={mode === 'first'}
          onClick={() => {
            setDivision('first');
          }}
        >
          1차 체크리스트
        </ChangeModeButton>
        <ChangeModeButton
          mode={mode === 'second'}
          onClick={() => {
            setDivision('second');
          }}
        >
          2차 체크리스트
        </ChangeModeButton>
      </ChangeModeButtonSection>

      {division === 'first' &&
        firstData.map((item, index) => {
          const array = [...renderedItem];

          const children = firstData.filter((child) => {
            if (Number(child.parent) === item.id) {
              array.push(child);
              return child;
            }
          });

          if (array.includes(item.id)) {
            return null;
          } else {
            return (
              <div
                key={index}
                style={{ display: 'flex', flexDirection: 'column' }}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    padding: '10px 0'
                  }}
                >
                  <div style={{ border: '1px solid #ddd', padding: '14px' }}>
                    <input
                      type="text"
                      defaultValue={item.name}
                      id={item.id}
                      onBlur={(e) => {
                        updateCheckListItem(
                          e.target.value,
                          e.target.id,
                          item.type
                        );
                      }}
                    />
                  </div>

                  <span
                    onClick={(e) => {
                      deleteCheckListItem(item.id);
                    }}
                    style={{
                      backgroundColor: '#ddd',
                      padding: '15px',
                      cursor: 'pointer'
                    }}
                  >
                    <img src={TrashIcon} alt="trash" />
                  </span>
                  <span
                    onClick={() => {
                      insertCheckListItem(item.id);
                    }}
                    style={{
                      backgroundColor: '#ddd',
                      padding: '15px',
                      cursor: 'pointer'
                    }}
                  >
                    <img src={PlusIcon} alt="trash" />
                  </span>

                  {children.length === 0 && (
                    <Select
                      defaultValue="check"
                      onChange={(e) => {
                        updateCheckListItem(item.name, item.id, e.target.value);
                      }}
                    >
                      <MenuItem value="check">체크박스</MenuItem>
                      <MenuItem value="input">텍스트 입력</MenuItem>
                      <MenuItem value="time">시간 입력</MenuItem>
                    </Select>
                  )}
                </div>
                {/* 자식 아이템이 있으면 바로 아래에 렌더링 */}
                {children.length > 0 &&
                  children.map((child, childIndex) => (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <div
                        style={{
                          border: '1px solid #ddd',
                          padding: '14px 18px 14px 18px'
                        }}
                      >
                        <span>ㄴ</span>
                      </div>

                      <div
                        style={{ border: '1px solid #ddd', padding: '14px' }}
                      >
                        <input
                          type="text"
                          defaultValue={child.name}
                          id={child.id}
                          onBlur={(e) => {
                            updateCheckListItem(
                              e.target.value,
                              e.target.id,
                              child.type
                            );
                          }}
                        />
                      </div>

                      <span
                        onClick={(e) => {
                          deleteCheckListItem(child.id);
                        }}
                        style={{
                          backgroundColor: '#ddd',
                          padding: '15px',
                          cursor: 'pointer'
                        }}
                      >
                        <img src={TrashIcon} alt="trash" />
                      </span>
                      <span
                        onClick={() => {
                          insertCheckListItem(child.id);
                        }}
                        style={{
                          backgroundColor: '#ddd',
                          padding: '15px',
                          cursor: 'pointer'
                        }}
                      >
                        <img src={PlusIcon} alt="trash" />
                      </span>

                      <Select
                        defaultValue="check"
                        onChange={(e) => {
                          updateCheckListItem(
                            child.name,
                            child.id,
                            e.target.value
                          );
                        }}
                      >
                        <MenuItem value="check">체크박스</MenuItem>
                        <MenuItem value="input">텍스트 입력</MenuItem>
                        <MenuItem value="time">시간 입력</MenuItem>
                      </Select>

                      {children.length === 0 && (
                        <Select
                          defaultValue="check"
                          onChange={(e) => {
                            updateCheckListItem(
                              child.name,
                              child.id,
                              e.target.value
                            );
                          }}
                        >
                          <MenuItem value="check">체크박스</MenuItem>
                          <MenuItem value="input">텍스트 입력</MenuItem>
                          <MenuItem value="time">시간 입력</MenuItem>
                        </Select>
                      )}
                    </div>
                  ))}
              </div>
            );
          }
        })}

      {division === 'second' &&
        secondData.map((item, index) => {
          const array = [...renderedItem];

          const children = secondData.filter((child) => {
            if (Number(child.parent) === item.id) {
              array.push(child);
              return child;
            }
          });

          if (array.includes(item.id)) {
            return null;
          } else {
            return (
              <div
                key={index}
                style={{ display: 'flex', flexDirection: 'column' }}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    padding: '10px 0'
                  }}
                >
                  <div style={{ border: '1px solid #ddd', padding: '14px' }}>
                    <input
                      type="text"
                      defaultValue={item.name}
                      id={item.id}
                      onBlur={(e) => {
                        updateCheckListItem(
                          e.target.value,
                          e.target.id,
                          item.type
                        );
                      }}
                    />
                  </div>

                  <span
                    onClick={(e) => {
                      deleteCheckListItem(item.id);
                    }}
                    style={{
                      backgroundColor: '#ddd',
                      padding: '15px',
                      cursor: 'pointer'
                    }}
                  >
                    <img src={TrashIcon} alt="trash" />
                  </span>
                  <span
                    onClick={() => {
                      insertCheckListItem(item.id);
                    }}
                    style={{
                      backgroundColor: '#ddd',
                      padding: '15px',
                      cursor: 'pointer'
                    }}
                  >
                    <img src={PlusIcon} alt="trash" />
                  </span>

                  {children.length === 0 && (
                    <Select
                      defaultValue="check"
                      onChange={(e) => {
                        updateCheckListItem(item.name, item.id, e.target.value);
                      }}
                    >
                      <MenuItem value="check">체크박스</MenuItem>
                      <MenuItem value="input">텍스트 입력</MenuItem>
                      <MenuItem value="time">시간 입력</MenuItem>
                    </Select>
                  )}
                </div>
                {/* 자식 아이템이 있으면 바로 아래에 렌더링 */}
                {children.length > 0 &&
                  children.map((child, childIndex) => (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <div
                        style={{
                          border: '1px solid #ddd',
                          padding: '14px 18px 14px 18px'
                        }}
                      >
                        <span>ㄴ</span>
                      </div>

                      <div
                        style={{ border: '1px solid #ddd', padding: '14px' }}
                      >
                        <input
                          type="text"
                          defaultValue={child.name}
                          id={child.id}
                          onBlur={(e) => {
                            updateCheckListItem(
                              e.target.value,
                              e.target.id,
                              child.type
                            );
                          }}
                        />
                      </div>

                      <span
                        onClick={(e) => {
                          deleteCheckListItem(child.id);
                        }}
                        style={{
                          backgroundColor: '#ddd',
                          padding: '15px',
                          cursor: 'pointer'
                        }}
                      >
                        <img src={TrashIcon} alt="trash" />
                      </span>
                      <span
                        onClick={() => {
                          insertCheckListItem(child.id);
                        }}
                        style={{
                          backgroundColor: '#ddd',
                          padding: '15px',
                          cursor: 'pointer'
                        }}
                      >
                        <img src={PlusIcon} alt="trash" />
                      </span>

                      <Select
                        defaultValue="check"
                        onChange={(e) => {
                          updateCheckListItem(
                            child.name,
                            child.id,
                            e.target.value
                          );
                        }}
                      >
                        <MenuItem value="check">체크박스</MenuItem>
                        <MenuItem value="input">텍스트 입력</MenuItem>
                        <MenuItem value="time">시간 입력</MenuItem>
                      </Select>

                      {children.length === 0 && (
                        <Select
                          defaultValue="check"
                          onChange={(e) => {
                            updateCheckListItem(
                              child.name,
                              child.id,
                              e.target.value
                            );
                          }}
                        >
                          <MenuItem value="check">체크박스</MenuItem>
                          <MenuItem value="input">텍스트 입력</MenuItem>
                          <MenuItem value="time">시간 입력</MenuItem>
                        </Select>
                      )}
                    </div>
                  ))}
              </div>
            );
          }
        })}

      <button
        style={{
          marginTop: '40px',
          backgroundColor: '#999',
          padding: '10px 20px',
          color: '#fff'
        }}
        onClick={() => {
          insertCheckListItem('');
        }}
      >
        체크리스트 추가
      </button>
    </TitleCard>
  );
}

export default CheckItem;
