import axios from 'axios';

export interface QueryParams {
  database: string;
  categories: string[];
  search_queries: string[];
  offset: number;
  limit: number;
  sort_option: string;
  sort: 'DESC' | 'ASC';
}

export async function getNotification(params: QueryParams) {
  const option = {
    url: 'https://cpi.contax.ai/get_board',
    method: 'POST',
    data: params,
    header: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data'
    }
  };

  try {
    const response = await axios(option);
    if (response.status === 200) {
      return {
        status: 'success',
        data: response.data
      };
    } else {
      return {
        status: 'failed',
        data: []
      };
    }
  } catch ({ message }) {
    return {
      status: 'failed',
      message: message
    };
  }
}
