import { createSlice } from '@reduxjs/toolkit';

export interface CheckedItem {
  title: string;
  content: string;
  unique_key: string;
}

interface EmailBoardState {
  total_count: number;
  board_list: TableType[];
  pending: false;
  open_item: number;
  type: string;
  isAllCheck: boolean;
  checked_item: CheckedItem[];
}

export interface TableType {
  id: number;
  title: string;
  content: string;
  regdate: string;
  category: string | null | undefined;

  check: boolean;
}

const initialState: EmailBoardState = {
  total_count: 0,
  board_list: [],
  pending: false,
  open_item: 0,
  isAllCheck: false,
  type: '매거진',
  checked_item: [],
};

export const emailBoardSlice = createSlice({
  name: 'EmailBoardSlice',
  initialState: initialState,
  reducers: {
    updateType(state, action) {
      state.type = action.payload;
    },

    handleCheck: (state, action: { payload: { id: number; type: string } }) => {
      const list = [...state.board_list];
      let checked = [...state.checked_item];

      list.forEach(item => {
        if (item.id === action.payload.id && item.check !== true) {
          item.check = true;
          checked.push({
            ...item,
            unique_key: `${action.payload.type}_${action.payload.id}`,
          });
        } else if (item.id === action.payload.id && item.check === true) {
          item.check = false;

          checked = checked.filter(
            jtem =>
              jtem.unique_key !== `${action.payload.type}_${action.payload.id}`,
          );
        }
      });

      state.checked_item = checked;
      state.board_list = list;
    },

    handleAllCheck: (state, action) => {
      const list = [...state.board_list];

      state.isAllCheck = action.payload === true;
      list.forEach(item => {
        item.check = action.payload === true;
      });

      state.board_list = list;
    },

    updateBoardList(state, action) {
      const array = action.payload.data;
      state.total_count = action.payload.total_count;

      array.forEach((item: TableType) => {
        state.checked_item.forEach(jtem => {
          if (jtem.unique_key === `${state.type}_${item.id}`) {
            item.check = true;
          }
        });
      });

      state.board_list = array;
    },

    resetBoardList(state) {
      state.board_list = [];
      state.total_count = 0;
    },

    updatePending(state, action) {
      state.pending = action.payload;
    },

    updateDetailSection(state, action) {
      if (state.open_item === action.payload) {
        state.open_item = 0;
      } else {
        state.open_item = action.payload;
      }
    },
  },
});

export const {
  updateBoardList,
  resetBoardList,
  updatePending,
  updateDetailSection,
  handleCheck,
  updateType,
  handleAllCheck,
} = emailBoardSlice.actions;

export default emailBoardSlice.reducer;
export type { EmailBoardState };
