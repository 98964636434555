import { useEffect, useState } from 'react';
import TitleCard from '../../components/Cards/TitleCard';
import axios from 'axios';
import { Grid } from '@mui/material';
import { useDispatch } from 'react-redux';
import { setPageTitle } from '../../app/slice/headerSlice';
import {
  AdminGrid,
  AdminGridCols,
  AdminGridOnlyExpertAnswerRows,
} from '../sales/component/adminGrid';
import { OnlyExpertDetailContents } from '../sales/component/components';
import {
  calculatePagination,
  createPaginationSize,
} from '../../utils/common_API';
import BoardPagenation from '../../components/BoardPagenation';

function ExpertInquiryBoard() {
  const dispatch = useDispatch();
  const [inquiries, setInquiries] = useState([]);

  const [, setCategory] = useState([]);
  const [detailOn, setDetailOn] = useState(false);

  const pendingCols = [
    { title: '문의 번호', width: 10 },
    { title: '이름', width: 10 },
    { title: '이메일', width: 20 },
    { title: '카테고리', width: 10 },
    { title: '제목', width: 20 },
    { title: '문의 작성일자', width: 15 },
    { title: '답변 작성', width: 7.5 },
    { title: '승인', width: 7.5 },
  ];

  useEffect(() => {
    dispatch(setPageTitle({ title: '1:1 문의' }));
    loadCategory();
    loadSaleManager();
  }, []);

  // async function insertNotification(){
  //     const body = {
  //         notification_type: 'inquiry',
  //         title: title,
  //         content: content,
  //         target: userIdList
  //     };

  //     if(status === 'create'){
  //         const option = {
  //             url: 'https://cpi.contax.ai/insert_notification',
  //             method: 'POST',
  //             data:body,

  //             header:{
  //                 'Accept':'application/json',
  //                 'Content-Type':'application/json'
  //             }
  //         }

  //         axios(option).then(response => {
  //             if(response.status === 200){
  //                 return true;
  //             }else{
  //                 console.log(response);
  //             }
  //         });
  //     }
  // }

  // async function sendNotification(paramsToken){
  //     const body = {
  //         title: '[1:1문의] ' + title,
  //         body: stripHtmlAndLimitLength(content, 28) ,
  //         token: paramsToken
  //     };

  //     const option = {
  //         url: 'https://cpi.contax.ai/send_notification',
  //         method: 'POST',
  //         data:body,
  //         header:{
  //             'Accept':'application/json',
  //             'Content-Type':'application/json'
  //         }
  //     }

  //     axios(option).then(response => {
  //         if(response.status === 200){
  //             return true;
  //         }else{
  //             console.log(response);
  //         }
  //     });
  // }

  const [, setSalesManager] = useState([]);

  async function loadSaleManager() {
    let data = [];
    let userData = [];
    let accessData = [];
    let salesAdminData = [];

    const conbiOption = {
      url: 'https://cpi.contax.ai/members/all_members',
      method: 'GET',
      header: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    };

    const accessOption = {
      url: 'https://cpi.contax.ai/access',
      method: 'GET',
      header: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    };

    await axios(conbiOption).then(response => {
      if (response.status === 200) {
        data = response.data;
      }
    });

    await axios(accessOption).then(response => {
      if (response.status === 200) {
        accessData = response.data;
      }
    });

    data.forEach((item, index) => {
      let isAdmin = false;
      accessData.forEach(jtem => {
        if (item.user_id === jtem.user_id) {
          if (jtem.manager_division === 'contact_sales') {
            const newObj = {
              ...item,
              name: item.name,
              is_admin: 'O',
              access: [],
              assignment: [],
              sales_member: jtem.sales_member,
              division: jtem.manager_division,
            };
            salesAdminData.push(newObj);
          }

          isAdmin = true;
        }
      });

      if (isAdmin === false) {
        const object = {
          ...item,
          subscription_type: '미가입',
          subscription_end: '-',
        };
        userData.push(object);
      }
    });

    setSalesManager(salesAdminData);
  }

  const [pageLength, setPageLength] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [limit, setLimit] = useState(10);
  const [pageNumbers, setPageNumbers] = useState([1]);
  const [offset, setOffset] = useState(0);

  const id = localStorage.getItem('id');

  const params = {
    database: '전문가 문의',
    categories: ['writter_to_expert', 'expert_id'],
    search_queries: [true, id],
    offset: offset * limit,
    limit: limit,
    sort_option: 'question_date',
    sort: 'DESC',
  };

  async function getInquiryData(params) {
    const option = {
      url: 'https://cpi.contax.ai/get_board',
      method: 'POST',
      data: params,
      header: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    };

    try {
      const response = await axios(option);

      if (response.status === 200) {
        setInquiries(response.data.data);
        setTotalCount(response.data.total_count);
        const length = calculatePagination(response.data.total_count, limit);
        setPageLength(calculatePagination(response.data.total_count, limit));
        setPageNumbers(createPaginationSize(offset, length));

        return response.data;
      } else {
        return [];
      }
    } catch (error) {
      return [];
    }
  }

  useEffect(() => {
    getInquiryData(params);
  }, [offset]);

  async function sendAnswerToUser(id) {
    if (!window.confirm('정말 승인하시겠습니까?')) {
      return false;
    }

    const option = {
      url: 'https://cpi.contax.ai/send_answer_to_user',
      params: {
        id: id,
      },
      method: 'PUT',
      header: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    };

    axios(option).then(response => {
      if (response.status === 200) {
        const array = [...inquiries];

        array.forEach((item, index) => {
          if (item.id === id) {
            item.answer_status = 'complete';
          }
        });

        setInquiries(array);
        alert('성공적으로 승인되었습니다');
      }
    });
  }

  async function loadCategory() {
    const option = {
      url: 'https://cpi.contax.ai/categories/inquiry',
      method: 'GET',
      header: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    };

    axios(option).then(response => {
      if (response.status === 200) {
        setCategory(response.data[0][0]);
      }
    });
  }

  const [selectedDetailData, setSelectedDetailData] = useState([]);
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');

  async function updateAnswer(id) {
    if (title.length === 0) {
      alert('제목을 입력해주세요');
      return false;
    }

    if (content.length === 0) {
      alert('내용을 입력해주세요');
      return false;
    }

    if (!window.confirm('답변을 남기시겠습니까?')) {
      return false;
    }

    const option = {
      url: 'https://cpi.contax.ai/update_answer',
      method: 'PUT',
      data: {
        id: id,
        answer_title: title,
        answer_contents: content,
      },
      header: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    };

    await axios(option).then(response => {
      if (response.status === 200) {
        alert(
          '성공적으로 답변이 저장되었습니다.\n고객에게 전달되기 전까지 최고 관리자의 승인이 필요합니다.',
        );

        const array = [...inquiries];

        array.forEach(item => {
          if (item.id === id) {
            item.answer_title = title;
            item.answer_contents = content;
            item.answer_status = 'pending';
          }
        });

        setInquiries(array);
      }
    });
  }

  return (
    <Grid container spacing={2} direction="column" justifyContent="center">
      <Grid item>
        {detailOn === false && (
          <TitleCard title="답변 대기 문의">
            {/* Team Member list in table format loaded constant */}
            <div style={{ minHeight: '500px' }}>
              <AdminGrid>
                <AdminGridCols
                  detailOn={detailOn}
                  setDetailOn={params => {
                    setDetailOn(params);
                  }}
                  columns={pendingCols}
                />
                <AdminGridOnlyExpertAnswerRows
                  detailOn={detailOn}
                  setDetailOn={params => {
                    setDetailOn(params);
                  }}
                  setTitle={params => {
                    setTitle(params);
                  }}
                  setContent={params => {
                    setContent(params);
                  }}
                  data={inquiries}
                  selecteDetaildData={selectedDetailData}
                  onClick={params => {
                    sendAnswerToUser(params);
                  }}
                  setSelectedData={params => {
                    setSelectedDetailData(params);
                  }}
                />
              </AdminGrid>

              <BoardPagenation
                offset={offset}
                pageNumbers={pageNumbers}
                pageLength={pageLength}
                setOffset={index => {
                  setOffset(index);
                }}
              />
            </div>
          </TitleCard>
        )}

        {detailOn === true && (
          <TitleCard title="문의글">
            <OnlyExpertDetailContents
              updateAnswer={id => {
                updateAnswer(id);
              }}
              title={title}
              content={content}
              setTitle={params => {
                setTitle(params);
              }}
              setContent={params => {
                setContent(params);
              }}
              setDetailOn={() => {
                setDetailOn(false);
              }}
              setSelectedDetailData={params => {
                setSelectedDetailData(params);
              }}
              item={selectedDetailData}
            />
          </TitleCard>
        )}
      </Grid>
    </Grid>
  );
}

export default ExpertInquiryBoard;
