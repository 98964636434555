import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import { Grid } from '@mui/material';
import TitleCard from '../../components/Cards/TitleCard';
import { setPageTitle } from '../../app/slice/headerSlice';
import styled from 'styled-components';

import React from 'react';
import {
  AdminGrid,
  AdminGridCols,
  AdminGridRows,
  AdminGridRowsPerformanceFirst,
  AdminGridRowsPerformanceSecond,
  AdminGridTitle,
} from './component/adminGrid';
import {
  FilterRow,
  FilterRowCategory,
  FilterSection,
  FilterSelect,
  ManagerSection,
  SalesSection,
  SearchButton,
  TableColumn,
  TableColumnSection,
  TableManagerSection,
  TableRowSection,
  TableSection,
  TrashRowSection,
  TrashSection,
} from './component/components';
import { Pagination } from '@mui/material';
import CheckItem from './component/checkItem';
import Contact from './component/contact';
import Second from './component/second';
import { DatePickerComponent } from '../sales_manager/component/datePicker';

const St_Count = styled.div`
  text-align: right;
`;

const St_ChangeModeButtonSection = styled.div`
  display: flex;
  gap: 20px;
  margin-bottom: 40px;
`;

const St_ChangeModeButton = styled.button`
  width: 140px;
  height: 40px;
  color: ${props => (props.mode ? '#fff' : '#999')};
  background-color: ${props => (props.mode ? '#999' : 'white')};
  border: 1px solid #999;
`;

const St_ArrowSection = styled.div`
  display: flex;
  margin-top: 20px;
  justify-content: center;
  gap: 20px;
`;

function Sales() {
  const access = localStorage.getItem('access');

  const AREA_NAME = [
    '서울 (17511)',
    '부산 (5467)',
    '대구 (3274)',
    '인천 (4725)',
    '광주 (3592)',
    '대전 (3077)',
    '울산 (1777)',
    '세종 (370)',
    '경기 (19976)',
    '강원 (4249)',
    '충북 (3625)',
    '충남 (4960)',
    '전북 (4396)',
    '전남 (5599)',
    '경북 (6664)',
    '경남 (6473)',
    '제주 (2091)',
  ];

  const SEOUL_DETAIL_NAME = [
    `종로구     (282) 1.61%`,
    '중구     (306) 1.75%',
    '용산구     (352) 2.01%',
    '성동구     (431) 2.46%',
    '광진구     (598) 3.42%',
    '동대문구     (434) 2.48%',
    '중랑구     (446) 2.55%',
    '성북구     (292) 1.67%',
    '강북구     (291) 1.66%',
    '도봉구     (242) 1.38%',
    '노원구     (359) 2.05%',
    '은평구     (551) 3.15%',
    '서대문구     (235) 1.34%',
    '마포구     (906) 5.18%',
    '양천구     (527) 3.01%',
    '강서구     (966) 5.52%',
    '구로구     (715) 4.09%',
    '금천구     (756) 4.32%',
    '영등포구     (1035) 5.91%',
    '동작구     (274) 1.57%',
    '관악구     (461) 2.63%',
    '서초구     (1955) 11.17%',
    '강남구     (2579) 14.74%',
    '송파구     (1757) 10.04%',
    '강동구     (749) 4.28%',
  ];
  const BUSAN_DETAIL_NAME = [
    '중구     (94) 1.60%',
    '서구     (499) 8.48%',
    '동구     (207) 3.52%',
    '영도구     (92) 1.56%',
    '부산진구     (562) 9.55%',
    '동래구     (540) 9.17%',
    '남구     (306) 5.20%',
    '북구     (276) 4.69%',
    '해운대구     (617) 10.48%',
    '사하구     (260) 4.42%',
    '금정구     (474) 8.05%',
    '강서구     (424) 7.20%',
    '연제구     (504) 8.56%',
    '수영구     (321) 5.45%',
    '사상구     (389) 6.61%',
    '기장군     (322) 5.47%',
  ];

  const DAEGU_DETAIL_NAME = [
    '중구     (148) 3.85%',
    '동구     (459) 11.95%',
    '서구     (809) 21.06%',
    '남구     (219) 5.70%',
    '북구     (514) 13.38%',
    '수성구     (628) 16.35%',
    '달서구     (568) 14.79%',
    '달성군     (490) 12.76%',
    '군위군     (6) 0.16%',
  ];

  const INCHEON_DETAIL_NAME = [
    '중구     (173) 3.24%',
    '동구     (1232) 23.08%',
    '미추홀구     (157) 2.94%',
    '연수구     (413) 7.74%',
    '남동구     (1016) 19.03%',
    '부평구     (553) 10.36%',
    '계양구     (470) 8.81%',
    '서구     (1036) 19.41%',
    '강화군     (211) 3.95%',
    '옹진군     (76) 1.42%',
  ];

  const GWANGJU_DETAIL_NAME = [
    '동구     (348) 9.70%',
    '서구     (1152) 32.11%',
    '남구     (338) 9.42%',
    '북구     (1032) 28.77%',
    '광산구     (717) 19.99%',
  ];

  const DAEJEON_DETAIL_NAME = [
    '동구     (362) 11.75%',
    '중구     (487) 15.79%',
    '서구     (921) 29.97%',
    '유성구     (775) 25.22%',
    '대덕구     (531) 17.28%',
  ];

  const ULSAN_DETAIL_NAME = [
    '중구     (277) 15.60%',
    '남구     (679) 38.24%',
    '동구     (59) 3.32%',
    '북구     (155) 8.73%',
    '울주군     (607) 34.12%',
  ];
  const GYEONGI_DETAIL_NAME = [
    '수원시     (1390) 6.70%',
    '고양시     (1296) 6.25%',
    '용인시     (1344) 6.48%',
    '성남시     (1679) 8.10%',
    '부천시     (1032) 4.98%',
    '화성시     (1698) 8.19%',
    '안산시     (1094) 5.27%',
    '남양주시     (900) 4.34%',
    '안양시     (1021) 4.92%',
    '평택시     (941) 4.54%',
    '시흥시     (844) 4.07%',
    '파주시     (739) 3.56%',
    '의정부시     (405) 1.95%',
    '김포시     (776) 3.74%',
    '광주시     (649) 3.13%',
    '광명시     (308) 1.49%',
    '군포시     (262) 1.26%',
    '하남시     (402) 1.94%',
    '오산시     (263) 1.27%',
    '양주시     (1300) 6.27%',
    '이천시     (428) 2.06%',
    '구리시     (203) 0.98%',
    '안성시     (363) 1.75%',
    '포천시     (350) 1.69%',
    '의왕시     (183) 0.88%',
    '양평군     (197) 0.95%',
    '여주시     (165) 0.80%',
    '동두천시     (152) 0.73%',
    '가평군     (112) 0.54%',
    '과천시     (117) 0.56%',
    '연천군     (127) 0.61%',
  ];

  const GANGWON_DETAIL_NAME = [
    '춘천시     (641) 16.83%',
    '원주시     (888) 23.20%',
    '강릉시     (536) 14.04%',
    '동해시     (198) 5.07%',
    '태백시     (123) 3.20%',
    '속초시     (163) 4.22%',
    '삼척시     (226) 5.84%',
    '홍천군     (169) 4.46%',
    '횡성군     (147) 3.79%',
    '영월군     (175) 4.54%',
    '평창군     (161) 4.27%',
    '정선군     (164) 4.30%',
    '철원군     (128) 3.31%',
    '화천군     (90) 2.40%',
    '양구군     (93) 2.40%',
    '인제군     (124) 3.20%',
    '고성군     (116) 3.10%',
    '양양군     (101) 2.56%',
  ];

  const CHUNGCHEONGBOOK_DETAIL_NAME = [
    '청주시     (1545) 45.42%',
    '충주시     (423) 12.42%',
    '제천시     (319) 9.37%',
    '보은군     (95) 2.79%',
    '옥천군     (119) 3.49%',
    '영동군     (132) 3.88%',
    '증평군     (47) 1.38%',
    '진천군     (169) 4.96%',
    '괴산군     (133) 3.90%',
    '음성군     (313) 9.19%',
    '단양군     (109) 3.20%',
  ];

  const CHUNGCHEONGNAM_DETAIL_NAME = [
    '천안시     (1199) 25.27%',
    '공주시     (362) 7.62%',
    '보령시     (341) 7.18%',
    '아산시     (512) 10.80%',
    '서산시     (371) 7.81%',
    '논산시     (306) 6.44%',
    '계룡시     (84) 1.77%',
    '당진시     (273) 5.75%',
    '금산군     (221) 4.65%',
    '부여군     (199) 4.19%',
    '서천군     (125) 2.63%',
    '청양군     (109) 2.30%',
    '홍성군     (234) 4.93%',
    '예산군     (229) 4.82%',
    '태안군     (180) 3.81%',
  ];

  const JEOLLABOOK_DETAIL_NAME = [
    '전주시     (1424) 32.40%',
    '군산시     (550) 12.54%',
    '익산시     (512) 11.65%',
    '정읍시     (251) 5.71%',
    '남원시     (284) 6.46%',
    '김제시     (250) 5.69%',
    '완주군     (272) 6.19%',
    '진안군     (118) 2.68%',
    '무주군     (115) 2.62%',
    '장수군     (93) 2.12%',
    '임실군     (125) 2.80%',
    '순창군     (130) 2.96%',
    '고창군     (167) 3.80%',
    '부안군     (105) 2.39%',
  ];

  const JEOLLANAM_DETAIL_NAME = [
    '목포시     (475) 8.50%',
    '여수시     (746) 13.37%',
    '순천시     (721) 12.88%',
    '나주시     (370) 6.62%',
    '광양시     (441) 7.89%',
    '담양군     (228) 4.08%',
    '곡성군     (122) 2.18%',
    '구례군     (81) 1.45%',
    '고흥군     (185) 3.31%',
    '보성군     (150) 2.67%',
    '화순군     (291) 5.21%',
    '장흥군     (120) 2.15%',
    '강진군     (122) 2.18%',
    '해남군     (256) 4.56%',
    '영암군     (234) 4.19%',
    '무안군     (237) 4.24%',
    '함평군     (131) 2.34%',
    '영광군     (160) 2.86%',
    '장성군     (233) 4.17%',
    '완도군     (112) 2.00%',
    '진도군     (57) 1.02%',
    '신안군     (119) 2.13%',
  ];

  const GYEONGSANGBOOK_DETAIL_NAME = [
    '포항시     (945) 14.47%',
    '경주시     (605) 9.26%',
    '김천시     (367) 5.62%',
    '안동시     (495) 7.58%',
    '구미시     (777) 11.90%',
    '영주시     (292) 4.47%',
    '영천시     (304) 4.65%',
    '상주시     (282) 4.30%',
    '문경시     (206) 3.15%',
    '경산시     (484) 7.41%',
    '의성군     (184) 2.82%',
    '청송군     (128) 1.96%',
    '영양군     (101) 1.55%',
    '영덕군     (114) 1.75%',
    '청도군     (113) 1.73%',
    '고령군     (112) 1.71%',
    '성주군     (214) 3.28%',
    '칠곡군     (299) 4.58%',
    '예천군     (175) 2.66%',
    '봉화군     (130) 1.99%',
    '울진군     (179) 2.74%',
    '울릉군     (28) 0.41%',
  ];

  const GYEONGSANGNAM_DETAIL_NAME = [
    '창원시     (1399) 22.49%',
    '진주시     (829) 13.32%',
    '통영시     (206) 3.31%',
    '사천시     (273) 4.39%',
    '김해시     (973) 15.63%',
    '밀양시     (295) 4.74%',
    '거제시     (281) 4.51%',
    '양산시     (465) 7.47%',
    '의령군     (125) 2.01%',
    '함안군     (227) 3.65%',
    '창녕군     (130) 2.09%',
    '고성군     (154) 2.47%',
    '남해군     (104) 1.67%',
    '하동군     (122) 1.96%',
    '산청군     (119) 1.91%',
    '함양군     (165) 2.65%',
    '거창군     (191) 3.07%',
    '합천군     (166) 2.65%',
  ];

  const JEJU_DETAIL_NAME = [
    '제주시     (1646) 80.13%',
    '서귀포시     (408) 19.87%',
  ];

  const [trans, setTrans] = useState([]);
  const [filterAreaName, setFilterAreaName] = useState('');
  const [defailData, setDetailData] = useState([]);
  const dispatch = useDispatch();
  const [totalCount, setTotalCount] = useState(0);
  const [salesAdminData, setSalesAdminData] = useState([]);
  const [secondAdminData, setSecondAdminData] = useState([]);
  const [contactAdminData, setContactAdminData] = useState([]);
  const [allCheck, setAllCheck] = useState(false);
  const [date, setDate] = useState();
  const [salesAdminArea, setSalesAdminArea] = useState([]);
  const [salesManagerArea, setSalesManagerArea] = useState();
  const [filters, setFilters] = useState({
    /**
     * 등록시군구
     */
    ncrareadetailname: '',
    /**
     * 등록시도
     */
    ncrareaname: '',
    ncrgsaddr: '',
    /**
     * 등록일자
     */
    ncrgsdate: '',
    /**
     * 공시내용 구분
     */
    ncrgsflag: '',
    ncrgskname: '',
    ncrgsmaster: '',
    /**
     * 공고번호
     */
    ncrgsnumber: '',
    /**
     * 변경사유 철회
     */
    ncrgsreason: '',
    /*** 공시 일자*/
    ncrgsregdate: '',
    /*** 공시 일련번호*/
    ncrgsseq: '',
    /*** 등록업종*/
    ncritemname: '',
    /** 업종 등록번호 */
    ncritemregno: '',
    /** 사업자 등록번호 */
    ncrmasternum: '',
    ncrofftel: '',
    email: '',
    phoneNumber: '',
  });

  const [checkData, setCheckData] = useState([]);
  const [salesManager, setSalesManager] = useState([]);
  const [mode, setMode] = useState('search');
  const [checkManager, setCheckManager] = useState('');
  const [target, setTarget] = useState([]);

  const [trash, setTrash] = useState([]);
  const [selectedTrash, setSelectedTrash] = useState([]);
  const [checkList, setCheckList] = useState({});

  useEffect(() => {
    var date = new Date();

    // 각 요소를 가져옵니다 (년, 월, 일).
    var year = date.getFullYear();
    var month = date.getMonth() + 1; // getMonth()는 0에서 시작하므로 1을 더해줍니다.
    var day = date.getDate();

    // 월과 일이 10보다 작으면 앞에 0을 붙여줍니다.
    month = month < 10 ? '0' + month : month;
    day = day < 10 ? '0' + day : day;

    // 'YYYY-MM-DD' 형식으로 변환
    var formattedDate = year + '-' + month + '-' + day;
    setDate(formattedDate);

    loadSaleManager();
  }, []);

  useEffect(() => {
    loadSaleManager();
  }, [date]);

  useEffect(() => {
    switch (filterAreaName.substring(0, 2)) {
      case '서울':
        setDetailData(SEOUL_DETAIL_NAME);
        break;

      case '부산':
        setDetailData(BUSAN_DETAIL_NAME);
        break;

      case '대구':
        setDetailData(DAEGU_DETAIL_NAME);
        break;

      case '인천':
        setDetailData(INCHEON_DETAIL_NAME);
        break;

      case '광주':
        setDetailData(GWANGJU_DETAIL_NAME);
        break;

      case '대전':
        setDetailData(DAEJEON_DETAIL_NAME);
        break;

      case '울산':
        setDetailData(ULSAN_DETAIL_NAME);
        break;

      case '세종':
        setDetailData(['세종']);
        break;

      case '경기':
        setDetailData(GYEONGI_DETAIL_NAME);
        break;

      case '강원':
        setDetailData(GANGWON_DETAIL_NAME);
        break;

      case '충북':
        setDetailData(CHUNGCHEONGBOOK_DETAIL_NAME);
        break;

      case '충남':
        setDetailData(CHUNGCHEONGNAM_DETAIL_NAME);
        break;

      case '전북':
        setDetailData(JEOLLABOOK_DETAIL_NAME);
        break;

      case '전남':
        setDetailData(JEOLLANAM_DETAIL_NAME);
        break;

      case '경남':
        setDetailData(GYEONGSANGNAM_DETAIL_NAME);
        break;

      case '경북':
        setDetailData(GYEONGSANGBOOK_DETAIL_NAME);
        break;

      case '제주':
        setDetailData(JEJU_DETAIL_NAME);
        break;

      default:
        break;
    }
  }, [filterAreaName]);

  const Columns = [
    { width: 10, title: '선택' },
    { width: 30, title: '관리자명' },
    { width: 20, title: '이메일 계정' },
    { width: 20, title: '휴대폰 번호' },
    { width: 20, title: '시도 공유 현황' },
    { width: 20, title: '시군구 공유 현황' },
  ];

  const FirstColumns = [
    { width: 10, title: '선택' },
    { width: 30, title: '관리자명' },
    { width: 10, title: '일일 스케줄 완료' },
    { width: 10, title: '방문 예약 완료' },
    { width: 10, title: '방문 완료' },
    { width: 10, title: '총 스케줄 완료' },
    { width: 10, title: '총 방문 예약 완료' },
    { width: 10, title: '총 방문 완료' },
  ];

  const SecondColumns = [
    { width: 10, title: '선택' },
    { width: 30, title: '관리자명' },
    { width: 15, title: '일일 방문 예약 완료' },
    { width: 15, title: '방문 완료' },
    { width: 15, title: '총 방문 예약 완료' },
    { width: 15, title: '총 방문 완료' },
  ];

  useEffect(() => {
    loadCheckList().then(transformedData => {
      setCheckList(transformedData);
    });

    searchFilter(1);
    dispatch(setPageTitle({ title: '영업' }));
    loadSaleManager();
    loadSalesAdmin();
    loadAllTrash();
  }, []);

  useEffect(() => {
    loadSalesManagerByArea();
  }, [defailData]);

  async function loadSalesManagerByArea() {
    const array = [];

    defailData.forEach(item => {
      array.push(item.substring(0, 5).trim());
    });

    const option = {
      url: 'https://cpi.contax.ai/get_sales_managers',
      method: 'POST',
      data: {
        area: filterAreaName.substring(0, 2),
        areaDetails: array,
      },
      header: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    };

    try {
      const response = await axios(option);
      if (response.status === 200) {
        setSalesManagerArea(response.data);
      } else {
        console.log('Non-200 response received: ', response.status);
        return null;
      }
    } catch (error) {
      console.error('Error loading checklist:', error);
    }
  }

  async function loadCheckList() {
    const option = {
      url: 'https://cpi.contax.ai/load_sales_check_item_list',
      method: 'GET',
      header: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    };

    try {
      const response = await axios(option);
      if (response.status === 200) {
        const firstArray = response.data.filter(
          item => item.division === 'first',
        );
        console.log(firstArray);
        const itemMap = {};
        const rootItems = {}; // 최상위 노드를 저장할 객체

        // 아이템을 id를 키로 해서 저장하고, 각 아이템에 자식을 추가할 children 객체 초기화
        firstArray.forEach(item => {
          itemMap[item.id] = { ...item, children: {} };
        });

        // 부모-자식 관계 설정
        Object.values(itemMap).forEach(item => {
          if (item.parent && itemMap[item.parent]) {
            itemMap[item.parent].children[item.name] =
              itemMap[item.id].children;
          } else {
            rootItems[`${item.name}_${item.type}`] = itemMap[item.id].children;
          }
        });

        // 모든 항목에 대해 false 값 할당
        const assignFalse = obj => {
          Object.keys(obj).forEach(key => {
            if (Object.keys(obj[key]).length === 0) {
              if (key.includes('check')) {
                obj[key] = false;
              } else {
                obj[key] = '';
              }
            } else {
              assignFalse(obj[key]);
            }
          });
        };

        assignFalse(rootItems);

        console.log(rootItems);
        return rootItems;
      } else {
        console.log('Non-200 response received: ', response.status);
        return null;
      }
    } catch (error) {
      console.error('Error loading checklist:', error);
    }
  }

  async function shareData() {
    const array = [];

    checkData.forEach(item => {
      array.push(item.ncrgsseq);
      insertMemo(item.ncrgsseq, item.ncrgskname);
    });

    const conbiOption = {
      url: 'https://cpi.contax.ai/update_sales_member',
      method: 'PUT',
      data: {
        user_id: checkManager.user_id,
        members_list: array,
      },
      header: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    };

    await axios(conbiOption).then(response => {
      if (response.status === 200) {
        alert('공유가 완료되었습니다');
      }
    });
  }

  async function insertMemo(ncrgsseq, ncrgskname) {
    const user_id = localStorage.getItem('id');
    const name = localStorage.getItem('name');

    const object = {
      item: JSON.stringify(checkList),
      comment: '',
      writter: checkManager.user_id,
      memo: '',
      call_memo: '',
      targetMasterNumber: ncrgsseq,
      title: ncrgskname,
      writter_name: checkManager.name,
    };

    const option = {
      url: 'https://cpi.contax.ai/sales/insert_sales_memo',
      method: 'POST',
      data: object,
      header: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    };

    const historyOption = {
      url: 'https://cpi.contax.ai/insert_sales_history',
      method: 'POST',
      params: {
        id: user_id,
        name: name,
        target: checkManager.name,
        item: ncrgskname,
        type: 'insert',
        targetId: checkManager.user_id,
      },
      header: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    };

    await axios(option).then(response => {
      if (response.status === 200) {
      }
    });

    await axios(historyOption).then(response => {
      if (response.status === 200) {
      }
    });
  }

  async function loadAllTrash() {
    const conbiOption = {
      url: 'https://cpi.contax.ai/sales/load_all_trash',
      method: 'GET',
      header: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    };

    await axios(conbiOption).then(response => {
      if (response.status === 200) {
        setTrash(response.data);
      }
    });
  }

  async function shareAllData() {
    const id = localStorage.getItem('id');

    const option = {
      url: 'https://cpi.contax.ai/update_sales_member_all',
      method: 'PUT',
      data: filters,
      params: {
        user_id: checkManager.user_id,
        item: JSON.stringify(checkList),
        comment: '',
        memo: '',
        call_memo: '',
        writter_name: checkManager.name,
        id: id,
      },
      header: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    };

    await axios(option).then(response => {
      if (response.status === 200) {
        alert('공유가 완료되었습니다');
      }
    });
  }

  async function loadSaleManager() {
    let data = [];
    let userData = [];
    let accessData = [];
    let salesAdminData = [];

    const conbiOption = {
      url: 'https://cpi.contax.ai/members/all_members',
      method: 'GET',
      header: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    };

    const accessOption = {
      url: 'https://cpi.contax.ai/access',
      method: 'GET',
      header: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    };

    await axios(conbiOption).then(response => {
      if (response.status === 200) {
        data = response.data;
      }
    });

    await axios(accessOption).then(response => {
      if (response.status === 200) {
        accessData = response.data;
      }
    });

    data.forEach((item, index) => {
      let isAdmin = false;
      accessData.forEach(jtem => {
        if (item.user_id === jtem.user_id) {
          const newObj = {
            ...item,
            name: item.name,
            is_admin: 'O',
            access: [],
            assignment: [],
            sales_member: jtem.sales_member,
            division: jtem.manager_division,
          };
          salesAdminData.push(newObj);

          isAdmin = true;
        }
      });

      if (isAdmin === false) {
        const object = {
          ...item,
          subscription_type: '미가입',
          subscription_end: '-',
        };
        userData.push(object);
      }
    });

    let index = 0;
    for (const item of salesAdminData) {
      if (item.division === 'sales') {
        const result = await loadPerformance(date, item.user_id, item.name);
        salesAdminData[index]['performance'] = result;
      }

      if (item.division === 'sales_second') {
        const result = await loadSecondPerformance(
          date,
          item.user_id,
          item.name,
        );
        salesAdminData[index]['performance'] = result;
      }

      index += 1;
    }

    setSalesManager(salesAdminData);
  }

  async function loadPerformance(sales_member, user_id, name) {
    const performanceOption = {
      url: 'https://cpi.contax.ai/load_first_count',
      method: 'GET',
      params: {
        date: sales_member,
        user_id: user_id,
        name: name,
      },
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    };

    try {
      if (sales_member !== null && sales_member !== undefined) {
        const response = await axios(performanceOption);
        if (response.status === 200) {
          return response.data;
        } else {
          // 여기에 오류 처리 로직 추가
          console.error('Response status is not 200');
        }
      }
    } catch (error) {
      // 네트워크 오류 또는 서버에서 오류 응답을 받았을 때
      console.error('Error in loadPerformance:', error);
    }
  }

  async function loadSecondPerformance(sales_member, user_id, name) {
    const performanceOption = {
      url: 'https://cpi.contax.ai/load_second_count',
      method: 'GET',
      params: {
        date: sales_member,
        user_id: user_id,
        name: name,
      },
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    };

    try {
      if (sales_member !== null && sales_member !== undefined) {
        const response = await axios(performanceOption);
        if (response.status === 200) {
          return response.data;
        } else {
          // 여기에 오류 처리 로직 추가
          console.error('Response status is not 200');
        }
      }
    } catch (error) {
      // 네트워크 오류 또는 서버에서 오류 응답을 받았을 때
      console.error('Error in loadPerformance:', error);
    }
  }

  // const ExcelUploader = () => {
  //     const handleChange = (e) => {
  //         const file = e.target.files[0];

  //         if (!file) return;

  //         const reader = new FileReader();
  //         reader.onload = async (evt) => {
  //             const bstr = evt.target.result;
  //             const workbook = XLSX.read(bstr, { type: 'binary' });
  //             const wsname = workbook.SheetNames[0];
  //             const ws = workbook.Sheets[wsname];
  //             const data = XLSX.utils.sheet_to_json(ws, { header: 1 });

  //             const dataArray = data;
  //             const objectArray = [];
  //             const email = [];
  //             const phoneNumber = [];
  //             const ncrmasternum = [];

  //             if((dataArray[0][16] === '이메일' && dataArray[0][17] === '휴대전화번호') || (dataArray[0][17] === '이메일' && dataArray[0][16] === '휴대전화번호')){
  //                 dataArray.forEach((item, index) => {
  //                     if( item[16] !== undefined && item[17] !== undefined ){
  //                         objectArray.push({ncrmasternum: item[13], email: item[16], phoneNumber: item[17]})

  //                         email.push(item[16]);
  //                         phoneNumber.push(item[17]);
  //                         ncrmasternum.push(item[13]);
  //                     }
  //                 })
  //             }else{
  //                 alert('문서 형식이 맞지 않습니다. 다운로드 받은 영업 DB 엑셀 파일 마지막 열에 이메일과 휴대전화번호를 추가한 후 작성한 후 업로드 해주세요.')
  //                 return;
  //             }

  //             const newArray = [...trans];

  //             newArray.forEach((item, index) => {
  //                 objectArray.forEach((jtem, jndex) => {

  //                     if(item.ncrmasternum === jtem.ncrmasternum){
  //                         const newObject = {...item, email:jtem.email, phoneNumber:jtem.phoneNumber};
  //                         newArray[index] = newObject;
  //                     }
  //                 })
  //             })

  //             const option = {
  //                 url: 'https://cpi.contax.ai/sales/update',
  //                 method: 'PUT',
  //                 data:{
  //                     email: email,
  //                     phoneNumber: phoneNumber,
  //                     ncrmasternum: ncrmasternum
  //                 },
  //                 header:{
  //                     'Accept':'application/json',
  //                     'Content-Type':'application/json'
  //                 }
  //             }

  //             await axios(option).then(response => {
  //                 if(response.status === 200){
  //                     alert("파일이 업로드 완료되었습니다.")
  //                     setTrans(newArray);
  //                 }
  //             });

  //         };

  //         reader.readAsBinaryString(file);
  //     }

  //     return (
  //         <Button sx={{width: "135px"}} accept=".xls,.xlsx" onChange={handleChange} component="label" variant="contained" startIcon={<CloudUploadIcon />}>
  //             엑셀 업로드
  //             <VisuallyHiddenInput type="file" />
  //         </Button>
  //     );
  // };

  function handlePage(event, target) {
    searchFilter(target);
  }

  async function loadSalesAdmin() {
    let data = [];
    let userData = [];
    let accessData = [];
    let salesAdminData = [];
    let secondAdminData = [];
    let contactAdminData = [];

    const conbiOption = {
      url: 'https://cpi.contax.ai/members/all_members',
      method: 'GET',
      header: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    };

    const accessOption = {
      url: 'https://cpi.contax.ai/access',
      method: 'GET',
      header: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    };

    await axios(conbiOption).then(response => {
      if (response.status === 200) {
        data = response.data;
      }
    });

    await axios(accessOption).then(response => {
      if (response.status === 200) {
        accessData = response.data;
      }
    });

    data.forEach((item, index) => {
      let isAdmin = false;
      accessData.forEach(jtem => {
        if (item.user_id === jtem.user_id) {
          if (jtem.manager_division === 'sales') {
            const newObj = {
              ...item,
              is_admin: 'O',
              access: [],
              assignment: [],
              sales_member: jtem.sales_member,
              manager_division: jtem.manager_division,
            };
            salesAdminData.push(newObj);
          }

          if (jtem.manager_division === 'sales_second') {
            const newObj = {
              ...item,
              is_admin: 'O',
              access: [],
              assignment: [],
              sales_member: jtem.sales_member,
              manager_division: jtem.manager_division,
            };
            secondAdminData.push(newObj);
          }

          if (jtem.manager_division === 'contact_sales') {
            const newObj = {
              ...item,
              is_admin: 'O',
              access: [],
              assignment: [],
              sales_member: jtem.sales_member,
              manager_division: jtem.manager_division,
            };
            contactAdminData.push(newObj);
          }

          isAdmin = true;
        }
      });

      if (isAdmin === false) {
        const object = {
          ...item,
          subscription_type: '미가입',
          subscription_end: '-',
        };
        userData.push(object);
      }
    });

    const salesAdminDataId = [];

    salesAdminData.forEach(item => {
      salesAdminDataId.push(item.name);
    });

    secondAdminData.forEach(item => {
      salesAdminDataId.push(item.name);
    });

    contactAdminData.forEach(item => {
      salesAdminDataId.push(item.name);
    });

    const option = {
      url: 'https://cpi.contax.ai/load_area_name_by_manager_name',
      method: 'POST',
      data: {
        name: salesAdminDataId,
      },
      header: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    };

    await axios(option).then(response => {
      if (response.status === 200) {
        setSalesAdminArea(response.data);
      }
    });

    setSalesAdminData(salesAdminData);
    setSecondAdminData(secondAdminData);
    setContactAdminData(contactAdminData);
  }

  function nameChange(name) {
    if (name === 'ncrareadetailname') {
      return '등록시군구';
    }

    if (name === 'ncrareaname') {
      return '등록시도';
    }

    if (name === 'ncrgsaddr') {
      return '소재지';
    }

    if (name === 'ncrgsdate') {
      return '등록일자';
    }

    if (name === 'ncrgsflag') {
      return '공시내용구분';
    }

    if (name === 'ncrgskname') {
      return '업체명';
    }

    if (name === 'ncrgsmaster') {
      return '업체대표자명';
    }

    if (name === 'ncrgsnumber') {
      return '공고번호';
    }

    if (name === 'ncrgsreason') {
      return '변경사유철회';
    }

    if (name === 'ncrgsregdate') {
      return '공시일자';
    }

    if (name === 'ncrgsseq') {
      return '공시일련번호';
    }

    if (name === 'ncritemname') {
      return '등록업종';
    }

    if (name === 'ncritemregno') {
      return '업종등록번호';
    }

    if (name === 'ncrmasternum') {
      return '사업자등록번호';
    }

    if (name === 'ncrofftel') {
      return '전화번호';
    }

    if (name === 'email') {
      return '이메일';
    }

    if (name === 'phoneNumber') {
      return '휴대전화번호';
    }
  }

  async function searchFilter(page) {
    const option = {
      url: 'https://cpi.contax.ai/sales/search',
      method: 'POST',
      params: {
        offset: 10 * (page - 1),
        limit: 10,
      },
      data: filters,
      header: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    };

    await axios(option).then(response => {
      if (response.status === 200) {
        setTrans(response.data.data);
        setTotalCount(response.data.total_count);
      }
    });
  }

  // const DownloadExcelButton = ({ data, fileName }) => {
  //     const array = transformObject(data);

  //     const downloadExcel = () => {
  //         const ws = XLSX.utils.json_to_sheet(array); // JSON 데이터를 Sheet로 변환
  //         const wb = XLSX.utils.book_new(); // 새 Workbook 생성
  //         XLSX.utils.book_append_sheet(wb, ws, "Sheet1"); // Sheet 추가
  //         XLSX.writeFile(wb, fileName + '.xlsx'); // 파일 저장 및 다운로드

  //         alert("조회하신 데이터를 엑셀파일로 다운로드 합니다.")
  //     };

  //     return (
  //         <Button sx={{width: "135px"}}onClick={downloadExcel} variant="contained">엑셀 다운로드</Button>
  //     );
  // };

  const St_CheckSection = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  `;

  async function restoreTrash() {
    const option = {
      url: 'https://cpi.contax.ai/sales/restore_trash',
      data: {
        ncrgsseq: selectedTrash,
      },
      method: 'DELETE',
      header: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    };

    await axios(option).then(response => {
      if (response.status === 200) {
        alert('복원이 완료되었습니다.');
        loadAllTrash();
      }
    });
  }

  return (
    <Grid container spacing={2} direction="column" justifyContent="center">
      <Grid item>
        {mode === 'search' && (
          <TitleCard title="영업">
            <St_ChangeModeButtonSection>
              <St_ChangeModeButton
                mode={'search' === mode}
                onClick={() => {
                  setMode('search');
                }}
              >
                검색
              </St_ChangeModeButton>
              <St_ChangeModeButton
                mode={'memo' === mode}
                onClick={() => {
                  setMode('memo');
                }}
              >
                관리자 현황
              </St_ChangeModeButton>
              <St_ChangeModeButton
                mode={'performance' === mode}
                onClick={() => {
                  setMode('performance');
                }}
              >
                영업실적
              </St_ChangeModeButton>
              <St_ChangeModeButton
                mode={'share' === mode}
                onClick={() => {
                  setMode('share');
                }}
              >
                1차 데이터 공유
              </St_ChangeModeButton>
              <St_ChangeModeButton
                mode={'contact' === mode}
                onClick={() => {
                  setMode('contact');
                }}
              >
                방문 데이터 공유
              </St_ChangeModeButton>
              <St_ChangeModeButton
                mode={'second' === mode}
                onClick={() => {
                  setMode('second');
                }}
              >
                방문 데이터
              </St_ChangeModeButton>
              <St_ChangeModeButton
                mode={'trash' === mode}
                onClick={() => {
                  setMode('trash');
                }}
              >
                휴지통
              </St_ChangeModeButton>
              {access !== '영업 관리' && (
                <St_ChangeModeButton
                  mode={'check_item' === mode}
                  onClick={() => {
                    setMode('check_item');
                  }}
                >
                  체크리스트
                </St_ChangeModeButton>
              )}
            </St_ChangeModeButtonSection>

            <SalesSection>
              <FilterSection>
                <FilterRowCategory title="기본 검색" menuOn={true}>
                  <FilterRow
                    title="상호명"
                    filter={filters.ncrgskname}
                    setFilters={params => {
                      setFilters({
                        ...filters,
                        ncrgskname: params.target.value,
                      });
                    }}
                  />
                  <FilterRow
                    title="대표자명"
                    filters={filters.ncrgsmaster}
                    setFilters={params => {
                      setFilters({
                        ...filters,
                        ncrgsmaster: params.target.value,
                      });
                    }}
                  />
                  <FilterRow
                    title="전화번호"
                    filters={filters.ncrofftel}
                    setFilters={params => {
                      setFilters({
                        ...filters,
                        ncrofftel: params.target.value,
                      });
                    }}
                  />
                  <FilterRow
                    title="면허"
                    filters={filters.ncritemname}
                    setFilters={params => {
                      setFilters({
                        ...filters,
                        ncritemname: params.target.value,
                      });
                    }}
                  />
                  <FilterSelect
                    title="등록시도"
                    data={AREA_NAME}
                    filter={filterAreaName}
                    setFilter={params => {
                      setFilterAreaName(params);
                      setFilters({
                        ...filters,
                        ncrareaname: params.substring(0, 2),
                      });
                    }}
                  />
                  <FilterSelect
                    title="등록시군구"
                    salesManagerArea={salesManagerArea}
                    data={defailData}
                    filter={filters.ncrgsaddr.substring(0, 5).trim()}
                    setFilter={params => {
                      setFilters({
                        ...filters,
                        ncrgsaddr: params.substring(0, 5).trim(),
                      });
                    }}
                  />
                  <SearchButton
                    onClick={() => {
                      searchFilter(1);
                    }}
                  />
                </FilterRowCategory>
              </FilterSection>

              <TableSection>
                <St_CheckSection>
                  <div
                    onClick={() => {
                      setAllCheck(!allCheck);
                    }}
                    style={{
                      display: 'flex',
                      gap: '10px',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      cursor: 'pointer',
                    }}
                  >
                    <input type="checkbox" checked={allCheck} />
                    <span>전체 선택</span>
                  </div>
                  <St_Count>총: {totalCount} 개</St_Count>
                </St_CheckSection>

                <TableColumnSection>
                  <TableColumn width={10}>선택</TableColumn>
                  <TableColumn width={20}>상호명</TableColumn>
                  <TableColumn width={15}>대표자명</TableColumn>
                  <TableColumn width={20}>전화번호</TableColumn>
                  <TableColumn width={25}>면허</TableColumn>
                  <TableColumn width={10}>상세보기</TableColumn>
                </TableColumnSection>

                {trans.map(item => {
                  return (
                    <TableRowSection
                      item={item}
                      checkData={checkData}
                      allCheck={allCheck}
                      setCheckData={params => {
                        const array = [...checkData];
                        if (array.length === 0) {
                          array.push(params);
                        } else {
                          let ok = false;
                          let index = 0;
                          checkData.forEach((item, jndex) => {
                            if (item.ncrgsseq === params.ncrgsseq) {
                              ok = true;
                              index = jndex;
                            }
                          });

                          if (ok === true) {
                            array.splice(index, 1);
                          } else {
                            array.push(params);
                          }
                        }

                        setCheckData(array);
                      }}
                    />
                  );
                })}

                <St_ArrowSection>
                  <Pagination
                    count={Math.ceil(totalCount / 10)}
                    onChange={handlePage}
                  />
                </St_ArrowSection>
              </TableSection>

              {/* <DownloadExcelButton data={trans} fileName="엑셀 파일"/> */}
            </SalesSection>
          </TitleCard>
        )}

        {mode === 'share' && (
          <TitleCard title="영업">
            <St_ChangeModeButtonSection>
              <St_ChangeModeButton
                mode={'search' === mode}
                onClick={() => {
                  setMode('search');
                }}
              >
                검색
              </St_ChangeModeButton>
              <St_ChangeModeButton
                mode={'memo' === mode}
                onClick={() => {
                  setMode('memo');
                }}
              >
                관리자 현황
              </St_ChangeModeButton>
              <St_ChangeModeButton
                mode={'performance' === mode}
                onClick={() => {
                  setMode('performance');
                }}
              >
                영업실적
              </St_ChangeModeButton>
              <St_ChangeModeButton
                mode={'share' === mode}
                onClick={() => {
                  setMode('share');
                }}
              >
                1차 데이터 공유
              </St_ChangeModeButton>
              <St_ChangeModeButton
                mode={'contact' === mode}
                onClick={() => {
                  setMode('contact');
                }}
              >
                방문 데이터 공유
              </St_ChangeModeButton>
              <St_ChangeModeButton
                mode={'second' === mode}
                onClick={() => {
                  setMode('second');
                }}
              >
                방문 데이터
              </St_ChangeModeButton>
              <St_ChangeModeButton
                mode={'trash' === mode}
                onClick={() => {
                  setMode('trash');
                }}
              >
                휴지통
              </St_ChangeModeButton>
              {access !== '영업 관리' && (
                <St_ChangeModeButton
                  mode={'check_item' === mode}
                  onClick={() => {
                    setMode('check_item');
                  }}
                >
                  체크리스트
                </St_ChangeModeButton>
              )}
            </St_ChangeModeButtonSection>

            <SalesSection>
              <TableSection>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginBottom: '20px',
                  }}
                >
                  <h1 style={{ fontWeight: 'bold' }}>선택한 데이터</h1>
                  {allCheck === false && (
                    <St_Count>총: {checkData.length} 개</St_Count>
                  )}
                  {allCheck === true && (
                    <St_Count>총: {totalCount} 개</St_Count>
                  )}
                </div>

                <TableColumnSection>
                  <TableColumn width={10}>선택</TableColumn>
                  <TableColumn width={20}>상호명</TableColumn>
                  <TableColumn width={15}>대표자명</TableColumn>
                  <TableColumn width={20}>전화번호</TableColumn>
                  <TableColumn width={25}>면허</TableColumn>
                  <TableColumn width={10}>상세보기</TableColumn>
                </TableColumnSection>

                {allCheck === false &&
                  checkData.map(item => {
                    return (
                      <TableRowSection
                        item={item}
                        checkData={checkData}
                        setCheckData={params => {
                          const array = [...checkData];
                          if (array.length === 0) {
                            array.push(params);
                          } else {
                            let ok = false;
                            let index = 0;
                            checkData.forEach((item, jndex) => {
                              if (item === params) {
                                ok = true;
                                index = jndex;
                              }
                            });

                            if (ok === true) {
                              array.splice(index, 1);
                            } else {
                              array.push(params);
                            }
                          }

                          setCheckData(array);
                        }}
                      />
                    );
                  })}

                {allCheck === true &&
                  trans.map(item => {
                    return (
                      <TableRowSection
                        item={item}
                        checkData={checkData}
                        allCheck={allCheck}
                        setCheckData={params => {
                          const array = [...checkData];
                          if (array.length === 0) {
                            array.push(params);
                          } else {
                            let ok = false;
                            let index = 0;
                            checkData.forEach((item, jndex) => {
                              if (item === params) {
                                ok = true;
                                index = jndex;
                              }
                            });

                            if (ok === true) {
                              array.splice(index, 1);
                            } else {
                              array.push(params);
                            }
                          }

                          setCheckData(array);
                        }}
                      />
                    );
                  })}

                <St_ArrowSection>
                  {allCheck === false && (
                    <Pagination
                      count={Math.ceil(checkData.length / 10)}
                      onChange={handlePage}
                    />
                  )}
                  {allCheck === true && (
                    <Pagination
                      count={Math.ceil(totalCount / 10)}
                      onChange={handlePage}
                    />
                  )}
                </St_ArrowSection>
              </TableSection>

              <ManagerSection>
                <h2
                  style={{
                    marginBottom: '20px',
                    fontWeight: 'bold',
                  }}
                >
                  공유 관리자 선택
                </h2>
                <TableColumnSection>
                  <TableColumn width={20}>선택</TableColumn>
                  <TableColumn width={80}>관리자명</TableColumn>
                  <TableColumn width={80}>이메일</TableColumn>
                  <TableColumn width={80}>관리자 구분</TableColumn>
                </TableColumnSection>

                {salesManager.map(item => {
                  if (item.division === 'sales') {
                    return (
                      <TableManagerSection
                        checkManager={checkManager}
                        setCheckManager={params => {
                          setCheckManager(params);
                        }}
                        item={item}
                      />
                    );
                  }
                })}
              </ManagerSection>
            </SalesSection>

            <button
              style={{
                display: 'block',
                marginTop: '30px',
                marginLeft: 'auto',
                backgroundColor: '#999',
                color: '#fff',
                borderRadius: '5px',
                width: '100px',
                height: '40px',
              }}
              onClick={allCheck === true ? shareAllData : shareData}
            >
              공유
            </button>
          </TitleCard>
        )}

        {mode === 'memo' && (
          <TitleCard title="영업 관리자 현황">
            <St_ChangeModeButtonSection>
              <St_ChangeModeButton
                mode={'search' === mode}
                onClick={() => {
                  setMode('search');
                }}
              >
                검색
              </St_ChangeModeButton>
              <St_ChangeModeButton
                mode={'memo' === mode}
                onClick={() => {
                  setMode('memo');
                }}
              >
                관리자 현황
              </St_ChangeModeButton>
              <St_ChangeModeButton
                mode={'performance' === mode}
                onClick={() => {
                  setMode('performance');
                }}
              >
                영업실적
              </St_ChangeModeButton>
              <St_ChangeModeButton
                mode={'share' === mode}
                onClick={() => {
                  setMode('share');
                }}
              >
                1차 데이터 공유
              </St_ChangeModeButton>
              <St_ChangeModeButton
                mode={'contact' === mode}
                onClick={() => {
                  setMode('contact');
                }}
              >
                방문 데이터 공유
              </St_ChangeModeButton>
              <St_ChangeModeButton
                mode={'second' === mode}
                onClick={() => {
                  setMode('second');
                }}
              >
                방문 데이터
              </St_ChangeModeButton>
              <St_ChangeModeButton
                mode={'trash' === mode}
                onClick={() => {
                  setMode('trash');
                }}
              >
                휴지통
              </St_ChangeModeButton>
              {access !== '영업 관리' && (
                <St_ChangeModeButton
                  mode={'check_item' === mode}
                  onClick={() => {
                    setMode('check_item');
                  }}
                >
                  체크리스트
                </St_ChangeModeButton>
              )}
            </St_ChangeModeButtonSection>

            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '20px',
              }}
            >
              <AdminGrid>
                <AdminGridTitle>1차 영업 관리자</AdminGridTitle>
                <AdminGridCols columns={Columns} />
                <AdminGridRows
                  data={salesAdminData}
                  setCheckData={params => {
                    setTarget(params);
                  }}
                  target={target}
                  adminArea={salesAdminArea}
                />
              </AdminGrid>

              <AdminGrid>
                <AdminGridTitle>2차 영업 관리자</AdminGridTitle>
                <AdminGridCols columns={Columns} />
                <AdminGridRows
                  data={secondAdminData}
                  setCheckData={params => {
                    setTarget(params);
                  }}
                  target={target}
                  adminArea={salesAdminArea}
                />
              </AdminGrid>

              <AdminGrid>
                <AdminGridTitle>방문 영업 관리자</AdminGridTitle>
                <AdminGridCols columns={Columns} />
                <AdminGridRows
                  data={contactAdminData}
                  setCheckData={params => {
                    setTarget(params);
                  }}
                  target={target}
                  adminArea={salesAdminArea}
                />
              </AdminGrid>
            </div>
          </TitleCard>
        )}

        {mode === 'check_item' && <CheckItem mode={mode} setMode={setMode} />}

        {mode === 'performance' && (
          <TitleCard title="영업 관리자 실적 현황">
            <St_ChangeModeButtonSection>
              <St_ChangeModeButton
                mode={'search' === mode}
                onClick={() => {
                  setMode('search');
                }}
              >
                검색
              </St_ChangeModeButton>
              <St_ChangeModeButton
                mode={'memo' === mode}
                onClick={() => {
                  setMode('memo');
                }}
              >
                관리자 현황
              </St_ChangeModeButton>
              <St_ChangeModeButton
                mode={'performance' === mode}
                onClick={() => {
                  setMode('performance');
                }}
              >
                영업실적
              </St_ChangeModeButton>
              <St_ChangeModeButton
                mode={'share' === mode}
                onClick={() => {
                  setMode('share');
                }}
              >
                1차 데이터 공유
              </St_ChangeModeButton>
              <St_ChangeModeButton
                mode={'contact' === mode}
                onClick={() => {
                  setMode('contact');
                }}
              >
                방문 데이터 공유
              </St_ChangeModeButton>
              <St_ChangeModeButton
                mode={'second' === mode}
                onClick={() => {
                  setMode('second');
                }}
              >
                방문 데이터
              </St_ChangeModeButton>
              <St_ChangeModeButton
                mode={'trash' === mode}
                onClick={() => {
                  setMode('trash');
                }}
              >
                휴지통
              </St_ChangeModeButton>
              {access !== '영업 관리' && (
                <St_ChangeModeButton
                  mode={'check_item' === mode}
                  onClick={() => {
                    setMode('check_item');
                  }}
                >
                  체크리스트
                </St_ChangeModeButton>
              )}
            </St_ChangeModeButtonSection>

            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '20px',
              }}
            >
              <DatePickerComponent
                setDate={params => {
                  setDate(params);
                }}
              />

              <AdminGrid>
                <AdminGridTitle>1차 영업 관리자</AdminGridTitle>
                <AdminGridCols columns={FirstColumns} />
                <AdminGridRowsPerformanceFirst
                  data={salesManager}
                  setCheckData={params => {
                    setTarget(params);
                  }}
                  target={target}
                />
              </AdminGrid>

              <AdminGrid>
                <AdminGridTitle>2차 영업 관리자</AdminGridTitle>
                <AdminGridCols columns={SecondColumns} />
                <AdminGridRowsPerformanceSecond
                  data={salesManager}
                  setCheckData={params => {
                    setTarget(params);
                  }}
                  target={target}
                />
              </AdminGrid>

              {/* <AdminGrid>
                                    <AdminGridTitle>2차 영업 관리자</AdminGridTitle>
                                    <AdminGridCols columns={Columns} />
                                    <AdminGridRows 
                                        data={secondAdminData}
                                        setCheckData={ (params)=>{ setTarget(params); } }
                                        target={target}
                                        adminArea={salesAdminArea}
                                    />
                                </AdminGrid>


                                <AdminGrid>
                                    <AdminGridTitle>방문 영업 관리자</AdminGridTitle>
                                    <AdminGridCols columns={Columns} />
                                    <AdminGridRows 
                                        data={contactAdminData}
                                        setCheckData={ (params)=>{ setTarget(params); } }
                                        target={target}
                                        adminArea={salesAdminArea}
                                    />
                                </AdminGrid> */}
            </div>
          </TitleCard>
        )}

        {mode === 'trash' && (
          <TitleCard title="영업">
            <St_ChangeModeButtonSection>
              <St_ChangeModeButton
                mode={'search' === mode}
                onClick={() => {
                  setMode('search');
                }}
              >
                검색
              </St_ChangeModeButton>
              <St_ChangeModeButton
                mode={'memo' === mode}
                onClick={() => {
                  setMode('memo');
                }}
              >
                관리자 현황
              </St_ChangeModeButton>
              <St_ChangeModeButton
                mode={'performance' === mode}
                onClick={() => {
                  setMode('performance');
                }}
              >
                영업실적
              </St_ChangeModeButton>
              <St_ChangeModeButton
                mode={'share' === mode}
                onClick={() => {
                  setMode('share');
                }}
              >
                1차 데이터 공유
              </St_ChangeModeButton>
              <St_ChangeModeButton
                mode={'contact' === mode}
                onClick={() => {
                  setMode('contact');
                }}
              >
                방문 데이터 공유
              </St_ChangeModeButton>
              <St_ChangeModeButton
                mode={'second' === mode}
                onClick={() => {
                  setMode('second');
                }}
              >
                방문 데이터
              </St_ChangeModeButton>
              <St_ChangeModeButton
                mode={'trash' === mode}
                onClick={() => {
                  setMode('trash');
                }}
              >
                휴지통
              </St_ChangeModeButton>
              {access !== '영업 관리' && (
                <St_ChangeModeButton
                  mode={'check_item' === mode}
                  onClick={() => {
                    setMode('check_item');
                  }}
                >
                  체크리스트
                </St_ChangeModeButton>
              )}
              ㅋ
            </St_ChangeModeButtonSection>

            <SalesSection>
              <TrashSection>
                <h1
                  style={{
                    fontWeight: 'bold',
                    marginBottom: '20px',
                  }}
                >
                  휴지통
                </h1>
                <TableColumnSection>
                  <TableColumn width={10}>선택</TableColumn>
                  <TableColumn width={20}>상호명</TableColumn>
                  <TableColumn width={15}>대표자명</TableColumn>
                  <TableColumn width={15}>전화번호</TableColumn>
                  <TableColumn width={20}>면허</TableColumn>
                  <TableColumn width={20}>담당 관리자</TableColumn>
                  <TableColumn width={10}>상세보기</TableColumn>
                </TableColumnSection>

                {trash.length === 0 && (
                  <span
                    style={{
                      textAlign: 'center',
                      fontSize: '14px',
                      color: '#333',
                      paddingTop: '50px',
                    }}
                  >
                    {' '}
                    메모가 존재하지 않습니다.
                  </span>
                )}

                {trash.map(item => {
                  return (
                    <TrashRowSection
                      item={item.sales_data}
                      checkData={selectedTrash}
                      allCheck={allCheck}
                      name={item.member_name}
                      setCheckData={params => {
                        const array = [...selectedTrash];
                        if (array.length === 0) {
                          array.push(params.ncrgsseq);
                        } else {
                          let ok = false;
                          let index = 0;
                          selectedTrash.forEach((item, jndex) => {
                            if (item.ncrgsseq === params.ncrgsseq) {
                              ok = true;
                              index = jndex;
                            }
                          });

                          if (ok === true) {
                            array.splice(index, 1);
                          } else {
                            array.push(params.ncrgsseq);
                          }
                        }

                        setSelectedTrash(array);
                      }}
                    />
                  );
                })}
              </TrashSection>
            </SalesSection>

            <button
              style={{
                display: 'block',
                marginTop: '30px',
                marginLeft: 'auto',
                backgroundColor: '#999',
                color: '#fff',
                borderRadius: '5px',
                width: '100px',
                height: '40px',
              }}
              onClick={restoreTrash}
            >
              복원
            </button>
          </TitleCard>
        )}

        {mode === 'second' && <Second mode={mode} setMode={setMode} />}

        {mode === 'contact' && (
          <Contact
            target={target}
            mode={mode}
            setMode={setMode}
            contactAdminData={contactAdminData}
          />
        )}
      </Grid>

      {/* <Grid item>
                        <Grid container justifyContent="flex-end" direction="row" spacing={2}>
                            {status !== 'change' && <Grid item><Button disabled={status === 'change'} variant="contained" onClick={createNoticeData}>글 작성</Button></Grid>}
                            {status !== 'create' && <Grid item><Button disabled={id === 0} variant="contained" onClick={updateNoticesData}>글 수정</Button></Grid>}
                            {(status === 'change' || status === 'create') && <Grid item><Button variant="outlined" onClick={()=>{setStatus(''); setTitle(''); setContent('');}}>취소</Button></Grid>}
                            {status !== 'create' && <Grid item><Button disabled={id === 0} sx={{backgroundColor: 'red'}} variant="contained" onClick={deleteNoticeData}>글 삭제</Button></Grid>}
                        </Grid>
                    </Grid> */}
    </Grid>
  );
}

export default Sales;
