import axios from 'axios';
import { SelectedMemberTypes } from '../app/slice/emailSlice';

const name = localStorage.getItem('name');
const user_id = localStorage.getItem('id');

interface ConvertPropsTypes {
  name: string;
  value: string | null | undefined;
  type: string | null | undefined;
}

function convertArrayToCheckList(array: ConvertPropsTypes[]) {
  const result: { [key: string]: any } = {};

  array.forEach((item) => {
    // 항목의 값이 배열인 경우 재귀적으로 처리합니다.
    if (Array.isArray(item.value)) {
      result[item.name] = convertArrayToCheckList(item.value);
    } else {
      // 값이 배열이 아니며 type에 따라 키를 조정합니다.
      let keySuffix = '';
      if (item.type === 'check') keySuffix = '_check';
      if (item.type === 'time') keySuffix = '_time';
      if (item.type === 'input') keySuffix = '_input';

      result[item.name + keySuffix] = item.value;
    }
  });

  return result;
}

async function getCompanyNameByUniqueKey(unique_key: string) {
  const option = {
    url: 'https://cpi.contax.ai/sales/convert_unique_key_to_company_name',
    method: 'POST',
    data: {
      unique_keys: [unique_key]
    }
  };

  try {
    const result = await axios(option);
    if (result.status === 200) {
      if (result.data[0].error === 'Not found in any table') {
        return { status: 'failed', data: 'Not Found' };
      }
      return { status: 'success', data: result.data[0] };
    }

    return { status: 'failed', data: 'server error' };
  } catch (error) {
    return { status: 'failed', data: 'server error' };
  }
}

async function sendReportEmail(
  sender: string,
  receiver: { email: string; name: string },
  subject: string,
  content: string
) {
  const array: string[] = [receiver.email];

  const option = {
    url: 'https://cpi.contax.ai/send_html_email',
    method: 'POST',
    data: {
      sender: sender,
      receiver: array,
      subject: subject,
      content: {
        plain: '',
        html: content
      }
    }
  };

  try {
    const response = await axios(option);
    if (response.status === 200) {
      return 'success';
    }
  } catch (error) {
    return 'failed';
  }
}

async function sendEmail(
  sender: string,
  receiver: SelectedMemberTypes[],
  subject: string,
  content: string
) {
  const array: string[] = [];
  receiver.forEach((item) => {
    array.push(item.email);
  });

  const option = {
    url: 'https://cpi.contax.ai/send_html_email',
    method: 'POST',
    data: {
      sender: sender,
      receiver: array,
      subject: subject,
      content: {
        plain: '',
        html: content
      }
    }
  };

  try {
    const response = await axios(option);
    if (response.status === 200) {
      return 'success';
    }
  } catch (error) {
    return 'failed';
  }
}

export async function getEmailMember(
  subscriptions: string[],
  areas: string[],
  offset: number,
  limit: number
) {
  let paramsPlan: string[] = [];
  let paramsArea: string[] = [];

  if (subscriptions.length === 0) {
    paramsPlan = ['비즈니스', '퍼스트', '콘택스'];
  } else {
    paramsPlan = subscriptions;
  }

  if (areas.length === 0) {
    paramsArea = [
      '서울',
      '경기',
      '인천',
      '부산',
      '경남',
      '경북',
      '충남',
      '충북',
      '전남',
      '전북',
      '대구',
      '광주',
      '대전',
      '울산',
      '세종',
      '강원',
      '제주'
    ];
  } else {
    paramsArea = areas;
  }

  const option = {
    url: 'https://cpi.contax.ai/get_email_member',
    method: 'POST',
    data: {
      subscriptions: paramsPlan,
      areas: paramsArea,
      offset: offset * limit,
      limit: limit
    }
  };

  try {
    const response = await axios(option);
    if (response.status === 200) {
      return { status: 'success', data: response.data };
    } else {
      return { status: 'failed', data: [] };
    }
  } catch (error) {
    return { status: 'failed', message: error };
  }
}

export async function getManagerEmailMember(
  id: number,
  subscriptions: string[],
  areas: string[],
  offset: number,
  limit: number
) {
  let paramsPlan: string[] = [];
  let paramsArea: string[] = [];

  if (subscriptions.length === 0) {
    paramsPlan = ['비즈니스', '퍼스트', '콘택스'];
  } else {
    paramsPlan = subscriptions;
  }

  if (areas.length === 0) {
    paramsArea = [
      '서울',
      '경기',
      '인천',
      '부산',
      '경남',
      '경북',
      '충남',
      '충북',
      '전남',
      '전북',
      '대구',
      '광주',
      '대전',
      '울산',
      '세종',
      '강원',
      '제주'
    ];
  } else {
    paramsArea = areas;
  }

  const option = {
    url: 'https://cpi.contax.ai/manager_email_member',
    method: 'POST',
    data: {
      id: id,
      subscriptions: paramsPlan,
      areas: paramsArea,
      offset: offset,
      limit: limit
    }
  };

  try {
    const response = await axios(option);
    if (response.status === 200) {
      return { status: 'success', data: response.data };
    } else {
      return { status: 'failed', data: [] };
    }
  } catch (error) {
    return { status: 'failed', message: error };
  }
}

async function insertSalesHistory(ncrgskname: string, type: string) {
  const option = {
    url: 'https://cpi.contax.ai/insert_sales_history',
    method: 'POST',
    params: {
      id: user_id,
      name: name,
      target: '',
      item: ncrgskname,
      type: type,
      targetId: 0
    },
    header: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };

  try {
    const response = await axios(option);
    if (response.status === 200) {
      return { status: 'true', message: 'success' };
    }
  } catch (error) {
    return { status: 'error', message: error };
  }
}

async function getSalesData(user_id: number) {
  const option = {
    url: 'https://cpi.contax.ai/load_sales_member',
    method: 'GET',
    params: {
      user_id: user_id
    },
    header: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };

  try {
    const response = await axios(option);
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    return { status: 'error', message: error };
  }
}

function calculatePagination(length: number, limit: number) {
  const pageLength = +length / +limit;

  if (+length % +limit === 0) {
    return Math.floor(pageLength);
  }

  return Math.floor(pageLength) + 1;
}

function createPaginationSize(offset: number, length: number) {
  const array = [];
  let firstNumber = offset + 1 - 2;
  let lastNumber = offset + 1 + 2;

  // offset이 3보다 작은경우
  if (offset + 1 - 2 < 1) {
    firstNumber = 1;
  }

  // offset 뒤의 숫자 갯수가 2개 미만 일때
  if (offset + 1 - 2 > length - 5 + 1) {
    firstNumber = length - 5 + 1;
  }

  // offset 앞의 숫자가 길이보다 큰 경우
  if (offset + 1 + 2 > length) {
    lastNumber = length;
  }

  // offset 3보다 작고 길이가 5보다 작은 경우
  if (offset + 1 + 2 < 5 && length <= 5) {
    lastNumber = length;
  }

  // offset 3보다 작고 길이가 5보다 큰 경우
  if (offset + 1 + 2 < 5 && length > 5) {
    lastNumber = 5;
  }

  for (let index = firstNumber; index <= lastNumber; index++) {
    if (index > 0) {
      array.push(index);
    }
  }

  return array;
}

export {
  getCompanyNameByUniqueKey,
  sendEmail,
  sendReportEmail,
  convertArrayToCheckList,
  getSalesData,
  insertSalesHistory,
  calculatePagination,
  createPaginationSize
};
