/* eslint-disable react/jsx-pascal-case */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import ExpandMore from '../../../asset/expand_more_FILL0_wght400_GRAD0_opsz24.svg';
import ExpandeLess from '../../../asset/expand_less_FILL0_wght400_GRAD0_opsz24.svg';
import {
  Button,
  Divider,
  FormControl,
  Input,
  ListItemText,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import Editor from '../../../components/Editor';
import { useDispatch, useSelector } from 'react-redux';
import {
  enterJonghapExtraInfo,
  updateIndex,
  updateJonghapIndex,
  updateSelectedJonghapData,
} from '../../../app/slice/jonghapSlice';
import { updateJeonmoonIndex } from '../../../app/slice/jeonmoonSlice';
import axios from 'axios';
import {
  getCheckList,
  resetRecall,
  updateCheckList,
  updateMemo,
  updateRecallDate,
  updateRecallTrue,
} from '../../../app/slice/checkListSlice';
import { getContactCheckList } from '../../../app/slice/contactCheckListSlice';

const St_SalesSection = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
`;

const St_SearchButton = styled.button`
  width: 100px;
  height: 40px;
  border-radius: 5px;
  background-color: #999;
  color: #fff;
`;

const St_TableColumn = styled.div`
  font-size: 14px;
  text-align: center;
  font-weight: bold;
  padding: 5px;
  width: ${props => props.width + '%'};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  word-break: keep-all;
`;

const St_TableRow = styled.div`
  font-size: 14px;
  height: auto;
  width: ${props => props.width + '%'};
  border-right: 1px solid #ddd;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  padding: 5px;
  word-break: break-all;

  &:first-child {
    border-left: 1px solid #ddd;
  }
`;

const St_FilterCategoryTitle = styled.span`
  font-size: 20px;
  font-weight: bold;
  width: 100%;
  display: inline-block;
  cursor: pointer;
`;

const St_TableRowSection = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  background-color: ${props =>
    props.is_saved === true ? 'rgba(0, 228, 42, 0.22)' : '#fff'};
  border-bottom: 1px solid #eee;
  border-top: 1px solid #eee;
  &:hover {
    background-color: ${props =>
      props.is_saved === true ? 'rgba(0, 228, 42, 0.35)' : 'rgba(0,0,0,0.03)'};
  }
`;

const St_TableColumnSection = styled.div`
  display: flex;
  height: 50px;
  background-color: #1976d2;
  align-items: center;
  width: 100%;
  color: #fff;
`;

const St_FilterCategorySection = styled.div`
  padding: 20px;
  padding-top: 0px;
  padding-left: 0px;
  display: ${props => (props.categoryOn === true ? 'flex' : 'none')};
  flex-direction: column;
  gap: 20px;
`;

const St_ManagerSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 30%;
  padding: 0 0px 0 30px;
`;

const St_FilterSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  padding: 0 30px 0 10px;

  margin-bottom: 30px;
`;

const St_TableSection = styled.div`
  width: 70%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #fff;
`;

const St_FilterRow = styled.div`
  width: 70%;
  display: flex;
  justify-content: space-between;
`;

const St_FilterRowTitle = styled.span`
  font-size: 1rem;
  margin-bottom: 5px;
`;

const St_FilterRowInput = styled.input`
  width: 70%;
  border: 1px solid #ddd;
  padding: 5px;
`;

const St_RowDetailContent = styled.div`
  padding: 20px;

  width: 60%;

  background-color: #fff;
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 5px;
  align-items: center;
`;

const St_RowDetailElement = styled.span`
  font-size: 14px;
`;

const St_TitleSection = styled.div`
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  font-size: 18px;
`;

export function SalesSection({ children }) {
  return <St_SalesSection>{children}</St_SalesSection>;
}

export function FilterSection({ children }) {
  return <St_FilterSection>{children}</St_FilterSection>;
}

export function ManagerSection({ children }) {
  return <St_ManagerSection>{children}</St_ManagerSection>;
}

const St_TrashSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 0px 0 10px;
`;

export function TrashSection({ children }) {
  return <St_TrashSection>{children}</St_TrashSection>;
}

export function TableSection({ children }) {
  return <St_TableSection>{children}</St_TableSection>;
}

export function TableRowSection({ item, setCheckData, checkData, allCheck }) {
  const [detailOn, setDetailOn] = useState(false);
  const [check, setCheck] = useState(false);

  useEffect(() => {
    checkData.forEach(jtem => {
      if (item.ncrmasternum === jtem.ncrmasternum) {
        setCheck(true);
      }
    });
  }, [checkData]);

  useEffect(() => {
    var ok = false;

    checkData.forEach(jtem => {
      if (item.ncrmasternum === jtem.ncrmasternum) {
        ok = true;
      }
    });

    if (ok === true) {
      setCheck(true);
    } else {
      setCheck(false);
    }
  }, [item]);

  return (
    <>
      <St_TableRowSection>
        <TableRow
          width={10}
          onClick={() => {
            setCheckData(item);
            setCheck(!check);
          }}
        >
          <input
            type="checkbox"
            checked={check === true || allCheck === true}
          />
        </TableRow>
        <TableRow
          width={20}
          onClick={() => {
            setCheckData(item);
            check === false ? setCheck(true) : setCheck(false);
          }}
        >
          {item.ncrgskname}
        </TableRow>
        <TableRow
          width={15}
          onClick={() => {
            setCheckData(item);
            check === false ? setCheck(true) : setCheck(false);
          }}
        >
          {item.ncrgsmaster}
        </TableRow>
        <TableRow
          width={20}
          onClick={() => {
            setCheckData(item);
            check === false ? setCheck(true) : setCheck(false);
          }}
        >
          {item.ncrofftel}
        </TableRow>
        <TableRow
          width={25}
          onClick={() => {
            setCheckData(item);
            check === false ? setCheck(true) : setCheck(false);
          }}
        >
          {item.ncritemname}
        </TableRow>
        <TableRow
          width={10}
          onClick={() => {
            setDetailOn(!detailOn);
          }}
        >
          <button
            style={{
              backgroundColor: '#ddd',
              borderRadius: '4px',
              fontSize: '12px',
              padding: '5px',
            }}
          >
            상세보기
          </button>
        </TableRow>
      </St_TableRowSection>
      <RowDetailContent data={item} detailOn={detailOn} />
    </>
  );
}

export function TrashRowSection({
  item,
  setCheckData,
  checkData,
  allCheck,
  name,
}) {
  const [detailOn, setDetailOn] = useState(false);
  const [check, setCheck] = useState(false);

  useEffect(() => {
    checkData.forEach(jtem => {
      if (item.ncrmasternum === jtem.ncrmasternum) {
        setCheck(true);
      }
    });
  }, [checkData]);

  useEffect(() => {
    var ok = false;

    checkData.forEach(jtem => {
      if (item.ncrmasternum === jtem.ncrmasternum) {
        ok = true;
      }
    });

    if (ok === true) {
      setCheck(true);
    } else {
      setCheck(false);
    }
  }, [item]);

  return (
    <>
      <St_TableRowSection>
        <TableRow
          width={10}
          onClick={() => {
            setCheckData(item);
            setCheck(!check);
          }}
        >
          <input
            type="checkbox"
            checked={check === true || allCheck === true}
          />
        </TableRow>
        <TableRow
          width={20}
          onClick={() => {
            setCheckData(item);
            check === false ? setCheck(true) : setCheck(false);
          }}
        >
          {item.ncrgskname}
        </TableRow>
        <TableRow
          width={15}
          onClick={() => {
            setCheckData(item);
            check === false ? setCheck(true) : setCheck(false);
          }}
        >
          {item.ncrgsmaster}
        </TableRow>
        <TableRow
          width={15}
          onClick={() => {
            setCheckData(item);
            check === false ? setCheck(true) : setCheck(false);
          }}
        >
          {item.ncrofftel}
        </TableRow>
        <TableRow
          width={20}
          onClick={() => {
            setCheckData(item);
            check === false ? setCheck(true) : setCheck(false);
          }}
        >
          {item.ncritemname}
        </TableRow>
        <TableRow
          width={20}
          onClick={() => {
            setCheckData(item);
            check === false ? setCheck(true) : setCheck(false);
          }}
        >
          {name}
        </TableRow>
        <TableRow
          width={10}
          onClick={() => {
            setDetailOn(!detailOn);
          }}
        >
          <button
            style={{
              backgroundColor: '#ddd',
              borderRadius: '4px',
              fontSize: '12px',
              padding: '5px',
            }}
          >
            상세보기
          </button>
        </TableRow>
      </St_TableRowSection>
      <RowDetailContent data={item} detailOn={detailOn} />
    </>
  );
}

export function MemoRowSection({ item }) {
  const [detailOn, setDetailOn] = useState(false);
  return (
    <>
      <St_TableRowSection>
        <TableRow width={10}>
          <input type="checkbox" />
        </TableRow>
        <TableRow width={20}>{item.ncrgskname}</TableRow>
        <TableRow width={15}>{item.ncrgsmaster}</TableRow>
        <TableRow width={20}>{item.ncrofftel}</TableRow>
        <TableRow width={25}>{item.ncritemname}</TableRow>

        <TableRow
          width={10}
          onClick={() => {
            setDetailOn(!detailOn);
          }}
        >
          <button
            style={{
              backgroundColor: '#ddd',
              borderRadius: '4px',
              fontSize: '12px',
              padding: '5px',
            }}
          >
            상세보기
          </button>
        </TableRow>
      </St_TableRowSection>
      <RowDetailContent data={item} detailOn={detailOn} />
    </>
  );
}

export function TableManagerSection({ item, checkManager, setCheckManager }) {
  return (
    <>
      <St_TableRowSection
        onClick={() => {
          setCheckManager(item);
        }}
      >
        <TableRow width={20}>
          <input type="radio" checked={checkManager.user_id === item.user_id} />
        </TableRow>
        <TableRow width={80}>{item.name}</TableRow>
        <TableRow width={80}>{item.email}</TableRow>
        <TableRow width={80}>1차</TableRow>
      </St_TableRowSection>
    </>
  );
}

export function TableManagerMemoRowSection({
  item,
  setCheckData,
  checkData,
  adminArea,
}) {
  const [check, setCheck] = useState(false);
  const [area, setArea] = useState('');
  const [areaDetail, setAreaDetail] = useState('');

  useEffect(() => {
    if (adminArea !== undefined) {
      adminArea.forEach(data => {
        let areaString = '';
        let areaDetailString = '';

        if (data.name === item.name) {
          data.ncrareaname.forEach(jtem => {
            areaString += jtem + ', ';
          });

          data.ncrareadetailname.forEach(jtem => {
            areaDetailString += jtem + ', ';
          });

          setArea(areaString);
          setAreaDetail(areaDetailString);
        }
      });
    }
  }, [adminArea]);

  useEffect(() => {
    setCheck(false);
    if (item.user_id === checkData.user_id) {
      setCheck(true);
    }
  }, [checkData]);

  return (
    <>
      <St_TableRowSection>
        <TableRow
          width={10}
          onClick={() => {
            setCheckData(item);
          }}
        >
          <input type="radio" checked={check} />
        </TableRow>
        <TableRow
          width={30}
          onClick={() => {
            setCheckData(item);
            check === false ? setCheck(true) : setCheck(false);
          }}
        >
          {item.name}
        </TableRow>
        <TableRow
          width={20}
          onClick={() => {
            setCheckData(item);
            check === false ? setCheck(true) : setCheck(false);
          }}
        >
          {item.email}
        </TableRow>
        <TableRow
          width={20}
          onClick={() => {
            setCheckData(item);
            check === false ? setCheck(true) : setCheck(false);
          }}
        >
          {item.phone_number}
        </TableRow>
        <TableRow
          width={20}
          onClick={() => {
            setCheckData(item);
            check === false ? setCheck(true) : setCheck(false);
          }}
        >
          {area}
        </TableRow>
        <TableRow
          width={20}
          onClick={() => {
            setCheckData(item);
            check === false ? setCheck(true) : setCheck(false);
          }}
        >
          {areaDetail.replaceAll('-, ', '')}
        </TableRow>
      </St_TableRowSection>
    </>
  );
}

export function TableExpertRows({
  item,
  selectedDetailData,
  setSelectedDetailData,
  setSelectedData,
  selectedData,
  setCheckData,
  checkData,
  detailOn,
  setDetailOn,
  adminArea,
}) {
  const [check, setCheck] = useState(false);

  useEffect(() => {
    setCheck(false);
  }, [checkData]);

  function onClick() {
    const array = [...selectedData];
    let isOk = false;

    selectedData.forEach((jtem, jndex) => {
      if (jtem === item.id) {
        isOk = true;
        array.splice(jndex, 1);
      }
    });

    if (isOk === false) {
      array.push(item.id);
    }

    setSelectedData(array);

    check === false ? setCheck(true) : setCheck(false);
  }

  return (
    <>
      {detailOn === false && (
        <St_TableRowSection>
          <TableRow width={5} onClick={onClick}>
            <input type="checkbox" checked={check} />
          </TableRow>
          <TableRow width={10} onClick={onClick}>
            {item.id}
          </TableRow>
          <TableRow width={15} onClick={onClick}>
            {item.writter_name}
          </TableRow>
          <TableRow width={20} onClick={onClick}>
            {item.writter_email}
          </TableRow>
          <TableRow width={10} onClick={onClick}>
            {item.category}
          </TableRow>
          <TableRow width={20} onClick={onClick}>
            {item.subject}
          </TableRow>
          <TableRow width={10} onClick={onClick}>
            {item.question_date === undefined
              ? ''
              : item.question_date.substring(0, 10)}
          </TableRow>
          <TableRow width={10} onClick={onClick}>
            <button
              onClick={() => {
                setDetailOn(true);
                setSelectedDetailData(item);
              }}
              style={{
                backgroundColor: '#ddd',
                borderRadius: '4px',
                fontSize: '12px',
                padding: '5px 10px',
              }}
            >
              상세내용
            </button>
          </TableRow>
        </St_TableRowSection>
      )}
    </>
  );
}

export function TableThirdManagerRows({
  item,
  selectedData,
  setSelectedData,
  detailOn,
}) {
  console.log(selectedData);
  return (
    <>
      {detailOn === false && (
        <St_TableRowSection>
          <TableRow
            width={20}
            onClick={() => {
              setSelectedData(item);
            }}
          >
            <input
              type="checkbox"
              checked={
                selectedData !== undefined &&
                item.user_id === selectedData.user_id
              }
            />
          </TableRow>
          <TableRow
            width={40}
            onClick={() => {
              setSelectedData(item);
            }}
          >
            {item.name}
          </TableRow>
          <TableRow
            width={40}
            onClick={() => {
              setSelectedData(item);
            }}
          >
            {item.email}
          </TableRow>
        </St_TableRowSection>
      )}
    </>
  );
}

export function TableExpertAnswerRows({
  item,
  onClick,
  setSelectedData,
  setCheckData,
  checkData,
  detailOn,
  setDetailOn,
  adminArea,
}) {
  const [check, setCheck] = useState(false);

  useEffect(() => {
    setCheck(false);
  }, [checkData]);

  return (
    <>
      {detailOn === false && (
        <St_TableRowSection>
          <TableRow
            width={10}
            onClick={() => {
              check === false ? setCheck(true) : setCheck(false);
            }}
          >
            {item.id}
          </TableRow>
          <TableRow
            width={10}
            onClick={() => {
              check === false ? setCheck(true) : setCheck(false);
            }}
          >
            {item.writter_name}
          </TableRow>
          <TableRow
            width={15}
            onClick={() => {
              check === false ? setCheck(true) : setCheck(false);
            }}
          >
            {item.writter_email}
          </TableRow>
          <TableRow
            width={10}
            onClick={() => {
              check === false ? setCheck(true) : setCheck(false);
            }}
          >
            {item.expert_name}
          </TableRow>
          <TableRow
            width={10}
            onClick={() => {
              check === false ? setCheck(true) : setCheck(false);
            }}
          >
            {item.category}
          </TableRow>
          <TableRow
            width={20}
            onClick={() => {
              check === false ? setCheck(true) : setCheck(false);
            }}
          >
            {item.subject}
          </TableRow>
          <TableRow
            width={10}
            onClick={() => {
              check === false ? setCheck(true) : setCheck(false);
            }}
          >
            {item.answer_date === undefined
              ? ''
              : item.question_date.substring(0, 10)}
          </TableRow>
          <TableRow
            width={7.5}
            onClick={() => {
              check === false ? setCheck(true) : setCheck(false);
            }}
          >
            <button
              onClick={() => {
                setDetailOn(true);
                setSelectedData(item);
              }}
              style={{
                backgroundColor: '#ddd',
                borderRadius: '4px',
                fontSize: '12px',
                padding: '5px 10px',
              }}
            >
              상세내용
            </button>
          </TableRow>
          <TableRow
            width={7.5}
            onClick={() => {
              check === false ? setCheck(true) : setCheck(false);
            }}
          >
            <button
              onClick={() => {
                item.answer_status === 'pending' && onClick(item.id);
              }}
              style={
                item.answer_status === 'pending'
                  ? {
                      backgroundColor: '#ddd',
                      borderRadius: '4px',
                      fontSize: '12px',
                      padding: '5px 10px',
                    }
                  : {
                      backgroundColor: '#1976d2',
                      color: '#fff',
                      borderRadius: '4px',
                      fontSize: '12px',
                      padding: '5px 10px',
                    }
              }
            >
              {item.answer_status === 'pending' ? '승인' : '승인 완료'}
            </button>
          </TableRow>
        </St_TableRowSection>
      )}
    </>
  );
}

export function TableOnlyExpertAnswerRows({
  item,
  setTitle,
  setContent,
  onClick,
  setSelectedData,
  setCheckData,
  checkData,
  detailOn,
  setDetailOn,
  adminArea,
}) {
  const [check, setCheck] = useState(false);

  useEffect(() => {
    setCheck(false);
  }, [checkData]);

  return (
    <>
      {detailOn === false && (
        <St_TableRowSection>
          <TableRow
            width={10}
            onClick={() => {
              check === false ? setCheck(true) : setCheck(false);
            }}
          >
            {item.id}
          </TableRow>
          <TableRow
            width={10}
            onClick={() => {
              check === false ? setCheck(true) : setCheck(false);
            }}
          >
            {item.writter_name}
          </TableRow>
          <TableRow
            width={20}
            onClick={() => {
              check === false ? setCheck(true) : setCheck(false);
            }}
          >
            {item.writter_email}
          </TableRow>
          <TableRow
            width={10}
            onClick={() => {
              check === false ? setCheck(true) : setCheck(false);
            }}
          >
            {item.category}
          </TableRow>
          <TableRow
            width={20}
            onClick={() => {
              check === false ? setCheck(true) : setCheck(false);
            }}
          >
            {item.subject}
          </TableRow>
          <TableRow
            width={15}
            onClick={() => {
              check === false ? setCheck(true) : setCheck(false);
            }}
          >
            {item.answer_date === undefined
              ? ''
              : item.question_date.substring(0, 10)}
          </TableRow>
          <TableRow
            width={7.5}
            onClick={() => {
              check === false ? setCheck(true) : setCheck(false);
            }}
          >
            <button
              onClick={() => {
                setDetailOn(true);
                setTitle(item.answer_title);
                setContent(item.answer_contents);
                setSelectedData(item);
              }}
              style={{
                backgroundColor: '#ddd',
                borderRadius: '4px',
                fontSize: '12px',
                padding: '5px 10px',
              }}
            >
              작성
            </button>
          </TableRow>
          <TableRow
            width={7.5}
            onClick={() => {
              check === false ? setCheck(true) : setCheck(false);
            }}
          >
            <button
              style={
                item.answer_status === 'pending'
                  ? {
                      backgroundColor: '#ddd',
                      borderRadius: '4px',
                      fontSize: '12px',
                      padding: '5px 10px',
                    }
                  : {
                      backgroundColor: '#1976d2',
                      color: '#fff',
                      borderRadius: '4px',
                      fontSize: '12px',
                      padding: '5px 10px',
                    }
              }
            >
              {item.answer_status === 'pending' ? '승인 대기' : '승인 완료'}
            </button>
          </TableRow>
        </St_TableRowSection>
      )}
    </>
  );
}

export function ExpertDetailContents({ item, setDetailOn }) {
  const Header = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
  `;

  const Title = styled.div`
    font-size: 20px;
    font-weight: bold;
  `;

  const Category = styled.div`
    font-size: 18px;
    color: #aaa;
    margin-bottom: 20px;
  `;

  const Content = styled.div`
    border-top: 1px solid #ddd;
    width: 100%;
    padding-top: 20px;
    margin-top: 20px;
    min-height: 300px;
  `;

  return (
    <>
      <Header onClick={setDetailOn}>
        <span
          style={{ fontSize: '30px' }}
          className="material-symbols-outlined"
        >
          chevron_left
        </span>
        <span style={{ fontSize: '16px', color: '#333' }}> 뒤로가기</span>
      </Header>

      {item.writter_to_expert === false && (
        <div style={{ padding: '30px', width: '100%' }}>
          <Category>{item.category}</Category>
          <Title>{item.subject}</Title>

          <Content dangerouslySetInnerHTML={{ __html: item.contents }} />
        </div>
      )}

      {item.writter_to_expert === true && (
        <div style={{ display: 'flex', gap: '50px', padding: '30px' }}>
          <div style={{ width: '50%' }}>
            <Category>{item.category}</Category>
            <Title>{item.subject}</Title>

            <Content>{item.contents}</Content>
          </div>

          <div style={{ width: '50%' }}>
            <Category>{item.category}</Category>
            <Title>{item.answer_title}</Title>

            <Content
              dangerouslySetInnerHTML={{
                __html: item.answer_contents,
              }}
            />
          </div>
        </div>
      )}
    </>
  );
}

export function RowSecondDetailMemo({
  item,
  setMemo,
  index,
  detailOn,
  updateMemo,
}) {
  const [checkLists, setCheckLists] = useState([]);

  function convertObjectToArray(obj) {
    return Object.entries(obj).map(([key, value]) => {
      // key를 '_'를 기준으로 분리하여 name과 type을 추출
      const [name, type] = key.split('_');

      if (
        typeof value === 'object' &&
        value !== null &&
        !Array.isArray(value)
      ) {
        // 값이 객체이면 재귀적으로 처리
        return { name, type, value: convertObjectToArray(value) };
      } else {
        // 그렇지 않으면, 기본 변환 수행
        return { name, type, value };
      }
    });
  }

  useEffect(() => {
    setCheckLists(convertObjectToArray(JSON.parse(item.items)));
  }, []);

  return (
    <St_RowDetailMemo detailOn={detailOn}>
      {checkLists.map((checkList, index) => {
        if (
          checkList.type === 'check' &&
          (checkList.value === true || checkList.value === false)
        ) {
          return (
            <>
              <RowDetailMemoCheckList
                checked={checkList.value}
                title={checkList.name}
              />
              <div
                style={{
                  margin: '10px 0',
                  border: '1px solid #eee',
                  borderStyle: 'dotted',
                }}
              />
            </>
          );
        } else if (checkList.type === 'input') {
          return (
            <>
              <RowDetailMemoInput
                value={checkList.value}
                title={checkList.name}
              />
              <div
                style={{
                  margin: '10px 0',
                  border: '1px solid #eee',
                  borderStyle: 'dotted',
                }}
              />
            </>
          );
        } else {
          return (
            <>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '10px',
                }}
              >
                <h3 style={{ fontWeight: 'bold' }}>{checkList.name}</h3>
                {checkList.value.map((jtem, jndex) => {
                  return (
                    <RowDetailMemoCheckList
                      checked={jtem.value}
                      title={jtem.name}
                    />
                  );
                })}
              </div>
              <div
                style={{
                  margin: '10px 0',
                  border: '1px solid #eee',
                  borderStyle: 'dotted',
                }}
              />
            </>
          );
        }
      })}

      <St_Textarea
        placeholder="메모를 입력해주세요"
        defaultValue={item.comment}
        disabled
      />
    </St_RowDetailMemo>
  );
}

const St_Checklist = styled.div`
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  padding: 5px 0;
  width: 100%;
`;
function RowDetailMemoInput({ title, value, onChange }) {
  return (
    <St_Checklist>
      <span>{title}</span>
      <input
        style={{
          border: '1px solid #ddd',
          paddingLeft: '5px',
          paddingRight: '5px',
        }}
        onChange={e => {
          onChange(e.target.value);
        }}
        type="text"
        value={value}
      />
    </St_Checklist>
  );
}

function RowDetailMemoTime({ title, value, onChange, placeholder }) {
  return (
    <div>
      <St_Checklist>
        <span>{title}</span>
        <input
          placeholder={placeholder}
          style={{
            border: '1px solid #ddd',
            padding: '5px 10px',
            fontSize: '14px',
          }}
          onChange={e => {
            onChange(e.target.value);
          }}
          type="text"
          value={value}
        />
      </St_Checklist>
    </div>
  );
}

function RowDetailMemoCheckList({ title, checked }) {
  const dispatch = useDispatch();

  return (
    <St_Checklist
      onClick={() => {
        dispatch(updateCheckList({ name: title, value: !checked }));
      }}
    >
      <span>{title}</span>
      <input type="checkbox" checked={checked} />
    </St_Checklist>
  );
}

export function TableMemoSection({
  division,
  item,
  index,
  setSelectedFirstData,
  selectedFirstData,
}) {
  const [detailOn, setDetailOn] = useState(false);
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    setChecked(false);
    selectedFirstData.forEach(jtem => {
      if (item.id === jtem.id) {
        setChecked(true);
      }
    });
  }, [selectedFirstData]);

  useEffect(() => {
    var ok = false;

    selectedFirstData.forEach(jtem => {
      if (item.id === jtem.id) {
        ok = true;
      }
    });

    if (ok === true) {
      setChecked(true);
    } else {
      setChecked(false);
    }
  }, [item]);
  return (
    <>
      <St_TableRowSection>
        <TableRow width={7} onClick={setSelectedFirstData}>
          <input type="checkbox" checked={checked} />
        </TableRow>
        <TableRow
          width={15}
          onClick={() => {
            setSelectedFirstData(item);
          }}
        >
          {item.area}
        </TableRow>
        <TableRow
          width={15}
          onClick={() => {
            setSelectedFirstData(item);
          }}
        >
          {item.second_saved_date.substring(0, 10)}
        </TableRow>
        <TableRow
          width={15}
          onClick={() => {
            setSelectedFirstData(item);
          }}
        >
          {item.title}
        </TableRow>
        <TableRow
          width={15}
          onClick={() => {
            setSelectedFirstData(item);
          }}
        >
          {item.contact_manager_name}
        </TableRow>
        <TableRow
          width={13}
          onClick={() => {
            setDetailOn(!detailOn);
          }}
        >
          {item.rejection === true && (
            <button
              style={{
                display: 'flex',
                alignItems: 'center',
                backgroundColor: '#da5959',
                borderRadius: '4px',
                fontSize: '12px',
                padding: '5px 10px',
              }}
            >
              <span style={{ whiteSpace: 'nowrap', color: '#fff' }}>반려</span>
              <span
                style={{ fontSize: '16px', color: '#fff' }}
                className="material-symbols-outlined"
              >
                chevron_right
              </span>
            </button>
          )}

          {item.resolve === true &&
            (item.contact_manager_id === null ||
              item.contact_manager_id === 0 ||
              item.contact_manager_id === undefined) && (
              <button
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  backgroundColor: '#1976d2',
                  borderRadius: '4px',
                  fontSize: '12px',
                  padding: '5px 10px',
                }}
              >
                <span
                  style={{
                    whiteSpace: 'nowrap',
                    color: '#fff',
                  }}
                >
                  반려 해결
                </span>
                <span
                  style={{ fontSize: '16px', color: '#fff' }}
                  className="material-symbols-outlined"
                >
                  chevron_right
                </span>
              </button>
            )}

          {item.resolve === undefined &&
            (item.contact_manager_id === null ||
              item.contact_manager_id === 0 ||
              item.contact_manager_id === undefined) && (
              <button
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  backgroundColor: '#ddd',
                  borderRadius: '4px',
                  fontSize: '12px',
                  padding: '5px 10px',
                }}
              >
                <span
                  style={{
                    whiteSpace: 'nowrap',
                    color: '#000',
                  }}
                >
                  공유 대기
                </span>
                <span
                  style={{ fontSize: '16px', color: '#000' }}
                  className="material-symbols-outlined"
                >
                  chevron_right
                </span>
              </button>
            )}
          {(item.resolve === undefined || item.resolve === true) &&
            item.contact_manager_id !== null &&
            item.contact_manager_id !== 0 &&
            item.contact_manager_id !== undefined && (
              <button
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  backgroundColor: '#31cc00',
                  borderRadius: '4px',
                  fontSize: '12px',
                  padding: '5px 10px',
                }}
              >
                <span
                  style={{
                    whiteSpace: 'nowrap',
                    color: '#fff',
                  }}
                >
                  공유 완료
                </span>
                <span
                  style={{ fontSize: '16px', color: '#fff' }}
                  className="material-symbols-outlined"
                >
                  chevron_right
                </span>
              </button>
            )}
        </TableRow>
      </St_TableRowSection>
      {division === 'sales' ? (
        <RowDetailMemo detailOn={detailOn} item={item} index={index} />
      ) : (
        <RowSecondDetailMemo detailOn={detailOn} item={item} index={index} />
      )}
    </>
  );
}

export function SecondTableMemoSection({
  detailOn,
  setDetailOn,
  item,
  index,
  setSelectedFirstData,
}) {
  function convertObjectToArray(obj) {
    return Object.entries(obj).map(([key, value]) => {
      // key를 '_'를 기준으로 분리하여 name과 type을 추출
      const [name, type] = key.split('_');

      if (
        typeof value === 'object' &&
        value !== null &&
        !Array.isArray(value)
      ) {
        // 값이 객체이면 재귀적으로 처리
        return { name, type, value: convertObjectToArray(value) };
      } else {
        // 그렇지 않으면, 기본 변환 수행
        return { name, type, value };
      }
    });
  }

  function rowClick() {
    dispatch(
      getCheckList({
        checkLists: convertObjectToArray(JSON.parse(item.items)),
        memo: item.comment,
      }),
    );
    setSelectedFirstData(item);

    if (item.id !== detailOn.key && detailOn.on === true) {
      setDetailOn({ key: item.id, on: true });
    } else if (item.id !== detailOn.key && detailOn.on === false) {
      setDetailOn({ key: item.id, on: true });
    } else if (item.id === detailOn.key && detailOn.on === false) {
      setDetailOn({ key: item.id, on: true });
    } else if (item.id === detailOn.key && detailOn.on === true) {
      setDetailOn({ key: item.id, on: false });
    }
  }

  const dispatch = useDispatch();
  return (
    <>
      <St_TableRowSection>
        <TableRow width={25} onClick={rowClick}>
          {item.saved_date === null || item.saved_date === undefined
            ? ''
            : item.saved_date.substring(0, 10)}
        </TableRow>
        <TableRow width={30} onClick={rowClick}>
          {item.title}
        </TableRow>
        <TableRow width={25} onClick={rowClick}>
          {item.writter_name}
        </TableRow>
        <TableRow width={20} onClick={rowClick}>
          <button
            style={{
              backgroundColor: '#ddd',
              borderRadius: '4px',
              fontSize: '12px',
              padding: '5px 10px',
            }}
          >
            상세내용
          </button>
        </TableRow>
      </St_TableRowSection>

      <RowDetailMemo detailOn={detailOn} item={item} index={index} />
    </>
  );
}

export function TableRow({ width, onClick, children }) {
  return (
    <St_TableRow onClick={onClick} width={width}>
      {children}
    </St_TableRow>
  );
}

export function TableColumnSection({ children }) {
  return <St_TableColumnSection>{children}</St_TableColumnSection>;
}

export function TableColumn({ width, children, onClick }) {
  return (
    <St_TableColumn width={width} onClick={onClick}>
      {children}
    </St_TableColumn>
  );
}

export function FilterRowCategory({ title, children, menuOn }) {
  const [categoryOn, setCategoryOn] = useState(menuOn);
  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <St_FilterCategoryTitle
          onClick={() => {
            setCategoryOn(!categoryOn);
          }}
        >
          {title}
        </St_FilterCategoryTitle>
        <img
          src={categoryOn === true ? ExpandeLess : ExpandMore}
          alt="ExpandMore"
        />
      </div>

      <St_FilterCategorySection categoryOn={categoryOn}>
        {children}
      </St_FilterCategorySection>
    </>
  );
}

export function FilterRow({ title, filter, setFilters }) {
  return (
    <St_FilterRow>
      <St_FilterRowTitle>{title}</St_FilterRowTitle>
      <St_FilterRowInput value={filter} onChange={setFilters} />
    </St_FilterRow>
  );
}

export function FilterSelect({
  title,
  data,
  filter,
  setFilter,
  salesManagerArea,
}) {
  return (
    <St_FilterRow>
      <St_FilterRowTitle>{title}</St_FilterRowTitle>
      <Select
        sx={{ width: '70%' }}
        value={filter}
        defaultValue=""
        onChange={e => {
          setFilter(e.target.value);
        }}
      >
        <MenuItem value="">선택</MenuItem>
        {data.map(item => {
          return (
            <MenuItem
              dense
              value={
                title === '등록시군구' ? item.substring(0, 5).trim() : item
              }
            >
              <ListItemText>{item}</ListItemText>
            </MenuItem>
          );
        })}
      </Select>
    </St_FilterRow>
  );
}

export function SearchButton({ onClick }) {
  return <St_SearchButton onClick={onClick}>검색</St_SearchButton>;
}

export function TitleSection({ children }) {
  return <St_TitleSection>{children}</St_TitleSection>;
}

export function JonghapJeonmoonRowDetailContent({ data, detailOn }) {
  return (
    <St_RowDetailContent detailOn={detailOn}>
      <h3 style={{ fontWeight: 'bold' }}>상세 내용</h3>
      <span></span>
      <St_RowDetailElement>이메일 : </St_RowDetailElement>
      <St_RowDetailElement>{data.email}</St_RowDetailElement>
      <St_RowDetailElement>휴대전화 번호 : </St_RowDetailElement>
      <St_RowDetailElement>{data.phonenumber}</St_RowDetailElement>
      <St_RowDetailElement>팩스 : </St_RowDetailElement>
      <St_RowDetailElement>
        {data.fax === null ? '' : data.fax}
      </St_RowDetailElement>
      <St_RowDetailElement>담당자 : </St_RowDetailElement>
      <St_RowDetailElement>
        {data.manager === null ? '' : data.manager}
      </St_RowDetailElement>
      <St_RowDetailElement>등록시도 : </St_RowDetailElement>
      <St_RowDetailElement>{data.area}</St_RowDetailElement>
      <St_RowDetailElement>소재지 : </St_RowDetailElement>
      <St_RowDetailElement>
        {data.address === 'NULL' ? '' : data.address}
      </St_RowDetailElement>
      <St_RowDetailElement>사업자등록번호 : </St_RowDetailElement>
      <St_RowDetailElement>
        {data.ncrmasternum === 'NULL' ? '' : data.ncrmasternum}
      </St_RowDetailElement>
      <St_RowDetailElement>종합 평가액 (단위: 백만원) : </St_RowDetailElement>
      <St_RowDetailElement>{data.jonghap_valuation}</St_RowDetailElement>
      <St_RowDetailElement>전문 평가액 (단위: 천원) : </St_RowDetailElement>
      <St_RowDetailElement>
        {data.jeonmoon_valuation === 'NULL' ? 0 : data.jeonmoon_valuation}
      </St_RowDetailElement>
      <St_RowDetailElement>
        종합 평가 합산액 (단위: 백만원):{' '}
      </St_RowDetailElement>
      <St_RowDetailElement>{data.abs_sumb_value}</St_RowDetailElement>
    </St_RowDetailContent>
  );
}

export function RowDetailContent({ data, detailOn }) {
  return (
    <St_RowDetailContent detailOn={detailOn}>
      <h3 style={{ fontWeight: 'bold' }}>상세 내용</h3>
      <span></span>
      <St_RowDetailElement>이메일 : </St_RowDetailElement>
      <St_RowDetailElement>{data.email}</St_RowDetailElement>
      <St_RowDetailElement>휴대전화 번호 : </St_RowDetailElement>
      <St_RowDetailElement>{data.phonenumber}</St_RowDetailElement>
      <St_RowDetailElement>팩스 : </St_RowDetailElement>
      <St_RowDetailElement>
        {data.fax === null ? '' : data.fax}
      </St_RowDetailElement>
      <St_RowDetailElement>담당자 : </St_RowDetailElement>
      <St_RowDetailElement>
        {data.manager === null ? '' : data.manager}
      </St_RowDetailElement>
      <St_RowDetailElement>등록시군구 : </St_RowDetailElement>
      <St_RowDetailElement>{data.ncrareadetailname}</St_RowDetailElement>
      <St_RowDetailElement>등록시도 : </St_RowDetailElement>
      <St_RowDetailElement>{data.ncrareaname}</St_RowDetailElement>
      <St_RowDetailElement>소재지 : </St_RowDetailElement>
      <St_RowDetailElement>{data.ncrgsaddr}</St_RowDetailElement>
      <St_RowDetailElement>등록일자 : </St_RowDetailElement>
      <St_RowDetailElement>{data.ncrgsdate}</St_RowDetailElement>
      <St_RowDetailElement>공시내용 구분 : </St_RowDetailElement>
      <St_RowDetailElement>{data.ncrgsflag}</St_RowDetailElement>
      <St_RowDetailElement>공고번호 : </St_RowDetailElement>
      <St_RowDetailElement>{data.ncrgsnumber}</St_RowDetailElement>
      <St_RowDetailElement>변경사유철회 : </St_RowDetailElement>
      <St_RowDetailElement>{data.ncrgsreason}</St_RowDetailElement>
      <St_RowDetailElement>공시일자 : </St_RowDetailElement>
      <St_RowDetailElement>{data.ncrgsregdate}</St_RowDetailElement>
      <St_RowDetailElement>공시일련번호 : </St_RowDetailElement>
      <St_RowDetailElement>{data.ncrgsseq}</St_RowDetailElement>
      <St_RowDetailElement>업종등록번호 : </St_RowDetailElement>
      <St_RowDetailElement>{data.ncritemregno}</St_RowDetailElement>
      <St_RowDetailElement>사업자등록번호 : </St_RowDetailElement>
      <St_RowDetailElement>{data.ncrmasternum}</St_RowDetailElement>
    </St_RowDetailContent>
  );
}

export function RowDetailExpert({ data, detailOn }) {
  return (
    <St_RowDetailContent detailOn={detailOn}>
      <h3 style={{ fontWeight: 'bold' }}>상세 내용</h3>
      <span></span>
      <span>{data.subject}</span>
      <div style={{ width: '300px', height: '300px', overflow: 'scroll' }}>
        {data.contents}
      </div>
    </St_RowDetailContent>
  );
}

const St_Textarea = styled.textarea`
  font-size: 14px;
  border: 1px solid #ddd;
  padding: 10px;
  resize: none;
  height: 150px;
  width: 100%;
`;
const St_RowDetailMemo = styled.div`
  padding: 20px;
  border-left: 1px solid #eee;
  border-right: 1px solid #eee;
  border-bottom: 1px solid #eee;
  width: 100%;
  height: auto;
  background-color: #fff;
  display: ${props => (props.detailOn === true ? 'block' : 'none')};
`;

export function RowDetailMemo({ shareData, detailOn, item }) {
  const dispatch = useDispatch();
  const { checkLists, memo } = useSelector(state => state.checkList);
  // 1번 아니다
  // 2번 쏘쏘
  // 3번 괜찬네

  return (
    <St_RowDetailMemo
      detailOn={
        item === undefined
          ? true
          : item.id === detailOn.key && detailOn.on === true
      }
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '10px',
          justifyContent: 'space-between',
        }}
      >
        {checkLists.map((checkList, index) => {
          if (
            checkList.type === 'check' &&
            (checkList.value === true || checkList.value === false)
          ) {
            return (
              <>
                <RowDetailMemoCheckList
                  checked={checkList.value}
                  title={checkList.name}
                />
                <div
                  style={{
                    margin: '10px 0',
                    border: '1px solid #eee',
                    borderStyle: 'dotted',
                  }}
                />
              </>
            );
          } else if (
            checkList.type === 'input' &&
            typeof checkList.value !== 'object'
          ) {
            return (
              <>
                <RowDetailMemoInput
                  value={checkList.value}
                  title={checkList.name}
                />
                <div
                  style={{
                    margin: '10px 0',
                    border: '1px solid #eee',
                    borderStyle: 'dotted',
                  }}
                />
              </>
            );
          } else if (
            checkList.type === 'time' &&
            typeof checkList.value !== 'object'
          ) {
            return (
              <>
                <RowDetailMemoTime
                  value={checkList.value}
                  title={checkList.name}
                />
                <div
                  style={{
                    margin: '10px 0',
                    border: '1px solid #eee',
                    borderStyle: 'dotted',
                  }}
                />
              </>
            );
          } else {
            return (
              <>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '10px',
                  }}
                >
                  <h3 style={{ fontWeight: 'bold' }}>{checkList.name}</h3>
                  {checkList.value.map((jtem, jndex) => {
                    return (
                      <RowDetailMemoCheckList
                        checked={jtem.value}
                        title={jtem.name}
                      />
                    );
                  })}
                </div>
                <div
                  style={{
                    margin: '10px 0',
                    border: '1px solid #eee',
                    borderStyle: 'dotted',
                  }}
                />
              </>
            );
          }
        })}

        <h3
          style={{
            fontWeight: 'bold',
            marginTop: '20px',
            marginBottom: '10px',
          }}
        >
          기타 메모 작성
        </h3>
        <St_Textarea
          placeholder="메모를 입력해주세요"
          value={memo}
          onChange={e => {
            dispatch(updateMemo(e.target.value));
          }}
          onKeyDown={e => {
            if (e.key === 'F5') {
              e.preventDefault(); // Prevent the default action of F5
              const now = new Date();

              const timeString =
                now.getFullYear() +
                '년 ' +
                (now.getMonth() + 1) +
                '월 ' +
                now.getDate() +
                '일 ' +
                now.toLocaleTimeString() +
                '\n> ';

              // Update the state with the new time
              const text = memo + timeString;

              dispatch(updateMemo(text));
            }
          }}
        />

        <div
          style={{
            margin: '10px 0',
            border: '1px solid #eee',
            borderStyle: 'dotted',
          }}
        />

        <Button
          variant="contained"
          sx={{ marginTop: '30px', marginLeft: 'auto' }}
          onClick={shareData}
        >
          저장
        </Button>
      </div>
    </St_RowDetailMemo>
  );
}
