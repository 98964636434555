import axios from 'axios';

export interface QueryParams {
  database: 'shared_marketing_user_information';
  categories: string[];
  search_queries: string[];
  offset: number;
  limit: number;
  sort_option: string;
  sort: 'DESC' | 'ASC';
}

async function getTutorialContacts(params: QueryParams) {
  const option = {
    url: 'https://cpi.contax.ai/get_board',
    method: 'POST',
    data: params,
    header: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data',
    },
  };

  try {
    const response = await axios(option);
    if (response.status === 200) {
      return {
        status: 'success',
        data: response.data,
      };
    }
  } catch ({ message }) {
    return {
      status: 'failed',
      message,
    };
  }

  return null;
}

export { getTutorialContacts };
