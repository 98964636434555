import React from 'react';
import { ManagerGridButton } from '../GridType';
import { PencilIcon } from '@heroicons/react/16/solid';

export function ModifyButton(props: { onClick: () => void }) {
  return (
    <ManagerGridButton onClick={props.onClick}>
      <PencilIcon
        style={{
          width: '20px'
        }}
      />
      수정
    </ManagerGridButton>
  );
}
