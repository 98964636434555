import axios from 'axios';
import { MemberType } from '../app/slice/memberManagementSlice';

export interface QueryParams {
  database: 'members';
  categories: string[];
  search_queries: string[];
  offset: number;
  limit: number;
  sort_option: string;
  sort: 'DESC' | 'ASC';
}

export async function getAllUserEmail(
  subscriptions: string[],
  areas: string[]
) {
  let paramsPlan: string[] = [];
  let paramsArea: string[] = [];

  if (subscriptions.length === 0) {
    paramsPlan = ['비즈니스', '퍼스트', '콘택스'];
  } else {
    paramsPlan = subscriptions;
  }

  if (areas.length === 0) {
    paramsArea = [
      '서울',
      '경기',
      '인천',
      '부산',
      '경남',
      '경북',
      '충남',
      '충북',
      '전남',
      '전북',
      '대구',
      '광주',
      '대전',
      '울산',
      '세종',
      '강원',
      '제주'
    ];
  } else {
    paramsArea = areas;
  }

  const option = {
    url: 'https://cpi.contax.ai/get_all_user_email',
    method: 'POST',
    header: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data'
    },
    data: {
      subscriptions: paramsPlan,
      areas: paramsArea
    }
  };

  try {
    const response = await axios(option);
    if (response.status === 200) {
      return {
        status: 'success',
        data: response.data
      };
    }
    return {
      status: 'failed',
      message: ''
    };
  } catch ({ message }) {
    return {
      status: 'failed',
      message
    };
  }
}

export async function getAllUserEmailManager(
  id: number,
  subscriptions: string[],
  areas: string[],
  offset: number,
  limit: number
) {
  let paramsPlan: string[] = [];
  let paramsArea: string[] = [];

  if (subscriptions.length === 0) {
    paramsPlan = ['비즈니스', '퍼스트', '콘택스'];
  } else {
    paramsPlan = subscriptions;
  }

  if (areas.length === 0) {
    paramsArea = [
      '서울',
      '경기',
      '인천',
      '부산',
      '경남',
      '경북',
      '충남',
      '충북',
      '전남',
      '전북',
      '대구',
      '광주',
      '대전',
      '울산',
      '세종',
      '강원',
      '제주'
    ];
  } else {
    paramsArea = areas;
  }

  const option = {
    url: 'https://cpi.contax.ai/manager_email_member_all',
    method: 'POST',
    header: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data'
    },
    data: {
      id,
      subscriptions: paramsPlan,
      areas: paramsArea,
      offset,
      limit
    }
  };

  try {
    const response = await axios(option);
    if (response.status === 200) {
      return {
        status: 'success',
        data: response.data
      };
    }
    return {
      status: 'failed',
      message: ''
    };
  } catch ({ message }) {
    return {
      status: 'failed',
      message
    };
  }
}
async function postUser(params: QueryParams) {
  const option = {
    url: 'https://cpi.contax.ai/get_board',
    method: 'POST',
    data: params,
    header: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data'
    }
  };

  try {
    const response = await axios(option);
    if (response.status === 200) {
      return {
        status: 'success',
        data: response.data
      };
    }
  } catch ({ message }) {
    return {
      status: 'failed',
      message
    };
  }
  return null;
}

async function postTestUser(params: QueryParams) {
  const option = {
    url: 'https://cpi.contax.ai/get_board',
    method: 'POST',
    params,
    header: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data'
    }
  };

  try {
    const response = await axios(option);
    if (response.status === 200) {
      return {
        status: 'success',
        data: response.data
      };
    }
  } catch ({ message }) {
    return {
      status: 'failed',
      message
    };
  }

  return null;
}

async function postManager(params: QueryParams) {
  const option = {
    url: 'https://cpi.contax.ai/get_board',
    method: 'POST',
    params,
    header: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data'
    }
  };

  try {
    const response = await axios(option);
    if (response.status === 200) {
      return {
        status: 'success',
        data: response.data
      };
    }
  } catch ({ message }) {
    return {
      status: 'failed',
      message
    };
  }

  return null;
}

async function getManagerDivision(id: number) {
  const option = {
    url: 'https://cpi.contax.ai/member_manager_division',
    method: 'GET',
    params: {
      id
    },
    header: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data'
    }
  };

  try {
    const response = await axios(option);
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    return error;
  }
}

async function getAccess(id: number) {
  const option = {
    url: 'https://cpi.contax.ai/get_contents_access',
    method: 'GET',
    params: {
      user_id: id
    },
    header: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data'
    }
  };

  try {
    const response = await axios(option);
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    return error;
  }
}

async function updateMember(params: MemberType) {
  const data: MemberType = {
    member_of_manager: '',
    subscription_end: params.subscription_end,
    subscription: params.subscription,
    fax_number: params.fax_number,
    representative_phone: params.representative_phone,
    password: '',
    user_id: params.user_id,
    email: params.email,
    name: params.name,
    is_login: params.is_login,
    phone_number: params.phone_number,
    business_number: params.business_number,
    business_type: params.business_type,
    representative_name: params.representative_name,
    address: params.address,
    company_name: params.company_name,
    position: params.position,
    company_phone_number: params.company_phone_number,
    reg_date: params.reg_date,
    sms_kakao_agree: params.sms_kakao_agree,
    license_names: params.license_names
  };

  const option = {
    url: 'https://cpi.contax.ai/new_mng_page_user',
    method: 'PUT',
    data,
    header: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data'
    }
  };

  try {
    const response = await axios(option);
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    return error;
  }
}

async function getMemberManager(email: string, name: string) {
  const option = {
    url: 'https://cpi.contax.ai/get_member_of_manager',
    params: {
      email,
      name
    },
    method: 'GET',
    header: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };

  console.log(334);

  try {
    const response = await axios(option);
    if (response.status === 200) {
      return response.data.names;
    }
  } catch (error) {
    return '';
  }
}

async function changeManagerDivision(id: number, division: string) {
  const option = {
    url: 'https://cpi.contax.ai/change_member_manager_division',
    data: {
      id,
      division
    },
    method: 'PUT',
    header: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };

  try {
    const response = await axios(option);
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    return error;
  }
}

async function deleteUserData(email: string) {
  const option = {
    url: 'https://cpi.contax.ai/members/delete_member',
    params: {
      email
    },
    method: 'DELETE',
    header: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };

  try {
    const response = await axios(option);
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    alert('서버 오류가 발생하였습니다.');
    return error;
  }

  return null;
}

async function deleteManager(id: number, email: string) {
  const option = {
    url: 'https://cpi.contax.ai/delete_manager_member',
    params: {
      id,
      email
    },
    method: 'DELETE',
    header: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };

  try {
    const response = await axios(option);
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    alert('서버 오류가 발생하였습니다.');
    return error;
  }

  return null;
}

async function updateAccess(user_id: number, access: string[]) {
  const option = {
    url: 'https://cpi.contax.ai/access',
    method: 'PUT',
    data: {
      user_id: user_id,
      access: access,
      assignment: []
    },
    header: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data'
    }
  };

  try {
    const response = await axios(option);
    if (response.status === 200) {
      return {
        status: 'success'
      };
    }
  } catch (error) {
    alert('서버 오류가 발생하였습니다.');

    return {
      status: 'failed',
      message: error
    };
  }
}

async function getAllUser(params: QueryParams) {
  const option = {
    url: 'https://cpi.contax.ai/get_board',
    method: 'POST',
    data: params,
    header: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data'
    }
  };

  try {
    const response = await axios(option);
    if (response.status === 200) {
      return {
        status: 'success',
        data: response.data
      };
    }
  } catch ({ message }) {
    return {
      status: 'failed',
      message: message
    };
  }

  return null;
}

export {
  getMemberManager,
  getAccess,
  updateAccess,
  getAllUser,
  deleteManager,
  getManagerDivision,
  deleteUserData,
  updateMember,
  postUser,
  postManager,
  changeManagerDivision,
  postTestUser
};
