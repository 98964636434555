import React from "react";
import styled from "styled-components";

const Title = styled.span<{active: boolean}>`
    font-size: 16px;
    font-weight: bold;
    color: #1a3155;
    opacity: ${props => props.active ? 1 : 0.5};
    cursor: pointer;
    transition: opacity 0.3s ease;

    &:hover{
        opacity: 1;
    }
`

interface PropTypes{
    active: boolean
    onClick: () => void
    title: string
}

export function AllCheck(props:PropTypes){
    return(
        <div style={{display: 'flex', gap: '10px', alignItems: 'center'}} onClick={props.onClick}>
            <Title active={props.active}>{props.title}</Title>
            <input type="checkbox" checked={props.active} />
        </div>
    )
}