import TitleCard from '../../components/Cards/TitleCard';
import Button from '@mui/material/Button';
import { Grid, Stack, TextField, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { getToken } from '../../utils/getToken';

function formatToKoreanCurrency(num) {
  num = Math.round(num);
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

const contentsColumns = [
  { field: 'user_id', headerName: '회원번호', width: 70 },
  { field: 'name', headerName: '이름', width: 130 },
  { field: 'email', headerName: '이메일', width: 130 },
  {
    field: 'password',
    headerName: '비밀번호',
    width: 130,
    renderCell: params => {
      return '*'.repeat(params.value.length);
    },
  },
  { field: 'position', headerName: '직급', width: 130 },
  { field: 'company_name', headerName: '회사', width: 130 },
  { field: 'phone_number', headerName: '휴대폰번호', width: 130 },
  { field: 'business_number', headerName: '사업자번호', width: 130 },
  { field: 'representative_name', headerName: '대표자명', width: 130 },
  { field: 'address', headerName: '주소', width: 130 },
  { field: 'company_phone_number', headerName: '회사 전화번호', width: 130 },
  { field: 'reg_date', headerName: '가입일', width: 130 },
  { field: 'smsAgree', headerName: '수신동의', width: 130 },
  { field: 'is_admin', headerName: '어드민 여부', width: 130 },
  { field: 'sales_member', headerName: '관리 회원', width: 130 },
];

const gyejeongColumns = [
  { field: 'category', headerName: '계정과목', width: 300 },
  {
    field: 'value',
    headerName: '값',
    width: 530,
    renderCell: params => {
      return formatToKoreanCurrency(params.row.value);
    },
  },
];

const taxColumns = [
  { field: 'user_id', headerName: '회원번호', width: 70 },
  { field: 'name', headerName: '이름', width: 130 },
  { field: 'email', headerName: '이메일', width: 130 },
  {
    field: 'password',
    headerName: '비밀번호',
    width: 130,
    renderCell: params => {
      return '*'.repeat(params.value.length);
    },
  },
  { field: 'position', headerName: '직급', width: 130 },
  { field: 'company_name', headerName: '회사', width: 130 },
  { field: 'phone_number', headerName: '휴대폰번호', width: 130 },
  { field: 'business_number', headerName: '사업자번호', width: 130 },
  { field: 'representative_name', headerName: '대표자명', width: 130 },
  { field: 'address', headerName: '주소', width: 130 },
  { field: 'company_phone_number', headerName: '회사 전화번호', width: 130 },
  { field: 'reg_date', headerName: '가입일', width: 130 },
  { field: 'smsAgree', headerName: '수신동의', width: 130 },
  { field: 'is_admin', headerName: '어드민 여부', width: 130 },
  { field: 'assignment', headerName: '관리 회원', width: 130 },
];

const checkColumns = [
  { field: 'user_id', headerName: '회원번호', width: 70 },
  { field: 'name', headerName: '이름', width: 130 },
  { field: 'email', headerName: '이메일', width: 130 },
  { field: 'company_name', headerName: '회사', width: 130 },
  { field: 'phone_number', headerName: '휴대폰번호', width: 130 },
  { field: 'business_number', headerName: '사업자번호', width: 130 },
];

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

function ContentManagement() {
  const [data, setData] = useState([
    {
      user_id: 0,
      name: '',
      email: '',
      password: '',
      position: '',
      company_name: '',
      phoneNumber: '',
      businessNumber: '',
      representative: '',
      address: '',
      companyPhoneNumber: '',
      regdate: '',
      smsAgree: '',
      is_admin: false,
      access: [],
      assignment: [],
    },
  ]);

  const [id, setId] = useState(0);
  const [status, setStatus] = useState('');
  const [company, setCompany] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [checked, setChecked] = useState(false);
  const [password, setPassword] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [access, setAccess] = useState([]);
  const [assignment, setAssignment] = useState('');
  const [selectedAccess, setSelectedAccess] = useState('');
  const [position, setPosition] = useState('');
  const [compnay_phone_number, setCompany_phone_number] = useState('');
  const [isTopAdmin, setIsTopAdmin] = useState('');
  const [checkedData, setCheckedData] = useState([]);
  const [adminUserData, setAdminUSerData] = useState([]);
  const [salesAdminData, setSalesAdminData] = useState([]);
  const [setMyAssignment] = useState([]);
  const [setGyejeongRow] = useState([]);
  const [originalRow, setOriginalRow] = useState([]);
  const [selectionModel, setSelectionModel] = useState([]);
  const [setUserNotification] = useState([]);
  const [userIdList] = useState([]);
  const [token, setToken] = useState([]);

  function removeDuplicatesByKey(array, key) {
    const seen = new Set();
    return array.filter(item => {
      return seen.has(item[key]) ? false : seen.add(item[key]);
    });
  }

  useEffect(() => {
    const localAccess = localStorage.getItem('access');
    const localMember = localStorage.getItem('assignment_member');
    const array = localAccess.split(',').map(item => item.trim());
    const member = localMember.split(',').map(item => item.trim());

    if (array.includes('최고 관리자')) {
      setIsTopAdmin(true);
    }

    setMyAssignment(member);
    getUserData(member);
  }, []);

  async function getManageNotification(paramsEmail) {
    try {
      const option = {
        url: 'https://cpi.contax.ai/get_mng_notification',
        method: 'GET',
        params: {
          email: paramsEmail,
        },
        header: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      };

      const response = await axios(option);

      if (response.status === 200) {
        const array = [];
        response.data.forEach((item, index) => {
          if (item.target.includes('jaemu')) {
            array.push(item.email);
          }
        });

        getToken(array, value => {
          setToken(value);
        });
        setUserNotification(array);
      }
    } catch (e) {}
  }

  async function insertNotification(text) {
    const body = {
      notification_type: 'jaemu',
      title: text + '가 업로드 되었습니다.',
      content: '클릭하여 확인해보세요',
      target: userIdList,
    };

    if (status === 'create') {
      const option = {
        url: 'https://cpi.contax.ai/insert_notification',
        method: 'POST',
        data: body,
        header: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      };

      axios(option).then(response => {
        if (response.status === 200) {
          return true;
        } else {
        }
      });
    }
  }

  async function sendNotification(paramsToken, text) {
    const body = {
      title: text + '가 업로드 되었습니다.',
      body: '클릭하여 확인해보세요',
      token: paramsToken,
      data: {
        route: 'jaemu',
      },
    };

    const option = {
      url: 'https://cpi.contax.ai/send_notification',
      method: 'POST',
      data: body,
      header: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    };

    axios(option).then(response => {
      if (response.status === 200) {
        return true;
      } else {
      }
    });
  }

  async function updateUserData() {
    if (status !== 'change') {
      setStatus('change');
    } else {
      let optionData = {
        user_id: id,
        name: name,
        email: email,
        company: company,
        password: password,
        phoneNumber: phoneNumber,
      };

      let conbiOption = {
        url: 'https://cpi.contax.ai/mng_page_user',
        method: 'PUT',
        data: optionData,
        header: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      };

      await axios(conbiOption).then(response => {
        if (response.status === 200) {
          const obj = [...data];
          obj.forEach((item, index) => {
            if (item.user_id === id) {
              obj[index].name = name;
              obj[index].email = email;
              obj[index].company_name = company;
              obj[index].password = password;
              obj[index].phone_number = phoneNumber;
            }
          });

          setData(obj);
          alert('회원이 수정되었습니다.');
        }
      });
    }
  }

  async function deleteUserData() {
    if (!window.confirm('정말 삭제하시겠습니까?')) {
      return;
    } else {
      const option = {
        url: 'https://cpi.contax.ai/members/delete_member',
        params: {
          email: email,
        },
        method: 'DELETE',
        header: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      };

      axios(option).then(response => {
        if (response.status === 200) {
          const array = [...data];

          array.forEach((item, index) => {
            if (item.id === id) {
              array.splice(index, 1);
            }
          });

          setData(array);
          alert('회원이 삭제되었습니다.');
        }
      });
    }
  }

  async function getUserData() {
    let data = [];
    let userData = [];
    let accessData = [];
    let salesAdminData = [];

    const conbiOption = {
      url: 'https://cpi.contax.ai/members/all_members',
      method: 'GET',
      header: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    };

    const accessOption = {
      url: 'https://cpi.contax.ai/access',
      method: 'GET',
      header: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    };

    const subscriptionOption = {
      url: 'https://cpi.contax.ai/subscriptions',
      method: 'GET',
      header: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    };

    await axios(conbiOption).then(response => {
      if (response.status === 200) {
        data = response.data;
      }
    });

    await axios(accessOption).then(response => {
      if (response.status === 200) {
        accessData = response.data;
      }
    });

    data.forEach((item, index) => {
      let isAdmin = false;
      accessData.forEach(jtem => {
        if (item.user_id === jtem.user_id) {
          if (jtem.manager_division === 'content') {
            const newObj = {
              ...item,
              is_admin: 'O',
              access: jtem.access,
            };
            salesAdminData.push(newObj);
          }

          isAdmin = true;
        }
      });

      if (isAdmin === false) {
        const object = {
          ...item,
          subscription_type: '미가입',
          subscription_end: '-',
        };
        userData.push(object);
      }
    });

    setData(userData);
    setSalesAdminData(salesAdminData);

    const anotherCheckedData = [...data];

    setCheckedData(anotherCheckedData);
  }

  async function insertMember(e) {
    if (status !== 'create') {
      setStatus('create');
    } else {
      const date = new Date();

      const optionData = {
        member: {
          email: email,
          password: password,
          name: name,
          position: position,
          phone_number: phoneNumber,
          business_number: phoneNumber,
          company_name: company,
          representative_name: 'Null',
          address: 'Null',
          company_phone_number: compnay_phone_number,
          business_type: 'Null',
          reg_date: `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`,
          sms_kakao_agree: 0,
        },

        licenses: [],
      };

      let option = {
        url: 'https://cpi.contax.ai/members/add_member',
        data: optionData,
        method: 'POST',
        header: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
        },
      };

      await axios(option).then(response => {
        if (response.status === 200) {
          if (response.data.error === 'The company name already exists') {
            alert('이미 존재하는 회사명입니다.');
            return;
          }

          if (response.data.error === 'The business number already exists') {
            alert('이미 존재하는 전화번호입니다.');
            return;
          }

          if (response.data.error === 'The email aleready exists') {
            alert('이미 존재하는 이메일입니다.');
            return;
          }

          const array = [...data];
          array.push({
            user_id: response.data.user_id,
            name: name,
            email: email,
            password: password,
            position: position,
            phone_number: phoneNumber,
            business_number: phoneNumber,
            company_name: company,
            representative_name: 'Null',
            address: 'Null',
            company_phone_number: compnay_phone_number,
            business_type: 'Null',
            reg_date: `${date.getFullYear()}-${date.getMonth()}-${date.getDate()}`,
            sms_kakao_agree: 0,
          });

          createAdmin(response.data.user_id);
          getUserData();
        }
      });
    }
  }

  async function createAdmin(id) {
    const option = {
      url: 'https://cpi.contax.ai/insert_admin',
      params: {
        id: id,
        division: 'content',
      },
      method: 'POST',
      header: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
      },
    };

    await axios(option).then(response => {
      if (response.status === 200) {
        alert('회원이 추가되었습니다.');
        setStatus('');
        window.location.reload();
      }
    });
  }

  async function uploadSonikFile(e) {
    const formData = new FormData();
    formData.append('file', e.target.files[0]);

    const option = {
      url: 'https://cpi.contax.ai/sonik_excel_upload',
      params: {
        company_name: company,
      },
      data: formData,
      method: 'POST',
      header: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
      },
    };

    await axios(option)
      .then(response => {
        if (response.status === 200) {
          alert('파일이 업로드되었습니다.');
          getGyejeongGwamok(company);
          insertNotification('손익계산서');
          token.forEach(item => {
            sendNotification(item, '손익계산서');
          });
        }
      })
      .catch(e => {
        if (e.code === 'ERR_NETWORK') {
          alert('파일 형식이 잘못되었습니다.');
        }
      });
  }

  async function getGyejeongGwamok(company) {
    const array = [];
    const option = {
      url: 'https://cpi.contax.ai/jaemu_data/get_current_jaemu',
      params: {
        company_name: company,
      },
      method: 'GET',
      header: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
      },
    };

    await axios(option).then(response => {
      if (response.status === 200) {
        response.data.forEach((item, index) => {
          array.push({ ...item, id: index });
        });
      }
    });

    const sonikOption = {
      url: 'https://cpi.contax.ai/sonik_data/get_current_sonik',
      params: {
        company_name: company,
      },
      method: 'GET',
      header: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
      },
    };

    await axios(sonikOption).then(response => {
      if (response.status === 200) {
        response.data.forEach((item, index) => {
          array.push({ ...item, id: index });
        });
      }
    });

    const siljiloption = {
      url: 'https://cpi.contax.ai/management_score/calculate_real_capital',
      params: {
        company_name: company,
      },
      method: 'GET',
      header: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
      },
    };

    await axios(siljiloption).then(response => {
      if (response.status === 200) {
        const item = response.data;
        array.push({
          category: '실질자본금',
          value: item.real_capital,
          id: 0,
        });
        array.push({
          category: '자산총계',
          value: item.total_assets,
          id: 1,
        });
        array.push({
          category: '부채총계',
          value: item.total_dept,
          id: 2,
        });
        array.push({
          category: '자본총계',
          value: item.capital_total,
          id: 3,
        });
        array.push({
          category: '인정자산',
          value: item.recognized_assets,
          id: 4,
        });
        array.push({
          category: '불인정자산',
          value: item.unrecognized_assets_sum,
          id: 5,
        });
      }
    });
    setOriginalRow(array);
  }

  const handleSelection = newSelection => {
    // newSelection은 선택된 행의 id 배열입니다.

    const selectedData = data.filter(row => newSelection.includes(row.user_id));

    setId(selectedData[0].user_id);
    setCompany(selectedData[0].company_name);
    setEmail(selectedData[0].email);
    setChecked(selectedData[0].is_admin === 'O' ? true : false);
    setName(selectedData[0].name);
    setPhoneNumber(selectedData[0].phone_number);
    setAssignment(selectedData[0].assignment);
    setPassword(selectedData[0].password);
    getGyejeongGwamok(selectedData[0].company_name);

    getManageNotification(selectedData[0].email);
  };

  function getGyejeongList(status) {
    let array = [];
    if (status === '실질자본금') {
      array = addGyejeongGwamok([
        '실질자본금',
        '자산총계',
        '부채총계',
        '자본총계',
        '인정자산',
        '불인정자산',
      ]);
    } else if (status === '유동비율') {
      array = addGyejeongGwamok([
        '유동자산',
        '당좌자산',
        '현금',
        '보통예금',
        '기타제예금',
        '공사미수금',
        '가지급금',
        '단기대여금',
        '재고자산',
        '유동부채',
        '외상매입금',
        '미지급금',
        '예수금',
        '가수금',
        '미지급세금',
        '공사선수금',
      ]);
    } else if (status === '부채비율') {
      array = addGyejeongGwamok([
        '부채총계',
        '유동부채',
        '외상매입금',
        '미지급금',
        '예수금',
        '가수금',
        '비유동부채',
        '자본총계',
        '자본금',
        '자본잉여금',
        '자본조정',
        '기타포괄손익누계액',
        '이익잉여금',
        '미처분이익잉여금',
      ]);
    } else if (status === '차입금의존도') {
      array = addGyejeongGwamok(['차입금', '자산총계']);
    } else if (status === '영업이익대비 이자보상배율') {
      array = addGyejeongGwamok(['영업이익', '영업외비용']);
    } else if (status === '매출액영업이익율') {
      array = addGyejeongGwamok(['영업이익', '매출액']);
    } else if (status === '매출액순이익율') {
      array = addGyejeongGwamok(['당기순이익', '매출액']);
    } else if (status === '총자산순이익율') {
      array = addGyejeongGwamok(['당기순이익', '자산총계']);
    } else if (status === '총자산대비영업현금흐름비율') {
      array = addGyejeongGwamok(['영업이익', '자산총계']);
    } else if (status === '최근년도 자산회전율') {
      array = addGyejeongGwamok(['매출액', '자산총계']);
    } else if (status === '건설기술개발투자비율') {
      array = addGyejeongGwamok(['건설기술투자비', '건설부분총매출']);
    }

    setGyejeongRow(removeDuplicatesByKey(array, 'category'));
  }

  function addGyejeongGwamok(gyejeongList) {
    const array = [];
    let index = 0;

    // Iterate through each item in the gyejeongList
    gyejeongList.forEach(jtem => {
      let found = false;

      // Check if there is a matching category in gyejeongRow
      originalRow.forEach(item => {
        if (jtem === item.category) {
          array.push(item);
          found = true;
        }
      });

      // If no matching category was found, push the default object
      if (!found) {
        array.push({
          category: jtem,
          value: 0,
          id: gyejeongList.length + index,
        });
        index++;
      }
    });

    console.log(array);

    return array;
  }

  const contentsHandleSelection = newSelection => {
    // newSelection은 선택된 행의 id 배열입니다.
    const selectedData = salesAdminData.filter(row =>
      newSelection.includes(row.user_id),
    );

    setId(selectedData[0].user_id);
    setCompany(selectedData[0].company_name);
    setEmail(selectedData[0].email);
    setChecked(selectedData[0].is_admin === 'O' ? true : false);
    setName(selectedData[0].name);
    setPhoneNumber(selectedData[0].phone_number);
    setAccess(selectedData[0].access);
    setSelectionModel(selectedData[0].assignment);
    setPassword(selectedData[0].password);
  };

  const taxHandleSelection = newSelection => {
    // newSelection은 선택된 행의 id 배열입니다.
    const selectedData = adminUserData.filter(row =>
      newSelection.includes(row.user_id),
    );

    setId(selectedData[0].user_id);
    setCompany(selectedData[0].company_name);
    setEmail(selectedData[0].email);
    setChecked(selectedData[0].is_admin === 'O' ? true : false);
    setName(selectedData[0].name);
    setPhoneNumber(selectedData[0].phone_number);
    setAccess(selectedData[0].access);
    setSelectionModel(selectedData[0].assignment);
    setPassword(selectedData[0].password);
    setAdmin('세무 관리');
  };

  const [admin, setAdmin] = useState('');

  const handleCheck = newSelection => {
    setSelectionModel(newSelection);
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={isTopAdmin ? 12 : 9}>
          {isTopAdmin && (
            <TitleCard title={'컨텐츠 관리자 현황'}>
              <DataGrid
                rows={salesAdminData}
                columns={contentsColumns}
                getRowId={row => row.user_id}
                onRowSelectionModelChange={newSelection => {
                  contentsHandleSelection(newSelection);
                }}
                initialState={{
                  pagination: {
                    paginationModel: {
                      page: 0,
                      pageSize: 5,
                    },
                  },
                }}
                pageSizeOptions={[10]}
              />
            </TitleCard>
          )}

          {(status === 'create' || status === 'change') && (
            <Grid container direction="row" gap={2}>
              <Grid item xs={5.8}>
                <TitleCard title="회원 관리">
                  <Stack>
                    <TextField
                      sx={{ marginBottom: '20px' }}
                      id="standard-basic"
                      label="회원 이름"
                      variant="standard"
                      value={name}
                      onChange={e => {
                        setName(e.target.value);
                      }}
                    />
                    <TextField
                      sx={{ marginBottom: '20px' }}
                      id="standard-basic"
                      label="이메일"
                      variant="standard"
                      value={email}
                      onChange={e => {
                        setEmail(e.target.value);
                      }}
                    />
                    <TextField
                      sx={{ marginBottom: '20px' }}
                      id="standard-basic"
                      type="password"
                      label="비밀번호"
                      variant="standard"
                      value={password}
                      onChange={e => {
                        setPassword(e.target.value);
                      }}
                    />
                    <TextField
                      sx={{ marginBottom: '20px' }}
                      id="standard-basic"
                      label="회사"
                      variant="standard"
                      value={company}
                      onChange={e => {
                        setCompany(e.target.value);
                      }}
                    />
                    <TextField
                      sx={{ marginBottom: '20px' }}
                      id="standard-basic"
                      label="휴대폰번호"
                      variant="standard"
                      value={phoneNumber}
                      onChange={e => {
                        setPhoneNumber(e.target.value);
                      }}
                    />
                    {status === 'create' && (
                      <TextField
                        sx={{ marginBottom: '20px' }}
                        id="standard-basic"
                        label="직위"
                        variant="standard"
                        value={position}
                        onChange={e => {
                          setPosition(e.target.value);
                        }}
                      />
                    )}
                    {status === 'create' && (
                      <TextField
                        sx={{ marginBottom: '20px' }}
                        id="standard-basic"
                        label="회사 전화번호"
                        variant="standard"
                        value={compnay_phone_number}
                        onChange={e => {
                          setCompany_phone_number(e.target.value);
                        }}
                      />
                    )}
                  </Stack>
                </TitleCard>
              </Grid>

              {admin === '세무 관리' && (
                <Grid item xs={5.8}>
                  <TitleCard title={'유저 지정'}>
                    <DataGrid
                      rows={checkedData}
                      columns={checkColumns}
                      getRowId={row => row.user_id}
                      onRowSelectionModelChange={newSelection => {
                        handleCheck(newSelection);
                      }}
                      initialState={{
                        pagination: {
                          paginationModel: {
                            page: 0,
                            pageSize: 5,
                          },
                        },
                      }}
                      checkboxSelection
                      rowSelectionModel={selectionModel}
                      pageSizeOptions={[5, 10]}
                    />
                  </TitleCard>
                </Grid>
              )}
            </Grid>
          )}

          {isTopAdmin && (
            <Grid item mt={3}>
              <Grid
                container
                justifyContent="flex-end"
                direction="row"
                spacing={2}
              >
                {status !== 'change' && (
                  <Grid item>
                    <Button
                      disabled={status === 'change'}
                      variant="contained"
                      onClick={insertMember}
                    >
                      회원 추가
                    </Button>
                  </Grid>
                )}
                {status !== 'create' && (
                  <Grid item>
                    <Button
                      disabled={id === 0}
                      variant="contained"
                      onClick={updateUserData}
                    >
                      회원 수정
                    </Button>
                  </Grid>
                )}
                {(status === 'change' || status === 'create') && (
                  <Grid item>
                    <Button
                      variant="outlined"
                      onClick={() => {
                        setStatus('');
                      }}
                    >
                      취소
                    </Button>
                  </Grid>
                )}
                {status !== 'create' && (
                  <Grid item>
                    <Button
                      disabled={id === 0}
                      sx={{ backgroundColor: 'red' }}
                      variant="contained"
                      onClick={deleteUserData}
                    >
                      회원 삭제
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </>
  );
}

export default ContentManagement;
