import React, { useState } from 'react';
import styled from 'styled-components';
import { ManagerButton, PreviewButton } from '../Button';
import { sendReportEmail } from '../../utils/common_API';
import { useDispatch, useSelector } from 'react-redux';
import { StoreState } from '../../app/store';
import { SelectedMember } from '../../page/email/component/SelectedMember';
import { updateContent } from '../../app/slice/emailSlice';

import ReportEmailEditor from './ReportEmailEditor';

const EmailFormBottom = styled.div`
  display: flex;
  gap: 20px;
`;

const Input = styled.input`
  box-sizing: border-box;
  padding: 10px 10px;
  border: 1px solid #ddd;
  border-radius: 5px;

  width: 92%;
`;

const EditorBox = styled.div`
  margin-bottom: 20px;
  width: 100%;
`;

const InputTitle = styled.span`
  display: inline-block;
  font-size: 14px;
  width: 8%;
`;

async function onClick(
  sender: string,
  title: string,
  content: string,
  receiver: { email: string; name: string },
) {
  const htmlContent: string = `
       <html>
            <head></head>
            <body >
            <div>
            <div
            style="
                padding: 20px; 
                margin-top: 20px;
                width: 100%;
                height: 100px;
                background-image: url('https://gys0007.cafe24.com/asset/contax_logo_1.png');
                background-size: 25%;
                background-position-x: left;
                background-position-y: center;
                background-repeat: no-repeat;
                background-color: #fff;
                border-bottom: 3px solid rgba(50, 116, 230, 0.7);
            "

            
        />
        <div style="padding: 200px 100px; background-color: #f5f4f4;">
            <div style="background-color: #fff; padding:  150px 50px;">
            ${content}
            </div>
            

            <div style="margin-top: 50px; background-color: #fff; border-top: 2px solid #ddd; padding: 50px 0;">
                <div style="text-align: center;">
                    <span style="font-weight: bold; font-size: 24px;">
                        지금 바로 앱을 무료로 다운받아보세요
                    </span>

                    <div
                        style="
                            display: flex;
                            justify-content: center;
                        "
                    >
                        <a href="https://play.google.com/store/apps/details?id=com.contaxAI">
                            <img
                                width="250px"
                                src="https://gys0007.cafe24.com/asset/google_play_download_button.png"
                                alt="다운로드 버튼"
                            />
                        </a>

                    </div>
                </div>
            </div>

            <div
                style="
                    margin-top: 50px;
                    background-color: #fff;
                    padding: 40px 20px;
                    color: #999;
                    font-size: 14px;
                "
            >
                <p>본 메일은 발신 전용입니다.</p>
                <p>
                    궁금하신 점이나 불편한 사항은 고객센터에 문의해 주시기
                    바랍니다.
                </p>
                <br />
                <p>
                    회사명 : (주)인베스트파트너스 ㅣ 대표 : 이준형
                    <br />
                    주소 : 인천시 연수구 인천타워대로323, A동 3104
                    <br />
                    대표전화 : 1544-1530
                    <br />
                    이메일 : conbi@contax.ai
                    <br />
                    개인정보보호책임자 : 이소연
                    <br />
                    사업자등록번호 : 657-81-02424
                </p>
            </div>
            </div>
            
            </body>
        </html>
            
        `
    .replaceAll('class="ql-size-28px"', 'style="font-size:28px;"')
    .replaceAll('class="ql-size-26px"', 'style="font-size:26px;"')
    .replaceAll('class="ql-size-24px"', 'style="font-size:24px;"')
    .replaceAll('class="ql-size-22px"', 'style="font-size:22px;"')
    .replaceAll('class="ql-size-20px"', 'style="font-size:20px;"')
    .replaceAll('class="ql-size-18px"', 'style="font-size:18px;"')
    .replaceAll('class="ql-size-16px"', 'style="font-size:16px;"')
    .replaceAll('class="ql-align-center"', 'style="text-align:center"');

  const response = await sendReportEmail(
    sender === '' ? 'conbi@contax.ai' : sender,
    receiver,
    title,
    htmlContent,
  );

  if (response === 'success') {
    alert('이메일 전송 성공');
  } else {
    alert('이메일 전송 실패');
  }
}

interface PropTypes {
  isSender: boolean;
}

export function ReportEmailForm(props: PropTypes) {
  const email = localStorage.getItem('email');

  const dispatch = useDispatch();
  const { selectedMember } = useSelector(
    (state: StoreState) => state.reportEmail,
  );
  const [mode, setMode] = useState('write');
  const [title, setTitle] = useState('');
  const [sender, setSender] = useState(email);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
      }}
    >
      <div
        style={{
          marginBottom: '20px',
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          height: '100%',
          justifyContent: 'space-between',
        }}
      >
        <InputTitle>제목</InputTitle>
        <Input value={title} onChange={e => setTitle(e.target.value)} />
      </div>

      {props.isSender && (
        <div
          style={{
            marginBottom: '20px',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            height: '100%',
            justifyContent: 'space-between',
          }}
        >
          <InputTitle>발신자</InputTitle>
          <Input
            value={sender ?? ''}
            onChange={e => setSender(e.target.value)}
          />
        </div>
      )}

      <div
        style={{
          marginBottom: '20px',
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          height: '100%',
          justifyContent: 'space-between',
        }}
      >
        <InputTitle>수신자</InputTitle>
        <div style={{ width: '92%' }}>
          <SelectedMember
            name={selectedMember.name}
            email={selectedMember.email}
            onDelete={() => {
              return;
            }}
          />
        </div>
      </div>

      <EditorBox>
        <ReportEmailEditor
          bringBoard={false}
          mode={mode}
          value=""
          setValue={(data: string) => {
            dispatch(updateContent(data));
          }}
        />
      </EditorBox>
      <EmailFormBottom>
        <PreviewButton
          onClick={() => {
            mode === 'write' ? setMode('preview') : setMode('write');
          }}
          width="100px"
          height="auto"
        >
          {mode === 'write' ? '미리보기' : '돌아가기'}
        </PreviewButton>

        <ManagerButton
          onClick={() => {
            onClick(sender ?? '', title, '', selectedMember);
          }}
          width="100px"
          height="auto"
        >
          전송
        </ManagerButton>
      </EmailFormBottom>
    </div>
  );
}
