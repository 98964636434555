import React from 'react';
import Subtitle from '../Typography/Subtitle';
import { ChevronLeft } from '@mui/icons-material';

interface TitleCardProps {
  title: string;
  children?: React.ReactNode | React.ReactNode[];
  topMargin?: number;
  TopSideButtons?: boolean;
  previous?: () => void;
}

function TitleCard(props: TitleCardProps) {
  return (
    <div className={'card w-full p-8 bg-base-100 '}>
      <div
        onClick={props.previous}
        style={{ display: 'flex', gap: '10px', cursor: 'pointer' }}
      >
        {props.previous && <ChevronLeft />}
        <Subtitle styleClass={props.TopSideButtons ? 'inline-block' : ''}>
          {props.title}
        </Subtitle>
      </div>

      <div className="divider mt-2"></div>

      {/** Card Body */}
      <div className="h-full w-full pb-6 bg-base-100">{props.children}</div>
    </div>
  );
}

export default TitleCard;
