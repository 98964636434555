import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import { Grid, MenuItem, Select, TextField } from '@mui/material';
import TitleCard from '../../components/Cards/TitleCard';
import { setPageTitle } from '../../app/slice/headerSlice';
import styled from 'styled-components';
import React from 'react';
import {
  ManagerSection,
  SalesSection,
  TableColumn,
  TableColumnSection,
  TableHistoryRowSection,
  TableMemoRowContactSection,
  TableMemoRowSection,
  TableMemoSection,
  TitleSection
} from './component/components';
import { Pagination } from '@mui/material';
import {
  getSecondManagerSalesMember,
  getThirdManagerSalesMember
} from '../../utils/sales_API';
import {
  saveSecondMemo,
  updateContactDataStatus
} from '../../utils/contact_sales_API';
import { insertSalesHistory } from '../../utils/common_API';

const St_Count = styled.div`
  text-align: right;
`;

const St_ArrowSection = styled.div`
  display: flex;
  margin-top: 20px;
  justify-content: center;
  gap: 20px;
`;

function SalesManager() {
  const [trans, setTrans] = useState([]);
  const dispatch = useDispatch();
  const [totalCount, setTotalCount] = useState(0);
  const [selectedSalesSecond, setSelectedSalesSecond] = useState('');
  const [checkData, setCheckData] = useState([]);
  const [salesManager, setSalesManager] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [mode] = useState('sales');
  const [history, setHistory] = useState([]);
  const [, setTargetId] = useState(0);
  const [page, setPage] = useState(1);
  const [firstMemo, setFirstMemo] = useState([]);
  const [secondMemo, setSecondMemo] = useState([]);
  const division = localStorage.getItem('manager_division');
  const user_id = localStorage.getItem('id');
  const [selectedSalesIndex, setSelectedSalesIndex] = useState();
  const [firstCheckList, setFirstCheckList] = useState([]);
  const [secondCheckList, setSecondCheckList] = useState([]);
  const [areaname, setAreaname] = useState([]);
  const [areadetailname, setAreadetailname] = useState([]);
  const [setOriginal] = useState([]);
  const [offset, setOffset] = useState(10);
  const [managerMemo, setManagerMemo] = useState('');
  const [selectedAreaname, setSelectedAreaname] = useState('전체');
  const [recallOn, setRecallOn] = useState(false);
  const [shareOn, setShareOn] = useState(false);
  const [selectedAreadetailname, setSelectedAreadetailname] = useState('전체');
  const [searchItem, setSearchItem] = useState('ncrgskname'); // 상호명 검색 조건
  const [searchValue, setSearchValue] = useState(''); // 상호명 검색 입력값
  const [filterManager, setFilterManager] = useState([]);
  const [selectedFilterManager, setSelectedFilterManager] = useState('');
  const [init, setInit] = useState(false);
  const [sotring, setSotring] = useState(false);
  const [order, setOrder] = useState({
    selected: 'second_saved_date',
    ncrareadetailname: 'ASC',
    second_saved_date: 'ASC',
    ncrgskname: 'ASC',
    writter_name: 'ASC',
    second_manager: 'ASC'
  });
  const [column, setColumn] = useState('second_saved_date');

  useEffect(() => {
    if (init === true) {
      if (
        selectedFilterManager === '전체' &&
        recallOn === false &&
        shareOn === false
      ) {
        selectedSearch(1);
      }

      if (
        selectedAreadetailname === '전체' &&
        selectedAreaname === '전체' &&
        recallOn === false &&
        shareOn === false
      ) {
        selectedSearch(1);
      } else if (
        (selectedAreadetailname === '전체' || selectedAreaname === '전체') &&
        recallOn === false &&
        shareOn === false
      ) {
        selectedSearch(1);
      } else if (
        selectedAreadetailname !== '' &&
        selectedAreaname !== '' &&
        recallOn === false &&
        shareOn === false
      ) {
        selectedSearch(1);
      }

      setSelectedSalesIndex(0);
    }
  }, [
    selectedAreaname,
    selectedAreadetailname,
    selectedFilterManager,
    offset,
    init
  ]);

  useEffect(() => {
    if (init === true) {
      if (recallOn) {
        setShareOn(false);
        division === 'sales' && searchRecall(1);
        division === 'sales_second' && searchRecallSecond(1);
      } else if (recallOn === false && shareOn === false) {
        selectedSearch(1);
      }
    }
  }, [recallOn, offset, init]);

  useEffect(() => {
    if (init === true) {
      if (shareOn) {
        setRecallOn(false);
        division === 'sales' && searchShared(1);
        division === 'sales_second' && searchShared(1);
      } else if (recallOn === false && shareOn === false) {
        selectedSearch(1);
      }
    }
  }, [shareOn, offset, init]);

  async function loadSaleManager() {
    let data = [];
    let userData = [];
    let accessData = [];
    let salesAdminData = [];

    const conbiOption = {
      url: 'https://cpi.contax.ai/members/all_members',
      method: 'GET',
      header: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    };

    const accessOption = {
      url: 'https://cpi.contax.ai/access',
      method: 'GET',
      header: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    };

    await axios(conbiOption).then((response) => {
      if (response.status === 200) {
        data = response.data;
      }
    });

    await axios(accessOption).then((response) => {
      if (response.status === 200) {
        accessData = response.data;
      }
    });

    data.forEach((item) => {
      let isAdmin = false;
      accessData.forEach((jtem) => {
        if (item.user_id === jtem.user_id) {
          if (
            jtem.manager_division === 'sales' ||
            jtem.manager_division === 'sales_second'
          ) {
            const newObj = {
              ...item,
              is_admin: 'O',
              access: [],
              assignment: [],
              manager_division: jtem.manager_division,
              sales_member: jtem.sales_member
            };
            salesAdminData.push(newObj);
          }

          if (jtem.manager_division === 'contact_sales') {
            const newObj = {
              ...item,
              is_admin: 'O',
              access: [],
              assignment: [],
              manager_division: jtem.manager_division,
              sales_member: jtem.second_manage_data
            };
            salesAdminData.push(newObj);
          }

          isAdmin = true;
        }
      });

      if (isAdmin === false) {
        const object = {
          ...item,
          subscription_type: '미가입',
          subscription_end: '-'
        };
        userData.push(object);
      }
    });

    setSalesManager(salesAdminData);
  }

  // const ExcelUploader = () => {
  //     const handleChange = (e) => {
  //         const file = e.target.files[0];

  //         if (!file) return;

  //         const reader = new FileReader();
  //         reader.onload = async (evt) => {
  //             const bstr = evt.target.result;
  //             const workbook = XLSX.read(bstr, { type: 'binary' });
  //             const wsname = workbook.SheetNames[0];
  //             const ws = workbook.Sheets[wsname];
  //             const data = XLSX.utils.sheet_to_json(ws, { header: 1 });

  //             const dataArray = data;
  //             const objectArray = [];
  //             const email = [];
  //             const phoneNumber = [];
  //             const ncrmasternum = [];

  //             if((dataArray[0][16] === '이메일' && dataArray[0][17] === '휴대전화번호') || (dataArray[0][17] === '이메일' && dataArray[0][16] === '휴대전화번호')){
  //                 dataArray.forEach((item, index) => {
  //                     if( item[16] !== undefined && item[17] !== undefined ){
  //                         objectArray.push({ncrmasternum: item[13], email: item[16], phoneNumber: item[17]})

  //                         email.push(item[16]);
  //                         phoneNumber.push(item[17]);
  //                         ncrmasternum.push(item[13]);
  //                     }
  //                 })
  //             }else{
  //                 alert('문서 형식이 맞지 않습니다. 다운로드 받은 영업 DB 엑셀 파일 마지막 열에 이메일과 휴대전화번호를 추가한 후 작성한 후 업로드 해주세요.')
  //                 return;
  //             }

  //             const newArray = [...trans];

  //             newArray.forEach((item, index) => {
  //                 objectArray.forEach((jtem, jndex) => {

  //                     if(item.ncrgsseq === jtem.ncrmasternum){
  //                         const newObject = {...item, email:jtem.email, phoneNumber:jtem.phoneNumber};
  //                         newArray[index] = newObject;
  //                     }
  //                 })
  //             })

  //             const option = {
  //                 url: 'https://cpi.contax.ai/sales/update',
  //                 method: 'PUT',
  //                 data:{
  //                     email: email,
  //                     phoneNumber: phoneNumber,
  //                     ncrmasternum: ncrgsseq
  //                 },
  //                 header:{
  //                     'Accept':'application/json',
  //                     'Content-Type':'application/json'
  //                 }
  //             }

  //             await axios(option).then(response => {
  //                 if(response.status === 200){
  //                     alert("파일이 업로드 완료되었습니다.")
  //                     setTrans(newArray);
  //                 }
  //             });

  //         };

  //         reader.readAsBinaryString(file);
  //     }

  //     return (
  //         <Button sx={{width: "135px"}} accept=".xls,.xlsx" onChange={handleChange} component="label" variant="contained" startIcon={<CloudUploadIcon />}>
  //             엑셀 업로드
  //             <VisuallyHiddenInput type="file" />
  //         </Button>
  //     );
  // };

  function handlePage(event, target) {
    document.querySelector('body').scrollTo(0, 0);

    if (recallOn === true) {
      searchRecall(target);
    } else if (shareOn === true) {
      searchShared(target);
    } else if (sotring === true) {
      searchSort(target, column, order[column]);
    } else if (
      selectedAreaname === '전체' &&
      selectedAreadetailname === '전체' &&
      recallOn !== true &&
      shareOn !== true
    ) {
      selectedSearch(target);
      if (division === 'contact_sales') {
        selectedSearchContact(target);
      }
      setPage(target);
    } else {
      selectedSearch(target);

      if (division === 'contact_sales') {
        selectedSearchContact(target);
      }
    }
  }

  async function loadTrashData() {
    const id = localStorage.getItem('id');

    const option = {
      url: 'https://cpi.contax.ai/sales/load_trash',
      method: 'GET',
      params: { email: id },
      header: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    };

    await axios(option).then((response) => {
      if (response.status === 200) {
        const array = [];

        response.data.data.forEach((item) => {
          array.push(item.ncrgsseq.replace('{', '').replace('}', ''));
        });

        setTrashData(array);
      }
    });
  }

  async function updateRecentEdit(id) {
    const option = {
      url: 'https://cpi.contax.ai/sales/update_recent_edit',
      method: 'PUT',
      params: {
        id: id
      },
      header: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    };

    await axios(option).then((response) => {
      if (response.status === 200) {
        /* empty */
      }
    });
  }

  async function loadMemo(id) {
    const option = {
      url: 'https://cpi.contax.ai/sales/load_sales_memo',
      method: 'GET',
      params: { id: id },
      header: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    };

    const contactOption = {
      url: 'https://cpi.contax.ai/sales/load_sales_second_memo',
      method: 'GET',
      params: { id: id },
      header: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    };

    if (division === 'sales' || division === 'sales_second') {
      await axios(option).then((response) => {
        if (response.status === 200) {
          response.data.forEach((item) => {
            if (division === 'sales' && item.writter === Number(user_id)) {
              setManagerMemo(item);
              updateRecentEdit(item.id);
              setFirstMemo(item);
              const array = convertObjectToArray(JSON.parse(item.items));
              setFirstCheckList(array);
              setSelectedSalesSecond({
                id: item.second_manager_id,
                name: item.second_manager
              });
            }

            if (
              division === 'sales_second' &&
              item.second_manager_id === Number(user_id)
            ) {
              setManagerMemo(item);
              updateRecentEdit(item.id);
              setFirstMemo(item);
              const array = convertObjectToArray(JSON.parse(item.items));
              setFirstCheckList(array);
            }
          });
        }
      });
    } else {
      await axios(contactOption).then((response) => {
        if (response.status === 200) {
          response.data.forEach((item) => {
            if (division === 'contact_sales') {
              console.log(item);
              updateRecentEdit(item.id);
              setFirstMemo(item);
            }
          });
        }
      });

      await axios(option).then((response) => {
        if (response.status === 200) {
          response.data.forEach((item) => {
            setManagerMemo(item);
            updateRecentEdit(item.id);
            setFirstMemo(item);
            const array = convertObjectToArray(JSON.parse(item.items));
            setFirstCheckList(array);
          });
        }
      });
    }
  }

  function convertObjectToArray(obj) {
    return Object.entries(obj).map(([key, value]) => {
      // key를 '_'를 기준으로 분리하여 name과 type을 추출
      const [name, type] = key.split('_');

      if (
        typeof value === 'object' &&
        value !== null &&
        !Array.isArray(value)
      ) {
        // 값이 객체이면 재귀적으로 처리
        return { name, type, value: convertObjectToArray(value) };
      } else {
        // 그렇지 않으면, 기본 변환 수행
        return { name, type, value };
      }
    });
  }

  async function loadSecondMemo(id) {
    const option = {
      url: 'https://cpi.contax.ai/sales/load_sales_second_memo',
      method: 'GET',
      params: { id: id },
      header: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    };

    await axios(option).then((response) => {
      if (response.status === 200) {
        setSecondMemo(response.data);
        const array = convertObjectToArray(JSON.parse(response.data[0].items));

        setSecondCheckList(array);
      }
    });
  }

  async function loadSalesHistory() {
    const id = localStorage.getItem('id');

    const option = {
      url: 'https://cpi.contax.ai/load_all_sales_history',
      method: 'GET',
      header: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    };

    await axios(option).then((response) => {
      if (response.status === 200) {
        const array = [];

        response.data.forEach((item) => {
          if (item.id === Number(id)) {
            array.push(item);
          }
        });

        setHistory(array);
      }
    });
  }

  function convertArrayToObject(arr) {
    const result = {};

    arr.forEach((item) => {
      // 항목의 값이 배열인 경우 재귀적으로 처리합니다.
      if (Array.isArray(item.value)) {
        result[item.name] = convertArrayToObject(item.value);
      } else {
        // 값이 배열이 아니며 type에 따라 키를 조정합니다.
        let keySuffix = '';
        if (item.type === 'check') keySuffix = '_check';
        if (item.type === 'time') keySuffix = '_time';
        if (item.type === 'input') keySuffix = '_input';

        result[item.name + keySuffix] = item.value;
      }
    });

    return result;
  }

  async function shareData(id, ncrgsseq) {
    const array = [ncrgsseq];

    if (id === null || id === '') {
      alert('2차 관리자를 선택해주세요');
      return false;
    }
    const conbiOption = {
      url: 'https://cpi.contax.ai/update_sales_member',
      method: 'PUT',
      data: {
        user_id: id,
        members_list: array
      },
      header: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    };

    await axios(conbiOption).then((response) => {
      if (response.status === 200) {
        /* empty */
      }
    });
  }

  async function updateDetail() {
    const aoption = {
      url: 'https://cpi.contax.ai/sales/update_etc_information',
      method: 'PUT',
      data: {
        id: managerMemo.id,
        email: trans[selectedSalesIndex]['email'],
        phone_number: trans[selectedSalesIndex]['phonenumber'],
        manager: trans[selectedSalesIndex]['manager'],
        ncrmasternum: trans[selectedSalesIndex]['ncrgsseq'],
        call_memo: trans[selectedSalesIndex]['check_list']['call_memo'],
        fax: trans[selectedSalesIndex]['fax'],
        manager_phonenumber: trans[selectedSalesIndex]['manager_phonenumber']
      },
      header: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    };

    await axios(aoption).then((response) => {
      if (response.status === 200) {
        /* empty */
      }
    });
  }

  async function updateSchedule(is_ok) {
    const name = localStorage.getItem('name');

    const aoption = {
      url: 'https://cpi.contax.ai/update_schedule_ok',
      method: 'PUT',
      params: {
        ncrgsseq: trans[selectedSalesIndex]['ncrgsseq'],
        is_ok: is_ok,
        name: name
      },
      header: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    };

    await axios(aoption).then((response) => {
      if (response.status === 200) {
        /* empty */
      }
    });
  }

  async function updateMemo(item) {
    const data = item;
    data.items = JSON.stringify(convertArrayToObject(firstCheckList));
    const name = localStorage.getItem('name');

    data.second_manager = '';
    data.second_manager_id = '';

    const manager_division = localStorage.getItem('manager_division');
    // YYYY M월 D일 오후 HH:MM 형식의 정규 표현식
    let timeOk = true;
    const regex =
      /^\d{2}\.((0[1-9])|(1[0-2]))\.((0[1-9])|([1-2][0-9])|(3[0-1]))\.([0-1][0-9]|2[0-3])$/;

    firstCheckList.forEach((data) => {
      if (
        data.type === 'time' &&
        data.value !== '' &&
        !regex.test(data.value)
      ) {
        timeOk = false;
      }
    });

    if (manager_division === 'sales_second' && !timeOk) {
      alert(
        '시간 체크 형식이 맞지 않습니다. 형식에 맞게 수정해주세요 \n 예) 24.01.01.17'
      );
      return false;
    } else if (
      JSON.parse(data.items)['시간 체크_time'] !== '' &&
      manager_division === 'sales' &&
      !timeOk
    ) {
      alert(
        '시간 체크 형식이 맞지 않습니다. 형식에 맞게 수정해주세요 \n 예) 24.01.01.17'
      );
      return false;
    }

    if (
      JSON.parse(data.items)['시간 체크_time'] === '' &&
      division === 'sales_second'
    ) {
      alert(
        '시간 체크 형식이 맞지 않습니다. 형식에 맞게 수정해주세요 \n 예) 24.01.01.17'
      );
      return false;
    }

    if (
      (selectedSalesSecond === '' || Number(selectedSalesSecond.id) === 0) &&
      JSON.parse(data.items)['시간 체크_time'] !== '' &&
      manager_division === 'sales'
    ) {
      alert('2차 관리자를 설정해주세요');
      return false;
    }

    if (
      ((selectedSalesSecond !== '' && Number(selectedSalesSecond.id) !== 0) ||
        JSON.parse(data.items)['시간 체크_time'] !== '') &&
      (trans[selectedSalesIndex]['check_list'].call_memo === '' ||
        trans[selectedSalesIndex]['check_list'].call_memo === null)
    ) {
      alert(
        '데이터 공유를 위해서는 메모 작성이 필요합니다.\n메모를 작성해주세요.'
      );
      return false;
    }

    const user_id = localStorage.getItem('id');

    if (manager_division === 'sales_second') {
      data.is_second = true;
      data.second_manager = '';
      data.second_manager_id = 0;
      data.first_share_ok = true;
    } else {
      data.is_second = false;
      data.second_manager = selectedSalesSecond.name;
      data.second_manager_id = selectedSalesSecond.id;
    }

    if (manager_division === 'sales') {
      shareData(Number(selectedSalesSecond.id), item.targetMasterNumber);

      if (selectedSalesSecond === '' || Number(selectedSalesSecond.id) === 0) {
        data.first_share_ok = false;
        updateSchedule(false);
      } else {
        data.first_share_ok = true;
        updateSchedule(true);
      }
    }

    const option = {
      url: 'https://cpi.contax.ai/sales/update_sales_memo',
      method: 'PUT',
      data: data,
      header: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    };

    const historyOption = {
      url: 'https://cpi.contax.ai/insert_sales_history',
      method: 'POST',
      params: {
        id: user_id,
        name: name,
        target: selectedSalesSecond.name,
        item: trans[selectedSalesIndex]['ncrgskname'],
        type: 'insert',
        targetId: selectedSalesSecond.id
      },
      header: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    };

    const secondHistoryOption = {
      url: 'https://cpi.contax.ai/insert_sales_history',
      method: 'POST',
      params: {
        id: user_id,
        name: name,
        target: '',
        item: trans[selectedSalesIndex]['ncrgskname'],
        type: 'save',
        targetId: 0
      },
      header: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    };

    await axios(option).then((response) => {
      if (response.status === 200) {
        const array = [...trans];

        if (
          selectedSalesSecond !== '' &&
          Number(selectedSalesSecond.id) !== 0 &&
          manager_division === 'sales'
        ) {
          alert('공유가 완료되었습니다');
          array[selectedSalesIndex]['first_share_ok'] = true;
          selectedSearch(page);
          setSelectedIndex(-1);
          setCheckData({ ncrgsseq: '' });
        } else if (
          JSON.parse(data.items)['시간 체크_time'] !== '' &&
          manager_division === 'sales_second'
        ) {
          alert('공유가 완료되었습니다');
          array[selectedSalesIndex]['second_saved'] = true;
          array[selectedSalesIndex]['check_list']['is_second_saved'] = true;
          selectedSearch(page);
          setSelectedIndex(-1);
          setCheckData({ ncrgsseq: '' });
        } else {
          alert('저장이 완료되었습니다');
          array[selectedSalesIndex]['first_share_ok'] = false;
          setSelectedIndex(-1);
          setCheckData({ ncrgsseq: '' });
        }

        setTrans(array);
      }
    });

    if (
      manager_division === 'sales' &&
      selectedSalesSecond.id !== null &&
      selectedSalesSecond.name !== null
    ) {
      await axios(historyOption);
    }

    if (
      manager_division === 'sales_second' &&
      JSON.parse(data.items)['시간 체크_time'] !== ''
    ) {
      await axios(secondHistoryOption);
    }
  }

  async function updateSecondMemo(item, id) {
    const saveResponse = await saveSecondMemo(item, secondCheckList);
    const statusResponse = await updateContactDataStatus(id, true);
    const historyResponse = await insertSalesHistory(id, 'save');

    console.log(saveResponse);
    console.log(statusResponse);
    console.log(historyResponse);

    if (
      saveResponse.status === 'true' &&
      statusResponse.status === 'true' &&
      historyResponse.status === 'true'
    ) {
      alert('저장이 완료되었습니다.');
    } else {
      alert('문제가 발생하였습니다. 관리자에게 문의해주세요.');
    }
  }

  const [trashData, setTrashData] = useState([]);

  async function moveToTrash() {
    alert('관리자에게 문의해주세요');
  }

  async function searchRecallSecond(page) {
    let data = [];

    const memberOption = {
      url: 'https://cpi.contax.ai/load_sales_member',
      method: 'GET',
      params: {
        user_id: user_id
      },
      header: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    };

    await axios(memberOption).then((response) => {
      if (response.status === 200) {
        data = response.data;
      }
    });

    const option = {
      url: 'https://cpi.contax.ai/sales/load_selected_manager_data_recall_second',
      method: 'POST',
      data: {
        selected_data: data,
        offset: offset * (page - 1),
        limit: offset,
        user_id: user_id
      },
      header: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    };

    await axios(option).then((response) => {
      if (response.status === 200) {
        const areanameArray = [];
        const areadetailnameArray = [];

        setTrans(response.data.data);
        setTotalCount(response.data.total_count);

        response.data.area_names.forEach((item) => {
          if (!areanameArray.includes(item) && item !== '-') {
            areanameArray.push(item);
          }
        });

        response.data.area_detail_names.forEach((item) => {
          if (!areadetailnameArray.includes(item) && item !== '-') {
            areadetailnameArray.push(item);
          }
        });
      }
    });
  }

  async function searchShared(page) {
    let data = [];

    const memberOption = {
      url: 'https://cpi.contax.ai/load_sales_member',
      method: 'GET',
      params: {
        user_id: user_id
      },
      header: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    };

    await axios(memberOption).then((response) => {
      if (response.status === 200) {
        data = response.data;
      }
    });

    const option = {
      url: 'https://cpi.contax.ai/sales/load_selected_manager_data_search_shared',
      method: 'POST',
      data: {
        selected_data: data,
        offset: offset * (page - 1),
        limit: offset,
        user_id: user_id
      },
      header: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    };

    let classifyData = {};
    let classifyArray = [];

    if (division === 'sales') {
      classifyData = {
        first_manager_id: Number(user_id),
        is_second: false
      };
    }

    const classifyOption = {
      url: 'https://cpi.contax.ai/sales/load_sales_classify',
      method: 'POST',
      data: classifyData,
      header: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    };

    await axios(classifyOption).then((response) => {
      if (response.status === 200) {
        classifyArray = response.data;
      }
    });

    await axios(option).then((response) => {
      if (response.status === 200) {
        const areanameArray = [];
        const areadetailnameArray = [];

        setTrans(response.data.data);
        setTotalCount(response.data.total_count);

        response.data.area_names.forEach((item) => {
          if (!areanameArray.includes(item) && item !== '-') {
            areanameArray.push(item);
          }
        });

        response.data.area_detail_names.forEach((item) => {
          if (!areadetailnameArray.includes(item) && item !== '-') {
            areadetailnameArray.push(item);
          }
        });

        classifyArray.forEach((item) => {
          let selectedJndex = -1;
          let value = '';

          response.data.data.forEach((jtem, jndex) => {
            if (
              item.ncrgsseq === jtem.ncrgsseq &&
              item.first_manager_id === jtem.check_list.writter
            ) {
              selectedJndex = jndex;
              value = item.classification;
            }
          });

          if (selectedJndex !== -1) {
            response.data.data[selectedJndex]['classification'] = value;
          }
        });

        setTrans(response.data.data);
        setTotalCount(response.data.total_count);
      }
    });
  }

  async function searchRecall(page) {
    let data = [];

    const memberOption = {
      url: 'https://cpi.contax.ai/load_sales_member',
      method: 'GET',
      params: {
        user_id: user_id
      },
      header: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    };

    await axios(memberOption).then((response) => {
      if (response.status === 200) {
        data = response.data;
      }
    });

    const option = {
      url: 'https://cpi.contax.ai/sales/load_selected_manager_data_recall',
      method: 'POST',
      data: {
        selected_data: data,
        offset: offset * (page - 1),
        limit: offset,
        user_id: user_id
      },
      header: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    };

    let classifyData = {};
    let classifyArray = [];

    if (division === 'sales') {
      classifyData = {
        first_manager_id: Number(user_id),
        is_second: false
      };
    }

    const classifyOption = {
      url: 'https://cpi.contax.ai/sales/load_sales_classify',
      method: 'POST',
      data: classifyData,
      header: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    };

    await axios(classifyOption).then((response) => {
      if (response.status === 200) {
        classifyArray = response.data;
      }
    });

    await axios(option).then((response) => {
      if (response.status === 200) {
        const areanameArray = [];
        const areadetailnameArray = [];

        setTotalCount(response.data.total_count);

        response.data.area_names.forEach((item) => {
          if (!areanameArray.includes(item) && item !== '-') {
            areanameArray.push(item);
          }
        });

        response.data.area_detail_names.forEach((item) => {
          if (!areadetailnameArray.includes(item) && item !== '-') {
            areadetailnameArray.push(item);
          }
        });

        classifyArray.forEach((item) => {
          let selectedJndex = -1;
          let value = '';

          response.data.data.forEach((jtem, jndex) => {
            if (
              item.ncrgsseq === jtem.ncrgsseq &&
              item.first_manager_id === jtem.check_list.writter
            ) {
              selectedJndex = jndex;
              value = item.classification;
            }
          });

          if (selectedJndex !== -1) {
            response.data.data[selectedJndex]['classification'] = value;
          }
        });

        setTrans(response.data.data);
      }
    });
  }

  async function removeSecondSaved(user_id, targetMasterNumber) {
    const memberOption = {
      url: 'https://cpi.contax.ai/sales/remove_second_saved',
      method: 'PUT',
      params: {
        user_id: user_id,
        ncrgsseq: targetMasterNumber
      },
      header: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    };

    await axios(memberOption).then((response) => {
      if (response.status === 200) {
        const array = [...trans];
        array[selectedSalesIndex].check_list['is_second_saved'] = false;
        alert('공유를 취소하였습니다.');
        setTrans(array);
        setSelectedIndex(-1);
      }
    });
  }

  async function removeSecondManager(
    user_id,
    second_manager_id,
    targetMasterNumber
  ) {
    const memberOption = {
      url: 'https://cpi.contax.ai/remove_target_master_number',
      method: 'POST',
      params: {
        user_id: user_id,
        second_manager_id: second_manager_id,
        target_master_number: targetMasterNumber
      },
      header: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    };

    await axios(memberOption).then((response) => {
      if (response.status === 200) {
        const array = [...trans];
        array[selectedSalesIndex].check_list['first_share_ok'] = false;
        array[selectedSalesIndex].check_list['second_manager_id'] = 0;
        array[selectedSalesIndex].check_list['second_manager'] = '';
        setTrans(array);
        setSelectedIndex(-1);
      }
    });
  }

  async function updateMissingNumber(ncrgsseq, is_true) {
    const option = {
      url: 'https://cpi.contax.ai/update_missing_number',
      method: 'PUT',
      params: {
        ncrgsseq: ncrgsseq,
        is_true: is_true
      },
      header: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    };

    await axios(option);
  }

  async function selectedSearchContact(page) {
    let data = [];

    const memberOption = {
      url: 'https://cpi.contax.ai/load_sales_member',
      method: 'GET',
      params: {
        user_id: user_id
      },
      header: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    };

    await axios(memberOption).then((response) => {
      if (response.status === 200) {
        data = response.data;
      }
    });

    let searchAreaname = '';

    if (selectedAreaname === '전체') {
      searchAreaname = '';
    } else {
      searchAreaname = selectedAreaname;
    }

    let searchAreadetailname = '';

    if (selectedAreadetailname === '전체') {
      searchAreadetailname = '';
    } else {
      searchAreadetailname = selectedAreadetailname;
    }

    const option = {
      url: 'https://cpi.contax.ai/sales/load_selected_manager_data_search',
      method: 'POST',
      data: {
        selected_data: data,
        areaname: searchAreaname,
        areadetailname: searchAreadetailname,
        offset: offset * (page - 1),
        limit: offset,
        user_id: user_id,
        search_input: searchValue,
        column: searchItem
      },
      header: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    };

    const secondOption = {
      url: 'https://cpi.contax.ai/sales/load_selected_manager_data_search_second_new_new',
      method: 'POST',
      data: {
        selected_data: data,
        areaname: searchAreaname,
        areadetailname: searchAreadetailname,
        offset: page - 1,
        limit: offset,
        user_id: user_id,
        search_input: searchValue,
        filter_manager: selectedFilterManager,
        column: searchItem
      },
      header: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    };

    const contactOption = {
      url: 'https://cpi.contax.ai/sales/load_selected_manager_data_search_contact',
      method: 'POST',
      data: {
        selected_data: data,
        areaname: searchAreaname,
        areadetailname: searchAreadetailname,
        offset: offset * (page - 1),
        limit: offset,
        user_id: user_id,
        search_input: searchValue,
        filter_manager: selectedFilterManager,
        column: searchItem
      },
      header: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    };

    if (division === 'sales') {
      await axios(option).then((response) => {
        if (response.status === 200) {
          const areanameArray = [];
          const areadetailnameArray = [];

          response.data.area_names.forEach((item) => {
            if (!areanameArray.includes(item) && item !== '-') {
              areanameArray.push(item);
            }
          });

          response.data.area_detail_names.forEach((item) => {
            if (!areadetailnameArray.includes(item) && item !== '-') {
              areadetailnameArray.push(item);
            }
          });

          setTrans(response.data.data);
          setTotalCount(response.data.total_count);
        }
      });
    }

    if (division === 'sales_second') {
      await axios(secondOption).then((response) => {
        if (response.status === 200) {
          const areanameArray = [];
          const areadetailnameArray = [];
          const managerArray = [];

          response.data.area_names.forEach((item) => {
            if (!areanameArray.includes(item) && item !== '-') {
              areanameArray.push(item);
            }
          });

          response.data.area_detail_names.forEach((item) => {
            if (!areadetailnameArray.includes(item) && item !== '-') {
              areadetailnameArray.push(item);
            }
          });

          response.data.manager_data_names.forEach((item) => {
            if (!managerArray.includes(item) && item !== '-') {
              managerArray.push(item);
            }
          });

          response.data.data.sort((a, b) => {
            let dateA = new Date(a.check_list.first_saved_date),
              dateB = new Date(b.check_list.first_saved_date);
            return dateB - dateA; // 내림차순 정렬
          });

          const array = [];

          response.data.data.forEach((item, index) => {
            if (
              index >= (page - 1) * offset &&
              index < (page - 1) * offset + offset
            ) {
              array.push(item);
            }
          });

          setTrans(array);
          setTotalCount(response.data.total_count);
        }
      });
    }

    if (division === 'contact_sales') {
      await axios(contactOption).then((response) => {
        if (response.status === 200) {
          // response.data.area_names.forEach((item) => {
          //     if(!areanameArray.includes(item) && item !== '-'){
          //         areanameArray.push(item)
          //     }
          // })

          // response.data.area_detail_names.forEach((item) => {
          //     if(!areadetailnameArray.includes(item) && item !== '-'){
          //         areadetailnameArray.push(item)
          //     }
          // })

          // response.data.manager_data_names.forEach((item) => {
          //     if(!managerArray.includes(item) && item !== '-'){
          //         managerArray.push(item)
          //     }
          // })

          setTrans(response.data.data);
          setTotalCount(response.data.total_count);
        }
      });
    }
  }

  async function searchSort(page, column, order) {
    setSotring(true);
    let data = [];

    const memberOption = {
      url: 'https://cpi.contax.ai/load_sales_member',
      method: 'GET',
      params: {
        user_id: user_id
      },
      header: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    };

    await axios(memberOption).then((response) => {
      if (response.status === 200) {
        data = response.data;
      }
    });

    let searchAreaname = '';

    if (selectedAreaname === '전체') {
      searchAreaname = '';
    } else {
      searchAreaname = selectedAreaname;
    }

    let searchAreadetailname = '';

    if (selectedAreadetailname === '전체') {
      searchAreadetailname = '';
    } else {
      searchAreadetailname = selectedAreadetailname;
    }

    const option = {
      url: 'https://cpi.contax.ai/sales/load_selected_manager_data_search_sort',
      method: 'POST',
      data: {
        selected_data: data,
        areaname: searchAreaname,
        areadetailname: searchAreadetailname,
        offset: offset * (page - 1),
        limit: offset,
        user_id: user_id,
        search_input: searchValue,
        column: searchItem,
        sort_column: column,
        sort: order
      },
      header: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    };

    let classifyData = {};

    if (division === 'sales') {
      classifyData = {
        first_manager_id: Number(user_id),
        is_second: false
      };
    }

    if (division === 'sales_second') {
      classifyData = {
        ncrgsseqList: data,
        is_second: true
      };
    }

    const classifyOption = {
      url: 'https://cpi.contax.ai/sales/load_sales_classify',
      method: 'POST',
      data: classifyData,
      header: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    };

    if (division === 'sales') {
      let classifyArray = [];

      await axios(classifyOption).then((response) => {
        if (response.status === 200) {
          classifyArray = response.data;
        }
      });

      await axios(option).then((response) => {
        if (response.status === 200) {
          const areanameArray = [];
          const areadetailnameArray = [];

          response.data.area_names.forEach((item) => {
            if (!areanameArray.includes(item) && item !== '-') {
              areanameArray.push(item);
            }
          });

          response.data.area_detail_names.forEach((item) => {
            if (!areadetailnameArray.includes(item) && item !== '-') {
              areadetailnameArray.push(item);
            }
          });

          setAreaname(areanameArray);
          setAreadetailname(areadetailnameArray);

          classifyArray.forEach((item) => {
            let selectedJndex = -1;
            let value = '';

            response.data.data.forEach((jtem, jndex) => {
              if (
                item.ncrgsseq === jtem.ncrgsseq &&
                item.first_manager_id === jtem.check_list.writter
              ) {
                selectedJndex = jndex;
                value = item.classification;
              }
            });

            if (selectedJndex !== -1) {
              response.data.data[selectedJndex]['classification'] = value;
            }
          });

          setTrans(response.data.data);
          setInit(true);
          setTotalCount(response.data.total_count);
        }
      });
    }
  }

  async function initialSelectedSearch(page) {
    let members = [];
    if (division === 'contact_sales') {
      members = await getThirdManagerSalesMember();
    }

    if (division === 'sales_second') {
      members = await getSecondManagerSalesMember();
    }

    let searchAreaname = '';

    if (selectedAreaname === '전체') {
      searchAreaname = '';
    } else {
      searchAreaname = selectedAreaname;
    }

    let searchAreadetailname = '';

    if (selectedAreadetailname === '전체') {
      searchAreadetailname = '';
    } else {
      searchAreadetailname = selectedAreadetailname;
    }

    const option = {
      url: 'https://cpi.contax.ai/sales/load_selected_manager_data_search',
      method: 'POST',
      data: {
        selected_data: members,
        areaname: searchAreaname,
        areadetailname: searchAreadetailname,
        offset: offset * (page - 1),
        limit: offset,
        user_id: user_id,
        search_input: searchValue,
        column: searchItem
      },
      header: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    };

    const secondOption = {
      url: 'https://cpi.contax.ai/sales/load_selected_manager_data_search_second_new_new',
      method: 'POST',
      data: {
        selected_data: members,
        areaname: searchAreaname,
        areadetailname: searchAreadetailname,
        offset: page - 1,
        limit: offset,
        user_id: user_id,
        search_input: searchValue,
        filter_manager: selectedFilterManager,
        column: searchItem
      },
      header: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    };

    const contactOption = {
      url: 'https://cpi.contax.ai/sales/load_selected_manager_data_search_contact',
      method: 'POST',
      data: {
        selected_data: members,
        areaname: searchAreaname,
        areadetailname: searchAreadetailname,
        offset: offset * (page - 1),
        limit: offset,
        user_id: user_id,
        search_input: searchValue,
        filter_manager: selectedFilterManager,
        column: searchItem,
        sort_column: order[order['selected']],
        sort: order['selected']
      },
      header: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    };

    let rejectionArray = [];

    const rejectionOption = {
      url: 'https://cpi.contax.ai/sales/load_sales_rejection',
      method: 'GET',
      header: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    };

    let classifyData = {};

    if (division === 'sales') {
      classifyData = {
        first_manager_id: Number(user_id),
        is_second: false
      };
    }

    if (division === 'sales_second') {
      classifyData = {
        ncrgsseqList: members,
        is_second: true
      };
    }

    const classifyOption = {
      url: 'https://cpi.contax.ai/sales/load_sales_classify',
      method: 'POST',
      data: classifyData,
      header: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    };

    await axios(rejectionOption).then((response) => {
      if (response.status === 200) {
        rejectionArray = response.data.data;
      }
    });

    if (division === 'sales') {
      let classifyArray = [];

      await axios(classifyOption).then((response) => {
        if (response.status === 200) {
          classifyArray = response.data;
        }
      });

      await axios(option).then((response) => {
        if (response.status === 200) {
          const areanameArray = [];
          const areadetailnameArray = [];

          response.data.area_names.forEach((item) => {
            if (!areanameArray.includes(item) && item !== '-') {
              areanameArray.push(item);
            }
          });

          response.data.area_detail_names.forEach((item) => {
            if (!areadetailnameArray.includes(item) && item !== '-') {
              areadetailnameArray.push(item);
            }
          });

          setAreaname(areanameArray);
          setAreadetailname(areadetailnameArray);

          classifyArray.forEach((item) => {
            let selectedJndex = -1;
            let value = '';

            response.data.data.forEach((jtem, jndex) => {
              if (
                item.ncrgsseq === jtem.ncrgsseq &&
                item.first_manager_id === jtem.check_list.writter
              ) {
                selectedJndex = jndex;
                value = item.classification;
              }
            });

            if (selectedJndex !== -1) {
              response.data.data[selectedJndex]['classification'] = value;
            }
          });

          setTrans(response.data.data);
          setInit(true);
          setTotalCount(response.data.total_count);
        }
      });
    }

    if (division === 'sales_second') {
      let classifyArray = [];

      await axios(classifyOption).then((response) => {
        if (response.status === 200) {
          classifyArray = response.data;
        }
      });

      await axios(secondOption).then((response) => {
        if (response.status === 200) {
          const areanameArray = [];
          const areadetailnameArray = [];
          const managerArray = [];

          response.data.area_names.forEach((item) => {
            if (!areanameArray.includes(item) && item !== '-') {
              areanameArray.push(item);
            }
          });

          response.data.area_detail_names.forEach((item) => {
            if (!areadetailnameArray.includes(item) && item !== '-') {
              areadetailnameArray.push(item);
            }
          });

          response.data.manager_data_names.forEach((item) => {
            if (!managerArray.includes(item) && item !== '-') {
              managerArray.push(item);
            }
          });

          response.data.data.sort((a, b) => {
            let dateA = new Date(a.check_list.first_saved_date),
              dateB = new Date(b.check_list.first_saved_date);
            return dateB - dateA; // 내림차순 정렬
          });

          const array = [];

          rejectionArray.forEach((item) => {
            let selectedIndex = 0;
            let is_rejected = false;
            response.data.data.forEach((jtem, jndex) => {
              if (
                item.ncrgsseq === jtem.ncrgsseq &&
                item.rejection_true === true
              ) {
                selectedIndex = jndex;
                is_rejected = true;
              }
            });

            if (is_rejected) {
              response.data.data[selectedIndex]['rejection'] = true;
              response.data.data[selectedIndex]['rejection_item'] = item;
            }
          });

          classifyArray.forEach((item) => {
            let selectedJndex = -1;
            let value = '';

            response.data.data.forEach((jtem, jndex) => {
              if (
                item.ncrgsseq === jtem.ncrgsseq &&
                item.first_manager_id === jtem.check_list.writter
              ) {
                selectedJndex = jndex;
                value = item.classification;
              }
            });

            if (selectedJndex !== -1) {
              response.data.data[selectedJndex]['classification'] = value;
            }
          });

          response.data.data.forEach((item, index) => {
            if (
              index >= (page - 1) * offset &&
              index < (page - 1) * offset + offset
            ) {
              array.push(item);
            }
          });

          setTrans(array);

          setAreaname(areanameArray);
          setAreadetailname(areadetailnameArray);
          setFilterManager(managerArray);
          setInit(true);
          setTotalCount(response.data.total_count);
        }
      });
    }

    if (division === 'contact_sales') {
      await axios(contactOption).then((response) => {
        if (response.status === 200) {
          let dataArray = [...response.data.data];

          const areanameArray = [];
          const areadetailnameArray = [];
          const managerArray = [];

          // response.data.area_names.forEach((item) => {
          //     if(!areanameArray.includes(item) && item !== '-'){
          //         areanameArray.push(item)
          //     }
          // })

          // response.data.area_detail_names.forEach((item) => {
          //     if(!areadetailnameArray.includes(item) && item !== '-'){
          //         areadetailnameArray.push(item)
          //     }
          // })

          // response.data.manager_data_names.forEach((item) => {
          //     if(!managerArray.includes(item) && item !== '-'){
          //         managerArray.push(item)
          //     }
          // })

          setAreaname(areanameArray);
          setAreadetailname(areadetailnameArray);
          setFilterManager(managerArray);

          dataArray.forEach((item) => {
            rejectionArray.forEach((jtem) => {
              item.rejection =
                jtem.ncrgsseq === item.unique_key &&
                jtem.rejection_true === true;
            });
          });

          console.log(dataArray);

          setTrans(dataArray);
          setTotalCount(response.data.total_count);
        }
      });
    }
  }

  async function selectedSearch(page) {
    const data = localStorage.getItem('sales_member');
    const array = data.split(',');

    let searchAreaname = '';

    if (selectedAreaname === '전체') {
      searchAreaname = '';
    } else {
      searchAreaname = selectedAreaname;
    }

    let searchAreadetailname = '';

    if (selectedAreadetailname === '전체') {
      searchAreadetailname = '';
    } else {
      searchAreadetailname = selectedAreadetailname;
    }

    const option = {
      url: 'https://cpi.contax.ai/sales/load_selected_manager_data_search',
      method: 'POST',
      data: {
        selected_data: array,
        areaname: searchAreaname,
        areadetailname: searchAreadetailname,
        offset: offset * (page - 1),
        limit: offset,
        user_id: user_id,
        search_input: searchValue,
        column: searchItem
      },
      header: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    };

    const secondOption = {
      url: 'https://cpi.contax.ai/sales/load_selected_manager_data_search_second_new_new',
      method: 'POST',
      data: {
        selected_data: array,
        areaname: searchAreaname,
        areadetailname: searchAreadetailname,
        filter_manager:
          selectedFilterManager === '전체' ? '' : selectedFilterManager,
        offset: page - 1,
        limit: offset,
        user_id: user_id,
        search_input: searchValue,
        column: searchItem
      },
      header: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    };

    let rejectionArray = [];
    let classifyArray = [];

    const rejectionOption = {
      url: 'https://cpi.contax.ai/sales/load_sales_rejection',
      method: 'GET',
      header: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    };

    let classifyData = {};

    if (division === 'sales') {
      classifyData = {
        first_manager_id: Number(user_id),
        is_second: false
      };
    }

    if (division === 'sales_second') {
      classifyData = {
        ncrgsseqList: array,
        is_second: true
      };
    }

    const classifyOption = {
      url: 'https://cpi.contax.ai/sales/load_sales_classify',
      method: 'POST',
      data: classifyData,
      header: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    };

    await axios(rejectionOption).then((response) => {
      if (response.status === 200) {
        rejectionArray = response.data.data;
      }
    });

    if (division === 'sales') {
      await axios(classifyOption).then((response) => {
        if (response.status === 200) {
          classifyArray = response.data;
        }
      });

      await axios(option).then((response) => {
        if (response.status === 200) {
          setTotalCount(response.data.total_count);

          const areanameArray = [];
          const areadetailnameArray = [];

          response.data.area_names.forEach((item) => {
            if (!areanameArray.includes(item) && item !== '-') {
              areanameArray.push(item);
            }
          });

          response.data.area_detail_names.forEach((item) => {
            if (!areadetailnameArray.includes(item) && item !== '-') {
              areadetailnameArray.push(item);
            }
          });

          classifyArray.forEach((item) => {
            let selectedJndex = -1;
            let value = '';

            response.data.data.forEach((jtem, jndex) => {
              if (
                item.ncrgsseq === jtem.ncrgsseq &&
                item.first_manager_id === jtem.check_list.writter
              ) {
                selectedJndex = jndex;
                value = item.classification;
              }
            });

            if (selectedJndex !== -1) {
              response.data.data[selectedJndex]['classification'] = value;
            }
          });

          setTrans(response.data.data);
          setTotalCount(response.data.total_count);
        }
      });
    }

    if (division === 'sales_second') {
      await axios(classifyOption).then((response) => {
        if (response.status === 200) {
          classifyArray = response.data;
        }
      });

      await axios(secondOption).then((response) => {
        if (response.status === 200) {
          setTotalCount(response.data.total_count);

          const areanameArray = [];
          const areadetailnameArray = [];

          response.data.area_names.forEach((item) => {
            if (!areanameArray.includes(item) && item !== '-') {
              areanameArray.push(item);
            }
          });

          response.data.area_detail_names.forEach((item) => {
            if (!areadetailnameArray.includes(item) && item !== '-') {
              areadetailnameArray.push(item);
            }
          });

          response.data.data.sort((a, b) => {
            let dateA = new Date(a.check_list.first_saved_date),
              dateB = new Date(b.check_list.first_saved_date);
            return dateB - dateA; // 내림차순 정렬
          });

          const array = [];

          setOriginal(response.data.data);

          rejectionArray.forEach((item) => {
            let selectedIndex = 0;
            let is_rejected = false;
            response.data.data.forEach((jtem, jndex) => {
              if (
                item.ncrgsseq === jtem.ncrgsseq &&
                item.rejection_true === true
              ) {
                selectedIndex = jndex;
                is_rejected = true;
              }
            });

            if (is_rejected) {
              response.data.data[selectedIndex]['rejection'] = true;
              response.data.data[selectedIndex]['rejection_item'] = item;
            }
          });

          classifyArray.forEach((item) => {
            let selectedJndex = -1;
            let value = '';

            response.data.data.forEach((jtem, jndex) => {
              if (
                item.ncrgsseq === jtem.ncrgsseq &&
                item.first_manager_id === jtem.check_list.writter
              ) {
                selectedJndex = jndex;
                value = item.classification;
              }
            });

            if (selectedJndex !== -1) {
              response.data.data[selectedJndex]['classification'] = value;
            }
          });

          response.data.data.forEach((item, index) => {
            if (
              index >= (page - 1) * offset &&
              index < (page - 1) * offset + offset
            ) {
              array.push(item);
            }
          });

          console.log(array);

          setTrans(array);
          setTotalCount(response.data.total_count);
        }
      });
    }
  }

  async function selectedSearchInput(page, input) {
    let data = '';

    if (division === 'sales' || division === 'sales_second') {
      data = localStorage.getItem('sales_member');
    }

    if (division === 'contact_sales') {
      data = localStorage.getItem('second_manage_data');
    }

    const array = data.split(',');

    let searchAreaname = '';

    if (selectedAreaname === '전체') {
      searchAreaname = '';
    } else {
      searchAreaname = selectedAreaname;
    }

    let searchAreadetailname = '';

    if (selectedAreadetailname === '전체') {
      searchAreadetailname = '';
    } else {
      searchAreadetailname = selectedAreadetailname;
    }

    const option = {
      url: 'https://cpi.contax.ai/sales/load_selected_manager_data_search',
      method: 'POST',
      data: {
        selected_data: array,
        areaname: searchAreaname,
        areadetailname: searchAreadetailname,
        offset: offset * (page - 1),
        limit: offset,
        user_id: user_id,
        search_input: input,
        column: searchItem
      },
      header: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    };

    const secondOption = {
      url: 'https://cpi.contax.ai/sales/load_selected_manager_data_search_second_new_new',
      method: 'POST',
      data: {
        selected_data: array,
        areaname: searchAreaname,
        areadetailname: searchAreadetailname,
        filter_manager: selectedFilterManager,
        offset: page - 1,
        limit: offset,
        user_id: user_id,
        search_input: input,
        column: searchItem
      },
      header: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    };

    const contactOption = {
      url: 'https://cpi.contax.ai/sales/load_selected_manager_data_search_contact',
      method: 'POST',
      data: {
        selected_data: array,
        areaname: searchAreaname,
        areadetailname: searchAreadetailname,
        filter_manager: selectedFilterManager,
        offset: page - 1,
        limit: offset,
        user_id: user_id,
        search_input: input,
        column: searchItem
      },
      header: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    };

    let rejectionArray = [];

    const rejectionOption = {
      url: 'https://cpi.contax.ai/sales/load_sales_rejection',
      method: 'GET',
      header: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    };

    let classifyArray = [];

    let classifyData = {};

    if (division === 'sales') {
      classifyData = {
        first_manager_id: Number(user_id),
        is_second: false
      };
    }

    if (division === 'sales_second') {
      classifyData = {
        ncrgsseqList: array,
        is_second: true
      };
    }

    const classifyOption = {
      url: 'https://cpi.contax.ai/sales/load_sales_classify',
      method: 'POST',
      data: classifyData,
      header: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    };

    await axios(rejectionOption).then((response) => {
      if (response.status === 200) {
        rejectionArray = response.data.data;
      }
    });

    if (division === 'sales') {
      let classifyArray = [];

      await axios(classifyOption).then((response) => {
        if (response.status === 200) {
          classifyArray = response.data;
        }
      });

      await axios(option).then((response) => {
        if (response.status === 200) {
          classifyArray.forEach((item) => {
            let selectedJndex = -1;
            let value = '';

            response.data.data.forEach((jtem, jndex) => {
              if (
                item.ncrgsseq === jtem.ncrgsseq &&
                item.first_manager_id === jtem.check_list.writter
              ) {
                selectedJndex = jndex;
                value = item.classification;
              }
            });

            if (selectedJndex !== -1) {
              response.data.data[selectedJndex]['classification'] = value;
            }
          });

          setTrans(response.data.data);
          setTotalCount(response.data.total_count);
        }
      });
    }

    if (division === 'sales_second') {
      await axios(classifyOption).then((response) => {
        if (response.status === 200) {
          classifyArray = response.data;
        }
      });

      await axios(secondOption).then((response) => {
        if (response.status === 200) {
          response.data.data.sort((a, b) => {
            let dateA = new Date(a.check_list.first_saved_date),
              dateB = new Date(b.check_list.first_saved_date);
            return dateB - dateA; // 내림차순 정렬
          });

          const array = [];

          response.data.data.forEach((item, index) => {
            if (
              index >= (page - 1) * offset &&
              index < (page - 1) * offset + offset
            ) {
              array.push(item);
            }
          });

          rejectionArray.forEach((item) => {
            let selectedIndex = 0;
            let is_rejected = false;
            response.data.data.forEach((jtem, jndex) => {
              if (
                item.ncrgsseq === jtem.ncrgsseq &&
                item.rejection_true === true
              ) {
                selectedIndex = jndex;
                is_rejected = true;
              }
            });

            if (is_rejected) {
              response.data.data[selectedIndex]['rejection'] = true;
              response.data.data[selectedIndex]['rejection_item'] = item;
            }
          });

          classifyArray.forEach((item) => {
            let selectedJndex = -1;
            let value = '';

            response.data.data.forEach((jtem, jndex) => {
              if (
                item.ncrgsseq === jtem.ncrgsseq &&
                item.first_manager_id === jtem.check_list.writter
              ) {
                selectedJndex = jndex;
                value = item.classification;
              }
            });

            if (selectedJndex !== -1) {
              response.data.data[selectedJndex]['classification'] = value;
            }
          });

          setTrans(array);
          setTotalCount(response.data.total_count);
        }
      });
    }

    if (division === 'contact_sales') {
      await axios(contactOption).then((response) => {
        if (response.status === 200) {
          setTrans(response.data.data);
          setTotalCount(response.data.total_count);
        }
      });
    }
  }

  useEffect(() => {
    dispatch(setPageTitle({ title: '영업' }));
    loadSaleManager();
    loadTrashData();
    loadSalesHistory();
    initialSelectedSearch(1);
  }, []);

  return (
    <Grid container spacing={2} direction="column" justifyContent="center">
      <Grid item>
        <TitleCard title="영업">
          {mode === 'sales' && (
            <SalesSection>
              <div style={{ width: '68%' }}>
                <TitleSection
                  style={{
                    marginBottom: '0px',
                    display: 'flex',
                    flexDirection: 'column'
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between'
                    }}
                  >
                    <h1
                      style={{
                        fontWeight: 'bold',
                        fontSize: '20px'
                      }}
                    >
                      영업 데이터
                    </h1>

                    <div
                      style={{
                        display: 'flex',
                        gap: '20px'
                      }}
                    >
                      <Select
                        style={{ height: '30px' }}
                        defaultValue={10}
                        onChange={(e) => {
                          setOffset(e.target.value);
                        }}
                      >
                        <MenuItem value={10}>10개씩 보기</MenuItem>
                        <MenuItem value={20}>20개씩 보기</MenuItem>
                        <MenuItem value={30}>30개씩 보기</MenuItem>
                        <MenuItem value={50}>50개씩 보기</MenuItem>
                        <MenuItem value={100}>100개씩 보기</MenuItem>
                      </Select>
                      <St_Count>총: {totalCount} 개</St_Count>
                    </div>
                  </div>

                  <div
                    style={{
                      display: 'flex',
                      margin: '25px 0',
                      padding: '10px',
                      flexDirection: 'column',
                      gap: '10px',
                      backgroundColor: '#fff'
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center'
                      }}
                    >
                      <span
                        style={{
                          padding: '5px',
                          margin: '10px 0 10xp 20px',
                          marginRight: '20px',
                          paddingRight: '10px',
                          borderRight: '1px solid #ddd',
                          color: '#555',
                          fontSize: '14px',
                          width: '120px'
                        }}
                      >
                        지역별
                      </span>
                      <Select
                        style={{
                          height: '30px',
                          background: '#fff'
                        }}
                        defaultValue="전체"
                        onChange={(e) => {
                          setSelectedAreaname(e.target.value);
                        }}
                        sx={{
                          width: '100px',
                          padding: '0'
                        }}
                      >
                        <MenuItem value="전체">전체</MenuItem>
                        {areaname.map((item, index) => {
                          return (
                            <MenuItem key={index} value={item}>
                              {item}
                            </MenuItem>
                          );
                        })}
                      </Select>

                      <Select
                        style={{
                          height: '30px',
                          background: '#fff'
                        }}
                        defaultValue="전체"
                        onChange={(e) => {
                          setSelectedAreadetailname(e.target.value);
                        }}
                        sx={{
                          width: '100px',
                          marginLeft: '20px'
                        }}
                      >
                        <MenuItem value="전체">전체</MenuItem>
                        {areadetailname.map((item, index) => {
                          return (
                            <MenuItem key={index} value={item}>
                              {item}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </div>

                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center'
                      }}
                    >
                      <span
                        style={{
                          padding: '5px',
                          margin: '10px 0 10xp 20px',
                          marginRight: '20px',
                          paddingRight: '10px',
                          borderRight: '1px solid #ddd',
                          color: '#555',
                          fontSize: '14px',
                          width: '120px'
                        }}
                      >
                        검색
                      </span>
                      <Select
                        style={{
                          height: '30px',
                          background: '#fff'
                        }}
                        defaultValue="ncrgskname"
                        onChange={(e) => {
                          setSearchItem(e.target.value);
                        }}
                        sx={{
                          width: '100px',
                          padding: '0'
                        }}
                      >
                        <MenuItem value="ncrgskname">상호명</MenuItem>
                        <MenuItem value="ncrofftel">전화번호</MenuItem>
                      </Select>

                      <TextField
                        variant="outlined"
                        onChange={(e) => {
                          setSearchValue(e.target.value);
                          // 타이머 초기화
                          if (window.searchTimer) {
                            clearTimeout(window.searchTimer);
                          }

                          // 사용자가 입력을 멈춘 후 1초 뒤에 selectedSearch 실행
                          window.searchTimer = setTimeout(() => {
                            selectedSearchInput(1, e.target.value);
                          }, 1000); // 1초 대기
                        }}
                        sx={{
                          marginLeft: '20px',
                          fontSize: '14px'
                        }}
                      />

                      {/* <button onClick={()=>{selectedSearch(1)}} style={{"display": "block", "marginLeft": "30px", "backgroundColor": "#999", "color" : "#fff", "borderRadius": "5px", "width": "100px", "height": "30px"}} >검색</button> */}
                    </div>

                    {division === 'sales_second' && (
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center'
                        }}
                      >
                        <span
                          style={{
                            padding: '5px',
                            margin: '10px 0 10xp 20px',
                            marginRight: '20px',
                            paddingRight: '10px',
                            borderRight: '1px solid #ddd',
                            color: '#555',
                            fontSize: '14px',
                            width: '120px'
                          }}
                        >
                          1차 관리자별
                        </span>
                        <Select
                          style={{
                            height: '30px',
                            background: '#fff'
                          }}
                          defaultValue="전체"
                          onChange={(e) => {
                            setSelectedFilterManager(e.target.value);
                          }}
                          sx={{
                            width: '100px',
                            padding: '0'
                          }}
                        >
                          <MenuItem value="전체">전체</MenuItem>
                          {filterManager.map((item, index) => {
                            return (
                              <MenuItem key={index} value={item}>
                                {item}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </div>
                    )}

                    {/* <div style={{display: 'flex', alignItems: 'center'}}>
            <span style={{padding: '5px', margin:'10px 0 10xp 20px',  marginRight: '20px', paddingRight: '10px', borderRight: '1px solid #ddd', color: '#555', fontSize: '14px', width: "120px"}}>재연락 여부</span>
                <Checkbox checked={recallOn} onClick={()=>{setRecallOn(!recallOn)}}/>

            </div> */}
                  </div>
                </TitleSection>

                <div
                  style={{
                    justifyContent: 'flex-end',
                    display: 'flex',
                    alignContent: 'center',
                    gap: '20px',
                    marginTop: '28px',
                    marginBottom: '25px'
                  }}
                >
                  {division === 'sales' && (
                    <button
                      onClick={() => {
                        setSelectedSalesIndex(0);
                        setShareOn(!shareOn);
                      }}
                      style={
                        shareOn
                          ? {
                              display: 'block',
                              backgroundColor: '#1976d2',
                              color: '#fff',
                              borderRadius: '5px',
                              width: '100px',
                              height: '30px'
                            }
                          : {
                              display: 'block',
                              backgroundColor: '#999',
                              color: '#fff',
                              borderRadius: '5px',
                              width: '100px',
                              height: '30px'
                            }
                      }
                    >
                      공유
                    </button>
                  )}
                </div>

                <TableColumnSection>
                  <TableColumn width={1}></TableColumn>
                  {division === 'sales' && (
                    <TableColumn
                      width={10}
                      onClick={() => {
                        // const object = {...order};
                        // object['selected'] = 'regdate'
                        // if(object['regdate'] === 'DESC'){
                        //         object['regdate'] = 'ASC'
                        //     }else{
                        //         object['regdate'] = 'DESC'
                        //     }
                        // setOrder(object)
                        // setColumn('regdate')
                        // searchSort(1, 'regdate', object['regdate'])
                      }}
                    >
                      날짜
                      {/* <span className="material-symbols-outlined">
                                                {order['regdate'] === 'ASC' ? 'expand_more' : 'expand_less'}
                                                </span>  */}
                    </TableColumn>
                  )}
                  {division === 'sales_second' && (
                    <TableColumn width={10}>1차 저장일</TableColumn>
                  )}
                  {(division === 'sales' || division === 'sales_second') && (
                    <TableColumn
                      width={division === 'sales' ? 20 : 10}
                      // onClick={() => {
                      //     if(division === 'sales'){
                      //         const object = {...order};
                      //         object['selected'] = 's.ncrgskname'
                      //         if(object['s.ncrgskname'] === 'DESC'){
                      //                 object['s.ncrgskname'] = 'ASC'
                      //             }else{
                      //                 object['s.ncrgskname'] = 'DESC'
                      //             }
                      //         setOrder(object)
                      //         setColumn('s.ncrgskname')

                      //         searchSort(1, 's.ncrgskname', object['s.ncrgskname'])
                      //     }

                      // }}
                    >
                      상호명
                      {/* <span className="material-symbols-outlined">
                {order['s.ncrgskname'] === 'ASC' && division === 'sales' ? 'expand_more' : 'expand_less'}
            </span> */}
                    </TableColumn>
                  )}
                  {division === 'contact_sales' && (
                    <TableColumn
                      width={10}
                      onClick={() => {
                        const object = { ...order };
                        object['selected'] = 'shared_date';
                        if (object['shared_date'] === 'DESC') {
                          object['shared_date'] = 'ASC';
                        } else {
                          object['shared_date'] = 'DESC';
                        }
                        setOrder(object);
                        setColumn('shared_date');
                        initialSelectedSearch(1);
                      }}
                    >
                      날짜
                    </TableColumn>
                  )}
                  {division === 'contact_sales' && (
                    <TableColumn width={16}>상호명</TableColumn>
                  )}

                  <TableColumn width={15}>대표자명</TableColumn>
                  <TableColumn width={15}>전화번호</TableColumn>
                  <TableColumn
                    width={
                      division === 'sales' || division === 'sales_second'
                        ? 15
                        : 25
                    }
                  >
                    면허
                  </TableColumn>
                  {division === 'sales_second' && (
                    <TableColumn width={9}>1차 관리자</TableColumn>
                  )}
                  {division === 'contact_sales' || division === 'sales_second'}
                  <TableColumn width={10}>상세보기</TableColumn>
                  {division === 'sales' && (
                    <TableColumn width={7}>가망 분류</TableColumn>
                  )}
                  {division === 'sales_second' && (
                    <TableColumn width={7}>삭제</TableColumn>
                  )}
                  {division === 'sales' && (
                    <TableColumn width={7}>결번</TableColumn>
                  )}
                  {division === 'sales_second' && (
                    <TableColumn width={7}>가망 등급</TableColumn>
                  )}
                  {division === 'contact_sales' && (
                    <TableColumn width={7}>반려</TableColumn>
                  )}
                </TableColumnSection>

                {(division === 'sales' || division === 'sales_second') &&
                  trans
                    .filter(
                      (item) =>
                        !trashData.some(
                          (jtem) => item.ncrgsseq === jtem.ncrgsseq
                        )
                    )
                    .map((item, index) => {
                      return (
                        <TableMemoRowSection
                          key={index}
                          order={order}
                          setOrder={(params) => {
                            setOrder(params);
                          }}
                          setColumn={(params) => {
                            setColumn(params);
                          }}
                          selectedSalesSecond={selectedSalesSecond}
                          setSelectedSalesSecond={setSelectedSalesSecond}
                          firstCheckList={firstCheckList}
                          loadMemo={() => {
                            loadMemo(item.ncrgsseq);
                          }}
                          selectedSalesIndex={selectedSalesIndex}
                          moveToTrash={() => {
                            moveToTrash(item.ncrgsseq);
                          }}
                          setSelectedSalesIndex={() => {
                            setSelectedSalesIndex(index);
                          }}
                          setSelectedIndex={setSelectedIndex}
                          memo={managerMemo}
                          selectedIndex={selectedIndex}
                          setManagerMemo={(params) => {
                            setManagerMemo(params);
                          }}
                          setTrans={(params) => {
                            setTrans(params);
                          }}
                          index={index}
                          trans={trans}
                          item={item}
                          firstMemo={firstMemo}
                          checkData={checkData}
                          updateMissingNumber={(ncrgsseq, is_true) => {
                            const array = [...trans];
                            array[index].missing_number = is_true;
                            setTrans(array);

                            updateMissingNumber(ncrgsseq, is_true);
                          }}
                          updateMemo={
                            division === 'sales' || division === 'sales_second'
                              ? () => {
                                  updateMemo(managerMemo, item.id);
                                }
                              : () => {
                                  updateSecondMemo(
                                    managerMemo,
                                    item.target_master_number
                                  );
                                }
                          }
                          updateDetail={updateDetail}
                          setCheckData={
                            item.ncrgsseq !== checkData.ncrgsseq
                              ? () => {
                                  setTargetId(item.ncrgsseq);
                                  division === 'sales' ||
                                  division === 'sales_second'
                                    ? loadMemo(item.ncrgsseq)
                                    : loadSecondMemo(item.unique_key);
                                  setCheckData(item);
                                  setSelectedIndex(0);
                                }
                              : () => {
                                  setTargetId(item.ncrgsseq);
                                  setCheckData({});
                                  setSelectedIndex(-1);
                                }
                          }
                        />
                      );
                    })}

                {division === 'contact_sales' && trans.length === 0 && (
                  <div
                    style={{
                      textAlign: 'center',
                      padding: '50px 0'
                    }}
                  >
                    데이터가 존재하지 않습니다.
                  </div>
                )}

                {division === 'contact_sales' &&
                  trans
                    .filter(
                      (item) =>
                        !trashData.some(
                          (jtem) => item.unique_key === jtem.ncrgsseq
                        )
                    )
                    .map((item, index) => {
                      if (
                        item.check_list.contact_manager_id === Number(user_id)
                      ) {
                        return (
                          <TableMemoRowContactSection
                            key={index}
                            selectedSalesSecond={selectedSalesSecond}
                            setSelectedSalesSecond={setSelectedSalesSecond}
                            firstCheckList={firstCheckList}
                            loadMemo={() => {
                              loadMemo(item.unique_key);
                            }}
                            selectedSalesIndex={selectedSalesIndex}
                            moveToTrash={() => {
                              moveToTrash(item.unique_key);
                            }}
                            setSelectedSalesIndex={() => {
                              setSelectedSalesIndex(index);
                            }}
                            setSelectedIndex={setSelectedIndex}
                            memo={managerMemo}
                            selectedIndex={selectedIndex}
                            setManagerMemo={(params) => {
                              setManagerMemo(params);
                            }}
                            setTrans={(params) => {
                              setTrans(params);
                            }}
                            index={index}
                            trans={trans}
                            item={item}
                            firstMemo={firstMemo}
                            checkData={checkData}
                            updateMissingNumber={(ncrgsseq, is_true) => {
                              const array = [...trans];
                              array[index].missing_number = is_true;
                              setTrans(array);

                              updateMissingNumber(ncrgsseq, is_true);
                            }}
                            updateMemo={
                              division === 'sales' ||
                              division === 'sales_second'
                                ? () => {
                                    updateMemo(managerMemo, item.id);
                                  }
                                : () => {
                                    updateSecondMemo(
                                      managerMemo,
                                      item.unique_key
                                    );
                                  }
                            }
                            updateDetail={updateDetail}
                            setCheckData={
                              item.unique_key !== checkData.target_master_number
                                ? () => {
                                    setTargetId(item.unique_key);
                                    loadSecondMemo(item.unique_key);
                                    setCheckData(item);
                                    setSelectedIndex(0);
                                  }
                                : () => {
                                    setTargetId(item.unique_key);
                                    setCheckData({});
                                    setSelectedIndex(-1);
                                  }
                            }
                          />
                        );
                      }
                    })}

                <St_ArrowSection>
                  <Pagination
                    count={Math.ceil(totalCount / offset)}
                    onChange={handlePage}
                  />
                </St_ArrowSection>
              </div>

              <ManagerSection division={division}>
                <h2
                  style={{
                    marginBottom: '20px',
                    fontWeight: 'bold',
                    fontSize: '20px'
                  }}
                >
                  체크리스트
                </h2>
                <TableColumnSection>
                  <TableColumn width={30}>날짜</TableColumn>
                  <TableColumn width={40}>메모</TableColumn>
                  <TableColumn width={30}>작성</TableColumn>

                  {managerMemo !== '' ? (
                    JSON.parse(managerMemo.items)['시간 체크_time'] === '' ? (
                      <></>
                    ) : (
                      <TableColumn width={20}>디데이</TableColumn>
                    )
                  ) : (
                    <></>
                  )}
                </TableColumnSection>

                {(division === 'sales' || division === 'sales_second') &&
                  managerMemo === '' && (
                    <span
                      style={{
                        textAlign: 'center',
                        fontSize: '14px',
                        color: '#333',
                        paddingTop: '50px'
                      }}
                    >
                      {' '}
                      왼쪽 영업데이터를 선택하여
                      <br />
                      체크리스트를 출력하세요
                    </span>
                  )}

                {(division === 'sales' || division === 'sales_second') &&
                  managerMemo !== '' && (
                    <TableMemoSection
                      trans={trans}
                      setTrans={setTrans}
                      removeSecondManager={removeSecondManager}
                      removeSecondSaved={removeSecondSaved}
                      selectedSalesSecond={selectedSalesSecond}
                      setSelectedSalesSecond={setSelectedSalesSecond}
                      setSelectedIndex={(params) => {
                        setSelectedIndex(params);
                      }}
                      selectedSalesIndex={selectedSalesIndex}
                      division={division}
                      managerMemo={managerMemo}
                      index={0}
                      firstCheckList={firstCheckList}
                      setFirstCheckList={setFirstCheckList}
                      selectedIndex={selectedIndex}
                      salesManager={salesManager}
                      updateSchedule={updateSchedule}
                      updateMemo={
                        division === 'sales' || division === 'sales_second'
                          ? () => {
                              updateMemo(managerMemo, managerMemo.id);
                            }
                          : () => {
                              updateSecondMemo(managerMemo, managerMemo.id);
                            }
                      }
                      setMemo={(params) => {
                        setManagerMemo(params);
                      }}
                    />
                  )}

                {division === 'contact_sales' && secondMemo.length === 0 && (
                  <span
                    style={{
                      textAlign: 'center',
                      fontSize: '14px',
                      color: '#333',
                      paddingTop: '50px'
                    }}
                  >
                    {' '}
                    왼쪽 영업데이터를 선택하여
                    <br />
                    체크리스트를 출력하세요
                  </span>
                )}

                {division === 'contact_sales' &&
                  secondMemo.map((item, index) => {
                    if (index === 0) {
                      return (
                        <TableMemoSection
                          key={index}
                          trans={trans}
                          setTrans={setTrans}
                          managerMemo={managerMemo}
                          firstCheckList={secondCheckList}
                          setFirstCheckList={setSecondCheckList}
                          selectedSalesSecond={selectedSalesSecond}
                          setSelectedSalesSecond={setSelectedSalesSecond}
                          setSelectedIndex={(params) => {
                            setSelectedIndex(params);
                          }}
                          division={division}
                          item={item}
                          index={index}
                          selectedIndex={selectedIndex}
                          updateMemo={() => {
                            updateSecondMemo(item, item.target_master_number);
                          }}
                          setMemo={(params) => {
                            const array = [...secondMemo];
                            array[index] = params;
                            setSecondMemo(array);
                          }}
                        />
                      );
                    }
                  })}
              </ManagerSection>
            </SalesSection>
          )}

          {mode === 'history' && (
            <SalesSection>
              <div style={{ width: '100%' }}>
                <TitleSection style={{ marginBottom: '0px' }}>
                  <h1
                    style={{
                      fontWeight: 'bold',
                      fontSize: '20px',
                      marginBottom: '20px'
                    }}
                  >
                    기록 조회
                  </h1>
                  <St_Count>총: {history.length} 개</St_Count>
                </TitleSection>

                <TableColumnSection>
                  <TableColumn width={20}>날짜</TableColumn>
                  <TableColumn width={80}>내용</TableColumn>
                </TableColumnSection>

                {history.map((item, index) => {
                  return <TableHistoryRowSection key={index} item={item} />;
                })}

                <St_ArrowSection>
                  <Pagination
                    count={Math.ceil(totalCount / 10)}
                    onChange={handlePage}
                  />
                </St_ArrowSection>
              </div>
            </SalesSection>
          )}
        </TitleCard>
      </Grid>
    </Grid>
  );
}

export default SalesManager;
