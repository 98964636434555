import { createSlice } from '@reduxjs/toolkit';

interface EmailLogBoard {
  sender: string;
  receiver: string;
  title: string;
  date: string;
}

interface EmailLogState {
  list: EmailLogBoard[];
  total_count: number;
  pending: string;
}

const initialState: EmailLogState = {
  list: [],
  total_count: 0,
  pending: 'success',
};

export const emailLogSlice = createSlice({
  name: 'emailLogSlice',
  initialState: initialState,
  reducers: {
    updateLoading(state, action) {
      state.pending = 'loading';
    },

    updateSuccess(state, action) {
      state.pending = 'success';
    },

    updateFailed(state, action) {
      state.pending = 'failed';
    },

    updateTutorialContact: (state, action) => {
      state.list = action.payload.data;
      state.total_count = action.payload.total_count;
    },
  },
});

export const {
  updateLoading,
  updateSuccess,
  updateFailed,
  updateTutorialContact,
} = emailLogSlice.actions;

export default emailLogSlice.reducer;
export type { EmailLogState, EmailLogBoard };
