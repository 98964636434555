import styled from 'styled-components';
import TitleCard from '../../../components/Cards/TitleCard';
import {
  ManagerSection,
  SalesSection,
  SecondTableMemoSection,
  TableColumn,
  TableColumnSection,
} from './components';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { CategorySection } from './CategorySectoin';

function ContactData({ detailOn, setDetailOn }) {
  async function loadSalesSecondAll() {
    const option = {
      url: 'https://cpi.contax.ai/sales/load_sales_second_memo_all',
      method: 'GET',
      header: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    };

    await axios(option).then(response => {
      if (response.status === 200) {
        setSecondData(response.data);
      }
    });
  }

  useEffect(() => {
    loadSalesSecondAll();
  }, []);
  const [secondData, setSecondData] = useState([]);
  const [selectedSecondData, setSelectedSecondData] = useState([]);

  return (
    <TitleCard title="영업">
      <CategorySection />
      <SalesSection>
        <h1 style={{ fontWeight: 'bold', marginBottom: '20px' }}>
          방문 데이터
        </h1>
        <TableColumnSection>
          <TableColumn width={25}>날짜</TableColumn>
          <TableColumn width={30}>상호명</TableColumn>
          <TableColumn width={25}>관리자</TableColumn>
          <TableColumn width={20}>상세내용</TableColumn>
        </TableColumnSection>

        {secondData.length === 0 && (
          <span
            style={{
              textAlign: 'center',
              fontSize: '14px',
              color: '#333',
              paddingTop: '50px',
            }}
          >
            {' '}
            메모가 존재하지 않습니다.
          </span>
        )}

        {secondData.map((item, index) => {
          if (item.is_saved === true) {
            return (
              <SecondTableMemoSection
                setDetailOn={setDetailOn}
                detailOn={detailOn}
                selectedFirstData={selectedSecondData}
                setSelectedFirstData={params => {
                  const array = [...selectedSecondData];
                  if (array.length === 0) {
                    array.push(params);
                  } else {
                    let ok = false;
                    let index = 0;
                    selectedSecondData.forEach((item, jndex) => {
                      if (item.id === params.id) {
                        ok = true;
                        index = jndex;
                      }
                    });

                    if (ok === true) {
                      array.splice(index, 1);
                    } else {
                      array.push(params);
                    }
                  }

                  setSelectedSecondData(array);
                }}
                item={item}
                index={index}
                division="sales_second"
              />
            );
          }
        })}
      </SalesSection>
    </TitleCard>
  );
}

export default ContactData;
