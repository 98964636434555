import React, { useEffect, useState } from 'react';
import BoardSearchHeader from '../../../components/BoardSearchHeader';
import { StoreState } from '../../../app/store';
import { useDispatch, useSelector } from 'react-redux';
import {
  LicenseData,
  updateLicenseLimit,
  updateLicenseMode,
  updateLicenseTarget,
  updateOffset,
  updateOriginalTargetLicense
} from '../../../app/slice/licensePageSlice';
import {
  EmptyDataRowSection,
  ManagerGrid,
  ManagerGridButton,
  ManagerGridRow,
  ManagerGridRowSection
} from '../../../components/GridType';
import { Settings } from '@mui/icons-material';
import { PencilIcon } from '@heroicons/react/16/solid';
import BoardPagenation from '../../../components/BoardPagenation';
import DetailSection, {
  TabButtonTypes
} from '../../../components/Table/DetailSection';

const items: TabButtonTypes[] = [
  {
    title: '상세정보',
    onClick: () => {
      console.log('open');
    },
    active: true
  }
];

function LicenseJeonmoonSection() {
  const [selectedCategory, setSelectedCategory] = useState('전문 면허');
  const [open, setOpen] = useState({ index: 0, open: false });
  const {
    category_list,
    limit,
    query,
    jonghap_column,
    total_count,
    data,
    offset,
    pageLength,
    pageNumbers
  } = useSelector((state: StoreState) => state.licensePage);

  const dispatch = useDispatch();

  async function openDetailSection(index: number, item: LicenseData) {
    dispatch(updateOriginalTargetLicense(item));
    dispatch(updateLicenseTarget(item));

    if (index === open.index) {
      if (open.open) {
        setOpen({ index: index, open: false });
      }

      if (!open.open) {
        setOpen({ index: index, open: true });
      }
    }

    if (index !== open.index) {
      setOpen({ index: index, open: true });
    }
  }

  useEffect(() => {
    category_list.forEach((category) => {
      if (category.active === true) {
        setSelectedCategory(category.title);
      }
    });
  }, [category_list]);

  return (
    <>
      <BoardSearchHeader
        limit={limit}
        setLimit={() => {
          dispatch(updateLicenseLimit);
        }}
        query={query}
        onInput={(e) => {
          return;
        }}
        onSelect={(e) => {
          return;
        }}
        col={jonghap_column}
        defaultColumn="id"
        title={selectedCategory}
        total_count={total_count}
        limitOption={[10, 50, 100]}
      />

      <ManagerGrid
        col={jonghap_column}
        sortFunction={() => {
          return;
        }}
        size="large"
      >
        {data.length === 0 && <EmptyDataRowSection />}
        {data.length > 0 &&
          data.map((item, index) => (
            <>
              <ManagerGridRowSection>
                <ManagerGridRow
                  size="large"
                  width={15}
                  onClick={() => {
                    openDetailSection(index, item);
                  }}
                >
                  {item.id}
                </ManagerGridRow>

                <ManagerGridRow
                  size="large"
                  width={15}
                  onClick={() => {
                    openDetailSection(index, item);
                  }}
                >
                  {item.license_category1}
                </ManagerGridRow>

                <ManagerGridRow
                  size="large"
                  width={30}
                  onClick={() => {
                    openDetailSection(index, item);
                  }}
                >
                  {item.license_name}
                </ManagerGridRow>

                <ManagerGridRow
                  size="large"
                  width={20}
                  onClick={() => {
                    openDetailSection(index, item);
                  }}
                >
                  {item.capital_standard}
                </ManagerGridRow>

                <ManagerGridRow
                  size="large"
                  width={20}
                  onClick={() => {
                    openDetailSection(index, item);
                  }}
                >
                  <ManagerGridButton type="active">
                    <Settings /> 설정
                  </ManagerGridButton>
                </ManagerGridRow>
              </ManagerGridRowSection>
              <DetailSection open={open.index === index && open.open}>
                <DetailSection.ButtonWrapper item={items} />
              </DetailSection>
            </>
          ))}

        <div
          style={{
            display: 'flex',
            marginTop: '30px',
            justifyContent: 'end'
          }}
        >
          <ManagerGridButton
            type="active"
            onClick={() => {
              dispatch(updateLicenseMode('create'));
            }}
          >
            <PencilIcon style={{ width: '20px' }} />
            작성
          </ManagerGridButton>
        </div>

        <BoardPagenation
          offset={offset}
          pageNumbers={pageNumbers}
          pageLength={pageLength}
          setOffset={(index: number) => {
            dispatch(updateOffset(index));
          }}
        />
      </ManagerGrid>
    </>
  );
}

export default LicenseJeonmoonSection;
