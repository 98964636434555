import axios from 'axios';

export async function insertNotification(
  route,
  title,
  content,
  userIdList,
  article_id
) {
  const body = {
    notification_type: route,
    title: title,
    content: content,
    target: userIdList,
    article_id: article_id
  };

  const option = {
    url: 'https://cpi.contax.ai/insert_notification',
    method: 'POST',
    data: body,
    header: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };

  axios(option).then((response) => {
    if (response.status === 200) {
      return true;
    } else {
      return response.status;
    }
  });
}
