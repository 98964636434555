import axios from 'axios';
import { FiltersType } from '../app/slice/salesSlice';

async function searchRecallJonghapSales(page: number, limit: number) {
  const option = {
    url: 'https://cpi.contax.ai/sales/load_selected_manager_data_recall',
    method: 'POST',
    data: {
      offset: limit * (page - 1),
      limit: limit,
    },
    header: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };

  try {
    const response = await axios(option);

    if (response.status === 200) {
      return { status: true, data: response.data };
    }
  } catch (error) {
    return;
  }
}

async function searchRecallJeonmoonSales(page: number, limit: number) {
  const option = {
    url: 'https://cpi.contax.ai/sales/load_selected_manager_jeonmoon_data_recall',
    method: 'POST',
    data: {
      offset: limit * (page - 1),
      limit: limit,
    },
    header: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };

  try {
    const response = await axios(option);

    if (response.status === 200) {
      return { status: true, data: response.data };
    }
  } catch (error) {
    return;
  }
}

async function searchRecallPhoneSales(page: number, limit: number) {
  const option = {
    url: 'https://cpi.contax.ai/sales/load_selected_manager_phone_sales_data_recall',
    method: 'POST',
    data: {
      offset: limit * (page - 1),
      limit: limit,
    },
    header: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };

  try {
    const response = await axios(option);

    if (response.status === 200) {
      return { status: true, data: response.data };
    }
  } catch (error) {
    return;
  }
}

async function searchRecallJonghapJeonmoonSales(page: number, limit: number) {
  const option = {
    url: 'https://cpi.contax.ai/sales/load_selected_manager_jonghap_jeonmoon_data_recall',
    method: 'POST',
    data: {
      offset: limit * (page - 1),
      limit: limit,
    },
    header: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };

  try {
    const response = await axios(option);

    if (response.status === 200) {
      return { status: true, data: response.data };
    }
  } catch (error) {
    return error;
  }
}

async function searchJonghapSales(
  page: number,
  filters: FiltersType,
  sort: boolean | null,
  limit: number,
) {
  const data = { ...filters };

  const option = {
    url: 'https://cpi.contax.ai/sales/jonghap_search',
    method: 'POST',
    params: {
      offset: limit * (page - 1),
      limit: limit,
      abs_sum_value: sort,
    },
    data,
    header: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };

  try {
    const response = await axios(option);

    if (response.status === 200) {
      return { status: true, data: response.data };
    }
    return { status: false, data: [] };
  } catch (error) {
    return { status: false, data: [] };
  }
}

async function searchPhoneSales(
  page: number,
  filters: FiltersType,
  sort: boolean | null,
  limit: number,
) {
  const data = { ...filters };

  const option = {
    url: 'https://cpi.contax.ai/sales/phoneSales_search',
    method: 'POST',
    params: {
      offset: limit * (page - 1),
      limit,
      abs_sum_value: sort,
    },
    data,
    header: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };

  try {
    const response = await axios(option);

    if (response.status === 200) {
      return { status: true, data: response.data };
    }
    return { status: false, data: [] };
  } catch (error) {
    return { status: false, data: [] };
  }
}

async function searchJeonmoonSales(
  page: number,
  filters: FiltersType,
  sort: boolean | null,
  limit: number,
) {
  const data = { ...filters };

  const option = {
    url: 'https://cpi.contax.ai/sales/jeonmoon_search',
    method: 'POST',
    params: {
      offset: limit * (page - 1),
      limit: limit,
      abs_sum_value: sort,
    },
    data: data,
    header: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };

  try {
    const response = await axios(option);

    if (response.status === 200) {
      return { status: true, data: response.data };
    } else {
      return { status: false, data: [] };
    }
  } catch (error) {
    return { status: false, data: [] };
  }
}

async function getRejection() {
  const rejectionOption = {
    url: 'https://cpi.contax.ai/sales/load_sales_rejection',
    method: 'GET',
    header: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };

  try {
    const response = await axios(rejectionOption);
    if (response.status === 200) {
      return response.data.data;
    }
  } catch (error) {
    return error;
  }
}

async function jonghapJeonmoonSearch(
  page: number,
  filters: FiltersType,
  sort: boolean | null,
  limit: number,
) {
  const option = {
    url: 'https://cpi.contax.ai/sales/jonghap_jeonmoon_search',
    method: 'POST',
    params: {
      offset: limit * (page - 1),
      limit,
      abs_sum_value: sort,
    },
    data: filters,
    header: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };

  try {
    const response = await axios(option);

    if (response.status === 200) {
      return { status: true, data: response.data };
    }

    return { status: false, data: [] };
  } catch (error) {
    return { status: false, data: [] };
  }
}

interface RejectionType {
  item: string;
  ncrgsseq: string;
  rejection_true: boolean;
  reason: string;
  second_manager_id: number;
  second_manager_name: string;
  contact_manager_id: number;
  contact_manager_name: string;
  date: string;
  resolve: boolean;
  resolve_date: string;
}

interface OrderType {
  selected: string;
  ncrareadetailname: 'ASC' | 'DESC';
  second_saved_date: 'ASC' | 'DESC';
  ncrgskname: 'ASC' | 'DESC';
  writter_name: 'ASC' | 'DESC';
  second_manager: 'ASC' | 'DESC';
}

interface JonghapMemoType {
  j_address: string;
  s_ncrofftel: string;
  s_address: string;
  second_saved_date: string;
  title: string;
  contact_manager_name: string;
  rejection: boolean;
  resolve: boolean;
  targetMasterNumber: string;
  rejection_true: boolean;
  rejection_item: RejectionType;
}

interface JonghapMemoResponse {
  status: number;
  data: JonghapMemoType[];
}

async function getJonghapSalesAll(
  column:
    | 'selected'
    | 'ncrareadetailname'
    | 'second_saved_date'
    | 'ncrgskname'
    | 'writter_name'
    | 'second_manager',
  order: OrderType,
  value: string,
  searchItem: string,
) {
  let rejectionArray: RejectionType[] = [];

  const rejectionOption = {
    url: 'https://cpi.contax.ai/sales/load_sales_rejection',
    method: 'GET',
    header: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };

  await axios(rejectionOption).then(response => {
    if (response.status === 200) {
      rejectionArray = response.data.data;
    }
  });

  const option = {
    url: 'https://cpi.contax.ai/sales/load_jonghap_sales_memo_all',
    params: {
      column: column,
      order: order[column] === undefined ? 'ASC' : order[column],
      input: value,
      input_column: searchItem,
    },
    method: 'GET',
    header: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };

  try {
    const response: JonghapMemoResponse = await axios(option);

    if (response.status === 200) {
      rejectionArray.forEach(item => {
        let selectedIndex = 0;
        let is_rejected = false;
        let is_resolved = false;

        response.data.forEach((jtem, jndex) => {
          if (item.ncrgsseq === jtem.targetMasterNumber) {
            selectedIndex = jndex;
            is_rejected = true;
          }

          if (item.ncrgsseq === jtem.targetMasterNumber) {
            selectedIndex = jndex;
            is_resolved = true;
          }
        });

        if (is_rejected) {
          response.data[selectedIndex].rejection = true;
          response.data[selectedIndex].rejection_item = item;
          response.data[selectedIndex].resolve = false;
        }

        if (is_resolved) {
          response.data[selectedIndex].rejection = false;
          response.data[selectedIndex].rejection_item = {
            item: '',
            ncrgsseq: '',
            rejection_true: false,
            reason: '',
            second_manager_id: 0,
            date: '',
            resolve: false,
            resolve_date: '',
            contact_manager_id: 0,
            contact_manager_name: '',
            second_manager_name: '',
          };
          response.data[selectedIndex].resolve = true;
        }
      });

      // console.log(response.data)

      // let mode = 'resolve'

      // if(mode === 'shared'){
      //     response.data.sort((a, b) => {
      //         if(a.contact_manager_id !== null){
      //             return -1;
      //         }
      //     });
      // }

      // if(mode === 'resolve'){
      //     response.data.sort((a, b) => {
      //         if(a.resolve === true && b.resolve === false){
      //             return -1;
      //         }
      //     });
      // }

      return response.data;
    }
  } catch (error) {
    return;
  }
}

async function getClassification(user_id: string | null) {
  const classifyData = {
    first_manager_id: Number(user_id),
    is_second: false,
  };

  const classifyOption = {
    url: 'https://cpi.contax.ai/sales/load_sales_classify',
    method: 'POST',
    data: classifyData,
    header: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };

  try {
    const result = await axios(classifyOption);
    if (result.status === 200) {
      return result.data;
    }
  } catch (error) {
    return;
  }
}

async function getThirdManager() {
  const option = {
    url: 'https://cpi.contax.ai/get_third_sales_manager',
    method: 'GET',
    header: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };

  try {
    const response = await axios(option);
    if (response.status === 200) {
      return response.data;
    } else {
      console.log('Non-200 response received: ', response.status);
      return null;
    }
  } catch (error) {
    console.error('Error loading checklist:', error);
  }
}

async function insertSalesClassify(
  ncrgsseq: string,
  ncrgskname: string,
  classification: string,
) {
  const user_id = localStorage.getItem('id');
  const name = localStorage.getItem('name');

  let data = {};

  data = {
    ncrgsseq: ncrgsseq,
    classification: classification,
    item: ncrgskname,
    first_manager_id: Number(user_id),
    first_manager_name: name,
    is_second: false,
  };

  const option = {
    url: 'https://cpi.contax.ai/sales/insert_sales_classify',
    method: 'POST',
    data: data,
    header: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };

  await axios(option);
}

async function updateRecall(
  ncrgsseq: string,
  recallTrue: boolean,
  recallDate: string,
) {
  const option = {
    url: 'https://cpi.contax.ai/update_recall',
    method: 'PUT',
    params: {
      ncrgsseq: ncrgsseq,
      recallTrue: recallTrue,
      recallDate: recallDate,
    },
    header: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };

  await axios(option);
}

async function updateExtraInfo(
  column: string,
  input: string,
  unique_key: string,
  target: string,
) {
  const option = {
    url: 'https://cpi.contax.ai/update_jonghap_extra_info',
    method: 'PUT',
    params: {
      table: target,
      target: column,
      input,
      unique_key,
    },
    header: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };

  await axios(option);
}

async function shareToThirdManager(
  selectedData: {
    targetMasterNumber: number;
    id: number;
    title: string;
    rejection: boolean;
  }[],
  checkSecondManager: { name: string; user_id: number } | '',
  data: any,
  selectedIndex: number,
  checkList: string,
) {
  let is_rejected = false;

  if (selectedIndex < 0) {
    alert('선택된 데이터가 없습니다.');
    return false;
  }

  if (checkSecondManager === '') {
    alert('방문 관리자를 선택해주세요.');
    return false;
  }

  selectedData.forEach(item => {
    if (item.rejection) {
      is_rejected = true;
    }
  });

  if (is_rejected) {
    alert('선택한 데이터 중 반려 데이터가 포함되어 있습니다.');
    return false;
  }

  const array: number[] = [];
  const ids: number[] = [];

  selectedData.forEach(item => {
    array.push(item.targetMasterNumber);
    ids.push(item.id);
    insertSecondMemo(
      item.title,
      item.targetMasterNumber,
      checkSecondManager,
      checkList,
    );
  });

  const contactResponse = await updateCheckListContactManager(
    checkSecondManager,
    ids,
  );
  const thirdResponse = await updateThirdManager(array, checkSecondManager);

  if (contactResponse && thirdResponse) {
    alert('공유가 완료되었습니다');

    const array = [...data];
    array[selectedIndex].contact_manager_name = checkSecondManager.name;

    return array;
  }
}

async function getThirdManagerSalesMember() {
  const user_id = localStorage.getItem('id');

  const option = {
    url: 'https://cpi.contax.ai/load_third_manager_sales_member',
    method: 'GET',
    params: {
      user_id: user_id,
    },
    header: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };

  try {
    const response = await axios(option);
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    return error;
  }
}

async function getSecondManagerSalesMember() {
  const user_id = localStorage.getItem('id');

  const option = {
    url: 'https://cpi.contax.ai/load_sales_member',
    method: 'GET',
    params: {
      user_id: user_id,
    },
    header: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };

  try {
    const response = await axios(option);
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {}
}

async function updateCheckListContactManager(
  checkSecondManager: { name: string; user_id: number },
  ids: number[],
) {
  const option = {
    url: 'https://cpi.contax.ai/update_first_data_second_manager',
    method: 'PUT',
    params: {
      name: checkSecondManager.name,
      user_id: checkSecondManager.user_id,
    },
    data: ids,
    header: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };

  try {
    const response = await axios(option);

    if (response.status === 200) {
      return true;
    }
  } catch (error) {
    return false;
  }
}

async function updateThirdManager(
  ids: number[],
  checkSecondManager: { name: string; user_id: number },
) {
  const option = {
    url: 'https://cpi.contax.ai/update_sales_second_member',
    method: 'PUT',
    data: {
      user_id: checkSecondManager.user_id,
      members_list: ids,
    },
    header: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };

  try {
    const response = await axios(option);

    if (response.status === 200) {
      return true;
    }
  } catch (error) {
    return false;
  }
}

async function insertSecondMemo(
  title: string,
  target_master_number: number,
  checkSecondManager: { name: string; user_id: number },
  checkList: string,
) {
  const object = {
    items: JSON.stringify(checkList),
    comment: '',
    call_memo: '',
    writter: checkSecondManager.user_id,
    title,
    target_master_number: target_master_number,
    writter_name: checkSecondManager.name,
  };

  const option = {
    url: 'https://cpi.contax.ai/sales/insert_sales_second_memo',
    method: 'POST',
    data: object,
    header: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };

  await axios(option);
}

async function updateMissingNumber(ncrgsseq: string, is_true: boolean) {
  const option = {
    url: 'https://cpi.contax.ai/update_missing_number',
    method: 'PUT',
    params: {
      ncrgsseq,
      is_true,
    },
    header: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };

  try {
    const response = await axios(option);
    if (response.status === 200) {
      return true;
    }
  } catch (error) {
    return false;
  }
}

async function updateJonghapJeonmoonMissingNumber(
  ncrgsseq: string,
  is_true: boolean,
  target: string,
) {
  const option = {
    url: 'https://cpi.contax.ai/update_jonghap_jeonmoon_missing_number',
    method: 'PUT',
    params: {
      ncrgsseq,
      is_true,
      target,
    },
    header: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };

  try {
    const response = await axios(option);
    if (response.status === 200) {
      return true;
    }
  } catch (error) {
    return false;
  }
}

export {
  jonghapJeonmoonSearch,
  searchJonghapSales,
  searchJeonmoonSales,
  getJonghapSalesAll,
  getSecondManagerSalesMember,
  getClassification,
  getThirdManager,
  insertSalesClassify,
  updateRecall,
  updateExtraInfo,
  shareToThirdManager,
  insertSecondMemo,
  getThirdManagerSalesMember,
  searchRecallJonghapSales,
  searchRecallJeonmoonSales,
  searchRecallJonghapJeonmoonSales,
  getRejection,
  updateJonghapJeonmoonMissingNumber,
  updateMissingNumber,
  searchPhoneSales,
  searchRecallPhoneSales,
};
