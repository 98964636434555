import TitleCard from '../../components/Cards/TitleCard';
import { Grid } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  GyejeongModal,
  OverallModal,
  ReportModal
} from '../../components/ModalComponents';
import DetailTable from '../../components/Layout/DetailTable';
import { SelectableExpertProperty } from './component/SelectableExpertProperty';
import { ManagerButton } from '../../components/Button';
import { useDispatch, useSelector } from 'react-redux';
import {
  closeReportEmailModal,
  openReportEmailModal,
  updateOffset,
  updatePageStatus,
  updateReportEmailTotalCount,
  updateSelectedMember,
  updateSelectedReport
} from '../../app/slice/reportSendEmailSlice';
import { getAllUser } from '../../utils/user_API';
import BoardPagenation from '../../components/BoardPagenation';
import {
  calculatePagination,
  createPaginationSize
} from '../../utils/common_API';
import { ReportEmailForm } from '../../components/Email/ReportEmailForm';

const ExpertPropertySection = styled.div`
  margin-top: 5px;
  display: flex;
  flex-wrap: wrap;
  gap: 50px;
`;

function ReportSendEmail() {
  const role = localStorage.getItem('access');

  const { pageStatus, modalOn, offset, limit } = useSelector(
    (state) => state.reportEmail
  );
  const dispatch = useDispatch();
  // TODO
  // 3차 관리자에도 엑셀 업로드 기능 붙이기

  const [expression, setExpression] = useState({});
  const [company, setCompany] = useState('');
  const [assignmentData, setAssignmentData] = useState([]);
  const [gyejeongStatus, setGyejeongStatus] = useState('');
  const [gyejeongRow, setGyejeongRow] = useState([]);
  const [originalRow, setOriginalRow] = useState([]);
  const [reportList, setReportList] = useState([]);
  const [uploadedQuarter, setUploadedQuarter] = useState('');
  const [selectedYear, setSelectedYear] = useState('2022');
  const [selectedQuarter, setSelectedQuarter] = useState('01');
  const [siljil, setSiljil] = useState([]);
  const [targetName, setTargetName] = useState('');
  const [targetId, setTargetId] = useState(0);

  const [diff, setDiff] = useState({
    yudong: 0,
    buchae: 0,
    chaIp: 0,
    maechul: 0,
    jasanTurning: 0,
    techdev: 0,
    cashflow: 0,
    profit: 0,
    income: 0,
    alljasan: 0
  });

  const [result, setResult] = useState({
    yudong: 'down',
    buchae: 'down',
    chaIp: 'down',
    maechul: 'down',
    jasanTurning: 'down',
    techdev: 'down',
    cashflow: 'down',
    profit: 'down',
    income: 'down',
    alljasan: 'down'
  });

  useEffect(() => {
    if (uploadedQuarter !== 'None') {
      setSelectedYear(uploadedQuarter.substring(0, 4));
      setSelectedQuarter(uploadedQuarter.substring(4, 6));
    }
  }, [uploadedQuarter]);

  function removeDuplicatesByKey(array, key) {
    const seen = new Set();

    return array.filter((item) => {
      return seen.has(item[key]) ? false : seen.add(item[key]);
    });
  }

  useEffect(() => {
    const localMember = localStorage.getItem('second_manage_data');
    if (localMember) {
      const member = localMember.split(',').map((item) => item.trim());
      getUserData(member);
    }
    getExpression();
  }, [offset]);

  useEffect(() => {
    getGyejeongList(gyejeongStatus);
  }, [originalRow]);

  const Grid_COL = [
    {
      id: 'user_id',
      title: '번호',
      width: '10',
      textAlign: 'center',
      align: 'center'
    },
    {
      id: 'representative_name',
      title: '이름',
      width: '20',
      textAlign: 'left'
    },
    { id: 'email', title: '이메일', width: '20', textAlign: 'left' },
    { id: 'company_name', title: '회사', width: '20', textAlign: 'left' },
    {
      id: 'phone_number',
      title: '휴대폰 번호',
      width: '15',
      textAlign: 'left'
    },
    {
      id: 'business_number',
      title: '사업자 번호',
      width: '15',
      textAlign: 'left'
    }
  ];

  const transformData = (data, Grid_COL) => {
    return {
      contents: Object.entries(data)
        .map(([key, value]) => {
          const matchingColumn = Grid_COL.find((column) => column.id === key);
          if (matchingColumn) {
            return {
              id: key,
              value: value,
              width: matchingColumn.width,
              textAlign: matchingColumn.textAlign,
              align: matchingColumn.align
            };
          }
          return null;
        })
        .filter((item) => item !== null), // 필터를 사용하여 null 값을 제거합니다.
      open: false
    };
  };

  const [pageLength, setPageLength] = useState(0);
  const [pageNumbers, setPageNumbers] = useState([1]);

  async function getUserData(member) {
    let data = [];

    const params = {
      database: 'members',
      categories: ['business_type'],
      search_queries: ['유저'],
      offset: offset * limit,
      limit: limit,
      sort_option: 'user_id',
      sort: 'DESC'
    };

    const response = await getAllUser(params);

    if (response.status === 'success') {
      data = response.data.data;
      dispatch(updateReportEmailTotalCount(response.data.total_count));
      const length = calculatePagination(response.data.total_count, limit);

      setPageLength(calculatePagination(response.data.total_count, limit));
      setPageNumbers(createPaginationSize(offset, length));
    } else {
      return;
    }

    const assignmentArray = [];
    const anotherCheckedData = [...data];

    const names = await convertUniqueKeyToName(member);

    data.forEach((item, index) => {
      names.forEach((jtem) => {
        anotherCheckedData[index] = {
          ...anotherCheckedData[index],
          id: anotherCheckedData[index].user_id
        };

        if (role !== '최고 관리자') {
          if (
            item.business_number === jtem.ncrmasternum &&
            item.business_type === '유저'
          ) {
            assignmentArray.push(item);
          }
        } else if (item.business_type === '유저') {
          assignmentArray.push(item);
        }
      });
    });

    const result = [];

    assignmentArray.forEach((item) => {
      result.push(transformData(item, Grid_COL));
    });

    setAssignmentData(result);
  }

  async function convertUniqueKeyToName(uniqueKey) {
    const option = {
      url: 'https://cpi.contax.ai/sales/convert_unique_key_to_user_name',
      data: {
        unique_keys: uniqueKey
      },
      method: 'POST',
      header: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data'
      }
    };

    try {
      const response = await axios(option);
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      return error;
    }
  }

  async function getAnalyReport(email) {
    const option = {
      url: 'https://cpi.contax.ai/conbi_analy_report?email=' + email,
      method: 'GET',
      header: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data'
      }
    };

    await axios(option).then((response) => {
      if (response.status === 200) {
        setReportList(response.data);
      }
    });
  }

  async function getExpression() {
    const option = {
      url: 'https://cpi.contax.ai/mng_avg_expression',
      method: 'GET',
      header: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data'
      }
    };

    await axios(option).then((response) => {
      if (response.status === 200) {
        setExpression(response.data);
      }
    });
  }

  const SelectQuarterSection = styled.div`
    display: flex;
    gap: 20px;
    align-items: end;
  `;

  async function insertComment(comment, title) {
    const id = localStorage.getItem('id');
    const name = localStorage.getItem('name');

    const option = {
      url: 'https://cpi.contax.ai/insert_expert_comment',
      data: {
        writter_id: id,
        writter_name: name,
        target_id: targetId,
        target_name: targetName,
        quarter: selectedYear + '' + selectedQuarter,
        company: company,
        comment: comment,
        pyeongjeom: title,
        comment_1: commentList.comment_1,
        comment_2: commentList.comment_2
      },
      method: 'POST',
      header: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data'
      }
    };

    await axios(option).then((response) => {
      if (response.status === 200) {
        alert('성공적으로 저장되었습니다.');
        dispatch(closeReportEmailModal('report'));
      }
    });
  }

  async function getGyejeongGwamok(company) {
    const array = [];
    const option = {
      url: 'https://cpi.contax.ai/jaemu_data_by_company',
      params: {
        company_name: company
      },
      method: 'GET',
      header: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data'
      }
    };

    try {
      const response = await axios(option);

      if (response.status === 200) {
        response.data.forEach((item, index) => {
          array.push({ ...item, id: index });
        });
      }
    } catch (e) {
      return e;
    }

    const sonikOption = {
      url: 'https://cpi.contax.ai/sonik_data_by_company',
      params: {
        company_name: company
      },
      method: 'GET',
      header: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data'
      }
    };

    try {
      const response = await axios(sonikOption);

      if (response.status === 200) {
        response.data.forEach((item, index) => {
          array.push({ ...item, id: index });
        });
      }
    } catch (e) {
      return e;
    }

    const siljiloption = {
      url: 'https://cpi.contax.ai/management_score/calculate_real_capital_quarter',
      params: {
        company_name: company,
        quarter: selectedYear + '' + selectedQuarter
      },
      method: 'GET',
      header: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data'
      }
    };

    try {
      const response = await axios(siljiloption);
      if (response.status === 200) {
        const item = response.data;

        setSiljil([
          { category: '실질자본금', value: item.real_capital, id: 0 },
          { category: '자산총계', value: item.total_assets, id: 1 },
          { category: '부채총계', value: item.total_dept, id: 2 },
          { category: '자본총계', value: item.capital_total, id: 3 },
          {
            category: '인정자산',
            value: item.recognized_assets,
            id: 4
          },
          {
            category: '불인정자산',
            value: item.unrecognized_assets_sum,
            id: 5
          }
        ]);
      }

      if (response.status === 404) {
        setSiljil([
          { category: '실질자본금', value: 0, id: 0 },
          { category: '자산총계', value: 0, id: 1 },
          { category: '부채총계', value: 0, id: 2 },
          { category: '자본총계', value: 0, id: 3 },
          { category: '인정자산', value: 0, id: 4 },
          { category: '불인정자산', value: 0, id: 5 }
        ]);
      }
    } catch (e) {
      setSiljil([
        { category: '실질자본금', value: 0, id: 0 },
        { category: '자산총계', value: 0, id: 1 },
        { category: '부채총계', value: 0, id: 2 },
        { category: '자본총계', value: 0, id: 3 },
        { category: '인정자산', value: 0, id: 4 },
        { category: '불인정자산', value: 0, id: 5 }
      ]);
    }

    setOriginalRow(array);
  }
  function getGyejeongList() {
    let array = [];
    array = addGyejeongGwamok([
      '유동자산',
      '당좌자산',
      '현금',
      '보통예금',
      '기타제예금',
      '공사미수금',
      '가지급금',
      '단기대여금',
      '재고자산',
      '유동부채',
      '외상매입금',
      '미지급금',
      '예수금',
      '가수금',
      '미지급세금',
      '공사선수금',
      '부채총계',
      '유동부채',
      '외상매입금',
      '미지급금',
      '예수금',
      '가수금',
      '비유동부채',
      '자본총계',
      '자본금',
      '자본잉여금',
      '자본조정',
      '기타포괄손익누계액',
      '이익잉여금',
      '미처분이익잉여금',
      '차입금',
      '자산총계',
      '이자비용',
      '영업손실',
      '매출액',
      '당기순손실',
      '영업손실',
      '매출액',
      '건설기술투자비',
      '건설부분총매출',
      '인정자산',
      '불인정자산',
      '영업이익',
      '당기순이익'
    ]);
    setGyejeongRow(removeDuplicatesByKey(array, 'category'));
  }

  function addGyejeongGwamok(gyejeongList) {
    const array = [];
    let index = 0;

    const quarterEndMonths = {
      '01': '03/31',
      '02': '06/30',
      '03': '09/30',
      '04': '12/31'
    };

    const quarterEndDate = `${selectedYear}/${quarterEndMonths[selectedQuarter]}`;

    const nonMatchingCategories = [];

    // Iterate through each item in the gyejeongList
    gyejeongList.forEach((jtem) => {
      let found = false;

      // Check if there is a matching category in gyejeongRow
      originalRow.forEach((item) => {
        if (jtem === item.category && item.period.endsWith(quarterEndDate)) {
          array.push(item);
          found = true;
          item.id = gyejeongList.length + index;
        }
      });

      if (!found) {
        nonMatchingCategories.push(jtem);

        const object = {
          category: jtem,
          value: 0,
          id: gyejeongList.length + index
        };

        if (selectedQuarter === '01') {
          object.period = `${selectedYear}/01/01 ~ ${selectedYear}/03/31`;
        }

        if (selectedQuarter === '02') {
          object.period = `${selectedYear}/01/01 ~ ${selectedYear}/06/30`;
        }

        if (selectedQuarter === '03') {
          object.period = `${selectedYear}/01/01 ~ ${selectedYear}/09/30`;
        }

        if (selectedQuarter === '04') {
          object.period = `${selectedYear}/01/01 ~ ${selectedYear}/12/31`;
        }

        array.push(object);
      }

      index++;
    });

    return array;
  }

  useEffect(() => {
    if (company !== '') {
      getGyejeongList(gyejeongStatus);
      getGyejeongGwamok(company);
      getDiff(company);
    }
  }, [selectedYear, selectedQuarter]);

  function calculateRatio(status) {
    let value1 = 0;
    let value2 = 0;
    let ratio = 0;

    gyejeongRow.forEach((item) => {
      if (status === '유동비율') {
        if (item['category'] === '유동자산') {
          value1 = item['value'];
        }

        if (item['category'] === '유동부채') {
          value2 = item['value'];
        }

        if (
          isNaN(value1 / value2) ||
          value1 / value2 === Infinity ||
          (value1 === 0 && value2 === 0)
        ) {
          ratio = 0;
        } else {
          ratio = (value1 / value2) * 100;
        }
      }

      if (status === '부채비율') {
        if (item['category'] === '부채총계') {
          value1 = item['value'];
        }

        if (item['category'] === '자본총계') {
          value2 = item['value'];
        }

        if (
          isNaN(value1 / value2) ||
          value1 / value2 === Infinity ||
          (value1 === 0 && value2 === 0)
        ) {
          ratio = 0;
        } else {
          ratio = (value1 / value2) * 100;
        }
      }

      if (status === '차입금의존도') {
        if (item['category'] === '차입금') {
          value1 = item['value'];
        }

        if (item['category'] === '자산총계') {
          value2 = item['value'];
        }

        if (
          isNaN(value1 / value2) ||
          value1 / value2 === Infinity ||
          (value1 === 0 && value2 === 0)
        ) {
          ratio = 0;
        } else {
          ratio = (value1 / value2) * 100;
        }
      }

      if (status === '영업이익대비 이자보상배율') {
        if (
          item['category'] === '영업이익' ||
          item['category'] === '영업손실'
        ) {
          if (item['value'] !== 0) {
            value1 = item['value'];
          }
        }

        if (item['category'] === '이자비용') {
          value2 = item['value'];
        }

        if (
          isNaN(value1 / value2) ||
          value1 / value2 === Infinity ||
          (value1 === 0 && value2 === 0)
        ) {
          ratio = 0;
        } else {
          ratio = -(value1 / value2);
        }
      }

      if (status === '매출액영업이익율') {
        if (
          item['category'] === '영업이익' ||
          item['category'] === '영업손실'
        ) {
          if (item['value'] !== 0) {
            value1 = item['value'];
          }
        }

        if (item['category'] === '매출액') {
          value2 = item['value'];
        }

        if (
          isNaN(value1 / value2) ||
          value1 / value2 === Infinity ||
          (value1 === 0 && value2 === 0)
        ) {
          ratio = 0;
        } else {
          ratio = -(value1 / value2) * 100;
        }
      }

      if (status === '매출액순이익율') {
        if (
          item['category'] === '당기순이익' ||
          item['category'] === '당기순손실'
        ) {
          if (item['value'] !== 0) {
            value1 = item['value'];
          }
        }

        if (item['category'] === '매출액') {
          value2 = item['value'];
        }

        if (
          isNaN(value1 / value2) ||
          value1 / value2 === Infinity ||
          (value1 === 0 && value2 === 0)
        ) {
          ratio = 0;
        } else {
          ratio = -(value1 / value2) * 100;
        }
      }

      if (status === '총자산순이익율') {
        if (
          item['category'] === '당기순이익' ||
          item['category'] === '당기순손실'
        ) {
          if (item['value'] !== 0) {
            value1 = item['value'];
          }
        }

        if (item['category'] === '자산총계') {
          value2 = item['value'];
        }

        if (
          isNaN(value1 / value2) ||
          value1 / value2 === Infinity ||
          (value1 === 0 && value2 === 0)
        ) {
          ratio = 0;
        } else {
          ratio = -(value1 / value2) * 100;
        }
      }

      if (status === '총자산대비영업현금흐름비율') {
        if (
          item['category'] === '영업이익' ||
          item['category'] === '영업손실'
        ) {
          if (item['value'] !== 0) {
            value1 = item['value'];
          }
        }

        if (item['category'] === '자산총계') {
          value2 = item['value'];
        }

        if (
          isNaN(value1 / value2) ||
          value1 / value2 === Infinity ||
          (value1 === 0 && value2 === 0)
        ) {
          ratio = 0;
        } else {
          ratio = -(value1 / value2) * 100;
        }
      }

      if (status === '최근년도 자산회전율') {
        if (item['category'] === '매출액') {
          value1 = item['value'];
        }

        if (item['category'] === '자산총계') {
          value2 = item['value'];
        }

        if (
          isNaN(value1 / value2) ||
          value1 / value2 === Infinity ||
          (value1 === 0 && value2 === 0)
        ) {
          ratio = 0;
        } else {
          ratio = value1 / value2;
        }
      }

      if (status === '건설기술개발투자비율') {
        if (item['category'] === '건설기술투자비') {
          value1 = item['value'];
        }

        if (item['category'] === '건설부분총매출') {
          value2 = item['value'];
        }

        if (
          isNaN(value1 / value2) ||
          value1 / value2 === Infinity ||
          (value1 === 0 && value2 === 0)
        ) {
          ratio = 0;
        } else {
          ratio = (value1 / value2) * 100;
        }
      }
    });

    return ratio;
  }

  async function getDiff(company) {
    setDiff({
      yudong: 0,
      buchae: 0,
      chaIp: 0,
      maechul: 0,
      jasanTurning: 0,
      techdev: 0,
      cashflow: 0,
      profit: 0,
      income: 0,
      alljasan: 0
    });

    const object = { ...diff };
    const resultObject = { ...result };

    const yudongOption = {
      url: 'https://cpi.contax.ai/management_score/calculate_yudongratio_diff_quarter',
      params: {
        company_name: company,
        quarter: selectedYear + '' + selectedQuarter
      },
      method: 'GET',
      header: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data'
      }
    };

    const yudongResultOption = {
      url: 'https://cpi.contax.ai/management_score/calculate_yudongratio_updown_quarter',
      params: {
        company_name: company,
        quarter: selectedYear + '' + selectedQuarter
      },
      method: 'GET',
      header: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data'
      }
    };

    try {
      const response = await yudongOption;

      if (
        response.status === 200 &&
        response.data.percent_diff !== 'Data insufficient'
      ) {
        object.yudong = response.data.percent_diff;
      }

      if (
        response.status === 200 &&
        response.data.percent_diff === 'Data insufficient'
      ) {
        object.yudong = 0;
      }
    } catch (error) {
      object.yudong = 0;
    }

    try {
      const response = await yudongResultOption;

      if (response.status === 200) {
        resultObject.yudong = response.data.result;
      }
    } catch (error) {
      resultObject.yudong = 'down';
    }

    const buchaeOption = {
      url: 'https://cpi.contax.ai/management_score/calculate_buchaeratio_diff_quarter',
      params: {
        company_name: company,
        quarter: selectedYear + '' + selectedQuarter
      },
      method: 'GET',
      header: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data'
      }
    };

    const buchaeResultOption = {
      url: 'https://cpi.contax.ai/management_score/calculate_buchaeratio_updown_quarter',
      params: {
        company_name: company,
        quarter: selectedYear + '' + selectedQuarter
      },
      method: 'GET',
      header: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data'
      }
    };

    try {
      const response = await buchaeOption;

      if (
        response.status === 200 &&
        response.data.percent_diff !== 'Data insufficient'
      ) {
        object.buchae = response.data.percent_diff;
      }

      if (
        response.status === 200 &&
        response.data.percent_diff === 'Data insufficient'
      ) {
        object.buchae = 0;
      }
    } catch (error) {
      object.buchae = 0;
    }

    try {
      const response = await buchaeResultOption;

      if (response.status === 200) {
        resultObject.buchae = response.data.result;
      }
    } catch (error) {
      resultObject.buchae = 'down';
    }

    const chaIpOption = {
      url: 'https://cpi.contax.ai/management_score/calculate_cha_ipratio_diff_quarter',
      params: {
        company_name: company,
        quarter: selectedYear + '' + selectedQuarter
      },
      method: 'GET',
      header: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data'
      }
    };

    const chaIpResultOption = {
      url: 'https://cpi.contax.ai/management_score/calculate_cha_ipratio_updown_quarter',
      params: {
        company_name: company,
        quarter: selectedYear + '' + selectedQuarter
      },
      method: 'GET',
      header: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data'
      }
    };

    try {
      const response = await chaIpOption;

      if (
        response.status === 200 &&
        response.data.percent_diff !== 'Data insufficient'
      ) {
        object.chaIp = response.data.percent_diff;
      }

      if (
        response.status === 200 &&
        response.data.percent_diff === 'Data insufficient'
      ) {
        object.chaIp = 0;
      }
    } catch (error) {
      object.chaIp = 0;
    }

    try {
      const response = await chaIpResultOption;

      if (response.status === 200) {
        resultObject.chaIp = response.data.result;
      }
    } catch (error) {
      resultObject.chaIp = 'down';
    }

    const maechulOption = {
      url: 'https://cpi.contax.ai/management_score/calculate_maechul_realinterest_ratio_diff_quarter',
      params: {
        company_name: company,
        quarter: selectedYear + '' + selectedQuarter
      },
      method: 'GET',
      header: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data'
      }
    };

    const maechulResultOption = {
      url: 'https://cpi.contax.ai/management_score/calculate_maechul_realinterest_ratio_updown_quarter',
      params: {
        company_name: company,
        quarter: selectedYear + '' + selectedQuarter
      },
      method: 'GET',
      header: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data'
      }
    };

    try {
      const response = await maechulOption;

      if (
        response.status === 200 &&
        response.data.percent_diff !== 'Data insufficient'
      ) {
        object.maechul = response.data.percent_diff;
      }

      if (
        response.status === 200 &&
        response.data.percent_diff === 'Data insufficient'
      ) {
        object.maechul = 0;
      }
    } catch (error) {
      object.maechul = 0;
    }

    try {
      const response = await maechulResultOption;

      if (response.status === 200) {
        resultObject.maechul = response.data.result;
      }
    } catch (error) {
      resultObject.maechul = 'down';
    }

    const jasanTurningOption = {
      url: 'https://cpi.contax.ai/management_score/calculate_jasan_turning_ratio_diff_quarter',
      params: {
        company_name: company,
        quarter: selectedYear + '' + selectedQuarter
      },
      method: 'GET',
      header: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data'
      }
    };

    const jasanTurningResultOption = {
      url: 'https://cpi.contax.ai/management_score/calculate_jasan_turning_ratio_updown_quarter',
      params: {
        company_name: company,
        quarter: selectedYear + '' + selectedQuarter
      },
      method: 'GET',
      header: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data'
      }
    };

    try {
      const response = await jasanTurningOption;

      if (
        response.status === 200 &&
        response.data.percent_diff !== 'Data insufficient'
      ) {
        object.jasanTurning = response.data.percent_diff;
      }

      if (
        response.status === 200 &&
        response.data.percent_diff === 'Data insufficient'
      ) {
        object.jasanTurning = 0;
      }
    } catch (error) {
      object.jasanTurning = 0;
    }

    try {
      const response = await jasanTurningResultOption;

      if (response.status === 200) {
        resultObject.jasanTurning = response.data.result;
      }
    } catch (error) {
      resultObject.jasanTurning = 'down';
    }

    const cashflowOption = {
      url: 'https://cpi.contax.ai/management_score/calculate_alljasan_sale_cashflow_ratio_diff_quarter',
      params: {
        company_name: company,
        quarter: selectedYear + '' + selectedQuarter
      },
      method: 'GET',
      header: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data'
      }
    };

    const cashflowResultOption = {
      url: 'https://cpi.contax.ai/management_score/calculate_alljasan_sale_cashflow_ratio_updown_quarter',
      params: {
        company_name: company,
        quarter: selectedYear + '' + selectedQuarter
      },
      method: 'GET',
      header: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data'
      }
    };

    try {
      const response = await cashflowOption;

      if (
        response.status === 200 &&
        response.data.percent_diff !== 'Data insufficient'
      ) {
        object.cashflow = response.data.percent_diff;
      }

      if (
        response.status === 200 &&
        response.data.percent_diff === 'Data insufficient'
      ) {
        object.cashflow = 0;
      }
    } catch (error) {
      object.cashflow = 0;
    }

    try {
      const response = await cashflowResultOption;

      if (response.status === 200) {
        resultObject.cashflow = response.data.result;
      }
    } catch (error) {
      resultObject.cashflow = 'down';
    }

    const techdevOption = {
      url: 'https://cpi.contax.ai/management_score/calculate_construct_techdev_invest_ratio_diff_quarter',
      params: {
        company_name: company,
        quarter: selectedYear + '' + selectedQuarter
      },
      method: 'GET',
      header: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data'
      }
    };

    const techdevResultOption = {
      url: 'https://cpi.contax.ai/management_score/calculate_construct_techdev_invest_ratio_updown_quarter',
      params: {
        company_name: company,
        quarter: selectedYear + '' + selectedQuarter
      },
      method: 'GET',
      header: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data'
      }
    };

    try {
      const response = await techdevOption;

      if (
        response.status === 200 &&
        response.data.percent_diff !== 'Data insufficient'
      ) {
        object.techdev = response.data.percent_diff;
      }

      if (
        response.status === 200 &&
        response.data.percent_diff === 'Data insufficient'
      ) {
        object.techdev = 0;
      }
    } catch (error) {
      object.techdev = 0;
    }

    try {
      const response = await techdevResultOption;

      if (response.status === 200) {
        resultObject.techdev = response.data.result;
      }
    } catch (error) {
      resultObject.techdev = 'down';
    }

    const alljasanOption = {
      url: 'https://cpi.contax.ai/management_score/calculate_alljasan_realinterest_ratio_diff_quarter',
      params: {
        company_name: company,
        quarter: selectedYear + '' + selectedQuarter
      },
      method: 'GET',
      header: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data'
      }
    };

    const alljasanResultOption = {
      url: 'https://cpi.contax.ai/management_score/calculate_alljasan_realinterest_ratio_updown_quarter',
      params: {
        company_name: company,
        quarter: selectedYear + '' + selectedQuarter
      },
      method: 'GET',
      header: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data'
      }
    };

    try {
      const response = await alljasanOption;

      if (
        response.status === 200 &&
        response.data.percent_diff !== 'Data insufficient'
      ) {
        object.alljasan = response.data.percent_diff;
      }

      if (
        response.status === 200 &&
        response.data.percent_diff === 'Data insufficient'
      ) {
        object.alljasan = 0;
      }
    } catch (error) {
      object.alljasan = 0;
    }

    try {
      const response = await alljasanResultOption;

      if (response.status === 200) {
        resultObject.alljasan = response.data.result;
      }
    } catch (error) {
      resultObject.alljasan = 'down';
    }

    const profitOption = {
      url: 'https://cpi.contax.ai/management_score/calculate_operating_profit_interest_ratio_diff_quarter',
      params: {
        company_name: company,
        quarter: selectedYear + '' + selectedQuarter
      },
      method: 'GET',
      header: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data'
      }
    };

    const profitResultOption = {
      url: 'https://cpi.contax.ai/management_score/calculate_operating_profit_interest_ratio_updown_quarter',
      params: {
        company_name: company,
        quarter: selectedYear + '' + selectedQuarter
      },
      method: 'GET',
      header: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data'
      }
    };

    try {
      const response = await profitOption;

      if (
        response.status === 200 &&
        response.data.percent_diff !== 'Data insufficient'
      ) {
        object.profit = response.data.percent_diff;
      }

      if (
        response.status === 200 &&
        response.data.percent_diff === 'Data insufficient'
      ) {
        object.profit = 0;
      }
    } catch (error) {
      object.alljasan = 0;
    }

    try {
      const response = await profitResultOption;

      if (response.status === 200) {
        resultObject.profit = response.data.result;
      }
    } catch (error) {
      resultObject.alljasan = 'down';
    }

    const incomeOption = {
      url: 'https://cpi.contax.ai/management_score/calculate_operating_income_margin_ratio_diff_quarter',
      params: {
        company_name: company,
        quarter: selectedYear + '' + selectedQuarter
      },
      method: 'GET',
      header: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data'
      }
    };

    const incomeResultOption = {
      url: 'https://cpi.contax.ai/management_score/calculate_operating_income_margin_ratio_updown_quarter',
      params: {
        company_name: company,
        quarter: selectedYear + '' + selectedQuarter
      },
      method: 'GET',
      header: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data'
      }
    };

    try {
      const response = await incomeOption;

      if (
        response.status === 200 &&
        response.data.percent_diff !== 'Data insufficient'
      ) {
        object.income = response.data.percent_diff;
      }

      if (
        response.status === 200 &&
        response.data.percent_diff === 'Data insufficient'
      ) {
        object.income = 0;
      }
    } catch (error) {
      object.income = 0;
    }

    try {
      const response = await incomeResultOption;

      if (response.status === 200) {
        resultObject.income = response.data.result;
      }
    } catch (error) {
      resultObject.income = 'down';
    }

    setDiff(object);
    setResult(resultObject);
  }

  async function getQuarter(company) {
    const option = {
      url: 'https://cpi.contax.ai/get_quarter',
      params: {
        company: company
      },
      method: 'GET',
      header: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data'
      }
    };

    await axios(option).then((response) => {
      setUploadedQuarter(response.data[1]);
    });
  }

  async function getComment(pyeongjeom) {
    const id = localStorage.getItem('id');

    const option = {
      url: 'https://cpi.contax.ai/load_tax_manager_comment',
      params: {
        writter_id: id,
        pyeongjeom: pyeongjeom,
        target_user_id: targetId,
        quarter: selectedYear + '' + selectedQuarter
      },
      method: 'GET',
      header: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data'
      }
    };

    await axios(option).then((response) => {
      console.log(response.data);
    });
  }

  const [expertComment, setExpertComment] = useState('');
  const [commentList, setCommentList] = useState({
    comment_1: '',
    comment_2: ''
  });

  async function getExpertComment(pyeongjeom) {
    const id = localStorage.getItem('id');

    const option = {
      url: 'https://cpi.contax.ai/load_expert_comment',
      params: {
        writter_id: id,
        target_id: targetId,
        quarter: selectedYear + '' + selectedQuarter,
        pyeongjeom: pyeongjeom
      },
      method: 'GET',
      header: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data'
      }
    };

    await axios(option).then((response) => {
      if (response.data.length > 0) {
        setExpertComment(response.data[0].overall_comment);
        setCommentList({
          comment_1: response.data[0].comment_1,
          comment_2: response.data[0].comment_2
        });
      } else {
        setExpertComment('');
        setCommentList({
          comment_1: '',
          comment_2: ''
        });
      }
    });
  }

  const [selectedPyeongjeom, setSelectedPyeongjeom] = useState('');

  return (
    <>
      {modalOn.on === true && modalOn.division === 'gyejeong' && (
        <GyejeongModal
          uploadedQuarter={uploadedQuarter}
          expression={expression}
          gyejeongRow={
            selectedPyeongjeom === '실질자본금' ? siljil : gyejeongRow
          }
          title={selectedPyeongjeom}
          setModalOn={() => {
            dispatch(closeReportEmailModal(modalOn.division));
          }}
        />
      )}

      {modalOn.on === true && modalOn.division === 'report' && (
        <ReportModal
          setCommentList={(params) => setCommentList(params)}
          commentList={commentList}
          expertComment={expertComment}
          insertComment={(comment, title) => {
            insertComment(comment, title);
          }}
          expression={expression}
          gyejeongRow={
            selectedPyeongjeom === '실질자본금' ? siljil : gyejeongRow
          }
          title={selectedPyeongjeom}
          setModalOn={() => {
            dispatch(closeReportEmailModal(modalOn.division));
          }}
          selectedQuarter={selectedQuarter}
          selectedYear={selectedYear}
          reportList={reportList}
        />
      )}

      {modalOn.on === true && modalOn.division === 'overall' && (
        <OverallModal
          comment={expertComment}
          targetName={targetName}
          targetId={targetId}
          quarter={selectedYear + '' + selectedQuarter}
          gyejeongRow={gyejeongRow}
          title={selectedPyeongjeom}
          setModalOn={() => {
            dispatch(closeReportEmailModal(modalOn.division));
          }}
          insertReview={(comment) => {
            insertComment(comment, '총평');
          }}
          loadReview={() => {
            getExpertComment('총평');
          }}
        />
      )}

      {/* <div style={{position:'absolute'}}>
                <PyeongjeomAverage year="2022" />
            </div> */}

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TitleCard
            title="보고서 이메일 전송"
            previous={
              pageStatus === 'write' &&
              (() => {
                dispatch(updatePageStatus('select'));
              })
            }
          >
            {pageStatus === 'select' && (
              <>
                <DetailTable
                  size="large"
                  col={Grid_COL}
                  sortFunction={() => {
                    console.log('sort');
                  }}
                  datas={assignmentData}
                  contents={
                    <>
                      <div
                        style={{
                          marginBottom: '10px',
                          fontWeight: 'bold',
                          fontSize: '18px'
                        }}
                      >
                        이메일 포함시킬 보고서를 선택하세요
                      </div>
                      <SelectQuarterSection>
                        <select
                          style={{
                            width: '95px',
                            fontWeight: 'bold',
                            fontSize: '20px'
                          }}
                          value={selectedYear}
                          onChange={(e) => {
                            getGyejeongList(gyejeongStatus);
                            setSelectedYear(e.target.value);
                          }}
                          defaultValue="2022"
                        >
                          <option value="2022">2022년</option>
                          <option value="2023">2023년</option>
                          <option value="2024">2024년</option>
                        </select>

                        <select
                          style={{
                            width: '95px',
                            fontWeight: 'bold',
                            fontSize: '20px'
                          }}
                          value={selectedQuarter}
                          onChange={(e) => {
                            getGyejeongList(gyejeongStatus);
                            setSelectedQuarter(e.target.value);
                          }}
                          defaultValue="01"
                        >
                          <option value="01">1분기</option>
                          <option value="02">2분기</option>
                          <option value="03">3분기</option>
                          <option value="04">4분기</option>
                        </select>

                        <span
                          style={{
                            color: '#1976d2',
                            cursor: 'pointer'
                          }}
                          className="material-symbols-outlined"
                        >
                          info
                        </span>

                        <span
                          style={{
                            fontSize: '14px',
                            color: '#555'
                          }}
                        >
                          최근 업로드 :{' '}
                          {(uploadedQuarter === 'None'
                            ? 2022
                            : uploadedQuarter.substring(0, 4)) +
                            '년 ' +
                            (uploadedQuarter === 'None'
                              ? 1
                              : uploadedQuarter.substring(5, 6)) +
                            '분기'}
                        </span>
                      </SelectQuarterSection>

                      {console.log(reportList)}

                      <ExpertPropertySection>
                        <SelectableExpertProperty
                          active={selectedPyeongjeom === '유동비율'}
                          onClick={() => {
                            setSelectedPyeongjeom('유동비율');
                            reportList.forEach((item) => {
                              if (
                                item.title.includes('유동비율') &&
                                item.title !== ''
                              ) {
                                dispatch(updateSelectedReport(item));
                              }
                            });
                          }}
                          setModalOn={(division) => {
                            dispatch(openReportEmailModal('report'));
                            setSelectedPyeongjeom('유동비율');

                            if (division === 'comment') {
                              getComment('유동비율');
                            }
                          }}
                          ratio={calculateRatio('유동비율')}
                          diff={diff.yudong}
                          result={result.yudong}
                          getExpertComment={(params) => {
                            getExpertComment(params);
                          }}
                          name="유동비율"
                          setGyejeongStatus={(params) => {
                            setGyejeongStatus(params);
                          }}
                        />
                        <SelectableExpertProperty
                          active={selectedPyeongjeom === '부채비율'}
                          onClick={() => {
                            setSelectedPyeongjeom('부채비율');
                            reportList.forEach((item) => {
                              if (
                                item.title.includes('부채비율') &&
                                item.title !== ''
                              ) {
                                dispatch(updateSelectedReport(item));
                              }
                            });
                          }}
                          setModalOn={(division) => {
                            dispatch(openReportEmailModal('report'));
                            setSelectedPyeongjeom('부채비율');

                            if (division === 'comment') {
                              getComment('부채비율');
                            }
                          }}
                          ratio={calculateRatio('부채비율')}
                          result={result.buchae}
                          setGyejeongStatus={(params) => {
                            setGyejeongStatus(params);
                          }}
                          diff={diff.buchae}
                          name="부채비율"
                          getExpertComment={(params) => {
                            getExpertComment(params);
                          }}
                        />

                        <SelectableExpertProperty
                          active={selectedPyeongjeom === '차입금의존도'}
                          onClick={() => {
                            setSelectedPyeongjeom('차입금의존도');
                            reportList.forEach((item) => {
                              if (
                                item.title.includes('차입금의존도') &&
                                item.title !== ''
                              ) {
                                dispatch(updateSelectedReport(item));
                              }
                            });
                          }}
                          setModalOn={(division) => {
                            dispatch(openReportEmailModal('report'));
                            setSelectedPyeongjeom('차입금의존도');

                            if (division === 'comment') {
                              getComment('차입금의존도');
                            }
                          }}
                          ratio={calculateRatio('차입금의존도')}
                          result={result.chaIp}
                          diff={diff.chaIp}
                          getExpertComment={(params) => {
                            getExpertComment(params);
                          }}
                          setGyejeongStatus={(params) => {
                            setGyejeongStatus(params);
                          }}
                          name="차입금의존도"
                        />

                        <SelectableExpertProperty
                          active={
                            selectedPyeongjeom === '영업이익대비 이자보상배율'
                          }
                          onClick={() => {
                            setSelectedPyeongjeom('영업이익대비 이자보상배율');
                            reportList.forEach((item) => {
                              if (
                                item.title.includes(
                                  '영업이익대비 이자보상배율'
                                ) &&
                                item.title !== ''
                              ) {
                                dispatch(updateSelectedReport(item));
                              }
                            });
                          }}
                          setModalOn={(division) => {
                            dispatch(openReportEmailModal('report'));
                            setSelectedPyeongjeom('영업이익대비 이자보상배율');
                            if (division === 'comment') {
                              getComment('영업이익대비 이자보상배율');
                            }
                          }}
                          ratio={calculateRatio('영업이익대비 이자보상배율')}
                          diff={diff.profit}
                          result={result.profit}
                          getExpertComment={(params) => {
                            getExpertComment(params);
                          }}
                          setGyejeongStatus={(params) => {
                            setGyejeongStatus(params);
                          }}
                          name="영업이익대비 이자보상배율"
                        />

                        <SelectableExpertProperty
                          active={selectedPyeongjeom === '매출액영업이익율'}
                          onClick={() => {
                            setSelectedPyeongjeom('매출액영업이익율');
                            reportList.forEach((item) => {
                              if (
                                item.title.includes('매출액영업이익율') &&
                                item.title !== ''
                              ) {
                                dispatch(updateSelectedReport(item));
                              }
                            });
                          }}
                          setModalOn={(division) => {
                            dispatch(openReportEmailModal('report'));
                            setSelectedPyeongjeom('매출액영업이익율');
                            if (division === 'comment') {
                              getComment('매출액영업이익율');
                            }
                          }}
                          ratio={calculateRatio('매출액영업이익율')}
                          diff={diff.maechul}
                          result={result.maechul}
                          getExpertComment={(params) => {
                            getExpertComment(params);
                          }}
                          name="매출액영업이익율"
                          setGyejeongStatus={(params) => {
                            setGyejeongStatus(params);
                          }}
                        />

                        <SelectableExpertProperty
                          active={selectedPyeongjeom === '매출액순이익율'}
                          onClick={() => {
                            setSelectedPyeongjeom('매출액순이익율');
                            reportList.forEach((item) => {
                              if (
                                item.title.includes('매출액순이익율') &&
                                item.title !== ''
                              ) {
                                dispatch(updateSelectedReport(item));
                              }
                            });
                          }}
                          setModalOn={(division) => {
                            dispatch(openReportEmailModal('report'));
                            setSelectedPyeongjeom('매출액순이익율');

                            if (division === 'comment') {
                              getComment('매출액순이익율');
                            }
                          }}
                          ratio={calculateRatio('매출액순이익율')}
                          diff={diff.income}
                          result={result.income}
                          getExpertComment={(params) => {
                            getExpertComment(params);
                          }}
                          setGyejeongStatus={(params) => {
                            setGyejeongStatus(params);
                          }}
                          name="매출액순이익율"
                        />
                        <SelectableExpertProperty
                          active={selectedPyeongjeom === '총자산순이익율'}
                          onClick={() => {
                            setSelectedPyeongjeom('총자산순이익율');
                            reportList.forEach((item) => {
                              if (
                                item.title.includes('총자산순이익율') &&
                                item.title !== ''
                              ) {
                                dispatch(updateSelectedReport(item));
                              }
                            });
                          }}
                          setModalOn={(division) => {
                            dispatch(openReportEmailModal('report'));
                            setSelectedPyeongjeom('총자산순이익율');

                            if (division === 'comment') {
                              getComment('총자산순이익율');
                            }
                          }}
                          ratio={calculateRatio('총자산순이익율')}
                          name="총자산순이익율"
                          diff={diff.alljasan}
                          result={result.alljasan}
                          getExpertComment={(params) => {
                            getExpertComment(params);
                          }}
                          setGyejeongStatus={(params) => {
                            setGyejeongStatus(params);
                          }}
                        />

                        <SelectableExpertProperty
                          active={
                            selectedPyeongjeom === '총자산대비영업현금흐름비율'
                          }
                          onClick={() => {
                            setSelectedPyeongjeom('총자산대비영업현금흐름비율');
                            reportList.forEach((item) => {
                              if (
                                item.title.includes(
                                  '총자산대비영업현금흐름비율'
                                ) &&
                                item.title !== ''
                              ) {
                                dispatch(updateSelectedReport(item));
                              }
                            });
                          }}
                          setModalOn={(division) => {
                            dispatch(openReportEmailModal('report'));
                            setSelectedPyeongjeom('총자산대비영업현금흐름비율');

                            if (division === 'comment') {
                              getComment('총자산대비영업현금흐름비율');
                            }
                          }}
                          ratio={calculateRatio('총자산대비영업현금흐름비율')}
                          diff={diff.cashflow}
                          result={result.cashflow}
                          getExpertComment={(params) => {
                            getExpertComment(params);
                          }}
                          name="총자산대비영업현금흐름비율"
                          setGyejeongStatus={(params) => {
                            setGyejeongStatus(params);
                          }}
                        />

                        <SelectableExpertProperty
                          active={selectedPyeongjeom === '최근년도 자산회전율'}
                          onClick={() => {
                            setSelectedPyeongjeom('최근년도 자산회전율');
                            reportList.forEach((item) => {
                              if (
                                item.title.includes('최근년도 자산회전율') &&
                                item.title !== ''
                              ) {
                                dispatch(updateSelectedReport(item));
                              }
                            });
                          }}
                          setModalOn={(division) => {
                            dispatch(openReportEmailModal('report'));
                            setSelectedPyeongjeom('최근년도 자산회전율');

                            if (division === 'comment') {
                              getComment('최근년도 자산회전율');
                            }
                          }}
                          ratio={calculateRatio('최근년도 자산회전율')}
                          diff={diff.jasanTurning}
                          result={result.jasanTurning}
                          getExpertComment={(params) => {
                            getExpertComment(params);
                          }}
                          name="최근년도 자산회전율"
                          setGyejeongStatus={(params) => {
                            setGyejeongStatus(params);
                          }}
                        />

                        <SelectableExpertProperty
                          active={selectedPyeongjeom === '건설기술개발투자비율'}
                          onClick={() => {
                            setSelectedPyeongjeom('건설기술개발투자비율');
                            reportList.forEach((item) => {
                              if (
                                item.title.includes('건설기술개발투자비율') &&
                                item.title !== ''
                              ) {
                                dispatch(updateSelectedReport(item));
                              }
                            });
                          }}
                          setModalOn={(division) => {
                            dispatch(openReportEmailModal('report'));
                            setSelectedPyeongjeom('건설기술개발투자비율');

                            if (division === 'comment') {
                              getComment('건설기술개발투자비율');
                            }
                          }}
                          ratio={calculateRatio('건설기술개발투자비율')}
                          diff={diff.techdev}
                          name="건설기술개발투자비율"
                          getExpertComment={(params) => {
                            getExpertComment(params);
                          }}
                          result={result.techdev}
                          setGyejeongStatus={(params) => {
                            setGyejeongStatus(params);
                          }}
                        />
                      </ExpertPropertySection>
                      <div style={{ display: 'flex', justifyContent: 'end' }}>
                        <ManagerButton
                          onClick={() => {
                            dispatch(updatePageStatus('write'));
                          }}
                          width="120px"
                        >
                          이메일 작성
                        </ManagerButton>
                      </div>
                    </>
                  }
                  updateOpenStatus={async (index) => {
                    const array = [...assignmentData];
                    array.forEach((item) => {
                      item.open = false;
                    });

                    if (array[index].open === false) {
                      array[index].open = true;
                    }

                    dispatch(
                      updateSelectedMember({
                        email: array[index].contents[1].value,
                        name: array[index].contents[5].value
                      })
                    );

                    setTargetName(array[index].contents[5].value);
                    setTargetId(array[index].contents[0].value);
                    setCompany(array[index].contents[4].value);
                    await getDiff(array[index].contents[4].value);
                    await getGyejeongGwamok(array[index].contents[4].value);
                    await getAnalyReport(array[index].contents[1].value);
                    await getQuarter(array[index].contents[4].value);
                    setAssignmentData(array);
                  }}
                />
                <BoardPagenation
                  offset={offset}
                  pageNumbers={pageNumbers}
                  pageLength={pageLength}
                  setOffset={(offset) => dispatch(updateOffset(offset))}
                />
              </>
            )}

            {pageStatus === 'write' && <ReportEmailForm isSender={false} />}
          </TitleCard>
        </Grid>
      </Grid>
    </>
  );
}

export default ReportSendEmail;
