import { Input } from '@mui/material';
import React, { ChangeEvent, ReactElement } from 'react';
import styled from 'styled-components';

const St_RowDetailElement = styled.span`
  font-size: 15px;
  font-weight: bold;
`;

interface PropTypes {
  title: string;
  children: ReactElement;
}

export function SalesDetailElement(props: PropTypes) {
  return (
    <div style={{ display: 'flex', paddingBottom: '10px' }}>
      <div style={{ width: '30%' }}>
        <St_RowDetailElement>{props.title} </St_RowDetailElement>
      </div>
      <div style={{ width: '70%' }}>
        <St_RowDetailElement>{props.children}</St_RowDetailElement>
      </div>
    </div>
  );
}

interface TextParams {
  text: string;
}

export function DetailText(props: TextParams) {
  return <>{props.text}</>;
}

interface InputTypes {
  id: string;
  data: any;
  target: string;
  placeholder: string;
  onChange: (e: ChangeEvent) => void;
  onBlur: (
    id: string,
    value: string,
    unique_key: string,
    target: string,
  ) => void;
}

function DetailInput(props: InputTypes) {
  return (
    <Input
      placeholder={props.placeholder}
      sx={{ fontSize: '15px' }}
      value={props.data[props.id] === null ? '' : props.data[props.id]}
      onChange={props.onChange}
      onBlur={() => {
        props.onBlur(
          props.id,
          props.data[props.id],
          props.data.unique_key,
          props.target,
        );
      }}
    />
  );
}

SalesDetailElement.Input = DetailInput;
SalesDetailElement.Text = DetailText;
