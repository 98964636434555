import TitleCard from '../../components/Cards/TitleCard';
import { Grid, Stack, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import {
  ColProps,
  ExpertProperty,
  ManagerGrid,
  ManagerGridButton,
  ManagerGridDetailSection,
  ManagerGridRow,
  ManagerGridRowSection
} from '../../components/GridType';
import { useDispatch, useSelector } from 'react-redux';
import { StoreState } from '../../app/store';
import {
  MemberType,
  resetCreateMemberInformation,
  resetTargetMember,
  updateExpression,
  updateGyejeongGwamok,
  updateMemberPageStatus,
  updateOriginalTargetMember,
  updatePageLength,
  updatePageNumbers,
  updateSelectedQuarter,
  updateSelectedYear,
  updateTab,
  updateTargetMember,
  updateUser,
  UserDivisionType
} from '../../app/slice/memberManagementSlice';
import styled from 'styled-components';
import BoardSearchHeader from '../../components/BoardSearchHeader';
import {
  calculatePagination,
  createPaginationSize
} from '../../utils/common_API';
import BoardPagenation from '../../components/BoardPagenation';
import axios from 'axios';
import {
  alljasanCurrentRatio,
  alljasanRatio,
  alljasanResult,
  buchaeCurrentRatio,
  buchaeRatio,
  buchaeResult,
  cashflowCurrentRatio,
  cashflowRatio,
  cashflowResult,
  chaIpCurrentRatio,
  chaIpRatio,
  chaIpResult,
  getAnalyReport,
  getJaemuGyejeong,
  getQuarter,
  getSiljilGyejeong,
  getSonikGyejeong,
  incomeCurrentRatio,
  incomeRatio,
  incomeResult,
  jasanTurningCurrentRatio,
  jasanTurningRatio,
  jasanTurningResult,
  maechulCurrentRatio,
  maechulRatio,
  maechulResult,
  ParamsType,
  profitCurrentRatio,
  profitRatio,
  profitResult,
  techdevCurrentRatio,
  techdevRatio,
  techdevResult,
  updateQuarter,
  yudongCurrentRatio,
  yudongRatio,
  yudongResult
} from '../../utils/jasan_API';
import {
  GyejeongModal,
  OverallModal,
  ReportModal,
  SiljilModal
} from '../../components/ModalComponents';
import {
  CommentListType,
  GyejeongType,
  SiljilType
} from '../../interface/jasanInterface';
import { MemberDetailSection } from './components/MemberDetailSection';
import Button from '@mui/material/Button';
import { CreateSection } from './components/CreateSection';
import { PencilIcon } from '@heroicons/react/16/solid';
import { TabButton } from '../../components/Layout/TabButton';
import { searchMembers } from './tools/searchMembers';
import { getMemberManager } from '../../utils/user_API';
import CheckCircleIcon from '@heroicons/react/24/solid/CheckCircleIcon';
import { MinusCircleIcon, PauseCircleIcon } from '@heroicons/react/24/outline';
import { PerformanceTable } from './components/PerformanceTable';

const SelectQuarterSection = styled.div`
  display: flex;
  gap: 20px;
  align-items: end;
`;
const St_PyeongjeomProperty = styled.div`
  width: 194px;
  height: 205px;
  border: 1px solid #d7d7d7;
  border-radius: 5px;
  padding: 14px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background-color: #eee;
  }
`;

const St_PyeongjeomPropertyName = styled.div`
  font-size: 20px;
  font-weight: bold;
  display: flex;
  gap: 3px;
  align-items: center;
`;

const St_MemberWrapper = styled.div`
  margin-bottom: 60px;
  display: flex;
  align-items: center;
  gap: 40px;
`;

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1
});

export interface SearchQueryType {
  business: {
    category: string;
    query: string;
  };

  first: {
    category: string;
    query: string;
  };

  contax: {
    category: string;
    query: string;
  };
}

const Grid_COL: ColProps[] = [
  {
    title: '이름',
    id: 'name',
    width: 10,
    textAlign: 'left',
    sort: true,
    search: true
  },
  {
    title: '이메일',
    id: 'm.email',
    width: 20,
    textAlign: 'left',
    sort: true,
    search: true
  },
  {
    title: '회사명',
    id: 'company_name',
    width: 15,
    textAlign: 'left',
    sort: true,
    search: true
  },
  {
    title: '전화번호',
    id: 'phone_number',
    width: 15,
    textAlign: 'left',
    sort: true,
    search: true
  },
  {
    title: '가입 날짜',
    id: 'reg_date',
    width: 15,
    textAlign: 'left',
    sort: true,
    search: false
  },
  {
    title: '상세보기',
    id: '',
    width: 15,
    textAlign: 'center',
    sort: false,
    search: false
  },

  {
    title: '로그인',
    id: '',
    width: 10,
    textAlign: 'center',
    sort: false,
    search: false
  }
];

const ExpertPropertySection = styled.div`
  margin-top: 22px;
  display: flex;
  flex-wrap: wrap;
  gap: 50px;
`;

function MemberManagement() {
  const dispatch = useDispatch();
  const {
    expression,
    gyejeongGwamok,
    status,
    selectedQuarter,
    selectedYear,
    users,
    user_total_count,
    page_length,
    page_numbers,
    tab
  } = useSelector((state: StoreState) => state.memberManagement);

  const jaemuRef = useRef(null);
  const sonikRef = useRef(null);
  const quarterEndMonths = {
    '01': '03/31',
    '02': '06/30',
    '03': '09/30',
    '04': '12/31'
  };

  const [expertComment, setExpertComment] = useState('');
  const [commentList, setCommentList] = useState<CommentListType>({
    comment_1: '',
    comment_2: ''
  });

  const [reportList, setReportList] = useState([]);
  const [open, setOpen] = useState({ index: 0, open: false });
  const [uploadedQuarter, setUploadedQuarter] = useState('');
  const [company, setCompany] = useState('');
  const [modalOn, setModalOn] = useState({ division: '', on: false });
  const [selectedPyeongjeom, setSelectedPyeongjeom] = useState('');
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(10);

  const [siljil, setSiljil] = useState<SiljilType>({
    company_name: '',
    real_capital: 0,
    unrecognized_assets_sum: 0,
    recognized_assets: 0,
    total_assets: 0,
    capital_total: 0,
    total_dept: 0
  });
  const [targetName, setTargetName] = useState<string>('');
  const [targetId, setTargetId] = useState(0);
  const [, setGyejeongStatus] = useState('');

  function changeSelectedQuarter(value: '01' | '02' | '03' | '04') {
    dispatch(updateSelectedQuarter(value));
  }

  function changeSelectedYear(value: string) {
    dispatch(updateSelectedYear(value));
  }

  useEffect(() => {
    dispatch(resetTargetMember());
  }, []);

  useEffect(() => {
    dispatch(resetTargetMember());
    setOpen({ index: 0, open: false });
  }, [tab]);

  const [diff, setDiff] = useState({
    yudong: 0,
    buchae: 0,
    chaIp: 0,
    maechul: 0,
    jasanTurning: 0,
    techdev: 0,
    cashflow: 0,
    profit: 0,
    income: 0,
    alljasan: 0
  });

  const [ratio, setRatio] = useState({
    yudong: 0,
    buchae: 0,
    chaIp: 0,
    maechul: 0,
    jasanTurning: 0,
    techdev: 0,
    cashflow: 0,
    profit: 0,
    income: 0,
    alljasan: 0
  });

  const [result, setResult] = useState({
    yudong: 'down',
    buchae: 'down',
    chaIp: 'down',
    maechul: 'down',
    jasanTurning: 'down',
    techdev: 'down',
    cashflow: 'down',
    profit: 'down',
    income: 'down',
    alljasan: 'down'
  });

  const [searchQuery, setSearchQuery] = useState<SearchQueryType>({
    business: {
      category: 'name',
      query: ''
    },

    first: {
      category: 'name',
      query: ''
    },

    contax: {
      category: 'name',
      query: ''
    }
  });

  const [sort, setSort] = useState<UserDivisionType>({
    business: {
      option: 'reg_date',
      sort: 'DESC'
    },

    first: {
      option: 'reg_date',
      sort: 'DESC'
    },

    contax: {
      option: 'reg_date',
      sort: 'DESC'
    }
  });

  useEffect(() => {
    if (company !== '') {
      getDiff(company, selectedYear, selectedQuarter);
      loadGyejeongGwamok(company, selectedYear, selectedQuarter);
      loadSiljilGwamok(company, selectedYear, selectedQuarter);
    }
  }, [selectedYear, selectedQuarter]);

  async function getComment(pyeongjeom: string) {
    const id = localStorage.getItem('id');

    const option = {
      url: 'https://cpi.contax.ai/load_tax_manager_comment',
      params: {
        writter_id: id,
        pyeongjeom: pyeongjeom,
        target_user_id: targetId,
        quarter: selectedYear + '' + selectedQuarter
      },
      method: 'GET',
      header: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data'
      }
    };

    await axios(option).then(() => {
      return;
    });
  }

  async function getDiff(
    company: string,
    selectedYear: string,
    selectedQuarter: string
  ) {
    setRatio({
      yudong: 0,
      buchae: 0,
      chaIp: 0,
      maechul: 0,
      jasanTurning: 0,
      techdev: 0,
      cashflow: 0,
      profit: 0,
      income: 0,
      alljasan: 0
    });

    setDiff({
      yudong: 0,
      buchae: 0,
      chaIp: 0,
      maechul: 0,
      jasanTurning: 0,
      techdev: 0,
      cashflow: 0,
      profit: 0,
      income: 0,
      alljasan: 0
    });

    setResult({
      yudong: 'down',
      buchae: 'down',
      chaIp: 'down',
      maechul: 'down',
      jasanTurning: 'down',
      techdev: 'down',
      cashflow: 'down',
      profit: 'down',
      income: 'down',
      alljasan: 'down'
    });

    const params: ParamsType = {
      company: company,
      selectedYear: selectedYear,
      selectedQuarter: selectedQuarter
    };

    const object = {
      yudong: await yudongRatio(params),
      buchae: await buchaeRatio(params),
      chaIp: await chaIpRatio(params),
      maechul: await maechulRatio(params),
      jasanTurning: await jasanTurningRatio(params),
      techdev: await techdevRatio(params),
      cashflow: await cashflowRatio(params),
      profit: await profitRatio(params),
      income: await incomeRatio(params),
      alljasan: await alljasanRatio(params)
    };

    const resultObject = {
      yudong: await yudongResult(params),
      buchae: await buchaeResult(params),
      chaIp: await chaIpResult(params),
      maechul: await maechulResult(params),
      jasanTurning: await jasanTurningResult(params),
      techdev: await techdevResult(params),
      cashflow: await cashflowResult(params),
      profit: await profitResult(params),
      income: await incomeResult(params),
      alljasan: await alljasanResult(params)
    };

    const ratioObject = {
      yudong: await yudongCurrentRatio(params),
      buchae: await buchaeCurrentRatio(params),
      chaIp: await chaIpCurrentRatio(params),
      maechul: await maechulCurrentRatio(params),
      jasanTurning: await jasanTurningCurrentRatio(params),
      techdev: await techdevCurrentRatio(params),
      cashflow: await cashflowCurrentRatio(params),
      profit: await profitCurrentRatio(params),
      income: await incomeCurrentRatio(params),
      alljasan: await alljasanCurrentRatio(params)
    };

    setDiff(object);
    setResult(resultObject);
    setRatio(ratioObject);
  }

  async function loadGyejeongGwamok(
    business_number: string,
    selectedYear: string,
    selectedQuarter: '01' | '02' | '03' | '04'
  ) {
    const array: GyejeongType[] = [];
    const jaemuResponse = await getJaemuGyejeong(business_number);
    const quarterEndDate = `${selectedYear}/${quarterEndMonths[selectedQuarter]}`;

    jaemuResponse.forEach((element: GyejeongType) => {
      if (element.period.endsWith(quarterEndDate)) {
        array.push(element);
      }
    });

    const sonikResponse = await getSonikGyejeong(business_number);

    sonikResponse.forEach((element: GyejeongType) => {
      if (element.period.endsWith(quarterEndDate)) {
        array.push(element);
      }
    });

    dispatch(updateGyejeongGwamok(filteringDuplicate(array)));
  }

  async function loadSiljilGwamok(
    company: string,
    selectedYear: string,
    selectedQuarter: string
  ) {
    const response: SiljilType = await getSiljilGyejeong(
      company,
      selectedYear + '' + selectedQuarter
    );

    setSiljil(response);
  }

  async function uploadSonikFile(
    e: React.ChangeEvent<HTMLInputElement>,
    selectedYear: string,
    selectedQuarter: '01' | '02' | '03' | '04'
  ) {
    const target = e.target as HTMLInputElement;
    const file: File = (target.files as FileList)[0];
    const formData = new FormData();
    formData.append('file', file);

    const option = {
      url: 'https://cpi.contax.ai/sonik_excel_upload_no_quarter',
      params: {
        company_name: company
      },
      data: formData,
      method: 'POST',
      header: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data'
      }
    };

    try {
      const response = await axios(option);
      const period = response.data.most_recent_period;
      if (response.status === 200) {
        alert('파일이 업로드되었습니다.');
        loadGyejeongGwamok(company, selectedYear, selectedQuarter);
        loadSiljilGwamok(company, selectedYear, selectedQuarter);

        if (response.data.message === 'Data inserted successfully') {
          await updateQuarter(company, period);
          dispatch(updateSelectedYear(period.substring(0, 4)));
          dispatch(updateSelectedQuarter(period.substring(4, 6)));

          if (Number(period) > Number(uploadedQuarter)) {
            setUploadedQuarter(response.data.most_recent_period);
          }
        }
      }
    } catch (error: any) {
      if (file === undefined) {
        alert('파일 업로드가 취소되었습니다. ');
        return;
      }

      if (error.code === 'ERR_NETWORK' || error.code === 'ERR_BAD_RESPONSE') {
        alert('파일 형식이 잘못되었습니다.');
        return;
      }

      if (
        error.code === 'ERR_BAD_REQUEST' &&
        error.response.data.detail === '손익계산서를 업로드해주세요'
      ) {
        alert('손익계산서를 업로드해주세요');
        return;
      }
    }
  }

  async function uploadJaemuFile(e: React.ChangeEvent<HTMLInputElement>) {
    const target = e.target as HTMLInputElement;
    const file: File = (target.files as FileList)[0];
    const formData = new FormData();
    formData.append('file', file);

    const option = {
      url: 'https://cpi.contax.ai/jaemu_excel_upload_no_quarter',
      params: {
        company_name: company,
        quarter: selectedYear + '' + selectedQuarter
      },
      data: formData,
      method: 'POST',
      header: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data'
      }
    };

    try {
      const response = await axios(option);
      const period = response.data.most_recent_period;

      if (response.status === 200) {
        alert('파일이 업로드되었습니다.');

        await loadGyejeongGwamok(company, selectedYear, selectedQuarter);
        await loadSiljilGwamok(company, selectedYear, selectedQuarter);

        if (response.data.message === 'Data inserted successfully') {
          await updateQuarter(company, response.data.most_recent_period);
          dispatch(updateSelectedYear(period.substring(0, 4)));
          dispatch(updateSelectedQuarter(period.substring(4, 6)));

          if (Number(period) > Number(uploadedQuarter)) {
            setUploadedQuarter(period);
          }
        }
        // insertNotification('재무제표');
        // token.forEach((item) => {
        //     sendNotification(item, '재무제표');
        // })
      }
    } catch (error: any) {
      if (file === undefined) {
        alert('파일 업로드가 취소되었습니다. ');
        return;
      }

      if (error.code === 'ERR_NETWORK' || error.code === 'ERR_BAD_RESPONSE') {
        alert('파일 형식이 잘못되었습니다.');
        return;
      }

      if (
        error.code === 'ERR_BAD_REQUEST' &&
        error.response.data.detail === '재무상태표를 업로드해주세요'
      ) {
        alert('재무상태표를 업로드해주세요');
        return;
      }
    }
  }

  function onPrevious() {
    dispatch(updateMemberPageStatus('read'));
  }

  async function insertSubscription(
    email: string,
    subscription_type: string,
    subscription_end: string
  ) {
    const params = {
      email,
      subscription_type,
      subscription_end
    };

    const option = {
      url: 'https://cpi.contax.ai/load_expert_comment',
      params: params,
      method: 'GET',
      header: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data'
      }
    };

    await axios(option);
  }

  async function initMembers() {
    dispatch(resetTargetMember());
    const result = await searchMembers(tab, offset, limit, sort, searchQuery);

    if (result!.status === 'success') {
      for (let i = 0; i < result!.data.data.length; i++) {
        const name = await getMemberManager(
          result!.data.data[i].email,
          result!.data.data[i].representative_name
        );
        result!.data.data[i].member_of_manager = name;
      }

      dispatch(updateUser(result!.data));
      const length = calculatePagination(result!.data.total_count, limit);
      const array = createPaginationSize(offset, length);
      dispatch(updatePageLength(length));
      dispatch(updatePageNumbers(array));
    }

    if (result!.status === 'failed') {
      dispatch(
        updateUser({
          data: [],
          total_count: 0
        })
      );
    }
  }

  async function getExpression() {
    const option = {
      url: 'https://cpi.contax.ai/mng_avg_expression',
      method: 'GET',
      header: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data'
      }
    };

    await axios(option).then((response) => {
      if (response.status === 200) {
        dispatch(updateExpression(response.data));
      }
    });
  }

  useEffect(() => {
    initMembers();
    setOpen({ index: 0, open: false });
    getExpression();
  }, [
    tab,
    offset,
    limit,
    sort.business,
    sort.first,
    sort.contax,
    searchQuery.business.query,
    searchQuery.first.query,
    searchQuery.contax.query,
    status
  ]);

  function clickTab(tab: string) {
    setOffset(0);
    dispatch(updateTab(tab));
  }

  function clickSort(id: string) {
    if (tab === '비즈니스') {
      if (id !== sort.business.option) {
        setSort({
          ...sort,
          business: {
            option: id,
            sort: 'DESC'
          }
        });
      }

      if (id === sort.business.option && sort.business.sort === 'DESC') {
        setSort({
          ...sort,
          business: {
            option: id,
            sort: 'ASC'
          }
        });
      }

      if (id === sort.business.option && sort.business.sort === 'ASC') {
        setSort({
          ...sort,
          business: {
            option: id,
            sort: 'DESC'
          }
        });
      }
    }

    if (tab === '퍼스트') {
      if (id !== sort.first.option) {
        setSort({
          ...sort,
          first: {
            option: id,
            sort: 'DESC'
          }
        });
      }

      if (id === sort.first.option && sort.first.sort === 'DESC') {
        setSort({
          ...sort,
          first: {
            option: id,
            sort: 'ASC'
          }
        });
      }

      if (id === sort.first.option && sort.first.sort === 'ASC') {
        setSort({
          ...sort,
          first: {
            option: id,
            sort: 'DESC'
          }
        });
      }
    }

    if (tab === '콘택스') {
      if (id !== sort.contax.option) {
        setSort({
          ...sort,
          contax: {
            option: id,
            sort: 'DESC'
          }
        });
      }

      if (id === sort.contax.option && sort.contax.sort === 'DESC') {
        setSort({
          ...sort,
          contax: {
            option: id,
            sort: 'ASC'
          }
        });
      }

      if (id === sort.contax.option && sort.contax.sort === 'ASC') {
        setSort({
          ...sort,
          contax: {
            option: id,
            sort: 'DESC'
          }
        });
      }
    }
  }

  function searchData(e: React.ChangeEvent<HTMLInputElement>) {
    const query = { ...searchQuery };

    if (tab === '비즈니스') {
      query.business.query = e.target.value;
    }

    if (tab === '퍼스트') {
      query.first.query = e.target.value;
    }

    if (tab === '콘택스') {
      query.contax.query = e.target.value;
    }

    setSearchQuery(query);
  }

  function selectCategory(e: React.ChangeEvent<HTMLInputElement>) {
    const query = { ...searchQuery };

    if (tab === '비즈니스') {
      query.business.category = e.target.value;
    }

    if (tab === '퍼스트') {
      query.first.category = e.target.value;
    }

    if (tab === '콘택스') {
      query.contax.category = e.target.value;
    }

    setSearchQuery(query);
  }

  async function getExpertComment(pyeongjeom: string) {
    const id = localStorage.getItem('id');

    const option = {
      url: 'https://cpi.contax.ai/load_expert_comment',
      params: {
        writter_id: id,
        target_id: targetId,
        quarter: selectedYear + '' + selectedQuarter,
        pyeongjeom: pyeongjeom
      },
      method: 'GET',
      header: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data'
      }
    };

    await axios(option).then((response) => {
      if (response.data.length > 0) {
        setExpertComment(response.data[0].overall_comment);
        setCommentList({
          comment_1: response.data[0].comment_1,
          comment_2: response.data[0].comment_2
        });
      } else {
        setExpertComment('');
        setCommentList({
          comment_1: '',
          comment_2: ''
        });
      }
    });
  }

  async function openDetailSection(index: number, item: MemberType) {
    const data = await getQuarter(item.business_number);
    let year: string;
    let quarter: '01' | '02' | '03' | '04';

    if (data === null) {
      dispatch(updateSelectedYear('2022'));
      dispatch(updateSelectedQuarter('01'));

      year = '2022';
      quarter = '01';
    } else {
      dispatch(updateSelectedYear(data.substring(0, 4)));
      dispatch(updateSelectedQuarter(data.substring(4, 6)));
      year = data.substring(0, 4);
      quarter = data.substring(4, 6);
    }

    setUploadedQuarter(data);

    dispatch(updateTargetMember(item));
    dispatch(updateOriginalTargetMember(item));
    if (index === open.index) {
      if (open.open) {
        setOpen({ index: index, open: false });
      }

      if (!open.open) {
        setOpen({ index: index, open: true });
      }
    }

    if (index !== open.index) {
      setOpen({ index: index, open: true });
    }

    setTargetName(item.name);
    setTargetId(item.user_id);
    setCompany(item.business_number);
    await getDiff(item.business_number, year, quarter);
    await loadSiljilGwamok(item.business_number, year, quarter);
    await loadGyejeongGwamok(item.business_number, year, quarter);
    const reportList = await getAnalyReport(item.email);
    setReportList(reportList);
  }

  function formatNumberWithCommas(num: number) {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  function filteringDuplicate(data: GyejeongType[]) {
    // 중복된 category를 찾기 위해 카운트 맵 생성
    const categoryCount: { [key: string]: number } = data.reduce(
      (acc, item) => {
        acc[item.category] = (acc[item.category] || 0) + 1;
        return acc;
      },
      {} as { [key: string]: number }
    );

    // 중복된 category 중 첫 번째 항목 제거
    const firstOccurrenceRemoved: { [key: string]: boolean } = {};
    // 중복된 category 중 value가 0인 항목 제거
    const zeroValueRemoved: { [key: string]: boolean } = {};

    return data.filter((item) => {
      // 해당 category가 중복된 경우
      if (categoryCount[item.category] > 1) {
        // 중복된 category 중 value가 0인 항목이 있는지 확인
        if (item.value === 0 && !zeroValueRemoved[item.category]) {
          zeroValueRemoved[item.category] = true;
          return false; // value가 0인 항목 제거
        }
        // 첫 번째 항목 제거
        if (
          !firstOccurrenceRemoved[item.category] &&
          !zeroValueRemoved[item.category]
        ) {
          firstOccurrenceRemoved[item.category] = true;
          return false; // 첫 번째 항목 제거
        }
      }
      return true; // 나머지 항목 유지
    });
  }

  async function insertComment(comment: string, title: string) {
    const id = localStorage.getItem('id');
    const name = localStorage.getItem('name');

    const option = {
      url: 'https://cpi.contax.ai/insert_expert_comment',
      data: {
        writter_id: id,
        writter_name: name,
        target_id: targetId,
        target_name: targetName,
        quarter: selectedYear + '' + selectedQuarter,
        company: company,
        comment: comment,
        pyeongjeom: title,
        comment_1: commentList.comment_1,
        comment_2: commentList.comment_2
      },
      method: 'POST',
      header: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data'
      }
    };

    await axios(option).then((response) => {
      if (response.status === 200) {
        alert('성공적으로 저장되었습니다.');
        setModalOn({ division: 'report', on: false });
      }
    });
  }

  function string_to_searchQuery() {
    if (tab === '비즈니스') {
      return searchQuery.business.query;
    }

    if (tab === '퍼스트') {
      return searchQuery.first.query;
    }

    if (tab === '콘택스') {
      return searchQuery.contax.query;
    }

    return '';
  }

  const [detailTab, setDetailTab] = useState<
    '상세정보' | '재무상태' | '퍼포먼스'
  >('상세정보');

  return (
    <>
      {modalOn.on && modalOn.division === 'siljil' && (
        <SiljilModal
          uploadedQuarter={uploadedQuarter}
          expression={expression}
          gyejeongRow={siljil}
          title={selectedPyeongjeom}
          setModalOn={() => {
            const object = { ...modalOn };
            object.on = false;
            setModalOn(object);
          }}
        />
      )}

      {modalOn.on && modalOn.division === 'gyejeong' && (
        <GyejeongModal
          uploadedQuarter={uploadedQuarter}
          expression={expression}
          gyejeongRow={gyejeongGwamok}
          title={selectedPyeongjeom}
          setModalOn={() => {
            const object = { ...modalOn };
            object.on = false;
            setModalOn(object);
          }}
        />
      )}
      {modalOn.on && modalOn.division === 'report' && (
        <ReportModal
          setCommentList={(params: CommentListType) => setCommentList(params)}
          commentList={commentList}
          expertComment={expertComment}
          insertComment={(comment: string, title: string) => {
            insertComment(comment, title);
          }}
          expression={expression}
          gyejeongRow={gyejeongGwamok}
          title={selectedPyeongjeom}
          setModalOn={() => {
            const object = { ...modalOn };
            object.on = false;
            setModalOn(object);
          }}
          selectedQuarter={selectedQuarter}
          selectedYear={selectedYear}
          reportList={reportList}
        />
      )}
      {modalOn.on && modalOn.division === 'overall' && (
        <OverallModal
          comment={expertComment}
          targetName={targetName}
          targetId={targetId}
          quarter={selectedYear + '' + selectedQuarter}
          gyejeongRow={gyejeongGwamok}
          title={selectedPyeongjeom}
          setModalOn={() => {
            const object = { ...modalOn };
            object.on = false;
            setModalOn(object);
          }}
          insertReview={(comment: string) => {
            insertComment(comment, '총평');
          }}
          loadReview={() => {
            getExpertComment('총평');
          }}
        />
      )}
      <Grid spacing={2}>
        {status == 'read' && (
          <TitleCard title={'회원 관리'}>
            <St_MemberWrapper>
              <TabButton
                onClick={() => {
                  clickTab('비즈니스');
                }}
                active={tab === '비즈니스'}
              >
                비즈니스
              </TabButton>
              <TabButton
                onClick={() => {
                  clickTab('퍼스트');
                }}
                active={tab === '퍼스트'}
              >
                퍼스트
              </TabButton>
              <TabButton
                onClick={() => {
                  clickTab('콘택스');
                }}
                active={tab === '콘택스'}
              >
                콘택스
              </TabButton>
            </St_MemberWrapper>

            <BoardSearchHeader
              limit={limit}
              setLimit={setLimit}
              query={string_to_searchQuery()}
              onInput={(e) => {
                searchData(e);
              }}
              onSelect={(e) => {
                selectCategory(e);
              }}
              col={Grid_COL}
              defaultColumn="name"
              title={tab}
              total_count={user_total_count}
              limitOption={[10, 50, 100]}
            />

            <ManagerGrid size="large" col={Grid_COL} sortFunction={clickSort}>
              {users.length === 0 && (
                <div
                  style={{
                    padding: '150px 0',
                    textAlign: 'center'
                  }}
                >
                  데이터가 존재하지 않습니다.
                </div>
              )}
              {users.length > 0 &&
                users.map((item, index) => {
                  return (
                    <>
                      <ManagerGridRowSection key={index}>
                        <ManagerGridRow
                          size="large"
                          onClick={() => {
                            openDetailSection(index, item);
                          }}
                          width={10}
                        >
                          {item.name}
                        </ManagerGridRow>
                        <ManagerGridRow
                          size="large"
                          onClick={() => {
                            openDetailSection(index, item);
                          }}
                          width={20}
                        >
                          {item.email}
                        </ManagerGridRow>
                        <ManagerGridRow
                          size="large"
                          onClick={() => {
                            openDetailSection(index, item);
                          }}
                          width={15}
                        >
                          {item.company_name}
                        </ManagerGridRow>
                        <ManagerGridRow
                          size="large"
                          onClick={() => {
                            openDetailSection(index, item);
                          }}
                          width={15}
                        >
                          {item.phone_number}
                        </ManagerGridRow>
                        <ManagerGridRow
                          size="large"
                          onClick={() => {
                            openDetailSection(index, item);
                          }}
                          width={15}
                        >
                          {item.reg_date}
                        </ManagerGridRow>
                        <ManagerGridRow
                          size="large"
                          onClick={() => {
                            openDetailSection(index, item);
                          }}
                          width={15}
                          align="center"
                        >
                          <ManagerGridButton type="active">
                            상세보기
                          </ManagerGridButton>
                        </ManagerGridRow>
                        <ManagerGridRow align="center" width={10}>
                          {item.is_login ? (
                            <CheckCircleIcon width={40} color="green" />
                          ) : (
                            <MinusCircleIcon width={40} color="red" />
                          )}
                        </ManagerGridRow>
                      </ManagerGridRowSection>
                      {/*
                                            TODO
                                            DetailSection 분리 해야함
                                        */}
                      <ManagerGridDetailSection
                        open={open.index === index && open.open === true}
                      >
                        <St_MemberWrapper>
                          <TabButton
                            onClick={() => {
                              setDetailTab('상세정보');
                            }}
                            active={detailTab === '상세정보'}
                          >
                            상세정보
                          </TabButton>
                          <TabButton
                            onClick={() => {
                              setDetailTab('재무상태');
                            }}
                            active={detailTab === '재무상태'}
                          >
                            재무상태
                          </TabButton>
                          <TabButton
                            onClick={() => {
                              setDetailTab('퍼포먼스');
                            }}
                            active={detailTab === '퍼포먼스'}
                          >
                            퍼포먼스
                          </TabButton>
                        </St_MemberWrapper>

                        {detailTab === '상세정보' && (
                          <MemberDetailSection
                            closeDetailSection={() => {
                              setOpen({
                                index: index,
                                open: false
                              });
                            }}
                            searchMembers={initMembers}
                          />
                        )}

                        {detailTab === '퍼포먼스' && <PerformanceTable />}

                        {detailTab === '재무상태' && (
                          <>
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between'
                              }}
                            >
                              <SelectQuarterSection>
                                <select
                                  style={{
                                    width: '95px',
                                    fontWeight: 'bold',
                                    fontSize: '20px'
                                  }}
                                  value={selectedYear}
                                  onChange={(e) => {
                                    changeSelectedYear(e.target.value);
                                  }}
                                  defaultValue="2022"
                                >
                                  <option value="2022">2022년</option>
                                  <option value="2023">2023년</option>
                                  <option value="2024">2024년</option>
                                </select>

                                <select
                                  style={{
                                    width: '95px',
                                    fontWeight: 'bold',
                                    fontSize: '20px'
                                  }}
                                  value={selectedQuarter}
                                  onChange={(e) => {
                                    changeSelectedQuarter(
                                      e.target.value as
                                        | '01'
                                        | '02'
                                        | '03'
                                        | '04'
                                    );
                                  }}
                                  defaultValue="01"
                                >
                                  <option value="01">1분기</option>
                                  <option value="02">2분기</option>
                                  <option value="03">3분기</option>
                                  <option value="04">4분기</option>
                                </select>

                                <span
                                  style={{
                                    color: '#1976d2',
                                    cursor: 'pointer'
                                  }}
                                  className="material-symbols-outlined"
                                >
                                  info
                                </span>
                                {(uploadedQuarter === 'None' ||
                                  uploadedQuarter === '01') && (
                                  <span
                                    style={{
                                      width: '200px',
                                      fontSize: '14px',
                                      color: '#555'
                                    }}
                                  >
                                    업로드 한 재무제표가 없습니다.
                                  </span>
                                )}

                                {uploadedQuarter !== 'None' &&
                                  uploadedQuarter !== '01' && (
                                    <span
                                      style={{
                                        width: '200px',
                                        fontSize: '14px',
                                        color: '#555'
                                      }}
                                    >
                                      최근 업로드 :{' '}
                                      {uploadedQuarter.substring(0, 4) +
                                        '년 ' +
                                        uploadedQuarter.substring(5, 6) +
                                        '분기'}
                                    </span>
                                  )}
                              </SelectQuarterSection>

                              <Grid container justifyContent="end" spacing={5}>
                                <Grid item>
                                  <Stack spacing={2}>
                                    <Typography variant="h6">
                                      재무상태표 업로드
                                    </Typography>
                                    <Button
                                      component="label"
                                      disabled={company === ''}
                                      variant="contained"
                                    >
                                      업로드
                                      <VisuallyHiddenInput
                                        onChange={(event) => {
                                          uploadJaemuFile(event);
                                          event.target.value = '';
                                        }}
                                        ref={jaemuRef}
                                        type="file"
                                        accept=".xlsx, .xls"
                                      />
                                    </Button>
                                  </Stack>
                                </Grid>

                                <Grid item>
                                  <Stack spacing={2} mr={2}>
                                    <Typography variant="h6">
                                      손익계산서 업로드
                                    </Typography>
                                    <Button
                                      component="label"
                                      disabled={company === ''}
                                      variant="contained"
                                    >
                                      업로드
                                      <VisuallyHiddenInput
                                        ref={sonikRef}
                                        type="file"
                                        accept=".xlsx, .xls"
                                        onChange={(event) => {
                                          uploadSonikFile(
                                            event,
                                            selectedYear,
                                            selectedQuarter
                                          );

                                          event.target.value = '';
                                        }}
                                      />
                                    </Button>
                                  </Stack>
                                </Grid>
                              </Grid>
                            </div>

                            <ExpertPropertySection>
                              <ExpertProperty
                                setModalOn={(division: string) => {
                                  const object = {
                                    ...modalOn
                                  };
                                  object.division = division;
                                  object.on = true;
                                  setModalOn(object);
                                  setSelectedPyeongjeom('실질자본금');

                                  if (division === 'comment') {
                                    getComment('실질자본금');
                                  }
                                }}
                                name="실질자본금"
                                getExpertComment={(params: string) => {
                                  getExpertComment(params);
                                }}
                                setGyejeongStatus={(params: string) => {
                                  setGyejeongStatus(params);
                                }}
                                ratio={formatNumberWithCommas(
                                  siljil?.real_capital
                                )}
                              />

                              <ExpertProperty
                                setModalOn={(division: string) => {
                                  const object = {
                                    ...modalOn
                                  };
                                  object.division = division;
                                  object.on = true;
                                  setModalOn(object);
                                  setSelectedPyeongjeom('유동비율');

                                  if (division === 'comment') {
                                    getComment('유동비율');
                                  }
                                }}
                                ratio={ratio.yudong}
                                diff={diff.yudong}
                                result={result.yudong}
                                getExpertComment={(params: string) => {
                                  getExpertComment(params);
                                }}
                                name="유동비율"
                                setGyejeongStatus={(params: string) => {
                                  setGyejeongStatus(params);
                                }}
                              />
                              <ExpertProperty
                                setModalOn={(division: string) => {
                                  const object = {
                                    ...modalOn
                                  };
                                  object.division = division;
                                  object.on = true;
                                  setModalOn(object);
                                  setSelectedPyeongjeom('부채비율');

                                  if (division === 'comment') {
                                    getComment('부채비율');
                                  }
                                }}
                                ratio={ratio.buchae}
                                result={result.buchae}
                                setGyejeongStatus={(params: string) => {
                                  setGyejeongStatus(params);
                                }}
                                diff={diff.buchae}
                                name="부채비율"
                                getExpertComment={(params: string) => {
                                  getExpertComment(params);
                                }}
                              />

                              <ExpertProperty
                                setModalOn={(division: string) => {
                                  const object = {
                                    ...modalOn
                                  };
                                  object.division = division;
                                  object.on = true;
                                  setModalOn(object);
                                  setSelectedPyeongjeom('차입금의존도');

                                  if (division === 'comment') {
                                    getComment('차입금의존도');
                                  }
                                }}
                                ratio={ratio.chaIp}
                                result={result.chaIp}
                                diff={diff.chaIp}
                                getExpertComment={getExpertComment}
                                setGyejeongStatus={setGyejeongStatus}
                                name="차입금의존도"
                              />

                              <ExpertProperty
                                setModalOn={(division: string) => {
                                  const object = {
                                    ...modalOn
                                  };
                                  object.division = division;
                                  object.on = true;
                                  setModalOn(object);
                                  setSelectedPyeongjeom(
                                    '영업이익대비 이자보상배율'
                                  );
                                  if (division === 'comment') {
                                    getComment('영업이익대비 이자보상배율');
                                  }
                                }}
                                ratio={Math.floor(
                                  Number((ratio.profit / 100 / 100).toFixed(2))
                                )}
                                diff={diff.profit}
                                result={result.profit}
                                getExpertComment={(params: string) => {
                                  getExpertComment(params).then((r) => {
                                    return r;
                                  });
                                }}
                                setGyejeongStatus={(params: string) => {
                                  setGyejeongStatus(params);
                                }}
                                name="영업이익대비 이자보상배율"
                              />

                              <ExpertProperty
                                setModalOn={(division: string) => {
                                  const object = {
                                    ...modalOn
                                  };
                                  object.division = division;
                                  object.on = true;
                                  setModalOn(object);
                                  setSelectedPyeongjeom('매출액영업이익율');
                                  if (division === 'comment') {
                                    getComment('매출액영업이익율');
                                  }
                                }}
                                ratio={ratio.income}
                                diff={diff.income}
                                result={result.income}
                                getExpertComment={(params: string) => {
                                  getExpertComment(params);
                                }}
                                name="매출액영업이익율"
                                setGyejeongStatus={(params: string) => {
                                  setGyejeongStatus(params);
                                }}
                              />

                              <ExpertProperty
                                setModalOn={(division: string) => {
                                  const object = {
                                    ...modalOn
                                  };
                                  object.division = division;
                                  object.on = true;
                                  setModalOn(object);
                                  setSelectedPyeongjeom('매출액순이익율');

                                  if (division === 'comment') {
                                    getComment('매출액순이익율');
                                  }
                                }}
                                ratio={ratio.maechul}
                                diff={diff.maechul}
                                result={result.maechul}
                                getExpertComment={(params: string) => {
                                  getExpertComment(params);
                                }}
                                setGyejeongStatus={(params: string) => {
                                  setGyejeongStatus(params);
                                }}
                                name="매출액순이익율"
                              />
                              <ExpertProperty
                                setModalOn={(division: string) => {
                                  const object = {
                                    ...modalOn
                                  };
                                  object.division = division;
                                  object.on = true;
                                  setModalOn(object);
                                  setSelectedPyeongjeom('총자산순이익율');

                                  if (division === 'comment') {
                                    getComment('총자산순이익율');
                                  }
                                }}
                                ratio={ratio.alljasan}
                                name="총자산순이익율"
                                diff={diff.alljasan}
                                result={result.alljasan}
                                getExpertComment={(params: string) => {
                                  getExpertComment(params);
                                }}
                                setGyejeongStatus={(params: string) => {
                                  setGyejeongStatus(params);
                                }}
                              />

                              <ExpertProperty
                                setModalOn={(division: string) => {
                                  const object = {
                                    ...modalOn
                                  };
                                  object.division = division;
                                  object.on = true;
                                  setModalOn(object);
                                  setSelectedPyeongjeom(
                                    '총자산대비영업현금흐름비율'
                                  );

                                  if (division === 'comment') {
                                    getComment('총자산대비영업현금흐름비율');
                                  }
                                }}
                                ratio={ratio.cashflow}
                                diff={diff.cashflow}
                                result={result.cashflow}
                                getExpertComment={(params: string) => {
                                  getExpertComment(params).then((r) => {
                                    return r;
                                  });
                                }}
                                name="총자산대비영업현금흐름비율"
                                setGyejeongStatus={(params: string) => {
                                  setGyejeongStatus(params);
                                }}
                              />

                              <ExpertProperty
                                setModalOn={(division: string) => {
                                  const object = {
                                    ...modalOn
                                  };
                                  object.division = division;
                                  object.on = true;
                                  setModalOn(object);
                                  setSelectedPyeongjeom('최근년도 자산회전율');

                                  if (division === 'comment') {
                                    getComment('최근년도 자산회전율');
                                  }
                                }}
                                ratio={ratio.jasanTurning / 100}
                                diff={diff.jasanTurning}
                                result={result.jasanTurning}
                                getExpertComment={(params: string) => {
                                  getExpertComment(params);
                                }}
                                name="최근년도 자산회전율"
                                setGyejeongStatus={(params: string) => {
                                  setGyejeongStatus(params);
                                }}
                              />

                              <ExpertProperty
                                setModalOn={(division: string) => {
                                  const object = {
                                    ...modalOn
                                  };
                                  object.division = division;
                                  object.on = true;
                                  setModalOn(object);
                                  setSelectedPyeongjeom('건설기술개발투자비율');

                                  if (division === 'comment') {
                                    getComment('건설기술개발투자비율');
                                  }
                                }}
                                ratio={ratio.techdev}
                                diff={diff.techdev}
                                name="건설기술개발투자비율"
                                getExpertComment={(params: string) => {
                                  getExpertComment(params);
                                }}
                                result={result.techdev}
                                setGyejeongStatus={(params: string) => {
                                  setGyejeongStatus(params);
                                }}
                              />

                              <St_PyeongjeomProperty
                                onClick={() => {
                                  setModalOn({
                                    on: true,
                                    division: 'overall'
                                  });
                                  getExpertComment('총평');
                                }}
                              >
                                <St_PyeongjeomPropertyName>
                                  <span className="material-symbols-outlined">
                                    add_circle
                                  </span>
                                  <span>총평 입력</span>
                                </St_PyeongjeomPropertyName>
                              </St_PyeongjeomProperty>
                            </ExpertPropertySection>
                          </>
                        )}
                      </ManagerGridDetailSection>
                    </>
                  );
                })}

              <div
                style={{
                  display: 'flex',
                  marginTop: '30px',
                  justifyContent: 'end'
                }}
              >
                <ManagerGridButton
                  type="active"
                  onClick={() => {
                    dispatch(updateMemberPageStatus('write'));
                    dispatch(resetCreateMemberInformation);
                  }}
                >
                  <PencilIcon style={{ width: '20px' }} />
                  작성
                </ManagerGridButton>
              </div>

              <BoardPagenation
                offset={offset}
                pageNumbers={page_numbers}
                pageLength={page_length}
                setOffset={setOffset}
              />
            </ManagerGrid>
          </TitleCard>
        )}

        {status === 'write' && (
          <TitleCard title="회원 작성" previous={onPrevious}>
            <ManagerGridDetailSection open={true}>
              <CreateSection />
            </ManagerGridDetailSection>
          </TitleCard>
        )}

        {status === 'modify' && (
          <TitleCard title="회원 수정" previous={onPrevious}>
            <ManagerGridDetailSection open={true}>
              <CreateSection />
            </ManagerGridDetailSection>
          </TitleCard>
        )}
      </Grid>
    </>
  );
}

export default MemberManagement;
