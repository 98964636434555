/* eslint-disable react/jsx-pascal-case */
import { Button, TextField } from '@mui/material';
import axios from 'axios';
import React, { MouseEventHandler, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Select } from './Select';
import Markdown from 'markdown-to-jsx';
import PropTypes from 'prop-types';
import {
  CommentListType,
  CommentType,
  ExpressionType,
  GyejeongType,
  ModalRadioSectionType,
  OptionType,
  PyeongjeomCommentType,
  ReportType,
  SiljilType
} from '../interface/jasanInterface';
import { formatKRW } from '../utils/formatKRW';
import { orderGyejeongList } from '../utils/orderGyejeongList';

const St_AnswerSection = styled.div`
  display: flex;
  flex-direction: column;
`;

const St_GyejeongModal = styled.div`
  width: 400px;
  height: 900px;
  padding: 20px 40px;
  background-color: #fff;

  position: fixed;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  z-index: 100;
  border: 1px solid #ddd;
  border-radius: 10px;
`;

export const BlurBackground = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 99;
  backdrop-filter: blur(2px);
`;

const St_Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 30px;
  border-bottom: 1px solid #aaa;
`;

const St_Title = styled.div`
  font-size: 20px;
  font-weight: bold;
`;

const St_Close = styled.span`
  font-size: 30px;
  font-weight: bold;
  cursor: pointer;
`;

const St_Content = styled.div`
  margin-top: 30px;
`;

const St_GwamokSection = styled.div`
  display: flex;
  margin-bottom: 10px;
`;

const St_Category = styled.span`
  width: 200px;
  font-weight: bold;
  font-size: 18px;
  display: flex;
  align-items: center;
  gap: 10px;
  color: #000;
`;

interface GyejeongModalType {
  title: string;
  setModalOn: MouseEventHandler;
  expression: ExpressionType;
  uploadedQuarter: string;
  gyejeongRow: GyejeongType[];
}

interface SiljilModalType {
  title: string;
  setModalOn: MouseEventHandler;
  expression: ExpressionType;
  uploadedQuarter: string;
  gyejeongRow: SiljilType;
}

export function SiljilModal(props: SiljilModalType) {
  // 카테고리별 조건을 객체로 정리

  return (
    <>
      <BlurBackground />
      <St_GyejeongModal>
        <St_Header>
          <St_Title>{props.title}</St_Title>
          <St_Close
            onClick={props.setModalOn}
            className="material-symbols-outlined"
          >
            close
          </St_Close>
        </St_Header>

        <div style={{ display: 'flex', gap: '10px', marginTop: '20px' }}>
          {props.uploadedQuarter !== '01' && (
            <>
              <span style={{ fontSize: '18px' }}>업로드 분기 : </span>
              <span style={{ fontSize: '18px' }}>
                {props.uploadedQuarter.substring(0, 4) +
                  '년 ' +
                  props.uploadedQuarter.substring(5, 7) +
                  '분기'}
              </span>
            </>
          )}
        </div>

        <St_Content>
          <St_GwamokSection>
            <St_Category>실질자본금</St_Category>
            <span
              style={{
                display: 'inline-block',
                width: '200px',
                textAlign: 'right'
              }}
            >
              {formatKRW(props.gyejeongRow.real_capital)}
            </span>
          </St_GwamokSection>

          <St_GwamokSection>
            <St_Category>자산총계</St_Category>
            <span
              style={{
                display: 'inline-block',
                width: '200px',
                textAlign: 'right'
              }}
            >
              {formatKRW(props.gyejeongRow.total_assets)}
            </span>
          </St_GwamokSection>

          <St_GwamokSection>
            <St_Category>부채총계</St_Category>
            <span
              style={{
                display: 'inline-block',
                width: '200px',
                textAlign: 'right'
              }}
            >
              {formatKRW(props.gyejeongRow.total_dept)}
            </span>
          </St_GwamokSection>

          <St_GwamokSection>
            <St_Category>자본총계</St_Category>
            <span
              style={{
                display: 'inline-block',
                width: '200px',
                textAlign: 'right'
              }}
            >
              {formatKRW(props.gyejeongRow.capital_total)}
            </span>
          </St_GwamokSection>

          <St_GwamokSection>
            <St_Category>인정자산</St_Category>
            <span
              style={{
                display: 'inline-block',
                width: '200px',
                textAlign: 'right'
              }}
            >
              {formatKRW(props.gyejeongRow.recognized_assets)}
            </span>
          </St_GwamokSection>

          <St_GwamokSection>
            <St_Category>불인정자산</St_Category>
            <span
              style={{
                display: 'inline-block',
                width: '200px',
                textAlign: 'right'
              }}
            >
              {formatKRW(props.gyejeongRow.unrecognized_assets_sum)}
            </span>
          </St_GwamokSection>
        </St_Content>
      </St_GyejeongModal>
    </>
  );
}

export function GyejeongModal(props: GyejeongModalType) {
  // 해당 타이틀에 맞는 데이터를 렌더링하는 함수
  const renderDataForTitle = (title: string, rowData: GyejeongType[]) => {
    return rowData
      .map((item) => {
        return (
          <St_GwamokSection>
            <St_Category>{item.category}</St_Category>
            <span
              style={{
                display: 'inline-block',
                width: '200px',
                textAlign: 'right'
              }}
            >
              {formatKRW(item.value)}
            </span>
          </St_GwamokSection>
        );
      })
      .filter((item) => item !== null); // null 값 제거
  };

  return (
    <>
      <BlurBackground />
      <St_GyejeongModal>
        <St_Header>
          <St_Title>{props.title}</St_Title>
          <St_Close
            onClick={props.setModalOn}
            className="material-symbols-outlined"
          >
            close
          </St_Close>
        </St_Header>

        <div style={{ display: 'flex', gap: '10px', marginTop: '20px' }}>
          <span style={{ fontSize: '18px' }}>산식 : </span>

          <span style={{ fontSize: '18px' }}>
            {props.title === '유동비율' &&
              props.expression.liquidity_expression}
            {props.title === '부채비율' && props.expression.debt_expression}
            {props.title === '차입금의존도' &&
              props.expression.dependency_expression}
            {props.title === '영업이익대비 이자보상배율' &&
              props.expression.assets_expression}
            {props.title === '매출액영업이익율' &&
              props.expression.margin_expression}
            {props.title === '매출액순이익율' &&
              props.expression.maechul_expression}
            {props.title === '총자산순이익율' &&
              props.expression.alljasanreal_expression}
            {props.title === '총자산대비영업현금흐름비율' &&
              props.expression.flow_expression}
            {props.title === '최근년도 자산회전율' &&
              props.expression.turnover_expression}
            {props.title === '건설기술개발투자비율' &&
              props.expression.investment_expression}
          </span>
        </div>

        <div style={{ display: 'flex', gap: '10px', marginTop: '20px' }}>
          {props.uploadedQuarter !== '01' && (
            <>
              <span style={{ fontSize: '18px' }}>업로드 분기 : </span>
              <span style={{ fontSize: '18px' }}>
                {props.uploadedQuarter.substring(0, 4) +
                  '년 ' +
                  props.uploadedQuarter.substring(5, 7) +
                  '분기'}
              </span>
            </>
          )}
        </div>

        <St_Content>
          {renderDataForTitle(
            props.title,
            orderGyejeongList({
              pyeongjeom: props.title,
              gyejeong: props.gyejeongRow
            }) ?? []
          )}
        </St_Content>
      </St_GyejeongModal>
    </>
  );
}

const St_RadioSection = styled.div`
  display: flex;
  gap: 30px;
`;

const St_Radio = styled.div`
  display: flex;
  gap: 20px;
  cursor: pointer;
`;

const St_RadioTitle = styled.span``;

const St_CommentModal = styled.div`
  width: 800px;
  height: 800px;
  overflow-y: scroll;
  padding: 20px 40px;
  background-color: #fff;

  position: fixed;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  z-index: 100;
  border: 1px solid #ddd;
  border-radius: 10px;
`;

const St_ReportCommentModal = styled.div`
  width: 1200px;
  height: 800px;
  overflow-y: scroll;
  padding: 20px 40px;
  background-color: #fff;

  position: fixed;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  z-index: 100;
  border: 1px solid #ddd;
  border-radius: 10px;
`;

export interface CommentModalProps {
  setModalOn: () => void;
  review: OptionType[];
  targetId: number;
  targetName: string;
  quarter: string;
}

export function CommentModal(props: CommentModalProps) {
  const [comment, setComment] = useState<PyeongjeomCommentType>({
    yudong: '',
    buchae: '',
    chaIp: '',
    profit: '',
    maechul: '',
    income: '',
    alljasan: '',
    cashflow: '',
    jasanTurning: '',
    techdev: ''
  });

  const [changeReview, setChangeReview] = useState({
    yudong: '',
    buchae: '',
    chaIp: '',
    profit: '',
    maechul: '',
    income: '',
    alljasan: '',
    cashflow: '',
    jasanTurning: '',
    techdev: ''
  });

  const [jaemu, setJaemu] = useState('');
  const [sonik, setSonik] = useState('');

  useEffect(() => {
    loadComment();
  }, []);

  function onChange(id: string, params: string) {
    const object: PyeongjeomCommentType = { ...comment };
    object[id] = params;
    setComment(object);
  }

  async function loadComment() {
    const id = localStorage.getItem('id');

    const option = {
      url: 'https://cpi.contax.ai/load_tax_manager_comment',
      method: 'GET',
      params: {
        writter_id: id,
        quarter: props.quarter,
        target_user_id: props.targetId
      },
      header: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data'
      }
    };

    try {
      const response = await axios(option);

      if (response.status === 200) {
        const object = { ...comment };
        const reviewObject = { ...changeReview };
        const commentList: CommentType[] = response.data;

        commentList.forEach((item) => {
          if (item.question_id === 'yudong') {
            object.yudong = item.answer;
            reviewObject.yudong = item.pyeongjeom_review;
          }

          if (item.question_id === 'buchae') {
            object.buchae = item.answer;
            reviewObject.buchae = item.pyeongjeom_review;
          }

          if (item.question_id === 'chaIp') {
            object.chaIp = item.answer;
            reviewObject.chaIp = item.pyeongjeom_review;
          }

          if (item.question_id === 'profit') {
            object.profit = item.answer;
            reviewObject.profit = item.pyeongjeom_review;
          }

          if (item.question_id === 'maechul') {
            object.maechul = item.answer;
            reviewObject.maechul = item.pyeongjeom_review;
          }

          if (item.question_id === 'income') {
            object.income = item.answer;
            reviewObject.income = item.pyeongjeom_review;
          }

          if (item.question_id === 'alljasan') {
            object.alljasan = item.answer;
            reviewObject.alljasan = item.pyeongjeom_review;
          }

          if (item.question_id === 'cashflow') {
            object.cashflow = item.answer;
            reviewObject.cashflow = item.pyeongjeom_review;
          }

          if (item.question_id === 'jasanTurning') {
            object.jasanTurning = item.answer;
            reviewObject.jasanTurning = item.pyeongjeom_review;
          }

          if (item.question_id === 'techdev') {
            object.techdev = item.answer;
            reviewObject.techdev = item.pyeongjeom_review;
          }
        });

        console.log(reviewObject);

        setComment(object);
        setChangeReview(reviewObject);
      }
    } catch (error) {}

    const reviewOption = {
      url: 'https://cpi.contax.ai/load_tax_manager_review',
      method: 'GET',
      params: {
        writter_id: id,
        quarter: props.quarter,
        target_user_id: props.targetId
      },
      header: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data'
      }
    };

    await axios(reviewOption).then((response) => {
      setJaemu(response.data.jaemu);
      setSonik(response.data.sonik);
    });
  }

  async function insertComment() {
    const id = localStorage.getItem('id');
    const name = localStorage.getItem('name');

    const yudongOption = {
      url: 'https://cpi.contax.ai/insert_tax_manager_comment',
      method: 'POST',
      data: {
        writter_id: id,
        writter_name: name,
        question: '유동비율에 대한 평가를 작성하세요.',
        question_id: 'yudong',
        answer: comment.yudong,
        quarter: props.quarter,
        target_user_id: props.targetId,
        target_user_name: props.targetName,
        pyeongjeom: '유동비율',
        pyeongjeom_review: changeReview.yudong
      },
      header: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data'
      }
    };

    await axios(yudongOption);

    const buchaeOption = {
      url: 'https://cpi.contax.ai/insert_tax_manager_comment',
      method: 'POST',
      data: {
        writter_id: id,
        writter_name: name,
        question: '부채비율에 대한 평가를 작성하세요.',
        question_id: 'buchae',
        answer: comment.buchae,
        quarter: props.quarter,
        target_user_id: props.targetId,
        target_user_name: props.targetName,
        pyeongjeom: '부채비율',
        pyeongjeom_review: changeReview.buchae
      },
      header: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data'
      }
    };

    await axios(buchaeOption);

    const chaIpOption = {
      url: 'https://cpi.contax.ai/insert_tax_manager_comment',
      method: 'POST',
      data: {
        writter_id: id,
        writter_name: name,
        question: '차입금의존도에 대한 평가를 작성하세요.',
        question_id: 'chaIp',
        answer: comment.chaIp,
        quarter: props.quarter,
        target_user_id: props.targetId,
        target_user_name: props.targetName,
        pyeongjeom: '차입금의존도',
        pyeongjeom_review: changeReview.chaIp
      },
      header: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data'
      }
    };

    await axios(chaIpOption);

    const profitOption = {
      url: 'https://cpi.contax.ai/insert_tax_manager_comment',
      method: 'POST',
      data: {
        writter_id: id,
        writter_name: name,
        question: '영업이익대비 이자보상배율에 대한 평가를 작성하세요.',
        question_id: 'profit',
        answer: comment.profit,
        quarter: props.quarter,
        target_user_id: props.targetId,
        target_user_name: props.targetName,
        pyeongjeom: '영업이익대비 이자보상배율',
        pyeongjeom_review: changeReview.profit
      },
      header: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data'
      }
    };

    await axios(profitOption);

    const maechulOption = {
      url: 'https://cpi.contax.ai/insert_tax_manager_comment',
      method: 'POST',
      data: {
        writter_id: id,
        writter_name: name,
        question: '매출액영업이익율에 대한 평가를 작성하세요.',
        question_id: 'maechul',
        answer: comment.maechul,
        quarter: props.quarter,
        target_user_id: props.targetId,
        target_user_name: props.targetName,
        pyeongjeom: '매출액영업이익율',
        pyeongjeom_review: changeReview.maechul
      },
      header: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data'
      }
    };

    await axios(maechulOption).then((response) => {
      if (response.status === 200) {
      }
    });

    const incomeOption = {
      url: 'https://cpi.contax.ai/insert_tax_manager_comment',
      method: 'POST',
      data: {
        writter_id: id,
        writter_name: name,
        question: '매출액순이익율에 대한 평가를 작성하세요.',
        question_id: 'income',
        answer: comment.income,
        quarter: props.quarter,
        target_user_id: props.targetId,
        target_user_name: props.targetName,
        pyeongjeom: '매출액순이익율',
        pyeongjeom_review: changeReview.income
      },
      header: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data'
      }
    };

    await axios(incomeOption).then((response) => {
      if (response.status === 200) {
      }
    });

    const alljasanOption = {
      url: 'https://cpi.contax.ai/insert_tax_manager_comment',
      method: 'POST',
      data: {
        writter_id: id,
        writter_name: name,
        question: '총자산순이익율에 대한 평가를 작성하세요.',
        question_id: 'alljasan',
        answer: comment.alljasan,
        quarter: props.quarter,
        target_user_id: props.targetId,
        target_user_name: props.targetName,
        pyeongjeom: '총자산순이익율',
        pyeongjeom_review: changeReview.alljasan
      },
      header: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data'
      }
    };

    await axios(alljasanOption).then((response) => {
      if (response.status === 200) {
      }
    });

    const cashflowOption = {
      url: 'https://cpi.contax.ai/insert_tax_manager_comment',
      method: 'POST',
      data: {
        writter_id: id,
        writter_name: name,
        question: '총자산대비현금흐름비율에 대한 평가를 작성하세요.',
        question_id: 'cashflow',
        answer: comment.cashflow,
        quarter: props.quarter,
        target_user_id: props.targetId,
        target_user_name: props.targetName,
        pyeongjeom: '총자산대비현금흐름비율',
        pyeongjeom_review: changeReview.cashflow
      },
      header: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data'
      }
    };

    await axios(cashflowOption).then((response) => {
      if (response.status === 200) {
      }
    });

    const jasanTurningOption = {
      url: 'https://cpi.contax.ai/insert_tax_manager_comment',
      method: 'POST',
      data: {
        writter_id: id,
        writter_name: name,
        question: '최근년도 자산회전율 대한 평가를 작성하세요.',
        question_id: 'jasanTurning',
        answer: comment.jasanTurning,
        quarter: props.quarter,
        target_user_id: props.targetId,
        target_user_name: props.targetName,
        pyeongjeom: '최근년도 자산회전율',
        pyeongjeom_review: changeReview.jasanTurning
      },
      header: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data'
      }
    };

    await axios(jasanTurningOption).then((response) => {
      if (response.status === 200) {
      }
    });

    const techdevOption = {
      url: 'https://cpi.contax.ai/insert_tax_manager_comment',
      method: 'POST',
      data: {
        writter_id: id,
        writter_name: name,
        question: '건설기술개발투자비율 대한 평가를 작성하세요.',
        question_id: 'techdev',
        answer: comment.techdev,
        quarter: props.quarter,
        target_user_id: props.targetId,
        target_user_name: props.targetName,
        pyeongjeom: '건설기술개발투자비율',
        pyeongjeom_review: changeReview.techdev
      },
      header: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data'
      }
    };

    await axios(techdevOption).then((response) => {
      if (response.status === 200) {
      }
    });

    const reviewOption = {
      url: 'https://cpi.contax.ai/insert_tax_overall_review',
      method: 'POST',
      data: {
        writter_id: id,
        writter_name: name,
        jaemu: jaemu,
        sonik: sonik,
        quarter: props.quarter,
        target_user_id: props.targetId,
        target_user_name: props.targetName
      },
      header: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data'
      }
    };

    await axios(reviewOption).then((response) => {
      if (response.status === 200) {
        alert('성공적으로 저장되었습니다.');
        props.setModalOn();
      }
    });
  }

  return (
    <>
      <BlurBackground />
      <St_CommentModal>
        <St_Header>
          <St_Title>코멘트 작성</St_Title>
          <St_Close
            onClick={props.setModalOn}
            className="material-symbols-outlined"
          >
            close
          </St_Close>
        </St_Header>

        <St_Content>
          <ModalRadioSection
            changeReview={(params: string) => {
              const object = { ...changeReview };
              object.yudong = params;
              setChangeReview(object);
            }}
            review={props.review}
            changedReview={changeReview.yudong}
            title="유동비율에 대한 평가를 작성하세요."
            id="yudong"
            setComment={onChange}
            comment={comment}
          />
          <ModalRadioSection
            changeReview={(params: string) => {
              const object = { ...changeReview };
              object.buchae = params;
              setChangeReview(object);
            }}
            review={props.review}
            changedReview={changeReview.buchae}
            title="부채비율에 대한 평가를 작성하세요."
            id="buchae"
            setComment={onChange}
            comment={comment}
          />
          <ModalRadioSection
            changeReview={(params: string) => {
              const object = { ...changeReview };
              object.chaIp = params;
              setChangeReview(object);
            }}
            review={props.review}
            changedReview={changeReview.chaIp}
            title="차입금의존도에 대한 평가를 작성하세요."
            id="chaIp"
            setComment={onChange}
            comment={comment}
          />
          <ModalRadioSection
            changeReview={(params: string) => {
              const object = { ...changeReview };
              object.profit = params;
              setChangeReview(object);
            }}
            review={props.review}
            changedReview={changeReview.profit}
            title="영업이익대비 이자보상배율에 대한 평가를 작성하세요."
            id="profit"
            setComment={onChange}
            comment={comment}
          />
          <ModalRadioSection
            changeReview={(params: string) => {
              const object = { ...changeReview };
              object.maechul = params;
              setChangeReview(object);
            }}
            review={props.review}
            changedReview={changeReview.maechul}
            title="매출액영업이익율에 대한 평가를 작성하세요."
            id="maechul"
            setComment={onChange}
            comment={comment}
          />
          <ModalRadioSection
            changeReview={(params: string) => {
              const object = { ...changeReview };
              object.income = params;
              setChangeReview(object);
            }}
            review={props.review}
            changedReview={changeReview.income}
            title="매출액순이익율에 대한 평가를 작성하세요."
            id="income"
            setComment={onChange}
            comment={comment}
          />
          <ModalRadioSection
            changeReview={(params: string) => {
              const object = { ...changeReview };
              object.alljasan = params;
              setChangeReview(object);
            }}
            review={props.review}
            changedReview={changeReview.alljasan}
            title="총자산순이익율에 대한 평가를 작성하세요."
            id="alljasan"
            setComment={onChange}
            comment={comment}
          />
          <ModalRadioSection
            changeReview={(params: string) => {
              const object = { ...changeReview };
              object.cashflow = params;
              setChangeReview(object);
            }}
            review={props.review}
            changedReview={changeReview.cashflow}
            title="총자산대비현금흐름비율에 대한 평가를 작성하세요."
            id="cashflow"
            setComment={onChange}
            comment={comment}
          />
          <ModalRadioSection
            changeReview={(params: string) => {
              const object = { ...changeReview };
              object.jasanTurning = params;
              setChangeReview(object);
            }}
            review={props.review}
            changedReview={changeReview.jasanTurning}
            title="최근년도 자산회전율 대한 평가를 작성하세요."
            id="jasanTurning"
            setComment={onChange}
            comment={comment}
          />
          <ModalRadioSection
            changeReview={(params: string) => {
              const object = { ...changeReview };
              object.techdev = params;
              setChangeReview(object);
            }}
            review={props.review}
            changedReview={changeReview.techdev}
            title="건설기술개발투자비율 대한 평가를 작성하세요."
            id="techdev"
            setComment={onChange}
            comment={comment}
          />
          <ModalTextareaSection
            title="재무제표에 대한 평가를 작성해주세요"
            onChange={(params: string) => {
              setJaemu(params);
            }}
            value={jaemu}
          />
          <ModalTextareaSection
            title="손익계산서에 대한 평가를 작성해주세요"
            onChange={(params: string) => {
              setSonik(params);
            }}
            value={sonik}
          />

          <Button variant="contained" onClick={insertComment}>
            저장
          </Button>
        </St_Content>
      </St_CommentModal>
    </>
  );
}

const ModalRadioSectionTitle = styled.div`
  font-size: 16px;
  font-weight: bold;
`;

const St_ModalRadioSection = styled.div`
  margin-bottom: 30px;
`;

export function ModalRadioSection(props: ModalRadioSectionType) {
  return (
    <St_ModalRadioSection>
      <ModalRadioSectionTitle>{props.title}</ModalRadioSectionTitle>
      <St_RadioSection>
        <St_Radio
          onClick={() => {
            props.setComment(props.id, '매우 좋음');
          }}
        >
          <St_RadioTitle>매우 좋음</St_RadioTitle>
          <input
            type="radio"
            name={props.id.toString()}
            value="매우 좋음"
            checked={props.comment[props.id] === '매우 좋음'}
          />
        </St_Radio>

        <St_Radio
          onClick={() => {
            props.setComment(props.id, '좋음');
          }}
        >
          <St_RadioTitle>좋음</St_RadioTitle>
          <input
            type="radio"
            name={props.id.toString()}
            value="좋음"
            checked={props.comment[props.id] === '좋음'}
          />
        </St_Radio>

        <St_Radio
          onClick={() => {
            props.setComment(props.id, '보통');
          }}
        >
          <St_RadioTitle>보통</St_RadioTitle>
          <input
            type="radio"
            name={props.id.toString()}
            value="보통"
            checked={props.comment[props.id] === '보통'}
          />
        </St_Radio>

        <St_Radio
          onClick={() => {
            props.setComment(props.id, '나쁨');
          }}
        >
          <St_RadioTitle>나쁨</St_RadioTitle>
          <input
            type="radio"
            name={props.id.toString()}
            value="나쁨"
            checked={props.comment[props.id] === '나쁨'}
          />
        </St_Radio>

        <St_Radio
          onClick={() => {
            props.setComment(props.id, '매우 나쁨');
          }}
        >
          <St_RadioTitle>매우 나쁨</St_RadioTitle>
          <input
            type="radio"
            name={props.id.toString()}
            value="매우 나쁨"
            checked={props.comment[props.id] === '매우 나쁨'}
          />
        </St_Radio>
      </St_RadioSection>

      <div style={{ margin: '30px 0' }}>
        <Select
          review={props.changedReview}
          bringValue={(params: string) => {
            props.changeReview(params);
          }}
          option={props.review}
        />
      </div>
    </St_ModalRadioSection>
  );
}

interface ModalTextAreaSectionType {
  title: string;
  onChange: (value: string) => void;
  value: string;
}

export function ModalTextareaSection(props: ModalTextAreaSectionType) {
  return (
    <St_ModalRadioSection>
      <ModalRadioSectionTitle>{props.title}</ModalRadioSectionTitle>
      <textarea
        defaultValue={props.value}
        onChange={(e) => {
          props.onChange(e.target.value);
        }}
        style={{
          marginTop: '20px',
          width: '100%',
          height: '300px',
          resize: 'none',
          border: '1px solid #ddd',
          padding: '20px'
        }}
      />
    </St_ModalRadioSection>
  );
}

interface OverallModalType {
  setModalOn: MouseEventHandler;
  insertReview: (review: string) => void;
  comment: string;
  targetName: string;
  targetId: number;
  quarter: string;
  gyejeongRow: GyejeongType[];
  title: string;
}

export function OverallModal(props: OverallModalType) {
  const [review, setReview] = useState('');

  useEffect(() => {
    setReview(props.comment);
  }, [props.comment]);

  return (
    <>
      <BlurBackground />
      <St_CommentModal>
        <St_Header>
          <St_Title>총평 작성</St_Title>
          <St_Close
            onClick={props.setModalOn}
            className="material-symbols-outlined"
          >
            close
          </St_Close>
        </St_Header>

        <St_Content>
          <textarea
            placeholder="총평을 입력해주세요"
            defaultValue={review}
            onChange={(e) => {
              setReview(e.target.value);
            }}
            style={{
              marginTop: '20px',
              width: '100%',
              height: '400px',
              resize: 'none',
              border: '1px solid #ddd',
              padding: '20px'
            }}
          />
        </St_Content>

        <Button
          sx={{ marginRight: 'auto' }}
          variant="contained"
          onClick={() => {
            props.insertReview(review);
          }}
        >
          저장
        </Button>
      </St_CommentModal>
    </>
  );
}

OverallModal.propTypes = {
  setModalOn: PropTypes.func.isRequired,
  insertReview: PropTypes.func.isRequired,
  loadReview: PropTypes.func.isRequired
};

ReportModal.propTypes = {
  readMode: PropTypes.bool,
  setModalOn: PropTypes.func.isRequired,
  reportList: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  selectedYear: PropTypes.string.isRequired,
  selectedQuarter: PropTypes.string.isRequired,
  insertComment: PropTypes.func.isRequired,
  expertComment: PropTypes.string.isRequired
};

interface ReportModalType {
  readMode?: boolean;
  setCommentList: (comment: CommentListType) => void;
  setModalOn: MouseEventHandler;
  title: string;
  selectedYear: string;
  selectedQuarter: string;
  insertComment: (comment: string, title: string) => void;
  expertComment: string;
  reportList: ReportType[];
  commentList: CommentListType;
  expression: ExpressionType;
  gyejeongRow: SiljilType | GyejeongType[];
}

export function ReportModal(props: ReportModalType) {
  const reportRender = () => {
    let isNothing = true;
    let itemObject = {
      title: '',
      regdate: '',
      quarter: '',
      content: ''
    };

    console.log(props.reportList);

    props.reportList.forEach((item) => {
      if (
        isNothing === true &&
        props.title !== '' &&
        item.title.includes(props.title) &&
        item.quarter === props.selectedYear + '' + props.selectedQuarter
      ) {
        isNothing = false;
        itemObject = item;
      }
    });

    if (!isNothing) {
      return (
        <div
          style={{
            paddingLeft: '20px',
            paddingRight: '20px',
            borderBottom: '1px solid #ddd',
            paddingBottom: '50px',
            marginBottom: '50px'
          }}
        >
          <h1 style={{ fontWeight: 'bold', fontSize: '24px' }}>
            {itemObject.title}
          </h1>
          <div
            style={{
              color: '#aaa',
              fontSize: '16px',
              marginBottom: '100px'
            }}
          >
            작성날짜 : {itemObject.regdate}
          </div>
          <div style={{ padding: '0 0px 0 0px' }}>
            <Markdown
              options={{
                wrapper: 'article',
                overrides: {
                  h1: {
                    props: {
                      style: {
                        fontWeight: 'bold',
                        fontSize: '24px'
                      }
                    }
                  },

                  li: {
                    props: {
                      style: {
                        fontSize: '18px',
                        lineHeight: '2',
                        marginLeft: '20px'
                      }
                    }
                  },

                  ol: {
                    props: {
                      style: {
                        fontSize: '20px',
                        fontWeight: 'bold',
                        lineHeight: '2',
                        marginTop: '20px'
                      }
                    }
                  },

                  ul: {
                    props: {
                      style: {
                        fontSize: '18px',
                        lineHeight: '2',
                        listStyleType: 'disc',
                        marginLeft: '20px'
                      }
                    }
                  },

                  p: {
                    props: {
                      style: {
                        fontSize: '18px',
                        lineHeight: '2'
                      }
                    }
                  }
                }
              }}
            >
              {itemObject.content.replaceAll('\n', '\n\n')}
            </Markdown>
          </div>
        </div>
      );
    } else {
      return (
        <div
          style={{
            fontWeight: 'bold',
            marginTop: '300px',
            textAlign: 'center'
          }}
        >
          보고서가 존재하지 않습니다.
        </div>
      );
    }
  };

  const [comment, setComment] = useState('');

  useEffect(() => {
    setComment(props.expertComment);
  }, [props.expertComment]);

  return (
    <>
      <BlurBackground />

      <St_ReportCommentModal>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            gap: '50px'
          }}
        >
          {props.title !== '총평' && (
            <div style={{ width: '60%' }}>
              <St_Header>
                <St_Title>레포트</St_Title>
              </St_Header>

              <St_Content>{reportRender()}</St_Content>
            </div>
          )}

          <div
            style={
              props.title !== '총평' ? { width: '40%' } : { width: '100%' }
            }
          >
            <St_Header>
              <St_Title>{props.readMode ? '총평' : '총평 작성'}</St_Title>
              <St_Close
                onClick={props.setModalOn}
                className="material-symbols-outlined"
              >
                close
              </St_Close>
            </St_Header>

            <St_Content>
              {props.readMode && (
                <div
                  style={{
                    padding: '10px',
                    fontSize: '18px'
                  }}
                >
                  {comment}
                </div>
              )}
              {!props.readMode && (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '20px'
                  }}
                >
                  <St_AnswerSection>
                    <St_RadioTitle>
                      회원님의 현재 상태를 어떻게 보십니까?
                    </St_RadioTitle>
                    <TextField
                      value={props.commentList.comment_1 ?? ''}
                      onChange={(event) => {
                        const object = {
                          ...props.commentList
                        };
                        props.setCommentList({
                          ...object,
                          comment_1: event.target.value
                        });
                      }}
                      style={{ marginTop: '10px' }}
                    />
                  </St_AnswerSection>

                  <St_AnswerSection>
                    <St_RadioTitle>
                      회원님에게 추천하는 개선방향은?
                    </St_RadioTitle>
                    <TextField
                      value={props.commentList.comment_2 ?? ''}
                      onChange={(event) => {
                        const object = {
                          ...props.commentList
                        };
                        props.setCommentList({
                          ...object,
                          comment_2: event.target.value
                        });
                      }}
                      style={{ marginTop: '10px' }}
                    />
                  </St_AnswerSection>

                  <St_AnswerSection>
                    <St_RadioTitle>총평</St_RadioTitle>
                    <textarea
                      defaultValue={comment}
                      onChange={(e) => {
                        setComment(e.target.value);
                      }}
                      style={{
                        marginTop: '10px',
                        width: '100%',
                        height: '400px',
                        resize: 'none',
                        border: '1px solid #ddd',
                        padding: '20px'
                      }}
                    />
                  </St_AnswerSection>
                </div>
              )}
            </St_Content>
          </div>
        </div>

        {!props.readMode && (
          <Button
            variant="contained"
            onClick={() => {
              props.insertComment(comment, props.title);
            }}
            sx={{ display: 'block', marginLeft: 'auto' }}
          >
            저장
          </Button>
        )}
      </St_ReportCommentModal>
    </>
  );
}
