import { createSlice } from '@reduxjs/toolkit';

export interface InquiryDataType {
  id: number;
  answer_contents?: string;
  answer_date?: string;
  answer_status?: string;
  answer_title?: string;
  category: string;
  contents: string;
  expert_id?: number;
  expert_name?: string;
  question_date: string;
  status_chagned_date?: string;
  subject: string;
  writter_email: string;
  writter_id: number;
  writter_name: string;
  writter_to_expert: boolean;
}

export interface ExpertInquiryState {
  detailOn: boolean;
  target: InquiryDataType;
}

const initialState: ExpertInquiryState = {
  detailOn: false,
  target: {
    id: 0,
    answer_contents: '',
    answer_date: '',
    answer_status: '',
    answer_title: '',
    category: '',
    contents: '',
    expert_id: 0,
    expert_name: '',
    question_date: '',
    status_chagned_date: '',
    subject: '',
    writter_email: '',
    writter_id: 0,
    writter_name: '',
    writter_to_expert: false
  }
};
export const expertInquirySlice = createSlice({
  name: 'expertInquiry',
  initialState: initialState,
  reducers: {
    updateDetailOn: (state, action) => {
      state.detailOn = action.payload;
    },

    updateExpertInquiryTarget: (state, action) => {
      state.target = action.payload;
    }
  }
});

export const { updateExpertInquiryTarget, updateDetailOn } =
  expertInquirySlice.actions;

export default expertInquirySlice.reducer;
