import React from 'react';
import { TabButton } from '../../../components/Layout/TabButton';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { StoreState } from '../../../app/store';
import {
  updateDetailTab,
  updateOffset,
  updateTab,
  updateTarget
} from '../../../app/slice/userNotificationSlice';

const St_MemberWrapper = styled.div`
  margin-bottom: 60px;
  display: flex;
  align-items: center;
  gap: 40px;
`;

export function SmallTabList() {
  const dispatch = useDispatch();
  const { detailTab, detailTabList } = useSelector(
    (state: StoreState) => state.userNotification
  );

  function onClick(params: string) {
    dispatch(updateDetailTab(params));
  }

  return (
    <St_MemberWrapper>
      {detailTabList.map((tab) => (
        <TabButton
          key={tab}
          active={tab === detailTab}
          onClick={() => {
            onClick(tab);
          }}
        >
          {tab}
        </TabButton>
      ))}
    </St_MemberWrapper>
  );
}
