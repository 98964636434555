import React from 'react';
import styled from 'styled-components';

const Button = styled.div`
  border-radius: 5px;
  background-color: ${(props) => props.color};
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  color: #fff;
  text-align: center;
  line-height: 33px;
  align-items: center;
  font-size: 14px;
  display: flex;
  justify-content: center;
  gap: 5px;
  cursor: pointer;
`;

export function ManagerButton({ children, width, height, onClick }) {
  return (
    <Button color="#0068C4" onClick={onClick} width={width} height={height}>
      {children}
    </Button>
  );
}

export function PreviewButton({ children, width, height, onClick }) {
  return (
    <Button color="#4b97d3" onClick={onClick} width={width} height={height}>
      {children}
    </Button>
  );
}
