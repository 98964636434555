import styled from 'styled-components';
import React from 'react';
import { MenuItem, Select } from '@mui/material';

const PropertyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
`;

const Title = styled.span`
  font-weight: bold;
  font-size: 16px;
  color: #606975;
`;

const Input = styled.input`
  border-radius: 10px;
  border: 1px solid #ddd;
  font-size: 18px;
  padding: 7px 10px;
  color: rgba(13, 29, 51, 0.7);
  width: 100%;
`;

interface DetailPropertyProps {
  title: string;
  value: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

interface SelectPropertyProps {
  title: string;
  value: string;
  items: { value: string; title: string }[];
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export function ManagerInput(props: DetailPropertyProps) {
  return (
    <PropertyWrapper>
      <Title>{props.title}</Title>
      <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
        <Input onChange={props.onChange} value={props.value} />
      </div>
    </PropertyWrapper>
  );
}

export function ManagerSelect(props: SelectPropertyProps) {
  return (
    <PropertyWrapper>
      <Title>{props.title}</Title>
      <Select
        sx={{
          borderRadius: '10px',
          border: '2px solid #ddd',
          padding: '6px',
          color: 'rgba(13, 29, 51, 0.7)',
          fontSize: '18px',
          fontWeight: 'bold'
        }}
        defaultValue={props.value}
      >
        {props.items.map((item) => {
          return <MenuItem value={item.value}>{item.title}</MenuItem>;
        })}
      </Select>
    </PropertyWrapper>
  );
}
