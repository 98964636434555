import axios from 'axios';

export async function insertSubscription(
  email: string,
  subscription_type: string,
  subscription_end: string
) {
  const params = {
    email,
    subscription_type,
    subscription_end
  };

  const option = {
    url: 'https://cpi.contax.ai/subscription/',
    params: params,
    method: 'POST',
    header: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data'
    }
  };

  const result = await axios(option);
}
