function stripHtmlAndLimitLength(htmlString, maxLength) {
  // 브라우저의 DOM 파서를 사용하여 HTML을 분석
  const tempDiv = document.createElement('div');
  tempDiv.innerHTML = htmlString;

  // 모든 HTML 태그를 제거하고 순수 텍스트만 추출
  const text = tempDiv.textContent || tempDiv.innerText || '';

  // 지정된 최대 길이로 문자열 자르기
  return text.length > maxLength ? text.substring(0, maxLength) + '...' : text;
}

export default stripHtmlAndLimitLength;
