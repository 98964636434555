import React, { MouseEventHandler, ReactNode } from 'react';
import { ArrowDropDownIcon } from '@mui/x-date-pickers';
import styled from 'styled-components';

const TableElement = styled.div<{ size: 'small' | 'medium' | 'large' }>`
  width: 100%;
  ${(props) => props.size === 'small' && 'min-height: 380px;'};
  ${(props) => props.size === 'medium' && 'min-height: 580px;'};
  ${(props) => props.size === 'large' && 'min-height: 680px;'};
  height: 100%;
`;

const GridColumnSection = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const GridRowSection = styled.div`
  width: 100%;
  display: flex;
  cursor: pointer;
  border-bottom: 2px solid #eee;

  & > div:first-child {
    padding-left: 20px;
  }
`;

interface GridRowProps {
  children?: React.ReactNode;
  width: number;
  onClick?: MouseEventHandler;
  align?:
    | 'flex-start'
    | 'flex-end'
    | 'center'
    | 'space-between'
    | 'space-around'
    | 'space-evenly';
  backgroundColor?: string;
  color?: string;
  size: 'small' | 'medium' | 'large';
  textAlign?: 'center' | 'left' | 'right';
}

const GridRow = styled.div<GridRowProps>`
  display: flex;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: ${(props) => props.width + '%'};
  padding: 15px 0;

  ${(props) => props.align !== undefined && 'justify-content: ' + props.align};
  ${(props) =>
    props.backgroundColor !== undefined &&
    'background-color: ' + props.backgroundColor};
  color: rgba(26, 49, 85, 0.88);

  align-items: center;
  gap: 10px;

  ${(props) => props.size === 'small' && 'font-size: 14px;'}
  ${(props) => props.size === 'medium' && 'font-size: 14px;'}
    ${(props) => props.size === 'large' && 'font-size: 16px;'}

    &:nth-child(1) {
    ${(props) => props.size === 'small' && 'padding-left: 20px;'}
    ${(props) => props.size === 'medium' && 'padding-left: 20px;'}
        ${(props) => props.size === 'large' && 'padding-left: 50px;'}
  }

  &:last-child {
    ${(props) => props.size === 'small' && 'padding-right: 20px;'}
    ${(props) => props.size === 'medium' && 'padding-right: 20px;'}
        ${(props) => props.size === 'large' && 'padding-right: 50px;'}
  }

  word-break: break-word;
`;

const GridColumn = styled.div<GridRowProps>`
  display: flex;
  align-items: center;
  width: ${(props) => props.width + '%'};
  font-weight: 500;
  padding: 15px 0;
  text-align: ${(props) => props.textAlign};
  justify-content: ${(props) => props.textAlign};
  background-color: #1976d2;
  color: #fff;
  cursor: pointer;

  span {
    ${(props) => props.size === 'small' && 'font-size: 14px;'}
    ${(props) => props.size === 'medium' && 'font-size: 14px;'}
        ${(props) => props.size === 'large' && 'font-size: 16px;'}
  }

  &:nth-child(1) {
    ${(props) => props.size === 'small' && 'padding-left: 20px;'};
    ${(props) => props.size === 'medium' && 'padding-left: 20px;'};
    ${(props) => props.size === 'large' && 'padding-left: 20px;'};
  }

  &:last-child {
    ${(props) => props.size === 'small' && 'padding-right: 20px;'};
    ${(props) => props.size === 'medium' && 'padding-right: 20px;'};
    ${(props) => props.size === 'large' && 'padding-right: 50px;'};
  }
`;

interface GridRowProps {
  children?: React.ReactNode;
  width: number;
  onClick?: MouseEventHandler;
  align?:
    | 'flex-start'
    | 'flex-end'
    | 'center'
    | 'space-between'
    | 'space-around'
    | 'space-evenly';
  backgroundColor?: string;
  color?: string;
  size: 'small' | 'medium' | 'large';
  textAlign?: 'center' | 'left' | 'right';
}

export interface ColumnType {
  id: string;
  title: string;
  width: number;
  textAlign?: 'center' | 'left' | 'right';
  sort: boolean;
  display: boolean;
  children?: ColumnType[];
}

export interface RowType {
  id: string;
  value: ReactNode | string | boolean | number | null;
  width: number;
  onClick?: MouseEventHandler;
  align?:
    | 'flex-start'
    | 'flex-end'
    | 'center'
    | 'space-between'
    | 'space-around'
    | 'space-evenly';
  backgroundColor?: string;
  color?: string;
  textAlign?: 'center' | 'left' | 'right';
  display: boolean;
}

interface propType {
  size: 'small' | 'medium' | 'large';
  col: ColumnType[];
  sortFunction: (id: string) => void;
  onClick: (data: RowType[]) => void;
  datas: [RowType[]];
}

function Table(props: propType) {
  return (
    <TableElement size={props.size}>
      <GridColumnSection>
        {props.col.map((item, index) => {
          return (
            item.display && (
              <GridColumn
                size={props.size}
                onClick={
                  item.sort
                    ? () => {
                        props.sortFunction(item.id);
                      }
                    : () => {
                        console.log(123);
                      }
                }
                key={index}
                width={item.width}
                textAlign={item.textAlign}
              >
                <span
                  style={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                  }}
                >
                  {item.title}
                </span>
                {item.sort && (
                  <ArrowDropDownIcon
                    style={{ color: '#ddd', marginLeft: '10%' }}
                  />
                )}
              </GridColumn>
            )
          );
        })}
      </GridColumnSection>

      {props.datas.length <= 0 && (
        <div style={{ textAlign: 'center', padding: '200px 0' }}>
          데이터가 존재하지 않습니다.
        </div>
      )}

      {props.datas.length > 0 &&
        props.datas.map((data, index) => {
          return (
            data !== null && (
              <GridRowSection key={index}>
                {data.map((item, index) => {
                  if (item !== null) {
                    if (item.display) {
                      return (
                        <GridRow
                          backgroundColor={item.backgroundColor}
                          onClick={() => {
                            props.onClick(data);
                          }}
                          color={item.color}
                          textAlign={item.textAlign}
                          align={item.align}
                          size={props.size}
                          width={item.width}
                          key={index}
                        >
                          {item.value}
                        </GridRow>
                      );
                    }
                  }
                })}
              </GridRowSection>
            )
          );
        })}
    </TableElement>
  );
}

export default Table;
