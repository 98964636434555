import axios from 'axios';

async function getAllManageNotification(route) {
  try {
    const option = {
      url: 'https://cpi.contax.ai/get_all_mng_notification',
      method: 'GET',
      header: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    };

    const response = await axios(option);

    if (response.status === 200) {
      const array = [];
      response.data.forEach((item, index) => {
        if (item.target.includes(route)) {
          array.push(item.email);
        }
      });

      return array;
    } else {
      return response.status;
    }
  } catch (error) {
    return error;
  }
}

export { getAllManageNotification };
