import React from 'react';
import styled from 'styled-components';
import { CloseOutlined } from '@mui/icons-material';

const Box = styled.span`
  display: inline-flex;
  align-items: center;
  gap: 5px;
  background-color: #0066cc;
  padding: 8px 14px;
  border-radius: 50px;
  margin-right: 10px;
  margin-bottom: 10px;
  span {
    color: #fff;
    font-size: 12px;
  }
  cursor: pointer;
`;

interface PropTypes {
  name: string;
  email: string;
  onDelete?: () => void;
}

export function SelectedMember(props: PropTypes) {
  return (
    <Box onClick={props.onDelete}>
      <span>{`${props.name} ${props.email === '' ? props.email : `<${props.email}>`}`}</span>
      {props.onDelete !== undefined && (
        <CloseOutlined style={{ color: '#fff', width: '20px' }} />
      )}
    </Box>
  );
}
