/* eslint-disable react/jsx-pascal-case */
import React, { memo, useEffect, useState } from 'react';
import styled from 'styled-components';
import ExpandMore from '../../../asset/expand_more_FILL0_wght400_GRAD0_opsz24.svg';
import ExpandeLess from '../../../asset/expand_less_FILL0_wght400_GRAD0_opsz24.svg';
import {
  Button,
  Checkbox,
  FormControl,
  Input,
  MenuItem,
  Select
} from '@mui/material';
import { CheckBox, Delete, LineAxisTwoTone } from '@mui/icons-material';
import axios from 'axios';
import 'dayjs/locale/ko';

const St_MemberWrapper = styled.div`
  margin-bottom: 40px;
  display: flex;
  align-items: center;
  gap: 40px;
`;

const St_MemberTabButton = styled.span`
  font-size: 18px;
  font-weight: bold;
  color: #1a3155;
  cursor: pointer;
  transition: opacity 0.3s ease;
  opacity: ${(props) => (props.active ? '1' : '0.5')};

  &:hover {
    opacity: 1;
  }
`;

const St_SalesSection = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
`;

const St_SearchButton = styled.button`
  width: 100px;
  height: 40px;
  border-radius: 5px;
  background-color: blue;
  color: #fff;
`;

const St_TableColumn = styled.div`
  font-size: 14px;
  text-align: center;
  font-weight: bold;
  padding: 5px;
  width: ${(props) => props.width + '%'};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const St_TableRow = styled.div`
  font-size: 14px;
  height: auto;
  width: ${(props) => props.width + '%'};
  border-right: 1px solid #eee;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  padding: 15px 5px;
  background-color: ${(props) => (props.recall === true ? '#1976d291' : '')};
  color: #000;

  &:first-child {
    border-left: 1px solid #ddd;
  }
`;

const St_FilterCategoryTitle = styled.span`
  font-size: 20px;
  font-weight: bold;
  width: 100%;
  display: inline-block;
  cursor: pointer;
`;

const St_TableRowSection = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  background-color: ${(props) =>
    props.recall === true
      ? '#1976d291'
      : props.isShared === true
        ? 'rgba(25, 118, 210, 0.25)'
        : props.missing_number === true
          ? 'rgba(228, 0, 0, 0.14)'
          : props.isSaved === true
            ? 'rgba(0, 228, 42, 0.22)'
            : '#fff'};
  border-bottom: 1px solid #eee;
  border-top: 1px solid #eee;
  &:hover {
    background-color: ${(props) => (props) =>
      props.isShared === true
        ? 'rgba(25, 118, 210, 0.3)'
        : props.missing_number === true
          ? 'rgba(228, 0, 0, 0.2)'
          : props.isSaved === true
            ? 'rgba(0, 228, 42, 0.35)'
            : 'rgba(0,0,0,0.03)'};
  }
`;

const St_TableColumnSection = styled.div`
  display: flex;
  height: 50px;
  background-color: #1976d2;
  color: #fff;
  align-items: center;
`;

const St_FilterCategorySection = styled.div`
  padding: 20px;
  padding-top: 0px;
  padding-left: 0px;
  display: ${(props) => (props.categoryOn === true ? 'flex' : 'none')};
  flex-direction: column;
  gap: 20px;
`;

const St_ManagerSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 30%;
  padding: 0 30px 0 10px;
  margin-top: ${(props) =>
    props.division === 'sales' || props.division === 'contact_sales'
      ? '177px'
      : '220px'};
`;

const St_FilterSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 30%;
  padding: 0 30px 0 10px;
`;

const St_TableSection = styled.div`
  width: 70%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #fff;
`;

const St_FilterRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const St_FilterRowTitle = styled.span`
  font-size: 1rem;
  margin-bottom: 5px;
`;

const St_FilterRowInput = styled.input`
  border: 1px solid #ddd;
  padding: 5px;
`;

const St_RowDetailContent = styled.div`
  width: 100%;

  background-color: #fff;
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 10px;
  align-items: center;
`;

const St_RowDetailElement = styled.span`
  font-size: 16px;
`;

const St_TitleSection = styled.div`
  display: flex;
  justify-content: space-between;
`;

export function SalesSection({ children }) {
  return <St_SalesSection>{children}</St_SalesSection>;
}

export function FilterSection({ children }) {
  return <St_FilterSection>{children}</St_FilterSection>;
}

export function ManagerSection({ children, division }) {
  return <St_ManagerSection division={division}>{children}</St_ManagerSection>;
}

export function TableSection({ children }) {
  return <St_TableSection>{children}</St_TableSection>;
}

function calculatewithHourDDay(dateStr) {
  if (dateStr !== undefined) {
    const [year, month, day, hour] = dateStr
      .split('.')
      .map((num) => parseInt(num));
    const inputDate = new Date(year + 2000, month - 1, day, hour); // JS Date는 0부터 시작하는 월을 사용합니다.

    // 현재 시간을 가져옵니다.
    const now = new Date();

    // 두 날짜 사이의 차이를 계산합니다 (밀리초 단위).
    let diff = inputDate - now;

    if (isNaN(diff)) {
      diff = 0;
    }

    // 밀리초를 시간과 일수로 변환합니다.
    const hoursDiff = Math.floor(diff / (1000 * 60 * 60));
    const daysDiff = Math.floor(hoursDiff / 24);

    // 시간 차이에 따라 다른 문자열을 반환합니다.
    if (Math.abs(hoursDiff) < 24) {
      if (hoursDiff > 0) {
        return `\n ${hoursDiff}시간 전`; // 미래
      } else {
        return `\n ${Math.abs(hoursDiff)}시간 후`; // 과거
      }
    } else {
      if (daysDiff > 0) {
        return `\n D-${daysDiff}`; // 미래
      } else {
        return `\n D+${Math.abs(daysDiff)}`; // 과거
      }
    }
  } else {
    return '';
  }
  // 입력된 날짜 문자열을 Date 객체로 변환합니다.
}

function calculateDDay(dateStr) {
  if (dateStr !== undefined) {
    const [year, month, day] = dateStr.split('.').map((num) => parseInt(num));
    const inputDate = new Date(year + 2000, month - 1, day); // JS Date는 0부터 시작하는 월을 사용합니다.

    // 현재 시간을 가져옵니다.
    const now = new Date();
    now.setHours(0, 0, 0, 0); // 시간을 00:00:00으로 설정하여 날짜만 비교합니다.

    // 두 날짜 사이의 차이를 계산합니다 (밀리초 단위).
    const diff = inputDate - now;

    // 밀리초를 일수로 변환합니다.
    const daysDiff = Math.floor(diff / (1000 * 60 * 60 * 24));

    if (daysDiff > 0) {
      return `\n D-${daysDiff}`; // 미래 날짜
    } else if (daysDiff < 0) {
      return `\n D+${Math.abs(daysDiff)}`; // 과거 날짜
    } else {
      return '\n D-Day'; // 오늘 날짜
    }
  } else {
    return '';
  }
}

export function TableMemoRowSection({
  firstMemo,
  updateMissingNumber,
  updateDetail,
  firstCheckList,
  loadMemo,
  item,
  index,
  trans,
  updateMemo,
  moveToTrash,
  setCheckData,
  memo,
  setManagerMemo,
  selectedIndex,
  checkData,
  setTrans,
  setSelectedSalesIndex
}) {
  const [check, setCheck] = useState(false);
  const [buttonStyle, setButtonStyle] = useState({
    backgroundColor: '#ddd',
    borderRadius: '4px',
    fontSize: '12px',
    padding: '5px'
  });
  const [isSaved, setIsSaved] = useState(false);
  const division = localStorage.getItem('manager_division');

  useEffect(() => {
    setIsSaved(false);

    if (division === 'sales' && item.check_list.first_share_ok === true) {
      setIsSaved(true);
    } else if (
      division === 'sales_second' &&
      item.check_list.second_saved === true
    ) {
      setIsSaved(true);
    } else {
      setIsSaved(false);
    }
  }, [
    (division === 'sales' || division === 'sales_second') &&
      item.check_list.second_saved,
    (division === 'sales' || division === 'sales_second') &&
      item.check_list.first_share_ok
  ]);

  useEffect(() => {
    setCheck(false);

    if (item.ncrgsseq === checkData.ncrgsseq) {
      setCheck(true);
    }
  }, [checkData]);

  useEffect(() => {
    if (
      (item.manager_phonenumber !== '' && item.manager_phonenumber !== null) ||
      (item.phonenumber !== '' && item.phonenumber !== null) ||
      (item.email !== '' && item.email !== null) ||
      (item.fax !== '' && item.fax !== null) ||
      (item.manager !== '' && item.manager !== null)
    ) {
      setButtonStyle({
        backgroundColor: '#000',
        borderRadius: '4px',
        fontSize: '12px',
        padding: '5px',
        color: '#fff'
      });
    } else if (
      trans[index]['check_list'] !== undefined &&
      trans[index]['check_list']['call_memo'] !== '' &&
      trans[index]['check_list']['call_memo'] !== null
    ) {
      setButtonStyle({
        backgroundColor: '#888',
        borderRadius: '4px',
        fontSize: '12px',
        padding: '5px',
        color: '#fff'
      });
    } else {
      setButtonStyle({
        backgroundColor: '#ddd',
        borderRadius: '4px',
        fontSize: '12px',
        padding: '5px'
      });
    }
  }, [
    trans[index]['check_list']['call_memo'],
    item.manager_phonenumber,
    item.phonenumber,
    item.email,
    item.fax,
    item.manager,
    item.check_list,
    item.recall_true
  ]);

  return (
    <>
      <St_TableRowSection
        isSaved={isSaved}
        missing_number={item.missing_number}
        isShared={
          division === 'sales_second' &&
          item.check_list.is_second_saved !== null &&
          item.check_list.is_second_saved !== false &&
          item.check_list.is_second_saved !== ''
        }
      >
        <div
          style={
            check === true
              ? { width: '1%', backgroundColor: '#1976d2' }
              : { width: '1%' }
          }
          onClick={() => {
            loadMemo();
            setCheckData();
            setSelectedSalesIndex(index);
          }}
        ></div>
        {division === 'sales_second' && (
          <TableRow
            width={10}
            onClick={() => {
              loadMemo();
              setCheckData();
              setSelectedSalesIndex(index);
              check === false ? setCheck(true) : setCheck(false);
            }}
          >
            {item.check_list.re_call_true === undefined ||
            item.check_list.first_saved_date === null ||
            item.check_list.first_saved_date === false ||
            item.check_list.first_saved_date === ''
              ? ''
              : item.check_list.first_saved_date.substring(0, 10)}
          </TableRow>
        )}

        {division === 'sales' && (
          <TableRow
            width={10}
            onClick={() => {
              loadMemo();
              setCheckData();
              setSelectedSalesIndex(index);
              check === false ? setCheck(true) : setCheck(false);
            }}
          >
            {item.regdate === undefined
              ? item.latest_regdate.substring(0, 10)
              : item.regdate.substring(0, 10)}
          </TableRow>
        )}

        <TableRow
          width={division === 'sales' ? 20 : 10}
          onClick={() => {
            loadMemo();
            setCheckData();
            setSelectedSalesIndex(index);
            check === false ? setCheck(true) : setCheck(false);
          }}
        >
          {item.ncrgskname}
        </TableRow>
        <TableRow
          width={15}
          onClick={() => {
            loadMemo();
            setCheckData();
            setSelectedSalesIndex(index);
            check === false ? setCheck(true) : setCheck(false);
          }}
        >
          {item.ncrgsmaster}
        </TableRow>

        {division === 'sales' && (
          <TableRow
            width={15}
            recall={
              item.check_list.re_call_true === undefined ||
              item.check_list.re_call_true === null ||
              item.check_list.re_call_true === false
                ? false
                : true
            }
            onClick={() => {
              loadMemo();
              setCheckData();
              setSelectedSalesIndex(index);
              check === false ? setCheck(true) : setCheck(false);
            }}
          >
            {item.check_list.re_call_date === null ||
            item.check_list.re_call_date === ''
              ? item.ncrofftel + '\n'
              : item.ncrofftel +
                calculatewithHourDDay(item.check_list.re_call_date)}
          </TableRow>
        )}

        {division === 'sales_second' && (
          <TableRow
            width={15}
            recall={item.check_list.second_re_call_true}
            onClick={() => {
              loadMemo();
              setCheckData();
              setSelectedSalesIndex(index);
              check === false ? setCheck(true) : setCheck(false);
            }}
          >
            {item.check_list.second_re_call_date === null ||
            item.check_list.second_re_call_date === ''
              ? item.ncrofftel + '\n'
              : item.ncrofftel +
                calculatewithHourDDay(item.check_list.second_re_call_date)}
          </TableRow>
        )}

        {division === 'contact_sales' && (
          <TableRow
            width={15}
            onClick={() => {
              loadMemo();
              setCheckData();
              setSelectedSalesIndex(index);
              check === false ? setCheck(true) : setCheck(false);
            }}
          >
            {item.ncrofftel}
          </TableRow>
        )}

        <TableRow
          width={division === 'contact_sales' ? 6 : 16}
          onClick={() => {
            loadMemo();
            setCheckData();
            setSelectedSalesIndex(index);
            check === false ? setCheck(true) : setCheck(false);
          }}
        >
          {item.ncritemname}
        </TableRow>
        {(division === 'contact_sales' || division === 'sales_second') && (
          <TableRow
            width={8}
            onClick={() => {
              loadMemo();
              setCheckData();
              setSelectedSalesIndex(index);
              check === false ? setCheck(true) : setCheck(false);
            }}
          >
            {item.sales_manager}
          </TableRow>
        )}

        {item.rejection === true ? (
          <TableRow
            width={10}
            onClick={() => {
              loadMemo();
              setSelectedSalesIndex(index);
              setCheckData();
            }}
          >
            <button
              style={{
                display: 'flex',
                alignItems: 'center',
                backgroundColor: '#da5959',
                borderRadius: '4px',
                fontSize: '12px',
                padding: '5px 10px'
              }}
            >
              <span style={{ whiteSpace: 'nowrap', color: '#fff' }}>반려</span>
              <span
                style={{ fontSize: '16px', color: '#fff' }}
                className="material-symbols-outlined"
              >
                chevron_right
              </span>
            </button>
          </TableRow>
        ) : (
          <TableRow
            width={10}
            onClick={() => {
              loadMemo();
              setSelectedSalesIndex(index);
              setCheckData();
            }}
          >
            <button style={buttonStyle}>상세보기</button>
          </TableRow>
        )}

        {division === 'sales' && (
          <TableRow
            onClick={() => {
              loadMemo();
              setSelectedSalesIndex(index);
              setCheckData();
            }}
            width={7}
          >
            {item.classification}
          </TableRow>
        )}

        {division === 'sales_second' && (
          <TableRow width={7} onClick={moveToTrash}>
            <Delete />
          </TableRow>
        )}

        {division === 'sales' && (
          <TableRow
            width={8}
            onClick={() => {
              updateMissingNumber(item.ncrgsseq, !item.missing_number);
            }}
          >
            <input
              type="checkbox"
              checked={
                item.missing_number === false || item.missing_number === null
                  ? false
                  : true
              }
            />
          </TableRow>
        )}

        {division === 'sales_second' && (
          <TableRow
            onClick={() => {
              loadMemo();
              setSelectedSalesIndex(index);
              setCheckData();
            }}
            width={8}
          >
            {item.classification}
          </TableRow>
        )}
      </St_TableRowSection>
      {division === 'sales' || division === 'sales_second' ? (
        <RowDetailContent
          trans={trans}
          setTrans={(params) => {
            setTrans(params);
          }}
          setMemo={setManagerMemo}
          updateDetail={updateDetail}
          selectedIndex={selectedIndex}
          memos={memo}
          updateMemo={updateMemo}
          index={index}
          data={item}
          item={memo}
          detailOn={checkData.ncrgsseq === item.ncrgsseq}
        />
      ) : (
        <RowSecondDetailContent
          firstCheckList={firstCheckList}
          division={division}
          updateMemo={updateMemo}
          index={index}
          data={firstMemo}
          detailOn={checkData.ncrgsseq === item.ncrgsseq}
        />
      )}
    </>
  );
}

export function TableMemoRowContactSection({
  firstMemo,
  firstCheckList,
  loadMemo,
  item,
  index,
  trans,
  updateMemo,
  setCheckData,
  checkData,
  setTrans,
  setSelectedSalesIndex
}) {
  const [check, setCheck] = useState(false);
  const [buttonStyle, setButtonStyle] = useState({
    backgroundColor: '#ddd',
    borderRadius: '4px',
    fontSize: '12px',
    padding: '5px'
  });
  const [isSaved, setIsSaved] = useState(false);
  const division = localStorage.getItem('manager_division');
  const id = localStorage.getItem('id');
  const [rejectionOn, setRejectionOn] = useState(false);
  const [reason, setReason] = useState('');

  useEffect(() => {
    setIsSaved(false);

    if (division === 'sales' && item.check_list.first_share_ok === true) {
      setIsSaved(true);
    } else if (
      division === 'sales_second' &&
      item.check_list.second_saved === true
    ) {
      setIsSaved(true);
    } else {
      setIsSaved(false);
    }
  }, [
    (division === 'sales' || division === 'sales_second') &&
      item.check_list.second_saved,
    (division === 'sales' || division === 'sales_second') &&
      item.check_list.first_share_ok
  ]);

  useEffect(() => {
    setCheck(false);

    if (item.unique_key === checkData.unique_key) {
      setCheck(true);
    }
  }, [checkData]);

  useEffect(() => {
    if (division === 'contact_sales') {
      loadContactMemos();
    }
  }, [item]);

  async function loadContactMemos() {
    const memoOption = {
      url: 'https://cpi.contax.ai/load_sales_memo_by_ncrgsseq_contact/',
      method: 'POST',
      data: {
        user_id: id,
        ncrgsseq: item.unique_key
      },
      header: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    };

    await axios(memoOption).then((response) => {
      if (response.status === 200) {
      }
    });
  }

  async function insertRejection() {
    const option = {
      url: 'https://cpi.contax.ai/sales/insert_sales_rejection/',
      method: 'POST',
      data: {
        item: item.ncrgskname,
        ncrgsseq: item.unique_key,
        rejection_true: true,
        reason: reason,
        second_manager_id: item.check_list.writter,
        second_manager_name: item.check_list.writter_name,
        contact_manager_id: item.check_list.contact_manager_id,
        contact_manager_name: item.check_list.contact_manager_name
      },
      header: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    };

    await axios(option).then((response) => {
      if (response.status === 200) {
        alert('데이터가 정상적으로 반려처리 되었습니다.');
        const array = [...trans];
        array[index]['check_list'].contact_manager_id = 0;
        array[index]['check_list'].contact_manager_name = '';

        setTrans(array);
        setRejectionOn(false);
      }
    });
  }

  useEffect(() => {
    if (
      (item.manager_phonenumber !== '' && item.manager_phonenumber !== null) ||
      (item.phonenumber !== '' && item.phonenumber !== null) ||
      (item.email !== '' && item.email !== null) ||
      (item.fax !== '' && item.fax !== null) ||
      (item.manager !== '' && item.manager !== null)
    ) {
      setButtonStyle({
        backgroundColor: '#000',
        borderRadius: '4px',
        fontSize: '12px',
        padding: '5px',
        color: '#fff'
      });
    } else if (
      item.check_list !== undefined &&
      item.check_list.call_memo !== '' &&
      item.check_list.call_memo !== null
    ) {
      setButtonStyle({
        backgroundColor: '#888',
        borderRadius: '4px',
        fontSize: '12px',
        padding: '5px',
        color: '#fff'
      });
    } else {
      setButtonStyle({
        backgroundColor: '#ddd',
        borderRadius: '4px',
        fontSize: '12px',
        padding: '5px'
      });
    }
  }, [
    item.manager_phonenumber,
    item.phonenumber,
    item.email,
    item.fax,
    item.manager,
    item.check_list,
    item.recall_true
  ]);

  const [sharedDate, setSharedDate] = useState('');

  function getSharedDate(shared_date) {
    if (shared_date === null) {
      setSharedDate('날짜 없음');
      return;
    }

    if (shared_date.length < 10) {
      setSharedDate('날짜 없음');
      return;
    }

    setSharedDate(shared_date.substring(0, 10));
  }

  useEffect(() => {
    getSharedDate(item.shared_date);
  }, []);

  return (
    <>
      <St_TableRowSection
        isSaved={isSaved}
        missing_number={item.missing_number}
      >
        <div
          style={
            check === true
              ? { width: '1%', backgroundColor: '#1976d2' }
              : { width: '1%' }
          }
          onClick={() => {
            loadMemo();
            setCheckData();
            setSelectedSalesIndex(index);
          }}
        ></div>
        <TableRow
          width={10}
          onClick={() => {
            loadMemo();
            setCheckData();
            setSelectedSalesIndex(index);
            check === false ? setCheck(true) : setCheck(false);
          }}
        >
          {sharedDate}
        </TableRow>
        <TableRow
          width={16}
          onClick={() => {
            loadMemo();
            setCheckData();
            setSelectedSalesIndex(index);
            check === false ? setCheck(true) : setCheck(false);
          }}
        >
          {item.ncrgskname}
        </TableRow>
        <TableRow
          width={15}
          onClick={() => {
            loadMemo();
            setCheckData();
            setSelectedSalesIndex(index);
            check === false ? setCheck(true) : setCheck(false);
          }}
        >
          {item.ncrgsmaster}
        </TableRow>

        {division === 'contact_sales' && (
          <TableRow
            width={15}
            onClick={() => {
              loadMemo();
              setCheckData();
              setSelectedSalesIndex(index);
              check === false ? setCheck(true) : setCheck(false);
            }}
          >
            {item.ncrofftel}
          </TableRow>
        )}

        <TableRow
          width={25}
          onClick={() => {
            loadMemo();
            setCheckData();
            setSelectedSalesIndex(index);
            check === false ? setCheck(true) : setCheck(false);
          }}
        >
          {item.license}
        </TableRow>
        <TableRow
          width={10}
          onClick={() => {
            loadMemo();
            setSelectedSalesIndex(index);
            setCheckData();
            check === false ? setCheck(true) : setCheck(false);
          }}
        >
          <button style={buttonStyle}>상세보기</button>
        </TableRow>

        <TableRow
          width={8}
          onClick={() => {
            setRejectionOn(!rejectionOn);
          }}
        >
          <input type="checkbox" checked={item.rejection} />
        </TableRow>

        {rejectionOn && (
          <div
            style={{
              width: '400px',
              height: '300px',
              border: '1px solid #ddd',
              padding: '20px',
              zIndex: 9,
              position: 'absolute',
              backgroundColor: '#fff',
              right: '0px',
              marginTop: '50px'
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between'
              }}
            >
              <h2 style={{ fontSize: '16px', fontWeight: 'bold' }}>
                반려사유 입력
              </h2>
              <span
                onClick={() => {
                  setRejectionOn(false);
                }}
                style={{ cursor: 'pointer' }}
              >
                X
              </span>
            </div>

            <textarea
              value={reason}
              onChange={(e) => {
                setReason(e.target.value);
              }}
              defaultValue={reason}
              style={{
                resize: 'none',
                border: '1px solid #ddd',
                width: '100%',
                marginTop: '20px',
                height: '60%',
                padding: '10px'
              }}
            />
            <div style={{ display: 'flex', justifyContent: 'end' }}>
              <button
                style={{
                  marginTop: '15px',
                  backgroundColor: '#ddd',
                  padding: '10px 20px',
                  borderRadius: '5px',
                  fontSize: '14px'
                }}
                onClick={() => {
                  insertRejection(trans, index);
                }}
              >
                저장
              </button>
            </div>
          </div>
        )}
      </St_TableRowSection>

      <RowSecondDetailContent
        firstCheckList={firstCheckList}
        division={division}
        updateMemo={updateMemo}
        index={index}
        trans={trans}
        data={firstMemo}
        detailOn={check}
      />
    </>
  );
}

export function TableHistoryRowSection({ item }) {
  return (
    <>
      <St_TableRowSection>
        <TableRow width={20}>{item.date.substring(0, 11)}</TableRow>
        {item.type === 'insert' && (
          <TableRow width={80}>
            '{item.name}' 님이 '{item.item}' 의 데이터를 '{item.target}' 님에게
            공유하였습니다.
          </TableRow>
        )}

        {item.type === 'save' && (
          <TableRow width={80}>
            '{item.name}' 님이 '{item.item}' 의 데이터를 저장하였습니다.
          </TableRow>
        )}
      </St_TableRowSection>
    </>
  );
}

export function DashboardTableHistoryRowSection({ onClick, item }) {
  const [contactBook, setContactBook] = useState();
  const [contactComp, setContactComp] = useState();

  useEffect(() => {
    let book = 0;
    let comp = 0;

    item.items.forEach((data) => {
      if (data.is_second_saved === true) {
        book += 1;
      }

      if (data.is_third_saved === true) {
        comp += 1;
      }
    });

    setContactBook(book);
    setContactComp(comp);
  }, [item]);

  return (
    <>
      <St_TableRowSection
        onClick={() => {
          onClick(item.items);
        }}
      >
        <TableRow width={25}>{item.name}</TableRow>
        <TableRow width={18} style={{ fontSize: '12px' }}>
          {item.items.length + '건\n(100%)'}
        </TableRow>
        <TableRow width={18} style={{ fontSize: '12px' }}>
          {contactBook +
            '건\n(' +
            Math.ceil((contactBook / item.items.length) * 100) +
            '%)'}
        </TableRow>
        <TableRow width={18} style={{ fontSize: '12px' }}>
          {contactComp +
            '건\n(' +
            Math.ceil((contactComp / item.items.length) * 100) +
            '%)\n(' +
            Math.ceil(
              (isNaN(contactComp / contactBook)
                ? 0
                : contactComp / contactBook) * 100
            ) +
            '%)'}
        </TableRow>
        <TableRow width={21}>
          {' '}
          <button
            style={{
              backgroundColor: '#ddd',
              borderRadius: '4px',
              fontSize: '12px',
              padding: '5px'
            }}
          >
            상세보기
          </button>{' '}
        </TableRow>
      </St_TableRowSection>
    </>
  );
}

export function DashboardTableRejectionRowSection({ onClick, item }) {
  return (
    <>
      <St_TableRowSection>
        <TableRow width={20}>{item.item}</TableRow>
        <TableRow width={20} style={{ fontSize: '12px' }}>
          {item.date.substring(0, 10)}
        </TableRow>
        <TableRow width={40} style={{ fontSize: '12px' }}>
          {item.reason}
        </TableRow>
        <TableRow width={10} style={{ fontSize: '12px' }}>
          {item.rejection_true === true ? 'O' : 'X'}
        </TableRow>
        <TableRow width={10} style={{ fontSize: '12px' }}>
          {item.resolve === true ? 'O' : 'X'}
        </TableRow>
      </St_TableRowSection>
    </>
  );
}

export function DashboardTableHistoryDetailSection({ item }) {
  return (
    <>
      <St_TableRowSection>
        <TableRow width={40}>{item.name}</TableRow>
        <TableRow width={30}>
          {item.is_second_saved === null ||
          item.is_second_saved === false ||
          item.is_second_saved === '' ? (
            <Checkbox checked={false} />
          ) : (
            <Checkbox checked={true} />
          )}
        </TableRow>
        <TableRow width={30}>
          {item.is_third_saved === null ||
          item.is_third_saved === false ||
          item.is_third_saved === '' ? (
            <Checkbox checked={false} />
          ) : (
            <Checkbox checked={true} />
          )}
        </TableRow>
      </St_TableRowSection>
    </>
  );
}

export function TableRowSection({ item, setCheckData, checkData }) {
  const [detailOn, setDetailOn] = useState(false);
  const [check, setCheck] = useState(false);

  useEffect(() => {
    checkData.forEach((jtem) => {
      if (item.ncrgsseq === jtem.ncrgsseq) {
        setCheck(true);
      }
    });
  }, [checkData]);

  useEffect(() => {
    var ok = false;

    checkData.forEach((jtem) => {
      if (item.ncrgsseq === jtem.ncrgsseq) {
        ok = true;
      }
    });

    if (ok === true) {
      setCheck(true);
    } else {
      setCheck(false);
    }
  }, [item]);

  return (
    <>
      <St_TableRowSection>
        <TableRow
          width={10}
          onClick={() => {
            setCheckData(item);
          }}
        >
          <input type="checkbox" checked={check} />
        </TableRow>
        <TableRow
          width={20}
          onClick={() => {
            setCheckData(item);
            check === false ? setCheck(true) : setCheck(false);
          }}
        >
          {item.ncrgskname}
        </TableRow>
        <TableRow
          width={15}
          onClick={() => {
            setCheckData(item);
            check === false ? setCheck(true) : setCheck(false);
          }}
        >
          {item.ncrgsmaster}
        </TableRow>
        <TableRow
          width={20}
          onClick={() => {
            setCheckData(item);
            check === false ? setCheck(true) : setCheck(false);
          }}
        >
          {item.ncrofftel}
        </TableRow>
        <TableRow
          width={25}
          onClick={() => {
            setCheckData(item);
            check === false ? setCheck(true) : setCheck(false);
          }}
        >
          {item.ncritemname}
        </TableRow>
        <TableRow
          width={10}
          onClick={() => {
            setDetailOn(!detailOn);
          }}
        >
          <button
            style={{
              backgroundColor: '#ddd',
              borderRadius: '4px',
              fontSize: '12px',
              padding: '5px'
            }}
          >
            상세보기
          </button>
        </TableRow>
      </St_TableRowSection>
      <RowDetailContent data={item} detailOn={detailOn} />
    </>
  );
}

export function MemoRowSection({ item }) {
  const [detailOn, setDetailOn] = useState(false);
  return (
    <>
      <St_TableRowSection>
        <TableRow width={10}>
          <input type="checkbox" />
        </TableRow>
        <TableRow width={20}>{item.ncrgskname}</TableRow>
        <TableRow width={15}>{item.ncrgsmaster}</TableRow>
        <TableRow width={20}>{item.ncrofftel}</TableRow>
        <TableRow width={25}>{item.ncritemname}</TableRow>

        <TableRow
          width={10}
          onClick={() => {
            setDetailOn(!detailOn);
          }}
        >
          <button
            style={{
              backgroundColor: '#ddd',
              borderRadius: '4px',
              fontSize: '12px',
              padding: '5px'
            }}
          >
            상세보기
          </button>
        </TableRow>
      </St_TableRowSection>
      <RowDetailContent data={item} detailOn={detailOn} />
    </>
  );
}

export function TableManagerSection({ item, checkManager, setCheckManager }) {
  return (
    <>
      <St_TableRowSection
        onClick={() => {
          setCheckManager(item.user_id);
        }}
      >
        <TableRow width={20}>
          <input type="radio" checked={checkManager === item.user_id} />
        </TableRow>
        <TableRow width={80}>{item.name}</TableRow>
      </St_TableRowSection>
    </>
  );
}

export function TableMemoSection({
  trans,
  setTrans,
  removeSecondSaved,
  removeSecondManager,
  selectedSalesIndex,
  updateSchedule,
  selectedSalesSecond,
  salesManager,
  setSelectedSalesSecond,
  managerMemo,
  index,
  setMemo,
  setFirstCheckList,
  firstCheckList,
  setSelectedIndex,
  selectedIndex,
  updateMemo,
  item,
  division
}) {
  let today = new Date();

  let year = today.getFullYear(); // 년도
  let month = today.getMonth() + 1; // 월
  let date = today.getDate(); // 날짜
  return (
    <>
      {division === 'sales' || division === 'sales_second' ? (
        <>
          <div
            style={{
              display: 'flex',
              borderBottom: '1px solid #ddd'
            }}
            onClick={() => {
              selectedIndex === index
                ? setSelectedIndex(-1)
                : setSelectedIndex(index);
            }}
          >
            <TableRow width={30}>
              {managerMemo.shared_date !== undefined
                ? managerMemo.shared_date.substring(0, 10)
                : year + '-' + month + '-' + date}
            </TableRow>
            <TableRow width={40}>{managerMemo.title}</TableRow>
            <TableRow width={30}>
              <button
                style={
                  managerMemo.is_saved === true &&
                  JSON.parse(managerMemo.items)['시간 체크_time'] !== ''
                    ? {
                        backgroundColor: 'black',
                        borderRadius: '4px',
                        fontSize: '12px',
                        padding: '5px',
                        color: '#fff'
                      }
                    : managerMemo.is_second_saved === true &&
                        JSON.parse(managerMemo.items)['시간 체크_time'] !== ''
                      ? {
                          backgroundColor: 'black',
                          borderRadius: '4px',
                          fontSize: '12px',
                          padding: '5px',
                          color: '#fff'
                        }
                      : {
                          backgroundColor: '#ddd',
                          borderRadius: '4px',
                          fontSize: '12px',
                          padding: '5px'
                        }
                }
              >
                {managerMemo.is_saved === true
                  ? '메모완료'
                  : managerMemo.is_second_saved === true &&
                      JSON.parse(managerMemo.items)['시간 체크_time'] !== ''
                    ? '메모완료'
                    : '메모작성'}
              </button>
            </TableRow>
            {JSON.parse(managerMemo.items)['시간 체크_time'] === '' ? (
              <></>
            ) : (
              <TableRow width={20}>
                {calculatewithHourDDay(
                  JSON.parse(managerMemo.items)['시간 체크_time']
                )}
              </TableRow>
            )}
          </div>
          <RowDetailMemo
            trans={trans}
            removeSecondSaved={removeSecondSaved}
            removeSecondManager={removeSecondManager}
            selectedSalesIndex={selectedSalesIndex}
            setTrans={setTrans}
            updateSchedule={updateSchedule}
            selectedSalesSecond={selectedSalesSecond}
            setSelectedSalesSecond={setSelectedSalesSecond}
            salesManager={salesManager}
            setFirstCheckList={setFirstCheckList}
            firstCheckList={firstCheckList}
            item={managerMemo}
            detailOn={selectedIndex === index ? true : false}
            setMemo={setMemo}
            updateMemo={updateMemo}
          />
        </>
      ) : (
        <>
          <div
            style={{
              display: 'flex',
              borderBottom: '1px solid #ddd'
            }}
            onClick={() => {
              selectedIndex === index
                ? setSelectedIndex(-1)
                : setSelectedIndex(index);
            }}
          >
            <TableRow width={30}>
              {managerMemo.shared_date !== undefined
                ? managerMemo.shared_date.substring(0, 10)
                : year + '-' + month + '-' + date}
            </TableRow>
            <TableRow width={40}>{managerMemo.title}</TableRow>
            <TableRow width={30}>
              <button
                style={
                  managerMemo.is_saved === true
                    ? {
                        backgroundColor: 'black',
                        borderRadius: '4px',
                        fontSize: '12px',
                        padding: '5px',
                        color: '#fff'
                      }
                    : {
                        backgroundColor: '#ddd',
                        borderRadius: '4px',
                        fontSize: '12px',
                        padding: '5px'
                      }
                }
              >
                {managerMemo.is_saved === true ? '메모완료' : '메모작성'}
              </button>
            </TableRow>
            {managerMemo !== '' &&
              (JSON.parse(managerMemo.items)['시간 체크_time'] === '' ? (
                <></>
              ) : (
                <TableRow width={20}>
                  {calculateDDay(
                    JSON.parse(managerMemo.items)['시간 체크_time']
                  )}
                </TableRow>
              ))}
          </div>
          <RowSecondDetailMemo
            item={item}
            setFirstCheckList={setFirstCheckList}
            firstCheckList={firstCheckList}
            detailOn={selectedIndex === index ? true : false}
            setMemo={setMemo}
            updateMemo={updateMemo}
          />
        </>
      )}
    </>
  );
}

export function TableRow({ recall, style, width, onClick, children }) {
  return (
    <St_TableRow style={style} recall={recall} onClick={onClick} width={width}>
      {children}
    </St_TableRow>
  );
}

export function TableColumnSection({ children }) {
  return <St_TableColumnSection>{children}</St_TableColumnSection>;
}

export function TableColumn({ width, children, onClick }) {
  return (
    <St_TableColumn width={width} onClick={onClick}>
      {children}
    </St_TableColumn>
  );
}

export function FilterRowCategory({ title, children, menuOn }) {
  const [categoryOn, setCategoryOn] = useState(menuOn);
  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <St_FilterCategoryTitle
          onClick={() => {
            setCategoryOn(!categoryOn);
          }}
        >
          {title}
        </St_FilterCategoryTitle>
        <img
          src={categoryOn === true ? ExpandeLess : ExpandMore}
          alt="ExpandMore"
        />
      </div>

      <St_FilterCategorySection categoryOn={categoryOn}>
        {children}
      </St_FilterCategorySection>
    </>
  );
}

export function FilterRow({ title, filter, setFilters }) {
  return (
    <St_FilterRow>
      <St_FilterRowTitle>{title}</St_FilterRowTitle>
      <St_FilterRowInput value={filter} onChange={setFilters} />
    </St_FilterRow>
  );
}

export function SearchButton({ onClick }) {
  return <St_SearchButton onClick={onClick}>검색</St_SearchButton>;
}

export function TitleSection({ children, style }) {
  return <St_TitleSection style={style}>{children}</St_TitleSection>;
}

const St_DetailContentSection = styled.div`
  min-height: 400px;
  height: auto;
  padding: 20px;
  display: ${(props) => (props.detailOn === true ? 'block' : 'none')};
`;

const St_DetailMemo = styled.textarea`
  border: 1px solid #eee;
  margin: 10px 0;
  padding: 10px;
  height: 100%;
  resize: none;
`;

const St_MemoSection = styled.div`
  display: flex;
  width: 50%;
  flex-direction: column;
  position: relative;
`;

export function RowSecondDetailContent({
  firstCheckList,
  updateMemo,
  data,
  detailOn,
  index,
  trans,
  division
}) {
  const [tab, setTab] = useState('상호 상세 내용');

  function clickTab(text) {
    setTab(text);
  }

  return (
    <div
      style={{
        borderLeft: '1px solid #eee',
        borderRight: '1px solid #eee',
        borderBottom: '1px solid #eee',
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'normal'
      }}
    >
      <St_DetailContentSection detailOn={detailOn}>
        <St_MemberWrapper>
          <St_MemberTabButton
            onClick={() => {
              clickTab('상호 상세 내용');
            }}
            active={tab === '상호 상세 내용'}
          >
            상호 상세 내용
          </St_MemberTabButton>
          <St_MemberTabButton
            onClick={() => {
              clickTab('상세정보');
            }}
            active={tab === '상세정보'}
          >
            상세정보
          </St_MemberTabButton>
        </St_MemberWrapper>
        {tab === '상호 상세 내용' && (
          <div
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              gap: '20px'
            }}
          >
            <div
              style={{
                display: 'flex'
              }}
            >
              <span
                style={{
                  width: '30%',
                  fontSize: '15px',
                  fontWeight: 'bold'
                }}
              >
                공유 날짜
              </span>
              <span
                style={{
                  width: '70%',
                  fontSize: '15px',
                  color: '#555'
                }}
              >
                {trans[index].shared_date}
              </span>
            </div>

            <div
              style={{
                display: 'flex'
              }}
            >
              <span
                style={{
                  width: '30%',
                  fontSize: '15px',
                  fontWeight: 'bold'
                }}
              >
                주소
              </span>
              <span
                style={{
                  width: '70%',
                  fontSize: '15px',
                  color: '#555'
                }}
              >
                {trans[index]['address'] ?? '-'}
              </span>
            </div>
            <div
              style={{
                display: 'flex'
              }}
            >
              <span
                style={{
                  width: '30%',
                  fontSize: '15px',
                  fontWeight: 'bold'
                }}
              >
                상호명
              </span>
              <span
                style={{
                  width: '70%',
                  fontSize: '15px',
                  color: '#555'
                }}
              >
                {trans[index]['ncrgskname']}
              </span>
            </div>

            <div
              style={{
                display: 'flex'
              }}
            >
              <span
                style={{
                  width: '30%',
                  fontSize: '15px',
                  fontWeight: 'bold'
                }}
              >
                전화번호
              </span>
              <span
                style={{
                  width: '70%',
                  fontSize: '15px',
                  color: '#555'
                }}
              >
                {trans[index]['ncrofftel']}
              </span>
            </div>

            <div
              style={{
                display: 'flex'
              }}
            >
              <span
                style={{
                  width: '30%',
                  fontSize: '15px',
                  fontWeight: 'bold'
                }}
              >
                사업자번호
              </span>
              <span
                style={{
                  width: '70%',
                  fontSize: '15px',
                  color: '#555'
                }}
              >
                {trans[index]['ncrmasternum'] ?? '-'}
              </span>
            </div>

            <div
              style={{
                display: 'flex'
              }}
            >
              <span
                style={{
                  width: '30%',
                  fontSize: '15px',
                  fontWeight: 'bold'
                }}
              >
                대표자 이름
              </span>
              <span
                style={{
                  width: '70%',
                  fontSize: '15px',
                  color: '#555'
                }}
              >
                {trans[index]['ncrgsmaster'] ?? '-'}
              </span>
            </div>
          </div>
        )}
        {tab === '상세정보' && (
          <div style={{ display: 'flex' }}>
            <div style={{ width: '50%' }}>
              <St_RowDetailContent>
                {firstCheckList.map((checkList, index) => {
                  if (
                    checkList.type === 'check' &&
                    (checkList.value === true || checkList.value === false)
                  ) {
                    return (
                      <>
                        <St_RowDetailElement>
                          {checkList.name}
                        </St_RowDetailElement>

                        <input type="checkbox" checked={checkList.value} />
                      </>
                    );
                  } else if (
                    checkList.type === 'input' &&
                    !Array.isArray(checkList.value)
                  ) {
                    return (
                      <>
                        <St_RowDetailElement>
                          {checkList.name} :{' '}
                        </St_RowDetailElement>
                        <span style={{ fontSize: '14px' }}>
                          {checkList.value}
                        </span>
                      </>
                    );
                  } else if (
                    checkList.type === 'time' &&
                    !Array.isArray(checkList.value)
                  ) {
                    return (
                      <>
                        <St_RowDetailElement>
                          {checkList.name} :{' '}
                        </St_RowDetailElement>
                        <span
                          style={{
                            marginRight: '60px',
                            fontSize: '16px',
                            textAlign: 'center'
                          }}
                        >
                          {checkList.value}
                        </span>
                      </>
                    );
                  } else {
                    return (
                      <>
                        <h4
                          style={{
                            marginTop: '20px',
                            fontSize: '16px',
                            fontWeight: 'bold'
                          }}
                        >
                          {checkList.name}
                        </h4>
                        <span></span>
                        {checkList.value.map((jtem) => {
                          return (
                            <>
                              <St_RowDetailElement>
                                {jtem.name}
                              </St_RowDetailElement>
                              <input
                                type="checkbox"
                                checked={checkList.value}
                              />
                            </>
                          );
                        })}
                        <h4
                          style={{
                            marginTop: '20px',
                            fontSize: '14px'
                          }}
                        ></h4>
                        <span></span>
                      </>
                    );
                  }
                })}
              </St_RowDetailContent>
            </div>

            <St_MemoSection>
              <h3 style={{ fontWeight: 'bold' }}>기타 사항</h3>
              <span></span>
              <St_DetailMemo
                placeholder="메모가 존재하지 않습니다."
                disabled
                defaultValue={
                  data.memo === null || data.memo === '' ? '' : data.memo
                }
                value={data.memo === null || data.memo === '' ? '' : data.memo}
              />
            </St_MemoSection>
          </div>
        )}
      </St_DetailContentSection>
      {division === 'sales' && detailOn && (
        <Button
          onClick={updateMemo}
          sx={{
            marginLeft: 'auto',
            marginRight: '20px',
            marginBottom: '20px'
          }}
          variant="contained"
        >
          저장
        </Button>
      )}
    </div>
  );
}

export function RowDetailContent({
  managerMemos,
  setMemo,
  updateDetail,
  data,
  detailOn,
  index,
  trans,
  setTrans,
  memos
}) {
  const [reCallDate, setRecallDate] = useState('');
  const [now, setNow] = useState('');
  const [modalOn, setModalOn] = useState(false);
  const division = localStorage.getItem('manager_division');
  const [classification, setClassification] = useState('');

  useEffect(() => {
    const nowString = new Date(); // 현재 날짜와 시간

    const year = nowString.getFullYear();
    const month = nowString.getMonth() + 1; // getMonth()는 0부터 시작하므로 1을 더해줍니다.
    const date = nowString.getDate();

    // 시간, 분을 구하고 오전/오후를 결정합니다.
    let hours = nowString.getHours();
    const minutes = nowString.getMinutes();
    const ampm = hours >= 12 ? '오후' : '오전';

    // 12시간 형식으로 변환: 13시 -> 1시, 14시 -> 2시, ...
    hours = hours % 12;
    hours = hours ? hours : 12; // 0시는 12시로 표시

    // 시간과 분이 항상 두 자리 숫자로 표시되도록 조정합니다.
    const strHours = hours < 10 ? '0' + hours : hours.toString();
    const strMinutes = minutes < 10 ? '0' + minutes : minutes.toString();

    // 최종 문자열을 구성합니다.
    const timeString =
      year +
      '년 ' +
      month +
      '월 ' +
      date +
      '일 ' +
      ampm +
      ' ' +
      strHours +
      ':' +
      strMinutes;
    setNow(timeString);

    console.log(data.classification);
  }, []);

  useEffect(() => {
    if (data.classification === undefined || data.classification === null) {
      setClassification('');
    } else {
      setClassification(data.classification);
    }
  }, [data]);

  async function updateRecall(ncrgsseq, recall_true, recall_date) {
    const option = {
      url: 'https://cpi.contax.ai/update_recall',
      method: 'PUT',
      params: {
        ncrgsseq: ncrgsseq,
        recall_true: recall_true,
        recall_date: recall_date
      },
      header: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    };

    await axios(option).then((response) => {
      if (response.status === 200) {
      }
    });
  }

  async function updateResolve(trans, index) {
    if (!window.confirm('정말 해결 처리 하시겠습니까?')) {
      return false;
    }

    const option = {
      url: 'https://cpi.contax.ai/sales/update_rejection_resolve',
      method: 'PUT',
      data: {
        ncrgsseq: trans[index].ncrgsseq,
        second_manager_id: trans[index].check_list.second_manager_id,
        contact_manager_id: trans[index].rejection_item.contact_manager_id
      },
      header: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    };

    await axios(option).then((response) => {
      if (response.status === 200) {
        alert('해당 데이터의 해결처리가 완료되었습니다.');

        const array = [...trans];
        array[index]['rejection'] = false;
        setTrans(array);
      }
    });
  }

  async function updateSecondRecall(ncrgsseq, recall_true, recall_date) {
    const option = {
      url: 'https://cpi.contax.ai/update_second_recall',
      method: 'PUT',
      params: {
        ncrgsseq: ncrgsseq,
        recall_true: recall_true,
        recall_date: recall_date
      },
      header: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    };

    await axios(option).then((response) => {
      if (response.status === 200) {
      }
    });
  }

  async function insertSalesClassify(
    ncrgsseq,
    item,
    classification,
    first_manager_id,
    first_manager_name
  ) {
    const user_id = localStorage.getItem('id');
    const name = localStorage.getItem('name');

    let data = {};

    if (division === 'sales') {
      data = {
        ncrgsseq: ncrgsseq,
        classification: classification,
        item: item,
        first_manager_id: Number(user_id),
        first_manager_name: name,
        is_second: false
      };
    }

    if (division === 'sales_second') {
      data = {
        ncrgsseq: ncrgsseq,
        classification: classification,
        item: item,
        first_manager_id: Number(first_manager_id),
        first_manager_name: first_manager_name,
        second_manager_id: Number(user_id),
        second_manager_name: name,
        is_second: true
      };
    }

    const option = {
      url: 'https://cpi.contax.ai/sales/insert_sales_classify',
      method: 'POST',
      data: data,
      header: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    };

    await axios(option).then((response) => {
      if (response.status === 200) {
      }
    });
  }

  return (
    <div
      style={{
        borderLeft: '1px solid #eee',
        borderRight: '1px solid #eee',
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'normal'
      }}
    >
      <St_DetailContentSection detailOn={detailOn}>
        {trans[index].rejection === true && (
          <div
            style={{
              paddingBottom: '30px',
              margin: '30px',
              borderBottom: '1px solid #ddd'
            }}
          >
            <h2
              style={{
                marginBottom: '20px',
                fontSize: '16px',
                fontWeight: 'bold'
              }}
            >
              반려
            </h2>

            <div
              style={{
                display: 'flex',
                gap: '20px',
                justifyContent: 'space-between'
              }}
            >
              <div
                style={{
                  width: '47%',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '10px'
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column'
                  }}
                >
                  <span
                    style={{
                      fontSize: '15px',
                      fontWeight: 'bold'
                    }}
                  >
                    반려 상호명
                  </span>
                  <span
                    style={{
                      fontSize: '15px',
                      color: '#555'
                    }}
                  >
                    {trans[index].rejection_item.item}
                  </span>
                </div>

                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column'
                  }}
                >
                  <span
                    style={{
                      fontSize: '15px',
                      fontWeight: 'bold'
                    }}
                  >
                    방문 영업사원
                  </span>
                  <span
                    style={{
                      fontSize: '15px',
                      color: '#555'
                    }}
                  >
                    {trans[index].rejection_item.contact_manager_name}
                  </span>
                </div>

                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column'
                  }}
                >
                  <span
                    style={{
                      fontSize: '15px',
                      fontWeight: 'bold'
                    }}
                  >
                    2차 관리자
                  </span>
                  <span
                    style={{
                      fontSize: '15px',
                      color: '#555'
                    }}
                  >
                    {trans[index].rejection_item.second_manager_name}
                  </span>
                </div>

                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column'
                  }}
                >
                  <span
                    style={{
                      fontSize: '15px',
                      fontWeight: 'bold'
                    }}
                  >
                    반려 상호명
                  </span>
                  <span
                    style={{
                      fontSize: '15px',
                      color: '#555'
                    }}
                  >
                    {trans[index].rejection_item.item}
                  </span>
                </div>

                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column'
                  }}
                >
                  <span
                    style={{
                      fontSize: '15px',
                      fontWeight: 'bold'
                    }}
                  >
                    반려된 날짜
                  </span>
                  <span
                    style={{
                      fontSize: '15px',
                      color: '#555'
                    }}
                  >
                    {trans[index].rejection_item.date}
                  </span>
                </div>
              </div>

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '53%'
                }}
              >
                <span
                  style={{
                    fontSize: '15px',
                    fontWeight: 'bold',
                    marginBottom: '10px'
                  }}
                >
                  반려 사유
                </span>
                <textarea
                  defaultValue={trans[index].rejection_item.reason}
                  disabled
                  style={{
                    resize: 'none',
                    height: '100%',
                    border: '1px solid #ddd',
                    padding: '10px'
                  }}
                />
                <button
                  onClick={() => {
                    updateResolve(trans, index);
                  }}
                  style={{
                    width: '100px',
                    backgroundColor: '#1976d2',
                    marginLeft: 'auto',
                    marginTop: '10px',
                    color: '#fff',
                    fontSize: '14px',
                    borderRadius: '5px',
                    padding: '5px 0'
                  }}
                >
                  반려 해결
                </button>
              </div>
            </div>
          </div>
        )}
        <div style={{ display: 'flex' }}>
          <St_RowDetailContent>
            <h3 style={{ fontWeight: 'bold' }}>상세 내용</h3>
            <span></span>
            <St_RowDetailElement>이메일 : </St_RowDetailElement>{' '}
            <St_RowDetailElement>
              <Input
                placeholder="이메일 입력"
                sx={{ fontSize: '12px' }}
                value={data.email === null ? '' : data.email}
                onChange={(e) => {
                  const array = [...trans];
                  array[index]['email'] = e.target.value;
                  setTrans(array);
                }}
                onBlur={() => {
                  updateDetail(index);
                }}
              />
            </St_RowDetailElement>
            <St_RowDetailElement>대표자 휴대전화 : </St_RowDetailElement>
            <St_RowDetailElement>
              <Input
                placeholder="대표자 휴대폰 입력"
                sx={{ fontSize: '12px' }}
                value={data.phonenumber === null ? '' : data.phonenumber}
                onChange={(e) => {
                  const array = [...trans];
                  array[index]['phonenumber'] = e.target.value;
                  setTrans(array);
                }}
                onBlur={() => {
                  updateDetail(index);
                }}
              />
            </St_RowDetailElement>
            <St_RowDetailElement>담당자 휴대전화 : </St_RowDetailElement>
            <St_RowDetailElement>
              <Input
                placeholder="휴대전화/이름/직책 입력"
                sx={{ fontSize: '12px' }}
                value={
                  data.manager_phonenumber === null
                    ? ''
                    : data.manager_phonenumber
                }
                onChange={(e) => {
                  const array = [...trans];
                  array[index]['manager_phonenumber'] = e.target.value;
                  setTrans(array);
                }}
                onBlur={() => {
                  updateDetail(index);
                }}
              />
            </St_RowDetailElement>
            <St_RowDetailElement>팩스 : </St_RowDetailElement>
            <St_RowDetailElement>
              <Input
                placeholder="팩스번호 입력"
                sx={{ fontSize: '12px' }}
                value={data.fax === null ? '' : data.fax}
                onChange={(e) => {
                  const array = [...trans];
                  array[index]['fax'] = e.target.value;
                  setTrans(array);
                }}
                onBlur={() => {
                  updateDetail(index);
                }}
              />
            </St_RowDetailElement>
            <St_RowDetailElement>담당자 : </St_RowDetailElement>
            <St_RowDetailElement>
              <Input
                placeholder="담당자 입력"
                sx={{ fontSize: '12px' }}
                value={data.manager === null ? '' : data.manager}
                onChange={(e) => {
                  const array = [...trans];
                  array[index]['manager'] = e.target.value;
                  setTrans(array);
                }}
                onBlur={() => {
                  updateDetail(index);
                }}
              />
            </St_RowDetailElement>
            <St_RowDetailElement>등록시군구 : </St_RowDetailElement>
            <St_RowDetailElement>{data.ncrareadetailname}</St_RowDetailElement>
            <St_RowDetailElement>등록시도 : </St_RowDetailElement>
            <St_RowDetailElement>{data.ncrareaname}</St_RowDetailElement>
            <St_RowDetailElement>상세주소 : </St_RowDetailElement>
            <St_RowDetailElement>{data.ncrgsaddr}</St_RowDetailElement>
            <St_RowDetailElement>등록일자 : </St_RowDetailElement>
            <St_RowDetailElement>{data.ncrgsdate}</St_RowDetailElement>
            <St_RowDetailElement>공시내용 구분 : </St_RowDetailElement>
            <St_RowDetailElement>{data.ncrgsflag}</St_RowDetailElement>
            <St_RowDetailElement>공고번호 : </St_RowDetailElement>
            <St_RowDetailElement>{data.ncrgsnumber}</St_RowDetailElement>
            <St_RowDetailElement>변경사유철회 : </St_RowDetailElement>
            <St_RowDetailElement>{data.ncrgsreason}</St_RowDetailElement>
            <St_RowDetailElement>공시일자 : </St_RowDetailElement>
            <St_RowDetailElement>{data.ncrgsregdate}</St_RowDetailElement>
            <St_RowDetailElement>공시일련번호 : </St_RowDetailElement>
            <St_RowDetailElement>{data.ncrgsseq}</St_RowDetailElement>
            <St_RowDetailElement>업종등록번호 : </St_RowDetailElement>
            <St_RowDetailElement>{data.ncritemregno}</St_RowDetailElement>
            <St_RowDetailElement>사업자등록번호 : </St_RowDetailElement>
            <St_RowDetailElement>{data.ncrmasternum}</St_RowDetailElement>
            <St_RowDetailElement>최근 편집시간 : </St_RowDetailElement>
            <St_RowDetailElement>
              {memos.recent_edit !== undefined &&
                memos !== '' &&
                memos.recent_edit !== null &&
                memos.recent_edit.substring(0, 16)}
            </St_RowDetailElement>
          </St_RowDetailContent>
          <St_MemoSection>
            <h3 style={{ fontWeight: 'bold' }}>메모 작성</h3>

            {(division === 'sales' || division === 'sales_second') && (
              <div
                style={{
                  marginTop: '10px',
                  display: 'flex',
                  justifyContent: 'space-between'
                }}
              >
                <span>가망 등급 분류</span>
                <div style={{ display: 'flex', gap: '20px' }}>
                  <div
                    style={{
                      display: 'flex',
                      gap: '10px',
                      cursor: 'pointer'
                    }}
                    onClick={() => {
                      const array = [...trans];
                      array[index]['classification'] =
                        array[index]['classification'] === '낮음' ? '' : '낮음';
                      setTrans(array);
                      insertSalesClassify(
                        data.ncrgsseq,
                        data.ncrgskname,
                        array[index]['classification'],
                        data.check_list.writter,
                        data.check_list.writter_name
                      );
                      classification === '낮음'
                        ? setClassification('')
                        : setClassification('낮음');
                    }}
                  >
                    <span>낮음</span>
                    <input
                      type="checkbox"
                      checked={trans[index]['classification'] === '낮음'}
                    />
                  </div>

                  <div
                    style={{
                      display: 'flex',
                      gap: '10px',
                      cursor: 'pointer'
                    }}
                    onClick={() => {
                      const array = [...trans];
                      array[index]['classification'] =
                        array[index]['classification'] === '보통' ? '' : '보통';
                      setTrans(array);
                      insertSalesClassify(
                        data.ncrgsseq,
                        data.ncrgskname,
                        array[index]['classification'],
                        data.check_list.writter,
                        data.check_list.writter_name
                      );
                      classification === '보통'
                        ? setClassification('')
                        : setClassification('보통');
                    }}
                  >
                    <span>보통</span>
                    <input
                      type="checkbox"
                      checked={trans[index]['classification'] === '보통'}
                    />
                  </div>

                  <div
                    style={{
                      display: 'flex',
                      gap: '10px',
                      cursor: 'pointer'
                    }}
                    onClick={() => {
                      const array = [...trans];
                      array[index]['classification'] =
                        array[index]['classification'] === '높음' ? '' : '높음';
                      setTrans(array);
                      insertSalesClassify(
                        data.ncrgsseq,
                        data.ncrgskname,
                        array[index]['classification'],
                        data.check_list.writter,
                        data.check_list.writter_name
                      );
                      classification === '높음'
                        ? setClassification('')
                        : setClassification('높음');
                    }}
                  >
                    <span>높음</span>
                    <input
                      type="checkbox"
                      checked={trans[index]['classification'] === '높음'}
                    />
                  </div>
                </div>
              </div>
            )}

            <St_DetailMemo
              placeholder="메모를 입력해주세요"
              onChange={(e) => {
                const array = [...trans];
                array[index]['check_list']['call_memo'] = e.target.value;
                setTrans(array);
              }}
              onKeyDown={(e) => {
                if (e.key === 'F5') {
                  e.preventDefault(); // Prevent the default action of F5
                  const now = new Date();

                  const timeString =
                    now.getFullYear() +
                    '년 ' +
                    (now.getMonth() + 1) +
                    '월 ' +
                    now.getDate() +
                    '일 ' +
                    now.toLocaleTimeString() +
                    '\n> ';

                  // Update the state with the new time
                  const array = [...trans];
                  // Append the current time to the existing memo text
                  array[index]['check_list']['call_memo'] += timeString;

                  setTrans(array);
                }
              }}
              onBlur={() => {
                updateDetail(index);
              }}
              value={
                trans[index]['check_list']['call_memo'] !== undefined &&
                trans[index] !== '' &&
                trans[index]['check_list']['call_memo'] !== null
                  ? trans[index]['check_list']['call_memo'].replaceAll(null, '')
                  : ''
              }
            />

            {memos['re_call_date'] === null ||
            memos['re_call_date'] === '' ||
            memos['re_call_date'] === undefined ? (
              <span></span>
            ) : (
              memos['re_call_true'] &&
              division === 'sales' && (
                <span style={{ marginBottom: '20px' }}>
                  {'\n 재연락 날짜 : ' + memos['re_call_date']}
                </span>
              )
            )}

            {memos['second_re_call_date'] === null ||
            memos['second_re_call_date'] === '' ||
            memos['second_re_call_date'] === undefined ? (
              <span></span>
            ) : (
              memos['second_re_call_true'] &&
              division === 'sales_second' && (
                <span style={{ marginBottom: '20px' }}>
                  {'\n 재연락 날짜 : ' + memos['second_re_call_date']}
                </span>
              )
            )}

            <div
              onClick={() => {
                if (division === 'sales') {
                  if (
                    memos.re_call_true === false ||
                    memos.re_call_true === null
                  ) {
                    setModalOn(true);
                  } else {
                    updateRecall(data.ncrgsseq, false, '');
                    alert('재연락을 취소하였습니다.');

                    const array = [...trans];

                    array[index]['check_list']['re_call_true'] = false;
                    array[index]['check_list']['re_call_date'] = '';

                    setTrans(array);

                    const object = { ...memos };
                    object.re_call_true = false;
                    object.re_call_date = '';
                    setMemo(object);
                  }
                }

                if (division === 'sales_second') {
                  if (
                    memos.second_re_call_true === false ||
                    memos.second_re_call_true === null
                  ) {
                    setModalOn(true);
                  } else {
                    updateSecondRecall(data.ncrgsseq, false, '');
                    alert('재연락을 취소하였습니다.');

                    const array = [...trans];
                    array[index]['check_list']['second_re_call_true'] = false;
                    array[index]['check_list']['second_re_call_date'] = '';
                    setTrans(array);

                    const object = { ...memos };
                    object.second_re_call_true = false;
                    object.second_re_call_date = '';
                    setMemo(object);
                  }
                }
              }}
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '10px',
                cursor: 'pointer',
                width: '100px'
              }}
            >
              {division === 'sales' && (
                <input
                  type="checkbox"
                  checked={
                    memos.re_call_true === false ||
                    memos.re_call_true === '' ||
                    memos.re_call_true === null
                      ? false
                      : true
                  }
                />
              )}
              {division === 'sales_second' && (
                <input
                  type="checkbox"
                  checked={
                    memos.second_re_call_true === false ||
                    memos.second_re_call_true === '' ||
                    memos.second_re_call_true === null
                      ? false
                      : true
                  }
                />
              )}
              <span>재연락</span>
            </div>

            {modalOn && (
              <div
                style={{
                  padding: '10px',
                  backgroundColor: '#fff',
                  width: '350px',
                  bottom: '65px',
                  height: 'auto',
                  border: '1px solid #ddd',
                  position: 'absolute',
                  display: 'flex',
                  flexDirection: 'column'
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between'
                  }}
                >
                  <h3 style={{ fontWeight: 'bold' }}>재연락 날짜 입력</h3>
                  <span
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      setModalOn(false);
                    }}
                  >
                    X
                  </span>
                </div>

                <input
                  style={{
                    border: '1px solid #ddd',
                    padding: '5px 10px ',
                    marginTop: '10px'
                  }}
                  value={reCallDate}
                  onChange={(e) => {
                    setRecallDate(e.target.value);
                  }}
                />
                <span
                  style={{
                    marginTop: '10px',
                    fontSize: '12px',
                    color: '#aaa'
                  }}
                >
                  {'형식에 맞게 입력해주세요. 예) 24.01.01.17'}
                </span>
                <button
                  onClick={() => {
                    const regex =
                      /^\d{2}\.((0[1-9])|(1[0-2]))\.((0[1-9])|([1-2][0-9])|(3[0-1]))\.([0-1][0-9]|2[0-3])$/;
                    if (!regex.test(reCallDate)) {
                      alert(
                        '날짜 형식이 맞지 않습니다. 형식에 맞게 수정해주세요 \n 예) 24.01.01.17'
                      );
                      return false;
                    } else {
                      alert('해당 데이터 재연락 날짜를 남겼습니다.');

                      if (division === 'sales') {
                        const object = { ...memos };
                        object.re_call_true = true;
                        setMemo(object);
                        updateRecall(data.ncrgsseq, true, reCallDate);

                        const memoObject = { ...memos };
                        memoObject.re_call_true = true;
                        memoObject.re_call_date = reCallDate;
                        setMemo(memoObject);

                        const array = [...trans];
                        object.re_call_date = reCallDate;
                        array[index]['check_list']['re_call_true'] = true;
                        array[index]['check_list']['re_call_date'] = reCallDate;

                        setTrans(array);

                        setModalOn(false);
                      } else if (division === 'sales_second') {
                        const object = { ...memos };
                        object.second_re_call_true = true;
                        setMemo(object);
                        updateSecondRecall(data.ncrgsseq, true, reCallDate);

                        const array = [...trans];
                        object.second_re_call_date = reCallDate;
                        array[index]['check_list']['second_re_call_true'] =
                          true;
                        array[index]['check_list']['second_re_call_date'] =
                          reCallDate;
                        setTrans(array);

                        setModalOn(false);
                      }
                    }
                  }}
                  style={{
                    padding: '5px',
                    border: '1px solid #ddd',
                    borderRadius: '5px',
                    marginTop: '10px',
                    backgroundColor: '#eee'
                  }}
                >
                  저장
                </button>
              </div>
            )}
          </St_MemoSection>
        </div>
      </St_DetailContentSection>
    </div>
  );
}

const St_Textarea = styled.textarea`
  font-size: 14px;
  border: 1px solid #ddd;
  padding: 10px;
  resize: none;
  height: 150px;
  width: 100%;
`;
const St_RowDetailMemo = styled.div`
  padding: 20px;
  border-left: 1px solid #eee;
  border-right: 1px solid #eee;
  border-bottom: 1px solid #eee;
  border-top: 1px solid #eee;
  width: 100%;
  height: auto;
  background-color: #fff;

  display: ${(props) => (props.detailOn === true ? 'flex' : 'none')};
  flex-direction: column;
`;

const St_Checklist = styled.div`
  padding: 5px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
`;

export function RowDetailMemo({
  trans,
  setTrans,
  removeSecondSaved,
  removeSecondManager,
  selectedSalesIndex,
  selectedSalesSecond,
  setSelectedSalesSecond,
  salesManager,
  item,
  setMemo,
  detailOn,
  updateMemo,
  setFirstCheckList,
  firstCheckList
}) {
  const division = localStorage.getItem('manager_division');
  const user_id = localStorage.getItem('id');
  const [now, setNow] = useState('');
  useEffect(() => {
    const nowString = new Date(); // 현재 날짜와 시간

    const year = nowString.getFullYear();
    const month = nowString.getMonth() + 1; // getMonth()는 0부터 시작하므로 1을 더해줍니다.
    const date = nowString.getDate();

    // 시간, 분을 구하고 오전/오후를 결정합니다.
    let hours = nowString.getHours();
    const minutes = nowString.getMinutes();
    const ampm = hours >= 12 ? '오후' : '오전';

    // 12시간 형식으로 변환: 13시 -> 1시, 14시 -> 2시, ...
    hours = hours % 12;
    hours = hours ? hours : 12; // 0시는 12시로 표시

    // 시간과 분이 항상 두 자리 숫자로 표시되도록 조정합니다.
    const strHours = hours < 10 ? '0' + hours : hours.toString();
    const strMinutes = minutes < 10 ? '0' + minutes : minutes.toString();

    // 최종 문자열을 구성합니다.
    const timeString =
      year +
      '년 ' +
      month +
      '월 ' +
      date +
      '일 ' +
      ampm +
      ' ' +
      strHours +
      ':' +
      strMinutes;
    setNow(timeString);
  }, []);

  return (
    <St_RowDetailMemo detailOn={detailOn}>
      {firstCheckList.map((checkList, index) => {
        if (
          checkList.type === 'check' &&
          (checkList.value === true || checkList.value === false)
        ) {
          return (
            <>
              <RowDetailMemoCheckList
                onClick={() => {
                  const array = [...firstCheckList];
                  array[index].value = !checkList.value;
                  setFirstCheckList(array);
                }}
                checked={checkList.value}
                title={checkList.name}
              />
              <div
                style={{
                  margin: '10px 0',
                  border: '1px solid #eee',
                  borderStyle: 'dotted'
                }}
              />
            </>
          );
        } else if (
          checkList.type === 'input' &&
          typeof checkList.value !== 'object'
        ) {
          return (
            <>
              <RowDetailMemoInput
                onChange={(params) => {
                  const array = [...firstCheckList];
                  array[index].value = params;
                  setFirstCheckList(array);
                }}
                value={checkList.value}
                title={checkList.name}
              />
              <div
                style={{
                  margin: '10px 0',
                  border: '1px solid #eee',
                  borderStyle: 'dotted'
                }}
              />
            </>
          );
        } else if (
          checkList.type === 'time' &&
          typeof checkList.value !== 'object'
        ) {
          return (
            <>
              <RowDetailMemoTime
                onChange={(params) => {
                  const array = [...firstCheckList];
                  array[index].value = params;
                  setFirstCheckList(array);
                }}
                placeholder={now}
                value={checkList.value}
                title={checkList.name}
              />
              <div
                style={{
                  margin: '10px 0',
                  border: '1px solid #eee',
                  borderStyle: 'dotted'
                }}
              />
            </>
          );
        } else {
          return (
            <>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '10px'
                }}
              >
                <h3 style={{ fontWeight: 'bold' }}>{checkList.name}</h3>
                {checkList.value.map((jtem, jndex) => {
                  return (
                    <RowDetailMemoCheckList
                      checked={jtem.value}
                      title={jtem.name}
                      onClick={() => {
                        const array = [...firstCheckList];
                        array[index].value[jndex].value = !jtem.value;

                        setFirstCheckList(array);
                      }}
                    />
                  );
                })}
              </div>
              <div
                style={{
                  margin: '10px 0',
                  border: '1px solid #eee',
                  borderStyle: 'dotted'
                }}
              />
            </>
          );
        }
      })}

      <h3
        style={{
          fontWeight: 'bold',
          marginTop: '20px',
          marginBottom: '10px'
        }}
      >
        기타 메모 작성
      </h3>
      <St_Textarea
        placeholder="메모를 입력해주세요"
        onChange={(e) => {
          const object = { ...item };
          object.memo = e.target.value;

          setMemo(object);
        }}
        onKeyDown={(e) => {
          if (e.key === 'F5') {
            e.preventDefault(); // Prevent the default action of F5
            const now = new Date();

            const timeString =
              now.getFullYear() +
              '년 ' +
              (now.getMonth() + 1) +
              '월 ' +
              now.getDate() +
              '일 ' +
              now.toLocaleTimeString() +
              '\n> ';
            // Update the state with the new time
            const array = { ...item };
            // Append the current time to the existing memo text
            array['memo'] += timeString;

            setMemo(array);
          }
        }}
        defaultValue={
          item.memo !== null && item.memo !== ''
            ? item.memo.replaceAll(null, '')
            : ''
        }
        value={
          item.memo !== null && item.memo !== ''
            ? item.memo.replaceAll(null, '')
            : ''
        }
      />

      {division === 'sales' && (
        <>
          <h3
            style={{
              fontWeight: 'bold',
              marginTop: '20px',
              marginBottom: '10px'
            }}
          >
            2차 관리자 설정
          </h3>
          {
            <FormControl>
              <Select
                defaultValue={selectedSalesSecond.id || 0}
                value={selectedSalesSecond.id || 0}
              >
                <MenuItem
                  onClick={() => {
                    setSelectedSalesSecond({
                      id: 0,
                      name: '선택'
                    });
                  }}
                  value={0}
                >
                  선택
                </MenuItem>
                {salesManager.map((item) => {
                  if (item.manager_division === 'sales_second') {
                    return (
                      <MenuItem
                        onClick={() => {
                          setSelectedSalesSecond({
                            id: item.user_id,
                            name: item.name
                          });
                        }}
                        value={item.user_id}
                      >
                        {item.name}
                      </MenuItem>
                    );
                  }
                })}
              </Select>
            </FormControl>
          }
        </>
      )}

      <div style={{ display: 'flex', gap: '10px' }}>
        {division === 'sales' &&
          trans[selectedSalesIndex] !== undefined &&
          trans[selectedSalesIndex].check_list.first_share_ok && (
            <Button
              variant="outlined"
              sx={{ marginTop: '30px', marginLeft: 'auto' }}
              onClick={() => {
                removeSecondManager(
                  Number(user_id),
                  Number(
                    trans[selectedSalesIndex].check_list.second_manager_id
                  ),
                  trans[selectedSalesIndex].check_list.targetMasterNumber
                );
              }}
            >
              취소
            </Button>
          )}

        {division === 'sales_second' &&
          trans[selectedSalesIndex] !== undefined &&
          trans[selectedSalesIndex].check_list.is_second_saved && (
            <Button
              variant="outlined"
              sx={{ marginTop: '30px', marginLeft: 'auto' }}
              onClick={() => {
                removeSecondSaved(
                  Number(user_id),
                  trans[selectedSalesIndex].check_list.targetMasterNumber
                );
              }}
            >
              취소
            </Button>
          )}

        <Button
          variant="contained"
          sx={{ marginTop: '30px', marginLeft: 'auto' }}
          onClick={() => {
            updateMemo();
          }}
        >
          저장
        </Button>
      </div>
    </St_RowDetailMemo>
  );
}

export function RowSecondDetailMemo({
  setFirstCheckList,
  firstCheckList,
  item,
  setMemo,
  detailOn,
  updateMemo
}) {
  const [now, setNow] = useState('');
  useEffect(() => {
    const nowString = new Date();
    setNow(
      nowString.getFullYear() +
        '년 ' +
        (nowString.getMonth() + 1) +
        '월 ' +
        nowString.getDate() +
        '일 ' +
        nowString.toLocaleTimeString()
    );
  }, []);

  return (
    <St_RowDetailMemo detailOn={detailOn}>
      {firstCheckList.map((checkList, index) => {
        if (
          checkList.type === 'check' &&
          (checkList.value === true || checkList.value === false)
        ) {
          return (
            <>
              <RowDetailMemoCheckList
                onClick={() => {
                  const array = [...firstCheckList];
                  array[index].value = !checkList.value;
                  setFirstCheckList(array);
                }}
                checked={checkList.value}
                title={checkList.name}
              />
              <div
                style={{
                  margin: '10px 0',
                  border: '1px solid #eee',
                  borderStyle: 'dotted'
                }}
              />
            </>
          );
        } else if (checkList.type === 'input') {
          return (
            <>
              <RowDetailMemoInput
                onChange={(params) => {
                  const array = [...firstCheckList];
                  array[index].value = params;
                  setFirstCheckList(array);
                }}
                value={checkList.value}
                title={checkList.name}
              />
              <div
                style={{
                  margin: '10px 0',
                  border: '1px solid #eee',
                  borderStyle: 'dotted'
                }}
              />
            </>
          );
        } else if (
          checkList.type === 'time' &&
          typeof checkList.value !== 'object'
        ) {
          return (
            <>
              <RowDetailMemoTime
                onChange={(params) => {
                  const array = [...firstCheckList];
                  array[index].value = params;
                  setFirstCheckList(array);
                }}
                placeholder={now}
                value={checkList.value}
                title={checkList.name}
              />
              <div
                style={{
                  margin: '10px 0',
                  border: '1px solid #eee',
                  borderStyle: 'dotted'
                }}
              />
            </>
          );
        } else {
          return (
            <>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '10px'
                }}
              >
                <h3 style={{ fontWeight: 'bold' }}>{checkList.name}</h3>
                {checkList.value.map((jtem, jndex) => {
                  return (
                    <RowDetailMemoCheckList
                      checked={jtem.value}
                      title={jtem.name}
                      onClick={() => {
                        const array = [...firstCheckList];
                        array[index].value[jndex].value = !jtem.value;
                        setFirstCheckList(array);
                      }}
                    />
                  );
                })}
              </div>
              <div
                style={{
                  margin: '10px 0',
                  border: '1px solid #eee',
                  borderStyle: 'dotted'
                }}
              />
            </>
          );
        }
      })}

      <St_Textarea
        placeholder="메모를 입력해주세요"
        onChange={(e) => {
          const object = { ...item };
          object.comment = e.target.value;
          setMemo(object);
        }}
        defaultValue={
          item.comment === '' || item.comment === null ? '' : item.comment
        }
        value={item.comment === '' || item.comment === null ? '' : item.comment}
      />

      <Button
        variant="contained"
        sx={{
          backgroundColor: '#999',
          color: 'white',
          marginTop: '20px'
        }}
        onClick={updateMemo}
      >
        저장
      </Button>
    </St_RowDetailMemo>
  );
}

function RowDetailMemoCheckList({ title, checked, onClick }) {
  return (
    <St_Checklist onClick={onClick}>
      <span>{title}</span>
      <input type="checkbox" checked={checked} />
    </St_Checklist>
  );
}

function RowDetailMemoInput({ title, value, onChange, placeholder }) {
  return (
    <St_Checklist>
      <span>{title}</span>
      <input
        placeholder={placeholder}
        style={{
          border: '1px solid #ddd',
          padding: '5px 10px',
          fontSize: '14px'
        }}
        onChange={(e) => {
          onChange(e.target.value);
        }}
        type="text"
        value={value}
      />
    </St_Checklist>
  );
}

function RowDetailMemoTime({ title, value, onChange, placeholder }) {
  return (
    <div>
      <St_Checklist>
        <span>{title}</span>
        <input
          placeholder={placeholder}
          style={{
            border: '1px solid #ddd',
            padding: '5px 10px',
            fontSize: '14px'
          }}
          onChange={(e) => {
            onChange(e.target.value);
          }}
          type="text"
          value={value}
        />
      </St_Checklist>
      <span style={{ fontSize: '12px', color: '#aaa' }}>
        {'형식에 맞게 입력해주세요. 예) 24.01.01.17'}
      </span>
    </div>
  );
}
