import React from 'react';

interface Props {
  styleClass?: string;
  children: React.ReactNode;
}

function Subtitle(props: Props): JSX.Element {
  return (
    <div className={`text-xl font-semibold ${props.styleClass}`}>
      {props.children}
    </div>
  );
}

export default Subtitle;
