import styled from 'styled-components';
import React from 'react';
import { MenuItem, Select } from '@mui/material';
import { useDispatch } from 'react-redux';
import {
  updateCreateMemberInformation,
  updateManager,
  updateTargetMemberInformation
} from '../../../app/slice/memberManagementSlice';

const PropertyWrapper = styled.div<{ size: 'short' | 'medium' | 'long' }>`
  display: flex;
  flex-direction: column;
  gap: 10px;
  ${(props) => props.size === 'short' && 'width: 25%;'}
  ${(props) => props.size === 'medium' && 'width: 50%;'}
    ${(props) => props.size === 'long' && 'width: 100%;'}
`;

const Title = styled.span`
  font-weight: bold;
  font-size: 14px;
  color: #606975;
`;

const Input = styled.input`
  border-radius: 10px;
  border: 2px solid #ddd;
  font-size: 18px;
  padding: 10px;
  color: rgba(13, 29, 51, 0.7);
`;

interface MemberDetailPropertyProps {
  title: string;
  value: string;
  isModify?: boolean;
  id: string;
  size: 'short' | 'medium' | 'long';
}

export function MemberDetailProperty(props: MemberDetailPropertyProps) {
  const dispatch = useDispatch();

  return (
    <PropertyWrapper size={props.size}>
      <Title>{props.title}</Title>
      <Input
        onChange={(e) => {
          dispatch(
            updateTargetMemberInformation({
              id: props.id,
              value: e.target.value
            })
          );
        }}
        disabled={props.isModify === undefined ? true : !props.isModify}
        value={props.value}
      />
    </PropertyWrapper>
  );
}

interface MemberDetailSelectPropertyDefaultProps {
  title: string;
  value: string;
  isModify?: boolean;
  id: string;
  size: 'short' | 'medium' | 'long';
  item: { value: any; title: string }[];
  onChange: (value: any) => void;
}

interface MemberDetailSelectPropertyProps {
  title: string;
  value: string;
  isModify?: boolean;
  id: string;
  size: 'short' | 'medium' | 'long';
  item: { value: any; title: string }[];
}

export function MemberDetailSelectProperty(
  props: MemberDetailSelectPropertyProps
) {
  const dispatch = useDispatch();

  return (
    <PropertyWrapper size={props.size}>
      <Title>{props.title}</Title>
      <Select
        sx={{
          borderRadius: '10px',
          border: '1px solid #ddd',
          padding: '6px',
          color: 'rgba(13, 29, 51, 0.7)',
          fontSize: '16px',
          fontWeight: 'bold'
        }}
        disabled={props.isModify === undefined ? true : !props.isModify}
        defaultValue={props.value}
        value={props.value}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          dispatch(updateManager(e.target.value));
          dispatch(
            updateCreateMemberInformation({
              id: props.id,
              value: e.target.value
            })
          );
        }}
      >
        {props.item.map((jtem) => (
          <MenuItem value={jtem.value}>{jtem.title}</MenuItem>
        ))}
      </Select>
    </PropertyWrapper>
  );
}

export function MemberDetailSelectPropertyDefault(
  props: MemberDetailSelectPropertyDefaultProps
) {
  return (
    <PropertyWrapper size={props.size}>
      <Title>{props.title}</Title>
      <Select
        sx={{
          borderRadius: '10px',
          border: '2px solid #ddd',
          padding: '6px',
          color: 'rgba(13, 29, 51, 0.7)',
          fontSize: '18px',
          fontWeight: 'bold'
        }}
        disabled={props.isModify === undefined ? true : !props.isModify}
        defaultValue={props.value}
        value={props.value}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          props.onChange(e);
        }}
      >
        {props.item.map((jtem, index) => (
          <MenuItem key={index} value={jtem.value}>
            {jtem.title}
          </MenuItem>
        ))}
      </Select>
    </PropertyWrapper>
  );
}
