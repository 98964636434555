import axios from 'axios';

export async function sendNotification(title, content, paramsToken, route) {
  const today = new Date();
  const formattedToday = today.toISOString().split('T')[0];

  const body = {
    title: title,
    body: content,
    token: paramsToken,
    data: {
      title,
      content,
      route,
      regdate: formattedToday,
    },
  };

  const option = {
    url: 'https://cpi.contax.ai/send_notification',
    method: 'POST',
    data: body,
    header: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };

  axios(option).then(response => {
    if (response.status === 200) {
      return true;
    }

    return response.status;
  });
}
