import { createSlice } from '@reduxjs/toolkit';

interface LayoutState {
  modal_active: boolean;
  category_list: string[];
  selected_category: string;
  selected_menu: string;
  notification_on: boolean;
}

const initialState: LayoutState = {
  modal_active: false,
  category_list: [],

  selected_category: '',
  selected_menu: '',
  notification_on: false,
};

export const layoutSlice = createSlice({
  name: 'layoutSlice',
  initialState: initialState,
  reducers: {
    updateNotificationOn(state, actions) {
      state.notification_on = actions.payload;
    },
    updateCategoryList(state, actions) {
      state.category_list = actions.payload;
    },

    updateSelectedCategory(state, actions) {
      state.selected_category = state.category_list[actions.payload];
    },

    updateSelectedMenu(state, actions) {
      state.selected_menu = actions.payload;
    },

    closeModal(state) {
      state.modal_active = false;
    },

    openModal(state) {
      state.modal_active = true;
    },
  },
});

export const {
  updateNotificationOn,
  updateSelectedMenu,
  closeModal,
  openModal,
  updateCategoryList,
  updateSelectedCategory,
} = layoutSlice.actions;

export default layoutSlice.reducer;
export type { LayoutState };
