import React from 'react';

import {
  SalesSection,
  TableColumn,
  TableColumnSection,
  TableExpertAnswerRows,
  TableExpertRows,
  TableManagerMemoRowPerformanceSection,
  TableManagerMemoRowPerformanceSectionSecond,
  TableManagerMemoRowSection,
  TableOnlyExpertAnswerRows,
  TableThirdManagerRows,
  TitleSection
} from './components';

function AdminGridTitle({ children }) {
  return (
    <TitleSection>
      <h1 style={{ marginBottom: '20px' }}>{children}</h1>
    </TitleSection>
  );
}

function AdminGridCols({ columns, detailOn }) {
  return (
    detailOn === false && (
      <TableColumnSection>
        {columns.map((item) => {
          return <TableColumn width={item.width}>{item.title}</TableColumn>;
        })}
      </TableColumnSection>
    )
  );
}

function AdminGridRows({ data, setCheckData, target, adminArea }) {
  return (
    <>
      {data.map((item) => {
        return (
          <TableManagerMemoRowSection
            item={item}
            checkData={target}
            setCheckData={setCheckData}
            adminArea={adminArea}
          />
        );
      })}
    </>
  );
}

export function AdminGridExpertRows({
  setSelectedData,
  selectedDetailData,
  setSelectedDetailData,
  selectedData,
  data,
  detailOn,
  setDetailOn,
  setCheckData,
  target,
  adminArea
}) {
  return (
    <>
      {data.map((item) => {
        if (item.writter_to_expert === false) {
          return (
            <TableExpertRows
              item={item}
              selectedDetailData={selectedDetailData}
              setSelectedDetailData={setSelectedDetailData}
              selectedData={selectedData}
              checkData={target}
              setCheckData={setCheckData}
              detailOn={detailOn}
              setDetailOn={setDetailOn}
              adminArea={adminArea}
              setSelectedData={setSelectedData}
            />
          );
        }
      })}
    </>
  );
}

export function AdminGridThirdManagerRows({
  setSelectedData,
  selectedData,
  data,
  detailOn,
  setDetailOn,
  setCheckData,
  target,
  adminArea
}) {
  return (
    <>
      {data.map((item) => {
        return (
          <TableThirdManagerRows
            selectedData={selectedData}
            item={item}
            checkData={target}
            setCheckData={setCheckData}
            detailOn={detailOn}
            setDetailOn={setDetailOn}
            adminArea={adminArea}
            setSelectedData={setSelectedData}
          />
        );
      })}
    </>
  );
}

export function AdminGridExpertAnswerRows({
  setSelectedData,
  onClick,
  data,
  detailOn,
  setDetailOn,
  setCheckData,
  target,
  adminArea
}) {
  return (
    <>
      {data.map((item) => {
        if (
          item.writter_to_expert === true &&
          (item.answer_status === 'pending' ||
            item.answer_status === 'complete')
        ) {
          return (
            <TableExpertAnswerRows
              onClick={onClick}
              item={item}
              checkData={target}
              setCheckData={setCheckData}
              detailOn={detailOn}
              setDetailOn={setDetailOn}
              adminArea={adminArea}
              setSelectedData={setSelectedData}
            />
          );
        }
      })}
    </>
  );
}

export function AdminGridOnlyExpertAnswerRows({
  setTitle,
  setContent,
  setSelectedData,
  onClick,
  selectedData,
  data,
  detailOn,
  setDetailOn,
  setCheckData,
  target,
  adminArea
}) {
  return (
    <>
      {data.length === 0 && (
        <div style={{ width: '100%', textAlign: 'center', padding: '100px 0' }}>
          데이터가 존재하지 않습니다
        </div>
      )}

      {data.map((item) => {
        if (
          item.writter_to_expert === true &&
          (item.answer_status === 'pending' ||
            item.answer_status === 'complete' ||
            item.answer_status === null)
        ) {
          return (
            <TableOnlyExpertAnswerRows
              setTitle={setTitle}
              setContent={setContent}
              onClick={onClick}
              item={item}
              checkData={target}
              setCheckData={setCheckData}
              detailOn={detailOn}
              setDetailOn={setDetailOn}
              adminArea={adminArea}
              setSelectedData={setSelectedData}
            />
          );
        }
      })}
    </>
  );
}

export function AdminGridRowsPerformanceFirst({ data, setCheckData, target }) {
  return (
    <>
      {data.map((item) => {
        if (item.division === 'sales') {
          return (
            <TableManagerMemoRowPerformanceSection
              item={item}
              checkData={target}
              setCheckData={setCheckData}
            />
          );
        }
      })}
    </>
  );
}

export function AdminGridRowsPerformanceSecond({ data, setCheckData, target }) {
  return (
    <>
      {data.map((item) => {
        if (item.division === 'sales_second') {
          return (
            <TableManagerMemoRowPerformanceSectionSecond
              item={item}
              checkData={target}
              setCheckData={setCheckData}
            />
          );
        }
      })}
    </>
  );
}

function AdminGrid({ children }) {
  return (
    <SalesSection>
      <div
        style={{
          width: '100%',
          display: 'flex',
          alignItems: 'start',
          flexDirection: 'column'
        }}
      >
        {children}
      </div>
    </SalesSection>
  );
}

export { AdminGrid, AdminGridTitle, AdminGridCols, AdminGridRows };
