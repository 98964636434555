import TitleCard from '../../components/Cards/TitleCard';
import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import {
  ColProps,
  ManagerGrid,
  ManagerGridRow,
  ManagerGridRowSection,
} from '../../components/GridType';
import { useSelector, useDispatch } from 'react-redux';
import {
  getTutorialContacts,
  QueryParams,
} from '../../utils/tutorial_contact_API';
import {
  TutorialContactBoard,
  updateFailed,
  updateLoading,
  updateSuccess,
  updateTutorialContact,
} from '../../app/slice/tutorialContactSlice';
import { StoreState } from '../../app/store';
import BoardSearchHeader from '../../components/BoardSearchHeader';
import BoardPagenation from '../../components/BoardPagenation';
import {
  calculatePagination,
  createPaginationSize,
} from '../../utils/common_API';

const Grid_COL: ColProps[] = [
  {
    title: '이름',
    id: 'name',
    width: 20,
    textAlign: 'left',
    sort: true,
    search: true,
  },
  {
    title: '회사',
    id: 'company',
    width: 20,
    textAlign: 'left',
    sort: true,
    search: true,
  },
  {
    title: '지역',
    id: 'area',
    width: 20,
    textAlign: 'left',
    sort: true,
    search: true,
  },
  {
    title: '전화번호',
    id: 'phone_number',
    width: 25,
    textAlign: 'left',
    sort: true,
    search: true,
  },
  {
    title: '날짜',
    id: 'date',
    width: 15,
    textAlign: 'left',
    sort: true,
    search: false,
  },
];

interface SortType {
  option: string;
  sort: 'DESC' | 'ASC';
}

function TutorialContact() {
  const dispatch = useDispatch();
  const tutorialContact = useSelector(
    (state: StoreState) => state.tutorialContact,
  );
  const list: TutorialContactBoard[] = tutorialContact.list;
  const total_count: number = tutorialContact.total_count;
  const [offset, setOffset] = useState(0);
  const [sort, setSort] = useState<SortType>({
    option: 'date',
    sort: 'DESC',
  });
  const [limit, setLimit] = useState(10);
  const [category, setCategory] = useState('name');
  const [search_query, setSearchQuery] = useState('');
  const [pageNumbers, setPageNumbers] = useState([1, 2, 3, 4, 5]);
  const [pageLength, setPageLength] = useState(1);

  useEffect(() => {
    loadTutorialContact();
  }, [search_query, sort, category, offset]);

  async function loadTutorialContact() {
    const params: QueryParams = {
      database: 'shared_marketing_user_information',
      categories: [],
      search_queries: [],
      offset: offset * limit,
      limit: limit,
      sort_option: sort.option,
      sort: sort.sort,
    };

    if (search_query !== '') {
      params.categories.push(category);
      params.search_queries.push(search_query);
    }

    dispatch(updateLoading);
    const result = await getTutorialContacts(params);

    if (result!.status === 'success') {
      dispatch(updateSuccess);
      dispatch(updateTutorialContact(result!.data));

      const length = calculatePagination(result!.data.total_count, limit);
      let array = [];
      setPageLength(length);

      array = createPaginationSize(offset, length);

      setPageNumbers(array);
    }

    if (result!.status === 'failed') {
      dispatch(updateFailed);
    }
  }

  function clickSort(id: string) {
    if (id !== sort.option) {
      setSort({ option: id, sort: 'DESC' });
    }

    if (id === sort.option && sort.sort === 'DESC') {
      setSort({ option: id, sort: 'ASC' });
    }

    if (id === sort.option && sort.sort === 'ASC') {
      setSort({ option: id, sort: 'DESC' });
    }
  }

  return (
    <>
      <Grid container spacing={2}>
        <TitleCard title={'튜토리얼 문의'}>
          <BoardSearchHeader
            limit={limit}
            setLimit={setLimit}
            query={search_query}
            onSelect={e => {
              setCategory(e.target.value);
            }}
            onInput={e => {
              setSearchQuery(e.target.value);
            }}
            col={Grid_COL}
            defaultColumn="name"
            title="문의"
            total_count={total_count}
            limitOption={[10, 50, 100]}
          />
          <ManagerGrid size="large" col={Grid_COL} sortFunction={clickSort}>
            {list.map((item, index) => {
              return (
                <ManagerGridRowSection>
                  <ManagerGridRow size="large" width={20}>
                    {item.name}
                  </ManagerGridRow>
                  <ManagerGridRow size="large" width={20}>
                    {item.company}
                  </ManagerGridRow>
                  <ManagerGridRow size="large" width={20}>
                    {item.area}
                  </ManagerGridRow>
                  <ManagerGridRow size="large" width={25}>
                    {item.phone_number}
                  </ManagerGridRow>
                  <ManagerGridRow size="large" width={15}>
                    {item.date}
                  </ManagerGridRow>
                </ManagerGridRowSection>
              );
            })}

            <BoardPagenation
              offset={offset}
              pageNumbers={pageNumbers}
              pageLength={pageLength}
              setOffset={setOffset}
            />
          </ManagerGrid>
        </TitleCard>
      </Grid>
    </>
  );
}

export default TutorialContact;
