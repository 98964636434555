import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setPageTitle } from '../../app/slice/headerSlice';
import TitleCard from '../../components/Cards/TitleCard';
import FunnelIcon from '@heroicons/react/24/outline/FunnelIcon';
import XMarkIcon from '@heroicons/react/24/outline/XMarkIcon';
import SearchBar from '../../components/Input/SearchBar';
import axios from 'axios';
import {
  Button,
  Grid,
  TextField,
  ToggleButtonGroup,
  ToggleButton
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

function Prompt() {
  const [conbiPrompt, setConbiPrompt] = useState([
    {
      id: 1,
      content: ''
    }
  ]);

  const [managePrompt, setManagePrompt] = useState([
    {
      id: 1,
      content: ''
    }
  ]);

  const [reportPrompt, setReportPrompt] = useState([
    {
      id: 1,
      content: ''
    }
  ]);

  const [gridWidth, setGridWidth] = useState(window.innerWidth);

  const [id, setId] = useState(0);

  const [columns] = useState([
    {
      field: 'id',
      headerName: '번호',
      width: gridWidth * 0.08,
      align: 'center',
      headerAlign: 'center'
    },
    {
      field: 'content',
      headerName: '프롬프트 내용',
      width: gridWidth * 0.4
    }
  ]);

  const [content, setContent] = useState('');
  const [toggle, setToggle] = useState('conbi');

  const dispatch = useDispatch();
  useEffect(() => {
    getPromptData();
    dispatch(setPageTitle({ title: '프롬프트 관리' }));

    const handleResize = () => {
      setGridWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  async function getPromptData() {
    const conbiOption = {
      url: 'https://cpi.contax.ai/conbichat-prompts/',
      method: 'GET',
      header: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    };

    await axios(conbiOption).then((response) => {
      if (response.status === 200) {
        setConbiPrompt(response.data);
      }
    });

    const manageOption = {
      url: 'https://cpi.contax.ai/management-prompts/',
      method: 'GET',
      header: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    };

    await axios(manageOption).then((response) => {
      if (response.status === 200) {
        setManagePrompt(response.data);
      }
    });

    const reportOption = {
      url: 'https://cpi.contax.ai/reportchat-prompts/',
      method: 'GET',
      header: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    };

    await axios(reportOption).then((response) => {
      if (response.status === 200) {
        setReportPrompt(response.data);
      }
    });
  }

  async function createConbiPromptData() {
    const option = {
      url: 'https://cpi.contax.ai/conbichat-prompts',
      method: 'POST',
      data: [{ content: content }],
      header: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    };

    axios(option).then((response) => {
      if (response.status === 200) {
        setContent('');
        alert('성공적으로 추가되었습니다.');
        getPromptData();
      }
    });
  }

  async function createManagePromptData() {
    const option = {
      url: 'https://cpi.contax.ai/management-prompts',
      method: 'POST',
      data: [{ content: content }],
      header: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    };

    axios(option).then((response) => {
      if (response.status === 200) {
        setContent('');
        alert('성공적으로 추가되었습니다.');
        getPromptData();
      }
    });
  }

  async function createReportPromptData() {
    const option = {
      url: 'https://cpi.contax.ai/reportchat-prompts',
      method: 'POST',
      data: [{ content: content }],
      header: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    };

    axios(option).then((response) => {
      if (response.status === 200) {
        setContent('');
        alert('성공적으로 추가되었습니다.');
        getPromptData();
      }
    });
  }

  async function deleteConbiPromptData() {
    if (!window.confirm('정말 삭제하시겠습니까?')) {
      return;
    } else {
      const option = {
        url: 'https://cpi.contax.ai/conbichat-prompts/' + id,
        method: 'DELETE',
        header: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      };

      axios(option).then((response) => {
        if (response.status === 200) {
          alert('성공적으로 삭제되었습니다.');
          window.location.reload();
        }
      });
    }
  }

  async function deleteManagementPromptData() {
    if (!window.confirm('정말 삭제하시겠습니까?')) {
      return;
    } else {
      const option = {
        url: 'https://cpi.contax.ai/management-prompts/' + id,
        method: 'DELETE',
        header: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      };

      axios(option).then((response) => {
        if (response.status === 200) {
          alert('성공적으로 삭제되었습니다.');
          window.location.reload();
        }
      });
    }
  }

  async function deleteReportPromptData() {
    if (!window.confirm('정말 삭제하시겠습니까?')) {
      return;
    } else {
      const option = {
        url: 'https://cpi.contax.ai/reportchat-prompts/' + id,
        method: 'DELETE',
        header: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      };

      axios(option).then((response) => {
        if (response.status === 200) {
          alert('성공적으로 삭제되었습니다.');
          window.location.reload();
        }
      });
    }
  }

  async function updateConbiPromptData() {
    const option = {
      url:
        'https://cpi.contax.ai/conbichat-prompts?id=' +
        id +
        '&content=' +
        content,
      method: 'PUT',
      header: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    };

    axios(option).then((response) => {
      if (response.status === 200) {
        setContent('');
        getPromptData();
        alert('성공적으로 수정되었습니다.');
      }
    });
  }

  async function updateManagementPromptData() {
    const option = {
      url:
        'https://cpi.contax.ai/management-prompts?id=' +
        id +
        '&content=' +
        content,
      method: 'PUT',
      header: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    };

    axios(option).then((response) => {
      if (response.status === 200) {
        alert('성공적으로 수정되었습니다.');
        setContent('');
        getPromptData();
      }
    });
  }

  async function updateReportPromptData() {
    const option = {
      url:
        'https://cpi.contax.ai/reportchat-prompts?id=' +
        id +
        '&content=' +
        content,
      method: 'PUT',
      header: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    };

    axios(option).then((response) => {
      if (response.status === 200) {
        alert('성공적으로 수정되었습니다.');
        setContent('');
        getPromptData();
      }
    });
  }

  const conbiHandleSelection = (newSelection) => {
    // newSelection은 선택된 행의 id 배열입니다.
    const selectedData = conbiPrompt.filter((row) =>
      newSelection.includes(row.id)
    );
    setId(selectedData[0].id);
    setContent(selectedData[0].content);
    // 이제 selectedData 변수는 선택된 행의 데이터를 포함합니다.
  };

  const manageHandleSelection = (newSelection) => {
    // newSelection은 선택된 행의 id 배열입니다.
    const selectedData = managePrompt.filter((row) =>
      newSelection.includes(row.id)
    );
    setId(selectedData[0].id);
    setContent(selectedData[0].content);
    // 이제 selectedData 변수는 선택된 행의 데이터를 포함합니다.
  };

  const reportHandleSelection = (newSelection) => {
    // newSelection은 선택된 행의 id 배열입니다.
    const selectedData = reportPrompt.filter((row) =>
      newSelection.includes(row.id)
    );
    setId(selectedData[0].id);
    setContent(selectedData[0].content);
    // 이제 selectedData 변수는 선택된 행의 데이터를 포함합니다.
  };

  const handleToggle = (event, newAlignment) => {
    setToggle(newAlignment);
    setContent('');
    setId(0);
  };

  return (
    <Grid container direction="column" justifyContent="center">
      <ToggleButtonGroup
        color="primary"
        value={toggle}
        exclusive
        onChange={handleToggle}
        aria-label="Platform"
      >
        <ToggleButton sx={{ backgroundColor: '#fff' }} value="conbi">
          콘비챗
        </ToggleButton>
        <ToggleButton sx={{ backgroundColor: '#fff' }} value="manage">
          경영
        </ToggleButton>
        <ToggleButton sx={{ backgroundColor: '#fff' }} value="report">
          보고서 채팅
        </ToggleButton>
      </ToggleButtonGroup>
      <Grid item>
        {toggle === 'conbi' && (
          <TitleCard title="콘비챗 프롬프트">
            {/* Team Member list in table format loaded constant */}
            <DataGrid
              sx={{ width: '100%' }}
              rows={conbiPrompt}
              columns={columns}
              getRowId={(row) => row.id}
              onRowSelectionModelChange={(newSelection) => {
                conbiHandleSelection(newSelection);
              }}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 5 }
                }
              }}
              pageSizeOptions={[5, 10]}
            />
          </TitleCard>
        )}

        {toggle === 'report' && (
          <TitleCard title="보고서 채팅 프롬프트">
            {/* Team Member list in table format loaded constant */}
            <DataGrid
              sx={{ width: '100%' }}
              rows={reportPrompt}
              columns={columns}
              getRowId={(row) => row.id}
              onRowSelectionModelChange={(newSelection) => {
                reportHandleSelection(newSelection);
              }}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 5 }
                }
              }}
              pageSizeOptions={[5, 10]}
            />
          </TitleCard>
        )}

        {toggle === 'manage' && (
          <TitleCard title="경영 프롬프트">
            {/* Team Member list in table format loaded constant */}
            <DataGrid
              sx={{ width: '100%' }}
              rows={managePrompt}
              columns={columns}
              getRowId={(row) => row.id}
              onRowSelectionModelChange={(newSelection) => {
                manageHandleSelection(newSelection);
              }}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 5 }
                }
              }}
              pageSizeOptions={[5, 10]}
            />
          </TitleCard>
        )}
      </Grid>

      <Grid item>
        {toggle === 'conbi' && (
          <TitleCard title="콘비챗 프롬프트 설정">
            {/* Team Member list in table format loaded constant */}
            <TextField
              sx={{ width: '100%' }}
              onChange={(e) => {
                setContent(e.target.value);
              }}
              value={content}
            />
          </TitleCard>
        )}

        {toggle === 'manage' && (
          <TitleCard title="경영 프롬프트 설정">
            {/* Team Member list in table format loaded constant */}
            <TextField
              sx={{ width: '100%' }}
              onChange={(e) => {
                setContent(e.target.value);
              }}
              value={content}
            />
          </TitleCard>
        )}

        {toggle === 'report' && (
          <TitleCard title="보고서 채팅 프롬프트 설정">
            {/* Team Member list in table format loaded constant */}
            <TextField
              sx={{ width: '100%' }}
              onChange={(e) => {
                setContent(e.target.value);
              }}
              value={content}
            />
          </TitleCard>
        )}

        {toggle === 'conbi' && (
          <Grid
            sx={{ marginTop: '10px' }}
            spacing={2}
            container
            justifyContent="end"
          >
            <Grid item>
              <Button variant="contained" onClick={createConbiPromptData}>
                프롬프트 작성
              </Button>
            </Grid>
            <Grid item>
              <Button
                disabled={id === 0}
                variant="contained"
                onClick={updateConbiPromptData}
              >
                프롬프트 수정
              </Button>
            </Grid>
            <Grid item>
              <Button
                disabled={id === 0}
                sx={{ backgroundColor: 'red' }}
                variant="contained"
                onClick={deleteConbiPromptData}
              >
                삭제
              </Button>
            </Grid>
          </Grid>
        )}

        {toggle === 'manage' && (
          <Grid
            sx={{ marginTop: '10px' }}
            spacing={2}
            container
            justifyContent="end"
          >
            <Grid item>
              <Button variant="contained" onClick={createManagePromptData}>
                프롬프트 작성
              </Button>
            </Grid>
            <Grid item>
              <Button
                disabled={id === 0}
                variant="contained"
                onClick={updateManagementPromptData}
              >
                프롬프트 수정
              </Button>
            </Grid>
            <Grid item>
              <Button
                disabled={id === 0}
                sx={{ backgroundColor: 'red' }}
                variant="contained"
                onClick={deleteManagementPromptData}
              >
                삭제
              </Button>
            </Grid>
          </Grid>
        )}

        {toggle === 'report' && (
          <Grid
            sx={{ marginTop: '10px' }}
            spacing={2}
            container
            justifyContent="end"
          >
            <Grid item>
              <Button variant="contained" onClick={createReportPromptData}>
                프롬프트 작성
              </Button>
            </Grid>
            <Grid item>
              <Button
                disabled={id === 0}
                variant="contained"
                onClick={updateReportPromptData}
              >
                프롬프트 수정
              </Button>
            </Grid>
            <Grid item>
              <Button
                disabled={id === 0}
                sx={{ backgroundColor: 'red' }}
                variant="contained"
                onClick={deleteReportPromptData}
              >
                삭제
              </Button>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}

export default Prompt;
