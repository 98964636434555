import TitleCard from '../../components/Cards/TitleCard';
import { Grid, MenuItem, Select, Switch } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TabList } from './components/TabList';
import { StoreState } from '../../app/store';
import BoardPagenation from '../../components/BoardPagenation';

const St_TotalCount = styled.div`
  color: rgba(26, 49, 85, 0.7);
  font-weight: bold;

  strong {
    font-weight: bold;
    color: #1a3155;
  }
`;

import {
  ManagerGrid,
  ManagerGridButton,
  ManagerGridRow,
  ManagerGridRowSection
} from '../../components/GridType';
import axios from 'axios';
import {
  updateAllCount,
  updateApproveCount,
  updateCategory,
  updateData,
  updateDate,
  updateLicensesCount,
  updateLimit,
  updateOffset,
  updatePageLength,
  updateTotalCount
} from '../../app/slice/dailyBidSlice';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { CalendarIcon } from '@mui/x-date-pickers';
import { ko } from 'date-fns/locale/ko';
import styled from 'styled-components';
import LicensesCountDisplay from './components/LicensesCountDisplay';
import { isEmptyObject } from './tools/isEmptyObject';
import ReceiverCountDisplay from './components/ReceiverCountDisplay';
import { getNotificationSetting } from './tools/getNotificationSetting';
import { updateNotificationSetting } from './tools/updateNotificationSetting';

function DailyBidPage() {
  const dispatch = useDispatch();

  const {
    page_length,
    selectedDate,
    selectedTab,
    category,
    page_numbers,
    all_count,
    approve_count,
    data,
    total_count,
    offset,
    limit,
    licenses_count,
    Grid_COL
  } = useSelector((state: StoreState) => state.dailyBid);

  async function init() {
    const response = await getNotificationSetting();

    if (response.status === 'success') {
      setMinute(response.data.time_minute);
      setHour(response.data.time_hour);
      setOn(response.data.notification_on);
    }

    const option = {
      url: 'https://cpi.contax.ai/gongo_pred_data/get_bids_by_date',
      method: 'GET',
      params: {
        license_category: category,
        preddate: selectedDate,
        offset: offset * limit,
        limit: limit
      },
      header: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data'
      }
    };

    try {
      const response = await axios(option);
      if (response.status === 200) {
        dispatch(updateLicensesCount(response.data.license_count));
        dispatch(updateAllCount(response.data.all_count));
        dispatch(updateTotalCount(response.data.total_count));
        dispatch(updateApproveCount(response.data.approve_count));
        dispatch(updatePageLength(response.data.total_count));
        dispatch(updateData(response.data.data));
      } else {
        return {
          status: 'failed',
          data: []
        };
      }
    } catch ({ message }) {
      return {
        status: 'failed',
        message: message
      };
    }
  }

  useEffect(() => {
    init();
  }, [selectedDate, category, offset]);

  const [on, setOn] = React.useState(true);
  const [hour, setHour] = React.useState('10');
  const [minute, setMinute] = React.useState('00');

  return (
    <>
      <Grid spacing={2}>
        <TitleCard title="일일 공고 현황">
          <TabList />
          {selectedTab === '일자별 공고' && (
            <>
              <span
                style={{
                  display: 'inline-block',
                  fontSize: '18px',
                  fontWeight: 'bold',
                  marginBottom: '20px'
                }}
              >
                {selectedDate === '20240810'
                  ? 'Today'
                  : selectedDate.substring(0, 4) +
                    '년 ' +
                    selectedDate.substring(4, 6) +
                    '월 ' +
                    selectedDate.substring(6) +
                    '일 '}
              </span>
              <div
                style={{
                  paddingBottom: '1rem',
                  display: 'flex'
                }}
              >
                <span
                  style={{
                    alignItems: 'center',
                    display: 'flex',
                    width: '140px',
                    fontWeight: 'bold'
                  }}
                >
                  <span
                    style={{
                      marginRight: '5px',
                      display: 'inline-block',
                      width: '5px',
                      height: '5px',
                      borderRadius: '50px',
                      backgroundColor: 'blue'
                    }}
                  />
                  전송 성공
                </span>
                {selectedDate === '20240808' ||
                selectedDate === '20240809' ||
                selectedDate === '20240810' ? (
                  <span style={{ color: 'blue' }}>YES</span>
                ) : (
                  <span style={{ color: 'red' }}>NO</span>
                )}
              </div>
              <div
                style={{
                  paddingBottom: '1rem',
                  display: 'flex'
                }}
              >
                <span
                  style={{
                    alignItems: 'center',
                    display: 'flex',
                    width: '140px',
                    fontWeight: 'bold'
                  }}
                >
                  <span
                    style={{
                      marginRight: '5px',
                      display: 'inline-block',
                      width: '5px',
                      height: '5px',
                      borderRadius: '50px',
                      backgroundColor: 'blue'
                    }}
                  />
                  전송된 알림 갯수
                </span>
                {selectedDate === '20240808' || selectedDate === '20240809'
                  ? '3'
                  : '0'}{' '}
                개
              </div>
              {(selectedDate === '20240808' || selectedDate === '20240809') && (
                <ReceiverCountDisplay />
              )}

              <div
                style={{
                  paddingBottom: '1rem',
                  display: 'flex'
                }}
              >
                <span
                  style={{
                    alignItems: 'center',
                    display: 'flex',
                    width: '140px',
                    fontWeight: 'bold'
                  }}
                >
                  <span
                    style={{
                      marginRight: '5px',
                      display: 'inline-block',
                      width: '5px',
                      height: '5px',
                      borderRadius: '50px',
                      backgroundColor: 'blue'
                    }}
                  />
                  새로 들어온 공고
                </span>
                {total_count} 개
              </div>
              <div
                style={{
                  paddingBottom: '1rem',
                  display: 'flex'
                }}
              >
                <span
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    width: '140px',
                    fontWeight: 'bold'
                  }}
                >
                  <span
                    style={{
                      marginRight: '5px',
                      display: 'inline-block',
                      width: '5px',
                      height: '5px',
                      borderRadius: '50px',
                      backgroundColor: 'blue'
                    }}
                  />
                  누적 공고 갯수
                </span>
                {all_count} 개
              </div>
              <div
                style={{
                  paddingBottom: '1rem',
                  display: 'flex'
                }}
              >
                <span
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    width: '140px',
                    fontWeight: 'bold'
                  }}
                >
                  <span
                    style={{
                      marginRight: '5px',
                      display: 'inline-block',
                      width: '5px',
                      height: '5px',
                      borderRadius: '50px',
                      backgroundColor: 'blue'
                    }}
                  />
                  유효 공고 갯수
                </span>
                {approve_count} 개
              </div>
              <div
                style={{
                  paddingBottom: '1rem',
                  display: 'flex'
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%'
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      flexDirection: 'row'
                    }}
                  >
                    <span
                      style={{
                        marginRight: '5px',
                        display: 'inline-block',
                        width: '5px',
                        height: '5px',
                        borderRadius: '50px',
                        backgroundColor: 'blue'
                      }}
                    />
                    <span style={{ width: '140px', fontWeight: 'bold' }}>
                      면허별 공고 갯수
                    </span>
                  </div>

                  {isEmptyObject(licenses_count) && (
                    <span style={{ color: '#aaa', margin: '1rem' }}>
                      데이터가 존재하지 않습니다.
                    </span>
                  )}

                  {!isEmptyObject(licenses_count) && (
                    <span
                      style={{
                        margin: '1rem',
                        fontSize: '14px'
                      }}
                    >
                      <LicensesCountDisplay data={licenses_count} />
                    </span>
                  )}
                </div>
              </div>
              <div
                style={{
                  paddingTop: '2rem',
                  display: 'flex',
                  borderTop: '1px solid #ddd',
                  marginTop: '1rem'
                }}
              >
                <span style={{ width: '140px', fontWeight: 'bold' }}>
                  날짜 선택
                </span>
                <div style={{ display: 'flex' }}>
                  <CalendarIcon fontSize="small" />
                  <DatePicker
                    locale={ko}
                    customInput={
                      <input
                        style={{
                          marginLeft: '10px',
                          border: 'none',
                          outline: 'none',
                          cursor: 'pointer'
                        }}
                      />
                    }
                    value={
                      selectedDate.substring(0, 4) +
                      '.' +
                      selectedDate.substring(4, 6) +
                      '.' +
                      selectedDate.substring(6) +
                      '.'
                    }
                    onChange={(e) => {
                      dispatch(updateOffset(0));
                      if (e === null) {
                        dispatch(updateDate('20240810'));
                        return;
                      }

                      const date: string =
                        e.getFullYear().toString() +
                        (e.getUTCMonth() + 1 < 10
                          ? '0' + (e.getUTCMonth() + 1).toString()
                          : (e.getUTCMonth() + 1).toString()) +
                        (e.getDate() < 10
                          ? '0' + e.getDate().toString()
                          : e.getDate().toString());

                      dispatch(updateDate(date));
                    }}
                    dateFormat="yyyy.MM.dd"
                    isClearable
                    placeholderText="Select a date"
                  />
                </div>
              </div>
              <div
                style={{
                  height: '100px',
                  display: 'flex',
                  justifyContent: 'space-between',
                  gap: '10px',
                  alignItems: 'center'
                }}
              >
                <St_TotalCount>
                  총 <strong>{total_count.toString()}</strong> 개의 공고
                  데이터가 있습니다.
                </St_TotalCount>
                <div style={{ display: 'flex', gap: '20px' }}>
                  <Select
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      dispatch(updateLimit(e.target.value));
                    }}
                    sx={{
                      outline: 'none',
                      width: '150px',
                      border: '1px solid #ddd',
                      borderRadius: '50px',
                      color: '#aaa',
                      fontSize: '16px'
                    }}
                    defaultValue={10}
                  >
                    {[10, 20, 50].map((item) => {
                      return (
                        <MenuItem
                          style={{ padding: '5px' }}
                          key={item}
                          value={item}
                        >
                          {item + '개씩 보기'}
                        </MenuItem>
                      );
                    })}
                  </Select>

                  <Select
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      dispatch(updateCategory(e.target.value));
                    }}
                    sx={{
                      outline: 'none',
                      width: '100px',
                      border: '1px solid #ddd',
                      borderRadius: '50px',
                      color: '#aaa',
                      fontSize: '16px'
                    }}
                    defaultValue={category}
                  >
                    {['전체', '종합', '전문'].map((item) => {
                      return (
                        <MenuItem
                          style={{ padding: '5px' }}
                          key={item}
                          value={item}
                        >
                          {item}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </div>
              </div>

              <ManagerGrid
                col={Grid_COL}
                sortFunction={() => {
                  console.log(123);
                }}
                size="large"
              >
                {data.length === 0 && (
                  <div
                    style={{
                      padding: '150px 0',
                      textAlign: 'center'
                    }}
                  >
                    데이터가 존재하지 않습니다.
                  </div>
                )}

                {data.length > 0 &&
                  data.map((item, i) => (
                    <>
                      <ManagerGridRowSection key={i}>
                        <ManagerGridRow size="large" width={20}>
                          <div
                            style={{
                              display: 'block',
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis'
                            }}
                          >
                            {item.maincnsttynm}
                          </div>
                        </ManagerGridRow>
                        <ManagerGridRow size="large" width={50}>
                          <div
                            style={{
                              display: 'block',
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis'
                            }}
                          >
                            {item.bidntcenm}
                          </div>
                        </ManagerGridRow>
                        <ManagerGridRow
                          size="large"
                          align="flex-start"
                          color={i === 1 ? '#ff0000' : '#000'}
                          width={30}
                        >
                          {item.rgsttynm}
                        </ManagerGridRow>
                      </ManagerGridRowSection>
                    </>
                  ))}
              </ManagerGrid>
              <BoardPagenation
                offset={offset}
                pageNumbers={page_numbers}
                pageLength={page_length}
                setOffset={(offset: number) => {
                  dispatch(updateOffset(offset));
                }}
              />
            </>
          )}

          {selectedTab === '자동 발송 설정' && (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginBottom: '30px'
                }}
              >
                <span style={{ fontWeight: 'bold', fontSize: '16px' }}>
                  자동 발송 활성화
                </span>
                <Switch
                  defaultChecked={on}
                  checked={on}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    e.target.value === 'false' ? setOn(true) : setOn(false);
                  }}
                />
              </div>

              <div
                style={{
                  marginBottom: '100px',
                  display: 'flex',
                  flexDirection: 'column'
                }}
              >
                <span
                  style={{
                    fontWeight: 'bold',
                    fontSize: '16px',
                    marginBottom: '10px'
                  }}
                >
                  자동 발송 시간 설정
                </span>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <span style={{ marginRight: '10px', fontSize: '18px' }}>
                    매일
                  </span>
                  <input
                    type="number"
                    style={{
                      backgroundColor: '#eee',
                      padding: '10px',
                      borderRadius: '5px',
                      marginRight: '5px'
                    }}
                    value={hour}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setHour(e.target.value);
                    }}
                  />{' '}
                  <span style={{ marginRight: '20px', fontSize: '18px' }}>
                    시
                  </span>{' '}
                  <input
                    type="number"
                    style={{
                      backgroundColor: '#eee',
                      padding: '10px',
                      borderRadius: '5px',
                      marginRight: '5px'
                    }}
                    value={minute}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setMinute(e.target.value);
                    }}
                  />{' '}
                  <span style={{ fontSize: '18px' }}>분</span>
                </div>
              </div>

              <ManagerGridButton
                onClick={() => {
                  updateNotificationSetting(on, Number(hour), Number(minute));
                  alert('설정 저장이 완료되었습니다.');
                }}
              >
                저장
              </ManagerGridButton>
            </div>
          )}
        </TitleCard>
      </Grid>
    </>
  );
}

export default DailyBidPage;
