import TitleCard from '../../components/Cards/TitleCard';
import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import {
  ColProps,
  ManagerGrid,
  ManagerGridRow,
  ManagerGridRowSection,
} from '../../components/GridType';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { getEmailLog, QueryParams } from '../../utils/email_log_API';
import {
  EmailLogBoard,
  updateFailed,
  updateLoading,
  updateSuccess,
  updateTutorialContact,
} from '../../app/slice/emailLogSlice';
import { StoreState } from '../../app/store';
import BoardSearchHeader from '../../components/BoardSearchHeader';
import BoardPagenation from '../../components/BoardPagenation';
import {
  calculatePagination,
  createPaginationSize,
} from '../../utils/common_API';

const Grid_COL: ColProps[] = [
  {
    title: '발신자',
    id: 'sender',
    width: 20,
    textAlign: 'left',
    sort: true,
    search: true,
  },
  {
    title: '수신자',
    id: 'receiver',
    width: 20,
    textAlign: 'left',
    sort: true,
    search: true,
  },
  {
    title: '제목',
    id: 'title',
    width: 30,
    textAlign: 'left',
    sort: true,
    search: true,
  },
  {
    title: '날짜',
    id: 'date',
    width: 30,
    textAlign: 'left',
    sort: true,
    search: true,
  },
];

interface SortType {
  option: string;
  sort: 'DESC' | 'ASC';
}

function EmailLogPage() {
  const dispatch = useDispatch();
  const emailLog = useSelector((state: StoreState) => state.emailLog);
  const list: EmailLogBoard[] = emailLog.list;
  const total_count: number = emailLog.total_count;
  const [offset, setOffset] = useState(0);
  const [sort, setSort] = useState<SortType>({
    option: 'date',
    sort: 'DESC',
  });
  const [limit, setLimit] = useState(10);
  const [category, setCategory] = useState('name');
  const [search_query, setSearchQuery] = useState('');
  const [pageNumbers, setPageNumbers] = useState([1, 2, 3, 4, 5]);
  const [pageLength, setPageLength] = useState(1);

  useEffect(() => {
    loadTutorialContact();
  }, [search_query, sort, category, offset, limit]);

  async function loadTutorialContact() {
    const params: QueryParams = {
      database: 'email_log',
      categories: [],
      search_queries: [],
      offset: offset * limit,
      limit: limit,
      sort_option: sort.option,
      sort: sort.sort,
    };

    if (search_query !== '') {
      params.categories.push(category);
      params.search_queries.push(search_query);
    }

    dispatch(updateLoading);
    const result = await getEmailLog(params);

    if (result!.status === 'success') {
      dispatch(updateSuccess);
      dispatch(updateTutorialContact(result!.data));

      const length = calculatePagination(result!.data.total_count, limit);
      let array = [];
      setPageLength(length);

      array = createPaginationSize(offset, length);

      setPageNumbers(array);
    }

    if (result!.status === 'failed') {
      dispatch(updateFailed);
    }
  }

  function clickSort(id: string) {
    if (id !== sort.option) {
      setSort({ option: id, sort: 'DESC' });
    }

    if (id === sort.option && sort.sort === 'DESC') {
      setSort({ option: id, sort: 'ASC' });
    }

    if (id === sort.option && sort.sort === 'ASC') {
      setSort({ option: id, sort: 'DESC' });
    }
  }

  return (
    <>
      <Grid container spacing={2}>
        <TitleCard title={'이메일 전송 내역'}>
          <BoardSearchHeader
            limit={limit}
            setLimit={setLimit}
            query={search_query}
            onSelect={e => {
              setCategory(e.target.value);
            }}
            onInput={e => {
              setSearchQuery(e.target.value);
            }}
            col={Grid_COL}
            defaultColumn="date"
            title="기록"
            total_count={total_count}
            limitOption={[10, 50, 100]}
          />
          <ManagerGrid size="large" col={Grid_COL} sortFunction={clickSort}>
            {list.map((item, index) => {
              return (
                <ManagerGridRowSection>
                  <ManagerGridRow size="large" width={20}>
                    {item.sender}
                  </ManagerGridRow>
                  <ManagerGridRow size="large" width={20}>
                    {item.receiver}
                  </ManagerGridRow>
                  <ManagerGridRow size="large" width={30}>
                    {item.title}
                  </ManagerGridRow>
                  <ManagerGridRow size="large" width={30}>
                    {item.date}
                  </ManagerGridRow>
                </ManagerGridRowSection>
              );
            })}

            <BoardPagenation
              offset={offset}
              pageNumbers={pageNumbers}
              pageLength={pageLength}
              setOffset={setOffset}
            />
          </ManagerGrid>
        </TitleCard>
      </Grid>
    </>
  );
}

export default EmailLogPage;
