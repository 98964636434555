import React, { useRef } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import EditorToolbar, { modules } from './EditorToolbar';
//EditorToolBar.tsx

function Editor(props) {
  const quillRef = useRef(null);

  const formats = [
    'font',
    'size',
    'header',
    'color',
    'background',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'image',
    'align'
  ];

  //....
  return (
    <>
      <EditorToolbar />

      <ReactQuill
        style={{ height: '800px', marginTop: '4px' }}
        theme="snow"
        ref={quillRef}
        value={props.value}
        onChange={props.setValue}
        placeholder={'내용을 입력해주세요...'}
        modules={modules}
        formats={formats}
      />
    </>
  );
}

export default Editor;
