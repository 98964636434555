import React, { useEffect, useState } from 'react';
import { CreateMemberProperty } from './CreateMemberProperty';
import { useSelector } from 'react-redux';
import { StoreState } from '../../../app/store';
import axios from 'axios';
import { DeleteButton, SuccessButton } from '../../../components/GridType';
import { CancelOutlined, Save } from '@mui/icons-material';
import styled from 'styled-components';
import { MemberDetailSelectProperty } from '../../manager_management/components/MemberDetailProperty';
import { MemberDetailProperty } from './MemberDetailProperty';

const PropertyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 25%;
`;

const Title = styled.span`
  font-weight: bold;
  color: rgba(31, 41, 55, 0.38);
`;

const Input = styled.input`
  border-radius: 10px;
  border: 2px solid #ddd;
  font-size: 18px;
  padding: 10px;
  color: rgba(13, 29, 51, 0.7);
`;

export function CreateSection(props: { setIsCreate: () => void }) {
  const members = useSelector((state: StoreState) => state.testUser);
  const [password, setPassword] = useState('');
  const create_member = members.create_member;
  const tab = members.tab;

  useEffect(() => {
    setPassword('');
  }, []);

  async function insertMember() {
    const date = new Date();

    const optionData = {
      member: {
        email: create_member.email,
        password: password,
        name: create_member.name,
        position: create_member.position,
        phone_number: create_member.phone_number,
        business_number: create_member.business_number,
        company_name: create_member.company_name,
        representative_name: 'Null',
        address: create_member.address,
        company_phone_number: create_member.company_phone_number,
        business_type: tab,
        reg_date: `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`,
        sms_kakao_agree: 0
      },

      licenses: []
    };

    const option = {
      url: 'https://cpi.contax.ai/members/add_member',
      data: optionData,
      method: 'POST',
      header: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data'
      }
    };

    try {
      const response = await axios(option);
      if (response.status === 200) {
        if (response.data.error === 'The company name already exists') {
          alert('이미 존재하는 회사명입니다.');
          return;
        }

        if (response.data.error === 'The business number already exists') {
          alert('이미 존재하는 사업자 번호입니다.');
          return;
        }

        if (response.data.error === 'The email already exists') {
          alert('이미 존재하는 이메일입니다.');
          return;
        }

        alert('회원이 추가되었습니다.');
        props.setIsCreate();
      }
    } catch (e: any) {
      if (
        e.response.data.detail[0].msg ===
        'ensure this value has at least 1 characters'
      ) {
        alert('적어도 한가지 이상의 문자를 적어주세요');
        return;
      }

      if (
        e.response.data.detail[0].msg === 'value is not a valid email address'
      ) {
        alert('이메일 형식을 맞게 작성해주세요');
        return;
      }
    }
  }

  return (
    <>
      <div style={{ display: 'flex', gap: '50px' }}>
        <CreateMemberProperty
          id={'name'}
          title="이름"
          isModify={true}
          value={create_member.name}
        />
        <CreateMemberProperty
          id={'company_name'}
          title="회사명"
          isModify={true}
          value={create_member.company_name}
        />
        <CreateMemberProperty
          id={'email'}
          title="이메일"
          isModify={true}
          value={create_member.email}
        />
        <PropertyWrapper>
          <Title>비밀번호</Title>
          <Input
            onChange={(e: any) => {
              setPassword(e.target.value);
            }}
            value={password}
          />
        </PropertyWrapper>
      </div>
      <div
        style={{
          margin: '50px 0',
          height: '2px',
          backgroundColor: '#eee'
        }}
      />

      <div style={{ display: 'flex', gap: '50px' }}>
        <CreateMemberProperty
          id={'phone_number'}
          title="전화번호"
          isModify={true}
          value={create_member.phone_number}
        />
        <CreateMemberProperty
          id={'company_phone_number'}
          title="회사 전화번호"
          isModify={true}
          value={create_member.company_phone_number}
        />
        <CreateMemberProperty
          id={'address'}
          title="주소"
          isModify={true}
          value={create_member.address}
        />
        <CreateMemberProperty
          id={'business_type'}
          title="유저 구분"
          value={tab}
        />
      </div>

      <div
        style={{
          margin: '50px 0',
          height: '2px',
          backgroundColor: '#eee'
        }}
      />

      <div style={{ display: 'flex', gap: '20px' }}>
        <MemberDetailProperty
          size="long"
          id={'license'}
          title="면허"
          isModify={true}
          value={
            create_member.license_names === undefined
              ? ''
              : create_member.license_names.toString()
          }
        />
      </div>

      <div
        style={{
          margin: '50px 0',
          height: '2px',
          backgroundColor: '#eee'
        }}
      />

      <div style={{ display: 'flex', gap: '50px' }}>
        <CreateMemberProperty
          id="business_number"
          title="사업자번호"
          isModify={true}
          value={create_member.business_number}
        />
        <CreateMemberProperty
          id="position"
          title="직위"
          isModify={true}
          value={create_member.position}
        />

        <MemberDetailSelectProperty
          item={[
            { value: 0, title: '아니오' },
            { value: 1, title: '예' }
          ]}
          size="short"
          id={'sms_kakao_agree'}
          title="마케팅 수신 여부"
          value={create_member.sms_kakao_agree.toString()}
        />
      </div>

      <div
        style={{
          margin: '50px 0',
          height: '2px',
          backgroundColor: '#eee'
        }}
      />
      <div style={{ justifyContent: 'end', display: 'flex', gap: '20px' }}>
        <SuccessButton type="active" onClick={insertMember}>
          <Save style={{ width: '20px' }} />
          저장
        </SuccessButton>
        <DeleteButton
          type="active"
          onClick={() => {
            props.setIsCreate();
          }}
        >
          <CancelOutlined style={{ width: '20px' }} />
          취소
        </DeleteButton>
      </div>
    </>
  );
}
