import styled from 'styled-components';
import React from 'react';

const St_MemberTabButton = styled.span<TabButtonProps>`
  font-size: 20px;
  font-weight: bold;
  color: #1a3155;
  opacity: ${(props) => (props.active ? 1 : 0.5)};
  cursor: pointer;
  transition: opacity 0.3s ease;

  &:hover {
    opacity: 1;
  }
`;

interface TabButtonProps {
  active: boolean;
}

export function TabButton(props: {
  onClick: () => void;
  active: boolean;
  children: React.ReactNode;
}) {
  return (
    <St_MemberTabButton onClick={props.onClick} active={props.active}>
      {props.children}
    </St_MemberTabButton>
  );
}
