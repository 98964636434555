import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import TitleCard from '../../components/Cards/TitleCard';
import { getClickRatio } from '../../utils/contact_sales_API';
import styled from 'styled-components';

interface RatioType {
  today: number;
  all: number;
}

const St_Title = styled.h2`
  font-size: 30px;
  font-weight: bold;
  color: rgb(94, 126, 163);
`;

const St_Ratio = styled.h1`
  font-size: 40px;
  font-weight: bold;
  color: rgb(30, 61, 90);
`;

const St_RatioWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const St_ContentWrapper = styled.div`
  margin-top: 40px;
  display: flex;
  justify-content: start;
  gap: 40px;
`;

const St_Header = styled.h3`
  font-size: 24px;
  color: rgb(94, 126, 163);
  font-weight: bold;
`;

export function KakaotalkShareClickRatio() {
  async function loadRatio() {
    const object = { ...data };

    const result = await getClickRatio(year, month, day);

    if (result.status === 'true') {
      object.all = result.data.all;
      object.today = result.data.today;
    } else {
      object.all = 0;
      object.today = 0;
    }

    setData(object);
    return;
  }

  const [data, setData] = useState<RatioType>({
    today: 0,
    all: 0
  });

  const today = new Date();

  const [year, setYear] = useState<string>(String(today.getFullYear()));
  const [month, setMonth] = useState<string>(
    String(today.getMonth() + 1).padStart(2, '0')
  );
  const [day, setDay] = useState<string>(
    String(today.getDate()).padStart(2, '0')
  );

  useEffect(() => {
    loadRatio();
  }, [year, month, day]);

  return (
    <Grid spacing={2} direction="column" justifyContent="center">
      <Grid item>
        <TitleCard title="카카오톡 공유 접속률">
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              padding: '200px 0',
              marginLeft: '30px'
            }}
          >
            <St_Header>
              날짜 선택 :{' '}
              <select
                value={year}
                onChange={(event) => {
                  setYear(event.target.value);
                }}
              >
                <option>2024</option>
                <option>2025</option>
                <option>2026</option>
                <option>2027</option>
              </select>{' '}
              <select
                value={month}
                onChange={(event) => {
                  setMonth(event.target.value);
                }}
              >
                <option>01</option>
                <option>02</option>
                <option>03</option>
                <option>04</option>
                <option>05</option>
                <option>06</option>
                <option>07</option>
                <option>08</option>
                <option>09</option>
                <option>10</option>
                <option>11</option>
                <option>12</option>
              </select>{' '}
              <select
                value={day}
                onChange={(event) => {
                  setDay(event.target.value);
                }}
              >
                <option>1</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
                <option>5</option>
                <option>6</option>
                <option>7</option>
                <option>8</option>
                <option>9</option>
                <option>10</option>
                <option>11</option>
                <option>12</option>
                <option>13</option>
                <option>14</option>
                <option>15</option>
                <option>16</option>
                <option>17</option>
                <option>18</option>
                <option>19</option>
                <option>20</option>
                <option>21</option>
                <option>22</option>
                <option>23</option>
                <option>24</option>
                <option>25</option>
                <option>26</option>
                <option>27</option>
                <option>28</option>
                <option>29</option>
                <option>30</option>
                <option>31</option>
              </select>
            </St_Header>
            <St_ContentWrapper>
              <St_RatioWrapper>
                <St_Title>오늘 클릭수</St_Title>
                <St_Ratio>{data.today}</St_Ratio>
              </St_RatioWrapper>

              <St_RatioWrapper>
                <St_Title>전체 클릭수</St_Title>
                <St_Ratio>{data.all}</St_Ratio>
              </St_RatioWrapper>
            </St_ContentWrapper>
          </div>
        </TitleCard>
      </Grid>
    </Grid>
  );
}
