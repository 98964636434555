import React, { useEffect, useRef, useState } from 'react';
import TitleCard from '../../components/Cards/TitleCard';
import axios from 'axios';
import {
  Box,
  Button,
  Chip,
  Grid,
  InputAdornment,
  Stack,
  TextField,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useDispatch } from 'react-redux';
import { setPageTitle } from '../../app/slice/headerSlice';
import { getAllManageNotification } from '../../utils/getAllManageNotification';
import { getToken } from '../../utils/getToken';
import { getUserId } from '../../utils/getUserId';
import { sendNotification } from '../../utils/sendNotification';
import { insertNotification } from '../../utils/insertNotification';
import Editor from '../../components/Editor';
import stripHtmlAndLimitLength from '../../utils/stripHtmlAndLimitLength';

function Tax() {
  const dispatch = useDispatch();
  const [data, setData] = useState([
    {
      id: 1,
      category: '',
      title: '',
      regdate: '',
    },
  ]);

  const [selectedCategory, setSelectedCategory] = useState('');

  const editorRef = useRef();

  const [id, setId] = useState(0);

  const [columns, setColumns] = useState([
    {
      field: 'id',
      headerName: '글 번호',
      width: 150,
      align: 'center',
      headerAlign: 'center',
    },
    { field: 'category', headerName: '카테고리', width: 500 },
    { field: 'title', headerName: '제목', width: 500 },
    { field: 'regdate', headerName: '작성일자', width: 200 },
  ]);
  const [, setToken] = useState([]);
  const [, setUserId] = useState([]);
  const [, setEditorKey] = useState(0);
  const [title, setTitle] = useState('');
  const [category, setCategory] = useState('');
  const [content, setContent] = useState('');
  const [status, setStatus] = useState('');

  useEffect(() => {
    getAllManageNotification('tax').then(array => {
      getToken(array)
        .then(token => setToken(token))
        .catch(error => console.log(error));
      getUserId(array)
        .then(idList => setUserId(idList))
        .catch(error => console.log(error));
    });

    getMagazineData();
    dispatch(setPageTitle({ title: '세무서비스' }));
    loadCategory();
  }, []);

  async function loadCategory() {
    const option = {
      url: 'https://cpi.contax.ai/categories/tax',
      method: 'GET',
      header: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    };

    axios(option).then(response => {
      if (response.status === 200) {
        setCategory(response.data[0][0]);
      }
    });
  }

  async function getMagazineData() {
    const option = {
      url: 'https://cpi.contax.ai/mng_tax',
      method: 'GET',
      header: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    };

    await axios(option).then(response => {
      if (response.status === 200) {
        setData(response.data);
      }
    });
  }

  async function createMagazineData() {
    const body = {
      title: title,
      content: content,
      category: selectedCategory,
    };

    if (status !== 'create') {
      setStatus('create');
    }

    if (status === 'create') {
      const option = {
        url: 'https://cpi.contax.ai/mng_tax',
        method: 'POST',
        data: body,
        header: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      };

      axios(option).then(response => {
        const inputContent = stripHtmlAndLimitLength(content, 28);
        if (response.status === 200) {
          getMagazineData();

          getAllManageNotification('tax').then(array => {
            getToken(array)
              .then(token =>
                token.forEach(item => {
                  sendNotification(
                    '[세무서비스] ' + title,
                    inputContent,
                    item,
                    'tax',
                  );
                }),
              )
              .catch(error => console.log(error));

            getUserId(array)
              .then(idList =>
                insertNotification(
                  'tax',
                  title,
                  inputContent,
                  idList,
                  response.data.id,
                ),
              )
              .catch(error => console.log(error));
          });

          setTitle('');
          setContent('');
          setStatus('');
          setSelectedCategory('');

          alert('성공적으로 글이 생성되었습니다.');
        }
      });
    }
  }

  async function deleteMagazineData() {
    if (!window.confirm('정말 삭제하시겠습니까?')) {
      return;
    } else {
      const option = {
        url: 'https://cpi.contax.ai/mng_tax',
        params: {
          id: id,
        },
        method: 'DELETE',
        header: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      };

      axios(option).then(response => {
        if (response.status === 200) {
          const array = [...data];

          array.forEach((item, index) => {
            if (item.id === id) {
              array.splice(index, 1);
            }
          });

          setData(array);
          alert('성공적으로 글이 삭제되었습니다.');
        }
      });
    }
  }

  async function updateMagazineData() {
    if (status !== 'change') {
      setStatus('change');
    }

    if (status === 'change') {
      const body = {
        id: id,
        title: title,
        content: content,
        category: selectedCategory,
      };

      const option = {
        url: 'https://cpi.contax.ai/mng_tax',
        data: body,
        method: 'PUT',
        header: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      };

      axios(option).then(response => {
        if (response.status === 200) {
          setTitle('');
          setContent('');
          setStatus('');
          getMagazineData();
          setSelectedCategory('');

          alert('성공적으로 글이 수정되었습니다.');
        }
      });
    }
  }

  function resetEditorKey() {
    setEditorKey(prevKey => prevKey + 1);
  }

  async function changeCategory(newCategory) {
    const option = {
      url: 'https://cpi.contax.ai/categories/tax',
      data: {
        text: newCategory,
      },
      method: 'PUT',
      header: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    };

    axios(option).then(response => {
      if (response.status === 200) {
        setCategory(newCategory);
      }
    });
  }

  const addCategory = () => {
    const newCategory = [...category];
    newCategory.push(selectedCategory);
    changeCategory(newCategory);
  };

  const handleCategory = e => {
    setSelectedCategory(e.target.innerText);
  };

  const handleDelete = index => {
    const newSelectedCategories = [...category];
    newSelectedCategories.splice(index, 1); // 인덱스에 해당하는 카테고리 삭제
    changeCategory(newSelectedCategories);
  };

  const handleChange = data => {
    setContent(data);
  };

  const handleSelection = newSelection => {
    // newSelection은 선택된 행의 id 배열입니다.
    const selectedData = data.filter(row => newSelection.includes(row.id));

    if (selectedData.length > 0) {
      setId(selectedData[0].id);
      setTitle(selectedData[0].title);
      setContent(selectedData[0].content);
      setSelectedCategory(selectedData[0].category);
      resetEditorKey();
    }

    // 이제 selectedData 변수는 선택된 행의 데이터를 포함합니다.
  };

  return (
    <Grid container spacing={2} direction="column" justifyContent="center">
      <Grid item>
        <TitleCard title="세무서비스">
          {/* Team Member list in table format loaded constant */}
          <DataGrid
            rows={data}
            columns={columns}
            getRowId={row => row.id}
            onRowSelectionModelChange={newSelection => {
              handleSelection(newSelection);
            }}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 5 },
              },
            }}
            pageSizeOptions={[5, 10]}
          />
        </TitleCard>
      </Grid>
      {(status === 'create' || status === 'change') && (
        <Grid item>
          <TitleCard title="세무서비스 내용">
            <Stack>
              <TextField
                id="standard-basic"
                label="세무서비스 제목"
                variant="standard"
                value={title}
                onChange={e => {
                  setTitle(e.target.value);
                }}
              />
              <Box mt={3} mb={2}>
                <Box display="flex" flexWrap="wrap" gap={1}>
                  {category.map((item, index) => (
                    <Chip
                      key={index}
                      label={item}
                      onClick={handleCategory}
                      onDelete={() => {
                        handleDelete(index);
                      }}
                    />
                  ))}
                </Box>

                <Box mt={2} display="flex">
                  <TextField
                    variant="outlined"
                    fullWidth
                    label="카테고리"
                    value={selectedCategory}
                    onChange={e => {
                      setSelectedCategory(e.target.value);
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Button color="primary" onClick={addCategory}>
                            저장
                          </Button>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
              </Box>
              <Editor value={content} setValue={handleChange} />
            </Stack>
          </TitleCard>
        </Grid>
      )}

      <Grid item>
        <Grid container justifyContent="flex-end" direction="row" spacing={2}>
          {status !== 'change' && (
            <Grid item>
              <Button
                disabled={status === 'change'}
                variant="contained"
                onClick={createMagazineData}
              >
                글 작성
              </Button>
            </Grid>
          )}
          {status !== 'create' && (
            <Grid item>
              <Button
                disabled={id === 0}
                variant="contained"
                onClick={updateMagazineData}
              >
                글 수정
              </Button>
            </Grid>
          )}
          {(status === 'change' || status === 'create') && (
            <Grid item>
              <Button
                variant="outlined"
                onClick={() => {
                  setStatus('');
                  setTitle('');
                  setContent('');
                }}
              >
                취소
              </Button>
            </Grid>
          )}
          {status !== 'create' && (
            <Grid item>
              <Button
                disabled={id === 0}
                sx={{ backgroundColor: 'red' }}
                variant="contained"
                onClick={deleteMagazineData}
              >
                글 삭제
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Tax;
