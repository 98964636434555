import React, { useEffect, useState } from 'react';
import BoardSearchHeader from '../../../components/BoardSearchHeader';
import { StoreState } from '../../../app/store';
import { useDispatch, useSelector } from 'react-redux';
import {
  LicenseData,
  deleteLicenseTarget,
  resetLicenseTarget,
  saveLicenseTarget,
  updateLicenseLimit,
  updateLicenseMode,
  updateLicenseTarget,
  updateLicenseTargetData,
  updateOffset,
  updateOriginalTargetLicense
} from '../../../app/slice/licensePageSlice';
import {
  DeleteButton,
  ManagerGrid,
  ManagerGridButton,
  ManagerGridDetailSection,
  ManagerGridRow,
  ManagerGridRowSection,
  SuccessButton
} from '../../../components/GridType';
import { PencilIcon } from '@heroicons/react/16/solid';
import BoardPagenation from '../../../components/BoardPagenation';
import { CreateProperty } from '../../../components/Layout/CreateProperty';
import {
  CancelOutlined,
  DeleteOutline,
  Save,
  Settings
} from '@mui/icons-material';
import styled from 'styled-components';
import {
  changeLicense,
  createLicense,
  removeLicense
} from '../../../utils/license_API';
import { ModifyButton } from '../../../components/Layout/ModifyButton';
import { SaveButton } from '../../../components/Layout/SaveButton';

const St_MemberWrapper = styled.div`
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  gap: 40px;
`;

const St_MemberTabButton = styled.span`
  font-size: 24px;
  font-weight: bold;
  color: #1a3155;
  cursor: pointer;
  transition: opacity 0.3s ease;

  &:hover {
    opacity: 1;
  }
`;

function LicenseJonghapSection() {
  const [selectedCategory, setSelectedCategory] = useState('종합 면허');
  const [open, setOpen] = useState({ index: 0, open: false });
  const [isModify, setIsModify] = React.useState(false);
  const {
    category_list,
    limit,
    query,
    jonghap_column,
    total_count,
    data,
    offset,
    pageLength,
    pageNumbers,
    mode,
    target,
    original_target
  } = useSelector((state: StoreState) => state.licensePage);

  const dispatch = useDispatch();

  useEffect(() => {
    category_list.forEach((category) => {
      if (category.active === true) {
        setSelectedCategory(category.title);
      }
    });
  }, [category_list]);

  async function openDetailSection(index: number, item: LicenseData) {
    dispatch(updateOriginalTargetLicense(item));
    dispatch(updateLicenseTarget(item));

    if (index === open.index) {
      if (open.open) {
        setOpen({ index: index, open: false });
      }

      if (!open.open) {
        setOpen({ index: index, open: true });
      }
    }

    if (index !== open.index) {
      setOpen({ index: index, open: true });
    }
  }

  async function updateLicenseData(target: LicenseData) {
    const result = await changeLicense(target);

    if (result === 'success') {
      alert('성공적으로 수정되었습니다');
      setOpen({ index: 0, open: false });
      setIsModify(false);
      dispatch(saveLicenseTarget());

      return;
    } else {
      alert('서버 오류가 발생하였습니다.');
    }
  }

  async function addLicenseData(target: LicenseData) {
    const result = await createLicense(target);

    if (result === 'success') {
      alert('성공적으로 저장되었습니다.');
      setOpen({ index: 0, open: false });
      setIsModify(false);
      dispatch(updateLicenseMode('default'));

      return;
    } else {
      alert('서버 오류가 발생하였습니다.');
    }
  }

  async function deleteLicenseData(id: number) {
    const result = await removeLicense(id);

    if (result === 'success') {
      alert('성공적으로 삭제되었습니다.');
      setOpen({ index: 0, open: false });
      setIsModify(false);
      dispatch(deleteLicenseTarget());

      return;
    } else {
      alert('서버 오류가 발생하였습니다.');
    }
  }

  return (
    <>
      {mode === 'default' && (
        <>
          <BoardSearchHeader
            limit={limit}
            setLimit={() => {
              dispatch(updateLicenseLimit);
            }}
            query={query}
            onInput={(e) => {
              return;
            }}
            onSelect={(e) => {
              return;
            }}
            col={jonghap_column}
            defaultColumn="id"
            title={selectedCategory}
            total_count={total_count}
            limitOption={[10, 50, 100]}
          />

          <ManagerGrid
            col={jonghap_column}
            sortFunction={() => {
              return;
            }}
            size="large"
          >
            {data.length === 0 && (
              <div
                style={{
                  padding: '150px 0',
                  textAlign: 'center'
                }}
              >
                데이터가 존재하지 않습니다.
              </div>
            )}

            {data.length > 0 &&
              data.map((item, index) => (
                <>
                  <ManagerGridRowSection>
                    <ManagerGridRow
                      size="large"
                      width={15}
                      onClick={() => {
                        openDetailSection(index, item);
                      }}
                    >
                      {item.id}
                    </ManagerGridRow>

                    <ManagerGridRow
                      size="large"
                      width={15}
                      onClick={() => {
                        openDetailSection(index, item);
                      }}
                    >
                      {item.license_category1}
                    </ManagerGridRow>

                    <ManagerGridRow
                      size="large"
                      width={30}
                      onClick={() => {
                        openDetailSection(index, item);
                      }}
                    >
                      {item.license_name}
                    </ManagerGridRow>

                    <ManagerGridRow
                      size="large"
                      width={20}
                      onClick={() => {
                        openDetailSection(index, item);
                      }}
                    >
                      {item.capital_standard}
                    </ManagerGridRow>

                    <ManagerGridRow
                      size="large"
                      width={20}
                      onClick={() => {
                        openDetailSection(index, item);
                      }}
                    >
                      <ManagerGridButton type="active">
                        <Settings /> 설정
                      </ManagerGridButton>
                    </ManagerGridRow>
                  </ManagerGridRowSection>
                  <ManagerGridDetailSection
                    open={open.index === index && open.open === true}
                  >
                    <St_MemberWrapper>
                      <St_MemberTabButton>상세정보</St_MemberTabButton>
                    </St_MemberWrapper>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '50px'
                      }}
                    >
                      <CreateProperty
                        title="면허명"
                        value={target.license_name}
                        active={isModify}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          dispatch(
                            updateLicenseTargetData({
                              id: 'license_name',
                              value: e.target.value
                            })
                          );
                        }}
                      />

                      <CreateProperty
                        title="자본금"
                        value={target.capital_standard.toString()}
                        active={isModify}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          dispatch(
                            updateLicenseTargetData({
                              id: 'capital_standard',
                              value: e.target.value
                            })
                          );
                        }}
                      />
                    </div>

                    <div
                      style={{
                        justifyContent: 'end',
                        display: 'flex',
                        gap: '20px'
                      }}
                    >
                      {!isModify && (
                        <ModifyButton
                          onClick={() => {
                            setIsModify(true);
                          }}
                        />
                      )}

                      {isModify && (
                        <SaveButton
                          onClick={() => {
                            updateLicenseData(target);
                          }}
                        />
                      )}

                      {!isModify && (
                        <DeleteButton
                          onClick={() => {
                            deleteLicenseData(target.id);
                          }}
                        >
                          <DeleteOutline
                            style={{
                              width: '20px'
                            }}
                          />
                          삭제
                        </DeleteButton>
                      )}

                      {isModify && (
                        <DeleteButton
                          onClick={() => {
                            setIsModify(false);
                            dispatch(updateLicenseTarget(original_target));
                          }}
                        >
                          <CancelOutlined
                            style={{
                              width: '20px'
                            }}
                          />
                          취소
                        </DeleteButton>
                      )}
                    </div>
                  </ManagerGridDetailSection>
                </>
              ))}

            <div
              style={{
                display: 'flex',
                marginTop: '30px',
                justifyContent: 'end'
              }}
            >
              <ManagerGridButton
                type="active"
                onClick={() => {
                  dispatch(updateLicenseMode('create'));
                  dispatch(resetLicenseTarget());
                  dispatch(
                    updateLicenseTargetData({
                      id: 'license_category1',
                      value: '종합'
                    })
                  );
                }}
              >
                <PencilIcon style={{ width: '20px' }} />
                작성
              </ManagerGridButton>
            </div>

            <BoardPagenation
              offset={offset}
              pageNumbers={pageNumbers}
              pageLength={pageLength}
              setOffset={(index: number) => {
                dispatch(updateOffset(index));
              }}
            />
          </ManagerGrid>
        </>
      )}

      {mode === 'create' && (
        <>
          <div style={{ display: 'flex', gap: '50px' }}>
            <CreateProperty title="구분" value="종합" active={false} />
            <CreateProperty
              title="면허명"
              value={target.license_name}
              active={true}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                dispatch(
                  updateLicenseTargetData({
                    id: 'license_name',
                    value: e.target.value
                  })
                );
              }}
            />
            <CreateProperty
              title="자본금"
              active={true}
              value={target.capital_standard.toString()}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                dispatch(
                  updateLicenseTargetData({
                    id: 'capital_standard',
                    value: e.target.value
                  })
                );
              }}
            />
          </div>

          <div
            style={{
              marginTop: '30px',
              justifyContent: 'end',
              display: 'flex',
              gap: '20px'
            }}
          >
            <SuccessButton
              type="active"
              onClick={() => {
                addLicenseData(target);
              }}
            >
              <Save style={{ width: '20px' }} />
              저장
            </SuccessButton>
            <DeleteButton
              type="active"
              onClick={() => {
                dispatch(updateLicenseMode('default'));
              }}
            >
              <CancelOutlined style={{ width: '20px' }} />
              취소
            </DeleteButton>
          </div>
        </>
      )}
    </>
  );
}

export default LicenseJonghapSection;
