import { EmailForm } from '../../components/Email/EmailForm';
import React from 'react';
import { Grid } from '@mui/material';
import TitleCard from '../../components/Cards/TitleCard';
import { SelectMemberList } from './SelectMemberList';
import { EmailBoardModal } from '../../components/EmailBoardModal';
import { BlurBackground } from '../../components/ModalComponents';
import { useSelector } from 'react-redux';
import { StoreState } from '../../app/store';

export function ManagerSendEmailPage() {
  const { modal_active } = useSelector((state: StoreState) => state.layout);

  return (
    <Grid container spacing={2}>
      {modal_active && <BlurBackground />}
      {modal_active && <EmailBoardModal />}
      <TitleCard title="이메일 전송 페이지">
        <div style={{ display: 'flex', gap: '50px' }}>
          <div style={{ width: '30%' }}>
            <SelectMemberList />
          </div>

          <div
            style={{
              width: '70%',
              display: 'flex',
              flexWrap: 'wrap',
              gap: '20px',
            }}
          >
            <EmailForm isSender={true} />
          </div>
        </div>
      </TitleCard>
    </Grid>
  );
}
