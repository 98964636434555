import styled from 'styled-components';
import React, { useState } from 'react';
import { AllCheck } from './AllCheck';
import { ManagerButton } from '../../../components/Button';
import { Close } from '@mui/icons-material';
import { getEmailMember } from '../../../utils/common_API';
import {
  handleAreaSetting,
  handlePlanSetting,
} from '../../../app/slice/emailSlice';
import { useDispatch, useSelector } from 'react-redux';
import { StoreState } from '../../../app/store';

const Modal = styled.div`
  position: absolute;
  z-index: 99;
  background-color: #fff;
  border: 1px solid #ddd;
  width: 500px;
  height: 390px;
  border-radius: 5px;
  box-shadow: 0 0px 1px 0 rgba(0, 0, 0, 0.2);
  padding: 15px;
`;

const Title = styled.span`
  font-weight: bold;
  font-size: 16px;
`;

const Header = styled.div`
  border-bottom: 1px solid #aaa;
  padding-bottom: 5px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ContentSection = styled.div``;

const Bottom = styled.div`
  margin-top: 45px;
`;

const SelectSection = styled.div`
  margin-top: 20px;
`;

interface Props {
  onClose: () => void;
  loadEmailMembers: () => void;
}

const areaList = [
  '서울',
  '경기',
  '인천',
  '부산',
  '경남',
  '경북',
  '충남',
  '충북',
  '전남',
  '전북',
  '대구',
  '광주',
  '대전',
  '울산',
  '세종',
  '강원',
  '제주',
];
const planList: string[] = ['비즈니스', '퍼스트', '콘택스'];

export function SettingModal(props: Props) {
  const { settings } = useSelector((state: StoreState) => state.email);
  const dispatch = useDispatch();

  function onClickPlan(value: string) {
    dispatch(handlePlanSetting(value));
  }

  function onClickArea(value: string) {
    dispatch(handleAreaSetting(value));
  }

  return (
    <Modal>
      <Header>
        <Title>설정</Title>
        <Close style={{ cursor: 'pointer' }} onClick={props.onClose} />
      </Header>
      <ContentSection>
        <SelectSection>
          <Title>구독 설정</Title>
          <div
            style={{
              display: 'flex',
              gap: '20px',
              marginTop: '10px',
            }}
          >
            {planList.map(item => {
              return (
                <AllCheck
                  active={settings.plan.includes(item)}
                  onClick={() => {
                    onClickPlan(item);
                  }}
                  title={item}
                />
              );
            })}
          </div>
        </SelectSection>

        <SelectSection>
          <Title>지역 설정</Title>
          <div
            style={{
              display: 'flex',
              gap: '20px',
              marginTop: '10px',
              flexWrap: 'wrap',
            }}
          >
            {areaList.map((item, index) => {
              return (
                <AllCheck
                  active={settings.area.includes(item)}
                  onClick={() => {
                    onClickArea(item);
                  }}
                  title={item}
                />
              );
            })}
          </div>
        </SelectSection>
      </ContentSection>
      <Bottom>
        <ManagerButton
          width="100%"
          height="35px"
          onClick={() => {
            props.loadEmailMembers();
            props.onClose();
          }}
        >
          저장
        </ManagerButton>
      </Bottom>
    </Modal>
  );
}
