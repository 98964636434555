import React, { MouseEventHandler } from 'react';
import { ArrowDropDownIcon } from '@mui/x-date-pickers';
import styled from 'styled-components';

const TableElement = styled.div<{ size: 'small' | 'medium' | 'large' }>`
  width: 100%;
  ${(props) => props.size === 'small' && 'min-height: 380px;'};
  ${(props) => props.size === 'medium' && 'min-height: 580px;'};
  ${(props) => props.size === 'large' && 'min-height: 680px;'};
  height: 100%;
`;

const GridColumnSection = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const GridRowSection = styled.div`
  width: 100%;
  display: flex;
  cursor: pointer;
  border-bottom: 2px solid #eee;

  & > div:first-child {
    padding-left: 20px;
  }
`;

interface GridRowProps {
  children?: React.ReactNode;
  width: number;
  onClick?: () => void;
  align?:
    | 'flex-start'
    | 'flex-end'
    | 'center'
    | 'space-between'
    | 'space-around'
    | 'space-evenly';
  backgroundColor?: string;
  color?: string;
  size: 'small' | 'medium' | 'large';
  textAlign?: 'center' | 'left' | 'right';
}

interface TableColumnProps {
  children?: React.ReactNode;
  width: number;
  onClick?: MouseEventHandler;
  align?:
    | 'flex-start'
    | 'flex-end'
    | 'center'
    | 'space-between'
    | 'space-around'
    | 'space-evenly';
  backgroundColor?: string;
  color?: string;
  size: 'small' | 'medium' | 'large';
  textAlign?: 'center' | 'left' | 'right';
}

const GridRow = styled.div<GridRowProps>`
  width: ${(props) => props.width + '%'};
  padding: 10px 0;
  display: flex;

  ${(props) => props.align !== undefined && 'justify-content: ' + props.align};
  ${(props) =>
    props.backgroundColor !== undefined &&
    'background-color: ' + props.backgroundColor};
  color: rgba(26, 49, 85, 0.88);

  align-items: center;
  gap: 10px;

  ${(props) => props.size === 'small' && 'font-size: 14px;'}
  ${(props) => props.size === 'medium' && 'font-size: 14px;'}
    ${(props) => props.size === 'large' && 'font-size: 16px;'}

    &:nth-child(1) {
    ${(props) => props.size === 'small' && 'padding-left: 20px;'}
    ${(props) => props.size === 'medium' && 'padding-left: 20px;'}
        ${(props) => props.size === 'large' && 'padding-left: 50px;'}
  }

  &:last-child {
    ${(props) => props.size === 'small' && 'padding-right: 20px;'}
    ${(props) => props.size === 'medium' && 'padding-right: 20px;'}
        ${(props) => props.size === 'large' && 'padding-right: 50px;'}
  }

  word-break: break-word;
`;

const GridColumn = styled.div<TableColumnProps>`
  display: flex;
  align-items: center;
  width: ${(props) => props.width + '%'};
  font-weight: 500;
  padding: 15px 0;
  text-align: ${(props) => props.textAlign};
  justify-content: ${(props) => props.textAlign};
  background-color: #1976d2;
  color: #fff;
  cursor: pointer;

  span {
    ${(props) => props.size === 'small' && 'font-size: 14px;'}
    ${(props) => props.size === 'medium' && 'font-size: 14px;'}
        ${(props) => props.size === 'large' && 'font-size: 16px;'}
  }

  &:nth-child(1) {
    ${(props) => props.size === 'small' && 'padding-left: 20px;'};
    ${(props) => props.size === 'medium' && 'padding-left: 20px;'};
    ${(props) => props.size === 'large' && 'padding-left: 20px;'};
  }

  &:last-child {
    ${(props) => props.size === 'small' && 'padding-right: 20px;'};
    ${(props) => props.size === 'medium' && 'padding-right: 20px;'};
    ${(props) => props.size === 'large' && 'padding-right: 50px;'};
  }
`;

export interface ColumnType {
  id: string;
  title: string;
  width: number;
  textAlign?: 'center' | 'left' | 'right';
  sort: boolean;
}

export interface RowType {
  id: string;
  value: string;
  width: number;
  onClick?: MouseEventHandler;
  align?:
    | 'flex-start'
    | 'flex-end'
    | 'center'
    | 'space-between'
    | 'space-around'
    | 'space-evenly';
  backgroundColor?: string;
  color?: string;
  textAlign?: 'center' | 'left' | 'right';
}

interface propType {
  size: 'small' | 'medium' | 'large';
  col: ColumnType[];
  sortFunction: (id: string) => void;
  datas: [
    {
      contents: RowType[];
      open: boolean;
    }
  ];
  contents?: React.ReactElement;
  updateOpenStatus: (index: number) => void;
}

const TableDetailSection = styled.div<ManagerGridDetailSectionProps>`
  width: 100%;
  gap: 30px;
  height: ${(props) => (props.open === true ? 'auto' : '0')};
  background-color: #fff;

  display: flex;
  flex-direction: ${(props) =>
    (props.direction ?? 'vertical') === 'vertical' ? 'column' : 'row'};

  overflow: hidden;
  padding: ${(props) => (props.open ? '20px 30px' : '0px 30px')};
  transition: all 0.3s ease;
  border-bottom: ${(props) => (props.open ? '1px solid #ddd' : 'none')};
`;

interface ManagerGridDetailSectionProps {
  children?: React.ReactNode;
  open: boolean;
  direction?: 'horizontal' | 'vertical';
}

function DetailTable(props: propType) {
  return (
    <TableElement size={props.size}>
      <GridColumnSection>
        {props.col.map((item, index) => (
          <GridColumn
            size={props.size}
            onClick={item.sort ? () => props.sortFunction(item.id) : undefined}
            key={index}
            width={item.width}
            textAlign={item.textAlign}
          >
            <span>{item.title}</span>
            {item.sort && (
              <ArrowDropDownIcon style={{ color: '#ddd', marginLeft: '10%' }} />
            )}
          </GridColumn>
        ))}
      </GridColumnSection>

      {props.datas.length <= 0 && (
        <div style={{ textAlign: 'center', padding: '200px 0' }}>
          데이터가 존재하지 않습니다.
        </div>
      )}

      {props.datas.length > 0 &&
        props.datas.map((data, dataIndex) => (
          <React.Fragment key={dataIndex}>
            <GridRowSection>
              {props.col.map((column, colIndex) =>
                data.contents.map(
                  (item, itemIndex) =>
                    column.id === item.id && (
                      <GridRow
                        backgroundColor={item.backgroundColor}
                        onClick={() => props.updateOpenStatus(dataIndex)}
                        color={item.color}
                        textAlign={item.textAlign}
                        align={item.align}
                        size={props.size}
                        width={item.width}
                        key={itemIndex}
                      >
                        {item.value}
                      </GridRow>
                    )
                )
              )}
            </GridRowSection>
            {props.contents !== undefined && (
              <TableDetailSection open={data.open}>
                {props.contents}
              </TableDetailSection>
            )}
          </React.Fragment>
        ))}
    </TableElement>
  );
}

export default DetailTable;
