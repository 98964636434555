import { Close } from '@mui/icons-material';
import React, { ReactNode } from 'react';
import styled from 'styled-components';

const ModalFrame = styled.div`
  width: 1200px;
  height: 900px;
  padding: 20px 40px;
  background-color: #fff;

  position: fixed;
  transform: translate(-50%, -50%) scale(0.7);
  top: 50%;
  left: 60%;
  z-index: 100;
  border: 1px solid #ddd;
  border-radius: 10px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled.h1`
  font-size: 20px;
  font-weight: bold;
`;

const Wrapper = styled.div`
  cursor: pointer;
  padding: 10px;
`;

const Content = styled.div`
  margin-top: 20px;
`;

const Bottom = styled.div`
  margin-top: 20px;
`;

export function Modal(props: {
  title: string;
  onClick: () => void;
  children: ReactNode;
}) {
  const childrenArray = React.Children.toArray(props.children);
  const content = childrenArray[0];
  const bottom = childrenArray[1];

  return (
    <ModalFrame>
      <ModalHeader title={props.title} onClick={props.onClick} />
      <ModalContent>{content}</ModalContent>
      <ModalBottom>{bottom}</ModalBottom>
    </ModalFrame>
  );
}

function ModalHeader(props: { title: string; onClick: () => void }) {
  return (
    <Header>
      <Title>{props.title}</Title>
      <CloseButton onClick={props.onClick} />
    </Header>
  );
}

function CloseButton(props: { onClick: () => void }) {
  return (
    <Wrapper>
      <Close onClick={props.onClick} />
    </Wrapper>
  );
}

function ModalContent(props: { children: ReactNode }) {
  return <Content>{props.children}</Content>;
}

function ModalBottom(props: { children: ReactNode }) {
  return <Bottom>{props.children}</Bottom>;
}
