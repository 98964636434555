import React, { MouseEventHandler } from 'react';
import styled from 'styled-components';
import { CancelOutlined, PlayArrow } from '@mui/icons-material';
import { ArrowDropDownIcon } from '@mui/x-date-pickers';
import { ManagerButton } from './Button';
import TrashIcon from '@heroicons/react/24/outline/TrashIcon';
import { ArrowUpIcon } from '@heroicons/react/16/solid';

const ManagerGridElement = styled.div<{ size: 'small' | 'medium' | 'large' }>`
  width: 100%;
  ${(props) => props.size === 'small' && 'min-height: 380px;'};
  ${(props) => props.size === 'medium' && 'min-height: 580px;'};
  ${(props) => props.size === 'large' && 'min-height: 680px;'};
  height: 100%;
`;

const GridColumnSection = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const GridButton = styled.div<ButtonType>`
  ${(props) => props.size === 'small' && 'width: 120px;'};
  ${(props) => props.size === 'medium' && 'width: 200px;'};
  ${(props) => props.size === 'large' && 'width: 300px;'};
  border-radius: 5px;
  padding: 10px 0;
  display: flex;

  justify-content: center;
  background-color: ${(props) =>
    props.type === 'active' ? '#1976d2' : '#dddddd'};
  color: #fff;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  font-size: 14px;
`;

const GridDeleteButton = styled.div<ButtonType>`
  ${(props) => props.size === 'small' && 'width: 120px;'};
  ${(props) => props.size === 'medium' && 'width: 200px;'};
  ${(props) => props.size === 'large' && 'width: 300px;'};
  border-radius: 5px;
  padding: 10px 0;
  display: flex;

  justify-content: center;
  background-color: ${(props) =>
    props.type === 'active' ? '#d21944' : '#dddddd'};
  color: #fff;

  align-items: center;
  gap: 10px;
  cursor: pointer;
  font-size: 14px;
`;

const GridSuccessButton = styled.div<ButtonType>`
  ${(props) => props.size === 'small' && 'width: 120px;'};
  ${(props) => props.size === 'medium' && 'width: 200px;'};
  ${(props) => props.size === 'large' && 'width: 300px;'};
  border-radius: 5px;
  padding: 10px 0;
  display: flex;

  justify-content: center;
  background-color: ${(props) =>
    props.type === 'active' ? '#089825' : '#dddddd'};
  color: #fff;

  align-items: center;
  gap: 10px;
  cursor: pointer;
  font-size: 14px;
`;

const GridColumn = styled.div<GridRowProps>`
  display: flex;
  align-items: center;
  width: ${(props) => props.width + '%'};
  font-weight: 500;
  padding: 15px 0;
  text-align: ${(props) => props.textAlign};
  justify-content: ${(props) => props.textAlign};
  background-color: #1976d2;
  color: #fff;
  cursor: pointer;

  span {
    ${(props) => props.size === 'small' && 'font-size: 14px;'}
    ${(props) => props.size === 'medium' && 'font-size: 14px;'}
        ${(props) => props.size === 'large' && 'font-size: 16px;'}
  }

  &:nth-child(1) {
    ${(props) => props.size === 'small' && 'padding-left: 20px;'};
    ${(props) => props.size === 'medium' && 'padding-left: 20px;'};
    ${(props) => props.size === 'large' && 'padding-left: 50px;'};
  }

  &:last-child {
    ${(props) => props.size === 'small' && 'padding-right: 20px;'};
    ${(props) => props.size === 'medium' && 'padding-right: 20px;'};
    ${(props) => props.size === 'large' && 'padding-right: 50px;'};
  }
`;

interface ButtonType {
  onClick?: MouseEventHandler;
  children?: React.ReactNode;
  size: 'small' | 'medium' | 'large';
  type: 'active' | 'inactive';
}

interface ButtonProps {
  onClick?: MouseEventHandler;
  children?: React.ReactNode;
  type?: 'active' | 'inactive';
}

interface GridRowProps {
  children?: React.ReactNode;
  width: number;
  onClick?: MouseEventHandler;
  align?:
    | 'flex-start'
    | 'flex-end'
    | 'center'
    | 'space-between'
    | 'space-around'
    | 'space-evenly';
  backgroundColor?: string;
  color?: string;
  size?: 'small' | 'medium' | 'large';
  textAlign?: 'center' | 'left' | 'right';
}

export function ManagerGridRow(props: GridRowProps) {
  return (
    <GridRow
      size={props.size ?? 'large'}
      width={props.width}
      onClick={props.onClick}
      textAlign={props.textAlign}
      align={props.align}
      backgroundColor={props.backgroundColor ?? '#fff'}
      color={props.color ?? '#000'}
    >
      {props.children}
    </GridRow>
  );
}

interface ManagerGridCheckboxProps {
  checked: boolean;
}

export function ManagerGridCheckbox(props: ManagerGridCheckboxProps) {
  return <input type="checkbox" checked={props.checked} />;
}

export function ManagerGridButton(props: ButtonProps) {
  return (
    <GridButton
      onClick={props.onClick}
      type={props.type ?? 'active'}
      size="small"
    >
      {props.children}
    </GridButton>
  );
}

export function DeleteButton(props: ButtonProps) {
  return (
    <GridDeleteButton
      onClick={props.onClick}
      type={props.type ?? 'active'}
      size="small"
    >
      <TrashIcon style={{ width: '20px' }} />
      삭제
    </GridDeleteButton>
  );
}

export function CancelButton(props: ButtonProps) {
  return (
    <GridDeleteButton
      onClick={props.onClick}
      type={props.type ?? 'active'}
      size="small"
    >
      <CancelOutlined style={{ width: '20px' }} />
      취소
    </GridDeleteButton>
  );
}

export function SuccessButton(props: ButtonProps) {
  return (
    <GridSuccessButton
      onClick={props.onClick}
      type={props.type ?? 'active'}
      size="small"
    >
      {props.children}
    </GridSuccessButton>
  );
}

export interface ColProps {
  title: string;
  width: number;
  id: string;
  textAlign?: 'center' | 'left' | 'right';
  sort: boolean;
  sortOption?: 'DESC' | 'ASC';
  search: boolean;
}

interface GridProps {
  col: ColProps[];
  children: React.ReactNode;
  sortFunction: (id: string, sortOption: 'DESC' | 'ASC' | undefined) => void;
  size: 'small' | 'medium' | 'large';
}

export function ManagerGrid(props: GridProps) {
  return (
    <ManagerGridElement size={props.size}>
      <GridColumnSection>
        {props.col.map((item, index) => {
          return (
            <GridColumn
              size={props.size}
              onClick={
                item.sort
                  ? () => {
                      props.sortFunction(item.id, item.sortOption);
                    }
                  : () => {
                      return;
                    }
              }
              key={index}
              width={item.width}
              textAlign={item.textAlign}
            >
              <span>{item.title}</span>
              {item.sort && item.sortOption == 'DESC' && (
                <ArrowDropDownIcon
                  style={{ color: '#ddd', marginLeft: '10%' }}
                />
              )}

              {item.sort && item.sortOption == 'ASC' && (
                <ArrowDropDownIcon
                  style={{
                    color: '#ddd',
                    marginLeft: '10%',
                    transform: 'rotate(-180deg)'
                  }}
                />
              )}
            </GridColumn>
          );
        })}
      </GridColumnSection>

      {props.children}
    </ManagerGridElement>
  );
}

export function EmptyDataRowSection() {
  return (
    <div
      style={{
        padding: '150px 0',
        textAlign: 'center'
      }}
    >
      데이터가 존재하지 않습니다.
    </div>
  );
}

const GridRow = styled.div<GridRowProps>`
  width: ${(props) => props.width + '%'};
  padding: 10px 0;
  display: flex;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  ${(props) => props.align !== undefined && 'justify-content: ' + props.align};
  ${(props) =>
    props.backgroundColor !== undefined &&
    'background-color: ' + props.backgroundColor};
  color: rgba(26, 49, 85, 0.88);

  align-items: center;
  gap: 10px;

  ${(props) => props.size === 'small' && 'font-size: 14px;'}
  ${(props) => props.size === 'medium' && 'font-size: 14px;'}
    ${(props) => props.size === 'large' && 'font-size: 16px;'}

    &:nth-child(1) {
    ${(props) => props.size === 'small' && 'padding-left: 20px;'}
    ${(props) => props.size === 'medium' && 'padding-left: 20px;'}
        ${(props) => props.size === 'large' && 'padding-left: 50px;'}
  }

  &:last-child {
    ${(props) => props.size === 'small' && 'padding-right: 20px;'}
    ${(props) => props.size === 'medium' && 'padding-right: 20px;'}
        ${(props) => props.size === 'large' && 'padding-right: 50px;'}
  }

  word-break: break-word;
`;

const GridRowSection = styled.div`
  width: 100%;
  display: flex;
  cursor: pointer;
  border-bottom: 2px solid #eee;
`;

interface ManagerGridRowSectionProps {
  children: React.ReactNode;
}

export function ManagerGridRowSection(props: ManagerGridRowSectionProps) {
  return <GridRowSection>{props.children}</GridRowSection>;
}

const St_PyeongjeomProperty = styled.div`
  width: 194px;
  height: 205px;
  border: 1px solid #d7d7d7;
  border-radius: 5px;
  padding: 14px 20px;
  display: flex;
  flex-direction: column;
  justify-content: end;
  position: relative;
`;

const St_PyeongjeomPropertyName = styled.div`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: auto;
`;

const St_PyeongjeomRatio = styled.span<PyeongjeomRatioProps>`
  font-size: ${(props) => (props.name === '실질자본금' ? '18px' : '20px')};
  font-weight: bold;
`;

const St_PyeongjeomRatioSection = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;
const St_Diff = styled.div`
  font-weight: bold;
  color: #575757;
  font-size: 14px;
`;

const St_ButtonSection = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
`;

const St_GridDetailSection = styled.div<ManagerGridDetailSectionProps>`
  width: 100%;
  gap: 30px;
  height: ${(props) => (props.open === true ? 'auto' : '0')};
  background-color: #fff;

  display: flex;
  flex-direction: ${(props) =>
    (props.direction ?? 'vertical') === 'vertical' ? 'column' : 'row'};

  overflow: hidden;
  padding: ${(props) => (props.open ? '20px 30px' : '0px 30px')};
  transition: all 0.3s ease;
  border-bottom: ${(props) => (props.open ? '1px solid #ddd' : 'none')};
`;

interface PyeongjeomRatioProps {
  name: string;
}

interface ExpertyProperty {
  setGyejeongStatus: (name: string) => void;
  name: string;
  ratio: number | string;
  diff?: number;
  setModalOn: (name: string) => void;
  result?: string;
  getExpertComment: (name: string) => void;
}

export function ExpertProperty(props: ExpertyProperty) {
  return (
    <St_PyeongjeomProperty>
      <St_PyeongjeomPropertyName>{props.name}</St_PyeongjeomPropertyName>

      <St_PyeongjeomRatioSection>
        <St_PyeongjeomRatio name={props.name}>
          {props.name !== '실질자본금'
            ? typeof props.ratio === 'number' &&
              (isNaN(props.ratio) || props.ratio === Infinity
                ? 0
                : props.ratio.toFixed(2))
            : props.ratio}
          {props.name === '실질자본금' && ' 원'}
          {props.name === '영업이익대비 이자보상배율' && ' 배'}
          {props.name === '최근년도 자산회전율' && ' 회'}
          {props.name !== '실질자본금' &&
            props.name !== '최근년도 자산회전율' &&
            props.name !== '영업이익대비 이자보상배율' &&
            ' %'}
        </St_PyeongjeomRatio>
        {props.result === 'up' && (
          <PlayArrow sx={{ color: '#40C057', transform: 'rotate(-90deg)' }} />
        )}
        {props.result === 'down' && (
          <PlayArrow sx={{ color: '#B80000', transform: 'rotate(-270deg)' }} />
        )}
      </St_PyeongjeomRatioSection>

      <St_Diff>
        전분기 대비 : {props.diff === undefined ? 0 : props.diff.toFixed(2)} %
      </St_Diff>
      <St_ButtonSection>
        <ManagerButton
          onClick={() => {
            props.setModalOn(
              props.name === '실질자본금' ? 'siljil' : 'gyejeong'
            );
          }}
          width="70px"
          height="30px"
        >
          계정과목
        </ManagerButton>
        <ManagerButton
          onClick={() => {
            props.getExpertComment(props.name);
            props.setGyejeongStatus(props.name);
            props.setModalOn('report');
          }}
          width="70px"
          height="30px"
        >
          보고서
        </ManagerButton>
      </St_ButtonSection>
    </St_PyeongjeomProperty>
  );
}

interface ManagerGridDetailSectionProps {
  children?: React.ReactNode;
  open: boolean;
  direction?: 'horizontal' | 'vertical';
}

export function ManagerGridDetailSection(props: ManagerGridDetailSectionProps) {
  return (
    <St_GridDetailSection direction={props.direction} open={props.open}>
      {props.children}
      <div style={{ height: '40px' }}></div>
    </St_GridDetailSection>
  );
}
