import { createSlice } from '@reduxjs/toolkit';

export interface SelectedReportType {
  content: string;
  email: string;
  quarter: string;
  ratio: number;
  regdate: string;
  result: number;
  room_number: number;
  title: string;
}

export interface ReportEmailState {
  selectedReport: SelectedReportType;
  pageStatus: 'select' | 'write';
  modalOn: {
    division: string;
    on: boolean;
  };
  total_count: number;
  offset: number;
  limit: number;
  selectedMember: {
    email: string;
    name: string;
  };
  pageNumbers: number[];
  pageLength: number;
}

const initialState: ReportEmailState = {
  selectedMember: {
    email: '',
    name: '',
  },
  selectedReport: {
    content: '',
    email: '',
    quarter: '',
    ratio: 0,
    regdate: '',
    result: 0,
    room_number: 0,
    title: '',
  },

  pageStatus: 'select',
  modalOn: {
    division: '',
    on: false,
  },
  total_count: 0,
  offset: 0,
  pageLength: 0,
  limit: 10,
  pageNumbers: [1, 2, 3, 4, 5],
};

export const reportEmailSlice = createSlice({
  name: 'report_email',
  initialState: initialState,
  reducers: {
    updateSelectedMember: (state, action) => {
      state.selectedMember.email = action.payload.email;
      state.selectedMember.name = action.payload.name;
    },
    updatePageLength: (state, action) => {
      state.pageLength = action.payload;
    },

    updateSelectedReport(state, action) {
      state.selectedReport = action.payload;
    },

    updatePageStatus(state, action) {
      state.pageStatus = action.payload;
    },

    openReportEmailModal(state, action) {
      state.modalOn.division = action.payload;
      state.modalOn.on = true;
    },

    closeReportEmailModal(state, action) {
      state.modalOn.division = action.payload;
      state.modalOn.on = false;
    },

    updateReportEmailTotalCount(state, action) {
      state.total_count = action.payload;
    },

    updateOffset(state, action) {
      state.offset = action.payload;
    },

    updateLimit(state, action) {
      state.limit = action.payload;
    },
  },
});

export const {
  updateSelectedMember,
  updatePageLength,
  updateLimit,
  updateOffset,
  updateReportEmailTotalCount,
  openReportEmailModal,
  closeReportEmailModal,
  updatePageStatus,
  updateSelectedReport,
} = reportEmailSlice.actions;

export default reportEmailSlice.reducer;
