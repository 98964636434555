import axios from 'axios';
import { convertArrayToCheckList } from './common_API';

class SecondMemoDataType {
  items: string | undefined;
  comment: string | undefined;
  writter: number | undefined;
  target_master_number: string | undefined;
  call_memo: string | undefined;
  id: number | undefined;
  name: string | null | undefined;
}

async function saveSecondMemo(props: SecondMemoDataType, secondCheckList: []) {
  const data = props;
  data.items = JSON.stringify(convertArrayToCheckList(secondCheckList));

  const nameParams = localStorage.getItem('name');
  data.name = nameParams;

  const option = {
    url: 'https://cpi.contax.ai/sales/update_sales_second_memo',
    method: 'PUT',
    data: data,
    header: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };

  try {
    const response = await axios(option);
    if (response.status === 200) {
      return { status: 'true', message: 'success' };
    }
  } catch (error) {
    return { status: 'error', message: error };
  }
}

export async function getClickRatio(year: string, month: string, day: string) {
  const option = {
    url: 'https://cpi.contax.ai/click_link_ratio',
    method: 'GET',
    params: {
      year: year,
      month: month,
      day: day
    },
    header: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };

  try {
    const response = await axios(option);
    if (response.status === 200) {
      return { status: 'true', data: response.data };
    } else {
      return { status: 'error', message: '' };
    }
  } catch (error) {
    return { status: 'error', message: error };
  }
}

async function updateContactDataStatus(
  target_master_number: string,
  status: boolean
) {
  const option = {
    url: 'https://cpi.contax.ai/update_third_ok',
    method: 'PUT',
    params: {
      ncrgsseq: target_master_number,
      is_ok: status
    },
    header: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };

  try {
    const response = await axios(option);
    if (response.status === 200) {
      return { status: 'true', message: 'success' };
    }
  } catch (error) {
    return { status: 'error', message: error };
  }
}

export { saveSecondMemo, updateContactDataStatus };
