import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { StoreState } from '../../../app/store';
import {
  resetFilterAreaname,
  resetFilters,
  updateMode,
  updateSort,
} from '../../../app/slice/salesSlice';

const ChangeModeButton = styled.button<{ mode: boolean }>`
  font-size: 20px;
  font-weight: bold;
  color: #1a3155;
  opacity: ${props => (props.mode ? 1 : 0.5)};
  cursor: pointer;
  transition: opacity 0.3s ease;

  &:hover {
    opacity: 1;
  }
`;

const ChangeModeButtonSection = styled.div`
  margin-bottom: 60px;
  display: flex;
  align-items: center;
  gap: 40px;
`;

export function CategorySection() {
  return (
    <ChangeModeButtonSection>
      <CategoryButton title="종합" category="jonghap" />
      <CategoryButton title="전문" category="jeonmoon" />
      <CategoryButton title="종합+전문" category="jonghap_jeonmoon" />
      <CategoryButton title="휴대폰DB" category="phone" />
      <CategoryButton title="방문 관리자 공유" category="contact" />
      <CategoryButton title="방문 데이터 공유" category="contact_data" />
    </ChangeModeButtonSection>
  );
}

interface CategoryProps {
  title: string;
  category:
    | 'jonghap'
    | 'jeonmoon'
    | 'jonghap_jeonmoon'
    | 'contact'
    | 'contact_data'
    | 'phone';
}

function CategoryButton(props: CategoryProps) {
  const { mode } = useSelector((state: StoreState) => state.sales);
  const dispatch = useDispatch();

  function onClick() {
    dispatch(updateMode(props.category));
    dispatch(updateSort(null));
    dispatch(resetFilterAreaname);
    dispatch(resetFilters);
  }

  return (
    <ChangeModeButton mode={props.category === mode} onClick={onClick}>
      {props.title}
    </ChangeModeButton>
  );
}
