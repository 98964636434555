import axios from 'axios';

export interface QueryParams {
  database: string;
  categories: string[];
  search_queries: string[];
  offset: number;
  limit: number;
  sort_option: string;
  sort: 'DESC' | 'ASC';
}

async function getEmailBoard(params: QueryParams) {
  const option = {
    url: 'https://cpi.contax.ai/get_board',
    method: 'POST',
    data: params,
    header: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data',
    },
  };

  try {
    const response = await axios(option);
    if (response.status === 200) {
      return {
        status: 'success',
        data: response.data,
      };
    }
  } catch ({ message }) {
    return {
      status: 'failed',
      message: message,
    };
  }
}

async function convertUniqueKeyToName(uniqueKey: string) {
  const option = {
    url: 'https://cpi.contax.ai/sales/convert_unique_key_to_user_name',
    data: {
      unique_keys: uniqueKey,
    },
    method: 'POST',
    header: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data',
    },
  };

  try {
    const response = await axios(option);
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    return error;
  }
}

export { getEmailBoard, convertUniqueKeyToName };
