import routes from '../routes/sidebar';
import { Link, useLocation } from 'react-router-dom';
import SidebarSubmenu from './SidebarSubmenu';
import XMarkIcon from '@heroicons/react/24/outline/XMarkIcon';
import { useDispatch } from 'react-redux';
import { DatePickerComponent } from '../page/sales_manager/component/datePicker';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { updateSelectedMenu } from '../app/slice/layoutSlice';

function LeftSidebar() {
  const [date, setDate] = useState();
  const [firstCount, setFirstCount] = useState(0);
  const [setShareCount] = useState(0);
  const [secondCount, setSecondCount] = useState(0);
  const [thirdCount, setThirdCount] = useState(0);
  const [firstAllCount, setFirstAllCount] = useState(0);
  const [secondAllCount, setSecondAllCount] = useState(0);
  const [thirdAllCount, setThirdAllCount] = useState(0);
  const [detailOn, setDetailOn] = useState(false);
  const [dashboardDetail, setDashboardDetail] = useState(false);
  const [, setData] = useState([]);

  const location = useLocation();

  const close = (e) => {
    document.getElementById('left-sidebar-drawer').click();
  };

  const email = localStorage.getItem('email');
  const user_id = localStorage.getItem('id');
  const name = localStorage.getItem('name');
  const division = localStorage.getItem('manager_division');

  async function loadData(dateParams) {
    if (division === 'sales') {
      const conbiFirstOption = {
        url: 'https://api-contax.kro.kr/load_first_data',
        method: 'GET',
        params: {
          date: dateParams,
          user_id: user_id
        },
        header: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      };

      await axios(conbiFirstOption).then((response) => {
        if (response.status === 200) {
          setData(response.data);
        }
      });
    } else if (division === 'sales_second' && dateParams !== undefined) {
      const conbiSecondOption = {
        url: 'https://api-contax.kro.kr/load_second_count',
        method: 'GET',
        params: {
          date: dateParams,
          user_id: user_id,
          name: name
        },
        header: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      };

      await axios(conbiSecondOption).then((response) => {
        if (response.status === 200) {
        }
      });
    }
  }

  async function loadCount(dateParams) {
    if (division === 'sales') {
      const conbiFirstOption = {
        url: 'https://api-contax.kro.kr/load_first_count',
        method: 'GET',
        params: {
          date: dateParams,
          user_id: user_id,
          name: name
        },
        header: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      };

      await axios(conbiFirstOption).then((response) => {
        if (response.status === 200) {
          setFirstCount(response.data.first_count);
          setShareCount(response.data.share_count);
          setSecondCount(response.data.second_count);
          setThirdCount(response.data.third_count);

          setFirstAllCount(response.data.first_count_all);
          setSecondAllCount(response.data.second_count_all);
          setThirdAllCount(response.data.third_count_all);
        }
      });
    } else if (division === 'sales_second' && dateParams !== undefined) {
      const conbiSecondOption = {
        url: 'https://api-contax.kro.kr/load_second_count',
        method: 'GET',
        params: {
          date: dateParams,
          user_id: user_id,
          name: name
        },
        header: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      };

      await axios(conbiSecondOption).then((response) => {
        if (response.status === 200) {
          setFirstCount(response.data.first_count);
          setShareCount(response.data.share_count);
          setThirdCount(response.data.third_count);

          setFirstAllCount(response.data.first_count_all);
          setSecondAllCount(response.data.second_count_all);
          setThirdAllCount(response.data.third_count_all);
        }
      });
    }
  }

  useEffect(() => {
    var date = new Date();

    // 각 요소를 가져옵니다 (년, 월, 일).
    var year = date.getFullYear();
    var month = date.getMonth() + 1; // getMonth()는 0에서 시작하므로 1을 더해줍니다.
    var day = date.getDate();

    // 월과 일이 10보다 작으면 앞에 0을 붙여줍니다.
    month = month < 10 ? '0' + month : month;
    day = day < 10 ? '0' + day : day;

    // 'YYYY-MM-DD' 형식으로 변환
    var formattedDate = year + '-' + month + '-' + day;
    setDate(formattedDate);

    loadCount(formattedDate);
  }, []);

  useEffect(() => {
    loadCount(date);
    loadData(date);
  }, [date]);

  const dispatch = useDispatch();

  return (
    <div className="drawer-side ">
      <label htmlFor="left-sidebar-drawer" className="drawer-overlay"></label>
      <ul className="menu pt-2 w-60 bg-base-100 text-base-content">
        <button
          className="btn btn-ghost bg-base-300  btn-circle z-50 top-0 right-0 mt-4 mr-2 absolute lg:hidden"
          onClick={() => close()}
        >
          <XMarkIcon className="h-5 inline-block w-5" />
        </button>

        <li className="mb-2 font-semibold text-xl">
          <Link>관리자 페이지</Link>{' '}
        </li>
        <span
          style={{ marginLeft: '20px', marginBottom: '20px', fontSize: '14px' }}
        >
          {email}
        </span>
        {division === 'sales' && (
          <div
            style={{
              borderTop: '1px solid #eee',
              borderBottom: '1px solid #eee',
              padding: '20px 0',
              margin: '20px 20px'
            }}
          >
            <DatePickerComponent
              setDate={(params) => {
                setDate(params);
              }}
            />
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '10px',
                padding: '20px'
              }}
            >
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div
                  style={{ display: 'flex', alignItems: 'center', gap: '10px' }}
                >
                  <span
                    style={{
                      display: 'inline-block',
                      backgroundColor: 'red',
                      width: '5px',
                      height: '5px',
                      borderRadius: '50px'
                    }}
                  ></span>
                  <span style={{ fontSize: '14px' }}>Today 스케줄 완료</span>
                </div>
                <span style={{ fontSize: '14px' }}>{firstCount}</span>
              </div>

              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div
                  style={{ display: 'flex', alignItems: 'center', gap: '10px' }}
                >
                  <span
                    style={{
                      display: 'inline-block',
                      backgroundColor: 'blue',
                      width: '5px',
                      height: '5px',
                      borderRadius: '50px'
                    }}
                  ></span>
                  <span style={{ fontSize: '14px' }}>방문 예약 완료</span>
                </div>
                <span style={{ fontSize: '14px' }}>{secondCount}</span>
              </div>

              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div
                  style={{ display: 'flex', alignItems: 'center', gap: '10px' }}
                >
                  <span
                    style={{
                      display: 'inline-block',
                      backgroundColor: 'green',
                      width: '5px',
                      height: '5px',
                      borderRadius: '50px'
                    }}
                  ></span>
                  <span style={{ fontSize: '14px' }}>방문 완료</span>
                </div>
                <span style={{ fontSize: '14px' }}>{thirdCount}</span>
              </div>

              <div
                onClick={() => {
                  setDetailOn(!detailOn);
                }}
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  cursor: 'pointer'
                }}
              >
                <div
                  style={{ display: 'flex', alignItems: 'center', gap: '10px' }}
                >
                  <span
                    style={{
                      display: 'inline-block',
                      backgroundColor: 'black',
                      width: '5px',
                      height: '5px',
                      borderRadius: '50px'
                    }}
                  ></span>
                  <span style={{ fontSize: '14px' }}>총 영업실적</span>
                </div>
                {detailOn === true && (
                  <span style={{ fontSize: '14px' }}>▲</span>
                )}
                {detailOn === false && (
                  <span style={{ fontSize: '14px' }}>▼</span>
                )}
              </div>

              {detailOn && (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '10px',
                    marginLeft: '20px',
                    marginBottom: '10px'
                  }}
                >
                  <div
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '10px'
                      }}
                    >
                      <span
                        style={{
                          display: 'inline-block',
                          backgroundColor: 'black',
                          width: '5px',
                          height: '5px',
                          borderRadius: '50px'
                        }}
                      ></span>
                      <span style={{ fontSize: '14px' }}>1차 스케줄 완료</span>
                    </div>
                    <span style={{ fontSize: '14px' }}>{firstAllCount}</span>
                  </div>

                  <div
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '10px'
                      }}
                    >
                      <span
                        style={{
                          display: 'inline-block',
                          backgroundColor: 'black',
                          width: '5px',
                          height: '5px',
                          borderRadius: '50px'
                        }}
                      ></span>
                      <span style={{ fontSize: '14px' }}>2차 스케줄 완료</span>
                    </div>
                    <span style={{ fontSize: '14px' }}>{secondAllCount}</span>
                  </div>

                  <div
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '10px'
                      }}
                    >
                      <span
                        style={{
                          display: 'inline-block',
                          backgroundColor: 'black',
                          width: '5px',
                          height: '5px',
                          borderRadius: '50px'
                        }}
                      ></span>
                      <span style={{ fontSize: '14px' }}>방문 완료</span>
                    </div>
                    <span style={{ fontSize: '14px' }}>{thirdAllCount}</span>
                  </div>
                </div>
              )}

              {division === 'sales' && (
                <div
                  style={{ color: '#aaa', fontSize: '14px', cursor: 'pointer' }}
                  onClick={() => {
                    setDashboardDetail(!dashboardDetail);
                  }}
                >
                  상세보기 {'>'}
                </div>
              )}
            </div>
          </div>
        )}

        {division === 'sales_second' && (
          <div
            style={{
              borderTop: '1px solid #eee',
              borderBottom: '1px solid #eee',
              padding: '20px 0',
              margin: '20px 20px'
            }}
          >
            <DatePickerComponent
              setDate={(params) => {
                setDate(params);
              }}
            />
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '10px',
                padding: '20px'
              }}
            >
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div
                  style={{ display: 'flex', alignItems: 'center', gap: '10px' }}
                >
                  <span
                    style={{
                      display: 'inline-block',
                      backgroundColor: 'red',
                      width: '5px',
                      height: '5px',
                      borderRadius: '50px'
                    }}
                  ></span>
                  <span style={{ fontSize: '14px' }}>Today 방문 예약 완료</span>
                </div>
                <span style={{ fontSize: '14px' }}>{firstCount}</span>
              </div>

              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div
                  style={{ display: 'flex', alignItems: 'center', gap: '10px' }}
                >
                  <span
                    style={{
                      display: 'inline-block',
                      backgroundColor: 'blue',
                      width: '5px',
                      height: '5px',
                      borderRadius: '50px'
                    }}
                  ></span>
                  <span style={{ fontSize: '14px' }}>방문 완료</span>
                </div>
                <span style={{ fontSize: '14px' }}>{thirdCount}</span>
              </div>

              <div
                onClick={() => {
                  setDetailOn(!detailOn);
                }}
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  cursor: 'pointer'
                }}
              >
                <div
                  style={{ display: 'flex', alignItems: 'center', gap: '10px' }}
                >
                  <span
                    style={{
                      display: 'inline-block',
                      backgroundColor: 'black',
                      width: '5px',
                      height: '5px',
                      borderRadius: '50px'
                    }}
                  ></span>
                  <span style={{ fontSize: '14px' }}>총 영업실적</span>
                </div>
                {detailOn === true && (
                  <span style={{ fontSize: '14px' }}>▲</span>
                )}
                {detailOn === false && (
                  <span style={{ fontSize: '14px' }}>▼</span>
                )}
              </div>

              {detailOn && (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '10px',
                    marginLeft: '20px',
                    marginBottom: '10px'
                  }}
                >
                  <div
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '10px'
                      }}
                    >
                      <span
                        style={{
                          display: 'inline-block',
                          backgroundColor: 'black',
                          width: '5px',
                          height: '5px',
                          borderRadius: '50px'
                        }}
                      ></span>
                      <span style={{ fontSize: '14px' }}>2차 스케줄 완료</span>
                    </div>
                    <span style={{ fontSize: '14px' }}>{firstAllCount}</span>
                  </div>

                  <div
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '10px'
                      }}
                    >
                      <span
                        style={{
                          display: 'inline-block',
                          backgroundColor: 'black',
                          width: '5px',
                          height: '5px',
                          borderRadius: '50px'
                        }}
                      ></span>
                      <span style={{ fontSize: '14px' }}>방문 완료</span>
                    </div>
                    <span style={{ fontSize: '14px' }}>{thirdAllCount}</span>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}

        {routes.map((route, k) => {
          return (
            <li className="" key={k}>
              {route.submenu ? (
                <SidebarSubmenu {...route} />
              ) : (
                <div
                  onClick={() => {
                    window.localStorage.setItem('selected_menu', route.name);
                    dispatch(updateSelectedMenu(route.name));
                  }}
                  className={({ isActive }) =>
                    `${isActive ? 'font-semibold  bg-base-200 ' : 'font-normal'}`
                  }
                >
                  {route.icon} {route.name}
                  {location.pathname === route.path ? (
                    <span
                      className="absolute inset-y-0 left-0 w-1 rounded-tr-md rounded-br-md bg-primary "
                      aria-hidden="true"
                    ></span>
                  ) : null}
                </div>
              )}
            </li>
          );
        })}
      </ul>
    </div>
  );
}

export default LeftSidebar;
