/* eslint-disable react/jsx-pascal-case */
import { Input } from '@mui/material';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import {
  enterJonghapExtraInfo,
  removeRejection,
} from '../../../app/slice/jonghapSlice';
import {
  insertSalesClassify,
  updateExtraInfo,
  updateRecall,
} from '../../../utils/sales_API';
import { resetRecall } from '../../../app/slice/checkListSlice';
import {
  updateRecallDate,
  updateRecallTrue,
} from '../../../app/slice/jonghapSlice';
import axios from 'axios';

const St_MemoSection = styled.div`
  display: flex;
  width: 40%;
  flex-direction: column;
  padding: 20px;
  position: relative;
`;

const St_DetailMemo = styled.textarea`
  border: 1px solid #ddd;
  margin: 10px 0;
  padding: 10px;
  height: 100%;
  resize: none;
`;

const St_DetailContentSection = styled.div`
  height: auto;
  display: ${props => (props.detailOn === true ? 'block' : 'none')};
`;

const St_RowDetailElement = styled.span`
  font-size: 15px;
  &:nth-child(2n-1) {
    font-weight: bold;
  }
  margin-bottom: 15px;
  max-width: 250px;
`;

const St_RowDetailContent = styled.div`
  padding: 20px;
  width: 60%;

  background-color: #fff;
  display: grid;
  grid-template-columns: 3fr 4fr;
  gap: 5px;
  align-items: center;
`;

export function JonghapRowDetailContent({ data, detailOn, target }) {
  const [modalOn, setModalOn] = useState(false);
  const dispatch = useDispatch();

  async function updateResolve(data) {
    if (!window.confirm('정말 해결 처리 하시겠습니까?')) {
      return false;
    }

    const option = {
      url: 'https://cpi.contax.ai/sales/update_rejection_resolve',
      method: 'PUT',
      data: {
        ncrgsseq: data.unique_key,
        second_manager_id: 116,
        contact_manager_id: data.rejection_item.contact_manager_id,
      },
      header: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    };

    await axios(option).then(response => {
      if (response.status === 200) {
        alert('해당 데이터의 해결처리가 완료되었습니다.');
        dispatch(removeRejection());
      }
    });
  }

  return (
    <div
      style={{
        borderLeft: '1px solid #eee',
        borderRight: '1px solid #eee',
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'normal',
      }}
    >
      <St_DetailContentSection
        detailOn={data.unique_key === detailOn.key && detailOn.on === true}
      >
        {data.rejection === true && !data.rejection_item.resolve && (
          <div
            style={{
              paddingBottom: '30px',
              margin: '30px',
              borderBottom: '1px solid #ddd',
            }}
          >
            <h2
              style={{
                marginBottom: '20px',
                fontSize: '16px',
                fontWeight: 'bold',
              }}
            >
              반려
            </h2>

            <div
              style={{
                display: 'flex',
                gap: '20px',
                justifyContent: 'space-between',
              }}
            >
              <div
                style={{
                  width: '47%',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '10px',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <span
                    style={{
                      fontSize: '15px',
                      fontWeight: 'bold',
                    }}
                  >
                    반려 상호명
                  </span>
                  <span
                    style={{
                      fontSize: '15px',
                      color: '#555',
                    }}
                  >
                    {data.rejection_item.item}
                  </span>
                </div>

                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <span
                    style={{
                      fontSize: '15px',
                      fontWeight: 'bold',
                    }}
                  >
                    방문 영업사원
                  </span>
                  <span
                    style={{
                      fontSize: '15px',
                      color: '#555',
                    }}
                  >
                    {data.rejection_item.contact_manager_name}
                  </span>
                </div>

                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <span
                    style={{
                      fontSize: '15px',
                      fontWeight: 'bold',
                    }}
                  >
                    2차 관리자
                  </span>
                  <span
                    style={{
                      fontSize: '15px',
                      color: '#555',
                    }}
                  >
                    {data.rejection_item.second_manager_name}
                  </span>
                </div>

                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <span
                    style={{
                      fontSize: '15px',
                      fontWeight: 'bold',
                    }}
                  >
                    반려 상호명
                  </span>
                  <span
                    style={{
                      fontSize: '15px',
                      color: '#555',
                    }}
                  >
                    {data.rejection_item.item}
                  </span>
                </div>

                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <span
                    style={{
                      fontSize: '15px',
                      fontWeight: 'bold',
                    }}
                  >
                    반려된 날짜
                  </span>
                  <span
                    style={{
                      fontSize: '15px',
                      color: '#555',
                    }}
                  >
                    {data.rejection_item.date}
                  </span>
                </div>
              </div>

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '53%',
                }}
              >
                <span
                  style={{
                    fontSize: '15px',
                    fontWeight: 'bold',
                    marginBottom: '10px',
                  }}
                >
                  반려 사유
                </span>
                <textarea
                  defaultValue={data.rejection_item.reason}
                  disabled
                  style={{
                    resize: 'none',
                    height: '100%',
                    border: '1px solid #ddd',
                    padding: '10px',
                  }}
                />
                <button
                  onClick={() => {
                    updateResolve(data);
                  }}
                  style={{
                    width: '100px',
                    backgroundColor: '#1976d2',
                    marginLeft: 'auto',
                    marginTop: '10px',
                    color: '#fff',
                    fontSize: '14px',
                    borderRadius: '5px',
                    padding: '5px 0',
                  }}
                >
                  반려 해결
                </button>
              </div>
            </div>
          </div>
        )}
        <div style={{ display: 'flex' }}>
          <St_RowDetailContent>
            <h3
              style={{
                fontSize: '17px',
                fontWeight: 'bold',
                marginBottom: '20px',
              }}
            >
              상세 내용
            </h3>
            <span></span>
            <St_RowDetailElement>이메일</St_RowDetailElement>
            <St_RowDetailElement>
              <Input
                placeholder="이메일 입력"
                sx={{ fontSize: '12px' }}
                value={data.email === null ? '' : data.email}
                onChange={e => {
                  target === 'jonghap' &&
                    dispatch(
                      enterJonghapExtraInfo({
                        target: 'email',
                        value: e.target.value,
                      }),
                    );
                }}
                onBlur={() => {
                  updateExtraInfo('email', data.email, data.unique_key, target);
                }}
              />
            </St_RowDetailElement>
            <St_RowDetailElement>휴대전화 번호</St_RowDetailElement>
            <St_RowDetailElement>
              <Input
                placeholder="휴대전화 번호 입력"
                sx={{ fontSize: '12px' }}
                value={data.phone_number === null ? '' : data.phone_number}
                onChange={e => {
                  target === 'jonghap' &&
                    dispatch(
                      enterJonghapExtraInfo({
                        target: 'phone_number',
                        value: e.target.value,
                      }),
                    );
                }}
                onBlur={() => {
                  updateExtraInfo(
                    'phone_number',
                    data.phone_number,
                    data.unique_key,
                    target,
                  );
                }}
              />
            </St_RowDetailElement>
            <St_RowDetailElement>팩스</St_RowDetailElement>
            <St_RowDetailElement>
              <Input
                placeholder="팩스 입력"
                sx={{ fontSize: '12px' }}
                value={data.fax === null ? '' : data.fax}
                onChange={e => {
                  target === 'jonghap' &&
                    dispatch(
                      enterJonghapExtraInfo({
                        target: 'fax',
                        value: e.target.value,
                      }),
                    );
                }}
                onBlur={() => {
                  updateExtraInfo('fax', data.fax, data.unique_key, target);
                }}
              />
            </St_RowDetailElement>
            <St_RowDetailElement>담당자</St_RowDetailElement>
            <St_RowDetailElement>
              <Input
                placeholder="담당자 입력"
                sx={{ fontSize: '12px' }}
                value={data.manager === null ? '' : data.manager}
                onChange={e => {
                  target === 'jonghap' &&
                    dispatch(
                      enterJonghapExtraInfo({
                        target: 'manager',
                        value: e.target.value,
                      }),
                    );
                }}
                onBlur={() => {
                  updateExtraInfo(
                    'manager',
                    data.manager,
                    data.unique_key,
                    target,
                  );
                }}
              />
            </St_RowDetailElement>

            <St_RowDetailElement>등록시도</St_RowDetailElement>
            <St_RowDetailElement>{data.area}</St_RowDetailElement>

            <St_RowDetailElement>소재지</St_RowDetailElement>
            <St_RowDetailElement>
              {data.address === 'NULL' ? '-' : data.address}
            </St_RowDetailElement>

            <St_RowDetailElement>사업자등록번호</St_RowDetailElement>
            <St_RowDetailElement>
              {data.ncrmasternum === 'NULL' ? '-' : data.ncrmasternum}
            </St_RowDetailElement>

            <St_RowDetailElement>평가액 (단위: 백만원)</St_RowDetailElement>
            <St_RowDetailElement>{data.valuation}</St_RowDetailElement>

            <St_RowDetailElement>
              평가 합산액 (단위: 백만원)
            </St_RowDetailElement>
            <St_RowDetailElement>
              {data.abs_sum_value.toLocaleString('ko-KR')}
            </St_RowDetailElement>
          </St_RowDetailContent>

          <St_MemoSection>
            <div
              style={{
                marginTop: '50px',
                display: 'flex',
                justifyContent: 'space-between',
                fontSize: '14px',
              }}
            >
              <span style={{ fontWeight: 'bold', fontSize: '15px' }}>
                가망 등급 분류
              </span>
              <div
                style={{ display: 'flex', gap: '20px', marginBottom: '10px' }}
              >
                <div
                  style={{
                    display: 'flex',
                    gap: '10px',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    if (data.classification === '낮음') {
                      dispatch(
                        enterJonghapExtraInfo({
                          target: 'classification',
                          value: null,
                        }),
                      );
                      insertSalesClassify(
                        data.unique_key,
                        data.ncrgskname,
                        '없음',
                      );

                      return;
                    }

                    dispatch(
                      enterJonghapExtraInfo({
                        target: 'classification',
                        value: '낮음',
                      }),
                    );
                    insertSalesClassify(
                      data.unique_key,
                      data.ncrgskname,
                      '낮음',
                    );
                  }}
                >
                  <span>낮음</span>
                  <input
                    type="checkbox"
                    checked={data.classification === '낮음'}
                  />
                </div>
                <div
                  style={{
                    display: 'flex',
                    gap: '10px',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    if (data.classification === '보통') {
                      dispatch(
                        enterJonghapExtraInfo({
                          target: 'classification',
                          value: null,
                        }),
                      );
                      insertSalesClassify(
                        data.unique_key,
                        data.ncrgskname,
                        '없음',
                      );

                      return;
                    }

                    dispatch(
                      enterJonghapExtraInfo({
                        target: 'classification',
                        value: '보통',
                      }),
                    );
                    insertSalesClassify(
                      data.unique_key,
                      data.ncrgskname,
                      '보통',
                    );
                  }}
                >
                  <span>보통</span>
                  <input
                    type="checkbox"
                    checked={data.classification === '보통'}
                  />
                </div>

                <div
                  style={{
                    display: 'flex',
                    gap: '10px',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    if (data.classification === '높음') {
                      dispatch(
                        enterJonghapExtraInfo({
                          target: 'classification',
                          value: null,
                        }),
                      );
                      insertSalesClassify(
                        data.unique_key,
                        data.ncrgskname,
                        '없음',
                      );

                      return;
                    }

                    dispatch(
                      enterJonghapExtraInfo({
                        target: 'classification',
                        value: '높음',
                      }),
                    );
                    insertSalesClassify(
                      data.unique_key,
                      data.ncrgskname,
                      '높음',
                    );
                  }}
                >
                  <span>높음</span>
                  <input
                    type="checkbox"
                    checked={data.classification === '높음'}
                  />
                </div>
              </div>
            </div>
            <div
              onClick={() => {
                if (data.recall_true === false || data.recall_true === null) {
                  setModalOn(true);
                } else {
                  updateRecall(data.unique_key, false, '');
                  alert('재연락을 취소하였습니다.');
                  dispatch(updateRecallTrue(false));
                }
              }}
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '10px',
                cursor: 'pointer',
                width: '100px',
                marginBottom: '10px',
              }}
            >
              <span style={{ fontWeight: 'bold', fontSize: '15px' }}>
                재연락
              </span>
              <input
                type="checkbox"
                checked={
                  data.recall_true === false ||
                  data.recall_true === '' ||
                  data.recall_true === null
                    ? false
                    : true
                }
              />
            </div>
            <h3 style={{ fontWeight: 'bold', marginTop: '10px' }}>메모 작성</h3>
            <St_DetailMemo
              placeholder="메모를 입력해주세요"
              value={data.memo === null ? '' : data.memo}
              onChange={e => {
                target === 'jonghap' &&
                  dispatch(
                    enterJonghapExtraInfo({
                      target: 'memo',
                      value: e.target.value,
                    }),
                  );
              }}
              onKeyDown={e => {
                if (e.key === 'F5') {
                  e.preventDefault(); // Prevent the default action of F5
                  const now = new Date();

                  const timeString =
                    now.getFullYear() +
                    '년 ' +
                    (now.getMonth() + 1) +
                    '월 ' +
                    now.getDate() +
                    '일 ' +
                    now.toLocaleTimeString() +
                    '\n> ';
                  dispatch(
                    enterJonghapExtraInfo({
                      target: 'memo',
                      value:
                        data.memo === null
                          ? timeString
                          : data.memo + timeString,
                    }),
                  );
                }
              }}
              onBlur={() => {
                updateExtraInfo(
                  'memo',
                  data.memo === null ? '' : data.memo,
                  data.unique_key,
                  target,
                );
              }}
            />

            {modalOn && (
              <div
                style={{
                  padding: '10px',
                  backgroundColor: '#fff',
                  width: '350px',
                  bottom: '65px',
                  height: 'auto',
                  border: '1px solid #ddd',
                  position: 'absolute',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <h3 style={{ fontWeight: 'bold' }}>재연락 날짜 입력</h3>
                  <span
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      setModalOn(false);
                    }}
                  >
                    X
                  </span>
                </div>

                <input
                  style={{
                    border: '1px solid #ddd',
                    padding: '5px 10px ',
                    marginTop: '10px',
                  }}
                  value={data.recall_date}
                  onChange={e => {
                    dispatch(updateRecallDate(e.target.value));
                  }}
                />
                <span
                  style={{
                    marginTop: '10px',
                    fontSize: '12px',
                    color: '#aaa',
                  }}
                >
                  {'형식에 맞게 입력해주세요. 예) 24.01.01.17'}
                </span>
                <button
                  onClick={() => {
                    const regex =
                      /^\d{2}\.((0[1-9])|(1[0-2]))\.((0[1-9])|([1-2][0-9])|(3[0-1]))\.([0-1][0-9]|2[0-3])$/;
                    if (!regex.test(data.recall_date)) {
                      alert(
                        '날짜 형식이 맞지 않습니다. 형식에 맞게 수정해주세요 \n 예) 24.01.01.17',
                      );
                      return false;
                    } else {
                      alert('해당 데이터 재연락 날짜를 남겼습니다.');

                      dispatch(updateRecallTrue(true));
                      updateRecall(data.unique_key, true, data.recall_date);

                      setModalOn(false);
                    }
                  }}
                  style={{
                    padding: '5px',
                    border: '1px solid #ddd',
                    borderRadius: '5px',
                    marginTop: '10px',
                    backgroundColor: '#eee',
                  }}
                >
                  저장
                </button>
              </div>
            )}
          </St_MemoSection>
        </div>
      </St_DetailContentSection>
    </div>
  );
}
