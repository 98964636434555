import React from 'react';
import { TabButton } from '../../../components/Layout/TabButton';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { StoreState } from '../../../app/store';
import { updateOffset, updateTab } from '../../../app/slice/dailyBidSlice';

const St_MemberWrapper = styled.div`
  margin-bottom: 60px;
  display: flex;
  align-items: center;
  gap: 40px;
`;

export function TabList() {
  const dispatch = useDispatch();
  const { selectedTab, tabList } = useSelector(
    (state: StoreState) => state.dailyBid
  );

  function onClick(params: string) {
    dispatch(updateTab(params));
    dispatch(updateOffset(0));
  }

  return (
    <St_MemberWrapper>
      {tabList.map((tab) => (
        <TabButton
          key={tab}
          active={tab === selectedTab}
          onClick={() => {
            onClick(tab);
          }}
        >
          {tab}
        </TabButton>
      ))}
    </St_MemberWrapper>
  );
}
