import React from 'react';
import styled from 'styled-components';

const MenuItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-bottom: 12px;
  max-height: 150px;
  padding: 10px;
  border-radius: 5px;
  position: relative;
  border: 2px solid red;

  //&:hover {
  //  transform: scale(1.05);
  //}
`;

interface LicensesCountType {
  [key: string]: number;
}

interface LicensesCountDisplayProps {
  data: LicensesCountType;
}

const ReceiverCountDisplay: React.FC = () => {
  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr 1fr',
        width: '100%',
        padding: '20px',
        marginBottom: '1rem',
        borderRadius: '10px',
        height: '400px',
        overflowY: 'scroll',
        gap: '20px',
        border: '2px solid #ddd'
      }}
    >
      <MenuItem>
        <div style={{ fontWeight: 'bold' }}>김테스트 [대한종합건설]</div>
        <div style={{ textAlign: 'center' }}>오전 10시 00분</div>
        <div
          style={{
            textAlign: 'left',
            color: 'red',
            marginTop: 'auto',
            fontSize: '18px',
            wordBreak: 'break-word'
          }}
        >
          이메일 1건, 카카오톡 1건 실패, 1건 발송 완료
        </div>

        {/*<div*/}
        {/*  style={{*/}
        {/*    top: '85%',*/}
        {/*    left: '50%',*/}
        {/*    position: 'absolute',*/}
        {/*    width: '300px',*/}
        {/*    height: '200px',*/}
        {/*    border: '2px solid #ddd',*/}
        {/*    borderRadius: '10px',*/}
        {/*    backgroundColor: '#fff',*/}
        {/*    display: 'inline-flex',*/}
        {/*    flexDirection: 'column',*/}
        {/*    padding: '10px'*/}
        {/*  }}*/}
        {/*>*/}
        {/*  <span>오류내용</span>*/}
        {/*  <span style={{ marginTop: '10px' }}>테스트 상황입니다.</span>*/}
        {/*</div>*/}
      </MenuItem>

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'start',
          marginBottom: '12px',
          maxHeight: '150px',
          padding: '10px',
          borderRadius: '5px',
          border: '2px solid #ddd'
        }}
      >
        <div style={{ fontWeight: 'bold' }}>김아무개 [대한종합건설]</div>
        <div style={{ textAlign: 'center' }}>오전 10시 00분</div>
        <div
          style={{ textAlign: 'center', marginTop: 'auto', fontSize: '18px' }}
        >
          발송 성공
        </div>
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'start',
          marginBottom: '12px',
          maxHeight: '150px',
          padding: '10px',
          borderRadius: '5px',
          border: '2px solid #ddd'
        }}
      >
        <div style={{ fontWeight: 'bold' }}>김성공 [대한종합건설]</div>
        <div style={{ textAlign: 'center' }}>오전 10시 00분</div>
        <div
          style={{ textAlign: 'center', marginTop: 'auto', fontSize: '18px' }}
        >
          발송 성공
        </div>
      </div>
    </div>
  );
};

export default ReceiverCountDisplay;
