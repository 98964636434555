import axios from 'axios';

export interface QueryParams {
  database: 'api_error_log';
  categories: string[];
  search_queries: string[];
  offset: number;
  limit: number;
  sort_option: 'date';
  sort: 'DESC' | 'ASC';
}

async function getErrorBoard(offset: number, limit: number) {
  const body: QueryParams = {
    database: 'api_error_log',
    categories: [],
    search_queries: [],
    offset: offset * limit,
    limit,
    sort_option: 'date',
    sort: 'DESC',
  };
  const option = {
    url: 'https://cpi.contax.ai/get_board',
    method: 'POST',
    data: body,
    header: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data',
    },
  };

  try {
    const response = await axios(option);
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    return [];
  }
}

export { getErrorBoard };
