import styled from 'styled-components';
import React from 'react';
import { MenuItem, Select } from '@mui/material';

const PropertyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 25%;
`;

const Title = styled.span`
  font-weight: bold;
  font-size: 14px;
  color: #606975;
`;

const Input = styled.input<{ error: boolean }>`
  border-radius: 10px;
  border: ${(props) => (props.error ? '1px solid red' : '1px solid #ddd')};
  font-size: 16px;
  padding: 7px 10px;
  color: rgba(13, 29, 51, 0.7);
  width: 100%;
`;

const Error = styled.span`
  font-size: 14px;
  color: red;
`;

interface DetailPropertyProps {
  title: string;
  value: string;
  active?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  error?: string;
}

export function CreateProperty(props: DetailPropertyProps) {
  return (
    <PropertyWrapper>
      <Title>{props.title}</Title>
      <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
        <Input
          error={!(props.error === '' || props.error == null)}
          onChange={props.onChange}
          disabled={props.active === undefined ? true : !props.active}
          value={props.value}
        />
        <Error>{props.error}</Error>
      </div>
    </PropertyWrapper>
  );
}

export function CreateSelectProperty(props: DetailPropertyProps) {
  return (
    <PropertyWrapper>
      <Title>{props.title}</Title>
      <Select
        sx={{
          borderRadius: '10px',
          border: '2px solid #ddd',
          padding: '6px',
          color: 'rgba(13, 29, 51, 0.7)',
          fontSize: '18px',
          fontWeight: 'bold'
        }}
        disabled={props.active === undefined ? true : props.active}
        defaultValue={props.value}
      >
        <MenuItem value={0}>아니오</MenuItem>
        <MenuItem value={1}>예</MenuItem>
      </Select>
    </PropertyWrapper>
  );
}
