import TitleCard from '../../components/Cards/TitleCard';
import Button from '@mui/material/Button';
import { Grid } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import {
  ManagerGrid,
  ManagerGridButton,
  ManagerGridRow,
  ManagerGridRowSection
} from '../../components/Grid';

const Grid_COL = [
  { title: '번호', width: '20', textAlign: 'left' },
  { title: '내용', width: '60', textAlign: 'left' },
  { title: '설정', width: '20', textAlign: 'left' }
];

function TaxManagementSetting() {
  useEffect(() => {
    loadTaxReview();
  }, []);

  const [review, setReview] = useState([{ id: 0, text: '', type: 'text' }]);

  async function loadTaxReview(e) {
    setReview([]);

    let option = {
      url: 'https://cpi.contax.ai/load_pyeongjeom_review',
      method: 'GET',
      header: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data'
      }
    };

    await axios(option).then((response) => {
      if (response.status === 200) {
        response.data.forEach((item) => {
          item.type = 'text';
        });

        setReview(response.data);
      }
    });
  }

  async function insertTaxReview(e) {
    let option = {
      url: 'https://cpi.contax.ai/insert_pyeongjeom_review',
      method: 'POST',
      header: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data'
      }
    };

    await axios(option).then((response) => {
      if (response.status === 200) {
        const array = [...review];
        array.push({ id: response.data, text: '', type: 'input' });
        setReview(array);
      }
    });
  }

  async function deleteTaxReview(index, id) {
    if (!window.confirm('정말 삭제하시겠습니까?')) {
      return false;
    }

    let option = {
      url: 'https://cpi.contax.ai/delete_pyeongjeom_review',
      method: 'DELETE',
      params: {
        id: id
      },
      header: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data'
      }
    };

    await axios(option).then((response) => {
      if (response.status === 200) {
        const array = [...review];
        array.splice(index, 1);
        setReview(array);
      }
    });
  }

  async function updateTaxReview(id, content) {
    let option = {
      url: 'https://cpi.contax.ai/update_pyeongjeom_review',
      method: 'PUT',
      params: {
        id: id,
        content: content
      },
      header: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data'
      }
    };

    await axios(option).then((response) => {
      if (response.status === 200) {
        const array = [...review];
        array.text = content;
        setReview(array);
      }
    });
  }

  function changeType(id, index, type) {
    if (type === 'input') {
      const array = [...review];
      array[index].type = 'input';

      setReview(array);
    }

    if (type === 'text') {
      const array = [...review];
      array[index].type = 'text';

      setReview(array);

      updateTaxReview(id, array[index].text);
    }
  }

  function changeText(value, index) {
    const array = [...review];
    array[index].text = value;

    setReview(array);
  }

  return (
    <>
      <Grid container spacing={2}>
        <TitleCard title={'세무 관리자 설정'}>
          <ManagerGrid col={Grid_COL}>
            {review.map((item, index) => {
              return (
                <ManagerGridRowSection>
                  <ManagerGridRow width={20}>{item.id}</ManagerGridRow>
                  <ManagerGridRow width={60}>
                    {item.type === 'text' && (
                      <span
                        onDoubleClick={() => {
                          changeType(item.id, index, 'input');
                        }}
                      >
                        {item.text}
                      </span>
                    )}

                    {item.type === 'input' && (
                      <input
                        onChange={(e) => {
                          changeText(e.target.value, index);
                        }}
                        onBlur={() => {
                          changeType(item.id, index, 'text');
                          updateTaxReview(item.id, item.text);
                        }}
                        style={{
                          border: '1px solid #ddd',
                          padding: '5px 10px',
                          width: '80%'
                        }}
                        placeholder="이곳에 질문을 입력해주세요"
                        type="text"
                        value={item.text}
                      />
                    )}
                  </ManagerGridRow>
                  <ManagerGridRow width={20}>
                    <Button
                      sx={{ minWidth: '40px' }}
                      onMouseDown={() => {
                        changeType(
                          item.id,
                          index,
                          item.type === 'input' ? 'text' : 'input'
                        );
                      }}
                      disableElevation
                      variant="contained"
                    >
                      <span
                        style={{ fontSize: '16px' }}
                        className="material-symbols-outlined"
                      >
                        edit
                      </span>
                    </Button>
                    <Button
                      sx={{ minWidth: '40px', backgroundColor: '#fff' }}
                      onMouseDown={() => {
                        deleteTaxReview(index, item.id);
                      }}
                      disableElevation
                      variant="outlined"
                    >
                      <span
                        style={{ fontSize: '16px' }}
                        className="material-symbols-outlined"
                      >
                        delete
                      </span>
                    </Button>
                  </ManagerGridRow>
                </ManagerGridRowSection>
              );
            })}

            <ManagerGridButton
              onClick={insertTaxReview}
              backgroundColor="#3d8ede"
              color="#fff"
            >
              <span className="material-symbols-outlined">add</span>
              <span>코멘트 질문 추가</span>
            </ManagerGridButton>
          </ManagerGrid>
        </TitleCard>
      </Grid>
    </>
  );
}

export default TaxManagementSetting;
