import axios from 'axios';

export async function updateNotificationSetting(
  notification_on: boolean,
  time_hour: number,
  time_minute: number
) {
  const option = {
    url: 'https://cpi.contax.ai/update_bid_notification_setting',
    method: 'PUT',
    data: {
      notification_on: notification_on,
      time_hour: time_hour,
      time_minute: time_minute
    },
    header: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data'
    }
  };

  try {
    const response = await axios(option);
    if (response.status === 200) {
      return {
        status: 'success',
        data: response.data
      };
    } else {
      return {
        status: 'failed',
        data: []
      };
    }
  } catch ({ message }) {
    return {
      status: 'failed',
      message: message
    };
  }
}
