import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { StoreState } from '../../app/store';

export function PreviewEmailEditor(props: { value: string }) {
  const [content, setContent] = useState('');
  const { checked_item } = useSelector((state: StoreState) => state.emailBoard);

  function previewConverter(input: string) {
    // 정규 표현식: [[<예약어> 내용]] 형식을 찾음
    const regex = /\[\[&lt;([^>]+)&gt; ([^\]]+)\]\]/g;

    // 문자열에서 정규 표현식과 일치하는 부분을 <div>내용</div>으로 대체
    const replacedString = input
      .replaceAll('<h1>', '<h1 style="font-size: 32px; font-weight: bold;">')
      .replace(regex, (match, p1, p2) => {
        let title = '';
        let content = '';
        let link = '';

        checked_item.forEach(item => {
          if (item.unique_key === p1) {
            title = item.title;
            content = item.content;

            const keywordParts = p1.split('_');

            if (keywordParts[0] === '공지사항') {
              link = 'https://contax.ai/notice/' + keywordParts[1];
            }

            if (keywordParts[0] === '이용안내') {
              link = 'https://contax.ai/use_guide/' + keywordParts[1];
            }

            if (keywordParts[0] === '매거진') {
              link = 'https://contax.ai/magazine/' + keywordParts[1];
            }

            if (keywordParts[0] === '자주하는 질문') {
              link = 'https://contax.ai/customerService/' + keywordParts[1];
            }
          }
        });

        return `
                <div onclick="window.location.href = '${link}';" style="cursor:pointer; border: 1px solid #ddd; display: flex; align-items:center; ">
                    <div style="width: 120px; height: 120px; background-size:cover;  background-image: url('https://admin.contax.ai/thumbnail.png')"></div>
                    <div style="width: 70%; margin-left: 20px;">
                        <h1 style="font-weight: bold; font-size: 20px;">${title}</h1>
                        <div style="padding-top: 20px; height: 40px; overflow:hidden;">${content}</div>
                    </div>
                </div>
            `;
      });

    setContent(replacedString);
  }

  useEffect(() => {
    previewConverter(props.value);
  }, [props.value]);
  return (
    <div style={{ backgroundColor: '#f5f4f4' }}>
      <div style={{ backgroundColor: '#fff', padding: '150px 50px' }}>
        <div dangerouslySetInnerHTML={{ __html: content }} />
      </div>
    </div>
  );
}
