import React, { useEffect } from 'react';
import {
  ColProps,
  ManagerGrid,
  ManagerGridCheckbox,
  ManagerGridRow,
  ManagerGridRowSection,
} from '../../components/GridType';
import styled from 'styled-components';
import BoardPagenation from '../../components/BoardPagenation';
import { getAllUserEmailManager } from '../../utils/user_API';
import {
  calculatePagination,
  createPaginationSize,
  getManagerEmailMember,
} from '../../utils/common_API';
import { AllCheck } from './component/AllCheck';
import { Settings } from '@mui/icons-material';
import { SettingModal } from './component/SettingModal';
import { useDispatch, useSelector } from 'react-redux';
import { StoreState } from '../../app/store';
import {
  SelectedMemberTypes,
  getMember,
  getSelectMember,
  handleAllCheck,
  handleCheck,
  resetSelectMember,
} from '../../app/slice/emailSlice';

const Grid_COL: ColProps[] = [
  {
    title: '선택',
    id: 'select',
    width: 15,
    textAlign: 'center',
    sort: false,
    search: false,
  },
  {
    title: '대표자명',
    id: 'representative_name',
    width: 20,
    textAlign: 'left',
    sort: true,
    search: true,
  },
  {
    title: '이메일',
    id: 'email',
    width: 40,
    textAlign: 'left',
    sort: true,
    search: true,
  },
  {
    title: '회사명',
    id: 'company_name',
    width: 25,
    textAlign: 'right',
    sort: true,
    search: true,
  },
];

const Title = styled.span`
  font-weight: bold;
  font-size: 16px;
`;

export function SelectMemberList() {
  const { isAllCheck, settings, member } = useSelector(
    (state: StoreState) => state.email,
  );
  const dispatch = useDispatch();

  async function clickAllCheck() {
    dispatch(handleAllCheck(!isAllCheck));
    if (isAllCheck === false) {
      const id = localStorage.getItem('id');
      const response = await getAllUserEmailManager(
        Number(id),
        settings.plan,
        settings.area,
        offset,
        limit,
      );

      if (response.status === 'success') {
        dispatch(resetSelectMember());
        response.data.data.forEach((item: any) => {
          dispatch(getSelectMember(item));
        });
      }
    } else {
      dispatch(resetSelectMember());
    }
  }

  async function loadEmailMember() {
    const id = localStorage.getItem('id');
    const result = await getManagerEmailMember(
      Number(id),
      settings.plan,
      settings.area,
      offset,
      limit,
    );

    if (result!.status === 'success') {
      dispatch(getMember(result!.data.data));

      const length = calculatePagination(result!.data.total_count, limit);
      let array = [];
      array = createPaginationSize(offset, length);
      setPageLength(length);
      setPageNumbers(array);
    }
  }

  function onClick(params: SelectedMemberTypes) {
    dispatch(getSelectMember(params));
  }

  const [offset, setOffset] = React.useState(0);
  const [limit, setLimit] = React.useState(10);
  const [page_numbers, setPageNumbers] = React.useState([1, 2, 3, 4, 5]);
  const [page_length, setPageLength] = React.useState(1);
  const [onModal, setOnModal] = React.useState(false);

  useEffect(() => {
    loadEmailMember();
  }, [offset]);

  return (
    <div
      style={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        gap: '15px',
      }}
    >
      {onModal && (
        <SettingModal
          loadEmailMembers={loadEmailMember}
          onClose={() => {
            setOnModal(false);
          }}
        />
      )}

      <Title>유저 선택</Title>

      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <AllCheck
          title="전체 선택"
          onClick={clickAllCheck}
          active={isAllCheck}
        />
        <Settings
          onClick={() => {
            setOnModal(!onModal);
          }}
        />
      </div>

      <ManagerGrid
        size="small"
        col={Grid_COL}
        sortFunction={() => {
          return;
        }}
      >
        {member.map((item, index) => {
          return (
            <ManagerGridRowSection key={index}>
              <ManagerGridRow
                onClick={() => {
                  onClick({
                    representative_name: item.representative_name,
                    email: item.email,
                  });
                  dispatch(handleCheck(index));
                }}
                width={15}
                size="small"
              >
                <ManagerGridCheckbox checked={item.check} />
              </ManagerGridRow>
              <ManagerGridRow
                onClick={() => {
                  onClick({
                    representative_name: item.representative_name,
                    email: item.email,
                  });
                  dispatch(handleCheck(index));
                }}
                size="small"
                width={20}
              >
                {item.representative_name}
              </ManagerGridRow>
              <ManagerGridRow
                onClick={() => {
                  onClick({
                    representative_name: item.representative_name,
                    email: item.email,
                  });
                  dispatch(handleCheck(index));
                }}
                size="small"
                width={40}
              >
                {item.email}
              </ManagerGridRow>
              <ManagerGridRow
                onClick={() => {
                  onClick({
                    representative_name: item.representative_name,
                    email: item.email,
                  });
                  dispatch(handleCheck(index));
                }}
                size="small"
                width={25}
              >
                {item.company_name}
              </ManagerGridRow>
            </ManagerGridRowSection>
          );
        })}
      </ManagerGrid>
      <BoardPagenation
        offset={offset}
        pageNumbers={page_numbers}
        pageLength={page_length}
        setOffset={setOffset}
      />
    </div>
  );
}
