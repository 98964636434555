import {
  DatePicker,
  DateTimeField,
  DateTimePicker,
  LocalizationProvider,
  koKR,
} from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import 'dayjs/locale/ko';

function DatePickerComponent({ setDate }) {
  const now = new Date();

  return (
    <LocalizationProvider
      dateAdapter={AdapterDayjs}
      adapterLocale="ko"
      dateFormats={{ monthShort: 'April' }}
    >
      <DatePicker
        sx={{ width: '100%' }}
        className="datePicker"
        label="날짜 선택"
        format="YYYY년 MM월 DD일"
        defaultValue={dayjs(
          now.getFullYear() + '-' + (now.getMonth() + 1) + '-' + now.getDate(),
        )}
        onAccept={e => {
          var date = new Date(e['$d']);

          // 각 요소를 가져옵니다 (년, 월, 일).
          var year = date.getFullYear();
          var month = date.getMonth() + 1; // getMonth()는 0에서 시작하므로 1을 더해줍니다.
          var day = date.getDate();

          // 월과 일이 10보다 작으면 앞에 0을 붙여줍니다.
          month = month < 10 ? '0' + month : month;
          day = day < 10 ? '0' + day : day;

          // 'YYYY-MM-DD' 형식으로 변환
          var formattedDate = year + '-' + month + '-' + day;
          setDate(formattedDate);
        }}
      />
    </LocalizationProvider>
  );
}

function DashboardDatePickerComponent({ setDate }) {
  const now = new Date();

  return (
    <LocalizationProvider
      dateAdapter={AdapterDayjs}
      adapterLocale="ko"
      dateFormats={{ monthShort: 'April' }}
    >
      <DatePicker
        dayOfWeekFormatter={day => `${day}`}
        className="datePicker"
        sx={{ width: '100%', marginBottom: '20px' }}
        label="날짜 선택"
        format="YYYY년 MM월 DD일"
        defaultValue={dayjs(
          now.getFullYear() + '-' + (now.getMonth() + 1) + '-' + now.getDate(),
        )}
        onAccept={e => {
          var date = new Date(e['$d']);

          // 각 요소를 가져옵니다 (년, 월, 일).
          var year = date.getFullYear();
          var month = date.getMonth() + 1; // getMonth()는 0에서 시작하므로 1을 더해줍니다.
          var day = date.getDate();

          // 월과 일이 10보다 작으면 앞에 0을 붙여줍니다.
          month = month < 10 ? '0' + month : month;
          day = day < 10 ? '0' + day : day;

          // 'YYYY-MM-DD' 형식으로 변환
          var formattedDate = year + '-' + month + '-' + day;
          setDate(formattedDate);
        }}
      />
    </LocalizationProvider>
  );
}

function DashboardMonthPickerComponent({ setDate }) {
  const now = new Date();

  return (
    <LocalizationProvider
      dateAdapter={AdapterDayjs}
      adapterLocale="ko"
      dateFormats={{ monthShort: 'April' }}
    >
      <DatePicker
        className="datePicker"
        sx={{ width: '100%', marginBottom: '20px' }}
        label="날짜 선택"
        format="YYYY년 MM월"
        defaultValue={dayjs(now.getFullYear() + '-' + (now.getMonth() + 1))}
        onAccept={e => {
          var date = new Date(e['$d']);

          // 각 요소를 가져옵니다 (년, 월, 일).
          var year = date.getFullYear();
          var month = date.getMonth() + 1; // getMonth()는 0에서 시작하므로 1을 더해줍니다.

          // 월과 일이 10보다 작으면 앞에 0을 붙여줍니다.
          month = month < 10 ? '0' + month : month;

          // 'YYYY-MM-DD' 형식으로 변환
          var formattedDate = year + '-' + month;
          setDate(formattedDate);
        }}
      />
    </LocalizationProvider>
  );
}

export {
  DashboardDatePickerComponent,
  DatePickerComponent,
  DashboardMonthPickerComponent,
};
