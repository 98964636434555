import { createSlice } from '@reduxjs/toolkit';

interface TutorialContactBoard {
  name: string;
  phone_number: string;
  company: string;
  area: string;
  date: string;
}

interface TutorialState {
  list: TutorialContactBoard[];
  total_count: number;
  pending: string;
}

const initialState: TutorialState = {
  list: [],
  total_count: 0,
  pending: 'success',
};

export const tutorialContactSlice = createSlice({
  name: 'tutorialContactSlice',
  initialState: initialState,
  reducers: {
    updateLoading(state, action) {
      state.pending = 'loading';
    },

    updateSuccess(state, action) {
      state.pending = 'success';
    },

    updateFailed(state, action) {
      state.pending = 'failed';
    },

    updateTutorialContact: (state, action) => {
      state.list = action.payload.data;
      state.total_count = action.payload.total_count;
    },
  },
});

export const {
  updateLoading,
  updateSuccess,
  updateFailed,
  updateTutorialContact,
} = tutorialContactSlice.actions;

export default tutorialContactSlice.reducer;
export type { TutorialState, TutorialContactBoard };
