import { createSlice } from '@reduxjs/toolkit';
import { RowType } from '../../components/Layout/Table';

interface ContactPageState {
  total_count: number;
  data: (RowType | null)[][];
  pending: false;
  target: TableType;
  offset: number;
  limit: number;
  page_length: number;
  query: string;
  page_numbers: number[];
  search_categories: string[];
  status: 'read' | 'write' | 'modify';
  categories: string[];
}

export interface TableType {
  [key: string]: any;
}

const column = [
  {
    id: 'id',
    title: '번호',
    width: '25',
    textAlign: 'left',
    align: 'left',
    sort: true,
    search: false
  },
  {
    id: 'category',
    title: '카테고리',
    width: '25',
    textAlign: 'left',
    align: 'left',
    sort: true,
    search: true
  },
  {
    id: 'title',
    title: '제목',
    width: '25',
    textAlign: 'left',
    align: 'left',
    sort: true,
    search: true
  },
  {
    id: 'regdate',
    title: '작성 날짜',
    width: '25',
    textAlign: 'left',
    align: 'left',
    sort: true,
    search: false
  }
];

const initialState: ContactPageState = {
  offset: 0,
  limit: 10,
  total_count: 0,
  data: [[]],
  page_length: 0,
  page_numbers: [1, 2, 3, 4, 5],
  categories: [],
  search_categories: ['id'],
  pending: false,
  query: '',
  target: {
    id: 0,
    title: '',
    content: '',
    regdate: '',
    category: '',
    file: ''
  },
  status: 'read'
};

export const contactPageSlice = createSlice({
  name: 'ContactPageSlice',
  initialState: initialState,
  reducers: {
    resetCategories: (state, action) => {
      state.categories = [];
    },
    updateCategories: (state, action) => {
      const array = [...state.categories];
      array.push(action.payload);
      state.categories = array;
    },

    deleteCategories: (state, action) => {
      const array = [...state.categories];
      array.push(action.payload);
      state.categories = array;
    },
    updateContactData: (state, action) => {
      state.total_count = action.payload.total_count;
      state.data = action.payload.data;
    },
    updateOffset: (state, action) => {
      state.offset = action.payload;
    },

    updateQuery: (state, action) => {
      state.query = action.payload;
    },

    updateLimit: (state, action) => {
      state.limit = action.payload;
    },

    updatePageStatus: (state, action) => {
      state.status = action.payload;
    },

    updateTarget: (state, action) => {
      let object = {};
      action.payload.forEach((item: TableType) => {
        if (item === null) {
          return;
        }

        const result = { ...object, [item.id]: item.value };
        object = result;
      });
      state.target = object;
      state.status = 'modify';
    },

    resetTarget: (state, action) => {
      state.target = {
        id: 0,
        title: '',
        content: '',
        category: ''
      };
    },

    updateTargetTitle: (state, action) => {
      state.target.title = action.payload;
    },

    updateTargetContent: (state, action) => {
      state.target.content = action.payload;
    },

    updatePageLength(state, action) {
      state.page_length = action.payload;
    },

    updatePageNumbers(state, action) {
      state.page_numbers = action.payload;
    },

    updateTargetSelectedCategory: (state, action) => {
      state.target.category = action.payload;
    },

    updateSearchCategories: (state, action) => {
      const array = ['id'];
      array.push(action.payload);

      state.search_categories = array;
    }
  }
});

export const {
  resetTarget,
  updateTargetSelectedCategory,
  resetCategories,
  updatePageNumbers,
  updatePageLength,
  updateCategories,
  updatePageStatus,
  updateSearchCategories,
  updateQuery,
  updateLimit,
  updateContactData,
  updateTargetTitle,
  updateTargetContent,
  updateOffset,
  updateTarget
} = contactPageSlice.actions;

export default contactPageSlice.reducer;
export type { ContactPageState };
