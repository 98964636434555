import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { Grid } from '@mui/material';
import TitleCard from '../../components/Cards/TitleCard';
import { setPageTitle } from '../../app/slice/headerSlice';
import React from 'react';
import {
  FilterRow,
  FilterRowCategory,
  FilterSection,
  FilterSelect,
  SalesSection,
  SearchButton,
} from './component/components';
import Contact from './component/Contact';
import {
  loadJonghapSales,
  resetJonghapSales,
  updateJonghapCurrentPage,
} from '../../app/slice/jonghapSlice';
import {
  loadJeonmoonSales,
  resetJeonmoonSales,
  updateJeonmoonCurrentPage,
} from '../../app/slice/jeonmoonSlice';
import {
  loadJonghapJeonmoonSales,
  resetJonghapJeonmoonSales,
  updateJonghapJeonmoonCurrentPage,
} from '../../app/slice/jonghap_jeonmoonSlice';
import {
  loadPhoneSales,
  resetPhoneSales,
  updatePhoneCurrentPage,
} from '../../app/slice/phoneSalesSlice';
import { updateSelectedManager } from '../../app/slice/managerSlice';
import { getCheckList, updateShared } from '../../app/slice/checkListSlice';
import {
  getClassification,
  jonghapJeonmoonSearch,
  searchJeonmoonSales,
  searchJonghapSales,
  getRejection,
  searchPhoneSales,
} from '../../utils/sales_API';
import JonghapRow from './component/JonghapRow';
import JeonmoonRow from './component/JeonmoonRow';
import JonghapJeonmoonRow from './component/JonghapJeonmoonRow';
import ContactData from './component/ContactData';
import PhoneSalesRow from './component/PhoneSalesRow';
import { updateSort, updateTotalCount } from '../../app/slice/salesSlice';
import { CategorySection } from './component/CategorySectoin';

function JonghapJeonmoonSales() {
  const {
    jonghap_data,
    jonghap_total_count,
    selected_jonghap_key,
    selected_jonghap_title,
    jonghap_index,
  } = useSelector(state => state.jonghapSales);
  const {
    selected_jeonmoon_key,
    jeonmoon_data,
    jeonmoon_index,
    selected_jeonmoon_title,
    jeonmoon_total_count,
  } = useSelector(state => state.jeonmoonSales);
  const {
    selected_jonghap_jeonmoon_key,
    jonghap_jeonmoon_data,
    jonghap_jeonmoon_index,
    selected_jonghap_jeonmoon_title,
    jonghap_jeonmoon_total_count,
  } = useSelector(state => state.jonghapJeonmoonSales);
  const {
    selected_phoneSales_key,
    phoneSales_data,
    phoneSales_index,
    selected_phoneSales_title,
    phoneSales_total_count,
  } = useSelector(state => state.phoneSales);

  const { mode, sort, limit } = useSelector(state => state.sales);

  const { checkLists, memo, regdate, is_shared } = useSelector(
    state => state.checkList,
  );
  const user_id = localStorage.getItem('id');

  const AREA_NAME = [
    '서울',
    '부산',
    '대구',
    '인천',
    '광주',
    '대전',
    '울산',
    '세종',
    '경기',
    '강원',
    '충북',
    '충남',
    '전북',
    '전남',
    '경북',
    '경남',
    '제주',
  ];

  const SEOUL_DETAIL_NAME = [
    `종로구`,
    '중구',
    '용산구',
    '성동구',
    '광진구',
    '동대문구',
    '중랑구',
    '성북구',
    '강북구',
    '도봉구',
    '노원구',
    '은평구',
    '서대문구',
    '마포구',
    '양천구',
    '강서구',
    '구로구',
    '금천구',
    '영등포구',
    '동작구',
    '관악구',
    '서초구',
    '강남구',
    '송파구',
    '강동구',
  ];
  const BUSAN_DETAIL_NAME = [
    '중구',
    '서구',
    '동구',
    '영도구',
    '부산진구',
    '동래구',
    '남구',
    '북구',
    '해운대구',
    '사하구',
    '금정구',
    '강서구',
    '연제구',
    '수영구',
    '사상구',
    '기장군',
  ];
  const DAEGU_DETAIL_NAME = [
    '중구',
    '동구',
    '서구',
    '남구',
    '북구',
    '수성구',
    '달서구     (568) 14.79%'.substring(0, 5).trim(),
    '달성군     (490) 12.76%'.substring(0, 5).trim(),
    '군위군     (6) 0.16%'.substring(0, 5).trim(),
  ];
  const INCHEON_DETAIL_NAME = [
    '중구',
    '동구',
    '미추홀구',
    '연수구',
    '남동구 ',
    '부평구     (553) 10.36%'.substring(0, 5).trim(),
    '계양구     (470) 8.81%'.substring(0, 5).trim(),
    '서구     (1036) 19.41%'.substring(0, 5).trim(),
    '강화군     (211) 3.95%'.substring(0, 5).trim(),
    '옹진군     (76) 1.42%'.substring(0, 5).trim(),
  ];

  const GWANGJU_DETAIL_NAME = [
    '동구',
    '서구',
    '남구',
    '북구',
    '광산구     (717) 19.99%'.substring(0, 5).trim(),
  ];

  const DAEJEON_DETAIL_NAME = ['동구', '중구', '서구', '유성구', '대덕구'];

  const ULSAN_DETAIL_NAME = ['중구', '남구', '동구', '북구', '울주군'];
  const GYEONGI_DETAIL_NAME = [
    '수원시',
    '고양시',
    '용인시',
    '성남시',
    '부천시',
    '화성시 ',
    '안산시',
    '남양주시',
    '안양시',
    '평택시',
    '시흥시',
    '파주시',
    '의정부시',
    '김포시',
    '광주시',
    '광명시',
    '군포시',
    '하남시',
    '오산시',
    '양주시',
    '이천시',
    '구리시',
    '안성시',
    '포천시',
    '의왕시',
    '양평군',
    '여주시',
    '동두천시     (152) 0.73%'.substring(0, 5).trim(),
    '가평군     (112) 0.54%'.substring(0, 5).trim(),
    '과천시     (117) 0.56%'.substring(0, 5).trim(),
    '연천군     (127) 0.61%'.substring(0, 5).trim(),
  ];

  const GANGWON_DETAIL_NAME = [
    '춘천시',
    '원주시',
    '강릉시',
    '동해시',
    '태백시',
    '속초시',
    '삼척시     (226) 5.84%'.substring(0, 5).trim(),
    '홍천군     (169) 4.46%'.substring(0, 5).trim(),
    '횡성군     (147) 3.79%'.substring(0, 5).trim(),
    '영월군     (175) 4.54%'.substring(0, 5).trim(),
    '평창군     (161) 4.27%'.substring(0, 5).trim(),
    '정선군     (164) 4.30%'.substring(0, 5).trim(),
    '철원군     (128) 3.31%'.substring(0, 5).trim(),
    '화천군     (90) 2.40%'.substring(0, 5).trim(),
    '양구군     (93) 2.40%'.substring(0, 5).trim(),
    '인제군     (124) 3.20%'.substring(0, 5).trim(),
    '고성군     (116) 3.10%'.substring(0, 5).trim(),
    '양양군     (101) 2.56%'.substring(0, 5).trim(),
  ];

  const CHUNGCHEONGBOOK_DETAIL_NAME = [
    '청주시     (1545) 45.42%'.substring(0, 5).trim(),
    '충주시     (423) 12.42%'.substring(0, 5).trim(),
    '제천시     (319) 9.37%'.substring(0, 5).trim(),
    '보은군     (95) 2.79%'.substring(0, 5).trim(),
    '옥천군     (119) 3.49%'.substring(0, 5).trim(),
    '영동군     (132) 3.88%'.substring(0, 5).trim(),
    '증평군     (47) 1.38%'.substring(0, 5).trim(),
    '진천군     (169) 4.96%'.substring(0, 5).trim(),
    '괴산군     (133) 3.90%'.substring(0, 5).trim(),
    '음성군     (313) 9.19%'.substring(0, 5).trim(),
    '단양군     (109) 3.20%'.substring(0, 5).trim(),
  ];

  const CHUNGCHEONGNAM_DETAIL_NAME = [
    '천안시     (1199) 25.27%'.substring(0, 5).trim(),
    '공주시     (362) 7.62%'.substring(0, 5).trim(),
    '보령시     (341) 7.18%'.substring(0, 5).trim(),
    '아산시     (512) 10.80%'.substring(0, 5).trim(),
    '서산시     (371) 7.81%'.substring(0, 5).trim(),
    '논산시     (306) 6.44%'.substring(0, 5).trim(),
    '계룡시     (84) 1.77%'.substring(0, 5).trim(),
    '당진시     (273) 5.75%'.substring(0, 5).trim(),
    '금산군     (221) 4.65%'.substring(0, 5).trim(),
    '부여군     (199) 4.19%'.substring(0, 5).trim(),
    '서천군     (125) 2.63%'.substring(0, 5).trim(),
    '청양군     (109) 2.30%'.substring(0, 5).trim(),
    '홍성군     (234) 4.93%'.substring(0, 5).trim(),
    '예산군     (229) 4.82%'.substring(0, 5).trim(),
    '태안군     (180) 3.81%'.substring(0, 5).trim(),
  ];

  const JEOLLABOOK_DETAIL_NAME = [
    '전주시     (1424) 32.40%'.substring(0, 5).trim(),
    '군산시     (550) 12.54%'.substring(0, 5).trim(),
    '익산시     (512) 11.65%'.substring(0, 5).trim(),
    '정읍시     (251) 5.71%'.substring(0, 5).trim(),
    '남원시     (284) 6.46%'.substring(0, 5).trim(),
    '김제시     (250) 5.69%'.substring(0, 5).trim(),
    '완주군     (272) 6.19%'.substring(0, 5).trim(),
    '진안군     (118) 2.68%'.substring(0, 5).trim(),
    '무주군     (115) 2.62%'.substring(0, 5).trim(),
    '장수군     (93) 2.12%'.substring(0, 5).trim(),
    '임실군     (125) 2.80%'.substring(0, 5).trim(),
    '순창군     (130) 2.96%'.substring(0, 5).trim(),
    '고창군     (167) 3.80%'.substring(0, 5).trim(),
    '부안군     (105) 2.39%'.substring(0, 5).trim(),
  ];

  const JEOLLANAM_DETAIL_NAME = [
    '목포시     (475) 8.50%'.substring(0, 5).trim(),
    '여수시     (746) 13.37%'.substring(0, 5).trim(),
    '순천시     (721) 12.88%'.substring(0, 5).trim(),
    '나주시     (370) 6.62%'.substring(0, 5).trim(),
    '광양시     (441) 7.89%'.substring(0, 5).trim(),
    '담양군     (228) 4.08%'.substring(0, 5).trim(),
    '곡성군     (122) 2.18%'.substring(0, 5).trim(),
    '구례군     (81) 1.45%'.substring(0, 5).trim(),
    '고흥군     (185) 3.31%'.substring(0, 5).trim(),
    '보성군     (150) 2.67%'.substring(0, 5).trim(),
    '화순군     (291) 5.21%'.substring(0, 5).trim(),
    '장흥군     (120) 2.15%'.substring(0, 5).trim(),
    '강진군     (122) 2.18%'.substring(0, 5).trim(),
    '해남군     (256) 4.56%'.substring(0, 5).trim(),
    '영암군     (234) 4.19%'.substring(0, 5).trim(),
    '무안군     (237) 4.24%'.substring(0, 5).trim(),
    '함평군     (131) 2.34%'.substring(0, 5).trim(),
    '영광군     (160) 2.86%'.substring(0, 5).trim(),
    '장성군     (233) 4.17%'.substring(0, 5).trim(),
    '완도군     (112) 2.00%'.substring(0, 5).trim(),
    '진도군     (57) 1.02%'.substring(0, 5).trim(),
    '신안군     (119) 2.13%'.substring(0, 5).trim(),
  ];

  const GYEONGSANGBOOK_DETAIL_NAME = [
    '포항시',
    '경주시',
    '김천시',
    '안동시',
    '구미시',
    '영주시     (292) 4.47%'.substring(0, 5).trim().substring(0, 5).trim(),
    '영천시     (304) 4.65%'.substring(0, 5).trim().substring(0, 5).trim(),
    '상주시     (282) 4.30%'.substring(0, 5).trim(),
    '문경시     (206) 3.15%'.substring(0, 5).trim(),
    '경산시     (484) 7.41%'.substring(0, 5).trim(),
    '의성군     (184) 2.82%'.substring(0, 5).trim(),
    '청송군     (128) 1.96%'.substring(0, 5).trim(),
    '영양군     (101) 1.55%'.substring(0, 5).trim(),
    '영덕군     (114) 1.75%'.substring(0, 5).trim(),
    '청도군     (113) 1.73%'.substring(0, 5).trim(),
    '고령군     (112) 1.71%'.substring(0, 5).trim(),
    '성주군     (214) 3.28%'.substring(0, 5).trim(),
    '칠곡군     (299) 4.58%'.substring(0, 5).trim(),
    '예천군     (175) 2.66%'.substring(0, 5).trim(),
    '봉화군     (130) 1.99%'.substring(0, 5).trim(),
    '울진군     (179) 2.74%'.substring(0, 5).trim(),
    '울릉군     (28) 0.41%'.substring(0, 5).trim(),
  ];

  const GYEONGSANGNAM_DETAIL_NAME = [
    '창원시     (1399) 22.49%'.substring(0, 5).trim().substring(0, 5).trim(),
    '진주시     (829) 13.32%'.substring(0, 5).trim(),
    '통영시     (206) 3.31%'.substring(0, 5).trim(),
    '사천시     (273) 4.39%'.substring(0, 5).trim(),
    '김해시     (973) 15.63%'.substring(0, 5).trim(),
    '밀양시     (295) 4.74%'.substring(0, 5).trim(),
    '거제시     (281) 4.51%'.substring(0, 5).trim(),
    '양산시     (465) 7.47%'.substring(0, 5).trim(),
    '의령군     (125) 2.01%'.substring(0, 5).trim(),
    '함안군',
    '창녕군',
    '고성군     (154) 2.47%'.substring(0, 5).trim(),
    '남해군     (104) 1.67%'.substring(0, 5).trim(),
    '하동군     (122) 1.96%'.substring(0, 5).trim(),
    '산청군     (119) 1.91%'.substring(0, 5).trim(),
    '함양군     (165) 2.65%'.substring(0, 5).trim(),
    '거창군     (191) 3.07%'.substring(0, 5).trim(),
    '합천군     (166) 2.65%'.substring(0, 5).trim(),
  ];

  const JEJU_DETAIL_NAME = ['제주시', '서귀포시'];

  const [trans, setTrans] = useState([]);
  const [filterAreaName, setFilterAreaName] = useState('');
  const [defailData, setDetailData] = useState([]);
  const dispatch = useDispatch();
  const [contactAdminData, setContactAdminData] = useState([]);

  const [salesManagerArea, setSalesManagerArea] = useState();

  const [filters, setFilters] = useState({
    area: '',
    address: '',
    ncrgskname: '',
    ncrgsmaster: '',
    /**
     * 공고번호
     */
    license: '',
    /** 업종 등록번호 */
    ncrmasternum: '',
    ncrofftel: '',
    email: '',
    phoneNumber: '',
  });

  useEffect(() => {
    init();
  }, [mode, sort]);

  useEffect(() => {
    dispatch(updateSort(null));
  }, [mode]);

  useEffect(() => {
    switch (filterAreaName.substring(0, 2)) {
      case '서울':
        setDetailData(SEOUL_DETAIL_NAME);
        break;

      case '부산':
        setDetailData(BUSAN_DETAIL_NAME);
        break;

      case '대구':
        setDetailData(DAEGU_DETAIL_NAME);
        break;

      case '인천':
        setDetailData(INCHEON_DETAIL_NAME);
        break;

      case '광주':
        setDetailData(GWANGJU_DETAIL_NAME);
        break;

      case '대전':
        setDetailData(DAEJEON_DETAIL_NAME);
        break;

      case '울산':
        setDetailData(ULSAN_DETAIL_NAME);
        break;

      case '세종':
        setDetailData(['세종']);
        break;

      case '경기':
        setDetailData(GYEONGI_DETAIL_NAME);
        break;

      case '강원':
        setDetailData(GANGWON_DETAIL_NAME);
        break;

      case '충북':
        setDetailData(CHUNGCHEONGBOOK_DETAIL_NAME);
        break;

      case '충남':
        setDetailData(CHUNGCHEONGNAM_DETAIL_NAME);
        break;

      case '전북':
        setDetailData(JEOLLABOOK_DETAIL_NAME);
        break;

      case '전남':
        setDetailData(JEOLLANAM_DETAIL_NAME);
        break;

      case '경남':
        setDetailData(GYEONGSANGNAM_DETAIL_NAME);
        break;

      case '경북':
        setDetailData(GYEONGSANGBOOK_DETAIL_NAME);
        break;

      case '제주':
        setDetailData(JEJU_DETAIL_NAME);
        break;

      default:
        break;
    }
  }, [filterAreaName]);

  useEffect(() => {
    init();
  }, []);

  async function init() {
    if (mode !== 'jonghap') {
      dispatch(resetJonghapSales());
    }

    if (mode !== 'jeonmoon') {
      dispatch(resetJeonmoonSales());
    }

    if (mode !== 'jonghap_jeonmoon') {
      dispatch(resetJonghapJeonmoonSales());
    }

    if (mode !== 'phone') {
      dispatch(resetPhoneSales());
    }

    const jonghap_page = localStorage.getItem('jonghap_page');
    const jeonmoon_page = localStorage.getItem('jeonmoon_page');
    const jonghap_jeonmoon_page = localStorage.getItem('jonghap_jeonmoon_page');
    const phone_page = localStorage.getItem('phone_page');

    const jonghap_result = await searchJonghapSales(
      Number(jonghap_page ?? 1),
      filters,
      sort,
      limit,
    );
    const jeonmoon_result = await searchJeonmoonSales(
      Number(jeonmoon_page ?? 1),
      filters,
      sort,
      limit,
    );
    const jonghap_jeonmoon_result = await jonghapJeonmoonSearch(
      Number(jonghap_jeonmoon_page ?? 1),
      filters,
      sort,
      limit,
    );
    const phone_result = await searchPhoneSales(
      Number(phone_page ?? 1),
      filters,
      sort,
      limit,
    );

    const rejection_result = await getRejection();

    rejection_result.forEach(item => {
      let selectedIndex = 0;
      let is_rejected = false;

      jonghap_result.data.data.forEach((element, index) => {
        if (element.unique_key === item.ncrgsseq) {
          selectedIndex = index;
          is_rejected = true;
        }
      });

      if (is_rejected) {
        jonghap_result.data.data[selectedIndex]['rejection'] = true;
        jonghap_result.data.data[selectedIndex]['rejection_item'] = item;
      }

      selectedIndex = 0;
      is_rejected = false;

      jeonmoon_result.data.data.forEach((element, index) => {
        if (element.unique_key === item.ncrgsseq) {
          selectedIndex = index;
          is_rejected = true;
        }
      });

      if (is_rejected) {
        jeonmoon_result.data.data[selectedIndex]['rejection'] = true;
        jeonmoon_result.data.data[selectedIndex]['rejection_item'] = item;
      }

      selectedIndex = 0;
      is_rejected = false;

      jonghap_jeonmoon_result.data.data.forEach((element, index) => {
        if (element.unique_key === item.ncrgsseq) {
          selectedIndex = index;
          is_rejected = true;
        }
      });

      if (is_rejected) {
        jonghap_jeonmoon_result.data.data[selectedIndex]['rejection'] = true;
        jonghap_jeonmoon_result.data.data[selectedIndex]['rejection_item'] =
          item;
      }

      phone_result.data.data.forEach((element, index) => {
        if (element.unique_key === item.ncrgsseq) {
          selectedIndex = index;
          is_rejected = true;
        }
      });

      if (is_rejected) {
        phone_result.data.data[selectedIndex]['rejection'] = true;
        phone_result.data.data[selectedIndex]['rejection_item'] = item;
      }
    });

    const classification = await getClassification(user_id);

    const jonghap_array = [...jonghap_result.data.data];
    const jeonmoon_array = [...jeonmoon_result.data.data];
    const jonghap_jeonmoon_array = [...jonghap_jeonmoon_result.data.data];
    const phoneSales_array = [...phone_result.data.data];

    jonghap_array.forEach(item => {
      classification.forEach(element => {
        if (item.unique_key === element.ncrgsseq) {
          item.classification = element.classification;
        }
      });
    });

    jeonmoon_array.forEach(item => {
      classification.forEach(element => {
        if (item.unique_key === element.ncrgsseq) {
          item.classification = element.classification;
        }
      });
    });

    jonghap_jeonmoon_array.forEach(item => {
      classification.forEach(element => {
        if (item.unique_key === element.ncrgsseq) {
          item.classification = element.classification;
        }
      });
    });

    phoneSales_array.forEach(item => {
      classification.forEach(element => {
        if (item.unique_key === element.ncrgsseq) {
          item.classification = element.classification;
        }
      });
    });

    if (jonghap_result.status === true) {
      dispatch(
        loadJonghapSales({
          total_count: jonghap_result.data.total_count,
          data: jonghap_array,
        }),
      );

      dispatch(updateTotalCount(jonghap_result.data.total_count));
      setDetailOn({ key: detailOn.key, on: false });
    }

    if (jeonmoon_result.status === true) {
      dispatch(
        loadJeonmoonSales({
          total_count: jeonmoon_result.data.total_count,
          data: jeonmoon_array,
        }),
      );
      dispatch(updateTotalCount(jeonmoon_result.data.total_count));
      setDetailOn({ key: detailOn.key, on: false });
    }

    if (jonghap_jeonmoon_result.status === true) {
      dispatch(
        loadJonghapJeonmoonSales({
          total_count: jonghap_jeonmoon_result.data.total_count,
          data: jonghap_jeonmoon_array,
        }),
      );
      dispatch(updateTotalCount(jonghap_jeonmoon_result.data.total_count));
      setDetailOn({ key: detailOn.key, on: false });
    }

    if (phone_result.status === true) {
      dispatch(
        loadPhoneSales({
          total_count: phone_result.data.total_count,
          data: phoneSales_array,
        }),
      );
      dispatch(updateTotalCount(phone_result.data.total_count));
      setDetailOn({ key: detailOn.key, on: false });
    }

    loadSalesAdmin();
    dispatch(setPageTitle({ title: '영업' }));
  }

  useEffect(() => {
    loadSalesManagerByArea();
  }, [defailData]);

  async function loadSalesManagerByArea() {
    const array = [];

    defailData.forEach(item => {
      array.push(item.substring(0, 5).trim());
    });

    const option = {
      url: 'https://cpi.contax.ai/get_sales_managers',
      method: 'POST',
      data: {
        area: filterAreaName.substring(0, 2),
        areaDetails: array,
      },

      header: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    };

    try {
      const response = await axios(option);
      if (response.status === 200) {
        setSalesManagerArea(response.data);
      } else {
        console.log('Non-200 response received: ', response.status);
        return null;
      }
    } catch (error) {
      console.error('Error loading checklist:', error);
    }
  }

  async function handlePage(event, target) {
    if (target === 0) {
      dispatch(updateJonghapCurrentPage(''));
      dispatch(updateJeonmoonCurrentPage(''));
      dispatch(updateJonghapJeonmoonCurrentPage(''));
      dispatch(updatePhoneCurrentPage(''));
      return;
    }

    const classification = await getClassification(user_id);

    if (mode === 'jonghap') {
      dispatch(updateJonghapCurrentPage(target));
      const array = await searchJonghapSales(target, filters, sort, limit);

      const rejection_result = await getRejection();

      rejection_result.forEach(item => {
        let selectedIndex = 0;
        let is_rejected = false;

        array.data.data.forEach((element, index) => {
          if (element.unique_key === item.ncrgsseq) {
            selectedIndex = index;
            is_rejected = true;
          }
        });

        if (is_rejected) {
          array.data.data[selectedIndex]['rejection'] = true;
          array.data.data[selectedIndex]['rejection_item'] = item;
        }
      });

      array.data.data.forEach(item => {
        classification.forEach(element => {
          if (item.unique_key === element.ncrgsseq) {
            item.classification = element.classification;
          }
        });
      });

      dispatch(loadJonghapSales(array.data));
    }

    if (mode === 'jeonmoon') {
      dispatch(updateJeonmoonCurrentPage(target));
      const array = await searchJeonmoonSales(target, filters, sort, limit);

      const rejection_result = await getRejection();

      rejection_result.forEach(item => {
        let selectedIndex = 0;
        let is_rejected = false;

        array.data.data.forEach((element, index) => {
          if (element.unique_key === item.ncrgsseq) {
            selectedIndex = index;
            is_rejected = true;
          }
        });

        if (is_rejected) {
          array.data.data[selectedIndex]['rejection'] = true;
          array.data.data[selectedIndex]['rejection_item'] = item;
        }
      });

      array.data.data.forEach(item => {
        classification.forEach(element => {
          if (item.unique_key === element.ncrgsseq) {
            item.classification = element.classification;
          }
        });
      });

      dispatch(loadJeonmoonSales(array.data));
    }

    if (mode === 'jonghap_jeonmoon') {
      dispatch(updateJonghapJeonmoonCurrentPage(target));
      const array = await jonghapJeonmoonSearch(target, filters, sort, limit);

      const rejection_result = await getRejection();

      rejection_result.forEach(item => {
        let selectedIndex = 0;
        let is_rejected = false;

        array.data.data.forEach((element, index) => {
          if (element.unique_key === item.ncrgsseq) {
            selectedIndex = index;
            is_rejected = true;
          }
        });

        if (is_rejected) {
          array.data.data[selectedIndex]['rejection'] = true;
          array.data.data[selectedIndex]['rejection_item'] = item;
        }
      });

      array.data.data.forEach(item => {
        classification.forEach(element => {
          if (item.unique_key === element.ncrgsseq) {
            item.classification = element.classification;
          }
        });
      });

      dispatch(loadJonghapJeonmoonSales(array.data));
    }

    if (mode === 'phone') {
      dispatch(updatePhoneCurrentPage(target));
      const array = await searchPhoneSales(target, filters, sort, limit);

      const rejection_result = await getRejection();

      rejection_result.forEach(item => {
        let selectedIndex = 0;
        let is_rejected = false;

        array.data.data.forEach((element, index) => {
          if (element.unique_key === item.ncrgsseq) {
            selectedIndex = index;
            is_rejected = true;
          }
        });

        if (is_rejected) {
          array.data.data[selectedIndex]['rejection'] = true;
          array.data.data[selectedIndex]['rejection_item'] = item;
        }
      });

      array.data.data.forEach(item => {
        classification.forEach(element => {
          if (item.unique_key === element.ncrgsseq) {
            item.classification = element.classification;
          }
        });
      });

      dispatch(loadPhoneSales(array.data));
    }
  }

  function convertObjectToArray(obj) {
    return Object.entries(obj).map(([key, value]) => {
      // key를 '_'를 기준으로 분리하여 name과 type을 추출
      const [name, type] = key.split('_');

      if (
        typeof value === 'object' &&
        value !== null &&
        !Array.isArray(value)
      ) {
        // 값이 객체이면 재귀적으로 처리
        return { name, type, value: convertObjectToArray(value) };
      } else {
        // 그렇지 않으면, 기본 변환 수행
        return { name, type, value };
      }
    });
  }

  async function loadDetailMemo(targetMasterNumber) {
    const params = {
      targetMasterNumber: targetMasterNumber,
    };

    const option = {
      url: 'https://cpi.contax.ai/get_detail_memo',
      method: 'GET',
      params: params,
      header: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    };

    try {
      const response = await axios(option);
      if (response.status === 200) {
        if (response.data.id !== undefined) {
          const array = convertObjectToArray(JSON.parse(response.data.items));
          dispatch(
            getCheckList({
              checkLists: array,
              memo: response.data.memo,
              regdate: response.data.regdate.substring(0, 10),
              is_shared: true,
              re_call_date:
                response.data.re_call_date === null
                  ? ''
                  : response.data.re_call_date,
              re_call_true: response.data.re_call_true === null ? false : true,
            }),
          );

          dispatch(
            updateSelectedManager({
              id: response.data.writter,
              value: response.data.writter_name,
            }),
          );
        }
      }
    } catch (error) {
      dispatch(
        getCheckList({
          checkLists: [
            {
              name: '대표자 미팅 방문 여부',
              value: false,
              type: 'check',
            },
            {
              name: '직원 미팅 방문 여부',
              value: false,
              type: 'check',
            },
            {
              name: '콘택스 소개',
              value: [
                { name: '경영', value: false, type: 'check' },
                { name: '입찰', value: false, type: 'check' },
              ],
              type: 'check',
            },
            {
              name: '중처법 브리핑 안내 여부',
              value: false,
              type: 'check',
            },
            {
              name: '법인, 재무 기타 언급',
              value: false,
              type: 'check',
            },
          ],
          memo: '',
          regdate: '-',
          is_shared: false,
          re_call_date: '',
          re_call_true: false,
        }),
      );

      dispatch(updateSelectedManager({ id: 0, value: '' }));
    }
  }

  function convertArrayToObject(arr) {
    const result = {};

    arr.forEach(item => {
      // 항목의 값이 배열인 경우 재귀적으로 처리합니다.
      if (Array.isArray(item.value)) {
        result[item.name] = convertArrayToObject(item.value);
      } else {
        // 값이 배열이 아니며 type에 따라 키를 조정합니다.
        let keySuffix = '';
        if (item.type === 'check') keySuffix = '_check';
        if (item.type === 'time') keySuffix = '_time';
        if (item.type === 'input') keySuffix = '_input';

        result[item.name + keySuffix] = item.value;
      }
    });

    return result;
  }

  async function shareJonghapData() {
    if (is_shared === false) {
      const array = [];
      array.push(selected_jonghap_key);

      const data = {
        item: JSON.stringify(convertArrayToObject(checkLists)),
        writter: user_id,
        targetMasterNumber: selected_jonghap_key,
        memo: memo,
        call_memo: jonghap_data[jonghap_index].memo,
        title: selected_jonghap_title,
        writter_name: '최고 관리자',
      };

      const option = {
        url: 'https://cpi.contax.ai/sales/insert_sales_memo',
        method: 'POST',
        data: data,
        header: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      };

      try {
        const response = await axios(option);
        if (response.status === 200) {
          const array = [...jonghap_data];
          array[jonghap_index] = {
            ...array[jonghap_index],
            is_saved: true,
          };

          alert('저장이 완료되었습니다.');

          dispatch(
            loadJonghapSales({
              data: array,
              total_count: jonghap_total_count,
            }),
          );
          dispatch(updateShared(true));
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      const array = [];
      array.push(selected_jonghap_key);

      const data = {
        item: JSON.stringify(convertArrayToObject(checkLists)),
        writter: user_id,
        targetMasterNumber: selected_jonghap_key,
        memo: memo,
        call_memo: jonghap_data[jonghap_index].memo,
        writter_name: '최고 관리자',
      };

      const option = {
        url: 'https://cpi.contax.ai/update_sales_check_list_item',
        method: 'PUT',
        params: data,
        header: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      };

      try {
        const response = await axios(option);
        if (response.status === 200) {
          alert('수정이 완료되었습니다.');
        }
      } catch (error) {
        console.log(error);
      }
    }
  }

  async function shareJonghapJeonmoonData() {
    if (is_shared === false) {
      const array = [];
      array.push(selected_jonghap_jeonmoon_key);

      const data = {
        item: JSON.stringify(convertArrayToObject(checkLists)),
        writter: user_id,
        targetMasterNumber: selected_jonghap_jeonmoon_key,
        memo: memo,
        call_memo: jonghap_jeonmoon_data[jonghap_jeonmoon_index].memo,
        title: selected_jonghap_jeonmoon_title,
        writter_name: '최고 관리자',
      };

      const option = {
        url: 'https://cpi.contax.ai/sales/insert_sales_memo',
        method: 'POST',
        data: data,
        header: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      };

      try {
        const response = await axios(option);
        if (response.status === 200) {
          const array = [...jonghap_jeonmoon_data];
          array[jonghap_jeonmoon_index] = {
            ...array[jonghap_jeonmoon_index],
            is_saved: true,
          };

          alert('저장이 완료되었습니다.');

          dispatch(
            loadJonghapJeonmoonSales({
              data: array,
              total_count: jonghap_jeonmoon_total_count,
            }),
          );
          dispatch(updateShared(true));
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      const array = [];
      array.push(selected_jonghap_jeonmoon_key);

      const data = {
        item: JSON.stringify(convertArrayToObject(checkLists)),
        writter: user_id,
        targetMasterNumber: selected_jonghap_jeonmoon_key,
        memo: memo,
        call_memo: jonghap_jeonmoon_data[jonghap_jeonmoon_index].memo,
        writter_name: '최고 관리자',
      };

      const option = {
        url: 'https://cpi.contax.ai/update_sales_check_list_item',
        method: 'PUT',
        params: data,
        header: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      };

      try {
        const response = await axios(option);
        if (response.status === 200) {
          alert('수정이 완료되었습니다.');
        }
      } catch (error) {
        console.log(error);
      }
    }
  }

  async function sharePhoneSales() {
    if (is_shared === false) {
      const array = [];
      array.push(selected_phoneSales_key);

      const data = {
        item: JSON.stringify(convertArrayToObject(checkLists)),
        writter: user_id,
        targetMasterNumber: selected_phoneSales_key,
        memo: memo,
        call_memo: phoneSales_data[phoneSales_index].memo,
        title: selected_phoneSales_title,
        writter_name: '최고 관리자',
      };

      const option = {
        url: 'https://cpi.contax.ai/sales/insert_sales_memo',
        method: 'POST',
        data: data,
        header: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      };

      try {
        const response = await axios(option);
        if (response.status === 200) {
          const array = [...phoneSales_data];
          array[phoneSales_index] = {
            ...array[phoneSales_index],
            is_saved: true,
          };

          alert('저장이 완료되었습니다.');

          dispatch(
            loadPhoneSales({
              data: array,
              total_count: phoneSales_total_count,
            }),
          );

          dispatch(updateShared(true));
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      const array = [];
      array.push(selected_jonghap_jeonmoon_key);

      const data = {
        item: JSON.stringify(convertArrayToObject(checkLists)),
        writter: user_id,
        targetMasterNumber: selected_jonghap_jeonmoon_key,
        memo: memo,
        call_memo: jonghap_jeonmoon_data[jonghap_jeonmoon_index].memo,
        writter_name: '최고 관리자',
      };

      const option = {
        url: 'https://cpi.contax.ai/update_sales_check_list_item',
        method: 'PUT',
        params: data,
        header: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      };

      try {
        const response = await axios(option);
        if (response.status === 200) {
          alert('수정이 완료되었습니다.');
        }
      } catch (error) {
        console.log(error);
      }
    }
  }

  async function shareJeonmoonData() {
    if (is_shared === false) {
      const array = [];
      array.push(selected_jeonmoon_key);

      const data = {
        item: JSON.stringify(convertArrayToObject(checkLists)),
        writter: user_id,
        targetMasterNumber: selected_jeonmoon_key,
        memo: memo,
        call_memo: jeonmoon_data[jeonmoon_index].memo,
        title: selected_jeonmoon_title,
        writter_name: '최고 관리자',
      };

      const option = {
        url: 'https://cpi.contax.ai/sales/insert_sales_memo',
        method: 'POST',
        data: data,
        header: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      };

      try {
        const response = await axios(option);
        if (response.status === 200) {
          const array = [...jeonmoon_data];
          array[jeonmoon_index] = {
            ...array[jeonmoon_index],
            is_saved: true,
          };

          alert('저장이 완료되었습니다.');

          dispatch(
            loadJeonmoonSales({
              data: array,
              total_count: jeonmoon_total_count,
            }),
          );
          dispatch(updateShared(true));
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      const array = [];
      array.push(selected_jeonmoon_key);

      const data = {
        item: JSON.stringify(convertArrayToObject(checkLists)),
        writter: user_id,
        targetMasterNumber: selected_jeonmoon_key,
        memo: memo,
        call_memo: jeonmoon_data[jeonmoon_index].memo,
        writter_name: '최고 관리자',
      };

      const option = {
        url: 'https://cpi.contax.ai/update_sales_check_list_item',
        method: 'PUT',
        params: data,
        header: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      };

      try {
        const response = await axios(option);
        if (response.status === 200) {
          alert('수정이 완료되었습니다.');
        }
      } catch (error) {
        console.log(error);
      }
    }
  }

  async function loadSalesAdmin() {
    let data = [];
    let userData = [];
    let accessData = [];
    let salesAdminData = [];
    let secondAdminData = [];
    let contactAdminData = [];

    const conbiOption = {
      url: 'https://cpi.contax.ai/members/all_members',
      method: 'GET',
      header: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    };

    const accessOption = {
      url: 'https://cpi.contax.ai/access',
      method: 'GET',
      header: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    };

    await axios(conbiOption).then(response => {
      if (response.status === 200) {
        data = response.data;
      }
    });

    await axios(accessOption).then(response => {
      if (response.status === 200) {
        accessData = response.data;
      }
    });

    data.forEach((item, index) => {
      let isAdmin = false;
      accessData.forEach(jtem => {
        if (item.user_id === jtem.user_id) {
          if (jtem.manager_division === 'sales') {
            const newObj = {
              ...item,
              is_admin: 'O',
              access: [],
              assignment: [],
              sales_member: jtem.sales_member,
              manager_division: jtem.manager_division,
            };
            salesAdminData.push(newObj);
          }

          if (jtem.manager_division === 'sales_second') {
            const newObj = {
              ...item,
              is_admin: 'O',
              access: [],
              assignment: [],
              sales_member: jtem.sales_member,
              manager_division: jtem.manager_division,
            };
            secondAdminData.push(newObj);
          }

          if (jtem.manager_division === 'contact_sales') {
            const newObj = {
              ...item,
              is_admin: 'O',
              access: [],
              assignment: [],
              sales_member: jtem.sales_member,
              manager_division: jtem.manager_division,
            };
            contactAdminData.push(newObj);
          }

          isAdmin = true;
        }
      });

      if (isAdmin === false) {
        const object = {
          ...item,
          subscription_type: '미가입',
          subscription_end: '-',
        };
        userData.push(object);
      }
    });

    const salesAdminDataId = [];

    salesAdminData.forEach(item => {
      salesAdminDataId.push(item.name);
    });

    secondAdminData.forEach(item => {
      salesAdminDataId.push(item.name);
    });

    contactAdminData.forEach(item => {
      salesAdminDataId.push(item.name);
    });

    const option = {
      url: 'https://cpi.contax.ai/load_area_name_by_manager_name',
      method: 'POST',
      data: {
        name: salesAdminDataId,
      },

      header: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    };

    await axios(option).then(response => {
      if (response.status === 200) {
      }
    });

    setContactAdminData(contactAdminData);
  }

  function changeExtraInfo(e, target, index) {
    const array = [...trans];
    array[index][target] = e.target.value;
    setTrans(array);
  }

  const [detailOn, setDetailOn] = useState({ key: '', on: false });

  async function jeonmoonSearch(page, limit) {
    if (mode !== 'jeonmoon') {
      dispatch(resetJeonmoonSales());
    }

    const option = {
      url: 'https://cpi.contax.ai/sales/jeonmoon_search',
      method: 'POST',
      params: {
        offset: limit * (page - 1),
        limit: limit,
        abs_sum_value: sort,
      },
      data: filters,
      header: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    };

    await axios(option).then(response => {
      if (response.status === 200) {
        dispatch(loadJeonmoonSales(response.data));
      }
    });
  }

  return (
    <Grid container spacing={2} direction="column" justifyContent="center">
      <Grid item>
        {mode === 'phone' && (
          <TitleCard title="휴대폰DB">
            <CategorySection />

            <SalesSection>
              <FilterSection>
                <FilterRowCategory title="기본 검색" menuOn={true}>
                  <FilterRow
                    title="상호명"
                    filter={filters.ncrgskname}
                    setFilters={params => {
                      setFilters({
                        ...filters,
                        ncrgskname: params.target.value,
                      });
                    }}
                  />
                  <FilterRow
                    title="대표자명"
                    filters={filters.ncrgsmaster}
                    setFilters={params => {
                      setFilters({
                        ...filters,
                        ncrgsmaster: params.target.value,
                      });
                    }}
                  />
                  <FilterRow
                    title="전화번호"
                    filters={filters.ncrofftel}
                    setFilters={params => {
                      setFilters({
                        ...filters,
                        ncrofftel: params.target.value,
                      });
                    }}
                  />
                  <FilterRow
                    title="면허"
                    filters={filters.license}
                    setFilters={params => {
                      setFilters({
                        ...filters,
                        license: params.target.value,
                      });
                    }}
                  />
                  <FilterSelect
                    title="등록시도"
                    data={AREA_NAME}
                    filter={filterAreaName}
                    setFilter={params => {
                      setFilterAreaName(params);
                      setFilters({
                        ...filters,
                        area: params.substring(0, 2),
                      });
                    }}
                  />
                  <FilterSelect
                    title="등록시군구"
                    salesManagerArea={salesManagerArea}
                    data={defailData}
                    filter={filters.address.substring(0, 5).trim()}
                    setFilter={params => {
                      setFilters({
                        ...filters,
                        address: params.substring(0, 5).trim(),
                      });
                    }}
                  />
                  <SearchButton
                    onClick={async () => {
                      const result = await searchPhoneSales(
                        1,
                        filters,
                        sort,
                        limit,
                      );
                      dispatch(loadPhoneSales(result.data));
                    }}
                  />
                </FilterRowCategory>
              </FilterSection>
              {mode === 'phone' && (
                <PhoneSalesRow
                  detailOn={detailOn}
                  setDetailOn={setDetailOn}
                  changeExtraInfo={changeExtraInfo}
                  loadDetailMemo={loadDetailMemo}
                  regdate={regdate}
                  is_shared={is_shared}
                  shareData={sharePhoneSales}
                  handlePage={handlePage}
                />
              )}
            </SalesSection>
          </TitleCard>
        )}
        {mode === 'jonghap' && (
          <TitleCard title="영업">
            <CategorySection />
            <SalesSection>
              <FilterSection>
                <FilterRowCategory title="기본 검색" menuOn={true}>
                  <FilterRow
                    title="상호명"
                    filter={filters.ncrgskname}
                    setFilters={params => {
                      setFilters({
                        ...filters,
                        ncrgskname: params.target.value,
                      });
                    }}
                  />
                  <FilterRow
                    title="대표자명"
                    filters={filters.ncrgsmaster}
                    setFilters={params => {
                      setFilters({
                        ...filters,
                        ncrgsmaster: params.target.value,
                      });
                    }}
                  />
                  <FilterRow
                    title="전화번호"
                    filters={filters.ncrofftel}
                    setFilters={params => {
                      setFilters({
                        ...filters,
                        ncrofftel: params.target.value,
                      });
                    }}
                  />
                  <FilterRow
                    title="면허"
                    filters={filters.license}
                    setFilters={params => {
                      setFilters({
                        ...filters,
                        license: params.target.value,
                      });
                    }}
                  />
                  <FilterSelect
                    title="등록시도"
                    data={AREA_NAME}
                    filter={filterAreaName}
                    setFilter={params => {
                      setFilterAreaName(params);
                      setFilters({
                        ...filters,
                        area: params.substring(0, 2),
                      });
                    }}
                  />
                  <FilterSelect
                    title="등록시군구"
                    salesManagerArea={salesManagerArea}
                    data={defailData}
                    filter={filters.address.substring(0, 5).trim()}
                    setFilter={params => {
                      setFilters({
                        ...filters,
                        address: params.substring(0, 5).trim(),
                      });
                    }}
                  />
                  <SearchButton
                    onClick={async () => {
                      const result = await searchJonghapSales(
                        1,
                        filters,
                        sort,
                        limit,
                      );
                      dispatch(loadJonghapSales(result.data));
                    }}
                  />
                </FilterRowCategory>
              </FilterSection>
              {mode === 'jonghap' && (
                <JonghapRow
                  detailOn={detailOn}
                  setDetailOn={setDetailOn}
                  changeExtraInfo={changeExtraInfo}
                  loadDetailMemo={loadDetailMemo}
                  regdate={regdate}
                  is_shared={is_shared}
                  shareData={shareJonghapData}
                  handlePage={handlePage}
                />
              )}
            </SalesSection>
          </TitleCard>
        )}

        {mode === 'jeonmoon' && (
          <TitleCard title="영업">
            <CategorySection />

            <SalesSection>
              <FilterSection>
                <FilterRowCategory title="기본 검색" menuOn={true}>
                  <FilterRow
                    title="상호명"
                    filter={filters.ncrgskname}
                    setFilters={params => {
                      setFilters({
                        ...filters,
                        ncrgskname: params.target.value,
                      });
                    }}
                  />
                  <FilterRow
                    title="대표자명"
                    filters={filters.ncrgsmaster}
                    setFilters={params => {
                      setFilters({
                        ...filters,
                        ncrgsmaster: params.target.value,
                      });
                    }}
                  />
                  <FilterRow
                    title="전화번호"
                    filters={filters.ncrofftel}
                    setFilters={params => {
                      setFilters({
                        ...filters,
                        ncrofftel: params.target.value,
                      });
                    }}
                  />
                  <FilterRow
                    title="면허"
                    filters={filters.license}
                    setFilters={params => {
                      setFilters({
                        ...filters,
                        license: params.target.value,
                      });
                    }}
                  />
                  <FilterSelect
                    title="등록시도"
                    data={AREA_NAME}
                    filter={filterAreaName}
                    setFilter={params => {
                      setFilterAreaName(params);
                      setFilters({
                        ...filters,
                        area: params.substring(0, 2),
                      });
                    }}
                  />
                  <FilterSelect
                    title="등록시군구"
                    salesManagerArea={salesManagerArea}
                    data={defailData}
                    filter={filters.address.substring(0, 5).trim()}
                    setFilter={params => {
                      setFilters({
                        ...filters,
                        address: params.substring(0, 5).trim(),
                      });
                    }}
                  />
                  <SearchButton
                    onClick={() => {
                      jeonmoonSearch(1, limit);
                    }}
                  />
                </FilterRowCategory>
              </FilterSection>

              <JeonmoonRow
                detailOn={detailOn}
                setDetailOn={setDetailOn}
                changeExtraInfo={changeExtraInfo}
                loadDetailMemo={loadDetailMemo}
                regdate={regdate}
                is_shared={is_shared}
                shareData={shareJeonmoonData}
                handlePage={handlePage}
              />

              {/* <DownloadExcelButton data={trans} fileName="엑셀 파일"/> */}
            </SalesSection>
          </TitleCard>
        )}

        {mode === 'contact' && (
          <Contact
            setFilterAreaName={setFilterAreaName}
            setFilters={setFilters}
            mode={mode}
            contactAdminData={contactAdminData}
          />
        )}

        {mode === 'jonghap_jeonmoon' && (
          <TitleCard title="영업">
            <CategorySection />

            <SalesSection>
              <FilterSection>
                <FilterRowCategory title="기본 검색" menuOn={true}>
                  <FilterRow
                    title="상호명"
                    filter={filters.ncrgskname}
                    setFilters={params => {
                      setFilters({
                        ...filters,
                        ncrgskname: params.target.value,
                      });
                    }}
                  />
                  <FilterRow
                    title="대표자명"
                    filters={filters.ncrgsmaster}
                    setFilters={params => {
                      setFilters({
                        ...filters,
                        ncrgsmaster: params.target.value,
                      });
                    }}
                  />
                  <FilterRow
                    title="전화번호"
                    filters={filters.ncrofftel}
                    setFilters={params => {
                      setFilters({
                        ...filters,
                        ncrofftel: params.target.value,
                      });
                    }}
                  />
                  <FilterRow
                    title="면허"
                    filters={filters.license}
                    setFilters={params => {
                      setFilters({
                        ...filters,
                        license: params.target.value,
                      });
                    }}
                  />
                  <FilterSelect
                    title="등록시도"
                    data={AREA_NAME}
                    filter={filterAreaName}
                    setFilter={params => {
                      setFilterAreaName(params);
                      setFilters({
                        ...filters,
                        area: params.substring(0, 2),
                      });
                    }}
                  />
                  <FilterSelect
                    title="등록시군구"
                    salesManagerArea={salesManagerArea}
                    data={defailData}
                    filter={filters.address.substring(0, 5).trim()}
                    setFilter={params => {
                      setFilters({
                        ...filters,
                        address: params.substring(0, 5).trim(),
                      });
                    }}
                  />
                  <SearchButton
                    onClick={async () => {
                      const result = await jonghapJeonmoonSearch(
                        1,
                        filters,
                        sort,
                      );
                      dispatch(loadJonghapJeonmoonSales(result.data));
                    }}
                  />
                </FilterRowCategory>
              </FilterSection>
              {mode === 'jonghap_jeonmoon' && (
                <JonghapJeonmoonRow
                  detailOn={detailOn}
                  setDetailOn={setDetailOn}
                  changeExtraInfo={changeExtraInfo}
                  loadDetailMemo={loadDetailMemo}
                  regdate={regdate}
                  is_shared={is_shared}
                  shareData={shareJonghapJeonmoonData}
                  handlePage={handlePage}
                />
              )}
            </SalesSection>
          </TitleCard>
        )}

        {mode === 'contact_data' && (
          <ContactData detailOn={detailOn} setDetailOn={setDetailOn} />
        )}
      </Grid>

      {/* <Grid item>
                        <Grid container justifyContent="flex-end" direction="row" spacing={2}>
                            {status !== 'change' && <Grid item><Button disabled={status === 'change'} variant="contained" onClick={createNoticeData}>글 작성</Button></Grid>}
                            {status !== 'create' && <Grid item><Button disabled={id === 0} variant="contained" onClick={updateNoticesData}>글 수정</Button></Grid>}
                            {(status === 'change' || status === 'create') && <Grid item><Button variant="outlined" onClick={()=>{setStatus(''); setTitle(''); setContent('');}}>취소</Button></Grid>}
                            {status !== 'create' && <Grid item><Button disabled={id === 0} sx={{backgroundColor: 'red'}} variant="contained" onClick={deleteNoticeData}>글 삭제</Button></Grid>}
                        </Grid>
                    </Grid> */}
    </Grid>
  );
}

export default JonghapJeonmoonSales;
