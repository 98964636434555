import React, { useEffect } from 'react';
import './App.css';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from 'react-router-dom';
import { themeChange } from 'theme-change';
import Login from './page/user/Login';
import Layout from './containers/Layout';

function App() {
  useEffect(() => {
    // 👆 daisy UI themes initialization
    themeChange(false);
  }, []);

  const EmailCheck = () => {
    const location = useLocation();
    const path = location.pathname;
    // 로컬 스토리지에서 이메일 값 가져오기
    const email = localStorage.getItem('email');

    if (!email) {
      return <Login />;
    }

    // 이메일에서 '@' 이전의 부분 추출
    const emailPrefix = email.split('@')[0];

    if ('/' + emailPrefix === path) {
      return <Layout />;
    }

    return <Login />;
  };

  return (
    <>
      <Router>
        <Routes>
          {/* Place new routes over this */}
          <Route path="/*" element={<EmailCheck />} />
          <Route path="*" element={<EmailCheck />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
