import { StaticDatePicker } from '@mui/x-date-pickers';
import { createSlice } from '@reduxjs/toolkit';

export const jonghapJeonmoonSlice = createSlice({
  name: 'jonghapJeonmoonSales',
  initialState: {
    jonghap_jeonmoon_data: [],
    jonghap_jeonmoon_total_count: 0,
    jonghap_jeonmoon_index: 0,
    selected_jonghap_jeonmoon_key: '',
    selected_jonghap_jeonmoon_title: '-',
    current_page: 1,
  },
  reducers: {
    updateJonghapJeonmoonIndex: (state, action) => {
      state.jonghap_jeonmoon_index = action.payload;
    },

    loadJonghapJeonmoonSales: (state, action) => {
      state.jonghap_jeonmoon_data = action.payload.data;
      state.jonghap_jeonmoon_total_count = action.payload.total_count;
    },

    resetJonghapJeonmoonSales: state => {
      state.jonghap_jeonmoon_data = [];
      state.jonghap_jeonmoon_total_count = 0;
    },

    enterJonghapJeonmoonExtraInfo: (state, action) => {
      const array = [...state.jonghap_jeonmoon_data];
      array[state.jonghap_jeonmoon_index][action.payload.target] =
        action.payload.value;
      state.jonghap_jeonmoon_data = array;
    },

    updateSelectedJonghapJeonmoonData: (state, action) => {
      state.selected_jonghap_jeonmoon_key = action.payload.key;
      state.selected_jonghap_jeonmoon_title = action.payload.title;
    },

    updateRecallTrue: (state, action) => {
      state.jonghap_jeonmoon_data[state.jonghap_jeonmoon_index].recall_true =
        action.payload;
    },

    updateRecallDate: (state, action) => {
      state.jonghap_jeonmoon_data[state.jonghap_jeonmoon_index].recall_date =
        action.payload;
    },

    updateMissingNumber: (state, action) => {
      state.jonghap_jeonmoon_data[state.jonghap_jeonmoon_index].missing_number =
        action.payload;
    },

    updateJonghapJeonmoonCurrentPage: (state, action) => {
      state.current_page = action.payload;
    },
  },
});

export const {
  updateJonghapJeonmoonCurrentPage,
  updateMissingNumber,
  updateRecallDate,
  updateRecallTrue,
  loadJonghapJeonmoonSales,
  resetJonghapJeonmoonSales,
  updateJonghapJeonmoonIndex,
  updateSelectedJonghapJeonmoonData,
  enterJonghapJeonmoonExtraInfo,
} = jonghapJeonmoonSlice.actions;

export default jonghapJeonmoonSlice.reducer;
