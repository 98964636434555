import { SearchRounded } from '@mui/icons-material';
import React from 'react';
import styled from 'styled-components';
import { MenuItem, Select } from '@mui/material';
import { ColProps } from './GridType';

const St_TotalCount = styled.div`
  color: rgba(26, 49, 85, 0.7);
  font-weight: bold;

  strong {
    font-weight: bold;
    color: #1a3155;
  }
`;

const St_SearchInputBox = styled.div`
  width: 300px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 2px solid #ddd;
  border-radius: 50px;
  overflow: hidden;
  cursor: pointer;

  &:focus {
    border: 2px solid #1a3155;
  }
`;

const St_SearchWrapper = styled.div`
  display: flex;
  gap: 20px;
`;

const St_SearchInput = styled.input`
  width: 80%;
  padding: 10px 15px;
  &:focus {
    outline: none;
  }
  color: #aaa;
  &::placeholder {
    color: #aaa;
  }
`;

interface BoardSearchHeaderProps {
  total_count: number;
  title: string;
  defaultColumn: string;
  col: ColProps[];
  query: string;
  limit: number;
  limitOption: number[];
  setLimit: (e: number) => void;
  onInput: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onSelect: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export default function BoardSearchHeader(props: BoardSearchHeaderProps) {
  return (
    <div
      style={{
        marginBottom: '20px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <St_TotalCount>
        총 <strong>{props.total_count.toString()}</strong> 개의 {props.title}{' '}
        데이터가 있습니다.{' '}
      </St_TotalCount>

      <St_SearchWrapper>
        <Select
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            props.setLimit(Number(e.target.value));
          }}
          sx={{
            outline: 'none',
            width: '150px',
            border: '1px solid #ddd',
            borderRadius: '50px',
            color: '#aaa',
            fontSize: '16px',
          }}
          defaultValue={props.limit}
        >
          {props.limitOption.map(item => {
            return (
              <MenuItem style={{ padding: '5px' }} key={item} value={item}>
                {item}개씩 보기
              </MenuItem>
            );
          })}
        </Select>
        <Select
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            props.onSelect(e);
          }}
          sx={{
            outline: 'none',
            width: '100px',
            border: '1px solid #ddd',
            borderRadius: '50px',
            color: '#aaa',
            fontSize: '16px',
          }}
          defaultValue={props.defaultColumn}
        >
          {props.col.map(item => {
            if (item.sort && item.search) {
              return (
                <MenuItem
                  style={{ padding: '5px' }}
                  key={item.id}
                  value={item.id}
                >
                  {item.title}
                </MenuItem>
              );
            }
          })}
        </Select>
        <St_SearchInputBox>
          <St_SearchInput
            value={props.query}
            onChange={e => {
              props.onInput(e);
            }}
            placeholder="검색어를 입력해주세요"
          />
          <SearchRounded style={{ color: '#aaa', width: '20%' }} />
        </St_SearchInputBox>
      </St_SearchWrapper>
    </div>
  );
}
