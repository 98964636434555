import { createSlice } from '@reduxjs/toolkit';
import { TutorialContactBoard } from './tutorialContactSlice';

export interface ErrorLogBoard {
  user_name: string;
  email: string;
  api_name: string;
  error: string;
  date: string;
}

export interface ErrorLogState {
  list: ErrorLogBoard[];
  total_count: number;
}

const initialState: ErrorLogState = {
  list: [],
  total_count: 0,
};

export const errorLogSlice = createSlice({
  name: 'errorLogSlice',
  initialState: initialState,
  reducers: {
    updateErrorLogBoard: (state, action) => {
      state.list = action.payload.data;
      state.total_count = action.payload.total_count;
    },
  },
});

export const { updateErrorLogBoard } = errorLogSlice.actions;

export default errorLogSlice.reducer;
