import { createSlice } from '@reduxjs/toolkit';
import { ColProps } from '../../components/GridType';
import {
  calculatePagination,
  createPaginationSize
} from '../../utils/common_API';

export interface BidType {
  bidntceno: string;
  bidntcenm: string;
  maincnsttynm: string;
  ntcekindnm: string;
  sucsfidwltrate: number;
  rgstdt: string;
  rgsttynm: string;
  bidbegindt: string;
  bidclsedt: string;
  cnstrtsitergnnm: string;
  bssamt: number;
  rsrvtnprcerngbgnrate: number;
  pricescope: string;
  pred: string;
  preddate: number;
}

export interface LicensesCountType {
  [key: string]: number;
}

export interface DailyBidState {
  data: BidType[];

  selectedDate: string;
  tabList: string[];
  selectedTab: '일자별 공고' | '자동 발송 설정';
  Grid_COL: ColProps[];
  category: string;
  licenses_count: LicensesCountType;
  offset: number;
  limit: number;
  page_numbers: number[];
  page_length: number;
  query: string;
  total_count: number;
  all_count: number;
  approve_count: number;
}

const initialState: DailyBidState = {
  category: '전체',
  offset: 0,
  all_count: 0,
  total_count: 0,
  query: '',
  limit: 10,
  page_numbers: [1, 2, 3, 4, 5],
  page_length: 0,
  data: [],
  licenses_count: {},
  selectedDate: '20240810',
  approve_count: 0,
  selectedTab: '일자별 공고',
  tabList: ['일자별 공고', '자동 발송 설정'],
  Grid_COL: [
    {
      search: false,
      title: '면허',
      id: 'maincnsttynm',
      width: 20,
      textAlign: 'left',
      sort: true
    },
    {
      search: false,
      title: '공고명',
      id: 'bidntcenm',
      width: 50,
      textAlign: 'left',
      sort: true
    },
    {
      search: false,
      title: '발주처',
      id: 'bssamt',
      width: 30,
      textAlign: 'left',
      sort: true
    }
  ]
};
export const dailyBidSlice = createSlice({
  name: 'dailyBid',
  initialState: initialState,
  reducers: {
    updateLicensesCount: (state, action) => {
      state.licenses_count = action.payload;
    },
    updateLimit: (state, action) => {
      state.limit = action.payload;
    },

    updateAllCount: (state, action) => {
      state.all_count = action.payload;
    },
    updateOffset: (state, action) => {
      state.offset = action.payload;
    },

    updatePageLength(state, action) {
      state.total_count = action.payload;
      const length = calculatePagination(action.payload, state.limit);
      let array = [];
      state.page_length = length;

      array = createPaginationSize(state.offset, length);
      state.page_numbers = array;
    },

    updateTotalCount: (state, action) => {
      state.total_count = action.payload;
    },

    updateApproveCount: (state, action) => {
      state.approve_count = action.payload;
    },

    updateTab: (state, action) => {
      state.selectedTab = action.payload;
    },

    updateQuery: (state, action) => {
      state.query = action.payload;
    },

    updateCategory: (state, action) => {
      state.category = action.payload;
    },

    updateData: (state, action) => {
      state.data = action.payload;
    },

    updateDate: (state, action) => {
      state.selectedDate = action.payload;
    }
  }
});

export const {
  updateLicensesCount,
  updateData,
  updateTab,
  updateApproveCount,
  updateQuery,
  updateOffset,
  updateAllCount,
  updatePageLength,
  updateTotalCount,
  updateLimit,
  updateDate,
  updateCategory
} = dailyBidSlice.actions;

export default dailyBidSlice.reducer;
