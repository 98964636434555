import React from 'react';
import styled from 'styled-components';
import { TabButton } from '../Layout/TabButton';

interface ManagerGridDetailSectionProps {
  children?: React.ReactNode;
  open: boolean;
  direction?: 'horizontal' | 'vertical';
}

const St_GridDetailSection = styled.div<ManagerGridDetailSectionProps>`
  width: 100%;
  gap: 30px;
  height: ${(props) => (props.open === true ? 'auto' : '0')};
  background-color: #fff;

  display: flex;
  flex-direction: ${(props) =>
    (props.direction ?? 'vertical') === 'vertical' ? 'column' : 'row'};

  overflow: hidden;
  padding: ${(props) => (props.open ? '20px 30px' : '0px 30px')};
  transition: all 0.3s ease;
  border-bottom: ${(props) => (props.open ? '1px solid #ddd' : 'none')};
`;

const St_MemberWrapper = styled.div`
  margin-bottom: 60px;
  display: flex;
  align-items: center;
  gap: 40px;
`;

function DetailSection(props: ManagerGridDetailSectionProps) {
  return (
    <St_GridDetailSection open={props.open} direction={props.direction}>
      {props.children}
    </St_GridDetailSection>
  );
}

export interface TabButtonTypes {
  title: string;
  onClick: () => void;
  active: boolean;
}

function ButtonWrapper(props: { item: TabButtonTypes[] }) {
  return (
    <St_MemberWrapper>
      {props.item.map((elem, index) => {
        return (
          <TabButton key={index} onClick={elem.onClick} active={elem.active}>
            {elem.title}
          </TabButton>
        );
      })}
    </St_MemberWrapper>
  );
}

DetailSection.ButtonWrapper = ButtonWrapper;

export default DetailSection;
