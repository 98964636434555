import { createSlice } from '@reduxjs/toolkit';

export const jeonmoonSlice = createSlice({
  name: 'jeonmoonSales',
  initialState: {
    jeonmoon_data: [],
    jeonmoon_total_count: 0,
    jeonmoon_index: 0,
    selected_jeonmoon_key: '',
    selected_jeonmoon_title: '-',
    current_page: 1,
  },
  reducers: {
    updateJeonmoonIndex: (state, action) => {
      state.jeonmoon_index = action.payload;
    },

    loadJeonmoonSales: (state, action) => {
      state.jeonmoon_data = action.payload.data;
      state.jeonmoon_total_count = action.payload.total_count;
    },

    resetJeonmoonSales: state => {
      state.jeonmoon_data = [];
      state.jeonmoon_total_count = 0;
    },

    enterJeonmoonExtraInfo: (state, action) => {
      const array = [...state.jeonmoon_data];
      array[state.jeonmoon_index][action.payload.target] = action.payload.value;
      state.jeonmoon_data = array;
    },

    updateSelectedJeonmoonData: (state, action) => {
      state.selected_jeonmoon_key = action.payload.key;
      state.selected_jeonmoon_title = action.payload.title;
    },

    updateRecallTrue: (state, action) => {
      state.jeonmoon_data[state.jeonmoon_index].recall_true = action.payload;
    },

    updateRecallDate: (state, action) => {
      state.jeonmoon_data[state.jeonmoon_index].recall_date = action.payload;
    },

    updateMissingNumber: (state, action) => {
      state.jeonmoon_data[state.jeonmoon_index].missing_number = action.payload;
    },

    updateJeonmoonCurrentPage: (state, action) => {
      state.current_page = action.payload;
    },
  },
});

export const {
  updateJeonmoonCurrentPage,
  updateMissingNumber,
  updateRecallDate,
  updateRecallTrue,
  loadJeonmoonSales,
  resetJeonmoonSales,
  updateJeonmoonIndex,
  enterJeonmoonExtraInfo,
  updateSelectedJeonmoonData,
} = jeonmoonSlice.actions;

export default jeonmoonSlice.reducer;
