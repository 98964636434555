import axios from 'axios';
import { SortType } from '../../../app/slice/managerManagementSlice';
import { postUser, QueryParams } from '../../../utils/user_API';

interface SearchQueryType {
  category: string;
  query: string;
}

interface propTypes {
  user_id: number;
  access: string[];
  assignment: string[];
}

export async function insertAccess(prop: propTypes) {
  const option = {
    url: 'https://cpi.contax.ai/access',
    method: 'PUT',
    data: {
      user_id: prop.user_id,
      access: prop.access,
      assignment: prop.assignment
    },
    header: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data'
    }
  };

  try {
    const response = await axios(option);
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    return error;
  }
}

export async function searchMembers(
  offset: number,
  limit: number,
  sort: SortType,
  searchQuery: SearchQueryType
) {
  const params: QueryParams = {
    database: 'members',
    categories: ['business_type'],
    search_queries: ['관리자'],
    offset: offset * limit,
    limit: limit,
    sort_option: sort.option,
    sort: sort.sort
  };

  params.sort_option = sort.option;
  params.sort = sort.sort;

  if (searchQuery.query !== '') {
    params.search_queries.push(searchQuery.query);
    params.categories.push(searchQuery.category);
  }

  const result = await postUser(params);
  return result;
}
