import TitleCard from '../../components/Cards/TitleCard';
import Button from '@mui/material/Button';
import { Box, Grid, Input, MenuItem, Select, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import { useEffect, useState } from 'react';

const uesrColumns = [
  { field: 'coupon_number', headerName: '프로모션 코드', width: 150 },
  {
    field: 'duration',
    headerName: '기간',
    width: 130,
    renderCell: params => {
      return params.value + ' 일';
    },
  },
  { field: 'created_time', headerName: '생성 날짜', width: 130 },
  {
    field: 'type',
    headerName: '멤버쉽 타입',
    width: 130,
    renderCell: params => {
      if (params.value === 'contax') {
        return '콘택스 멤버쉽';
      } else if (params.value === 'first') {
        return '퍼스트 멤버쉽';
      }
    },
  },
  {
    field: 'is_used',
    headerName: '사용 여부',
    width: 130,
    renderCell: params => {
      if (params.value === false) {
        return 'X';
      } else {
        return 'O';
      }
    },
  },
  { field: 'used_time', headerName: '사용 날짜', width: 130 },
];

function Coupon() {
  const [couponData, setCouponData] = useState([
    {
      id: 0,
      coupon: 1234567890,
      duration: '14',
      created_time: '2023-01-01',
      is_used: true,
    },
  ]);
  const [duration, setDuration] = useState(0);
  const [code, setCode] = useState('');

  const [isTopAdmin, setIsTopAdmin] = useState('');
  const [myAssignment, setMyAssignment] = useState([]);

  useEffect(() => {
    const localAccess = localStorage.getItem('access');
    const localMember = localStorage.getItem('assignment_member');
    const array = localAccess.split(',').map(item => item.trim());
    const member = localMember.split(',').map(item => item.trim());

    if (array.includes('최고 관리자')) {
      setIsTopAdmin(true);
    }

    setMyAssignment(member);
    loadAllCoupons(member);
  }, []);

  async function loadAllCoupons() {
    const array = [];
    const conbiOption = {
      url: 'https://cpi.contax.ai/load_all_coupon',
      method: 'GET',
      header: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    };

    await axios(conbiOption).then(response => {
      if (response.status === 200) {
        response.data.forEach((item, index) => {
          array.push({ ...item, id: index });
        });

        setCouponData(array.reverse());
      }
    });
  }

  async function createPromotioncode(duration) {
    if (selectedMembership === '') {
      alert('멤버쉽을 선택해주세요.');
      return;
    }

    if (duration <= 0) {
      alert('기간을 1일 이상 입력해주세요');
      return;
    }

    const conbiOption = {
      url: `https://cpi.contax.ai/generate_coupon?duration=${duration}&type=${selectedMembership}`,
      method: 'POST',
      header: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    };

    await axios(conbiOption).then(response => {
      if (response.status === 200) {
        setCode(response.data);
        alert('프로모션 코드가 생성되었습니다.');
        loadAllCoupons();
      }
    });
  }

  const [selectedMembership, setSelectedMemberShip] = useState('');

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={isTopAdmin ? 12 : 9}>
          <TitleCard title="프로모션 코드 생성">
            <div style={{ display: 'flex', gap: '80px', alignItems: 'end' }}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  gap: 1,
                }}
              >
                <Typography variant="h7">멤버쉽 선택</Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <Select
                    defaultValue=""
                    value={selectedMembership}
                    onChange={e => {
                      setSelectedMemberShip(e.target.value);
                    }}
                  >
                    <MenuItem value="">선택</MenuItem>
                    <MenuItem value="contax">콘택스</MenuItem>
                    <MenuItem value="first">퍼스트</MenuItem>
                  </Select>
                </Box>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  gap: 1,
                }}
              >
                <Typography variant="h7">기간</Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <Input
                    value={duration}
                    onChange={e => {
                      setDuration(e.target.value);
                    }}
                  />
                  <Button
                    onClick={() => {
                      createPromotioncode(duration);
                    }}
                    variant="contained"
                    color="primary"
                  >
                    생성
                  </Button>
                </Box>
              </Box>

              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  gap: 1,
                }}
              >
                <Typography variant="h7">프로모션 코드</Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <Input disabled value={code} />
                </Box>
              </Box>
            </div>
          </TitleCard>

          <TitleCard title="프로모션 코드 관리">
            <DataGrid
              rows={couponData}
              columns={uesrColumns}
              getRowId={row => row.id}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 5 },
                },
              }}
              pageSizeOptions={[10]}
            />
          </TitleCard>
        </Grid>
      </Grid>
    </>
  );
}

export default Coupon;
