import axios from 'axios';
import { QueryParams } from './email_API';
import { LicenseData } from '../app/slice/licensePageSlice';

async function getLicenses(params: QueryParams) {
  const option = {
    url: 'https://cpi.contax.ai/get_board',
    method: 'POST',
    data: params,
    header: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };

  try {
    const response = await axios(option);

    if (response.status === 200) {
      return response.data;
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
}

async function changeLicense(params: LicenseData) {
  const option = {
    url: 'https://cpi.contax.ai/mng_page/licenses',
    data: {
      id: params.id,
      license_category1: params.license_category1,
      license_category2: params.license_category2,
      license_name: params.license_name,
      capital_standard: params.capital_standard,
      year: '2023'
    },
    method: 'PUT',
    header: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };

  try {
    const response = await axios(option);

    if (response.status === 200) {
      return 'success';
    } else {
      return 'failed';
    }
  } catch (error) {
    return 'failed';
  }
}

async function removeLicense(id: number) {
  if (!window.confirm('정말 삭제하시겠습니까?')) {
    return;
  }

  const option = {
    url: 'https://cpi.contax.ai/mng_page/licenses',
    data: {
      license_id: id
    },
    method: 'DELETE',
    header: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };

  try {
    const response = await axios(option);

    if (response.status === 200) {
      return 'success';
    } else {
      return 'failed';
    }
  } catch (error) {
    return 'failed';
  }
}

async function createLicense(params: LicenseData) {
  const option = {
    url: 'https://cpi.contax.ai/mng_page/licenses/',
    data: {
      license_category1: params.license_category1,
      license_category2: params.license_category2,
      license_name: params.license_name,
      capital_standard: params.capital_standard,
      year: '2023'
    },
    method: 'POST',
    header: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };

  try {
    const response = await axios(option);

    if (response.status === 200) {
      return 'success';
    } else {
      return 'failed';
    }
  } catch (error) {
    return 'failed';
  }
}

export { getLicenses, changeLicense, removeLicense, createLicense };
