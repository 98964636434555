import axios from 'axios';

async function getToken(paramArray) {
  try {
    const option = {
      url: 'https://cpi.contax.ai/get_token',
      method: 'GET',
      header: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    };

    const response = await axios(option);

    if (response.status === 200) {
      const array = [];
      response.data.forEach(item => {
        paramArray.forEach(jtem => {
          if (item.email === jtem) {
            array.push(item.token);
          }
        });
      });

      return array;
    } else {
      return response.status;
    }
  } catch (error) {
    return error;
  }
}

export { getToken };
