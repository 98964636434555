import React from 'react';
import { SuccessButton } from '../GridType';
import { Save } from '@mui/icons-material';

export function SaveButton(props: { onClick: () => void }) {
  return (
    <SuccessButton onClick={props.onClick}>
      <Save
        style={{
          width: '20px'
        }}
      />
      저장
    </SuccessButton>
  );
}
