import TitleCard from '../../components/Cards/TitleCard';
import { Button, Grid } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  ManagerGrid,
  ManagerGridDetailSection,
  ManagerGridRow,
  ManagerGridRowSection,
} from '../../components/Grid';
import { ReportModal } from '../../components/ModalComponents';

const SelectQuarterSection = styled.div`
  display: flex;
  gap: 20px;
  align-items: end;
`;

function ExpertComment() {
  const [company, setCompany] = useState('');
  const [userData, setUserData] = useState([]);
  const [reportList, setReportList] = useState([]);
  const [uploadedQuarter, setUploadedQuarter] = useState('');
  const [selectedYear, setSelectedYear] = useState('2022');
  const [selectedQuarter, setSelectedQuarter] = useState('01');
  const [targetName, setTargetName] = useState('');
  const [targetId, setTargetId] = useState(0);
  const [open, setOpen] = useState({ index: 0, open: false });
  const [modalOn, setModalOn] = useState({ division: '', on: false });
  const [selectedPyeongjeom, setSelectedPyeongjeom] = useState('');
  const [comemntList, setCommentList] = useState([]);

  useEffect(() => {
    if (uploadedQuarter === 'None') {
      return false;
    } else {
      setSelectedYear(uploadedQuarter.substring(0, 4));
      setSelectedQuarter(uploadedQuarter.substring(4, 6));

      loadExpertCommentList(targetId, uploadedQuarter);
    }
  }, [targetId, uploadedQuarter]);

  useEffect(() => {
    getUserData();
  }, []);

  async function getQuarter(company) {
    const option = {
      url: 'https://cpi.contax.ai/get_quarter',
      params: {
        company: company,
      },
      method: 'GET',
      header: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
      },
    };

    await axios(option).then(response => {
      setUploadedQuarter(response.data[1]);
    });
  }

  async function getUserData() {
    const option = {
      url: 'https://cpi.contax.ai/load_expert_comment_user_list',
      method: 'GET',
      header: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    };

    await axios(option).then(response => {
      if (response.status === 200) {
        setUserData(response.data);
      }
    });
  }

  async function getAnalyReport(email) {
    const option = {
      url: 'https://cpi.contax.ai/conbi_analy_report?email=' + email,
      method: 'GET',
      header: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
      },
    };

    await axios(option).then(response => {
      if (response.status === 200) {
        setReportList(response.data);
      }
    });
  }

  async function insertComment(comment, title) {
    const id = localStorage.getItem('id');
    const name = localStorage.getItem('name');

    const option = {
      url: 'https://cpi.contax.ai/insert_expert_comment',
      data: {
        writter_id: id,
        writter_name: name,
        target_id: targetId,
        target_name: targetName,
        quarter: selectedYear + '' + selectedQuarter,
        company: company,
        comment: comment,
        pyeongjeom: title,
      },
      method: 'POST',
      header: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
      },
    };

    await axios(option).then(response => {
      if (response.status === 200) {
        alert('성공적으로 저장되었습니다.');
        setModalOn({ division: 'report', on: false });
      }
    });
  }

  function openDetailSection(index, item) {
    if (index === open.index) {
      if (open.open) {
        setOpen({ index: index, open: false });
      }

      if (!open.open) {
        setOpen({ index: index, open: true });
      }
    }

    if (index !== open.index) {
      setOpen({ index: index, open: true });
    }

    setTargetName(item.name);
    setTargetId(item.target_id);
    setCompany(item.company);
    getAnalyReport(item.email);
    getQuarter(item.company);
  }

  async function loadExpertCommentList(id, quarter) {
    setCommentList([]);

    const option = {
      url: 'https://cpi.contax.ai/load_expert_comment_list_by_targetId',
      params: {
        quarter: quarter,
        target_id: id,
      },
      method: 'GET',
      header: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
      },
    };

    await axios(option).then(response => {
      setCommentList(response.data);
    });
  }

  const GRID_COL = [
    { title: '순번', width: '15', textAlign: 'left' },
    { title: '이름', width: '15', textAlign: 'left' },
    { title: '이메일', width: '20', textAlign: 'left' },
    { title: '회사', width: '25', textAlign: 'left' },
    { title: '휴대폰 번호', width: '15', textAlign: 'left' },
    { title: '사업자 번호', width: '10', textAlign: 'left' },
  ];

  const DETAIL_COL = [
    { title: '번호', width: '10', textAlign: 'left' },
    { title: '경영 평점', width: '30', textAlign: 'left' },
    { title: '작성자', width: '20', textAlign: 'left' },
    { title: '작성날짜', width: '25', textAlign: 'left' },
    { title: '상세보기', width: '15', textAlign: 'center' },
  ];

  const [expertComment, setExpertComment] = useState('');

  async function getExpertComment(pyeongjeom) {
    const option = {
      url: 'https://cpi.contax.ai/load_expert_comment_by_targetId',
      params: {
        target_id: targetId,
        quarter: selectedYear + '' + selectedQuarter,
        pyeongjeom: pyeongjeom,
      },
      method: 'GET',
      header: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
      },
    };

    await axios(option).then(response => {
      setExpertComment(response.data);
    });
  }

  function modalOpen(division, pyeongjeom) {
    const object = { ...modalOn };
    object.division = division;
    object.on = true;
    setModalOn(object);
    setSelectedPyeongjeom(pyeongjeom);
    getExpertComment(pyeongjeom).then(r => {
      console.log(r);
    });
  }

  return (
    <>
      {modalOn.on === true && modalOn.division === 'report' && (
        <ReportModal
          expertComment={expertComment}
          insertComment={(comment, title) => {
            insertComment(comment, title);
          }}
          title={selectedPyeongjeom}
          setModalOn={() => {
            const object = { ...modalOn };
            object.on = false;
            setModalOn(object);
          }}
          selectedQuarter={selectedQuarter}
          selectedYear={selectedYear}
          reportList={reportList}
          readMode
        />
      )}

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TitleCard title="보고서 코멘트 관리">
            <ManagerGrid col={GRID_COL}>
              {userData.map((item, index) => {
                return (
                  <>
                    <ManagerGridRowSection>
                      <ManagerGridRow
                        onClick={() => {
                          openDetailSection(index, item);
                        }}
                        width="15"
                      >
                        {index + 1}
                      </ManagerGridRow>
                      <ManagerGridRow
                        onClick={() => {
                          openDetailSection(index, item);
                        }}
                        width="15"
                      >
                        {item.target_name}
                      </ManagerGridRow>
                      <ManagerGridRow
                        onClick={() => {
                          openDetailSection(index, item);
                        }}
                        width="20"
                      >
                        {item.email}
                      </ManagerGridRow>
                      <ManagerGridRow
                        onClick={() => {
                          openDetailSection(index, item);
                        }}
                        width="25"
                      >
                        {item.company}
                      </ManagerGridRow>
                      <ManagerGridRow
                        onClick={() => {
                          openDetailSection(index, item);
                        }}
                        width="15"
                      >
                        {item.phone_number}
                      </ManagerGridRow>
                      <ManagerGridRow
                        onClick={() => {
                          openDetailSection(index, item);
                        }}
                        width="10"
                      >
                        {item.business_number}
                      </ManagerGridRow>
                    </ManagerGridRowSection>

                    <ManagerGridDetailSection
                      open={open.index === index && open.open === true}
                    >
                      <SelectQuarterSection>
                        <select
                          style={{
                            width: '95px',
                            fontWeight: 'bold',
                            fontSize: '20px',
                          }}
                          value={selectedYear}
                          onChange={e => {
                            loadExpertCommentList(
                              targetId,
                              e.target.value + '' + selectedQuarter,
                            );
                            setSelectedYear(e.target.value);
                          }}
                          defaultValue="2022"
                        >
                          <option value="2022">2022년</option>
                          <option value="2023">2023년</option>
                          <option value="2024">2024년</option>
                        </select>

                        <select
                          style={{
                            width: '95px',
                            fontWeight: 'bold',
                            fontSize: '20px',
                          }}
                          value={selectedQuarter}
                          onChange={e => {
                            loadExpertCommentList(
                              targetId,
                              selectedYear + '' + e.target.value,
                            );
                            setSelectedQuarter(e.target.value);
                          }}
                          defaultValue="01"
                        >
                          <option value="01">1분기</option>
                          <option value="02">2분기</option>
                          <option value="03">3분기</option>
                          <option value="04">4분기</option>
                        </select>

                        <span
                          style={{
                            fontSize: '14px',
                            color: '#555',
                          }}
                        >
                          최근 업로드 :{' '}
                          {uploadedQuarter.substring(0, 4) +
                            '년 ' +
                            uploadedQuarter.substring(5, 6) +
                            '분기'}
                        </span>
                      </SelectQuarterSection>

                      <div style={{ marginTop: '20px' }}>
                        <ManagerGrid col={DETAIL_COL}>
                          {comemntList.map(comment => {
                            return (
                              <ManagerGridRowSection>
                                <ManagerGridRow
                                  onClick={() => {
                                    modalOpen('report', comment.pyeongjeom);
                                  }}
                                  width="10"
                                >
                                  {comment.id}
                                </ManagerGridRow>
                                <ManagerGridRow
                                  onClick={() => {
                                    modalOpen('report', comment.pyeongjeom);
                                  }}
                                  width="30"
                                >
                                  {comment.pyeongjeom}
                                </ManagerGridRow>
                                <ManagerGridRow
                                  onClick={() => {
                                    modalOpen('report', comment.pyeongjeom);
                                  }}
                                  width="20"
                                >
                                  {comment.writter_name}
                                </ManagerGridRow>
                                <ManagerGridRow
                                  onClick={() => {
                                    modalOpen('report', comment.pyeongjeom);
                                  }}
                                  width="25"
                                >
                                  {comment.date}
                                </ManagerGridRow>
                                <ManagerGridRow
                                  align="center"
                                  onClick={() => {
                                    modalOpen('report', comment.pyeongjeom);
                                  }}
                                  width="15"
                                >
                                  <Button variant="contained">상세보기</Button>
                                </ManagerGridRow>
                              </ManagerGridRowSection>
                            );
                          })}

                          {comemntList.length === 0 && (
                            <div
                              style={{
                                textAlign: 'center',
                                marginTop: '250px',
                              }}
                            >
                              데이터가 존재하지 않습니다.
                            </div>
                          )}
                        </ManagerGrid>
                      </div>
                    </ManagerGridDetailSection>
                  </>
                );
              })}
            </ManagerGrid>
          </TitleCard>
        </Grid>
      </Grid>
    </>
  );
}

export default ExpertComment;
