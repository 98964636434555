import React, { useEffect } from 'react';
import TitleCard from '../../components/Cards/TitleCard';
import axios from 'axios';
import { Grid } from '@mui/material';
import { getAllManageNotification } from '../../utils/getAllManageNotification';
import { getToken } from '../../utils/getToken';
import { getUserId } from '../../utils/getUserId';
import { sendNotification } from '../../utils/sendNotification';
import Editor from '../../components/Editor';
import stripHtmlAndLimitLength from '../../utils/stripHtmlAndLimitLength';
import { useDispatch, useSelector } from 'react-redux';
import { getCategory, getContactData } from './tools/contact_API';
import {
  resetCategories,
  resetTarget,
  updateCategories,
  updateContactData,
  updateLimit,
  updateOffset,
  updatePageStatus,
  updateQuery,
  updateSearchCategories,
  updateTarget,
  updateTargetContent,
  updateTargetTitle
} from '../../app/slice/useGuidePageSlice';
import BoardSearchHeader from '../../components/BoardSearchHeader';
import Table from '../../components/Layout/Table';
import {
  ManagerInput,
  ManagerSelect
} from '../../components/Layout/ManagerInput';
import BoardPagenation from '../../components/BoardPagenation';
import {
  calculatePagination,
  createPaginationSize
} from '../../utils/common_API';
import {
  updatePageLength,
  updatePageNumbers
} from '../../app/slice/useGuidePageSlice';
import { DeleteButton, ManagerGridButton } from '../../components/GridType';

import { PencilIcon } from '@heroicons/react/16/solid';
import { insertNotification } from '../../utils/insertNotification';

function UseGuide() {
  const {
    status,
    categories,
    target,
    data,
    search_categories,
    offset,
    limit,
    total_count,
    page_numbers,
    page_length,
    query
  } = useSelector((state) => state.useGuidePage);

  function setOffset(offset) {
    dispatch(updateOffset(offset));
  }

  const dispatch = useDispatch();

  const column = [
    {
      id: 'id',
      title: '번호',
      width: '10',
      textAlign: 'left',
      align: 'left',
      sort: true,
      search: false,
      display: true
    },
    {
      id: 'title',
      title: '제목',
      width: '40',
      textAlign: 'left',
      align: 'left',
      sort: true,
      search: true,
      display: true
    },

    {
      id: 'category',
      title: '작성날짜',
      width: '25',
      textAlign: 'left',
      align: 'left',
      sort: true,
      search: false,
      display: true
    },

    {
      id: 'regdate',
      title: '카테고리',
      width: '25',
      textAlign: 'left',
      align: 'left',
      sort: true,
      search: true,
      display: true
    },

    {
      id: 'content',
      title: '',
      width: '0',
      textAlign: 'left',
      align: 'left',
      sort: false,
      search: false,
      display: false
    }
  ];

  useEffect(() => {
    loadContactData();
    loadCategory();
  }, [offset]);

  async function loadCategory() {
    const result = await getCategory();

    dispatch(resetCategories());
    if (result.status === 'failed') {
      dispatch(updateCategories());
      return;
    }

    result.data.forEach((element) => {
      dispatch(updateCategories(element));
    });
  }

  async function deleteUseGuideData() {
    if (!window.confirm('정말 삭제하시겠습니까?')) {
      return;
    } else {
      const option = {
        url: 'https://cpi.contax.ai/use_guide',
        params: {
          id: target.id
        },
        method: 'DELETE',
        header: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      };

      axios(option).then((response) => {
        if (response.status === 200) {
          if (response.status === 200) {
            alert('삭제가 완료되었습니다.');
            dispatch(updatePageStatus('read'));
            loadContactData();
          }
        }
      });
    }
  }

  async function loadContactData() {
    const params = {
      database: 'use_guide',
      categories: search_categories,
      search_queries: [''],
      offset: offset,
      limit: limit,
      sort_option: 'regdate',
      sort: 'DESC'
    };

    const result = await getContactData(params);

    if (result.status === 'failed') {
      dispatch(
        updateContactData({
          total_count: 0,
          data: []
        })
      );
      return;
    }

    dispatch(
      updateContactData({
        total_count: result.data.total_count,
        data: convertToData(result.data.data)
      })
    );

    const length = calculatePagination(result.data.total_count, limit);
    const array = createPaginationSize(offset, length);
    dispatch(updatePageLength(length));
    dispatch(updatePageNumbers(array));
  }

  function convertToData(datas) {
    const result = [];
    datas.forEach((data) => {
      const array = Object.entries(data).map(([key, value]) => {
        const matchingColumn = column.find((col) => col.id === key);

        if (matchingColumn !== undefined && matchingColumn.id === 'content') {
          return {
            id: key,
            value: value,
            width: 0,
            textAlign: 'left',
            align: 'flex-start',
            display: false
          };
        }

        if (matchingColumn !== undefined && matchingColumn.id === 'regdate') {
          return {
            id: key,
            value: value.substring(0, 10),
            width: Number(matchingColumn.width),
            textAlign: 'left',
            align: 'flex-start',
            display: true
          };
        }

        if (matchingColumn) {
          return {
            id: key,
            value: value,
            width: Number(matchingColumn.width),
            textAlign: 'left',
            align: 'flex-start',
            display: true
          };
        }
        return null;
      });

      result.push(array);
    });

    return result;
  }

  async function createContactData() {
    if (target.title === '' || target.title === null) {
      alert('제목을 입력해주세요');
      return;
    }

    if (target.content === '' || target.content === null) {
      alert('내용을 입력해주세요');
      return;
    }

    const body = {
      title: target.title,
      content: target.content,
      category: target.category
    };

    const option = {
      url: 'https://cpi.contax.ai/use_guide',
      method: 'POST',
      data: body,
      header: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    };

    axios(option).then((response) => {
      const inputContent = stripHtmlAndLimitLength(target.content, 28);

      if (response.status === 200) {
        getAllManageNotification('contact').then((array) => {
          getToken(array)
            .then((token) =>
              token.forEach((item) => {
                sendNotification(
                  '[자주하는 질문] ' + target.title,
                  inputContent,
                  item,
                  'contact'
                );
              })
            )
            .catch((error) => console.log(error));

          getUserId(array)
            .then((idList) => {
              insertNotification(
                'contact',
                target.title,
                inputContent,
                idList,
                0
              );
            })
            .catch((error) => console.log(error));
        });

        alert('글이 생성되었습니다.');
        dispatch(updatePageStatus('read'));
        loadContactData();
      }
    });
  }

  async function changeContactData() {
    const data = {
      id: target.id,
      title: target.title,
      content: target.content,
      category: target.category
    };

    const option = {
      url: 'https://cpi.contax.ai/use_guide',
      data: data,
      method: 'PUT'
    };

    axios(option).then((response) => {
      if (response.status === 200) {
        alert('수정이 완료되었습니다.');
        dispatch(updatePageStatus('read'));
        loadContactData();
      }
    });
  }

  function convertToCategoryList(category) {
    const result = [];

    category.forEach((item) => {
      result.push({
        title: item,
        value: item
      });
    });

    return result;
  }

  async function loadArticle(article_id) {
    const option = {
      url:
        'https://cpi.contax.ai/get_use_guide_article?article_id=' + article_id,
      method: 'GET'
    };

    axios(option).then((response) => {
      if (response.status === 200) {
        dispatch(updateTargetContent(response.data[0].content));
      }
    });
  }

  // async function changeCategory(newCategory) {
  //   const option = {
  //     url: 'https://cpi.contax.ai/categories/contact',
  //     data: {
  //       text: newCategory
  //     },
  //     method: 'PUT',
  //     header: {
  //       Accept: 'application/json',
  //       'Content-Type': 'application/json'
  //     }
  //   };
  //
  //   axios(option).then((response) => {
  //     if (response.status === 200) {
  //       setCategory(newCategory);
  //     }
  //   });
  // }

  return (
    <Grid spacing={2}>
      {status === 'read' && (
        <TitleCard title="이용안내">
          <BoardSearchHeader
            total_count={total_count}
            title="이용안내"
            defaultColumn="id"
            col={column}
            query={query}
            limit={limit}
            limitOption={[5, 10, 20, 50, 100]}
            setLimit={(e) => {
              dispatch(updateLimit(e));
            }}
            onInput={(e) => {
              dispatch(updateQuery(e.target.value));
            }}
            onSelect={(e) => {
              dispatch(updateSearchCategories(e.target.value));
            }}
          />

          <Table
            onClick={(data) => {
              loadArticle(data[0].value);
              dispatch(updateTarget(data));
            }}
            size="large"
            col={column}
            sortFunction={() => {
              console.log(1);
            }}
            datas={data}
          />

          <BoardPagenation
            offset={offset}
            pageNumbers={page_numbers}
            pageLength={page_length}
            setOffset={setOffset}
          />

          <div
            style={{
              display: 'flex',
              marginTop: '30px',
              justifyContent: 'end'
            }}
          >
            <ManagerGridButton
              type="active"
              onClick={() => {
                dispatch(updatePageStatus('write'));
                dispatch(resetTarget());
              }}
            >
              <PencilIcon style={{ width: '20px' }} />
              작성
            </ManagerGridButton>
          </div>
        </TitleCard>
      )}

      {(status === 'write') | (status === 'modify') && (
        <TitleCard
          previous={() => {
            dispatch(updatePageStatus('read'));
          }}
          title="이용안내 글 작성"
        >
          <div style={{ marginTop: '50px', padding: '20px' }}>
            <ManagerInput
              title="제목"
              value={target.title}
              onChange={(e) => {
                dispatch(updateTargetTitle(e.target.value));
              }}
            />

            <div style={{ height: '30px' }} />
            <ManagerSelect
              title="카테고리"
              value={target.category}
              items={convertToCategoryList(categories)}
            />
            <div style={{ height: '50px' }} />
            <Editor
              value={target.content}
              setValue={(data) => {
                dispatch(updateTargetContent(data));
              }}
            />
          </div>

          <div
            style={{
              display: 'flex',
              marginTop: '30px',
              justifyContent: 'end'
            }}
          >
            <ManagerGridButton
              type="active"
              onClick={() => {
                status === 'write'
                  ? createContactData()
                  : changeContactData(target.id);
              }}
            >
              <PencilIcon style={{ width: '20px' }} />
              작성
            </ManagerGridButton>
            {status === 'modify' && (
              <DeleteButton onClick={deleteUseGuideData} />
            )}
          </div>
        </TitleCard>
      )}
    </Grid>
  );
}

export default UseGuide;
