import React, { useEffect, useState } from 'react';
import ErrorText from '../../components/Typography/ErrorText';
import InputText from '../../components/Input/InputText';
import axios from 'axios';
import { useNavigate } from 'react-router';
import Layout from '../../containers/Layout';

function Login({ id }) {
  const navigate = useNavigate();

  const INITIAL_LOGIN_OBJ = {
    password: '',
    emailId: id
  };

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [loginObj, setLoginObj] = useState(INITIAL_LOGIN_OBJ);
  const email = localStorage.getItem('email');
  if (email !== null) {
    const emailPrefix = email.split('@')[0];

    window.location.href = './' + emailPrefix;
  }
  async function login() {
    const optionData = {
      email: loginObj.emailId.trim(),
      password: loginObj.password.trim()
    };

    const option = {
      url: 'https://cpi.contax.ai/members/login',
      data: optionData,
      method: 'POST',
      header: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data'
      }
    };

    try {
      const response = await axios(option);
      if (
        response.status === 200 &&
        response.data.members_mng.is_admin === true
      ) {
        setLoading(true);
        localStorage.setItem('is_admin', 'true');
        localStorage.setItem('access', response.data.members_mng.access);
        localStorage.setItem(
          'assignment_member',
          response.data.members_mng.assignment_member
        );
        localStorage.setItem(
          'sales_member',
          response.data.members_mng.sales_member
        );
        localStorage.setItem(
          'manager_division',
          response.data.members_mng.manager_division
        );
        localStorage.setItem('id', response.data.id);
        localStorage.setItem('name', response.data.name);
        localStorage.setItem(
          'second_manage_data',
          response.data.members_mng.second_manage_data
        );
        localStorage.setItem('email', response.data.email);

        if (
          response.data.members_mng.manager_division === 'sales' ||
          response.data.members_mng.manager_division === 'sales_second' ||
          response.data.members_mng.manager_division === 'contact_sales'
        ) {
          const emailPrefix = loginObj.emailId.trim().split('@')[0];
          window.location.href = '/' + emailPrefix;
        } else {
          const emailPrefix = loginObj.emailId.trim().split('@')[0];
          window.location.href = '/' + emailPrefix;
        }
      } else {
        setErrorMessage('관리자 권한이 없습니다');
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        setErrorMessage('로그인 정보가 일치하지 않습니다.');
      }
    }
  }

  const submitForm = (e) => {
    e.preventDefault();
    setErrorMessage('');

    if (loginObj.emailId.trim() === '')
      return setErrorMessage('이메일을 입력해주세요');
    if (loginObj.password.trim() === '')
      return setErrorMessage('비밀번호를 입력해주세요');
    else {
      login();
    }
  };

  const updateFormValue = ({ updateType, value }) => {
    setErrorMessage('');
    setLoginObj({ ...loginObj, [updateType]: value });
  };

  return (
    <div className="min-h-screen bg-base-200 flex items-center">
      <div className="card mx-auto w-full max-w-xl  shadow-xl">
        <div className="grid  md:grid-cols-1 grid-cols-1  bg-base-100 rounded-xl">
          <div className="py-24 px-10">
            <h2 className="text-2xl font-semibold mb-2 text-center">
              관리자 로그인
            </h2>
            <form onSubmit={(e) => submitForm(e)}>
              <div className="mb-4">
                <InputText
                  type="emailId"
                  defaultValue={loginObj.emailId}
                  updateType="emailId"
                  containerStyle="mt-4"
                  labelTitle="이메일"
                  updateFormValue={updateFormValue}
                />

                <InputText
                  defaultValue={loginObj.password}
                  type="password"
                  updateType="password"
                  containerStyle="mt-4"
                  labelTitle="비밀번호"
                  updateFormValue={updateFormValue}
                />
              </div>

              <ErrorText styleClass="mt-8">{errorMessage}</ErrorText>
              <button
                type="submit"
                className={
                  'btn mt-2 w-full btn-primary' + (loading ? ' loading' : '')
                }
              >
                로그인
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
