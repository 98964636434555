import TitleCard from '../../components/Cards/TitleCard';
import Button from '@mui/material/Button';
import { Grid } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  BeforeUploadGridRow,
  CompleteUploadGridRow,
  ManagerGrid,
  ManagerGridDetailSection,
  ManagerGridRow,
  ManagerGridRowSection,
  PyeongjeomProperty
} from '../../components/Grid';
import {
  CommentModal,
  GyejeongModal,
  OverallModal
} from '../../components/ModalComponents';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1
});

const St_PyeongjeomProperty = styled.div`
  width: 194px;
  height: 205px;
  border: 1px solid #d7d7d7;
  border-radius: 5px;
  padding: 14px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background-color: #eee;
  }
`;

const St_PyeongjeomPropertyName = styled.div`
  font-size: 20px;
  font-weight: bold;
  display: flex;
  gap: 3px;
  align-items: center;
`;

const SelectQuarterSection = styled.div`
  display: flex;
  gap: 20px;
  align-items: end;
`;

const PyeongjeomPropertySection = styled.div`
  margin-top: 22px;
  display: flex;
  flex-wrap: wrap;
  gap: 50px;
`;

function TaxManager() {
  const [diff, setDiff] = useState({
    yudong: 0,
    buchae: 0,
    chaIp: 0,
    maechul: 0,
    jasanTurning: 0,
    techdev: 0,
    cashflow: 0,
    profit: 0,
    income: 0,
    alljasan: 0
  });

  const [result, setResult] = useState({
    yudong: 'down',
    buchae: 'down',
    chaIp: 'down',
    maechul: 'down',
    jasanTurning: 'down',
    techdev: 'down',
    cashflow: 'down',
    profit: 'down',
    income: 'down',
    alljasan: 'down'
  });

  const [uploadedQuarter, setUploadedQuarter] = useState('');
  const [company, setCompany] = useState('');
  const [assignmentData, setAssignmentData] = useState([]);
  const [gyejeongRow, setGyejeongRow] = useState([]);
  const [originalRow, setOriginalRow] = useState([]);
  const [userIdList] = useState([]);
  const [token] = useState([]);
  const [selectedYear, setSelectedYear] = useState();
  const [selectedQuarter, setSelectedQuarter] = useState('01');
  const [review, setReview] = useState([{ id: '', text: '' }]);
  const [comment, setComment] = useState('');

  async function loadReview() {
    const option = {
      url: 'https://cpi.contax.ai/load_pyeongjeom_review',
      method: 'GET',
      header: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data'
      }
    };

    await axios(option)
      .then((response) => {
        if (response.status === 200) {
          setReview(response.data);
        }
      })
      .catch((error) => {
        return error;
      });
  }

  async function getDiff(company) {
    setDiff({
      yudong: 0,
      buchae: 0,
      chaIp: 0,
      maechul: 0,
      jasanTurning: 0,
      techdev: 0,
      cashflow: 0,
      profit: 0,
      income: 0,
      alljasan: 0
    });

    const object = { ...diff };
    const resultObject = { ...result };

    const yudongOption = {
      url: 'https://cpi.contax.ai/management_score/calculate_yudongratio_diff_quarter',
      params: {
        company_name: company,
        quarter: `${selectedYear}${selectedQuarter}`
      },
      method: 'GET',
      header: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data'
      }
    };

    const yudongResultOption = {
      url: 'https://cpi.contax.ai/management_score/calculate_yudongratio_updown_quarter',
      params: {
        company_name: company,
        quarter: `${selectedYear}${selectedQuarter}`
      },
      method: 'GET',
      header: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data'
      }
    };

    try {
      const response = await yudongOption;

      if (
        response.status === 200 &&
        response.data.percent_diff !== 'Data insufficient'
      ) {
        object.yudong = response.data.percent_diff;
      }

      if (
        response.status === 200 &&
        response.data.percent_diff === 'Data insufficient'
      ) {
        object.yudong = 0;
      }
    } catch (error) {
      object.yudong = 0;
    }

    try {
      const response = await yudongResultOption;

      if (response.status === 200) {
        resultObject.yudong = response.data.result;
      }
    } catch (error) {
      resultObject.yudong = 'down';
    }

    const buchaeOption = {
      url: 'https://cpi.contax.ai/management_score/calculate_buchaeratio_diff_quarter',
      params: {
        company_name: company,
        quarter: `${selectedYear}${selectedQuarter}`
      },
      method: 'GET',
      header: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data'
      }
    };

    const buchaeResultOption = {
      url: 'https://cpi.contax.ai/management_score/calculate_buchaeratio_updown_quarter',
      params: {
        company_name: company,
        quarter: `${selectedYear}${selectedQuarter}`
      },
      method: 'GET',
      header: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data'
      }
    };

    try {
      const response = await buchaeOption;

      if (
        response.status === 200 &&
        response.data.percent_diff !== 'Data insufficient'
      ) {
        object.buchae = response.data.percent_diff;
      }

      if (
        response.status === 200 &&
        response.data.percent_diff === 'Data insufficient'
      ) {
        object.buchae = 0;
      }
    } catch (error) {
      object.buchae = 0;
    }

    try {
      const response = await buchaeResultOption;

      if (response.status === 200) {
        resultObject.buchae = response.data.result;
      }
    } catch (error) {
      resultObject.buchae = 'down';
    }

    const chaIpOption = {
      url: 'https://cpi.contax.ai/management_score/calculate_cha_ipratio_diff_quarter',
      params: {
        company_name: company,
        quarter: `${selectedYear}${selectedQuarter}`
      },
      method: 'GET',
      header: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data'
      }
    };

    const chaIpResultOption = {
      url: 'https://cpi.contax.ai/management_score/calculate_cha_ipratio_updown_quarter',
      params: {
        company_name: company,
        quarter: `${selectedYear}${selectedQuarter}`
      },
      method: 'GET',
      header: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data'
      }
    };

    try {
      const response = await chaIpOption;

      if (
        response.status === 200 &&
        response.data.percent_diff !== 'Data insufficient'
      ) {
        object.chaIp = response.data.percent_diff;
      }

      if (
        response.status === 200 &&
        response.data.percent_diff === 'Data insufficient'
      ) {
        object.chaIp = 0;
      }
    } catch (error) {
      object.chaIp = 0;
    }

    try {
      const response = await chaIpResultOption;

      if (response.status === 200) {
        resultObject.chaIp = response.data.result;
      }
    } catch (error) {
      resultObject.chaIp = 'down';
    }

    const maechulOption = {
      url: 'https://cpi.contax.ai/management_score/calculate_maechul_realinterest_ratio_diff_quarter',
      params: {
        company_name: company,
        quarter: `${selectedYear}${selectedQuarter}`
      },
      method: 'GET',
      header: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data'
      }
    };

    const maechulResultOption = {
      url: 'https://cpi.contax.ai/management_score/calculate_maechul_realinterest_ratio_updown_quarter',
      params: {
        company_name: company,
        quarter: `${selectedYear}${selectedQuarter}`
      },
      method: 'GET',
      header: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data'
      }
    };

    try {
      const response = await maechulOption;

      if (
        response.status === 200 &&
        response.data.percent_diff !== 'Data insufficient'
      ) {
        object.maechul = response.data.percent_diff;
      }

      if (
        response.status === 200 &&
        response.data.percent_diff === 'Data insufficient'
      ) {
        object.maechul = 0;
      }
    } catch (error) {
      object.maechul = 0;
    }

    try {
      const response = await maechulResultOption;

      if (response.status === 200) {
        resultObject.maechul = response.data.result;
      }
    } catch (error) {
      resultObject.maechul = 'down';
    }

    const jasanTurningOption = {
      url: 'https://cpi.contax.ai/management_score/calculate_jasan_turning_ratio_diff_quarter',
      params: {
        company_name: company,
        quarter: `${selectedYear}${selectedQuarter}`
      },
      method: 'GET',
      header: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data'
      }
    };

    const jasanTurningResultOption = {
      url: 'https://cpi.contax.ai/management_score/calculate_jasan_turning_ratio_updown_quarter',
      params: {
        company_name: company,
        quarter: `${selectedYear}${selectedQuarter}`
      },
      method: 'GET',
      header: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data'
      }
    };

    try {
      const response = await jasanTurningOption;

      if (
        response.status === 200 &&
        response.data.percent_diff !== 'Data insufficient'
      ) {
        object.jasanTurning = response.data.percent_diff;
      }

      if (
        response.status === 200 &&
        response.data.percent_diff === 'Data insufficient'
      ) {
        object.jasanTurning = 0;
      }
    } catch (error) {
      object.jasanTurning = 0;
    }

    try {
      const response = await jasanTurningResultOption;

      if (response.status === 200) {
        resultObject.jasanTurning = response.data.result;
      }
    } catch (error) {
      resultObject.jasanTurning = 'down';
    }

    const cashflowOption = {
      url: 'https://cpi.contax.ai/management_score/calculate_alljasan_sale_cashflow_ratio_diff_quarter',
      params: {
        company_name: company,
        quarter: `${selectedYear}${selectedQuarter}`
      },
      method: 'GET',
      header: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data'
      }
    };

    const cashflowResultOption = {
      url: 'https://cpi.contax.ai/management_score/calculate_alljasan_sale_cashflow_ratio_updown_quarter',
      params: {
        company_name: company,
        quarter: `${selectedYear}${selectedQuarter}`
      },
      method: 'GET',
      header: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data'
      }
    };

    try {
      const response = await cashflowOption;

      if (
        response.status === 200 &&
        response.data.percent_diff !== 'Data insufficient'
      ) {
        object.cashflow = response.data.percent_diff;
      }

      if (
        response.status === 200 &&
        response.data.percent_diff === 'Data insufficient'
      ) {
        object.cashflow = 0;
      }
    } catch (error) {
      object.cashflow = 0;
    }

    try {
      const response = await cashflowResultOption;

      if (response.status === 200) {
        resultObject.cashflow = response.data.result;
      }
    } catch (error) {
      resultObject.cashflow = 'down';
    }

    const techdevOption = {
      url: 'https://cpi.contax.ai/management_score/calculate_construct_techdev_invest_ratio_diff_quarter',
      params: {
        company_name: company,
        quarter: `${selectedYear}${selectedQuarter}`
      },
      method: 'GET',
      header: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data'
      }
    };

    const techdevResultOption = {
      url: 'https://cpi.contax.ai/management_score/calculate_construct_techdev_invest_ratio_updown_quarter',
      params: {
        company_name: company,
        quarter: `${selectedYear}${selectedQuarter}`
      },
      method: 'GET',
      header: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data'
      }
    };

    try {
      const response = await techdevOption;

      if (
        response.status === 200 &&
        response.data.percent_diff !== 'Data insufficient'
      ) {
        object.techdev = response.data.percent_diff;
      }

      if (
        response.status === 200 &&
        response.data.percent_diff === 'Data insufficient'
      ) {
        object.techdev = 0;
      }
    } catch (error) {
      object.techdev = 0;
    }

    try {
      const response = await techdevResultOption;

      if (response.status === 200) {
        resultObject.techdev = response.data.result;
      }
    } catch (error) {
      resultObject.techdev = 'down';
    }

    const alljasanOption = {
      url: 'https://cpi.contax.ai/management_score/calculate_alljasan_realinterest_ratio_diff_quarter',
      params: {
        company_name: company,
        quarter: `${selectedYear}${selectedQuarter}`
      },
      method: 'GET',
      header: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data'
      }
    };

    const alljasanResultOption = {
      url: 'https://cpi.contax.ai/management_score/calculate_alljasan_realinterest_ratio_updown_quarter',
      params: {
        company_name: company,
        quarter: `${selectedYear}${selectedQuarter}`
      },
      method: 'GET',
      header: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data'
      }
    };

    try {
      const response = await alljasanOption;

      if (
        response.status === 200 &&
        response.data.percent_diff !== 'Data insufficient'
      ) {
        object.alljasan = response.data.percent_diff;
      }

      if (
        response.status === 200 &&
        response.data.percent_diff === 'Data insufficient'
      ) {
        object.alljasan = 0;
      }
    } catch (error) {
      object.alljasan = 0;
    }

    try {
      const response = await alljasanResultOption;

      if (response.status === 200) {
        resultObject.alljasan = response.data.result;
      }
    } catch (error) {
      resultObject.alljasan = 'down';
    }

    const profitOption = {
      url: 'https://cpi.contax.ai/management_score/calculate_operating_profit_interest_ratio_diff_quarter',
      params: {
        company_name: company,
        quarter: `${selectedYear}${selectedQuarter}`
      },
      method: 'GET',
      header: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data'
      }
    };

    const profitResultOption = {
      url: 'https://cpi.contax.ai/management_score/calculate_operating_profit_interest_ratio_updown_quarter',
      params: {
        company_name: company,
        quarter: `${selectedYear}${selectedQuarter}`
      },
      method: 'GET',
      header: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data'
      }
    };

    try {
      const response = await profitOption;

      if (
        response.status === 200 &&
        response.data.percent_diff !== 'Data insufficient'
      ) {
        object.profit = response.data.percent_diff;
      }

      if (
        response.status === 200 &&
        response.data.percent_diff === 'Data insufficient'
      ) {
        object.profit = 0;
      }
    } catch (error) {
      object.alljasan = 0;
    }

    try {
      const response = await profitResultOption;

      if (response.status === 200) {
        resultObject.profit = response.data.result;
      }
    } catch (error) {
      resultObject.alljasan = 'down';
    }

    const incomeOption = {
      url: 'https://cpi.contax.ai/management_score/calculate_operating_income_margin_ratio_diff_quarter',
      params: {
        company_name: company,
        quarter: `${selectedYear}${selectedQuarter}`
      },
      method: 'GET',
      header: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data'
      }
    };

    const incomeResultOption = {
      url: 'https://cpi.contax.ai/management_score/calculate_operating_income_margin_ratio_updown_quarter',
      params: {
        company_name: company,
        quarter: `${selectedYear}${selectedQuarter}`
      },
      method: 'GET',
      header: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data'
      }
    };

    try {
      const response = await incomeOption;

      if (
        response.status === 200 &&
        response.data.percent_diff !== 'Data insufficient'
      ) {
        object.income = response.data.percent_diff;
      }

      if (
        response.status === 200 &&
        response.data.percent_diff === 'Data insufficient'
      ) {
        object.income = 0;
      }
    } catch (error) {
      object.income = 0;
    }

    try {
      const response = await incomeResultOption;

      if (response.status === 200) {
        resultObject.income = response.data.result;
      }
    } catch (error) {
      resultObject.income = 'down';
    }

    setDiff(object);
    setResult(resultObject);
  }

  useEffect(() => {
    if (uploadedQuarter === 'None') {
      return false;
    } else {
      setSelectedYear(uploadedQuarter.substring(0, 4));
      setSelectedQuarter(uploadedQuarter.substring(4, 6));
    }
  }, [uploadedQuarter]);

  const [siljil, setSiljil] = useState([
    { category: '실질자본금', value: 0, id: 0 },
    { category: '자산총계', value: 0, id: 1 },
    { category: '부채총계', value: 0, id: 2 },
    { category: '자본총계', value: 0, id: 3 },
    { category: '인정자산', value: 0, id: 4 },
    { category: '불인정자산', value: 0, id: 5 }
  ]);

  const [targetId, setTargetId] = useState(0);
  const [expression, setExpression] = useState();

  useEffect(() => {
    getGyejeongList();
  }, [originalRow]);

  function calculateRatio(status) {
    let value1 = 0;
    let value2 = 0;

    let ratio = 0;

    gyejeongRow.forEach((item) => {
      if (status === '유동비율') {
        if (item.category === '유동자산') {
          value1 = item.value;
        }

        if (item.category === '유동부채') {
          value2 = item.value;
        }

        if (
          Number.isNaN(value1 / value2) ||
          value1 / value2 === Infinity ||
          (value1 === 0 && value2 === 0)
        ) {
          ratio = 0;
        } else {
          ratio = (value1 / value2) * 100;
        }
      }

      if (status === '부채비율') {
        if (item.category === '부채총계') {
          value1 = item.value;
        }

        if (item.category === '자본총계') {
          value2 = item.value;
        }

        if (
          Number.isNaN(value1 / value2) ||
          value1 / value2 === Infinity ||
          (value1 === 0 && value2 === 0)
        ) {
          ratio = 0;
        } else {
          ratio = (value1 / value2) * 100;
        }
      }

      if (status === '차입금의존도') {
        if (item.category === '차입금') {
          value1 = item.value;
        }

        if (item.category === '자산총계') {
          value2 = item.value;
        }

        if (
          Number.isNaN(value1 / value2) ||
          value1 / value2 === Infinity ||
          (value1 === 0 && value2 === 0)
        ) {
          ratio = 0;
        } else {
          ratio = (value1 / value2) * 100;
        }
      }

      if (status === '영업이익대비 이자보상배율') {
        if (item.category === '영업이익' || item.category === '영업손실') {
          if (item.value !== 0) {
            value1 = item.value;
          }
        }

        if (item.category === '이자비용') {
          value2 = item.value;
        }

        if (
          Number.isNaN(value1 / value2) ||
          value1 / value2 === Infinity ||
          (value1 === 0 && value2 === 0)
        ) {
          ratio = 0;
        } else {
          ratio = -(value1 / value2);
        }
      }

      if (status === '매출액영업이익율') {
        if (item.category === '영업이익' || item.category === '영업손실') {
          if (item.value !== 0) {
            value1 = item.value;
          }
        }

        if (item.category === '매출액') {
          value2 = item.value;
        }

        if (
          Number.isNaN(value1 / value2) ||
          value1 / value2 === Infinity ||
          (value1 === 0 && value2 === 0)
        ) {
          ratio = 0;
        } else {
          ratio = -(value1 / value2) * 100;
        }
      }

      if (status === '매출액순이익율') {
        if (item.category === '당기순이익' || item.category === '당기순손실') {
          if (item.value !== 0) {
            value1 = item.value;
          }
        }

        if (item.category === '매출액') {
          value2 = item.value;
        }

        if (
          Number.isNaN(value1 / value2) ||
          value1 / value2 === Infinity ||
          (value1 === 0 && value2 === 0)
        ) {
          ratio = 0;
        } else {
          ratio = -(value1 / value2) * 100;
        }
      }

      if (status === '총자산순이익율') {
        if (item.category === '당기순이익' || item.category === '당기순손실') {
          if (item.value !== 0) {
            value1 = item.value;
          }
        }

        if (item.category === '자산총계') {
          value2 = item.value;
        }

        if (
          Number.isNaN(value1 / value2) ||
          value1 / value2 === Infinity ||
          (value1 === 0 && value2 === 0)
        ) {
          ratio = 0;
        } else {
          ratio = -(value1 / value2) * 100;
        }
      }

      if (status === '총자산대비영업현금흐름비율') {
        if (item.category === '영업이익' || item.category === '영업손실') {
          if (item.value !== 0) {
            value1 = item.value;
          }
        }

        if (item.category === '자산총계') {
          value2 = item.value;
        }

        if (
          Number.isNaN(value1 / value2) ||
          value1 / value2 === Infinity ||
          (value1 === 0 && value2 === 0)
        ) {
          ratio = 0;
        } else {
          ratio = -(value1 / value2) * 100;
        }
      }

      if (status === '최근년도 자산회전율') {
        if (item.category === '매출액') {
          value1 = item.value;
        }

        if (item.category === '자산총계') {
          value2 = item.value;
        }

        if (
          Number.isNaN(value1 / value2) ||
          value1 / value2 === Infinity ||
          (value1 === 0 && value2 === 0)
        ) {
          ratio = 0;
        } else {
          ratio = value1 / value2;
        }
      }

      if (status === '건설기술개발투자비율') {
        if (item.category === '건설기술투자비') {
          value1 = item.value;
        }

        if (item.category === '건설부분총매출') {
          value2 = item.value;
        }

        if (
          Number.isNaN(value1 / value2) ||
          value1 / value2 === Infinity ||
          (value1 === 0 && value2 === 0)
        ) {
          ratio = 0;
        } else {
          ratio = (value1 / value2) * 100;
        }
      }
    });

    return ratio;
  }

  useEffect(() => {
    if (company !== '') {
      getGyejeongList();
      getGyejeongGwamok(company);
      getDiff(company);
    }
  }, [selectedYear, selectedQuarter]);

  function removeDuplicatesByKey(array, key) {
    const seen = new Set();
    return array.filter((item) => {
      return seen.has(item[key]) ? false : seen.add(item[key]);
    });
  }

  useEffect(() => {
    const localMember = localStorage.getItem('assignment_member');
    const member = localMember.split(',').map((item) => item.trim());

    getUserData(member);
    getExpression();
    loadReview();
  }, []);

  async function getExpression() {
    const option = {
      url: 'https://cpi.contax.ai/mng_avg_expression',
      method: 'GET',
      header: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data'
      }
    };

    await axios(option).then((response) => {
      if (response.status === 200) {
        setExpression(response.data);
      }
    });
  }

  async function getFileUpload(company) {
    const option = {
      url: 'https://cpi.contax.ai/load_file_upload',
      params: {
        company_name: company
      },
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    };

    try {
      const response = await axios(option);
      if (response.status === 200) {
        return response.data.data.length > 0;
      } else {
        return false;
      }
    } catch (error) {
      console.error('Error in getFileUpload:', error);
      return false;
    }
  }

  async function getUserData(member) {
    let data = [];
    const userData = [];
    let accessData = [];
    const memberAdminData = [];
    const contentAdminData = [];

    const conbiOption = {
      url: 'https://cpi.contax.ai/members/all_members',
      method: 'GET',
      header: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    };

    const accessOption = {
      url: 'https://cpi.contax.ai/access',
      method: 'GET',
      header: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    };

    await axios(conbiOption).then((response) => {
      if (response.status === 200) {
        data = response.data;
      }
    });

    await axios(accessOption).then((response) => {
      if (response.status === 200) {
        accessData = response.data;
      }
    });

    let index = 0;

    for (const item of data) {
      let isAdmin = false;
      for (const jtem of accessData) {
        if (item.user_id === jtem.user_id) {
          if (jtem.access && jtem.access.length > 0) {
            const newObj = {
              ...item,
              is_admin: 'O',
              access: jtem.access,
              assignment: []
            };
            contentAdminData.push(newObj);
          }

          if (jtem.assignment_member === null) {
            const newObj = {
              ...item,
              is_admin: 'O',
              access: [],
              assignment: jtem.assignment_member
            };
            memberAdminData.push(newObj);
          }

          isAdmin = true;
        }
      }

      if (!isAdmin) {
        const object = {
          ...item,
          subscription_type: '미가입',
          subscription_end: '-'
        };
        const is_uploaded = await getFileUpload(item.company_name);
        object.is_uploaded = is_uploaded;
        userData.push(object);
      }

      index++;
    }

    const assignmentArray = [];
    const anotherCheckedData = [...data];

    index = 0;

    for (const item of data) {
      for (const jtem of member) {
        // 객체 확장 가능성을 확인하고, 필요하다면 새 객체를 생성
        if (!Object.isExtensible(anotherCheckedData[item.user_id])) {
          anotherCheckedData[item.user_id] = {
            ...anotherCheckedData[item.user_id]
          };
        }

        anotherCheckedData[item.user_id].id =
          anotherCheckedData[item.user_id].user_id;

        if (item.user_id === Number(jtem)) {
          const is_uploaded = await getFileUpload(item.company_name);
          data[index].is_uploaded = is_uploaded;
          assignmentArray.push(item);
        }
      }
      index++;
    }

    let unmatchIndex = 0;

    for (const item of assignmentArray) {
      const unmatchOption = {
        url: 'https://cpi.contax.ai/load_unmatch_count',
        params: {
          company_name: item.company_name
        },
        method: 'GET',
        header: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data'
        }
      };

      await axios(unmatchOption).then((response) => {
        if (response.status === 200) {
          assignmentArray[unmatchIndex].unmatchLength = response.data.sum;
        }
      });

      unmatchIndex++;
    }

    setAssignmentData(assignmentArray);
  }

  async function insertNotification(text) {
    const body = {
      notification_type: 'jaemu',
      title: text + '가 업로드 되었습니다.',
      content: '클릭하여 확인해보세요',
      target: userIdList
    };

    const option = {
      url: 'https://cpi.contax.ai/insert_notification',
      method: 'POST',
      data: body,
      header: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    };

    axios(option).then((response) => {
      if (response.status === 200) {
        return true;
      } else {
      }
    });
  }

  function formatNumberWithCommas(num) {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  async function sendNotification(paramsToken, text) {
    const body = {
      title: text + '가 업로드 되었습니다.',
      body: '클릭하여 확인해보세요',
      token: paramsToken,
      data: {
        route: 'jaemu'
      }
    };

    const option = {
      url: 'https://cpi.contax.ai/send_notification',
      method: 'POST',
      data: body,
      header: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    };

    axios(option).then((response) => {
      if (response.status === 200) {
        return true;
      } else {
      }
    });
  }

  async function uploadSonikFile(e, params_company) {
    const formData = new FormData();
    formData.append('file', e.target.files[0]);

    const option = {
      url: 'https://cpi.contax.ai/sonik_excel_upload',
      params: {
        company_name: params_company
      },
      data: formData,
      method: 'POST',
      header: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data'
      }
    };

    await axios(option)
      .then((response) => {
        if (response.status === 200) {
          alert('파일이 업로드되었습니다.');
          getGyejeongGwamok(params_company);
          insertNotification('손익계산서');
          token.forEach((item) => {
            sendNotification(item, '손익계산서');
          });
        }
      })
      .catch((e) => {
        if (e.code === 'ERR_NETWORK') {
          alert('파일 형식이 잘못되었습니다.');
        }
      });
  }

  async function getGyejeongGwamok(company) {
    const array = [];
    const option = {
      url: 'https://cpi.contax.ai/jaemu_data_by_company',
      params: {
        company_name: company
      },
      method: 'GET',
      header: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data'
      }
    };

    try {
      const response = await axios(option);

      if (response.status === 200) {
        response.data.forEach((item, index) => {
          array.push({ ...item, id: index });
        });
      }
    } catch (e) {}

    const sonikOption = {
      url: 'https://cpi.contax.ai/sonik_data_by_company',
      params: {
        company_name: company
      },
      method: 'GET',
      header: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data'
      }
    };

    try {
      const response = await axios(sonikOption);

      if (response.status === 200) {
        response.data.forEach((item, index) => {
          array.push({ ...item, id: index });
        });
      }
    } catch (e) {}

    const siljiloption = {
      url: 'https://cpi.contax.ai/management_score/calculate_real_capital_quarter',
      params: {
        company_name: company,
        quarter: selectedYear + '' + selectedQuarter
      },
      method: 'GET',
      header: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data'
      }
    };

    setSiljil([
      { category: '실질자본금', value: 0, id: 0 },
      { category: '자산총계', value: 0, id: 1 },
      { category: '부채총계', value: 0, id: 2 },
      { category: '자본총계', value: 0, id: 3 },
      { category: '인정자산', value: 0, id: 4 },
      { category: '불인정자산', value: 0, id: 5 }
    ]);

    try {
      const response = await axios(siljiloption);
      if (response.status === 200) {
        const item = response.data;

        setSiljil([
          { category: '실질자본금', value: item.real_capital, id: 0 },
          { category: '자산총계', value: item.total_assets, id: 1 },
          { category: '부채총계', value: item.total_dept, id: 2 },
          { category: '자본총계', value: item.capital_total, id: 3 },
          {
            category: '인정자산',
            value: item.recognized_assets,
            id: 4
          },
          {
            category: '불인정자산',
            value: item.unrecognized_assets_sum,
            id: 5
          }
        ]);
      }
    } catch (e) {
      setSiljil([
        { category: '실질자본금', value: 0, id: 0 },
        { category: '자산총계', value: 0, id: 1 },
        { category: '부채총계', value: 0, id: 2 },
        { category: '자본총계', value: 0, id: 3 },
        { category: '인정자산', value: 0, id: 4 },
        { category: '불인정자산', value: 0, id: 5 }
      ]);
    }

    setOriginalRow(array);
  }

  async function getComment(pyeongjeom) {
    const id = localStorage.getItem('id');

    const option = {
      url: 'https://cpi.contax.ai/load_tax_manager_comment',
      params: {
        writter_id: id,
        pyeongjeom: pyeongjeom,
        target_user_id: targetId,
        quarter: selectedYear + '' + selectedQuarter
      },
      method: 'GET',
      header: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data'
      }
    };

    await axios(option).then((response) => {
      console.log(response.data);
    });
  }

  function getGyejeongList() {
    let array = [];
    array = addGyejeongGwamok([
      '유동자산',
      '당좌자산',
      '현금',
      '보통예금',
      '기타제예금',
      '공사미수금',
      '가지급금',
      '단기대여금',
      '재고자산',
      '유동부채',
      '외상매입금',
      '미지급금',
      '예수금',
      '가수금',
      '미지급세금',
      '공사선수금',
      '부채총계',
      '유동부채',
      '외상매입금',
      '미지급금',
      '예수금',
      '가수금',
      '비유동부채',
      '자본총계',
      '자본금',
      '자본잉여금',
      '자본조정',
      '기타포괄손익누계액',
      '이익잉여금',
      '미처분이익잉여금',
      '차입금',
      '자산총계',
      '이자비용',
      '영업손실',
      '매출액',
      '당기순손실',
      '영업손실',
      '매출액',
      '건설기술투자비',
      '건설부분총매출',
      '인정자산',
      '불인정자산',
      '영업이익',
      '당기순이익'
    ]);
    setGyejeongRow(removeDuplicatesByKey(array, 'category'));
  }

  function addGyejeongGwamok(gyejeongList) {
    const array = [];
    let index = 0;

    const quarterEndMonths = {
      '01': '03/31',
      '02': '06/30',
      '03': '09/30',
      '04': '12/31'
    };

    const quarterEndDate = `${selectedYear}/${quarterEndMonths[selectedQuarter]}`;

    const nonMatchingCategories = [];

    // Iterate through each item in the gyejeongList
    gyejeongList.forEach((jtem) => {
      let found = false;

      // Check if there is a matching category in gyejeongRow
      originalRow.forEach((item) => {
        if (jtem === item.category && item.period.endsWith(quarterEndDate)) {
          array.push(item);
          found = true;
          item.id = gyejeongList.length + index;
        }
      });

      if (!found) {
        nonMatchingCategories.push(jtem);

        const object = {
          category: jtem,
          value: 0,
          id: gyejeongList.length + index
        };

        if (selectedQuarter === '01') {
          object.period = `${selectedYear}/01/01 ~ ${selectedYear}/03/31`;
        }

        if (selectedQuarter === '02') {
          object.period = `${selectedYear}/01/01 ~ ${selectedYear}/06/30`;
        }

        if (selectedQuarter === '03') {
          object.period = `${selectedYear}/01/01 ~ ${selectedYear}/09/30`;
        }

        if (selectedQuarter === '04') {
          object.period = `${selectedYear}/01/01 ~ ${selectedYear}/12/31`;
        }

        array.push(object);
      }

      index++;
    });

    return array;
  }

  async function uploadJaemuFile(e, params_company) {
    const formData = new FormData();
    formData.append('file', e.target.files[0]);

    const option = {
      url: 'https://cpi.contax.ai/jaemu_excel_upload',
      params: {
        company_name: params_company
      },
      data: formData,
      method: 'POST',
      header: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data'
      }
    };

    await axios(option)
      .then((response) => {
        if (response.status === 200) {
          alert('파일이 업로드되었습니다.');
          getGyejeongGwamok(params_company);
          insertNotification('재무제표');
          token.forEach((item) => {
            sendNotification(item, '재무제표');
          });
        }
      })
      .catch((e) => {
        if (e.code === 'ERR_NETWORK') {
          alert('파일 형식이 잘못되었습니다.');
        }
      });
  }

  async function loadOverallReview() {
    const id = localStorage.getItem('id');
    const reviewOption = {
      url: 'https://cpi.contax.ai/load_tax_manager_review',
      method: 'GET',
      params: {
        writter_id: id,
        quarter: selectedYear + '' + selectedQuarter,
        target_user_id: targetId
      },
      header: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data'
      }
    };

    await axios(reviewOption).then((response) => {
      if (response.status === 200) {
        if (response.data.overall_review !== undefined) {
          setComment(response.data.overall_review);
        } else {
          setComment('');
        }
      }
    });
  }

  async function insertOverallReview(review) {
    const id = localStorage.getItem('id');
    const name = localStorage.getItem('name');

    const reviewOption = {
      url: 'https://cpi.contax.ai/insert_tax_overall_review',
      method: 'POST',
      data: {
        writter_id: id,
        writter_name: name,
        overall_review: review,
        quarter: selectedYear + '' + selectedQuarter,
        target_user_id: targetId,
        target_user_name: targetName
      },
      header: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data'
      }
    };

    await axios(reviewOption).then((response) => {
      if (response.status === 200) {
        alert('성공적으로 저장되었습니다.');
        setModalOn({ on: false, division: 'overall' });
      }
    });
  }

  const Grid_COL = [
    { title: '번호', width: '10', textAlign: 'left' },
    { title: '이름', width: '10', textAlign: 'left' },
    { title: '이메일', width: '15', textAlign: 'left' },
    { title: '회사', width: '15', textAlign: 'left' },
    { title: '휴대폰 번호', width: '15', textAlign: 'left' },
    { title: '사업자 번호', width: '10', textAlign: 'left' },
    { title: '상태', width: '10', textAlign: 'center' },
    { title: '업로드', width: '15', textAlign: 'left' }
  ];

  const [open, setOpen] = useState({ index: 0, open: false });
  const [modalOn, setModalOn] = useState({ division: '', on: false });
  const [selectedPyeongjeom, setSelectedPyeongjeom] = useState('');
  const [targetName, setTargetName] = useState('');

  async function getQuarter(company) {
    const option = {
      url: 'https://cpi.contax.ai/get_quarter',
      params: {
        company: company
      },
      method: 'GET',
      header: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data'
      }
    };

    await axios(option).then((response) => {
      setUploadedQuarter(response.data[1]);
    });
  }

  function openDetailSection(index, item) {
    if (index === open.index) {
      if (open.open) {
        setOpen({ index: index, open: false });
      }

      if (!open.open) {
        setOpen({ index: index, open: true });
      }
    }

    if (index !== open.index) {
      setOpen({ index: index, open: true });
    }

    getQuarter(item.company_name);
    setTargetName(item.name);
    setTargetId(item.user_id);
    setCompany(item.company_name);
    getDiff(item.company_name);
    getGyejeongGwamok(item.company_name);
  }

  function modalOpen(division, name) {
    const object = { ...modalOn };
    object.division = division;
    object.on = true;
    setModalOn(object);
    setSelectedPyeongjeom(name);

    if (division === 'comment') {
      getComment(name);
    }
  }

  return (
    <>
      {modalOn.on === true && modalOn.division === 'gyejeong' && (
        <GyejeongModal
          uploadedQuarter={uploadedQuarter}
          expression={expression}
          gyejeongRow={
            selectedPyeongjeom === '실질자본금' ? siljil : gyejeongRow
          }
          title={selectedPyeongjeom}
          setModalOn={() => {
            const object = { ...modalOn };
            object.on = false;
            setModalOn(object);
          }}
        />
      )}

      {modalOn.on === true && modalOn.division === 'comment' && (
        <CommentModal
          review={review}
          targetName={targetName}
          targetId={targetId}
          quarter={selectedYear + '' + selectedQuarter}
          gyejeongRow={gyejeongRow}
          title={selectedPyeongjeom}
          setModalOn={() => {
            const object = { ...modalOn };
            object.on = false;
            setModalOn(object);
          }}
        />
      )}

      {modalOn.on === true && modalOn.division === 'overall' && (
        <OverallModal
          comment={comment}
          insertReview={(params) => {
            insertOverallReview(params);
          }}
          setModalOn={() => {
            const object = { ...modalOn };
            object.on = false;
            setModalOn(object);
          }}
        />
      )}

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TitleCard title="세무 유저 현황">
            <ManagerGrid col={Grid_COL}>
              {assignmentData.map((item, index) => {
                return (
                  <>
                    <ManagerGridRowSection>
                      <ManagerGridRow
                        onClick={() => {
                          openDetailSection(index, item);
                        }}
                        width="10"
                      >
                        {item.user_id}
                      </ManagerGridRow>
                      <ManagerGridRow
                        onClick={() => {
                          openDetailSection(index, item);
                        }}
                        width="10"
                      >
                        {item.name}
                      </ManagerGridRow>
                      <ManagerGridRow
                        onClick={() => {
                          openDetailSection(index, item);
                        }}
                        width="15"
                      >
                        {item.email}
                      </ManagerGridRow>
                      <ManagerGridRow
                        onClick={() => {
                          openDetailSection(index, item);
                        }}
                        width="15"
                      >
                        {item.company_name}
                      </ManagerGridRow>
                      <ManagerGridRow
                        onClick={() => {
                          openDetailSection(index, item);
                        }}
                        width="15"
                      >
                        {item.phone_number}
                      </ManagerGridRow>
                      <ManagerGridRow
                        onClick={() => {
                          openDetailSection(index, item);
                        }}
                        width="10"
                      >
                        {item.business_number}
                      </ManagerGridRow>
                      {item.is_uploaded === false ? (
                        <BeforeUploadGridRow
                          onClick={() => {
                            openDetailSection(index, item);
                          }}
                          width="10"
                          textAlign="center"
                        />
                      ) : (
                        <CompleteUploadGridRow
                          onClick={() => {
                            openDetailSection(index, item);
                          }}
                          width="10"
                          textAlign="center"
                        />
                      )}
                      <ManagerGridRow width="15">
                        <Button
                          sx={{
                            background: '#1976d2'
                          }}
                          component="label"
                          variant="contained"
                          onChange={(e) => {
                            uploadJaemuFile(e, item.company_name);
                          }}
                        >
                          재무
                          <VisuallyHiddenInput
                            type="file"
                            accept=".xlsx, .xls"
                          />
                        </Button>
                        <Button
                          sx={{
                            background: '#1976d2'
                          }}
                          component="label"
                          variant="contained"
                          onChange={(e) => {
                            uploadSonikFile(e, item.company_name);
                          }}
                        >
                          손익
                          <VisuallyHiddenInput
                            type="file"
                            accept=".xlsx, .xls"
                          />
                        </Button>
                      </ManagerGridRow>
                    </ManagerGridRowSection>

                    <ManagerGridDetailSection
                      open={open.index === index && open.open === true}
                    >
                      <SelectQuarterSection>
                        <select
                          style={{
                            width: '95px',
                            fontWeight: 'bold',
                            fontSize: '20px'
                          }}
                          value={selectedYear}
                          onChange={(e) => {
                            getGyejeongList();
                            setSelectedYear(e.target.value);
                          }}
                          defaultValue="2022"
                        >
                          <option value="2022">2022년</option>
                          <option value="2023">2023년</option>
                          <option value="2024">2024년</option>
                        </select>

                        <select
                          style={{
                            width: '95px',
                            fontWeight: 'bold',
                            fontSize: '20px'
                          }}
                          value={selectedQuarter}
                          onChange={(e) => {
                            getGyejeongList();
                            setSelectedQuarter(e.target.value);
                          }}
                          defaultValue="01"
                        >
                          <option value="01">1분기</option>
                          <option value="02">2분기</option>
                          <option value="03">3분기</option>
                          <option value="04">4분기</option>
                        </select>

                        <span
                          style={{
                            fontSize: '14px',
                            color: '#555'
                          }}
                        >
                          최근 업로드 :{' '}
                          {uploadedQuarter.substring(0, 4) +
                            '년 ' +
                            uploadedQuarter.substring(5, 6) +
                            '분기'}
                        </span>
                      </SelectQuarterSection>

                      <PyeongjeomPropertySection>
                        <PyeongjeomProperty
                          setModalOn={(division) => {
                            modalOpen(division, '실질자본금');
                          }}
                          name="실질자본금"
                          ratio={formatNumberWithCommas(siljil[0].value)}
                        />

                        <PyeongjeomProperty
                          setModalOn={(division) => {
                            modalOpen(division, '유동비율');
                          }}
                          ratio={calculateRatio('유동비율')}
                          diff={diff.yudong}
                          result={result.yudong}
                          name="유동비율"
                        />
                        <PyeongjeomProperty
                          setModalOn={(division) => {
                            modalOpen(division, '부채비율');
                          }}
                          ratio={calculateRatio('부채비율')}
                          result={result.buchae}
                          diff={diff.buchae}
                          name="부채비율"
                        />

                        <PyeongjeomProperty
                          setModalOn={(division) => {
                            modalOpen(division, '차입금의존도');
                          }}
                          ratio={calculateRatio('차입금의존도')}
                          result={result.chaIp}
                          diff={diff.chaIp}
                          name="차입금의존도"
                        />

                        <PyeongjeomProperty
                          setModalOn={(division) => {
                            modalOpen(division, '영업이익대비 이자보상배율');
                          }}
                          ratio={calculateRatio('영업이익대비 이자보상배율')}
                          diff={diff.profit}
                          result={result.profit}
                          name="영업이익대비 이자보상배율"
                        />

                        <PyeongjeomProperty
                          setModalOn={(division) => {
                            modalOpen(division, '매출액영업이익율');
                          }}
                          ratio={calculateRatio('매출액영업이익율')}
                          diff={diff.maechul}
                          result={result.maechul}
                          name="매출액영업이익율"
                        />

                        <PyeongjeomProperty
                          setModalOn={(division) => {
                            modalOpen(division, '매출액순이익율');
                          }}
                          ratio={calculateRatio('매출액순이익율')}
                          diff={diff.income}
                          result={result.income}
                          name="매출액순이익율"
                        />
                        <PyeongjeomProperty
                          setModalOn={(division) => {
                            modalOpen(division, '총자산순이익율');
                          }}
                          ratio={calculateRatio('총자산순이익율')}
                          name="총자산순이익율"
                          diff={diff.alljasan}
                          result={result.alljasan}
                        />

                        <PyeongjeomProperty
                          setModalOn={(division) => {
                            modalOpen(division, '총자산대비영업현금흐름비율');
                          }}
                          ratio={calculateRatio('총자산대비영업현금흐름비율')}
                          diff={diff.cashflow}
                          result={result.cashflow}
                          name="총자산대비영업현금흐름비율"
                        />

                        <PyeongjeomProperty
                          setModalOn={(division) => {
                            modalOpen(division, '최근년도 자산회전율');
                          }}
                          ratio={calculateRatio('최근년도 자산회전율')}
                          diff={diff.jasanTurning}
                          result={result.jasanTurning}
                          name="최근년도 자산회전율"
                        />

                        <PyeongjeomProperty
                          setModalOn={(division) => {
                            modalOpen(division, '건설기술개발투자비율');
                          }}
                          ratio={calculateRatio('건설기술개발투자비율')}
                          diff={diff.techdev}
                          name="건설기술개발투자비율"
                          result={result.techdev}
                        />

                        <St_PyeongjeomProperty
                          onClick={() => {
                            setModalOn({
                              on: true,
                              division: 'overall'
                            });
                            loadOverallReview();
                          }}
                        >
                          <St_PyeongjeomPropertyName>
                            <span className="material-symbols-outlined">
                              add_circle
                            </span>
                            <span>총평 입력</span>
                          </St_PyeongjeomPropertyName>
                        </St_PyeongjeomProperty>
                      </PyeongjeomPropertySection>
                    </ManagerGridDetailSection>
                  </>
                );
              })}
            </ManagerGrid>
          </TitleCard>
        </Grid>
      </Grid>
    </>
  );
}

export default TaxManager;
