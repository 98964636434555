import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import ExpandMore from '../../../asset/expand_more_FILL0_wght400_GRAD0_opsz24.svg';
import ExpandeLess from '../../../asset/expand_less_FILL0_wght400_GRAD0_opsz24.svg';
import { Button, ListItemText, MenuItem, Select } from '@mui/material';
import Editor from '../../../components/Editor';

const St_SalesSection = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
`;

const StSearchButton = styled.button`
  width: 100px;
  height: 40px;
  border-radius: 5px;
  background-color: #999;
  color: #fff;
`;

const St_TableColumn = styled.div`
  font-size: 14px;
  text-align: center;
  font-weight: bold;
  padding: 5px;
  width: ${(props) => props.width + '%'};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const St_TableRow = styled.div`
  font-size: 14px;
  height: 100%;
  width: ${(props) => props.width + '%'};

  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  padding: 15px 5px;
  word-break: break-all;
`;

const St_FilterCategoryTitle = styled.span`
  font-size: 20px;
  font-weight: bold;
  width: 100%;
  display: inline-block;
  cursor: pointer;
`;

const St_TableRowSection = styled.div`
  display: flex;
  background-color: #eee;
  align-items: center;
  background-color: #fff;
  border-bottom: 1px solid #eee;
  width: 100%;
  &:hover {
    background-color: rgba(0, 0, 0, 0.03);
  }
`;

const St_TableColumnSection = styled.div`
  display: flex;
  height: 50px;
  background-color: #1976d2;
  color: #fff;
  align-items: center;
  width: 100%;
`;

const St_FilterCategorySection = styled.div`
  padding: 20px;
  padding-top: 0px;
  padding-left: 0px;
  display: ${(props) => (props.categoryOn === true ? 'flex' : 'none')};
  flex-direction: column;
  gap: 20px;
`;

const St_ManagerSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 60%;
  padding: 0 0px 0 10px;
`;

const St_FilterSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 30%;
  padding: 0 30px 0 10px;
`;

const St_TableSection = styled.div`
  width: 60%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #fff;
`;

const St_FilterRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const St_FilterRowTitle = styled.span`
  font-size: 1rem;
  margin-bottom: 5px;
`;

const St_FilterRowInput = styled.input`
  border: 1px solid #ddd;
  padding: 5px;
`;

const StRowDetailContent = styled.div`
  padding: 20px;
  border-left: 1px solid #eee;
  border-right: 1px solid #eee;
  border-bottom: 1px solid #eee;
  width: 100%;
  height: 400px;
  background-color: #fff;
  display: ${(props) => (props.detailOn === true ? 'grid' : 'none')};
  grid-template-columns: 1fr 5fr;
  gap: 5px;
`;

const RowDetailElement = styled.span`
  font-size: 12px;
`;

const StTitleSection = styled.div`
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  font-size: 18px;
`;

export function SalesSection({ children }) {
  return <St_SalesSection>{children}</St_SalesSection>;
}

export function FilterSection({ children }) {
  return <St_FilterSection>{children}</St_FilterSection>;
}

export function ManagerSection({ children }) {
  return <St_ManagerSection>{children}</St_ManagerSection>;
}

const St_TrashSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 0px 0 10px;
`;

export function TrashSection({ children }) {
  return <St_TrashSection>{children}</St_TrashSection>;
}

export function TableSection({ children }) {
  return <St_TableSection>{children}</St_TableSection>;
}

export function TableRowSection({ item, setCheckData, checkData, allCheck }) {
  const [detailOn, setDetailOn] = useState(false);
  const [check, setCheck] = useState(false);

  useEffect(() => {
    checkData.forEach((jtem) => {
      if (item.ncrmasternum === jtem.ncrmasternum) {
        setCheck(true);
      }
    });
  }, [checkData]);

  useEffect(() => {
    var ok = false;

    checkData.forEach((jtem) => {
      if (item.ncrmasternum === jtem.ncrmasternum) {
        ok = true;
      }
    });

    if (ok === true) {
      setCheck(true);
    } else {
      setCheck(false);
    }
  }, [item]);

  return (
    <>
      <St_TableRowSection>
        <TableRow
          width={10}
          onClick={() => {
            setCheckData(item);
            setCheck(!check);
          }}
        >
          <input
            type="checkbox"
            checked={check === true || allCheck === true}
          />
        </TableRow>
        <TableRow
          width={20}
          onClick={() => {
            setCheckData(item);
            check === false ? setCheck(true) : setCheck(false);
          }}
        >
          {item.ncrgskname}
        </TableRow>
        <TableRow
          width={15}
          onClick={() => {
            setCheckData(item);
            check === false ? setCheck(true) : setCheck(false);
          }}
        >
          {item.ncrgsmaster}
        </TableRow>
        <TableRow
          width={20}
          onClick={() => {
            setCheckData(item);
            check === false ? setCheck(true) : setCheck(false);
          }}
        >
          {item.ncrofftel}
        </TableRow>
        <TableRow
          width={25}
          onClick={() => {
            setCheckData(item);
            check === false ? setCheck(true) : setCheck(false);
          }}
        >
          {item.ncritemname}
        </TableRow>
        <TableRow
          width={10}
          onClick={() => {
            setDetailOn(!detailOn);
          }}
        >
          <button
            style={{
              backgroundColor: '#ddd',
              borderRadius: '4px',
              fontSize: '12px',
              padding: '5px'
            }}
          >
            상세보기
          </button>
        </TableRow>
      </St_TableRowSection>
      <StRowDetailContent data={item} detailOn={detailOn} />
    </>
  );
}

export function TrashRowSection({
  item,
  setCheckData,
  checkData,
  allCheck,
  name
}) {
  const [detailOn, setDetailOn] = useState(false);
  const [check, setCheck] = useState(false);

  useEffect(() => {
    checkData.forEach((jtem) => {
      if (item.ncrmasternum === jtem.ncrmasternum) {
        setCheck(true);
      }
    });
  }, [checkData]);

  useEffect(() => {
    var ok = false;

    checkData.forEach((jtem) => {
      if (item.ncrmasternum === jtem.ncrmasternum) {
        ok = true;
      }
    });

    if (ok === true) {
      setCheck(true);
    } else {
      setCheck(false);
    }
  }, [item]);

  return (
    <>
      <St_TableRowSection>
        <TableRow
          width={10}
          onClick={() => {
            setCheckData(item);
            setCheck(!check);
          }}
        >
          <input
            type="checkbox"
            checked={check === true || allCheck === true}
          />
        </TableRow>
        <TableRow
          width={20}
          onClick={() => {
            setCheckData(item);
            check === false ? setCheck(true) : setCheck(false);
          }}
        >
          {item.ncrgskname}
        </TableRow>
        <TableRow
          width={15}
          onClick={() => {
            setCheckData(item);
            check === false ? setCheck(true) : setCheck(false);
          }}
        >
          {item.ncrgsmaster}
        </TableRow>
        <TableRow
          width={15}
          onClick={() => {
            setCheckData(item);
            check === false ? setCheck(true) : setCheck(false);
          }}
        >
          {item.ncrofftel}
        </TableRow>
        <TableRow
          width={20}
          onClick={() => {
            setCheckData(item);
            check === false ? setCheck(true) : setCheck(false);
          }}
        >
          {item.ncritemname}
        </TableRow>
        <TableRow
          width={20}
          onClick={() => {
            setCheckData(item);
            check === false ? setCheck(true) : setCheck(false);
          }}
        >
          {name}
        </TableRow>
        <TableRow
          width={10}
          onClick={() => {
            setDetailOn(!detailOn);
          }}
        >
          <button
            style={{
              backgroundColor: '#ddd',
              borderRadius: '4px',
              fontSize: '12px',
              padding: '5px'
            }}
          >
            상세보기
          </button>
        </TableRow>
      </St_TableRowSection>
      <StRowDetailContent data={item} detailOn={detailOn} />
    </>
  );
}

export function MemoRowSection({ item }) {
  const [detailOn, setDetailOn] = useState(false);
  return (
    <>
      <St_TableRowSection>
        <TableRow width={10}>
          <input type="checkbox" />
        </TableRow>
        <TableRow width={20}>{item.ncrgskname}</TableRow>
        <TableRow width={15}>{item.ncrgsmaster}</TableRow>
        <TableRow width={20}>{item.ncrofftel}</TableRow>
        <TableRow width={25}>{item.ncritemname}</TableRow>

        <TableRow
          width={10}
          onClick={() => {
            setDetailOn(!detailOn);
          }}
        >
          <button
            style={{
              backgroundColor: '#ddd',
              borderRadius: '4px',
              fontSize: '12px',
              padding: '5px'
            }}
          >
            상세보기
          </button>
        </TableRow>
      </St_TableRowSection>
      <StRowDetailContent data={item} detailOn={detailOn} />
    </>
  );
}

export function TableManagerSection({ item, checkManager, setCheckManager }) {
  return (
    <>
      <St_TableRowSection
        onClick={() => {
          setCheckManager(item);
        }}
      >
        <TableRow width={20}>
          <input type="radio" checked={checkManager.user_id === item.user_id} />
        </TableRow>
        <TableRow width={80}>{item.name}</TableRow>
        <TableRow width={80}>{item.email}</TableRow>
        <TableRow width={80}>1차</TableRow>
      </St_TableRowSection>
    </>
  );
}

export function TableManagerMemoRowSection({
  item,
  setCheckData,
  checkData,
  adminArea
}) {
  const [check, setCheck] = useState(false);
  const [area, setArea] = useState('');
  const [areaDetail, setAreaDetail] = useState('');

  useEffect(() => {
    if (adminArea !== undefined) {
      adminArea.forEach((data) => {
        let areaString = '';
        let areaDetailString = '';

        if (data.name === item.name) {
          data.ncrareaname.forEach((jtem) => {
            areaString += jtem + ', ';
          });

          data.ncrareadetailname.forEach((jtem) => {
            areaDetailString += jtem + ', ';
          });

          setArea(areaString);
          setAreaDetail(areaDetailString);
        }
      });
    }
  }, [adminArea]);

  useEffect(() => {
    setCheck(false);
    if (item.user_id === checkData.user_id) {
      setCheck(true);
    }
  }, [checkData]);

  return (
    <>
      <St_TableRowSection>
        <TableRow
          width={10}
          onClick={() => {
            setCheckData(item);
          }}
        >
          <input type="radio" checked={check} />
        </TableRow>
        <TableRow
          width={30}
          onClick={() => {
            setCheckData(item);
            check === false ? setCheck(true) : setCheck(false);
          }}
        >
          {item.name}
        </TableRow>
        <TableRow
          width={20}
          onClick={() => {
            setCheckData(item);
            check === false ? setCheck(true) : setCheck(false);
          }}
        >
          {item.email}
        </TableRow>
        <TableRow
          width={20}
          onClick={() => {
            setCheckData(item);
            check === false ? setCheck(true) : setCheck(false);
          }}
        >
          {item.phone_number}
        </TableRow>
        <TableRow
          width={20}
          onClick={() => {
            setCheckData(item);
            check === false ? setCheck(true) : setCheck(false);
          }}
        >
          {area}
        </TableRow>
        <TableRow
          width={20}
          onClick={() => {
            setCheckData(item);
            check === false ? setCheck(true) : setCheck(false);
          }}
        >
          {areaDetail.replaceAll('-, ', '')}
        </TableRow>
      </St_TableRowSection>
    </>
  );
}

export function TableExpertRows({
  item,
  setSelectedDetailData,
  setSelectedData,
  selectedData,
  checkData,
  detailOn,
  setDetailOn
}) {
  const [check, setCheck] = useState(false);

  useEffect(() => {
    setCheck(false);
  }, [checkData]);

  function onClick() {
    const array = [...selectedData];
    let isOk = false;

    selectedData.forEach((jtem, jndex) => {
      if (jtem === item.id) {
        isOk = true;
        array.splice(jndex, 1);
      }
    });

    if (isOk === false) {
      array.push(item.id);
    }

    setSelectedData(array);

    check === false ? setCheck(true) : setCheck(false);
  }

  return (
    <>
      {detailOn === false && (
        <St_TableRowSection>
          <TableRow width={5} onClick={onClick}>
            <input type="checkbox" checked={check} />
          </TableRow>
          <TableRow width={10} onClick={onClick}>
            {item.id}
          </TableRow>
          <TableRow width={13} onClick={onClick}>
            {item.writter_name}
          </TableRow>
          <TableRow width={20} onClick={onClick}>
            {item.writter_email}
          </TableRow>
          <TableRow width={10} onClick={onClick}>
            {item.category}
          </TableRow>
          <TableRow width={20} onClick={onClick}>
            {item.subject}
          </TableRow>
          <TableRow width={12} onClick={onClick}>
            {item.question_date === undefined
              ? ''
              : item.question_date.substring(0, 10)}
          </TableRow>
          <TableRow width={10} onClick={onClick}>
            <button
              onClick={() => {
                setDetailOn(true);
                setSelectedDetailData(item);
              }}
              style={{
                backgroundColor: '#ddd',
                borderRadius: '4px',
                fontSize: '12px',
                padding: '5px 10px'
              }}
            >
              상세내용
            </button>
          </TableRow>
        </St_TableRowSection>
      )}
    </>
  );
}

export function TableThirdManagerRows({
  item,
  selectedData,
  setSelectedData,
  detailOn
}) {
  return (
    <>
      {detailOn === false && (
        <St_TableRowSection>
          <TableRow
            width={20}
            onClick={() => {
              if (item === selectedData) {
                setSelectedData('');
              } else {
                setSelectedData(item);
              }
            }}
          >
            <input
              type="checkbox"
              checked={
                selectedData !== undefined &&
                item.user_id === selectedData.user_id
              }
            />
          </TableRow>
          <TableRow
            width={40}
            onClick={() => {
              if (item === selectedData) {
                setSelectedData('');
              } else {
                setSelectedData(item);
              }
            }}
          >
            {item.name}
          </TableRow>
          <TableRow
            width={40}
            onClick={() => {
              if (item === selectedData) {
                setSelectedData('');
              } else {
                setSelectedData(item);
              }
            }}
          >
            {item.email}
          </TableRow>
        </St_TableRowSection>
      )}
    </>
  );
}

export function TableExpertAnswerRows({
  item,
  onClick,
  setSelectedData,
  checkData,
  detailOn,
  setDetailOn
}) {
  const [check, setCheck] = useState(false);

  useEffect(() => {
    setCheck(false);
  }, [checkData]);

  return (
    <>
      {detailOn === false && (
        <St_TableRowSection>
          <TableRow
            width={10}
            onClick={() => {
              check === false ? setCheck(true) : setCheck(false);
            }}
          >
            {item.id}
          </TableRow>
          <TableRow
            width={10}
            onClick={() => {
              check === false ? setCheck(true) : setCheck(false);
            }}
          >
            {item.writter_name}
          </TableRow>
          <TableRow
            width={15}
            onClick={() => {
              check === false ? setCheck(true) : setCheck(false);
            }}
          >
            {item.writter_email}
          </TableRow>
          <TableRow
            width={10}
            onClick={() => {
              check === false ? setCheck(true) : setCheck(false);
            }}
          >
            {item.expert_name}
          </TableRow>
          <TableRow
            width={10}
            onClick={() => {
              check === false ? setCheck(true) : setCheck(false);
            }}
          >
            {item.category}
          </TableRow>
          <TableRow
            width={20}
            onClick={() => {
              check === false ? setCheck(true) : setCheck(false);
            }}
          >
            {item.subject}
          </TableRow>
          <TableRow
            width={10}
            onClick={() => {
              check === false ? setCheck(true) : setCheck(false);
            }}
          >
            {item.answer_date === undefined
              ? ''
              : item.question_date.substring(0, 10)}
          </TableRow>
          <TableRow
            width={7.5}
            onClick={() => {
              check === false ? setCheck(true) : setCheck(false);
            }}
          >
            <button
              onClick={() => {
                setDetailOn(true);
                setSelectedData(item);
              }}
              style={{
                backgroundColor: '#ddd',
                borderRadius: '4px',
                fontSize: '12px',
                padding: '5px 10px'
              }}
            >
              상세내용
            </button>
          </TableRow>
          <TableRow
            width={7.5}
            onClick={() => {
              check === false ? setCheck(true) : setCheck(false);
            }}
          >
            <button
              onClick={() => {
                item.answer_status === 'pending' && onClick(item.id);
              }}
              style={
                item.answer_status === 'pending'
                  ? {
                      backgroundColor: '#ddd',
                      borderRadius: '4px',
                      fontSize: '12px',
                      padding: '5px 10px'
                    }
                  : {
                      backgroundColor: '#1976d2',
                      color: '#fff',
                      borderRadius: '4px',
                      fontSize: '12px',
                      padding: '5px 10px'
                    }
              }
            >
              {item.answer_status === 'pending' ? '승인' : '승인 완료'}
            </button>
          </TableRow>
        </St_TableRowSection>
      )}
    </>
  );
}

export function TableOnlyExpertAnswerRows({
  item,
  setTitle,
  setContent,
  setSelectedData,
  checkData,
  detailOn,
  setDetailOn
}) {
  const [check, setCheck] = useState(false);

  useEffect(() => {
    setCheck(false);
  }, [checkData]);

  return (
    <>
      {detailOn === false && (
        <St_TableRowSection>
          <TableRow
            width={10}
            onClick={() => {
              check === false ? setCheck(true) : setCheck(false);
            }}
          >
            {item.id}
          </TableRow>
          <TableRow
            width={10}
            onClick={() => {
              check === false ? setCheck(true) : setCheck(false);
            }}
          >
            {item.writter_name}
          </TableRow>
          <TableRow
            width={20}
            onClick={() => {
              check === false ? setCheck(true) : setCheck(false);
            }}
          >
            {item.writter_email}
          </TableRow>
          <TableRow
            width={10}
            onClick={() => {
              check === false ? setCheck(true) : setCheck(false);
            }}
          >
            {item.category}
          </TableRow>
          <TableRow
            width={20}
            onClick={() => {
              check === false ? setCheck(true) : setCheck(false);
            }}
          >
            {item.subject}
          </TableRow>
          <TableRow
            width={15}
            onClick={() => {
              check === false ? setCheck(true) : setCheck(false);
            }}
          >
            {item.answer_date === undefined
              ? ''
              : item.question_date.substring(0, 10)}
          </TableRow>
          <TableRow
            width={7.5}
            onClick={() => {
              check === false ? setCheck(true) : setCheck(false);
            }}
          >
            <button
              onClick={() => {
                setDetailOn(true);
                setTitle(item.answer_title);
                setContent(item.answer_contents);
                setSelectedData(item);
              }}
              style={{
                backgroundColor: '#ddd',
                borderRadius: '4px',
                fontSize: '12px',
                padding: '5px 10px'
              }}
            >
              작성
            </button>
          </TableRow>
          <TableRow
            width={7.5}
            onClick={() => {
              check === false ? setCheck(true) : setCheck(false);
            }}
          >
            <button
              style={
                item.answer_status === null
                  ? {
                      backgroundColor: '#ddd',
                      borderRadius: '4px',
                      fontSize: '12px',
                      padding: '5px 10px'
                    }
                  : item.answer_status === 'pending'
                    ? {
                        backgroundColor: 'green',
                        color: '#fff',
                        borderRadius: '4px',
                        fontSize: '12px',
                        padding: '5px 10px'
                      }
                    : {
                        backgroundColor: '#1976d2',
                        color: '#fff',
                        borderRadius: '4px',
                        fontSize: '12px',
                        padding: '5px 10px'
                      }
              }
            >
              {item.answer_status === null
                ? '답변 전'
                : item.answer_status === 'pending'
                  ? '전송중'
                  : '전송 완료'}
            </button>
          </TableRow>
        </St_TableRowSection>
      )}
    </>
  );
}

export function ExpertDetailContents({ item, setDetailOn }) {
  const Header = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
  `;

  const Title = styled.div`
    font-size: 20px;
    font-weight: bold;
  `;

  const Category = styled.div`
    font-size: 18px;
    color: #aaa;
    margin-bottom: 20px;
  `;

  const Content = styled.div`
    border-top: 1px solid #ddd;
    width: 100%;
    padding-top: 20px;
    margin-top: 20px;
    min-height: 300px;
  `;

  return (
    <>
      <Header onClick={setDetailOn}>
        <span
          style={{ fontSize: '30px' }}
          className="material-symbols-outlined"
        >
          chevron_left
        </span>
        <span style={{ fontSize: '16px', color: '#333' }}> 뒤로가기</span>
      </Header>

      {item.writter_to_expert === false && (
        <div style={{ padding: '30px', width: '100%' }}>
          <Category>{item.category}</Category>
          <Title>{item.subject}</Title>

          <Content dangerouslySetInnerHTML={{ __html: item.contents }} />
        </div>
      )}

      {item.writter_to_expert === true && (
        <div style={{ display: 'flex', gap: '50px', padding: '30px' }}>
          <div style={{ width: '50%' }}>
            <Category>{item.category}</Category>
            <Title>{item.subject}</Title>

            <Content>{item.contents}</Content>
          </div>

          <div style={{ width: '50%' }}>
            <Category>{item.category}</Category>
            <Title>{item.answer_title}</Title>

            <Content
              dangerouslySetInnerHTML={{
                __html: item.answer_contents
              }}
            />
          </div>
        </div>
      )}
    </>
  );
}

export function OnlyExpertDetailContents({
  updateAnswer,
  setSelectedDetailData,
  item,
  setDetailOn,
  title,
  setTitle,
  content,
  setContent
}) {
  const Header = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
  `;

  const Title = styled.div`
    font-size: 20px;
    font-weight: bold;
  `;

  const Category = styled.div`
    font-size: 18px;
    color: #aaa;
    margin-bottom: 20px;
  `;

  const Content = styled.div`
    border-top: 1px solid #ddd;
    width: 100%;
    padding-top: 20px;
    margin-top: 20px;
    min-height: 300px;
  `;

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <Header onClick={setDetailOn}>
        <span
          style={{ fontSize: '30px' }}
          className="material-symbols-outlined"
        >
          chevron_left
        </span>
        <span style={{ fontSize: '16px', color: '#333' }}> 뒤로가기</span>
      </Header>

      {item.writter_to_expert === true && (
        <div style={{ display: 'flex', gap: '50px', padding: '30px' }}>
          <div style={{ width: '50%' }}>
            <Category>{item.category}</Category>
            <Title>{item.subject}</Title>

            <Content>{item.contents}</Content>
          </div>

          <div style={{ width: '50%' }}>
            <Category>{item.category}</Category>
            <input
              placeholder="제목을 입력해주세요."
              value={title}
              onChange={(e) => {
                setTitle(e.target.value);
                const object = {
                  ...item,
                  answer_title: e.target.value
                };
                setSelectedDetailData(object);
              }}
              style={{
                padding: '10px',
                fontSize: '20px',
                fontWeight: 'bold',
                width: '100%',
                marginBottom: '20px',
                border: '1px solid #ddd'
              }}
              type="text"
            />
            <Editor
              value={content}
              setValue={(data) => {
                setContent(data);
                const object = {
                  ...item,
                  answer_contents: data
                };
                setSelectedDetailData(object);
              }}
            />
          </div>
        </div>
      )}

      <Button
        onClick={() => {
          setDetailOn();
          updateAnswer(item.id);
        }}
        sx={{ width: '120px', marginLeft: 'auto' }}
        variant="contained"
      >
        저장
      </Button>
    </div>
  );
}

export function TableManagerContactRowSection({
  item,
  setCheckData,
  checkData,
  adminArea
}) {
  const [check, setCheck] = useState(false);

  useEffect(() => {
    setCheck(false);
    if (item.user_id === checkData.user_id) {
      setCheck(true);
    }
  }, [checkData]);

  return (
    <>
      <St_TableRowSection>
        <TableRow
          width={10}
          onClick={() => {
            setCheckData(item);
            check === false ? setCheck(true) : setCheck(false);
          }}
        >
          <input type="radio" checked={check} />
        </TableRow>
        <TableRow
          width={30}
          onClick={() => {
            setCheckData(item);
            check === false ? setCheck(true) : setCheck(false);
          }}
        >
          {item.name}
        </TableRow>
        <TableRow
          width={60}
          onClick={() => {
            setCheckData(item);
            check === false ? setCheck(true) : setCheck(false);
          }}
        >
          {item.email}
        </TableRow>
      </St_TableRowSection>
    </>
  );
}

export function TableManagerMemoRowPerformanceSection({
  item,
  setCheckData,
  checkData,
  adminArea
}) {
  const [check, setCheck] = useState(false);

  useEffect(() => {
    setCheck(false);
    if (item.user_id === checkData.user_id) {
      setCheck(true);
    }
  }, [checkData]);

  return (
    <>
      <St_TableRowSection>
        <TableRow
          width={10}
          onClick={() => {
            setCheckData(item);
          }}
        >
          <input type="radio" checked={check} />
        </TableRow>
        <TableRow
          width={30}
          onClick={() => {
            setCheckData(item);
            check === false ? setCheck(true) : setCheck(false);
          }}
        >
          {item.name}
        </TableRow>
        <TableRow
          width={10}
          onClick={() => {
            setCheckData(item);
            check === false ? setCheck(true) : setCheck(false);
          }}
        >
          {item.performance === undefined ? 0 : item.performance.first_count}
        </TableRow>
        <TableRow
          width={10}
          onClick={() => {
            setCheckData(item);
            check === false ? setCheck(true) : setCheck(false);
          }}
        >
          {item.performance === undefined ? 0 : item.performance.second_count}
        </TableRow>
        <TableRow
          width={10}
          onClick={() => {
            setCheckData(item);
            check === false ? setCheck(true) : setCheck(false);
          }}
        >
          {item.performance === undefined ? 0 : item.performance.third_count}
        </TableRow>
        <TableRow
          width={10}
          onClick={() => {
            setCheckData(item);
            check === false ? setCheck(true) : setCheck(false);
          }}
        >
          {item.performance === undefined
            ? 0
            : item.performance.first_count_all}
        </TableRow>
        <TableRow
          width={10}
          onClick={() => {
            setCheckData(item);
            check === false ? setCheck(true) : setCheck(false);
          }}
        >
          {item.performance === undefined
            ? 0
            : item.performance.second_count_all}
        </TableRow>
        <TableRow
          width={10}
          onClick={() => {
            setCheckData(item);
            check === false ? setCheck(true) : setCheck(false);
          }}
        >
          {item.performance === undefined
            ? 0
            : item.performance.third_count_all}
        </TableRow>
      </St_TableRowSection>
    </>
  );
}

export function TableManagerMemoRowPerformanceSectionSecond({
  item,
  setCheckData,
  checkData,
  adminArea
}) {
  const [check, setCheck] = useState(false);
  const [area, setArea] = useState('');
  const [areaDetail, setAreaDetail] = useState('');

  useEffect(() => {
    if (adminArea !== undefined) {
      adminArea.forEach((data) => {
        let areaString = '';
        let areaDetailString = '';

        if (data.name === item.name) {
          data.ncrareaname.forEach((jtem) => {
            areaString += jtem + ', ';
          });

          data.ncrareadetailname.forEach((jtem) => {
            areaDetailString += jtem + ', ';
          });

          setArea(areaString);
          setAreaDetail(areaDetailString);
        }
      });
    }
  }, [adminArea]);

  useEffect(() => {
    setCheck(false);
    if (item.user_id === checkData.user_id) {
      setCheck(true);
    }
  }, [checkData]);

  return (
    <>
      <St_TableRowSection>
        <TableRow
          width={10}
          onClick={() => {
            setCheckData(item);
          }}
        >
          <input type="radio" checked={check} />
        </TableRow>
        <TableRow
          width={30}
          onClick={() => {
            setCheckData(item);
            check === false ? setCheck(true) : setCheck(false);
          }}
        >
          {item.name}
        </TableRow>
        <TableRow
          width={15}
          onClick={() => {
            setCheckData(item);
            check === false ? setCheck(true) : setCheck(false);
          }}
        >
          {item.performance === undefined ? 0 : item.performance.first_count}
        </TableRow>
        <TableRow
          width={15}
          onClick={() => {
            setCheckData(item);
            check === false ? setCheck(true) : setCheck(false);
          }}
        >
          {item.performance === undefined ? 0 : item.performance.third_count}
        </TableRow>
        <TableRow
          width={15}
          onClick={() => {
            setCheckData(item);
            check === false ? setCheck(true) : setCheck(false);
          }}
        >
          {item.performance === undefined
            ? 0
            : item.performance.first_count_all}
        </TableRow>
        <TableRow
          width={15}
          onClick={() => {
            setCheckData(item);
            check === false ? setCheck(true) : setCheck(false);
          }}
        >
          {item.performance === undefined
            ? 0
            : item.performance.third_count_all}
        </TableRow>
      </St_TableRowSection>
    </>
  );
}

export function RowSecondDetailMemo({ item, detailOn }) {
  const [checkLists, setCheckLists] = useState([]);

  function convertObjectToArray(obj) {
    return Object.entries(obj).map(([key, value]) => {
      // key를 '_'를 기준으로 분리하여 name과 type을 추출
      const [name, type] = key.split('_');

      if (
        typeof value === 'object' &&
        value !== null &&
        !Array.isArray(value)
      ) {
        // 값이 객체이면 재귀적으로 처리
        return { name, type, value: convertObjectToArray(value) };
      } else {
        // 그렇지 않으면, 기본 변환 수행
        return { name, type, value };
      }
    });
  }

  useEffect(() => {
    setCheckLists(convertObjectToArray(JSON.parse(item.items)));
  }, []);

  return (
    <RowDetailMemo detailOn={detailOn} item={item}>
      {checkLists.map((checkList, index) => {
        if (
          checkList.type === 'check' &&
          (checkList.value === true || checkList.value === false)
        ) {
          return (
            <>
              <RowDetailMemoCheckList
                checked={checkList.value}
                title={checkList.name}
              />
              <div
                style={{
                  margin: '10px 0',
                  border: '1px solid #eee',
                  borderStyle: 'dotted'
                }}
              />
            </>
          );
        } else if (checkList.type === 'input') {
          return (
            <>
              <RowDetailMemoInput
                value={checkList.value}
                title={checkList.name}
              />
              <div
                style={{
                  margin: '10px 0',
                  border: '1px solid #eee',
                  borderStyle: 'dotted'
                }}
              />
            </>
          );
        } else {
          return (
            <>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '10px'
                }}
              >
                <h3 style={{ fontWeight: 'bold' }}>{checkList.name}</h3>
                {checkList.value.map((jtem) => {
                  return (
                    <RowDetailMemoCheckList
                      checked={jtem.value}
                      title={jtem.name}
                    />
                  );
                })}
              </div>
              <div
                style={{
                  margin: '10px 0',
                  border: '1px solid #eee',
                  borderStyle: 'dotted'
                }}
              />
            </>
          );
        }
      })}

      <St_Textarea
        placeholder="메모를 입력해주세요"
        defaultValue={item.comment}
        disabled
      />
    </RowDetailMemo>
  );
}

const St_Checklist = styled.div`
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  padding: 5px 0;
`;
function RowDetailMemoInput({ title, value, onChange }) {
  return (
    <St_Checklist>
      <span>{title}</span>
      <input
        style={{
          border: '1px solid #ddd',
          paddingLeft: '5px',
          paddingRight: '5px'
        }}
        onChange={(e) => {
          onChange(e.target.value);
        }}
        type="text"
        value={value}
      />
    </St_Checklist>
  );
}

function RowDetailMemoTime({ title, value, onChange, placeholder }) {
  return (
    <div>
      <St_Checklist>
        <span>{title}</span>
        <input
          placeholder={placeholder}
          style={{
            border: '1px solid #ddd',
            padding: '5px 10px',
            fontSize: '14px'
          }}
          onChange={(e) => {
            onChange(e.target.value);
          }}
          type="text"
          value={value}
        />
      </St_Checklist>
    </div>
  );
}

function RowDetailMemoCheckList({ title, item, checked, itemTitle }) {
  return (
    <St_Checklist
      onClick={() => {
        const object = { ...item };
        if (checked === false) {
          object[itemTitle] = true;
        } else {
          object[itemTitle] = false;
        }
      }}
    >
      <span>{title}</span>
      <input type="checkbox" checked={checked} />
    </St_Checklist>
  );
}

export function TableMemoSection({
  division,
  item,
  index,
  setSelectedFirstData,
  selectedFirstData
}) {
  const [detailOn, setDetailOn] = useState(false);
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    setChecked(false);
    selectedFirstData.forEach((jtem) => {
      if (item.id === jtem.id) {
        setChecked(true);
      }
    });
  }, [selectedFirstData]);

  useEffect(() => {
    var ok = false;

    selectedFirstData.forEach((jtem) => {
      if (item.id === jtem.id) {
        ok = true;
      }
    });

    if (ok === true) {
      setChecked(true);
    } else {
      setChecked(false);
    }
  }, [item]);
  return (
    <>
      <St_TableRowSection>
        <TableRow width={7} onClick={setSelectedFirstData}>
          <input type="checkbox" checked={checked} />
        </TableRow>
        <TableRow
          width={15}
          onClick={() => {
            setSelectedFirstData(item);
          }}
        >
          {item.j_address}
        </TableRow>
        <TableRow
          width={10}
          onClick={() => {
            setSelectedFirstData(item);
          }}
        ></TableRow>
        <TableRow
          width={10}
          onClick={() => {
            setSelectedFirstData(item);
          }}
        >
          {item.s_ncrofftel ?? item.s_address}
        </TableRow>
        <TableRow
          width={15}
          onClick={() => {
            setSelectedFirstData(item);
          }}
        >
          {item.second_saved_date.substring(0, 10)}
        </TableRow>
        <TableRow
          width={15}
          onClick={() => {
            setSelectedFirstData(item);
          }}
        >
          {item.title}
        </TableRow>
        <TableRow
          width={15}
          onClick={() => {
            setSelectedFirstData(item);
          }}
        >
          {item.contact_manager_name}
        </TableRow>
        <TableRow
          width={15}
          onClick={() => {
            setDetailOn(!detailOn);
          }}
        >
          {item.rejection === true && (
            <button
              style={{
                display: 'flex',
                alignItems: 'center',
                backgroundColor: '#da5959',
                borderRadius: '4px',
                fontSize: '12px',
                padding: '5px 10px'
              }}
            >
              <span style={{ whiteSpace: 'nowrap', color: '#fff' }}>반려</span>
              <span
                style={{ fontSize: '16px', color: '#fff' }}
                className="material-symbols-outlined"
              >
                chevron_right
              </span>
            </button>
          )}

          {(item.contact_manager_id === null ||
            item.contact_manager_id === 0 ||
            item.contact_manager_id === undefined) &&
            item.resolve === true && (
              <button
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  backgroundColor: '#1976d2',
                  borderRadius: '4px',
                  fontSize: '12px',
                  padding: '5px 10px'
                }}
              >
                <span
                  style={{
                    whiteSpace: 'nowrap',
                    color: '#fff'
                  }}
                >
                  반려 해결
                </span>
                <span
                  style={{ fontSize: '16px', color: '#fff' }}
                  className="material-symbols-outlined"
                >
                  chevron_right
                </span>
              </button>
            )}

          {(item.contact_manager_id === null ||
            item.contact_manager_id === 0 ||
            item.contact_manager_id === undefined) &&
            item.resolve === undefined && (
              <button
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  backgroundColor: '#ddd',
                  borderRadius: '4px',
                  fontSize: '12px',
                  padding: '5px 10px'
                }}
              >
                <span
                  style={{
                    whiteSpace: 'nowrap',
                    color: '#000'
                  }}
                >
                  공유 대기
                </span>
                <span
                  style={{ fontSize: '16px', color: '#000' }}
                  className="material-symbols-outlined"
                >
                  chevron_right
                </span>
              </button>
            )}
          {(item.resolve === undefined || item.resolve === true) &&
            item.contact_manager_id !== null &&
            item.contact_manager_id !== 0 &&
            item.contact_manager_id !== undefined && (
              <button
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  backgroundColor: '#31cc00',
                  borderRadius: '4px',
                  fontSize: '12px',
                  padding: '5px 10px'
                }}
              >
                <span
                  style={{
                    whiteSpace: 'nowrap',
                    color: '#fff'
                  }}
                >
                  공유 완료
                </span>
                <span
                  style={{ fontSize: '16px', color: '#fff' }}
                  className="material-symbols-outlined"
                >
                  chevron_right
                </span>
              </button>
            )}
        </TableRow>
      </St_TableRowSection>
      {division === 'sales' ? (
        <RowDetailMemo detailOn={detailOn} item={item} index={index} />
      ) : (
        <RowSecondDetailMemo detailOn={detailOn} item={item} index={index} />
      )}
    </>
  );
}

export function SecondTableMemoSection({
  division,
  item,
  index,
  setSelectedFirstData,
  selectedFirstData
}) {
  const [detailOn, setDetailOn] = useState(false);
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    setChecked(false);
    selectedFirstData.forEach((jtem) => {
      if (item.id === jtem.id) {
        setChecked(true);
      }
    });
  }, [selectedFirstData]);

  useEffect(() => {
    var ok = false;

    selectedFirstData.forEach((jtem) => {
      if (item.id === jtem.id) {
        ok = true;
      }
    });

    if (ok === true) {
      setChecked(true);
    } else {
      setChecked(false);
    }
  }, [item]);
  return (
    <>
      <St_TableRowSection>
        <TableRow width={10} onClick={setSelectedFirstData}>
          <input type="checkbox" checked={checked} />
        </TableRow>
        <TableRow
          width={20}
          onClick={() => {
            setSelectedFirstData(item);
          }}
        >
          {item.saved_date === null || item.saved_date === undefined
            ? ''
            : item.saved_date.substring(0, 10)}
        </TableRow>
        <TableRow
          width={25}
          onClick={() => {
            setSelectedFirstData(item);
          }}
        >
          {item.title}
        </TableRow>
        <TableRow
          width={25}
          onClick={() => {
            setSelectedFirstData(item);
          }}
        >
          {item.writter_name}
        </TableRow>
        <TableRow
          width={20}
          onClick={() => {
            setDetailOn(!detailOn);
          }}
        >
          <button
            style={{
              backgroundColor: '#ddd',
              borderRadius: '4px',
              fontSize: '12px',
              padding: '5px 10px'
            }}
          >
            상세내용
          </button>
        </TableRow>
      </St_TableRowSection>
      {division === 'sales' ? (
        <RowDetailMemo detailOn={detailOn} item={item} index={index} />
      ) : (
        <RowSecondDetailMemo detailOn={detailOn} item={item} index={index} />
      )}
    </>
  );
}

export function TableRow({ width, onClick, children }) {
  return (
    <St_TableRow onClick={onClick} width={width}>
      {children}
    </St_TableRow>
  );
}

export function TableColumnSection({ children }) {
  return <St_TableColumnSection>{children}</St_TableColumnSection>;
}

export function TableColumn({ width, children, onClick }) {
  return (
    <St_TableColumn width={width} onClick={onClick}>
      {children}
    </St_TableColumn>
  );
}

export function FilterRowCategory({ title, children, menuOn }) {
  const [categoryOn, setCategoryOn] = useState(menuOn);
  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <St_FilterCategoryTitle
          onClick={() => {
            setCategoryOn(!categoryOn);
          }}
        >
          {title}
        </St_FilterCategoryTitle>
        <img
          src={categoryOn === true ? ExpandeLess : ExpandMore}
          alt="ExpandMore"
        />
      </div>

      <St_FilterCategorySection categoryOn={categoryOn}>
        {children}
      </St_FilterCategorySection>
    </>
  );
}

export function FilterRow({ title, filter, setFilters }) {
  return (
    <St_FilterRow>
      <St_FilterRowTitle>{title}</St_FilterRowTitle>
      <St_FilterRowInput value={filter} onChange={setFilters} />
    </St_FilterRow>
  );
}

export function FilterSelect({
  title,
  data,
  filter,
  setFilter,
  salesManagerArea
}) {
  return (
    <St_FilterRow>
      <St_FilterRowTitle>{title}</St_FilterRowTitle>
      <Select
        value={filter}
        defaultValue=""
        onChange={(e) => {
          setFilter(e.target.value);
        }}
      >
        <MenuItem value="">선택</MenuItem>
        {data.map((item) => {
          return (
            <MenuItem
              dense
              value={
                title === '등록시군구' ? item.substring(0, 5).trim() : item
              }
            >
              <ListItemText>
                {item} <br />
                {title === '등록시군구' &&
                  salesManagerArea !== undefined &&
                  '공유 멤버 : ' +
                    (salesManagerArea[item.substring(0, 5).trim()] === undefined
                      ? ''
                      : salesManagerArea[item.substring(0, 5).trim()])}
              </ListItemText>
            </MenuItem>
          );
        })}
      </Select>
    </St_FilterRow>
  );
}

export function SearchButton({ onClick }) {
  return <StSearchButton onClick={onClick}>검색</StSearchButton>;
}

export function TitleSection({ children }) {
  return <StTitleSection>{children}</StTitleSection>;
}

const St_Textarea = styled.textarea`
  font-size: 14px;
  border: 1px solid #ddd;
  padding: 10px;
  resize: none;
  height: 150px;
  width: 100%;
`;
const StRowDetailMemo = styled.div`
  padding: 20px;
  border-left: 1px solid #eee;
  border-right: 1px solid #eee;
  border-bottom: 1px solid #eee;
  width: 100%;
  height: auto;
  background-color: #fff;
  display: ${(props) => (props.detailOn === true ? 'block' : 'none')};
`;

export function RowDetailMemo({ item, detailOn }) {
  const [checkLists, setCheckLists] = useState([]);

  function convertObjectToArray(obj) {
    return Object.entries(obj).map(([key, value]) => {
      // key를 '_'를 기준으로 분리하여 name과 type을 추출
      const [name, type] = key.split('_');

      if (
        typeof value === 'object' &&
        value !== null &&
        !Array.isArray(value)
      ) {
        // 값이 객체이면 재귀적으로 처리
        return { name, type, value: convertObjectToArray(value) };
      } else {
        // 그렇지 않으면, 기본 변환 수행
        return { name, type, value };
      }
    });
  }

  // 1번 아니다
  // 2번 쏘쏘
  // 3번 괜찬네

  useEffect(() => {
    setCheckLists(convertObjectToArray(JSON.parse(item.items)));
  }, []);
  return (
    <StRowDetailMemo detailOn={detailOn}>
      {item.rejection === true && (
        <div
          style={{
            paddingBottom: '30px',
            marginBottom: '30px',
            borderBottom: '1px solid #ddd'
          }}
        >
          <h2
            style={{
              marginBottom: '20px',
              fontSize: '16px',
              fontWeight: 'bold'
            }}
          >
            반려
          </h2>

          <div
            style={{
              display: 'flex',
              gap: '20px',
              justifyContent: 'space-between'
            }}
          >
            <div
              style={{
                width: '50%',
                display: 'flex',
                flexDirection: 'column',
                gap: '10px'
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column'
                }}
              >
                <span
                  style={{
                    fontSize: '15px',
                    fontWeight: 'bold'
                  }}
                >
                  반려 상호명
                </span>
                <span style={{ fontSize: '15px', color: '#555' }}>
                  {item.rejection_item.item}
                </span>
              </div>

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column'
                }}
              >
                <span
                  style={{
                    fontSize: '15px',
                    fontWeight: 'bold'
                  }}
                >
                  방문 영업사원
                </span>
                <span style={{ fontSize: '15px', color: '#555' }}>
                  {item.rejection_item.contact_manager_name}
                </span>
              </div>

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column'
                }}
              >
                <span
                  style={{
                    fontSize: '15px',
                    fontWeight: 'bold'
                  }}
                >
                  2차 관리자
                </span>
                <span style={{ fontSize: '15px', color: '#555' }}>
                  {item.rejection_item.second_manager_name}
                </span>
              </div>

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column'
                }}
              >
                <span
                  style={{
                    fontSize: '15px',
                    fontWeight: 'bold'
                  }}
                >
                  반려 상호명
                </span>
                <span style={{ fontSize: '15px', color: '#555' }}>
                  {item.rejection_item.item}
                </span>
              </div>

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column'
                }}
              >
                <span
                  style={{
                    fontSize: '15px',
                    fontWeight: 'bold'
                  }}
                >
                  반려된 날짜
                </span>
                <span style={{ fontSize: '15px', color: '#555' }}>
                  {item.rejection_item.date}
                </span>
              </div>
            </div>

            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '50%'
              }}
            >
              <span
                style={{
                  fontSize: '15px',
                  fontWeight: 'bold',
                  marginBottom: '10px'
                }}
              >
                반려 사유
              </span>
              <textarea
                defaultValue={item.rejection_item.reason}
                disabled
                style={{
                  resize: 'none',
                  height: '100%',
                  border: '1px solid #ddd',
                  padding: '10px'
                }}
              />
            </div>
          </div>
        </div>
      )}

      <div
        style={{
          display: 'flex',
          gap: '20px',
          justifyContent: 'space-between'
        }}
      >
        <div style={{ width: '50%' }}>
          <h2
            style={{
              marginBottom: '20px',
              fontSize: '16px',
              fontWeight: 'bold'
            }}
          >
            상세 내용
          </h2>

          <div
            style={{
              display: 'flex',
              gap: '20px',
              justifyContent: 'space-between'
            }}
          >
            <div
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                gap: '10px'
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column'
                }}
              >
                <span
                  style={{
                    fontSize: '15px',
                    fontWeight: 'bold'
                  }}
                >
                  2차 저장 날짜
                </span>
                <span style={{ fontSize: '15px', color: '#555' }}>
                  {item.second_saved_date.substring(0, 10)}
                </span>
              </div>

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column'
                }}
              >
                <span
                  style={{
                    fontSize: '15px',
                    fontWeight: 'bold'
                  }}
                >
                  주소
                </span>
                <span style={{ fontSize: '15px', color: '#555' }}>
                  {item.j_address}
                </span>
              </div>

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column'
                }}
              >
                <span
                  style={{
                    fontSize: '15px',
                    fontWeight: 'bold'
                  }}
                >
                  전화번호
                </span>
                <span style={{ fontSize: '15px', color: '#555' }}>
                  {item.phone_number}
                </span>
              </div>

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column'
                }}
              >
                <span
                  style={{
                    fontSize: '15px',
                    fontWeight: 'bold'
                  }}
                >
                  대표자 이름
                </span>
                <span style={{ fontSize: '15px', color: '#555' }}>
                  {item.ncrgsmaster}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div style={{ width: '50%' }}>
          {checkLists.map((checkList, index) => {
            if (
              checkList.type === 'check' &&
              (checkList.value === true || checkList.value === false)
            ) {
              return (
                <>
                  <RowDetailMemoCheckList
                    checked={checkList.value}
                    title={checkList.name}
                  />
                  <div
                    style={{
                      margin: '10px 0',
                      border: '1px solid #eee',
                      borderStyle: 'dotted'
                    }}
                  />
                </>
              );
            } else if (
              checkList.type === 'input' &&
              typeof checkList.value !== 'object'
            ) {
              return (
                <>
                  <RowDetailMemoInput
                    value={checkList.value}
                    title={checkList.name}
                  />
                  <div
                    style={{
                      margin: '10px 0',
                      border: '1px solid #eee',
                      borderStyle: 'dotted'
                    }}
                  />
                </>
              );
            } else if (
              checkList.type === 'time' &&
              typeof checkList.value !== 'object'
            ) {
              return (
                <>
                  <RowDetailMemoTime
                    value={checkList.value}
                    title={checkList.name}
                  />
                  <div
                    style={{
                      margin: '10px 0',
                      border: '1px solid #eee',
                      borderStyle: 'dotted'
                    }}
                  />
                </>
              );
            } else {
              return (
                <>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '10px'
                    }}
                  >
                    <h3 style={{ fontWeight: 'bold' }}>{checkList.name}</h3>
                    {checkList.value.map((jtem) => {
                      return (
                        <RowDetailMemoCheckList
                          checked={jtem.value}
                          title={jtem.name}
                        />
                      );
                    })}
                  </div>
                  <div
                    style={{
                      margin: '10px 0',
                      border: '1px solid #eee',
                      borderStyle: 'dotted'
                    }}
                  />
                </>
              );
            }
          })}
        </div>
      </div>

      <h3
        style={{
          fontWeight: 'bold',
          marginTop: '20px',
          marginBottom: '10px'
        }}
      >
        기타 메모 작성
      </h3>
      <St_Textarea
        placeholder="메모를 입력해주세요"
        onChange={(e) => {
          const object = { ...item };
          object.memo = e.target.value;
        }}
        defaultValue={item.memo}
      />
    </StRowDetailMemo>
  );
}
