import axios from 'axios';
import { QueryParams } from '../../../utils/user_API';

async function getContactData(params: QueryParams) {
  const option = {
    url: 'https://cpi.contax.ai/get_board',
    method: 'POST',
    data: params,
    header: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };

  try {
    const response = await axios(option);

    if (response.status === 200) {
      return {
        status: 'success',
        data: response.data
      };
    }
  } catch (error) {
    return {
      status: 'failed',
      data: error
    };
  }
}

async function getCategory() {
  const option = {
    url: 'https://cpi.contax.ai/categories/use_guide_list',
    method: 'GET',
    header: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };

  try {
    const response = await axios(option);

    if (response.status === 200) {
      return {
        status: 'success',
        data: response.data[0][0]
      };
    }
  } catch (error) {
    return {
      status: 'failed',
      data: error
    };
  }
}

export { getContactData, getCategory };
