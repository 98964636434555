import React from 'react';
import TitleCard from '../../components/Cards/TitleCard';
import { Grid } from '@mui/material';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { BigCategory } from '../../components/Layout/BigCategory';
import { useDispatch, useSelector } from 'react-redux';
import { StoreState } from '../../app/store';
import {
  activateLicenseCategory,
  updateLicenseData
} from '../../app/slice/licensePageSlice';
import LicenseJonghapSection from './components/license_jonghap_section';
import { getLicenses } from '../../utils/license_API';
import { QueryParams } from '../../utils/email_API';
import LicenseJeonmoonSection from './components/license_jeonmoon_section';

function formatNumberToKorean(num: number) {
  const units = [
    '',
    '십 ',
    '백 ',
    '천 ',
    '만 ',
    '십만 ',
    '백만 ',
    '천만 ',
    '억 '
  ];
  const str = String(num);
  let result = '';

  for (let i = 0; i < str.length; i++) {
    const char = str[str.length - 1 - i];
    if (char !== '0') {
      result = char + units[i] + result;
    }
  }

  // '1'을 제외한 단위 표시
  result = result.replace(/1(십 {2}|백 {2}|천 {2}|만 {2}|억 {2})/g, '$1');

  return result + '원';
} // 출력: 12억3456만7890

const average_columns = [
  { field: 'id', headerName: '고유번호', width: 100 },
  { field: 'license_name', headerName: '면허명', width: 300 },
  { field: 'liquidity_ratio_avg', headerName: '유동비율', width: 130 },
  { field: 'debt_ratio_avg', headerName: '부채비율', width: 130 },
  {
    field: 'borrowing_dependency_avg',
    headerName: '차입금의존도',
    width: 130
  },
  {
    field: 'return_on_assets_avg',
    headerName: '영업이익대비 이자보상배율',
    width: 130
  },
  {
    field: 'net_profit_margin_avg',
    headerName: '매출액영업이익율',
    width: 130
  },
  { field: 'maechul', headerName: '매출액순이익율', width: 130 },
  { field: 'alljasanreal', headerName: '총자산순이익율', width: 130 },
  {
    field: 'cash_flow_ratio_avg',
    headerName: '총자산대비영업현금흐름비율',
    width: 130
  },
  {
    field: 'asset_turnover_ratio_avg',
    headerName: '최근년도 자산회전율',
    width: 130
  },
  {
    field: 'construction_technology_investment_ratio_avg',
    headerName: '건설기술개발투자비율',
    width: 130
  },
  { field: 'year', headerName: '연도', width: 100 }
];

function License() {
  const { category_list, offset, mode } = useSelector(
    (state: StoreState) => state.licensePage
  );
  const [licenseName, setLicenseName] = useState([]);

  const [average, setAverage] = useState([
    {
      id: 0,
      license_name: '',
      borrowing_dependency_avg: 0,
      net_profit_margin_avg: 0,
      return_on_assets_avg: 0,
      cash_flow_ratio_avg: 0,
      asset_turnover_ratio_avg: 0,
      construction_technology_investment_ratio_avg: 0,
      liquidity_ratio_avg: 0,
      debt_ratio_avg: 0,
      year: '2018'
    }
  ]);

  const [id] = useState(0);
  const [dependency] = useState('');
  const [margin] = useState('');
  const [assets] = useState('');
  const [flow] = useState('');
  const [alljasanreal] = useState('');
  const [maechul] = useState('');
  const [turnover] = useState('');
  const [investment] = useState('');
  const [liquidity] = useState('');
  const [debt] = useState('');
  const [expression, setExpression] = useState({
    liquidity_expression: '',
    debt_expression: '',
    flow_expression: '',
    dependency_expression: '',
    assets_expression: '',
    margin_expression: '',
    turnover_expression: '',
    investment_expression: '',
    alljasanreal_expression: '',
    maechul_expression: ''
  });

  async function loadLicenseData() {
    let category = '';

    category_list.forEach((item) => {
      if (item.active === true) {
        category = item.title.substring(0, 2);
      }
    });
    const body: QueryParams = {
      database: '면허',
      categories: ['license_category1'],
      search_queries: [category],
      offset: 10 * offset,
      limit: 10,
      sort_option: 'id',
      sort: 'DESC'
    };
    const data = await getLicenses(body);

    dispatch(updateLicenseData(data));
  }

  useEffect(() => {
    getAverage();
    loadExpression();
  }, []);

  async function removeAverage() {
    if (!window.confirm('정말 삭제하시겠습니까?')) {
      return;
    } else {
      const conbiOption = {
        url: 'https://cpi.contax.ai/mng_page/average/' + id,
        method: 'DELETE',
        header: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      };

      await axios(conbiOption).then((response) => {
        if (response.status === 200) {
          alert('삭제되었습니다.');
          const array = [...average];
          array.forEach((item, index) => {
            if (item.id === id) {
              array.splice(index, 1);
            }
          });

          setAverage(array);
        }
      });
    }
  }

  async function getAverage() {
    const conbiOption = {
      url: 'https://cpi.contax.ai/mng_page/average',
      method: 'GET',
      header: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    };
  }

  async function createAverage() {
    const option = {
      url: 'https://cpi.contax.ai/mng_page/average',
      data: {
        license_name: licenseName,
        borrowing_dependency_avg: dependency,
        net_profit_margin_avg: margin,
        return_on_assets_avg: assets,
        cash_flow_ratio_avg: flow,
        asset_turnover_ratio_avg: turnover,
        construction_technology_investment_ratio_avg: investment,
        liquidity_ratio_avg: liquidity,
        alljasanreal: alljasanreal,
        maechul: maechul,
        debt_ratio_avg: debt,
        year: 1234
      },
      method: 'POST',
      header: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    };
  }

  async function updateAverage() {
    console.log(assets);

    const option = {
      url: 'https://cpi.contax.ai/mng_page/average/',
      data: {
        id: id,
        license_name: licenseName,
        borrowing_dependency_avg: dependency,
        net_profit_margin_avg: margin,
        return_on_assets_avg: assets,
        cash_flow_ratio_avg: flow,
        asset_turnover_ratio_avg: turnover,
        construction_technology_investment_ratio_avg: investment,
        liquidity_ratio_avg: liquidity,
        debt_ratio_avg: debt,
        alljasanreal: alljasanreal,
        maechul: maechul,
        year: 1234
      },
      method: 'PUT',
      header: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    };
  }

  async function loadExpression() {
    const option = {
      url: 'https://cpi.contax.ai/mng_avg_expression',
      method: 'GET',
      header: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    };

    axios(option).then((response) => {
      if (response.status === 200) {
        setExpression(response.data);
      }
    });
  }

  async function updateExpression() {
    const option = {
      url: 'https://cpi.contax.ai/mng_avg_expression',
      data: expression,
      method: 'PUT',
      header: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    };

    axios(option).then((response) => {
      if (response.status === 200) {
        return;
      }
    });
  }

  useEffect(() => {
    loadLicenseData();
  }, [category_list, offset, mode]);

  const dispatch = useDispatch();

  return (
    <>
      <Grid container direction="column" gap={3}>
        <Grid item>
          <Grid
            container
            spacing={2}
            justifyContent="space-between"
            alignItems="stretch"
            direction="row"
          >
            <Grid item xs={12}>
              <TitleCard title={'면허 관리'}>
                <BigCategory
                  category_list={category_list}
                  onClick={(index: number) => {
                    dispatch(activateLicenseCategory(index));
                  }}
                />

                {category_list.map((category, index) => {
                  if (category.title === '종합 면허' && category.active) {
                    return <LicenseJonghapSection key={index} />;
                  }

                  if (category.title === '전문 면허' && category.active) {
                    return <LicenseJeonmoonSection key={index} />;
                  }

                  return <div key={index}></div>;
                })}
              </TitleCard>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default License;
