import { UserDivisionType } from '../../../app/slice/memberManagementSlice';
import { postUser, QueryParams } from '../../../utils/user_API';
import { SearchQueryType } from '../index';

export async function searchMembers(
  tab: string,
  offset: number,
  limit: number,
  sort: UserDivisionType,
  searchQuery: SearchQueryType
) {
  const params: QueryParams = {
    database: 'members',
    categories: ['subscription_type'],
    search_queries: [tab],
    offset: offset * limit,
    limit: limit,
    sort_option: sort.business.option,
    sort: sort.business.sort
  };

  if (tab === '비즈니스') {
    params.sort_option = sort.business.option;
    params.sort = sort.business.sort;
  }

  if (tab === '퍼스트') {
    params.sort_option = sort.first.option;
    params.sort = sort.first.sort;
  }

  if (tab === '콘택스') {
    params.sort_option = sort.contax.option;
    params.sort = sort.contax.sort;
  }

  if (tab === '비즈니스' && searchQuery.business.query !== '') {
    params.search_queries.push(searchQuery.business.query);
    params.categories.push(searchQuery.business.category);
  }

  if (tab === '퍼스트' && searchQuery.first.query !== '') {
    params.search_queries.push(searchQuery.first.query);
    params.categories.push(searchQuery.first.category);
  }

  if (tab === '콘택스' && searchQuery.contax.query !== '') {
    params.search_queries.push(searchQuery.contax.query);
    params.categories.push(searchQuery.contax.category);
  }

  const result = await postUser(params);

  return result;
}
