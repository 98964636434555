import React from 'react';
import {
  MemberDetailProperty,
  MemberDetailSelectProperty
} from './MemberDetailProperty';
import { useDispatch, useSelector } from 'react-redux';
import { StoreState } from '../../../app/store';
import {
  DeleteButton,
  ManagerGridButton,
  SuccessButton
} from '../../../components/GridType';
import { PencilIcon } from '@heroicons/react/16/solid';
import { CancelOutlined, DeleteOutline, Save } from '@mui/icons-material';
import { updateTargetMember } from '../../../app/slice/testUserSlice';
import { deleteUserData, updateMember } from '../../../utils/user_API';

interface MemberDetailSectionProps {
  searchMembers: () => void;
  closeDetailSection: () => void;
}

export function MemberDetailSection(props: MemberDetailSectionProps) {
  const members = useSelector((state: StoreState) => state.testUser);
  const dispatch = useDispatch();
  const target_members = members.target_member;

  const [isModify, setIsModify] = React.useState(false);

  async function changeMemberInformation() {
    const response = await updateMember(target_members);

    if (response.message === 'success') {
      alert('성공적으로 수정되었습니다.');
      props.searchMembers();
      props.closeDetailSection();
    } else {
      alert('오류가 발생하였습니다.');
    }
  }

  async function removeMember() {
    if (!window.confirm('정말 삭제하시겠습니까?')) {
      return;
    } else {
      const response = await deleteUserData(target_members.email);
      if (response.status === 'member deleted') {
        alert('성공적으로 삭제하였습니다.');
        props.searchMembers();
        props.closeDetailSection();
      }
    }
  }

  return (
    <>
      <div style={{ display: 'flex', gap: '20px' }}>
        <MemberDetailProperty
          size="short"
          id={'user_id'}
          title="회원번호"
          value={target_members.user_id.toString()}
        />
        <MemberDetailProperty
          size="short"
          id={'name'}
          title="이름"
          isModify={isModify}
          value={target_members.name}
        />
        <MemberDetailProperty
          size="short"
          id={'company_name'}
          title="회사명"
          isModify={isModify}
          value={target_members.company_name}
        />
        <MemberDetailProperty
          size="short"
          id={'email'}
          title="이메일"
          isModify={isModify}
          value={target_members.email}
        />
      </div>
      <div
        style={{
          margin: '20px 0',
          height: '2px',
          backgroundColor: '#eee'
        }}
      />

      <div style={{ display: 'flex', gap: '20px' }}>
        <MemberDetailProperty
          size="short"
          id={'phone_number'}
          title="전화번호"
          isModify={isModify}
          value={target_members.phone_number}
        />
        <MemberDetailProperty
          size="short"
          id={'company_phone_number'}
          title="회사 전화번호"
          isModify={isModify}
          value={target_members.company_phone_number}
        />
        <MemberDetailProperty
          size="short"
          id={'address'}
          title="주소"
          isModify={isModify}
          value={target_members.address}
        />
      </div>

      <div style={{ margin: '20px 0' }} />

      <div style={{ display: 'flex', gap: '20px' }}>
        <MemberDetailProperty
          size="long"
          id={'license'}
          title="면허"
          isModify={isModify}
          value={
            target_members.license_names === undefined
              ? ''
              : target_members.license_names.toString()
          }
        />
      </div>

      <div
        style={{
          margin: '20px 0',
          height: '2px',
          backgroundColor: '#eee'
        }}
      />

      <div style={{ display: 'flex', gap: '20px' }}>
        <MemberDetailProperty
          size="short"
          id="business_number"
          title="사업자번호"
          isModify={isModify}
          value={target_members.business_number}
        />
        <MemberDetailProperty
          size="short"
          id="position"
          title="직위"
          isModify={isModify}
          value={target_members.position}
        />
        <MemberDetailProperty
          size="short"
          id={'representative_name'}
          title="대표 이름"
          isModify={isModify}
          value={target_members.representative_name}
        />
        <MemberDetailSelectProperty
          size="short"
          id={'sms_kakao_agree'}
          title="마케팅 수신 여부"
          value={target_members.sms_kakao_agree.toString()}
        />
      </div>

      <div
        style={{
          margin: '20px 0',
          height: '2px',
          backgroundColor: '#eee'
        }}
      />

      <div style={{ justifyContent: 'end', display: 'flex', gap: '20px' }}>
        {!isModify && (
          <ManagerGridButton
            type="active"
            onClick={() => {
              setIsModify(true);
            }}
          >
            <PencilIcon style={{ width: '20px' }} />
            수정
          </ManagerGridButton>
        )}

        {isModify && (
          <SuccessButton
            type="active"
            onClick={() => {
              changeMemberInformation();
            }}
          >
            <Save style={{ width: '20px' }} />
            저장
          </SuccessButton>
        )}

        {!isModify && (
          <DeleteButton
            type="active"
            onClick={() => {
              removeMember();
            }}
          >
            <DeleteOutline style={{ width: '20px' }} />
            삭제
          </DeleteButton>
        )}

        {isModify && (
          <DeleteButton
            type="active"
            onClick={() => {
              setIsModify(false);
              dispatch(updateTargetMember(members.original_target_member));
            }}
          >
            <CancelOutlined style={{ width: '20px' }} />
            취소
          </DeleteButton>
        )}
      </div>
    </>
  );
}
