import TitleCard from '../../components/Cards/TitleCard';
import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import {
  ManagerGrid,
  ManagerGridRow,
  ManagerGridRowSection,
} from '../../components/GridType';
import { useDispatch } from 'react-redux';
import { getErrorBoard } from '../../utils/error_board_API';

import { updateErrorLogBoard } from '../../app/slice/errorLogSlice';
import { useSelector } from 'react-redux';
import BoardPagenation from '../../components/BoardPagenation';
import {
  calculatePagination,
  createPaginationSize,
} from '../../utils/common_API';

const Grid_COL = [
  { title: '이름', width: '10', textAlign: 'left' },
  { title: '이메일', width: '15', textAlign: 'left' },
  { title: 'API', width: '25', textAlign: 'left' },
  { title: '에러 내용', width: '40', textAlign: 'left' },
  { title: '날짜', width: '10', textAlign: 'left' },
];

function ErrorBoard() {
  const dispatch = useDispatch();
  const { list, total_count } = useSelector(state => state.errorLog);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(10);
  const [pageNumbers, setPageNumbers] = useState([1]);
  const [pageLength, setPageLength] = useState(0);

  useEffect(() => {
    loadErrorLogBoard();
  }, [offset]);

  async function loadErrorLogBoard() {
    const result = await getErrorBoard(offset, limit);
    let length = calculatePagination(result.data.total_count, limit);
    let array = [];
    array = createPaginationSize(offset, length);
    setPageLength(length);
    setPageNumbers(array);
    dispatch(updateErrorLogBoard(result));
  }

  return (
    <>
      <Grid container spacing={2}>
        <TitleCard title={'에러 로그'}>
          <ManagerGrid col={Grid_COL}>
            {list.map((item, index) => {
              return (
                <ManagerGridRowSection>
                  <ManagerGridRow width={10}>{item.user_name}</ManagerGridRow>
                  <ManagerGridRow width={15}>{item.email}</ManagerGridRow>
                  <ManagerGridRow width={25}>{item.api_name}</ManagerGridRow>
                  <ManagerGridRow width={40}>{item.error}</ManagerGridRow>
                  <ManagerGridRow width={10}>{item.date}</ManagerGridRow>
                </ManagerGridRowSection>
              );
            })}
          </ManagerGrid>
          <BoardPagenation
            offset={offset}
            pageNumbers={pageNumbers}
            pageLength={pageLength}
            setOffset={value => setOffset(value)}
          />
        </TitleCard>
      </Grid>
    </>
  );
}

export default ErrorBoard;
