import styled from 'styled-components';
import { Close } from '@mui/icons-material';
import {
  updateNotificationOn,
  updateSelectedMenu
} from '../../app/slice/layoutSlice';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StoreState } from '../../app/store';
import {
  NotificationDataType,
  resetNotificationData,
  updateNotificationData,
  updateReadedStatus
} from '../../app/slice/notificationSlice';
import {
  changeNotificationReaded,
  getInquiryData,
  QueryParams
} from '../../utils/inquiry_API';
import {
  updateDetailOn,
  updateExpertInquiryTarget
} from '../../app/slice/expertInquirySlice';

import { getCompanyNameByUniqueKey } from '../../utils/common_API';
import { updateMode } from '../../app/slice/salesSlice';
import { getNotification } from '../../page/notification/tools/getNotification';

const StyledNotificationModal = styled.div<{ empty: boolean }>`
  overflow: scroll;
  width: 320px;
  height: 600px;
  border: 1px solid #ddd;
  border-radius: 10px;
  position: absolute;
  top: 120px;
  padding: 60px 30px;
  right: 80px;
  background-color: #fff;
  font-size: 14px;
  text-align: ${(props) => (props.empty ? 'center' : 'left')};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: ${(props) => (props.empty ? 'center' : 'start')};
`;

const Item = styled.div<{ readed: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px;
  cursor: pointer;
  border-bottom: 1px solid #ddd;
  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }

  opacity: ${(props) => (props.readed ? '0.5' : '1.0')};

  span {
    margin: 0;
    font-weight: bold;
    color: #1976d2;
  }

  p {
    margin-top: 0;
    margin-bottom: 10px;
  }

  h2 {
    margin-top: 10px;
    margin-bottom: 5px;
    font-size: 16px;
    font-weight: bold;
    color: #1976d2;
  }
`;

export function NotificationModal(props: { id: string }) {
  async function getNotificationDataNotReaded() {
    const params: QueryParams = {
      database: '알림',
      categories: ['receiver', 'readed'],
      search_queries: [props.id, 'false'],
      offset: 0,
      limit: 10,
      sort_option: 'regdate',
      sort: 'DESC'
    };
    const result = await getNotification(params);
    if (result !== undefined && result.status === 'success') {
      dispatch(updateNotificationData(result.data.data));

      return 'success';
    } else {
      return 'failed';
    }
  }

  async function getNotificationDataReaded() {
    const params: QueryParams = {
      database: '알림',
      categories: ['receiver', 'readed'],
      search_queries: [props.id, 'true'],
      offset: 0,
      limit: 10,
      sort_option: 'regdate',
      sort: 'DESC'
    };
    const result = await getNotification(params);
    if (result !== undefined && result.status === 'success') {
      dispatch(updateNotificationData(result.data.data));
    }
  }

  const { data } = useSelector((state: StoreState) => state.notification);
  const dispatch = useDispatch();

  async function init() {
    dispatch(resetNotificationData());
    const result = await getNotificationDataNotReaded();
    if (result === 'success' || result === 'failed') {
      getNotificationDataReaded();
    }
  }

  useEffect(() => {
    init();
  }, []);

  return (
    <StyledNotificationModal empty={data.length === 0}>
      <Close
        style={{
          cursor: 'pointer',
          position: 'absolute',
          top: '10px',
          right: '10px'
        }}
        onClick={() => {
          dispatch(updateNotificationOn(false));
        }}
      />

      {data.length === 0 && <span>현재 알림이 존재하지 않습니다.</span>}
      {data.length > 0 &&
        data.map((item) => (
          <NotificationItem
            content_id={item.content_id}
            key={item.id}
            id={item.id}
            division={item.division}
            writter={item.writter}
            regdate={item.regdate}
            readed={item.readed}
          />
        ))}
    </StyledNotificationModal>
  );
}

function NotificationItem(props: NotificationDataType) {
  let division = '';
  const dispatch = useDispatch();

  async function onClick() {
    const params: QueryParams = {
      database: division,
      categories: ['id'],
      search_queries: [props.content_id.toString()],
      offset: 0,
      limit: 1,
      sort: 'DESC',
      sort_option: 'id'
    };

    try {
      const result = await changeNotificationReaded(props.id);
      console.log(result);
    } catch (error) {
      console.log(error);
    }

    if (division === '영업 데이터 공유') {
      dispatch(updateSelectedMenu('영업'));
      dispatch(updateNotificationOn(false));
    }

    if (division === '영업 데이터 저장') {
      dispatch(updateSelectedMenu('종합영업관리'));
      dispatch(updateMode('contact_data'));
      dispatch(updateNotificationOn(false));
    }

    if (division === '전문가 문의') {
      dispatch(updateSelectedMenu('문의글 공유'));
      dispatch(updateNotificationOn(false));
      try {
        const result = await getInquiryData(params);

        if (result !== undefined && result.status === 'success') {
          dispatch(updateReadedStatus(props.id));

          if (division === '전문가 문의') {
            dispatch(updateDetailOn(true));
            dispatch(updateNotificationOn(false));
            dispatch(updateSelectedMenu('문의글 공유'));
            dispatch(updateExpertInquiryTarget(result.data.data[0]));
          }
        }
      } catch (error) {
        return;
      }
    }
  }

  switch (props.division) {
    case 'inquiry':
      division = '1:1 문의';
      break;

    case 'expertInquiry':
      division = '전문가 문의';
      break;

    case 'update_sales_db':
      division = '영업 데이터 공유';
      break;

    case 'save_sales_db':
      division = '영업 데이터 저장';
      break;
  }

  return (
    <>
      {division === '전문가 문의' && (
        <ExpertInquiryItem
          readed={props.readed}
          onClick={onClick}
          regdate={props.regdate}
          writter={props.writter}
        />
      )}

      {division === '영업 데이터 공유' && (
        <ShareSalesDataItem
          content_id={props.content_id}
          readed={props.readed}
          onClick={onClick}
          regdate={props.regdate}
          writter={props.writter}
        />
      )}

      {division === '영업 데이터 저장' && (
        <SaveSalesDataItem
          content_id={props.content_id}
          readed={props.readed}
          onClick={onClick}
          regdate={props.regdate}
          writter={props.writter}
        />
      )}
    </>
  );
}

const ExpertInquiryItem = (props: {
  readed: boolean;
  onClick: () => void;
  regdate: string;
  writter: string;
}) => {
  return (
    <Item readed={props.readed} onClick={props.onClick}>
      <span>{props.regdate}</span>
      <h2>전문가 문의</h2>
      <p>
        {props.writter}님이 <span>전문가 문의</span> 글을 남겼습니다.
      </p>
    </Item>
  );
};

const ShareSalesDataItem = (props: {
  readed: boolean;
  onClick: () => void;
  regdate: string;
  writter: string;
  content_id: string;
}) => {
  const [companyName, setCompanyName] = useState('');

  async function loadCompanyNamebyUniqueKey(unique_key: string) {
    const result = await getCompanyNameByUniqueKey(unique_key);

    if (result.status === 'success') {
      setCompanyName(result.data.ncrgskname);
    }
  }

  useEffect(() => {
    loadCompanyNamebyUniqueKey(props.content_id.toString());
  }, []);

  return (
    <Item readed={props.readed} onClick={props.onClick}>
      <span>{props.regdate}</span>
      <h2>영업 데이터 공유</h2>
      <p>
        최고 관리자님이 <span>{companyName}</span> 데이터를 공유하였습니다.
      </p>
    </Item>
  );
};

const SaveSalesDataItem = (props: {
  readed: boolean;
  onClick: () => void;
  regdate: string;
  writter: string;
  content_id: string;
}) => {
  const [companyName, setCompanyName] = useState('');

  async function loadCompanyNamebyUniqueKey(unique_key: string) {
    const result = await getCompanyNameByUniqueKey(unique_key);

    if (result.status === 'success') {
      setCompanyName(result.data.ncrgskname);
    }
  }

  useEffect(() => {
    loadCompanyNamebyUniqueKey(props.content_id.toString());
  }, []);

  return (
    <Item readed={props.readed} onClick={props.onClick}>
      <span>{props.regdate}</span>
      <h2>영업 데이터 저장</h2>
      <p>
        {props.writter}님이 <span>{companyName}</span> 데이터를 저장하였습니다.
      </p>
    </Item>
  );
};
