import styled from 'styled-components';
import React from 'react';
import { MenuItem, Select } from '@mui/material';
import { useDispatch } from 'react-redux';
import { updateCreateMemberInformation } from '../../../app/slice/memberManagementSlice';

const PropertyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 25%;
`;

const Title = styled.span`
  font-weight: bold;
  color: rgba(31, 41, 55, 0.38);
`;

const Input = styled.input`
  border-radius: 10px;
  border: 2px solid #ddd;
  font-size: 18px;
  padding: 10px;
  color: rgba(13, 29, 51, 0.7);
`;

interface MemberDetailPropertyProps {
  title: string;
  value: string;
  isModify?: boolean;
  id: string;
}

export function CreateMemberProperty(props: MemberDetailPropertyProps) {
  const dispatch = useDispatch();

  return (
    <PropertyWrapper>
      <Title>{props.title}</Title>
      <Input
        onChange={(e) => {
          dispatch(
            updateCreateMemberInformation({
              id: props.id,
              value: e.target.value
            })
          );
        }}
        disabled={props.isModify === undefined ? true : !props.isModify}
        value={props.value}
      />
    </PropertyWrapper>
  );
}

export function CreateMemberSelectProperty(props: MemberDetailPropertyProps) {
  return (
    <PropertyWrapper>
      <Title>{props.title}</Title>
      <Select
        sx={{
          borderRadius: '10px',
          border: '2px solid #ddd',
          padding: '6px',
          color: 'rgba(13, 29, 51, 0.7)',
          fontSize: '18px',
          fontWeight: 'bold'
        }}
        disabled={props.isModify === undefined ? true : props.isModify}
        defaultValue={props.value}
      >
        <MenuItem value={0}>아니오</MenuItem>
        <MenuItem value={1}>예</MenuItem>
      </Select>
    </PropertyWrapper>
  );
}
