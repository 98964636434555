import axios from 'axios';

async function getUserId(paramsArray) {
  try {
    const option = {
      url: 'https://cpi.contax.ai/members/all_members',
      method: 'GET',
      header: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    };

    const response = await axios(option);
    const array = [];

    if (response.status === 200) {
      response.data.forEach((item) => {
        paramsArray.forEach((jtem) => {
          if (item.email === jtem) {
            array.push(item.user_id);
          }
        });
      });

      return array;
    } else {
      return response.status;
    }
  } catch (error) {
    return error;
  }
}

export { getUserId };
