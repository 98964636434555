import React from 'react';
import styled from 'styled-components';

interface TabButtonProps {
  active: boolean;
}

const BigCategoryWrapper = styled.div`
  margin-bottom: 60px;
  display: flex;
  align-items: center;
  gap: 40px;
`;

const BigCategoryButton = styled.span<TabButtonProps>`
  font-size: 24px;
  font-weight: bold;
  color: #1a3155;
  opacity: ${props => (props.active ? 1 : 0.5)};
  cursor: pointer;
  transition: opacity 0.3s ease;

  &:hover {
    opacity: 1;
  }
`;

export interface Category {
  title: string;
  active: boolean;
}

interface PropTypes {
  category_list: Category[];
  onClick: (index: number) => void;
}
function BigCategory(prop: PropTypes) {
  return (
    <BigCategoryWrapper>
      {prop.category_list.map((category, index) => {
        return (
          <BigCategoryButton
            onClick={() => {
              prop.onClick(index);
            }}
            active={category.active}
          >
            {category.title}
          </BigCategoryButton>
        );
      })}
    </BigCategoryWrapper>
  );
}

function activateCategory(list: CategoryList[], index: number) {
  list.forEach(category => {
    category.active = false;
  });

  list[index].active = true;

  return list;
}

export { BigCategory, activateCategory };
