import { useDispatch, useSelector } from 'react-redux';
import {
  ManagerSection,
  RowDetailMemo,
  TableColumn,
  TableColumnSection,
  TableRow,
  TableSection,
} from './components';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { MenuItem, Pagination, Select } from '@mui/material';
import JonghapTableRowSection from './JonghapTableRowSection';
import JonghapJeonmoonTableRowSection from './JonghapJeonmoonTableRowSection';
import {
  getClassification,
  jonghapJeonmoonSearch,
  searchRecallJonghapJeonmoonSales,
} from '../../../utils/sales_API';
import {
  loadJonghapJeonmoonSales,
  resetJonghapJeonmoonSales,
  updateJonghapJeonmoonCurrentPage,
} from '../../../app/slice/jonghap_jeonmoonSlice';
import { updateLimit, updateSort } from '../../../app/slice/salesSlice';
import PageNumber from './PageNumber';

const St_CheckSection = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const St_ArrowSection = styled.div`
  display: flex;
  margin-top: 20px;
  justify-content: center;
  gap: 20px;
  align-items: end;
`;

const St_TotalCount = styled.div`
  color: rgba(26, 49, 85, 0.7);
  font-weight: bold;

  strong {
    font-weight: bold;
    color: #1a3155;
  }
`;
export default function JonghapJeonmoonRow({
  detailOn,
  setDetailOn,
  checkData,
  allCheck,
  changeExtraInfo,
  loadDetailMemo,
  regdate,
  is_shared,
  shareData,
  handlePage,
}) {
  const {
    jonghap_jeonmoon_data,
    jonghap_jeonmoon_total_count,
    selected_jonghap_jeonmoon_title,
    current_page,
  } = useSelector(state => state.jonghapJeonmoonSales);

  const dispatch = useDispatch();

  const [recallOn, setRecallOn] = useState(false);

  async function loadRecallSales() {
    const result = await searchRecallJonghapJeonmoonSales(1, limit);
    dispatch(
      loadJonghapJeonmoonSales({
        data: result.data.data,
        total_count: result.data.total_count,
      }),
    );
  }

  const user_id = localStorage.getItem('id');
  const { filters, sort, limit } = useSelector(state => state.sales);

  async function init() {
    const page = localStorage.getItem('jonghap_jeonmoon_page');

    dispatch(updateJonghapJeonmoonCurrentPage(Number(page ?? 1)));
    dispatch(resetJonghapJeonmoonSales());
    const jonghap_jeonmoon_result = await jonghapJeonmoonSearch(
      Number(page ?? 1),
      filters,
      sort,
      limit,
    );

    const classification = await getClassification(user_id);

    const jonghap_jeonmoon_array = [...jonghap_jeonmoon_result.data.data];

    jonghap_jeonmoon_array.forEach(item => {
      classification.forEach(element => {
        if (item.unique_key === element.ncrgsseq) {
          item.classification = element.classification;
        }
      });
    });

    if (jonghap_jeonmoon_result.status === true) {
      dispatch(
        loadJonghapJeonmoonSales({
          total_count: jonghap_jeonmoon_result.data.total_count,
          data: jonghap_jeonmoon_array,
        }),
      );
      setDetailOn({ key: detailOn.key, on: false });
    }
  }

  useEffect(() => {
    if (recallOn) {
      loadRecallSales();
    } else {
      init();
    }
  }, [recallOn, limit]);

  const limitOption = [10, 50, 100];

  return (
    <div style={{ display: 'flex' }}>
      <TableSection>
        <St_CheckSection>
          <St_TotalCount>
            총 <strong>{jonghap_jeonmoon_total_count.toString()}</strong> 개의
            종합+전문 DB 데이터가 있습니다.{' '}
          </St_TotalCount>

          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '10px',
            }}
          >
            <Select
              onChange={e => {
                dispatch(updateLimit(Number(e.target.value)));
              }}
              sx={{
                outline: 'none',
                width: '150px',
                border: '1px solid #ddd',
                borderRadius: '50px',
                color: '#aaa',
                fontSize: '16px',
              }}
              defaultValue={limit}
            >
              {limitOption.map(item => {
                return (
                  <MenuItem style={{ padding: '5px' }} key={item} value={item}>
                    {item}개씩 보기
                  </MenuItem>
                );
              })}
            </Select>

            <button
              onClick={() => {
                setRecallOn(!recallOn);
              }}
              style={
                recallOn
                  ? {
                      display: 'block',
                      backgroundColor: '#1976d2',
                      color: '#fff',
                      borderRadius: '5px',
                      width: '100px',
                      height: '30px',
                    }
                  : {
                      display: 'block',
                      backgroundColor: '#999',
                      color: '#fff',
                      borderRadius: '5px',
                      width: '100px',
                      height: '30px',
                    }
              }
            >
              재연락
            </button>
          </div>
        </St_CheckSection>

        <TableColumnSection>
          <TableColumn width={10}>번호</TableColumn>
          <TableColumn width={15}>상호명</TableColumn>
          <TableColumn width={10}>대표자명</TableColumn>
          <TableColumn width={15}>전화번호</TableColumn>
          <TableColumn width={15}>면허</TableColumn>
          <TableColumn
            width={15}
            onClick={() => {
              sort === null
                ? dispatch(updateSort(false))
                : dispatch(updateSort(!sort));
            }}
          >
            평가액 (단위:백만원)
            <span className="material-symbols-outlined">
              {sort === true ? 'expand_more' : 'expand_less'}
            </span>
          </TableColumn>
          <TableColumn width={10}>상세보기</TableColumn>
          <TableColumn width={10}>결번</TableColumn>
        </TableColumnSection>

        {jonghap_jeonmoon_data !== undefined &&
          jonghap_jeonmoon_data.map((item, index) => {
            return (
              <JonghapJeonmoonTableRowSection
                page={current_page}
                index={index}
                target="jonghap_jeonmoon"
                item={item}
                detailOn={detailOn}
                setDetailOn={params => {
                  setDetailOn(params);
                }}
                checkData={checkData}
                allCheck={allCheck}
                changeExtraInfo={() => {
                  changeExtraInfo();
                }}
                loadDetailMemo={() => {
                  loadDetailMemo(item.unique_key);
                }}
              />
            );
          })}

        <St_ArrowSection>
          <Pagination
            count={Math.ceil(jonghap_jeonmoon_total_count / limit)}
            onChange={handlePage}
            page={Number(current_page)}
          />

          <PageNumber
            currentPage={current_page}
            onChange={handlePage}
            pageNumber={Math.ceil(jonghap_jeonmoon_total_count / limit)}
          />
        </St_ArrowSection>
      </TableSection>

      <ManagerSection>
        <h2
          style={{
            marginBottom: '20px',
            fontWeight: 'bold',
            fontSize: '20px',
          }}
        >
          체크리스트
        </h2>
        <TableColumnSection>
          <TableColumn width={30}>날짜</TableColumn>
          <TableColumn width={40}>상호명</TableColumn>
          <TableColumn width={30}>공유</TableColumn>
        </TableColumnSection>

        <>
          <div
            style={{
              display: 'flex',
              borderBottom: '1px solid #ddd',
            }}
          >
            <TableRow width={30}>
              {regdate === undefined ? '-' : regdate}
            </TableRow>
            <TableRow width={40}>{selected_jonghap_jeonmoon_title}</TableRow>
            <TableRow width={30}>
              <button
                style={
                  is_shared === true
                    ? {
                        backgroundColor: 'black',
                        borderRadius: '4px',
                        fontSize: '12px',
                        padding: '5px',
                        color: '#fff',
                      }
                    : {
                        backgroundColor: '#ddd',
                        borderRadius: '4px',
                        fontSize: '12px',
                        padding: '5px',
                      }
                }
              >
                {is_shared === true ? '메모완료' : '메모작성'}
              </button>
            </TableRow>
          </div>
          <RowDetailMemo shareData={shareData} detailOn={detailOn} />
        </>
      </ManagerSection>
    </div>
  );
}
