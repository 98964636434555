import axios from 'axios';

export async function getNotificationSetting() {
  const option = {
    url: 'https://cpi.contax.ai/get_bid_notification_setting',
    method: 'GET',
    header: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data'
    }
  };

  try {
    const response = await axios(option);
    if (response.status === 200) {
      return {
        status: 'success',
        data: response.data
      };
    } else {
      return {
        status: 'failed',
        data: []
      };
    }
  } catch ({ message }) {
    return {
      status: 'failed',
      message: message
    };
  }
}
