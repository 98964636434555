import { createSlice } from '@reduxjs/toolkit';

export interface MemberTypes {
  representative_name: string;
  email: string;
  company_name: string;
  check: boolean;
}

export interface SelectedMemberTypes {
  representative_name: string;
  email: string;
}

export interface SettingType {
  plan: string[];
  area: string[];
}

export interface EmailState {
  member: MemberTypes[];
  selectedMembers: SelectedMemberTypes[];
  settings: SettingType;
  pending: true | false | 'success' | 'failed';
  isAllCheck: boolean;
  content: string;
}

const initialState: EmailState = {
  member: [],
  selectedMembers: [],
  settings: {
    plan: [],
    area: []
  },
  pending: true,
  isAllCheck: false,
  content: ''
};

export const emailSlice = createSlice({
  name: 'email',
  initialState: initialState,
  reducers: {
    resetSettings: (state, action) => {
      state.settings = {
        plan: ['비즈니스', '퍼스트', '콘택스'],
        area: [
          '서울',
          '경기',
          '인천',
          '부산',
          '경남',
          '경북',
          '충남',
          '충북',
          '전남',
          '전북',
          '대구',
          '광주',
          '대전',
          '울산',
          '세종',
          '강원',
          '제주'
        ]
      };
    },

    addSelectedMember: (state, action) => {
      const array = [...state.selectedMembers];

      array.push({
        representative_name: action.payload.representative_name,
        email: action.payload.email
      });

      state.selectedMembers = array;
    },

    getMember: (state, action: { payload: MemberTypes[] }) => {
      const member: MemberTypes[] = [];

      if (state.isAllCheck) {
        action.payload.forEach((item) => {
          member.push({ ...item, check: true });
        });
      } else {
        action.payload.forEach((item) => {
          member.push({ ...item, check: false });
        });

        state.selectedMembers.forEach((item) => {
          member.forEach((jtem) => {
            if (jtem.email === item.email) {
              jtem.check = true;
            }
          });
        });
      }

      state.member = member;
    },

    updateContent: (state, action) => {
      state.content = action.payload;
    },

    getSelectMember: (state, action) => {
      let member = [...state.selectedMembers];
      let isFind = false;

      member.forEach((item) => {
        if (item.email === action.payload.email) {
          isFind = true;
        }
      });

      if (isFind) {
        member = member.filter((item) => {
          return item.email !== action.payload.email;
        });
      } else {
        member.push({
          representative_name: action.payload.representative_name,
          email: action.payload.email
        });
      }

      state.selectedMembers = member;
    },

    offCheck: (state, action) => {
      const member = [...state.member];

      member.forEach((item) => {
        if (item.email === action.payload.email) {
          item.check = false;
        }
      });

      state.member = member;
    },

    handleCheck: (state, action) => {
      const member = [...state.member];

      member[action.payload].check = !member[action.payload].check;

      state.member = member;
    },

    handleAllCheck: (state, action) => {
      const member = [...state.member];

      state.isAllCheck = action.payload === true;
      member.forEach((item) => {
        item.check = action.payload === true;
      });

      state.member = member;
    },

    handlePlanSetting: (state, action) => {
      let array = [...state.settings.plan];

      if (array.includes(action.payload)) {
        array = array.filter((item) => item !== action.payload);
      } else {
        array.push(action.payload);
      }

      state.settings.plan = array;
    },

    deleteSelectedMember: (state, action) => {
      const array = [...state.selectedMembers];
      state.selectedMembers = array.filter((item) => {
        return item.email !== action.payload.email;
      });
    },

    resetSelectMember: (state) => {
      state.selectedMembers = [];
    },

    handleAreaSetting: (state, action) => {
      let array = [...state.settings.area];

      if (array.includes(action.payload)) {
        array = array.filter((item) => item !== action.payload);
      } else {
        array.push(action.payload);
      }

      state.settings.area = array;
    }
  }
});

export const {
  offCheck,
  updateContent,
  resetSelectMember,
  getSelectMember,
  handleCheck,
  handleAllCheck,
  getMember,
  deleteSelectedMember,
  addSelectedMember,
  resetSettings,
  handleAreaSetting,
  handlePlanSetting
} = emailSlice.actions;

export default emailSlice.reducer;
