import {ChevronLeft, ChevronRight} from '@mui/icons-material';
import React, {MouseEventHandler} from 'react';
import styled from 'styled-components';

interface PaginationNumberProps {
  active: boolean;
  onClick?: MouseEventHandler;
}

const St_PaginationNumber = styled.span<PaginationNumberProps>`
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  border-radius: 50px;
  color: ${(props) => (props.active ? '#fff' : '#071e37')};
  background-color: ${(props) => (props.active ? '#1976d2' : 'none')};
  cursor: pointer;
`;

const St_Pagination = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  font-size: 18px;
  margin-top: 30px;
`;

interface BoardPagenationProps {
  offset: number;
  pageNumbers: number[];
  pageLength: number;
  setOffset: (offset: number) => void;
}

function BoardPagenation(params: BoardPagenationProps) {
  return (
    <St_Pagination>
      {params.offset !== 0 && (
        <ChevronLeft
          onClick={() => {
            params.setOffset(params.offset - 1);
          }}
          style={{cursor: 'pointer', color: 'rgba(26,49,85,0.51)'}}
        />
      )}

      {params.pageNumbers.map((item, index) => {
        return (
          <St_PaginationNumber
            onClick={() => {
              params.setOffset(item - 1);
            }}
            active={item - 1 === params.offset}
          >
            {item}
          </St_PaginationNumber>
        );
      })}

      {params.offset !== params.pageLength - 1 && (
        <ChevronRight
          onClick={() => {
            params.setOffset(params.offset + 1);
          }}
          style={{cursor: 'pointer', color: 'rgba(26,49,85,0.51)'}}
        />
      )}
    </St_Pagination>
  );
}

export default BoardPagenation;
