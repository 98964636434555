import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StoreState } from '../../../app/store';
import {
  changeManagerDivision,
  updateAccess,
  updateMember
} from '../../../utils/user_API';
import axios from 'axios';
import { CancelButton } from '../../../components/GridType';
import { MemberDetailSelectPropertyDefault } from './MemberDetailProperty';
import { SelectedMember } from '../../email/component/SelectedMember';
import { insertAccess } from '../tools/content_management';
import { CreateProperty } from '../../../components/Layout/CreateProperty';
import {
  updateMemberPageStatus,
  updateCreateMemberInformation,
  updateSelectedContents,
  deleteSelectedContents
} from '../../../app/slice/managerManagementSlice';
import { SaveButton } from '../../../components/Layout/SaveButton';

export function CreateSection() {
  const members = useSelector((state: StoreState) => state.managerManagement);
  const [password, setPassword] = useState('');
  const [lastSelected, setLastSelected] = useState('');
  const create_member = members.create_member;

  useEffect(() => {
    setPassword('');
  }, []);

  async function changeManager(user_id: number) {
    await changeManagerDivision(user_id, create_member.manager ?? '전문가');
  }

  async function insertMember() {
    const date = new Date();

    const optionData = {
      member: {
        email: create_member.email,
        password: password,
        name: create_member.name,
        position: create_member.position,
        phone_number: create_member.phone_number,
        business_number: create_member.business_number,
        company_name: create_member.company_name,
        representative_name: 'Null',
        address: create_member.address,
        company_phone_number: create_member.company_phone_number,
        business_type: '관리자',
        reg_date: `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`,
        sms_kakao_agree: 0
      },

      licenses: []
    };

    const option = {
      url: 'https://cpi.contax.ai/members/add_member',
      data: optionData,
      method: 'POST',
      header: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data'
      }
    };

    try {
      const response = await axios(option);
      if (response.status === 200) {
        if (response.data.error === 'The company name already exists') {
          alert('이미 존재하는 회사명입니다.');
          return;
        }

        if (response.data.error === 'The business number already exists') {
          alert('이미 존재하는 사업자 번호입니다.');
          return;
        }

        if (response.data.error === 'The email already exists') {
          alert('이미 존재하는 이메일입니다.');
          return;
        }

        alert('관리자가 추가되었습니다.');

        if (create_member.manager === 'content') {
          const body = {
            user_id: response.data.user_id,
            access: members.selected_contents,
            assignment: []
          };

          await insertAccess(body);
        }

        if (response.data.status === 'member added') {
          await changeManager(response.data.user_id);
          await updateAccess(create_member.user_id, members.selected_contents);
        }
      }
    } catch (e: any) {
      if (
        e.response.data.detail[0].msg ===
        'ensure this value has at least 1 characters'
      ) {
        alert('적어도 한가지 이상의 문자를 적어주세요');
        return;
      }

      if (
        e.response.data.detail[0].msg === 'value is not a valid email address'
      ) {
        alert('이메일 형식을 맞게 작성해주세요');
        return;
      }
    }
  }

  const dispatch = useDispatch();

  async function changeMemberInformation() {
    const response = await updateMember(members.create_member);

    if (response.message === 'success') {
      alert('성공적으로 수정되었습니다.');
      await updateAccess(create_member.user_id, members.selected_contents);
      await changeManager(create_member.user_id);
      dispatch(updateMemberPageStatus('read'));
    } else {
      alert('오류가 발생하였습니다.');
    }
  }

  function onChange(text: string, id: string) {
    dispatch(
      updateCreateMemberInformation({
        id: id,
        value: text
      })
    );
  }

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '30px' }}>
        <CreateProperty
          active={true}
          onChange={(e) => {
            onChange(e.target.value, 'name');
          }}
          title="이름"
          value={create_member.name}
        />

        <CreateProperty
          active={true}
          onChange={(e) => {
            onChange(e.target.value, 'company_name');
          }}
          title="회사명"
          value={create_member.company_name}
        />

        <CreateProperty
          active={true}
          onChange={(e) => {
            onChange(e.target.value, 'email');
          }}
          title="이메일"
          value={create_member.email}
        />

        <CreateProperty
          active={true}
          onChange={(e) => {
            onChange(e.target.value, 'password');
          }}
          title="비밀번호"
          value={create_member.password}
        />

        <CreateProperty
          active={true}
          onChange={(e) => {
            onChange(e.target.value, 'phone_number');
          }}
          title="전화번호"
          value={create_member.phone_number}
        />

        <CreateProperty
          active={true}
          onChange={(e) => {
            onChange(e.target.value, 'company_phone_number');
          }}
          title="회사 전화번호"
          value={create_member.company_phone_number}
        />

        <CreateProperty
          active={true}
          onChange={(e) => {
            onChange(e.target.value, 'business_number');
          }}
          title="사업자번호"
          value={create_member.business_number}
        />

        <CreateProperty
          active={true}
          onChange={(e) => {
            onChange(e.target.value, 'position');
          }}
          title="직위"
          value={create_member.position}
        />

        <MemberDetailSelectPropertyDefault
          isModify={true}
          title="관리자 선택"
          value={create_member.manager ?? '전문가'}
          id="manager"
          size="short"
          item={[
            { value: 'contact_sales', title: '전문가' },
            { value: 'content', title: '콘텐츠 관리자' },
            { value: 'tax', title: '세무 관리자' }
          ]}
          onChange={(e) => {
            onChange(e.target.value, 'manager');
          }}
        />

        {create_member.manager === 'content' && (
          <MemberDetailSelectPropertyDefault
            item={[
              { value: '면허 관리', title: '면허 관리' },
              { value: '회원 관리', title: '회원 관리' },
              { value: '영업 관리', title: '영업 관리' },
              { value: '이메일', title: '이메일' },
              { value: '고객센터', title: '고객센터' },
              { value: '전문가 고객센터', title: '전문가 고객센터' },
              { value: '공지사항', title: '공지사항' },
              { value: '프로모션 코드', title: '프로모션 코드' },
              { value: '1:1 문의', title: '1:1 문의' },
              { value: '기업 컨설팅', title: '기업 컨설팅' },
              { value: '세무 서비스', title: '세무 서비스' },
              { value: '알림 현황', title: '알림 현황' }
            ]}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              dispatch(updateSelectedContents(event.target.value));
              setLastSelected(event.target.value);
            }}
            size="short"
            id="content"
            title="콘텐츠 선택"
            isModify={true}
            value={lastSelected}
          />
        )}
      </div>

      {create_member.manager === 'content' && (
        <div style={{ display: 'flex', gap: '20px' }}>
          {members.selected_contents.length === 0 && (
            <span style={{ color: '#606975' }}>
              선택한 콘텐츠가 존재하지 않습니다.
            </span>
          )}
          {members.selected_contents.length > 0 &&
            members.selected_contents.map((item) => (
              <SelectedMember
                key={item}
                name={item}
                email={''}
                onDelete={() => {
                  dispatch(deleteSelectedContents(item));
                }}
              />
            ))}
        </div>
      )}

      <div
        style={{
          margin: '50px 0',
          height: '2px',
          backgroundColor: '#eee'
        }}
      />
      <div style={{ justifyContent: 'end', display: 'flex', gap: '20px' }}>
        <SaveButton
          onClick={
            members.status === 'write' ? insertMember : changeMemberInformation
          }
        />
        <CancelButton
          onClick={() => {
            dispatch(updateMemberPageStatus('read'));
          }}
        />
      </div>
    </>
  );
}
