import Header from './Header';
import routes from '../routes';
import { Suspense } from 'react';
import SuspenseContent from './SuspenseContent';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useRef } from 'react';
import { updateSelectedMenu } from '../app/slice/layoutSlice';

function PageContent() {
  const mainContentRef = useRef(null);
  const { pageTitle } = useSelector((state) => state.header);
  const { selected_menu } = useSelector((state) => state.layout);

  const dispatch = useDispatch();

  // Scroll back to top on new page load
  useEffect(() => {
    mainContentRef.current.scroll({
      top: 0,
      behavior: 'smooth'
    });

    const menu = localStorage.getItem('selected_menu');
    if (menu === null) {
      dispatch(updateSelectedMenu(''));
    } else {
      dispatch(updateSelectedMenu(menu));
    }
  }, [pageTitle]);

  return (
    <div className="drawer-content flex flex-col ">
      <Header />
      <main
        className="flex-1 overflow-y-auto pt-8 px-6  bg-base-200"
        ref={mainContentRef}
      >
        <Suspense fallback={<SuspenseContent />}>
          {routes.map((route) => {
            if (route.name === selected_menu) {
              return route.component;
            }
          })}
        </Suspense>
        <div className="h-16"></div>
      </main>
    </div>
  );
}

export default PageContent;
