import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {closeModal} from '../app/slice/layoutSlice';
import {Modal} from './Modal';
import {
  ColProps,
  ManagerGrid,
  ManagerGridButton,
  ManagerGridCheckbox,
  ManagerGridDetailSection,
  ManagerGridRow,
  ManagerGridRowSection
} from './GridType';
import BoardPagenation from './BoardPagenation';
import {QueryParams, getEmailBoard} from '../utils/email_API';
import styled from 'styled-components';
import {StoreState} from '../app/store';
import {
  TableType,
  handleCheck,
  resetBoardList,
  updateBoardList,
  updateDetailSection,
  updatePending,
  updateType
} from '../app/slice/emailBoardSlice';
import {calculatePagination, createPaginationSize} from '../utils/common_API';
import {AllCheck} from '../page/email/component/AllCheck';
import {updateContent} from '../app/slice/emailSlice';

const TableTypeButton = styled.div<{active: boolean}>`
  font-size: 24px;
  font-weight: bold;
  color: #1a3155;
  opacity: ${(props) => (props.active ? 1 : 0.5)};
  cursor: pointer;
  transition: opacity 0.3s ease;

  &:hover {
    opacity: 1;
  }
`;

const TableTypeButtonWrapper = styled.div`
  margin-bottom: 60px;
  display: flex;
  align-items: center;
  gap: 40px;
`;

export function EmailBoardModal() {
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = React.useState(10);
  const [page_numbers, setPageNumbers] = React.useState([1, 2]);
  const [page_length, setPageLength] = React.useState(1);

  const dispatch = useDispatch();
  const {type, board_list, pending, open_item, isAllCheck, checked_item} = useSelector(
    (state: StoreState) => state.emailBoard
  );
  const {content} = useSelector((state: StoreState) => state.email);
  const typeList = ['매거진', '공지사항', '이용안내', '자주하는 질문'];

  function onClose() {
    dispatch(closeModal());
  }

  function clickTypeButton(item: string) {
    dispatch(updateType(item));
    setOffset(0);
  }

  async function clickAllCheck() {
    // dispatch(handleAllCheck(!isAllCheck));
    // if (isAllCheck === false) {
    //     const response = await getAllUserEmail(
    //         settings.plan,
    //         settings.area,
    //     );
    //     if (response.status === "success") {
    //         dispatch(resetBoardList());
    //         response.data.forEach((item: any) => {
    //             dispatch(updateBoardList(item));
    //         });
    //     }
    // } else {
    //     dispatch(resetBoardList());
    // }
  }

  async function loadBoardList() {
    dispatch(updatePending(true));

    const params: QueryParams = {
      database: type,
      categories: [],
      search_queries: [],
      offset: offset * limit,
      limit: limit,
      sort_option: 'regdate',
      sort: 'DESC'
    };

    const response = await getEmailBoard(params);

    if (response?.status === 'success') {
      const length = calculatePagination(response.data.total_count, limit);
      const array = createPaginationSize(offset, length);

      setPageLength(length);
      setPageNumbers(array);

      dispatch(updateBoardList(response.data));
      dispatch(updatePending(false));
      return;
    }

    dispatch(updatePending(false));
    dispatch(resetBoardList());
  }

  useEffect(() => {
    loadBoardList();
  }, [type, offset]);

  const GridColumns: ColProps[] = [
    {
      title: '선택',
      id: 'check',
      sort: false,
      width: 40,
      search: false
    },

    {
      title: '제목',
      id: 'title',
      sort: false,
      width: 120,
      search: false
    },

    {
      title: '카테고리',
      id: 'category',
      sort: false,
      width: 60,
      search: false
    },

    {
      title: '작성날짜',
      id: 'regdate',
      sort: false,
      width: 60,
      search: false
    }
  ];

  function removeBoardItem(className: string, list: TableType) {
    let isExist = false;

    checked_item.forEach((item) => {
      if (item.unique_key === className) {
        isExist = true;
      }
    });

    if (isExist) {
      const parser = new DOMParser();
      const doc = parser.parseFromString(content, 'text/html');
      const boardItem = doc.querySelectorAll(`p`);
      boardItem.forEach((item) => {
        if (item.innerText.includes(className)) {
          const prevElement = item.previousElementSibling;
          if (prevElement && prevElement.tagName === 'H1') {
            prevElement.remove();
          }
          item.remove();
        }
      });

      dispatch(updateContent(doc.body.innerHTML));
    } else {
      let html_content = content;
      html_content += `
            <div class="${className}">
                <h1 style="font-weight: bold;">${list.title}</h1>
                <p>[[<${className}> ${list.title} 링크 배너]]</p>
            </div>
        `;
      dispatch(updateContent(html_content));
    }
  }

  return (
    <Modal title="게시글 선택" onClick={onClose}>
      <>
        <div style={{display: 'flex', flexDirection: 'column'}}>
          <TableTypeButtonWrapper>
            {typeList.map((item) => (
              <TableTypeButton
                onClick={() => {
                  clickTypeButton(item);
                }}
                active={type === item}
              >
                {item}
              </TableTypeButton>
            ))}
          </TableTypeButtonWrapper>
          <div style={{marginBottom: '20px'}}>
            <AllCheck title="전체 선택" onClick={clickAllCheck} active={isAllCheck} />
          </div>
        </div>

        <ManagerGrid
          col={GridColumns}
          size="small"
          sortFunction={() => {
            return;
          }}
        >
          {pending === false && board_list.length <= 0 && <div> 데이터가 존재하지 않습니다. </div>}
          {pending === false &&
            board_list.length > 0 &&
            board_list.map((item, index) => (
              <>
                <ManagerGridRowSection>
                  <ManagerGridRow
                    width={40}
                    size="small"
                    onClick={() => {
                      removeBoardItem(`${type}_${item.id}`, item);
                      dispatch(
                        handleCheck({
                          id: item.id,
                          type: type
                        })
                      );
                    }}
                  >
                    <ManagerGridCheckbox checked={item.check} />
                  </ManagerGridRow>

                  <ManagerGridRow
                    width={120}
                    size="small"
                    onClick={() => {
                      dispatch(updateDetailSection(index + 1));
                    }}
                  >
                    {item.title}
                  </ManagerGridRow>

                  <ManagerGridRow
                    width={60}
                    size="small"
                    onClick={() => {
                      dispatch(updateDetailSection(index + 1));
                    }}
                  >
                    {item.category === undefined ? '-' : item.category}
                  </ManagerGridRow>

                  <ManagerGridRow
                    width={60}
                    size="small"
                    onClick={() => {
                      dispatch(updateDetailSection(index + 1));
                    }}
                  >
                    {item.regdate.substring(0, 10)}
                  </ManagerGridRow>
                </ManagerGridRowSection>
                <ManagerGridDetailSection open={open_item === index + 1}>
                  <div
                    style={{
                      height: '50px',
                      overflowY: 'scroll'
                    }}
                    dangerouslySetInnerHTML={{
                      __html: item.content
                    }}
                  />
                </ManagerGridDetailSection>
              </>
            ))}
        </ManagerGrid>

        <BoardPagenation offset={offset} pageNumbers={page_numbers} pageLength={page_length} setOffset={setOffset} />
      </>

      <ManagerGridButton
        onClick={() => {
          dispatch(closeModal());
        }}
        type={'active'}
      >
        저장
      </ManagerGridButton>
    </Modal>
  );
}
