import React from 'react';

interface LicensesCountType {
  [key: string]: number;
}

interface LicensesCountDisplayProps {
  data: LicensesCountType;
}

const LicensesCountDisplay: React.FC<LicensesCountDisplayProps> = ({
  data
}) => {
  return (
    <div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '5px',
          marginBottom: '1rem'
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <span>종합 면허 : </span>
          <span
            style={{
              marginLeft: '5px',
              display: 'inline-block',
              width: '15px',
              height: '5px',
              backgroundColor: 'green'
            }}
          />
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <span>전문 면허 : </span>
          <span
            style={{
              marginLeft: '5px',
              display: 'inline-block',
              width: '15px',
              height: '5px',
              backgroundColor: 'black'
            }}
          />
        </div>
      </div>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(4, 1fr)',
          width: '100%',
          padding: '20px',
          borderRadius: '10px',
          height: '400px',
          overflowY: 'scroll',
          border: '2px solid #ddd',
          gap: '10px'
        }}
      >
        {Object.entries(data).map(([key, value]) => (
          <div
            key={key}
            style={{
              border: '2px solid #ddd',
              borderRadius: '5px',
              padding: '10px',
              marginBottom: '12px',
              width: '100%',
              minHeight: '100px'
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '10px',
                justifyContent: 'center',
                height: '100%'
              }}
            >
              <div
                style={{
                  fontWeight: 'bold',
                  textAlign: 'center',
                  color:
                    key === '토목공사업' ||
                    key === '건축공사업' ||
                    key === '조경공사업' ||
                    key === '토목건축공사업' ||
                    key === '산업환경설비공사업'
                      ? 'green'
                      : 'black'
                }}
              >
                {key === '' ? '면허명 없음' : key}
              </div>
              <div style={{ textAlign: 'center' }}>Today: {value}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default LicensesCountDisplay;
